import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { RvsCategory } from '@models/rvs-category.model';
import { SearchSubjectVersion } from '@models/search-subject-version.model';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { NomenclatureService } from '@services/nomenclature.service';
import { SubjectVersionService } from '@services/subject-version.service';
import { EMPTY, Observable, of, OperatorFunction, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, first, switchMap, tap } from 'rxjs/operators';
import { ListDailyServiceReportSingle } from '../_models/list-daily-service-report-single.model';
import { DailyServiceReportSingleService } from '../_services/daily-service-report-single.service';
import { prepareDateForDb, convertDateToString, convertObjDateToString, prepareDateForForm } from '@app/_utils/date-util';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { Service } from '@app/services/_models/service.model';
import { ServiceType } from '@models/service-type.model';

@Component({
  selector: 'app-daily-service-report-single',
  templateUrl: './daily-service-report-single.component.html',
  styleUrls: ['./daily-service-report-single.component.css']
})
export class DailyServiceReportSingleComponent extends BaseSortableSearchComponent<ListDailyServiceReportSingle> implements OnInit, OnDestroy {
  // Units
  technicalPoints:                TechnicalPoint[];
  categories:                     RvsCategory[];
  serviceTypes:                   ServiceType[];
  services:                       Service[];

  // Constants
  private readonly MAIN_SERVICE_TYPE      = 'Основна услуга';
  private readonly MAIN_SERVICE_GTP_NAME  = "ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД";
  private readonly YES                    = "Да";
  private readonly NO                     = "Не";

  // Payload
  userTechnicalPointMobileAppIds: number[];
  mainServiceType:                ServiceType;
  total:                          any = [];
  dateObj                         = new Date();
  sumServiceCount:                number;
  sumTotalAmountDds:              number;
  isEmployeeOptions:              any;
  sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);

  // Booleans
  hasTechnicalPoint:  boolean;
  loadPdfOrExcel      = false;  

  // Form
  searchForm = this.formBuilder.group({
    techPointMobileAppId:            [''],
    dateFrom:                        prepareDateForForm(this.dateObj),
    dateTo:                          prepareDateForForm(this.dateObj),
    regNumber:                       null,
    categoryId:                      null,
    subject:                         null,
    ownerName:                       null,
    serviceType:                     null,
    serviceName:                     null,
    isEmployee:                      [],
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.dailyServiceReportSingleService.pageable(this.sortingPaging, this.searchForm.value,
                                                            prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                            prepareDateForDb(this.searchForm.get('dateTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    })
  );

  ownerSearch: OperatorFunction<string, String[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    filter((input) => input.length > 3), 
    switchMap(input =>
      this.dailyServiceReportSingleService.filterOwnerName(input).pipe(
        catchError(() => of([]))
      )
    )
  );

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
    input$.pipe(
      debounceTime(300),
      filter((input) => input.length > 3), 
      switchMap(input =>
        this.subjectVersionService.filterSubjectVersions(input).pipe(
          catchError(() => of([]))
        )
      )
  );

  constructor(
    public perms: PermissionsService,
    private formBuilder: FormBuilder,
    private exportService: ExportService,
    private translateService: TranslateService,
    private nomenclatureService: NomenclatureService,
    private technicalPointService: TechnicalPointService,
    private subjectVersionService: SubjectVersionService,
    private dailyServiceReportSingleService: DailyServiceReportSingleService
    ) {
      super();
      this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  async ngOnInit() {
    await this.loadCategories()
    await this.loadTechnicalPoints();
    await this.loadServiceTypes();
    this.searchSubscription = this.search$.subscribe();
    this.isEmployeeOptions = [
      { key: this.YES, value: true },
      { key: this.NO, value: false }
    ];
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  private async loadCategories() {
    try {
      const result = await this.nomenclatureService.getRvsCategories().toPromise();
      this.categories = result;
    } catch (err) {
      displayErrorFromUnknown(err);
    }
  }

  private async loadTechnicalPoints() {
    try {
      if (this.userTechnicalPointMobileAppIds.length === 0) {
        const result = await this.technicalPointService.findTechnicalPoints().toPromise();
        this.technicalPoints = result;
        this.hasTechnicalPoint = false;
      } else {
        const result = await this.technicalPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise();
        this.hasTechnicalPoint = true;
        this.technicalPoints = result;
        this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
      }
    } catch (err) {
      displayErrorFromUnknown(err);
    }
  }

  private async loadServiceTypes() {
    try {
      const serviceTypes = await this.nomenclatureService.getServiceTypes().toPromise();
      this.serviceTypes = serviceTypes;

      this.mainServiceType = this.serviceTypes.splice(this.serviceTypes.findIndex((item: { description: string; }) => item.description === this.MAIN_SERVICE_TYPE), 1)[0];
      if (this.mainServiceType) {
        this.serviceTypes.splice(0, 0, this.mainServiceType);
        this.searchForm.get('serviceType')?.patchValue(this.mainServiceType.id);

        await this.loadServices();
      }
    } catch(err) {
      displayErrorFromUnknown(err);
    }
  }

  async loadServices() {
    try {
      this.searchForm.get('serviceName')?.setValue('');
      let mainService = this.searchForm.get('serviceType')?.value;

      if (mainService) { 
        this.services = await this.nomenclatureService.findAllValidServicesByTypeId(mainService).toPromise();
      } else {
        this.services = await this.nomenclatureService.findAllValidServices().toPromise();
      }

      const gtp = this.services.splice(this.services.findIndex((item: { name: string; }) => item.name === this.MAIN_SERVICE_GTP_NAME), 1)[0];
      if (gtp) {
        this.services.splice(0, 0, gtp);
      }
    } catch(err) {
      displayErrorFromUnknown(err);
    }
  }

  async search() {
    this.dataLoaded = false;
    
    await this.dailyServiceReportSingleService.pageable(this.sortingPaging, this.searchForm.value, 
      prepareDateForDb(this.searchForm.get('dateFrom')?.value), prepareDateForDb(this.searchForm.get('dateTo')?.value)).toPromise().then(res => {
      const [page, totalCount] = res; 
      this.sortingPaging.fromRow = page.fromRow;
      this.sortingPaging.toRow = page.toRow;
      this.sortingPaging.totalElements = totalCount;
      this.content = page.content;
      this.dataLoaded = true;
    })
    .catch(err => {
      this.dataLoaded = false;
      displayError(err);
    });
  }


  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      techPointMobileAppId:                 [''],
      dateFrom:                             prepareDateForForm(this.dateObj),
      dateTo:                               prepareDateForForm(this.dateObj),
      regNumber:                            null,
      categoryId:                           null,
      subject:                              null,
      ownerName:                            null,
      serviceType:                          null,
      serviceName:                          null,
      isEmployee:                           []
    });

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }
    
    if (this.mainServiceType) {
      this.searchForm.get('serviceType')?.patchValue(this.mainServiceType.id);
      this.loadServices();
    }

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("references.dailyReportSingle"), this.total);
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("references.dailyReportSingle"), convertDateToString(new Date()), this.translateService.instant("references.pastIncomePdfFooter", { 0: this.sumServiceCount, 1: parseFloat(this.sumTotalAmountDds.toString()).toFixed(2)}));  
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.dailyServiceReportSingleService.findAllByFilter(sortingPagingCopy, this.searchForm.value,
                                                                                prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                                prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                           ).pipe(first()).toPromise();
    this.sumServiceCount = 0;
    this.sumTotalAmountDds = 0;

    result.content.forEach(object => {
      newContent?.push([object.inspectionDate !== null ? object.inspectionDate : "-",
                        object.techPointShortName !== null ? object.techPointShortName : "-",
                        object.personName !== null ? object.personName : "-",
                        object.invoiceSubjectName !== null ? object.invoiceSubjectName : "-",
                        object.isEmployee !== null ? object.isEmployee ? this.YES : this.NO : this.NO,
                        object.serviceName !== null ? object.serviceName : "-",
                        object.paymentType !== null ? object.paymentType : "-",
                        object.discountName !== null ? object.discountName : "-",
                        object.serviceCount !== null ? object.serviceCount : "-",
                        object.techPointDiscountAmount !== null ? object.techPointDiscountAmount : "-",
                        object.totalAmountDds !== null ? object.totalAmountDds : "-",
                        object.incomingWay !== null ? object.incomingWay : "-",
                        object.regNumber !== null ? object.regNumber : "-",
                        object.categoryCode !== null ? object.categoryCode : "-"]);
                        // object.conclusionDescription !== null ? object.conclusionDescription : "-",
                        // object.validTo !== null ? object.validTo : "-"]);

      this.sumServiceCount = this.sumServiceCount + object.serviceCount;
      this.sumTotalAmountDds = this.sumTotalAmountDds + object.totalAmountDds;
    })
    
    this.total = [];
    this.total?.push([[],
                      [],
                      [this.translateService.instant("references.sum")],
                      [this.sumServiceCount], 
                      [this.translateService.instant("references.sum")], 
                      [parseFloat(this.sumTotalAmountDds.toString()).toFixed(2)]
                    ]);
    
    return newContent;
  }

  private getHeadings() : string[][] {
    return [[this.translateService.instant("references.date"),
             this.translateService.instant("references.point"),
             this.translateService.instant("references.name"),
             this.translateService.instant("references.subjectName"),
             this.translateService.instant("references.isEmployee"),
             this.translateService.instant("references.service"),
             this.translateService.instant("references.paymentMethod"),
             this.translateService.instant("references.discount"),
             this.translateService.instant("references.pointTechInsp"),
             this.translateService.instant("references.unitPriceWithVatAfterDiscount"),
             this.translateService.instant("references.totalPrice"),
             this.translateService.instant("references.receptionChannel"),
             this.translateService.instant("references.regNumberShort"),
             this.translateService.instant("references.category")]]
            //  this.translateService.instant("references.conclusionDescription"),
            //  this.translateService.instant("references.validTo")]]
  }

  private getFilterBody(): any[] {
    let tpName: string = "";
    this.technicalPoints.forEach(tp => {
      if (tp.mobileAppId == this.searchForm.get('techPointMobileAppId')?.value) {
        tpName = tp.shortName;
      }
    });

    let serviceTypeName = '';
    this.serviceTypes.forEach(st => {
      if (st.id == this.searchForm.get('serviceType')?.value) {
        serviceTypeName = st.description; 
      }
    });

    let result: string[] = [
      tpName,
      convertObjDateToString(this.searchForm.get('dateFrom')?.value), 
      convertObjDateToString(this.searchForm.get('dateTo')?.value),
      this.searchForm.get('regNumber')?.value,
      this.searchForm.get('ownerName')?.value,
      serviceTypeName,
      this.searchForm.get('serviceName')?.value,
    ];

    return [result];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 9;
    let result: any[] = [
     this.translateService.instant('references.point'),
     this.translateService.instant('references.fromDate'),
     this.translateService.instant('references.toDate'),
     this.translateService.instant('references.regNumber'),
     this.translateService.instant('references.name'),
     this.translateService.instant('references.serviceType'),
     this.translateService.instant('references.service'),
    ];

   return [
     [{content: this.translateService.instant('references.statisticsInspections'), 
       colSpan: colSpan, styles: {halign: 'center'}}], result
   ];
  }

  subjectVersionFormatter(element: any): string {
    return element.fullName;
  }

}