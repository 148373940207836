import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { config } from '@env/config';
import { SortingPaging } from '@helpers/sorting-paging';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-search',
  template: ``,
  styles: [
  ]
})
export class BaseSearchComponent<T> implements OnInit {
  
  pageSizeOptions: number[] = config.pageSizeOptions;
  sortingPaging: SortingPaging = new SortingPaging(config.pageSize);
  content: T[];
  maxSize: number = config.maxSize;
  dataLoaded: boolean;
  searchForm: FormGroup;

  protected errorMessageSubject = new Subject<string>();
  errorMessage$ = this.errorMessageSubject.asObservable();

  protected reloadSubject = new Subject();
  reload$ = this.reloadSubject.asObservable();

  protected searchSubject = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
  }

  reload():void {
    this.errorMessageSubject.next(undefined);
    this.reloadSubject.next();
  }

  pageChanged(page: number): void {
    this.sortingPaging.pageNumber = page;
    this.searchSubject.next();
  }

  onPageSizeChange(event: any) {
    this.sortingPaging.pageSize = event.target.value;
    this.searchSubject.next();
  }

  search() {
    if (this.searchForm.valid) {
      this.searchSubject.next();
    }
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchSubject.next();
  }

  compareById(optionOne: any, optionTwo: any): boolean {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  compareByCode(optionOne: any, optionTwo: any) : boolean {
    return optionOne && optionTwo && optionOne.code === optionTwo.code;
  }
}
