import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { SearchSubjectVersion } from '@models/search-subject-version.model';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { SubjectVersionService } from '@services/subject-version.service';
import { EMPTY, Observable, of, OperatorFunction, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, first, switchMap, tap } from 'rxjs/operators';
import { ListStatisticsInspections } from '../_models/list-statistics-inspections.model';
import { StatisticsInspectionsService } from '../_services/statistics-inspections.service';
import { prepareDateForDb, convertDateToString, convertObjDateToString, prepareDateForForm } from '@app/_utils/date-util';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';

@Component({
  selector: 'app-statistics-inspections',
  templateUrl: './statistics-inspections.component.html',
  styleUrls: ['./statistics-inspections.component.css']
})
export class StatisticsInspectionsComponent extends BaseSortableSearchComponent<ListStatisticsInspections> implements OnInit, OnDestroy {
  // Units
  technicalPoints:                TechnicalPoint[];
  userTechnicalPointMobileAppIds: number[];
  dateObj                         = new Date();
  sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);

  // Booleans
  hasTechnicalPoint:  boolean;
  loadPdfOrExcel      = false;

  // Form
  searchForm = this.formBuilder.group({
    techPointMobileAppId:             [''],
    tp:                               null,
    dateFrom:                         prepareDateForForm(this.dateObj),
    dateTo:                           prepareDateForForm(this.dateObj),
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.statisticsInspectionsService.pageable(this.sortingPaging, this.searchForm.value, 
                                                          prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                          prepareDateForDb(this.searchForm.get('dateTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    })
  );

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    filter((input) => input.length > 3),
    switchMap(input =>
      this.subjectVersionService.filterSubjectVersions(input).pipe(
        catchError(() => of([]))
      )
    )
  );

  constructor(
    private formBuilder: FormBuilder,
    public  perms: PermissionsService,
    private translateService: TranslateService,
    private exportService: ExportService,
    private technicalPointService: TechnicalPointService,
    private subjectVersionService: SubjectVersionService,
    private statisticsInspectionsService: StatisticsInspectionsService
  ) {
    super();
    this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  async ngOnInit() {
    await this.loadTechnicalPoints();
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  private async loadTechnicalPoints() {
    try {
      if (this.userTechnicalPointMobileAppIds.length === 0) {
        const result = await this.technicalPointService.findTechnicalPoints().toPromise();
        this.technicalPoints = result;
        this.hasTechnicalPoint = false;
      } else {
        const result = await this.technicalPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise();
        this.hasTechnicalPoint = true;
        this.technicalPoints = result;
        this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
      }
    } catch (err) {
      displayErrorFromUnknown(err);
    }
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      techPointMobileAppId:                 [''],
      tp:                                   null,
      dateFrom:                             prepareDateForForm(this.dateObj),
      dateTo:                               prepareDateForForm(this.dateObj)
    });

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("references.statisticsInspections"), null);
    } catch(err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("references.statisticsInspections"), convertDateToString(new Date()));  
    } catch(err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];

    this.sortingPagingCopy.pageSize = this.sortingPaging.totalElements
    this.sortingPagingCopy.sortBy = this.sortingPaging.sortBy
    this.sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc
    let result = await this.statisticsInspectionsService.findAllByFilter(this.sortingPagingCopy, this.searchForm.value,
                                                                          prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                          prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                        ).pipe(first()).toPromise();
    
    result.content.forEach(object => {
       newContent?.push([object.name !== null ? object.name : object.subjectName, object.identNum !== null ? object.identNum : "-", object.count])
    })

    return newContent;
  }

  private getHeadings() : string[][] {
    return [[this.translateService.instant("col.name"), this.translateService.instant("col.bulstat"), this.translateService.instant("col.inspectionsCount")]]
  }

  private getFilterBody(): any[] {
    let tpName: string = "";
    this.technicalPoints.forEach(tp => {
      if (tp.mobileAppId == this.searchForm.get('techPointMobileAppId')?.value) {
        tpName = tp.shortName;
      }
    });
    
    let result: string[]= [
      tpName,
      convertObjDateToString(this.searchForm.get('dateFrom')?.value), 
      convertObjDateToString(this.searchForm.get('dateTo')?.value),
      this.searchForm.get('tp')?.value?.fullName
    ];

   return [result];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 4;
    let result: any[] = [
     this.translateService.instant('references.point'),
     this.translateService.instant('references.fromDate'),
     this.translateService.instant('references.toDate'),
     this.translateService.instant('references.partner')
   ];

   return [
     [{content: this.translateService.instant('references.statisticsInspections'), colSpan: colSpan, styles: {halign: 'center'}}],
     result
   ];
  }

  subjectVersionFormatter(element: any): string {
    return element.fullName;
  }

}