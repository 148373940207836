import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerCards } from '@app/card-config/_models/customer-cards.model';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { TechnicalInspectionService } from '@app/technical-inspections/_services/technical-inspection.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { first } from 'rxjs/operators';
import { ListTechnicalInspectionProgramCardCore } from '@app/technical-inspections/_models/list-technical-inspection-program-card-core.model';
import { displayError } from '@app/_utils/error-util';
import { formatDateFromArray } from '@app/_utils/date-util';
import { Status } from '@models/status.model';
import { NomenclatureService } from '@services/nomenclature.service';
import { StatusCode } from '@app/_enums/status-code';
import { UIEventCustom } from '@app/_utils/ui-event-util';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.css']
})
export class CardDetailComponent extends BaseSortableSearchComponent<CustomerCards> implements OnInit {
  // Units
  card: CustomerCards;
  cards: CustomerCards[];
  technicalInspections: ListTechnicalInspectionProgramCardCore[] = [];
  discountName: string = '';
  statuses: Status[] = [];
  selectedStatus: Status;

  // Booleans
  isSubmited        = false;
  loadData          = false;
  showComponent     = false;

  // Form
  cardForm: FormGroup = this.formBuilder.group({
    id:                 ['', [Validators.required]],
    cardNumber:         ['', [Validators.required]],
    cardBarcode:        ['', [Validators.required]],
    programMobileId:    ['', [Validators.required]],
    maxUsage:           [''],
    status:             [''],
    versionData:        [''],
    activationEmail:    [''],
    activationDate:     [''],
    isGenerated:        [''],
  });

  searchForm: FormGroup = this.formBuilder.group({
    loyaltyCardId:      [0]
  });

  constructor(
    private perms:                PermissionsService,
    protected router:             Router,
    private route:                ActivatedRoute,
    private formBuilder:          FormBuilder,
    private techInspService:      TechnicalInspectionService,
    private nomenclatureService:  NomenclatureService,
    private uiEvent:              UIEventCustom,
    private cardService:          CustomerCardService) { 
      super();
  }

  async ngOnInit() {
    this.showComponent = false;
    if (!this.perms.hasAccess(this.perms.CAN_SEARCH_LOYALTY_CARD)) {
      this.router.navigate(['/']);
    }
    this.reloadStatuses();
    await new Promise(f => setTimeout(f, 500));
    await this.reloadCard();
    this.showComponent = true;
  }

  onSubmit() {
    this.structCard();
    this.cardService.updateCard(this.card).toPromise().then(() => {
      this.uiEvent.displayUISuccessChange();
      if (this.card.isGenerated) {
        this.router.navigate(['search-card-loyal']);
      } else {
        this.router.navigate(['search-partner-card']);
      }
    })
    .catch(err => {
      this.uiEvent.displayUIError('Възникна грешка по време на обновяването');
      displayError(err);
    })
  }

  private structCard() {
    const values = this.cardForm.value as CustomerCards;
    this.card.maxUsage = values.maxUsage;
    this.card.status = values.status;
  }

  private reloadStatuses() {
    this.nomenclatureService.getStatusesByType(StatusCode.LoyaltyProgram).subscribe(data => {
      this.statuses = data;
    });
  }

  private async reloadCard() {
    const cardId = this.route.snapshot.queryParamMap.get('id');
    if (!cardId) return;

    await this.cardService.findCardById(Number.parseInt(cardId)).toPromise().then(async data => {
      this.card = data as CustomerCards;
      
      await this.loadDiscount();
      this.cardForm.setValue(this.card);
      this.cardForm.get('programMobileId')?.setValue(this.card.programMobileId.programName);
      this.selectedStatus = this.card.status;
      this.statuses = this.statuses.filter(status => status.name !== this.selectedStatus.name);
      this.statuses.push(this.selectedStatus)

      this.cardForm.get('activationDate')?.setValue(formatDateFromArray(this.card.activationDate));
      this.searchForm.get('loyaltyCardId')?.setValue(cardId);

	    await Promise.all([this.loadTechnicalInspections(), this.loadTotalElements()]);
      this.loadData = true;
    })
    .catch(err => {
      err.customMessage = 'Following card with id: ' + cardId + ' is not valid';
      displayError(err);
    })
  }

  loadTechnicalInspections() {
    this.techInspService.getPagableTechnicalInspectionProgramCard(this.sortingPaging, this.searchForm.value).pipe(first()).toPromise().then((resp: any) => {
      this.technicalInspections = resp.content as ListTechnicalInspectionProgramCardCore[];
      this.sortingPaging.fromRow = resp.fromRow;
      this.sortingPaging.toRow = resp.toRow;
    })
    .catch(err => displayError(err));
  }

  loadTotalElements() {
    this.techInspService.getTotalElementsWithoutDates(this.sortingPaging, this.searchForm.value).toPromise().then(resp => {
      this.sortingPaging.totalElements = resp;
    })
    .catch(err => displayError(err));
  }

  pageChanged(page: number) {
    this.sortingPaging.pageNumber = page;
    this.loadTechnicalInspections();
    this.loadTotalElements(); 
  }

  onSubmitSearchFormSort() {
    this.technicalInspections.sort((a, b) => a.technicalPointShortName > b.technicalPointShortName ? 1 : -1);
  }

  private async loadDiscount() {
    await this.cardService.getLoyalComboByCard(this.card.cardNumber).toPromise().then(data => {
      if (!data) return;
      if (!data.customerDiscount) return;
      this.discountName = data.customerDiscount.discountName;
    })
    .catch(err => displayError(err));
  }

  get getForm() {
    return this.cardForm.controls;
  }

}