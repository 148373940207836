export enum StatusCode {
    TechnicalPoint         = "TECHPNT",
    TechnicalPointLine     = "TPLINE",
    TechnicalPointService  = "TPSER" ,
    TechnicalInspection    = "TECHINSP",
    Contract               = "CONTRACT",
    PromotionDiscount      = "PROMO_DISCOUNT",
    PromotionPromocode     = "PROMO_PROMOCODE",
    PromotionInviteFriend  = "PROMO_INVFRIEND",
    Invoice                = "INVOICE",
    LoyaltyProgram         = "LOYALTY_PROGRAM",
    LoyaltyCards           = "LOYALTY_CARDS",
}