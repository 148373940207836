import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
 
  private apiServerUrl  = environment.apiBaseURL;
  private promotionsUrl = this.apiServerUrl + '/promotion';

  private findDiscountsByTechnicalInspectionURL = this.promotionsUrl + "/discounts"

  constructor(private http: HttpClient) { }

  public findDiscountsByTechnicalInspection(technicalInspection: any): Observable<any> {
    return this.http.post(this.findDiscountsByTechnicalInspectionURL, technicalInspection);
  }

}