import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Page } from '@interfaces/page';
import { FormGroup } from '@angular/forms';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { ListExpiringServices } from '../_models/list-expiring-services.model';
import { CustomerHttpParamsCodec } from '@helpers/custom-http-params-codec';

const expiringServicesURL = environment.apiBaseURL + '/expiring-services';

const pageableTotalElements         = expiringServicesURL + '/pageable/count';
const pageableExpiringServices      = expiringServicesURL + '/pageable';

@Injectable({
  providedIn: 'root'
})
export class ExpiringServicesService {
  
  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPagingData, searchForm: any, fromDate: string, toDate: string) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm, fromDate, toDate),
      this.countAllByFilter(sortingPaging, searchForm, fromDate, toDate)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<Page<ListExpiringServices>> {
    return this.http.get<Page<ListExpiringServices>>(pageableExpiringServices, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<number> {
    return this.http.get<number>(pageableTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string) {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });

    requestParams = this.filterSubjects(requestParams, searchForm, fromDate, toDate);

    return requestParams;
  }

  filterSubjects(requestParams: HttpParams, searchForm : any, fromDate: string, toDate: string){
    if (searchForm.phoneNumber != null) {
      requestParams = requestParams.set('phoneNumber', searchForm.phoneNumber);
    }

    if (searchForm.regNumber != null) {
      requestParams = requestParams.set('regNumber', searchForm.regNumber);
    }

    if (searchForm.invoiceNumber != null) {
      requestParams = requestParams.set('invoiceNumber', searchForm.invoiceNumber);
    }
    
    if (searchForm.service != null) {
      requestParams = requestParams.set('serviceId', searchForm.service.mobileAppId);
    }

    if(fromDate && fromDate.length !== 0) {
      requestParams = requestParams.set("validFrom", fromDate);
     }

    if(toDate && toDate.length !== 0) {
        requestParams = requestParams.set("validTo", toDate);
    }

    return requestParams;
  }

}
