import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
 
  public readonly CAN_VIEW_BACK_OFFICE_MENU                 = 'CAN_VIEW_BACK_OFFICE_MENU';
  public readonly CAN_SEARCH_CUSTOMERS                      = 'CAN_SEARCH_CUSTOMERS';
  public readonly CAN_VIEW_CUSTOMER                         = 'CAN_VIEW_CUSTOMER';
  public readonly CAN_VIEW_CORPORATE_CLIENTS_CONTRACT       = 'CAN_VIEW_CORPORATE_CLIENTS_CONTRACT';
  public readonly CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS    = 'CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS';
  public readonly CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT   = 'CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT';
  public readonly CAN_VIEW_PARTNER_CONTRACT                 = 'CAN_VIEW_PARTNER_CONTRACT';
  public readonly CAN_ADD_EDIT_PARTNER_CONTRACT             = 'CAN_ADD_EDIT_PARTNER_CONTRACT';
  public readonly CAN_SERACH_PARTNERS_CONTRACTS             = 'CAN_SERACH_PARTNERS_CONTRACTS';
  public readonly CAN_SEARCH_ARTICLES                       = 'CAN_SEARCH_ARTICLES';
  public readonly CAN_VIEW_ARTICLE                          = 'CAN_VIEW_ARTICLE';
  public readonly CAN_ADD_EDIT_ARTICLE                      = 'CAN_ADD_EDIT_ARTICLE';
  public readonly CAN_ADD_EDIT_SERVICE                      = 'CAN_ADD_EDIT_SERVICE';
  public readonly CAN_SEARCH_SERVICES                       = 'CAN_SEARCH_SERVICES';
  public readonly CAN_VIEW_SERVICE                          = 'CAN_VIEW_SERVICE';
  public readonly CAN_ADD_EDIT_TECHNICAL_POINT              = 'CAN_ADD_EDIT_TECHNICAL_POINT';
  public readonly CAN_SEARCH_TECHNICAL_POINTS               = 'CAN_SEARCH_TECHNICAL_POINTS';
  public readonly CAN_VIEW_TECHNICAL_POINT                  = 'CAN_VIEW_TECHNICAL_POINT';
  public readonly CAN_VIEW_TECHNICAL_CONTROL_POINT_MENU     = 'CAN_VIEW_TECHNICAL_CONTROL_POINT_MENU';
  public readonly CAN_ADD_EDIT_TECHNICAL_INSPECTION         = 'CAN_ADD_EDIT_TECHNICAL_INSPECTION';
  public readonly CAN_ADD_SUBSCRIPTION_CARD                 = 'CAN_ADD_SUBSCRIPTION_CARD';
  public readonly CAN_SEARCH_DAILY_SALES_REPORTS            = 'CAN_SEARCH_DAILY_SALES_REPORTS';
  public readonly CAN_VIEW_CRM_MENU                         = 'CAN_VIEW_CRM_MENU';
  public readonly CAN_VIEW_MARKETING_MENU                   = 'CAN_VIEW_MARKETING_MENU';         
  public readonly CAN_ADD_EDIT_PROMOTION                    = 'CAN_ADD_EDIT_PROMOTION';
  public readonly CAN_SEARCH_PROMOTIONS                     = 'CAN_SEARCH_PROMOTIONS';
  public readonly CAN_VIEW_PROMOTION                        = 'CAN_VIEW_PROMOTION';
  public readonly CAN_ADD_EDIT_PROMOCODE                    = 'CAN_ADD_EDIT_PROMOCODE';
  public readonly CAN_SEARCH_PROMOCODES                     = 'CAN_SEARCH_PROMOCODES';   
  public readonly CAN_VIEW_PROMOCODE                        = 'CAN_VIEW_PROMOCODE';
  public readonly CAN_ADD_EDIT_INVITE_FRIEND                = 'CAN_ADD_EDIT_INVITE_FRIEND';
  public readonly CAN_SEARCH_INVITE_FRIENDS                 = 'CAN_SEARCH_INVITE_FRIENDS';
  public readonly CAN_VIEW_INVITE_FRIEND                    = 'CAN_VIEW_INVITE_FRIEND';
  public readonly CAN_VIEW_CALL_CENTER_MENU                 = 'CAN_VIEW_CALL_CENTER_MENU';
  public readonly CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC      = 'CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC';
  public readonly CAN_VIEW_TECHNICAL_INSPECTION             = 'CAN_VIEW_TECHNICAL_INSPECTION';
  public readonly CAN_VIEW_PARTNERS_MENU                    = 'CAN_VIEW_PARTNERS_MENU';
  public readonly CAN_SEARCH_RESERVATIONS                   = 'CAN_SEARCH_RESERVATIONS';
  public readonly CAN_VIEW_FINANCES_MENU                    = 'CAN_VIEW_FINANCES_MENU';
  public readonly CAN_SEARCH_ACCOUNTING_DOCUMENT            = 'CAN_SEARCH_ACCOUNTING_DOCUMENT';
  public readonly CAN_ADD_EDIT_ACCOUNTING_DOCUMENT          = 'CAN_ADD_EDIT_ACCOUNTING_DOCUMENT';
  public readonly CAN_VIEW_ACCOUNTING_DOCUMENT              = 'CAN_VIEW_ACCOUNTING_DOCUMENT';
  public readonly CAN_VIEW_TECHNICAL_INSPECTION_CC          = 'CAN_VIEW_TECHNICAL_INSPECTION_CC';
  public readonly CAN_VIEW_ADMIN_MENU                       = 'CAN_VIEW_ADMIN_MENU';
  public readonly CAN_VIEW_REFERENCES_MENU                  = 'CAN_VIEW_REFERENCES_MENU';
  public readonly CAN_VIEW_TECH_POINT_SLOTS_REFERENCE       = 'CAN_VIEW_TECH_POINT_SLOTS_REFERENCE';
  public readonly CAN_VIEW_INCOME_BY_SERVICES               = 'CAN_VIEW_INCOME_BY_SERVICES';
  public readonly CAN_VIEW_INCOME_BY_STICKERS               = 'CAN_VIEW_INCOME_BY_STICKERS';
  public readonly CAN_VIEW_LOYALTY_PROGRAM_MENU             = 'CAN_VIEW_LOYALTY_PROGRAM_MENU';
  public readonly CAN_SEARCH_LOYALTY_PROGRAM                = 'CAN_SEARCH_LOYALTY_PROGRAM';
  public readonly CAN_ADD_EDIT_LOYALTY_PROGRAM              = 'CAN_ADD_EDIT_LOYALTY_PROGRAM';
  public readonly CAN_SEARCH_LOYALTY_CARD                   = 'CAN_SEARCH_LOYALTY_CARD';
  public readonly CAN_ADD_EDIT_LOYALTY_CARD                 = 'CAN_ADD_EDIT_LOYALTY_CARD';
  public readonly CAN_DOWNLOAD_RESERVATION_REPORTS          = 'CAN_DOWNLOAD_RESERVATION_REPORTS';
  public readonly CAN_DOWNLOAD_DAILY_CAR_REPORTS            = 'CAN_DOWNLOAD_DAILY_CAR_REPORTS';
  public readonly CAN_DOWNLOAD_EXPIRING_INSPECTIONS_REPORTS = 'CAN_DOWNLOAD_EXPIRING_INSPECTIONS_REPORTS';
  public readonly CAN_VIEW_AND_PAY_ACCOUNTING_DOCUMENT      = 'CAN_VIEW_AND_PAY_ACCOUNTING_DOCUMENT';
  public readonly CAN_ANNUL_TECHNICAL_INSPECTION            = 'CAN_ANNUL_TECHNICAL_INSPECTION';
 
  private initialized: boolean;
  private perms: string[];

  constructor() {
    this.initialized = false;
    this.perms = [];
  }

  public isLoggedIn(): boolean {
    let loggedIn = AuthenticationService.getAccessToken() != null;
    if (loggedIn) {
      if (!this.initialized) {
        this.init();
      }
    } else {
      this.clear();
    }
    
    return loggedIn;
  }

  public hasAccess(...perms: string[]): boolean {
    if (this.isLoggedIn()) {
      let access = false;
      for (let p of perms) {
        for (let dp of this.perms) {
          if (p == dp) {
            access = true;
            break;
          }
        }

        if (access) {
          break;
        }
      }
      return access;
    }

    return false;
  }

  private init(): void {
    this.initialized = true;
    this.perms = AuthenticationService.getDecodedAccessToken().permissions;
  }

  private clear(): void {
    this.initialized = false;
    this.perms = [];
  }

}
