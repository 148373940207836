<ng-container *ngIf="showComponent">
    <div class="container-fluid py-3" style="padding-left:30rem !important;padding-right:30rem !important">
        <div>
            <h1 class="form-group title-position" translate>users.addUser</h1>
            <form [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="loadUser$ | async as data; else loading;">
                <!-- Data -->
                <div class="card">
                    <div class="card-body">
                        <!-- username -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label translate>users.username</label>
                                <input type="text" class="form-control" formControlName="username"
                                    [ngClass]="{'is-invalid': showValidations && form.username.errors }"
                                    placeholder="{{'placeholder.enter' | translate}}" />
                                <small class="text-danger" *ngIf="form.username?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                                <small class="text-danger" *ngIf="form.username?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:50} }}
                                </small>
                            </div>
                        </div>

                        <!-- email -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label translate>users.email</label>
                                <input type="text" class="form-control" formControlName="email"
                                    [ngClass]="{'is-invalid': showValidations && form.email.errors }"
                                    placeholder="{{'placeholder.enter' | translate}}" />
                                <small class="text-danger" *ngIf="form.email?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                                <small class="text-danger" *ngIf="form.email?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:50} }}
                                </small>
                                <small class="text-danger" *ngIf="form.email?.errors?.email && showValidations" translate>
                                    errors.invalidMail
                                </small>
                            </div>
                        </div>

                        <!-- full name -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label translate>users.fullName</label>
                                <input type="text" class="form-control" formControlName="fullName"
                                    [ngClass]="{'is-invalid': showValidations && form.fullName.errors }"
                                    placeholder="{{'placeholder.enter' | translate}}" />
                                <small class="text-danger" *ngIf="form.fullName?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                                <small class="text-danger" *ngIf="form.fullName?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:128} }}
                                </small>
                            </div>
                        </div>

                        <!-- status -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label translate>users.enabled</label>
                                <select class="custom-select input-custom-size" formControlName="enabled">
                                    <option *ngFor="let option of yesNoOptions" [value]="option.code">
                                        {{option.name}}
                                </select>
                            </div>
                        </div>

                        <!-- employeer -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label translate>users.employer</label>
                                <input id="employerTypeahead" type="text" class="form-control" [editable]='false'
                                    formControlName="employer" [ngbTypeahead]="subjectVersionsSearch"
                                    [ngClass]="{'is-invalid': showValidations && form.employer.errors }"
                                    [inputFormatter]="subjectVersionFormatter" placeholder="{{'placeholder.enter' | translate}}"
                                    [resultTemplate]="ert"/>
                                <ng-template #ert let-r="result" let-t="term">
                                    <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                                </ng-template>
                                <small class="text-danger" *ngIf="form.employer?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                            </div>
                        </div>

                        <!-- role -->
                        <div class="form-group form-row">
                            <div class="col">
                                <label translate>users.role</label>
                                <select class="custom-select input-custom-size" formControlName="role"
                                        [ngClass]="{'is-invalid': showValidations && form.role.errors }">
                                    <option [ngValue]="null" disabled translate>placeholder.select</option>
                                    <option *ngFor="let r of roles" [ngValue]="r">
                                        {{r.description}}
                                    </option>
                                </select>
                                <small class="text-danger" *ngIf="form.role?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                            </div>
                        </div>

                        <!-- tech points -->
                        <ng-container *ngIf="showTechPointSection">
                            <div class="d-flex flex-row">
                                <h4 class="mt-2" translate>users.techPoints</h4>
                                <small class="text-red" *ngIf="showValidations">new</small>
                            </div>
                            <small *ngIf="form.employer?.value == null" translate>errors.noEmployerSelected</small>
                            <div class="d-flex flex-column" formArrayName="techPoints">
                                <ng-container *ngFor="let techPoint of techPointsForm.controls;let i=index" [formGroupName]="i">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" formControlName="isValid" (click)="onTechPointCheck($event, i)">
                                        <label class="form-check-label">
                                            {{techPoint.get('name')?.value}}
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- Save & change password buttons -->
                <div class="d-flex flex-row-reverse mt-2" *ngIf="!isSaving; else saveLoading">
                    <button class="btn btn-primary ml-2" type="button" (click)="onSubmit()" [attr.disabled]="isUnchanged" translate>button.save</button>
                    <button class="btn btn-primary" type="button" (click)="openChangePasswordModal()" translate>userProfile.changePassword</button>
                </div>

                <ng-template #saveLoading>
                    <div class="d-flex flex-row-reverse mt-2">
                        <div class="spinner-border mr-5" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </ng-template>
            </form>

            <ng-template #loading>
                <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                    <div class="alert alert-danger">
                        {{errorMessage}}
                    </div>
                    <div class="d-flex justify-content-center">
                        <em class="fa fa-refresh" (click)="reload()"></em>
                    </div>
                </div>
                <ng-template #spinner>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #changePassword>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.confirm</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;"><p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                 translate>
            no
        </button>
            <button mat-button [mat-dialog-close]="true" class="ml-2 mr-2 mb-1 btn btn btn-danger" type="button" 
                translate>
                button.delete
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>