<ng-container *ngIf="loadSubjectTypes$ | async as data; else loading">
  <!-- subjectVersionMainForm -->
  <form [formGroup]="subjectVersionMainForm">
    <div class="card-header" style="margin-bottom: 10px;">
      <h5 class="style" style="text-align:left; margin-top: 1px; margin-bottom: 1px;" translate>registerPerson.data</h5>
    </div>
    <div class="row">
      <!-- name -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.name</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="fullName" #fullName id="fullName" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.fullName.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.fullName.errors?.required && showValidations" translate>errors.required</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.fullName.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
        </div>
      </div>

      <!-- personalUniqueNumber -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.personalUniqueNumber</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="identNum" id="identNum" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.identNum.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.identNum.errors?.required && showValidations" translate>errors.required</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.identNum.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 15} }}</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.identNum.errors?.pattern && showValidations" translate>errors.onlyDigits</div>
        </div>
      </div>

      <!-- address -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.address</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="address" id="address"
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.address.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.address.errors?.required && showValidations" translate>errors.required</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.address.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
        </div>
      </div>

      <!-- manager -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.manager</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="manager" id="manager"
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.manager.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.manager.errors?.required && showValidations" translate>errors.required</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.manager.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.manager.errors?.pattern && showValidations" translate>errors.onlyLetters</div>
        </div>
      </div>

      <!-- type -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.type</label>
        <div class="col-sm-12">
          <select class="custom-select input-custom-size" formControlName="type" [compareWith]="compareById" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.type?.errors }" >
            <option *ngFor="let subjectType of subjectTypes" [ngValue]="subjectType">
              {{subjectType.description}}
            </option>
          </select>  
          <div class="invalid-feedback" *ngIf="subjectVersionForm.type.errors?.required && showValidations" translate>errors.required</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.type.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
        </div>
      </div>

      <!-- city -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.city</label>
        <div class="col-sm-12">
          <select class="custom-select input-custom-size" formControlName="city" [compareWith]="compareByCode"
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.city.errors }">
            <option *ngFor="let city of cities" [ngValue]="city"> 
              {{city.name}}
            </option>
          </select>  
          <div class="invalid-feedback" *ngIf="subjectVersionForm.city.errors?.required && showValidations" translate>errors.required</div>
        </div>
      </div>
    </div>

    <div class="card-header" style="margin-bottom: 10px;">
      <h5 class="style" style="text-align:left; margin-top: 1px; margin-bottom: 1px;" translate>registerPerson.invoiceData</h5>
    </div>

    <div class="row">
      <!-- name -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.name</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="invoiceFullName" id="invoiceFullName" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.invoiceFullName.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceFullName.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
        </div>
      </div>
      <!-- personalUniqueNumber -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.personalUniqueNumber</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="invoiceIdentNum" id="invoiceIdentNum" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.invoiceIdentNum.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceIdentNum.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 15} }}</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceIdentNum.errors?.pattern && showValidations" translate>errors.onlyDigits</div>
        </div>
      </div>

      <!-- vatNumber -->
      <div class="form-group">
        <label class="col-sm-5 col-form-label" translate>registerPerson.vatNumber</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="invoiceDdsRegistration" id="invoiceDdsRegistration" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.invoiceDdsRegistration.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceDdsRegistration.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 15} }}</div>
        </div>
      </div>

      <!-- invoiceManager -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.invoiceManager</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="invoiceManager" id="invoiceManager" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.invoiceManager.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceManager.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceManager.errors?.pattern && showValidations" translate>errors.onlyLetters</div>
        </div>
      </div>

      <!-- address -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.address</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="invoiceFullAddress" id="invoiceFullAddress" 
            [ngClass]="{ 'is-invalid': showValidations && subjectVersionForm.invoiceFullAddress.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="subjectVersionForm.invoiceFullAddress.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
        </div>
      </div>
    </div>
  </form>
  
  <!-- subjectVersionBankAccountForm -->
  <form [formGroup]="subjectVersionBankAccountForm">
    <div class="card-header" style="margin-bottom: 10px;">
      <h5 class="style" style="text-align:left; margin-top: 1px; margin-bottom: 1px;" translate>registerPerson.bankAccounts</h5>
    </div>

    <div class="row">
      <!-- bank -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.bank</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="bankName" id="bank"
            [ngClass]="{ 'is-invalid': showValidations && bankAccountForm.bankName.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="bankAccountForm.bankName.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
          <div class="invalid-feedback" *ngIf="bankAccountForm.bankName.errors?.pattern && showValidations" translate>errors.onlyLetters</div>
        </div>
      </div>

      <!-- iban -->
      <div class="form-group">
          <label class="col-sm-3 col-form-label" translate>registerPerson.iban</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" formControlName="iban" id="iban"
              [ngClass]="{ 'is-invalid': showValidations && bankAccountForm.iban.errors }" placeholder="{{'placeholder.enter' | translate}}">
            <div class="invalid-feedback" *ngIf="bankAccountForm.iban.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 35} }}</div>
            <div class="invalid-feedback" *ngIf="bankAccountForm.iban.errors?.pattern && showValidations" translate>errors.onlyLettersAndDigits</div>
          </div>
      </div>

      <!-- bic -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.bic</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="bic" id="bic"
            [ngClass]="{ 'is-invalid': showValidations && bankAccountForm.bic.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="bankAccountForm.bic.errors?.pattern && showValidations" translate>errors.onlyCapitalLatinLetters</div>
          <div class="invalid-feedback" *ngIf="bankAccountForm.bic.errors?.invalidBic && showValidations" translate>errors.invalidBic</div>
        </div>
      </div>

      <!-- description -->
      <div class="form-group">
        <label class="col-sm-3 col-form-label" translate>registerPerson.description</label>
        <div class="col-sm-12">
          <input type="text" class="form-control" formControlName="description" id="description" 
            [ngClass]="{ 'is-invalid': showValidations && bankAccountForm.description.errors }" placeholder="{{'placeholder.enter' | translate}}">
          <div class="invalid-feedback" *ngIf="bankAccountForm.description.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
        </div>
      </div>
    </div>
  </form>
  
  <!-- action buttons -->
  <div style="text-align: center; margin-top: 30px;">
    <button *ngIf="editState && addSection == false" type="button" (click) ="clear()" class="btn btn-primary" translate>button.new</button>
    <button type="button" class="btn btn-primary" style="margin-left: 20px" (click)="save()" translate>button.save</button>
    <button type="button" class="btn btn-primary" style="margin-left: 20px" (click)="selectSubject()" translate>button.choose</button>
  </div>
</ng-container>

<ng-template #loading>
  <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
    <div class="alert alert-danger">
      {{errorMessage}}
    </div>
    <div class="d-flex justify-content-center">
      <i class="fa fa-refresh" (click)="reload()"></i>
    </div>
  </div>
  <ng-template #spinner>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
  <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
    <div class="alert alert-danger">
      {{errorMessage}}
    </div>
    <div class="d-flex justify-content-center">
      <i class="fa fa-refresh" (click)="reload()"></i>
    </div>
  </div>
  <ng-template #dataSpinner>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</ng-template>