import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { CustomerService } from '@app/customers/_services/customer.service';
import { EMPTY } from 'rxjs';
import { CustomerInvoiceDetails } from '@app/customers/_models/customer-invoice-details.model';

@Component({
  selector: 'app-client-profile-invoice-data',
  templateUrl: './client-profile-invoice-data.component.html',
  styleUrls: ['./client-profile-invoice-data.component.css']
})
export class ClientProfileInvoiceDataComponent extends BaseChildComponent implements OnInit {
  customerInvoiceDetails: CustomerInvoiceDetails;

  constructor(
    private customerService: CustomerService,
    private translateService: TranslateService
  ) {
    super();
   }

  ngOnInit(): void { }

  initialize() {
    if (this.isInitialized) return;

    this.customerService.findInvoiceDetailsByCustomerId(this.parentId).pipe(
      tap(data => this.customerInvoiceDetails = data),
      catchError(err => {
        console.log(err);
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true})
  }
}
