import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContractRvs } from "@app/contracts/_models/contract-rvs.model";
import { ContractService } from "@app/contracts/_services/contract.service";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { ArrayValidators } from "@app/_validators/array.validator";
import { config } from "@env/config";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { UtilValidators } from "@app/_validators/util.validators";

@Component({
    selector: 'contract-rvs',
    templateUrl: './contract-rvs.component.html',
    styleUrls: ['./contract-rvs.component.css']
})

export class ContractRvsComponent extends BaseChildComponent implements OnInit, OnDestroy {
    // Units
    contractRvs: ContractRvs[];

    // Form
    contractRvsForm: FormGroup;

    // Decorators
    @Input() contractId: number;
    @Input() module: string;

    constructor(private formBuilder: FormBuilder,
        private perms: PermissionsService,
        private contractService: ContractService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.contractRvsForm = this.formBuilder.group({
            rows: this.formBuilder.array([], [ArrayValidators.equalsToSomeGroupKey('isValid', true)])
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    async getContractRvs() {
        if (this.isInitialized) return;
        this.isInitialized = true;

        if (!!this.contractId) {
            const response = await this.contractService.findContractRvs(this.contractId).toPromise();
            this.contractRvs = response;
            
            this.contractRvsForm = this.formBuilder.group({
                rows: this.formBuilder.array(
                    this.contractRvs.map(x =>
                        this.formBuilder.group({
                            id:                     x.id,
                            regNumber:              [x.regNumber, [Validators.required, Validators.maxLength(8), Validators.pattern(config.regexRegNumber)]],
                            registrationCertNum:    [x.registrationCertNum, [Validators.required, Validators.maxLength(9), Validators.pattern(config.regexOnlyLatinSymbolsAndDigits)]],
                            phoneNumber:            [x.phoneNumber, [Validators.required, Validators.maxLength(16), UtilValidators.validatePhoneNumber()]],
                            isValid:                x.isValid
                        })
                    ), [ArrayValidators.equalsToSomeGroupKey('isValid', true)]
                )
            });
        }
    }

    addRow() {
        let contractServicesCtrl = <FormArray>this.contractRvsForm.controls['rows'];
        contractServicesCtrl.push(this.formBuilder.group({
            id:                     null,
            regNumber:              ['', [Validators.required, Validators.maxLength(8), Validators.pattern(config.regexRegNumber)]],
            registrationCertNum:    ['', [Validators.required, Validators.maxLength(9), Validators.pattern(config.regexOnlyLatinSymbolsAndDigits)]],
            phoneNumber:            ['', [Validators.required, Validators.maxLength(16), Validators.pattern(config.regexPhonePlusAndDigits)]],
            isValid:                true
        }));
    }

    deleteRow(index: number) {
        let rowsCtrl = <FormArray>this.contractRvsForm.controls['rows']
        let element = rowsCtrl.at(index).value
        element.isValid = false;
        rowsCtrl.at(index).patchValue(element);
        rowsCtrl.at(index).get('regNumber')?.clearValidators();
        rowsCtrl.at(index).get('registrationCertNum')?.clearValidators();
        rowsCtrl.at(index).get('phoneNumber')?.clearValidators();
        rowsCtrl.at(index).get('regNumber')?.updateValueAndValidity();
        rowsCtrl.at(index).get('registrationCertNum')?.updateValueAndValidity();
        rowsCtrl.at(index).get('phoneNumber')?.updateValueAndValidity();

    }
    
    // isValid(): boolean {
    //     if (this.isInitialized) {
    //         return this.contractRvsForm.valid
    //     }
    //     return true;
    // }

    get rows() {
        return this.contractRvsForm.get('rows') as FormArray;
    }

    get canAddEdit() {
        switch(this.module) {
          case 'client': 
            return this.perms.hasAccess(this.perms.CAN_VIEW_PARTNER_CONTRACT);
          case 'pertner': 
            return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PARTNER_CONTRACT);
          default: 
            return false;
        }
    }

}