<div class="container mainbox">
    <div>
        <h1 class="form-group title-position" translate>page-management.create</h1>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()" *ngIf="siteManagement$ | async as data; else loading">
            <div class="card">
              <div class="card-body">
                <div class="form-group form-row">
                  <div class="col">
                    <label translate>page-management.pageNum</label>
                    <input type="number" min="0" max="50" class="form-control" formControlName="pageNumber" [ngClass]="{ 'is-invalid': showValidations && mainForm.pageNumber.errors }"
                      placeholder="{{'placeholder.enter' | translate}}">
                    <div class="invalid-feedback" *ngIf="mainForm.pageNumber?.errors?.required && showValidations" translate>
                        errors.required
                    </div>
                  </div>
          
                  <div class="col">
                    <div class="form-group form-row">
                      <div class="col">
                        <label translate>page-management.status</label>
                        <select class="custom-select input-custom-size" aria-describedby="isActive" formControlName="isActive">
                          <option value="true" translate>yes</option>
                          <option value="false" translate>no</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group form-row">
                  <div class="col">
                    <label translate>page-management.showAddMiliseconds</label>
                    <input type="number" min="0" max="50" class="form-control" formControlName="showAddMiliseconds" [ngClass]="{ 'is-invalid': showValidations && mainForm.showAddMiliseconds.errors }"
                      placeholder="{{'placeholder.enter' | translate}}">
                    <div class="invalid-feedback" *ngIf="mainForm.showAddMiliseconds?.errors?.required && showValidations" translate>
                        errors.required
                    </div>
                  </div>   
                  <div class="col">
                    <label translate>page-management.uploadBanner</label>
                    <div></div>
                    <input type="file" formControlName="file" accept="image/png, image/jpeg, image/gif" class="file-input" maxFileSize="5000000" (change)="onFileSelected($event)"/>
                    <span class="invalid-format" *ngIf="invalidFileType" translate>Невалиден файл формат</span>
                    <div class="invalid-feedback" *ngIf="mainForm.file.errors?.required && showValidations" translate>
                        errors.required
                    </div>  
                  </div>
                </div>

                <div class="form-group form-row">
                    <div class="col" style="max-width: 500pt;">
                      <mat-form-field class="mat-form-field">
                          <mat-label translate style="font-size: 1rem; color: #333;">page-management.pleaseChooseaTechPoints</mat-label>
                          <mat-select multiple formControlName="techPointNames">
                              <mat-option *ngFor="let technicalPoint of technicalPoints" [value]="technicalPoint.shortName">
                                  {{ technicalPoint.shortName }}
                              </mat-option>
                          </mat-select>
                          <small class="text-danger" *ngIf="mainForm.discountTypes?.errors?.required && showValidations" translate>
                              errors.required
                          </small>
                      </mat-form-field>
                  </div>
                </div>

                <br>
                <div class="form-group form-row">
                  <div class="col">
                    <img height="400" width="1050" [src]="imageUrl" alt="Selected Image" *ngIf="imageUrl" />
                  </div>
                </div>
              </div>
            </div>
            <!-- test -->
            <div class="text-right">
              <button class="btn btn-primary w-20 mt-2" type="submit" translate>button.save</button>
            </div>
          </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <i class="fa fa-refresh" (click)="reload()"></i>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>