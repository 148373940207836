import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CustomerDiscount } from '@models/customer-discount.model';
import { defaultIfEmpty, shareReplay } from 'rxjs/operators';
import { Page } from '@interfaces/page';
import { FormGroup } from '@angular/forms';
import { ListContract } from '../_models/list-contract.model';
import { Contract } from '../_models/contract.model';
import { ContractTechnicalPoint } from '../_models/contract-technical-point.model';
import { ContractTechnicalPointService } from '../_models/contract-technical-point-service.model';
import { ContractTechnicalPointLine } from '../_models/contract-technical-point-line.model';
import { ContractRvs } from '../_models/contract-rvs.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';

const contractURL = environment.apiBaseURL + '/contract';

const findByIdURL                 = contractURL + '/find/{id}';
const saveURL                     = contractURL + '/save';
const listCustomerDiscountsURL    = contractURL + '/list-customer-discounts';
const listTechnicalPointsURL      = contractURL + '/{id}/list-technical-points';
const listTechPointServicesURL    = contractURL + '/{id}/list-technical-point-services';
const listTechnicalPointLinesURL  = contractURL + '/{id}/list-technical-point-lines';
const listRvsURL                  = contractURL + '/{id}/list-rvs';
const pageableTotalElements       = contractURL + '/pageable/count';
const pageableContracts           = contractURL + '/pageable';
const findDiscountByPointAndSubjectIdURL  = contractURL + '/discount-by-point-and-subject';

@Injectable({
  providedIn: 'root'
})
export class ContractService {


  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm),
      this.countAllByFilter(sortingPaging, searchForm)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup): Observable<Page<ListContract>> {
    return this.http.get<Page<ListContract>>(pageableContracts, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup): Observable<number> {
    return this.http.get<number>(pageableTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
  }

  public findContractById(id: number): Observable<Contract> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<Contract>(findByIdURL.replace('{id}', `${id}`));
  }
  
  public findContractDiscountBySubjectId(subjectMobileId: number | undefined, techPointMobileAppId: number, 
                                         serviceMobileAppId: number, categoryId: number): Observable<CustomerDiscount[]> {
    if (subjectMobileId === undefined || subjectMobileId === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }

    let requestParams = new HttpParams();
    if (subjectMobileId !== undefined && subjectMobileId !== null) {
        requestParams = requestParams.set("subjectMobileId", subjectMobileId.toString());
    }
    if (techPointMobileAppId !== undefined && techPointMobileAppId !== null) {
        requestParams = requestParams.set("technicalMobileId", techPointMobileAppId.toString());
    }
    if (serviceMobileAppId !== undefined && serviceMobileAppId !== null) {
        requestParams = requestParams.set("serviceMobileId", serviceMobileAppId.toString());
    }
    if (categoryId !== undefined && categoryId !== null) {
        requestParams = requestParams.set("categoryId", categoryId.toString());
    }
    
    return this.http.get<CustomerDiscount[]>(findDiscountByPointAndSubjectIdURL, {params: requestParams}).pipe(shareReplay());
  }

  public saveContract(contract: Contract): Observable<any> {
    return this.http.post(saveURL, contract);
  }

  public findDiscountList(): Observable<CustomerDiscount[]> {
    return this.http.get<CustomerDiscount[]>(listCustomerDiscountsURL);
  }

  public findTechPointsByContractId(id: number): Observable<ContractTechnicalPoint[]> {
    return this.http.get<ContractTechnicalPoint[]>(listTechnicalPointsURL.replace('{id}', `${id}`));
  }

  public findContractTechnicalPointServices(id: number): Observable<ContractTechnicalPointService[]> {
    return this.http.get<ContractTechnicalPointService[]>(listTechPointServicesURL.replace('{id}', `${id}`));
  }

  public findContractTechnicalPointLines(id: number): Observable<ContractTechnicalPointLine[]> {
    return this.http.get<ContractTechnicalPointLine[]>(listTechnicalPointLinesURL.replace('{id}', `${id}`));
  }

  public findContractRvs(id: number): Observable<ContractRvs[]> {
    return this.http.get<ContractRvs[]>(listRvsURL.replace('{id}', `${id}`));
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });
    requestParams = this.filterSubjects(requestParams, searchForm);

    return requestParams;
  }

  filterSubjects(requestParams: HttpParams, searchForm : any){
      if (searchForm.ownerSubjectVersion != null) {
      if (searchForm.ownerSubjectVersion.id != null) {
        requestParams = requestParams.set('ownerSubjectVersionId', searchForm.ownerSubjectVersion.id!);
      } else {
        requestParams = requestParams.set('ownerSubjectVersionName', searchForm.ownerSubjectVersion);
      }
    }

    if (searchForm.contractSubjectVersion != null) {
      if (searchForm.contractSubjectVersion.id != null) {
        requestParams = requestParams.set('contractSubjectVersionId', searchForm.contractSubjectVersion.id!);
      } else {
        requestParams = requestParams.set('contractSubjectVersionName', searchForm.contractSubjectVersion);
      }
    }
    return requestParams;
  }
}
