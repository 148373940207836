<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 translate>references.dailyReport</h3>
        </span>
    </div>
    <div class="my-2 py-2">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- point -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>references.point</span>
                </div>
                <select class="custom-select" aria-describedby="techPointMobileAppId" formControlName="techPointMobileAppId"  style="max-width: 250pt;">
                    <option *ngIf="!hasTechnicalPoint" value="" translate>placeholder.select</option>
                    <option *ngFor="let p of technicalPoints" [value]=p.mobileAppId>
                        {{p.shortName}}
                    </option>
                </select>

                <!-- date from -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="dateFrom" translate>technicalInspection.startDate</span>
                </div>
                <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="dateFrom" 
                       formControlName="dateFrom" ngbDatepicker #d1="ngbDatepicker" readonly>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                </div>
    
                <!-- date to -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="dateTo" translate>technicalInspection.endDate</span>
                </div>
                <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="dateTo" 
                       formControlName="dateTo" ngbDatepicker #d2="ngbDatepicker" readonly>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                </div>

                <!-- reg number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>references.regNumber</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitRegNumber">
                    <input type="text" class="form-control" formControlName="regNumber" (keyup.enter)="search()" style='text-transform:uppercase' style="max-width: 250pt;">
                </ng-container>
                <ng-template #pleaseWaitRegNumber>
                    <input type="text" class="form-control" formControlName="regNumber" style='text-transform:uppercase' style="max-width: 250pt;">
                </ng-template>


            </div>
            <div class="input-group mb-2">
                <!-- service type -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>references.serviceType</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitService">
                    <select class="custom-select" aria-describedby="serviceType" formControlName="serviceType" style="max-width: 300pt;" (change)='loadServices()'>
                        <option *ngIf="!serviceTypes" value="" translate>placeholder.select</option>
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let s of serviceTypes" [value]=s.id>
                            {{ s.description }}
                        </option>
                    </select>
                </ng-container>
                <ng-template #pleaseWaitService>
                    <select class="custom-select" aria-describedby="serviceType" formControlName="serviceType" style="max-width: 300pt;">
                        <option *ngIf="!serviceTypes" value="" translate>placeholder.select</option>
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let s of serviceTypes" [value]=s.id>
                            {{ s.description }}
                        </option>
                    </select>
                </ng-template>

                <!-- service -->
                <div class="input-group-prepend ml-2" >
                    <span class="input-group-text" translate>references.service</span>
                </div>
                <select class="custom-select" aria-describedby="serviceName" formControlName="serviceName" style="max-width: 300pt;">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let s of services" [value]=s.name>
                        {{ s.name }}
                    </option>
                </select>

                <!-- actions -->
                <div class="input-group-append ml-2 action-buttons">
                    <button class="btn btn-outline-success" type="button" (click)="search()" [disabled]="loadPdfOrExcel" translate>button.search</button>
                    <button class="btn btn-outline-danger ml-2" type="button" (click)="clearSearch()" [disabled]="loadPdfOrExcel" translate>button.clear</button>
                    <ng-container *ngIf="!loadPdfOrExcel;else pleaseWait">
                        <i class="fa fa-file-excel-o fa-2x ml-2 mt-1"  data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" (click)="exportExcel()"></i>
                        <i class="fa fa-file-pdf-o fa-2x ml-2 mt-1"  data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" (click)="exportPDF()"></i>
                    </ng-container>
                    <ng-template #pleaseWait>
                        <span class="pleaseWait" translate>placeholder.wait</span>
                    </ng-template>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class=" d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                        <tr>
                            <th scope="col" translate>references.date</th>
                            <th scope="col" translate>references.point</th>
                            <th scope="col" translate>references.service</th>
                            <th scope="col" translate>references.paymentMethod</th>
                            <th scope="col" translate>references.discount</th>
                            <th scope="col" translate>references.pointTechInsp</th>
                            <th scope="col" translate>references.unitPriceWithVatAfterDiscount</th>
                            <th scope="col" translate>references.totalPrice</th>
                            <th scope="col" translate>references.receptionChannel</th>
                            <th scope="col" translate>references.registrationNumbers</th>
                        </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{item.inspectionDate}}</td>
                        <td>{{item.techPointShortName}}</td>
                        <td>{{item.serviceName}}</td>
                        <td>{{item.paymentType}}</td>
                        <td>{{item.discountName}}</td>
                        <td>{{item.serviceCount}}</td>
                        <td>{{item.techPointDiscountAmount}}</td>
                        <td>{{item.totalAmountDds}}</td>
                        <td>{{item.incomingWay}}</td>
                        <td>{{item.regNumbers}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between action-pagination">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)" [disabled]="loadPdfOrExcel">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)" [disabled]="loadPdfOrExcel">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>