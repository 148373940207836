<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 translate>menu.expiringServices.search</h3>
        </span>
    </div>
    <div class="my-2 py-2">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- date from -->
                <div class="input-group-prepend">
                    <span class="input-group-text" id="validFrom" translate>technicalInspection.startDate</span>
                </div>
                <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="validFrom" 
                    formControlName="validFrom" ngbDatepicker #d1="ngbDatepicker" readonly>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                </div>
        
                <!-- date to -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="validTo" translate>technicalInspection.endDate</span>
                </div>
                <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="validTo" 
                    formControlName="validTo" ngbDatepicker #d2="ngbDatepicker" readonly>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                </div>

                <!-- <div class="input-group-prepend ml-1" style="width:14%">
                        <div class="input-group-prepend">
                            <div class="input-group-text" translate> references.service</div>
                        </div>
                        <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="service">
                            <option translate>placeholder.select</option>
                            <option *ngFor="let service of services" [ngValue]="service"> {{service.name}}
                        </select>
                </div> -->

                <!-- phone number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>references.phoneNumber</span>
                </div>
                <input type="text" class="form-control" formControlName="phoneNumber">

                <!-- reg number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>references.regNumber</span>
                </div>
                <input type="text" class="form-control" formControlName="regNumber">

                <!-- <div class="input-group-prepend ml-1"><span class="input-group-text" 
                    translate>references.invoiceNumber</span></div>
                <input type="text" class="form-control" formControlName="invoiceNumber">

                <div class="input-group-prepend ml-1"><span class="input-group-text" 
                    translate>references.identNumber</span></div>
                <input type="text" class="form-control" formControlName="identNumber"> -->

                <!-- actions -->
                <div class="input-group-append ml-1">
                    <button class="btn btn-outline-success" type="button" (click)="search()" [disabled]="loadPdfOrExcel" translate>button.search</button>
                    <button class="btn btn-outline-danger ml-1" type="button" (click)="clearSearch()" [disabled]="loadPdfOrExcel" translate>button.clear</button>
                    <ng-container *ngIf="!loadPdfOrExcel;else pleaseWait">
                        <ng-container *ngIf="perms.hasAccess(perms.CAN_DOWNLOAD_EXPIRING_INSPECTIONS_REPORTS)">
                            <i class="fa fa-file-excel-o fa-2x ml-2 mt-1" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" (click)="exportExcel()"></i>
                            <i class="fa fa-file-pdf-o fa-2x ml-2 mt-1" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" (click)="exportPDF()"></i>
                        </ng-container>
                    </ng-container>
                    <ng-template #pleaseWait>
                        <span class="pleaseWait" translate>placeholder.wait</span>
                    </ng-template>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class=" d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th style="width: 12%;" scope="col" translate>references.phoneNumber</th>
                        <th style="width: 14%;" style="width: 14%;" scope="col" translate>references.email</th>
                        <th style="width: 10%;" scope="col" translate>references.regNumber</th>
                        <!-- <th scope="col" translate>references.service</th> -->
                        <th style="width: 10%;" scope="col" translate>references.dateValidity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{item.phoneNumber}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.regNumber}}</td>
                        <!-- <td>{{item.service}}</td> -->
                        <td>{{item.validTo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)" [disabled]="loadPdfOrExcel">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)" [disabled]="loadPdfOrExcel">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>