import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { Page } from '@interfaces/page';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import { defaultIfEmpty, shareReplay } from 'rxjs/operators';
import { ListPrograms } from '../_models/list-programs.model';
import { LoyaltyPrograms } from '../_models/loyalty-programs.model';

@Injectable({
  providedIn: 'root'
})
export class CardProgramServiceService {

  private cardProgramServiceURL      = environment.apiBaseURL + "/loyalty-program";

  private findAllActiveURL           = this.cardProgramServiceURL + '/all-active';
  private findByIdURL                = this.cardProgramServiceURL + '/find/{id}';
  private saveURL                    = this.cardProgramServiceURL + '/save';
  private pageablePrograms           = this.cardProgramServiceURL + '/pageable'
  private pageableTotalElements      = this.cardProgramServiceURL + '/pageable/count';

  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.getPagablePrograms(sortingPaging, searchForm),
      this.getTotalPrograms(sortingPaging, searchForm)
    ]).pipe(shareReplay());
  }

  public getPagablePrograms(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<Page<ListPrograms>> {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.pageablePrograms, {params: requestParams}).pipe(shareReplay());
  }

  public getTotalPrograms(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<number>{
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.pageableTotalElements, {params: requestParams}).pipe(shareReplay());
  }
  
  public saveProgram(program: LoyaltyPrograms): Observable<any> {
    return this.http.post(this.saveURL, program);
  }

  public findAll(): Observable<LoyaltyPrograms[]> {
    return this.http.get<LoyaltyPrograms[]>(this.findAllActiveURL);
  }

  public findProgramById(id: number): Observable<LoyaltyPrograms> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<LoyaltyPrograms>(this.findByIdURL.replace('{id}', `${id}`));
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });
    return requestParams;
  }
  
}