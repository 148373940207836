export interface DayOfTheWeek {
    name: string;
    code: number
};

export const DAYS_OF_THE_WEEK: DayOfTheWeek[] = [
    { name: "monday",    code: 1 },
    { name: "tuesday",   code: 2 },
    { name: "wednesday", code: 3 },
    { name: "thursday",  code: 4 },
    { name: "friday",    code: 5 },
    { name: "saturday",  code: 6 },
    { name: "sunday",    code: 0 }
];

export const findByCode = (code: number) => DAYS_OF_THE_WEEK.filter(day => day.code === code);
