<form [formGroup]="resetForm">
    <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
            <i class="fa fa-lock mb-2 messagebox-icon" style="font-size: 120px;"></i>
        </div>
        <div class="d-flex justify-content-center">
            <h4 translate>auth.resetPassRequestMessage</h4>
        </div>
        <div class="form-group">
            <label translate>auth.mail</label>
            <input type="email" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': showValidation && form.email.errors }" />
            <div class="invalid-feedback" *ngIf="showValidation && form.email.errors?.required" translate>errors.required</div>
            <div class="invalid-feedback" *ngIf="showValidation && form.email.errors?.email" translate>errors.mailInvalid</div>
        </div>
        <div class="d-flex flex-row justify-content-center">
            <button type="button" class="btn btn-success mr-2 w-25" (click)="requestPassReset()" translate>button.send</button>
            <button type="button" class="btn btn-danger mr-2 w-25" (click)="cancel()" translate>button.cancel</button>
        </div>
    </div>
</form>
