import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { Page } from '@interfaces/page';
import { Observable, EMPTY, forkJoin } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { SitePageManagement } from '../model/site-page-management';

@Injectable({
  providedIn: 'root'
})
export class SitPageManagementService {

  private readonly url = environment.apiBaseURL + "/site-page-management";
  
  private readonly findIdURL                   = this.url + '/{id}';
  private readonly pageableURL                 = this.url + '/pageable'
  private readonly pageableCountURL            = this.url + '/pageable/count';
  private readonly uploadImageURL              = this.url + '/image/upload';
  
  constructor(private http: HttpClient) { }

  public pageableCountSite(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.getPagable(sortingPaging, searchForm),
      this.getCount(sortingPaging, searchForm)
    ]);
  }
  
  public getPagable(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<Page<SitePageManagement>> {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.pageableURL, {params: requestParams});
  }

  public getCount(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<number>{
      let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
      return this.http.get<any>(this.pageableCountURL, {params: requestParams});
  }

  public findById(id: number): Observable<SitePageManagement> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<SitePageManagement>(this.findIdURL.replace('{id}', `${id}`));
  }

  public save(obj: SitePageManagement): Observable<any> {
    return this.http.post(this.url, obj);
  }

  public update(id: number, obj: SitePageManagement): Observable<any> {
    return this.http.put(this.findIdURL.replace('{id}', `${id}`), obj);
  }

  public uploadImage(file: any): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
  
    const options = { responseType: 'text' as 'json' };
    return this.http.post<string>(this.uploadImageURL, formData, options);
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });

    return requestParams;
  }

}
