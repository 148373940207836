<div class="container my-2">
    <h1 class="form-group title-position" translate>clientProfile.clientProfile</h1>
    <form *ngIf="loadCustomer$ | async as data; else loading">
    <div id="mainContent" role="tablist" aria-multiselectable="true">
      <div class="card">
        <div class="card-header" role="tab" id="mainSection">
          <h5 class="mb-0">
            <a data-toggle="collapse" datant="#mainContent" href="#mainSectionContent" aria-expanded="true"
               aria-controls="mainSection" translate>
               clientProfile.personalData
            </a>
          </h5>
        </div>
        <div id="mainSectionContent" class="collapse show" role="tabpanel" aria-labelledby="mainSection">
          <div class="card-body">
            <div class="d-flex">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 col-lg-4">
                  <b translate>clientProfile.clientName</b>
                  <br><label>{{data?.name}}</label>
                </div>
                <div class="col-md-6 col-lg-4">
                  <b translate>clientProfile.clientMail</b>
                  <br><label>{{data?.email}}</label>
                </div>
                <div class="col-md-6 col-lg-4">
                  <b translate>clientProfile.clientPhone</b>
                  <br><label>{{data?.phoneNumber}}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-4">
                  <b translate>clientProfile.clientLicenseNumber</b>
                  <br><label>{{data?.drivingLicenceNumber}}</label>
                </div>
                <div class="col-md-6 col-lg-4">
                  <b for="clientPIN" translate>clientProfile.clientPIN</b>
                  <br><label>{{data?.identificationNumber}}</label>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" role="tab" id="invoiceSection">
          <h5 class="mb-0">
            <a data-toggle="collapse" datant="#invoiceSection" href="#invoiceSectionContent" aria-expanded="true"
               (click)="initializeSection('invoice')" aria-controls="invoiceSection" translate>
               clientProfile.invoiceData
            </a>
          </h5>
        </div>
        <div id="invoiceSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="invoiceSection">
          <div class="card-body">
            <app-client-profile-invoice-data #invoice [parentId]= "data.id"></app-client-profile-invoice-data>
          </div>
        </div>
      <div class="card">
        <div class="card-header" role="tab" id="carsSection">
          <h5 class="mb-0">
            <a data-toggle="collapse" datant="#mainSection" href="#carsSectionContent" aria-expanded="true" 
               (click)="initializeSection('rvs')" aria-controls="carsSection" translate>
               clientProfile.cars
            </a>
          </h5>
        </div>
        <div id="carsSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="carsSection">
          <app-client-profile-rvs #rvs [parentId]= "data.id" [customerPhoneNum] = "data.phoneNumber">  </app-client-profile-rvs>
        </div>
      </div>
      <div class="card">
        <div class="card-header" role="tab" id="cardsSection">
          <h5 class="mb-0">
            <a data-toggle="collapse" datant="#codesSection" href="#cardsSectionContent" aria-expanded="true" (click)="initializeSection('cards')"
               aria-controls="cardsSection" translate>
               clientProfile.cards
            </a>
          </h5>
        </div>
        <div id="cardsSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="cardsSection">
          <app-client-profile-cards #cards [parentId]= "data.id"></app-client-profile-cards>
        </div>
        <div class="card">
          <div class="card-header" role="tab" id="purchasesSection">
            <h5 class="mb-0">
              <a data-toggle="collapse" datant="#purchasesSection" href="#purchasesSectionContent" aria-expanded="true"
                 (click)="initializeSection('purchaseHistory')"
                 aria-controls="purchasesSection" translate>
                 clientProfile.purchasesHistory
              </a>
            </h5>
          </div>
          <div id="purchasesSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="purchasesSection">
            <app-client-profile-purchase-history #purchaseHistory [parentId]= "data.id" [customerPhoneNum] = "data.phoneNumber"></app-client-profile-purchase-history>
          </div>
        </div>
        <div class="card">
          <div class="card-header" role="tab" id="codesSection">
            <h5 class="mb-0">
              <a data-toggle="collapse" datant="#codesSection" href="#codesSectionContent" aria-expanded="true" (click)="initializeSection('promoCodes')"
                 aria-controls="codesSection" translate>
                 clientProfile.promoCodes
              </a>
            </h5>
          </div>
          <div id="codesSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="codesSection">
            <app-client-profile-promo-codes #promoCodes [parentId]= "data.id"></app-client-profile-promo-codes>
          </div>
        </div>
          <!-- <div class="card">
            <div class="card-header" role="tab" id="notificationsSection">
              <h5 class="mb-0">
                <a data-toggle="collapse" datant="#notificationsSection" href="#notificationsSectionContent" aria-expanded="true" (click)="initializeSection('notificationsHistory')"
                   aria-controls="notificationsSection" translate>
                   clientProfile.notificationsHistory
                </a>
              </h5>
            </div>
            <div id="notificationsSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="notificationsSection">
              <app-client-profile-notifications-history #notificationsHistory [parentId]= "data.id"></app-client-profile-notifications-history>
            </div>
          </div> -->
          <!-- <div class="card">
            <div class="card-header" role="tab" id="notificationsSettingSection">
              <h5 class="mb-0">
                <a data-toggle="collapse" datant="#notificationsSettingSection" href="#notificationsSettingSectionContent" aria-expanded="true" 
                   (click)="initializeSection('notificationsSettings')"
                   aria-controls="notificationsSettingSection" translate>
                   clientProfile.notificationsSettings
                </a>
              </h5>
            </div>
            <div id="notificationsSettingSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="notificationsSettingSection">
             <app-client-profile-notifications-settings #notificationsSettings [parentId]="data.id"> </app-client-profile-notifications-settings>
            </div>
          </div> -->
          <!-- <div class="card">
            <div class="card-header" role="tab" id="remindersSection">
              <h5 class="mb-0">
                <a data-toggle="collapse" datant="#remindersSection" href="#remindersSectionContent" aria-expanded="true" 
                   (click)="initializeSection('reminders')"
                   aria-controls="remindersSection" translate>
                   clientProfile.reminders
                </a>
              </h5>
            </div>
            <div id="remindersSectionContent" class="collapse hidden" role="tabpanel" aria-labelledby="remindersSection">
             <app-client-profile-reminders #reminders [parentId]= "data.id"></app-client-profile-reminders>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </form>
  <ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>
</div>
