import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService {

  private apiServerUrl            = environment.apiBaseURL + '/barcode';
  private downloadBarcodePdfUrl   = this.apiServerUrl + '/download-pdf-single';
  private downloadBarcodesPdfUrl  = this.apiServerUrl + '/download-pdf';

  constructor(private http: HttpClient) { }

  downloadPdfSingleBarcode(barcode: string): Observable<Blob> {
    return this.http.post(this.downloadBarcodePdfUrl, barcode, { responseType: 'blob' });
  }

  downloadPdfWithMultipleBarcodes(barcodes: string[]): Observable<Blob> {
    return this.http.post(this.downloadBarcodesPdfUrl, barcodes, { responseType: 'blob' });
  }

}
