<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 translate>technicalInspection.technicalInspections</h3>
        </span>
    </div>
    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearchForm()">
            <!-- first section search fields -->
            <div class="input-group mb-2">
                <!-- tech point -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>technicalInspection.pointPlace</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitTechPoint">
                    <select class="custom-select" aria-describedby="techPointMobileAppId" formControlName="techPointMobileAppId" (change)="onChangeTechnicalPoint($event)">
                        <option *ngIf="!hasTechnicalPoint" value="" translate>placeholder.select</option>
                        <option *ngFor="let p of technicalPoints" [value]=p.mobileAppId>
                            {{ p.shortName }}
                        </option>
                    </select>
                </ng-container>
                <ng-template #pleaseWaitTechPoint>
                    <select class="custom-select" aria-describedby="techPointMobileAppId" formControlName="techPointMobileAppId">
                        <option *ngIf="!hasTechnicalPoint" value="" translate>placeholder.select</option>
                        <option *ngFor="let p of technicalPoints" [value]=p.mobileAppId>
                            {{ p.shortName }}
                        </option>
                    </select>
                </ng-template>

                <!-- reg number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="registrationNumber" translate>technicalInspection.regNumber</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitRegistrationNumber">
                    <input style='text-transform:uppercase' type="text" class="form-control" (keyup.enter)="onSubmitSearchForm()" formControlName="registrationNumber" aria-describedby="registrationNumber" appTrimWhitespace>
                </ng-container>
                <ng-template #pleaseWaitRegistrationNumber>
                    <input style='text-transform:uppercase' type="text" class="form-control" formControlName="registrationNumber" aria-describedby="registrationNumber" appTrimWhitespace>
                </ng-template>

                <!-- start date -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="startDate" translate>technicalInspection.startDate</span>
                </div>
                <!-- the only diff is invoking onFromDateChange func-->
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitfromDateSearch">
                    <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="fromDateSearch" 
                        formControlName="fromDateSearch" ngbDatepicker #d1="ngbDatepicker" (dateSelect)='onFromDateChange()' readonly>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                    </div>
                </ng-container>
                <ng-template #pleaseWaitfromDateSearch>
                        <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="fromDateSearch" 
                        formControlName="fromDateSearch" ngbDatepicker #d1="ngbDatepicker" readonly>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                    </div>
                </ng-template>

                <!-- end date -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="endDate" translate>technicalInspection.endDate</span>
                </div>
                <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="toDateSearch" 
                    formControlName="toDateSearch" ngbDatepicker #d2="ngbDatepicker" (dateSelect)='onToDateChange()' readonly>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                </div>

                <!-- actions -->
                <div class="input-group-append ml-2 action-buttons">
                    <button class="btn btn-outline-success" type="button" (click)="onSubmitSearchForm()" [disabled]="loadPdfOrExcel" translate>button.search</button>
                    <button class="btn btn-outline-success ml-2" type="button" (click)="showExtendedSearch()" [disabled]="loadPdfOrExcel" translate>button.extendedSearch</button>
                    <button class="btn btn-outline-danger ml-2" type="button" (click)="clearFilters()" [disabled]="loadPdfOrExcel" translate>button.clear</button>
                    <ng-container *ngIf="!loadPdfOrExcel;else pleaseWait">
                        <ng-container *ngIf="perms.hasAccess(perms.CAN_DOWNLOAD_RESERVATION_REPORTS)">
                            <i class="fa fa-file-excel-o fa-2x ml-2 mt-1" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" (click)="exportExcel()"></i>
                            <i class="fa fa-file-pdf-o fa-2x ml-2 mt-1" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" (click)="exportPDF()" ></i>
                        </ng-container>
                    </ng-container>
                    <ng-template #pleaseWait>
                        <span class="pleaseWait" translate>placeholder.wait</span>
                    </ng-template>
                </div>
            </div>

            <!-- second section search fields -->
            <div class="input-group mb-2" *ngIf="extendedSearch">
                <!-- slot -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>technicalInspection.slot</span>
                </div>
                <select class="custom-select" aria-describedby="techPointWorkingHoursId" formControlName="techPointWorkingHoursId">
                    <option value="" *ngIf="!lastHour" translate>placeholder.select</option>
                    <ng-container *ngIf="lastHour && lastHour.id">
                        <option [ngValue]="lastHour.id">{{ lastHour.name }}</option>
                        <option value="" translate>placeholder.select</option>
                    </ng-container>
                    <option *ngFor="let tpwh of techPointWorkingHours" [value]=tpwh.id>
                        {{ tpwh.timeConstraint.validFrom }} - {{ tpwh.timeConstraint.validTo }}
                    </option>
                </select>

                <!-- <div class="input-group-prepend ml-2"><span class="input-group-text" translate>technicalInspection.serviceType</span></div>
                <select class="custom-select" aria-describedby="serviceTypeId" formControlName="serviceTypeId">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let st of serviceTypes" [value]=st.id>
                        {{st.description}}
                    </option>
                </select> -->

                <!-- service -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>technicalInspection.service</span>
                </div>
                <select class="custom-select" aria-describedby="serviceId" formControlName="serviceId">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let service of services" [value]=service.id>
                        {{service.name}}
                    </option>
                </select>

                <!-- request status -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>technicalInspection.requestStatus</span>
                </div>
                <select class="custom-select" aria-describedby="statusId" formControlName="statusId" [compareWith]="compareById">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let s of techInspStatuses" [value]=s.id>
                        {{s.name}}
                    </option>
                </select>

                <!-- Discount Type -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>cardLoyal.discountType</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="discountType" (change)='loadDiscountByType()'>
                    <option selected value translate>placeholder.select</option>
                    <option *ngFor="let type of discountTypes" [ngValue]="type">
                        {{ type.description }}
                    </option>
                </select>
                
                <!-- discount -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>technicalInspection.discount</span>
                </div>
                <select class="custom-select" aria-describedby="customerDiscountId" formControlName="customerDiscountId">
                    <option value="" selected translate>placeholder.select</option>
                    <option *ngFor="let d of customerDiscountsByType" [value]=d.id>
                        {{ d.discountName }}
                    </option>
                </select>
            </div>

            <!-- third section search fields -->
            <div class="input-group mb-2" *ngIf="extendedSearch">
                <!-- <div class="input-group-prepend"><span class="input-group-text" id="mobileId" translate>technicalInspection.reservationNumber</span></div>
                <input type="number" class="form-control" formControlName="mobileId" aria-describedby="mobileId">

                <div class="input-group-prepend ml-2"><span class="input-group-text" id="subscriptionNumber" translate>technicalInspection.cardNumber</span></div>
                <input type="number" class="form-control" formControlName="subscriptionNumber" aria-describedby="subscriptionNumber">

                <div class="input-group-prepend ml-2"><span class="input-group-text" id="contractNumber" translate>technicalInspection.contractNumber</span></div>
                <input type="text" class="form-control" formControlName="contractNumber" aria-describedby="contractNumber"> -->

                <!-- termination reason -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>technicalInspection.terminationReason</span>
                </div>
                <select class="custom-select" aria-describedby="rejectReasonId" formControlName="rejectReasonId">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let r of rejectReasons" [value]=r.id>
                        {{r.description}}
                    </option>
                </select>

                <!-- marketing messages -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>technicalInspection.marketingNotification</span>
                </div>
                <select class="custom-select" aria-describedby="wantsMarketingMessages" formControlName="wantsMarketingMessages">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let option of yesNoOptions" [value]="option.code">
                        {{ option.name }}
                    </option>
                </select>
                
                <!-- category -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>technicalInspection.category</span>
                </div>
                <select class="custom-select" aria-describedby="rvsCategoryId" formControlName="rvsCategoryId">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let c of rvsCategories" [value]=c.id>
                        {{c.description}}
                    </option>
                </select>

                <!-- phone number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="phoneNumber" translate>technicalInspection.phoneNumber</span>
                </div>
                <input type="text" class="form-control" formControlName="phoneNumber" aria-describedby="phoneNumber" appTrimWhitespace>

                <!-- loyal card -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="loyaltyCardCombo" translate>technicalInspection.loyalCard</span>
                </div>
                <input type="text" class="form-control" formControlName="loyaltyCardCombo" aria-describedby="loyaltyCardCombo" appTrimWhitespace>

                <!-- promocode -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="promocode" translate>technicalInspection.listPromocode</span>
                </div>
                <input type="text" class="form-control" formControlName="promocode" aria-describedby="promocode" appTrimWhitespace>
                <!-- <div class="input-group-prepend ml-2"><span class="input-group-text" translate>technicalInspection.customerType</span></div>
                <select class="custom-select input-custom-size">
                    <option value="0"> тип 1
                    <option value="1"> тип 2
                    <option value="2"> тип 3
                </select> -->
            </div>
            
            <!-- fourth section search fields -->
            <div class="input-group" *ngIf="extendedSearch">
                <!-- has assistant -->
                <!-- <div class="input-group-prepend ml-2"><span class="input-group-text" translate>technicalInspection.mannerOfExecution</span></div>
                <select class="custom-select" aria-describedby="hasAssistant" formControlName="hasAssistant">
                    <option value="" translate>placeholder.select</option>
                    <option [value]=true  translate> technicalInspection.withAssistant
                    <option [value]=false translate> technicalInspection.withoutAssistant
                </select> -->
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100 mt-2" [hidden]="!dataLoaded">
                <thead>
                    <tr *ngIf="!loadPdfOrExcel;else pleaseWaitTable">
                        <th style="width: 8%;"  sortBy="technicalPoint" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.shortName</th>
                        <th style="width: 5%;"  sortBy="inspectionDate" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.date</th>
                        <th style="width: 6%;"  sortBy="regNumber" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.regNumber</th>
                        <th style="width: 5.5%;"sortBy="rvsCategory" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.rvsCategory</th>
                        <th style="width: 6%;"  sortBy="phoneNumber" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.phoneNumber</th>
                        <th style="width: 10%;" sortBy="email" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.email</th>
                        <th style="width: 8%;"  sortBy="techPointWorkingHours" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.slot</th>
                        <th style="width: 11%;" sortBy="name" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.subjectName</th>
                        <th style="width: 19%;" translate>technicalInspection.service</th>
                        <th style="width: 6%"   sortBy="promocode" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.listPromocode</th>
                        <th style="width: 5%;"  sortBy="loyaltyCardId" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.loyalCard</th>
                        <th style="width: 5.5%;"sortBy="status" (sort)="onSubmitSearchFormSort($event)" translate>technicalInspection.techInspStatus</th>
                        <th style="width: 5%;"  translate class="text-center" >actions</th>
                    </tr>
                    <ng-template #pleaseWaitTable>
                        <tr>
                            <th style="width: 8%;"  translate>technicalInspection.shortName</th>
                            <th style="width: 5%;"  translate>technicalInspection.date</th>
                            <th style="width: 6%;"  translate>technicalInspection.regNumber</th>
                            <th style="width: 5.5%;"translate>technicalInspection.rvsCategory</th>
                            <th style="width: 6%;"  translate>technicalInspection.phoneNumber</th>
                            <th style="width: 10%;" translate>technicalInspection.email</th>
                            <th style="width: 8%;"  translate>technicalInspection.slot</th>
                            <th style="width: 11%;" translate>technicalInspection.subjectName</th>
                            <th style="width: 19%;" translate>technicalInspection.service</th>
                            <th style="width: 6%"   translate>technicalInspection.listPromocode</th>
                            <th style="width: 5%;"  translate>technicalInspection.loyalCard</th>
                            <th style="width: 5.5%;"translate>technicalInspection.techInspStatus</th>
                            <th style="width: 5%;"  translate class="text-center">actions</th>
                        </tr>
                    </ng-template>
                </thead>
                <tbody>
                    <tr *ngFor="let item of technicalInspections">
                        <td class="text-center py-2">{{item.technicalPointShortName}}</td>
                        <td class="text-center py-2">{{item.inspectionDate}}</td>
                        <td class="text-center py-2">{{item.registrationNumber}}</td>
                        <td class="text-center py-2">{{item.rvsCategory}}</td>
                        <td class="text-center py-2">{{item.phoneNumber}}</td>
                        <td class="text-center py-2">{{item.email}}</td>
                        <td class="text-center py-2">{{item.slot}}</td>
                        <td class="text-center py-2">{{item.subjectName}}</td>
                        <td class="text-center py-2">{{item.services}}</td>
                        <td class="text-center py-2">{{item.promocode}}</td>
                        <td class="text-center py-2">{{item.customerCard}}</td>
                        <td class="text-center py-2" [ngClass]="item.status == FINISHED_STATUS_NAME ? 'green' : 
                                                                item.status == ANNULED_STATUS_NAME ? 'red' : ''">
                                                                {{item.status}}
                        <!--  <svg *ngIf="item.orderStatus == PAID_ORDER_STATUS_NAME"
                                xmlns="http://www.w3.org/2000/svg" 
                                width="25" height="25" 
                                fill="green" 
                                class="bi bi-currency-dollar" 
                                viewBox="0 0 16 16">
                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                            </svg>-->
                            <svg *ngIf="item.orderStatus == PAID_ORDER_STATUS_NAME && item.orderPaymentTypeId == ORDER_CARD_PAYMENT_ID"
                                xmlns="http://www.w3.org/2000/svg" 
                                width="32" height="32" 
                                fill="green" 
                                class="bi bi-currency-dollar" 
                                viewBox="0 0 16 16">
                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z"/>
                            </svg>
                        </td>
                        <td class="text-center py-2">
                            <button *ngIf="perms.hasAccess(perms.CAN_VIEW_TECHNICAL_INSPECTION)" class="btn btn-sm btn-success"  data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                                [queryParams]="{id: item.id}" routerLink="/add-edit-technical-inspection" [disabled]="loadPdfOrExcel">
                                <i class="fa fa-pencil fa-lg"></i>
                            </button>
                            <button *ngIf="perms.hasAccess(perms.CAN_VIEW_TECHNICAL_INSPECTION_CC) && item?.status == RESERVED_STATUS_NAME" class="btn btn-sm btn-success"  data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                                [queryParams]="{id: item.id}" routerLink="/add-edit-technical-inspection-cc" [disabled]="loadPdfOrExcel">
                                <i class="fa fa-pencil fa-lg"></i>
                            </button>
                            <button *ngIf="(perms.hasAccess(perms.CAN_ANNUL_TECHNICAL_INSPECTION) && (perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC) || perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION))) && item?.status == RESERVED_STATUS_NAME"  data-toggle="tooltip" data-placement="bottom" title="{{'button.annul' | translate}}"
                                (click)="onSubmitAdditionalFormAnnulModalFutureReservation(item.id)" class="btn btn-sm btn-danger  btn-lg ml-1" type="button" [disabled]="loadPdfOrExcel">
                                <i class="fa fa-close"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (ngModelChange)="loadTechnicalInspections()" [disabled]="loadPdfOrExcel">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)"
                [disabled]="loadPdfOrExcel">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner" >
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #confirmAnnul>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.confirm</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;"><p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                (click)="onSubmitAdditionalFormTerminated()" translate>
                no
            </button>
            <button mat-button [mat-dialog-close]="true" class="ml-2 mr-2 mb-1 btn btn btn-danger" type="button" 
                (click)="onSubmitAdditionalFormTerminated()" translate>
                button.annul
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>