<div class="d-flex flex-column">
    <div class="d-flex justify-content-center">
        <i class="fa fa-exclamation-triangle mb-2 messagebox-icon" ></i>
    </div>
    <h4 translate>{{message | translate}}</h4>
    <div class="d-flex flex-row justify-content-center">
        <button *ngIf="!isWarning"type="button" class="btn btn-success mr-2 w-25" (click)="onYes()" translate>yes</button>
        <button *ngIf="isWarning" type="button" class="btn btn-success mr-2 w-25" (click)="onYes()" translate>ok</button>
        <button *ngIf="!isWarning" type="button" class="btn btn-danger ml-2 w-25" (click)="onNo()" translate>no</button>
    </div>
</div>
