<mat-dialog-content> 
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>  
    <h4 mat-dialog-title translate>registerPerson.personalData</h4>
        <ul class="nav nav-tabs">
            <li class="nav-item"  (click)="changeState(false)" href="#searchTab" >
                <a class="nav-link" [ngClass]="{'show active': !editState}"  data-toggle="tab" href="#searchTab" translate>registerPerson.search</a>
            </li>
            <li class="nav-item" (click)="changeState(true)" href="#addEditTab"  >
                <a class="nav-link"  [ngClass]="{'show active': editState}" data-toggle="tab" href="#addEditTab" translate>registerPerson.addEdit</a>
            </li> 
        </ul> 

        <div class="tab-content">
            <div id="searchTab" class="tab-pane" [ngClass]="{'show active': !editState}"> 
                <app-search-person #search (getSubjectEvent)="handleGetSubjectEvent($event)"
                (selectSubjectEvent)="handleSelectSubjectEvent($event)" 
                (editStateEvent)="handleLoadAddEvent($event)" (editStateEvent) = "handleEditStateEvent($event)"
                (addSectionEvent)="handleAddSectionEvent($event)"></app-search-person>
            </div>
            <div id="addEditTab" class="tab-pane" [ngClass]="{'show active': editState}" data-toggle="tab">
                <app-add-edit-person  #addEdit (selectSubjectEvent)="handleSelectSubjectEvent($event)" 
                (stateEvent)=" handleEditStateAndReloadEvent($event)"
                [editState]="editState" [addSection]="addSection"></app-add-edit-person>
            </div>
        </div>              
</mat-dialog-content>
