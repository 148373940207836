import { isDevMode } from '@angular/core';
import { convertDateToString } from './date-util';

const isDevEnvironment = isDevMode();

/** 
 * @param {Error} err: Error obj
 * @return {void}: display error in console
*/
const displayError = (err: Error): void => {
    if (isDevEnvironment) {
        console.error(`An error occurred at: ${convertDateToString(new Date())}`);
        console.error(err);
    } else {
        console.error(err.message);
    }
} 

/** 
 * @param {unknown} err: unknown obj
 * @return {void}: display error in console
*/
const displayErrorFromUnknown = (err: unknown): void => {
    if (err instanceof Error) {
      displayError(err);
    } else {
      displayError(new Error());
    }
  }

export { displayError, displayErrorFromUnknown }