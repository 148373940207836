<div *ngIf="isInitialized; else notificationsSettingsLoading"></div>
 <div class="card-body">
                <table class="table">
                  <thead >
                    <tr>
                      <th translate>clientProfile.notificationType</th>
                      <th translate>clientProfile.notificationStatus</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let notification of notificationsCustomer" >
                    <tr>
                      <td>{{notification?.notificationType?.name}}</td>
                      <td>
                        <input type="checkbox" class="form-check-input" [checked]="notification?.isValid" [attr.disabled]="true">
                      </td>
                    </tr>
                  </tbody>
                </table>
 </div>
<ng-template #notificationsSettingsLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else notificationsSettingsSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #notificationsSettingsSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>