import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.css']
})
export class ResetPasswordPopupComponent implements OnInit {
  showValidation = false;

  resetForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]]
  });

  constructor(
    private toastr:           ToastrService,
    private formBuilder:      FormBuilder,
    private authService:      AuthenticationService,
    private translateService: TranslateService,
    public dialog: MatDialogRef<ResetPasswordPopupComponent>,
  ) { }

  ngOnInit(): void { }

  requestPassReset() {
    if (!this.resetForm.valid) {
      this.showValidation = true;
      return;
    }

    this.authService.requestPasswordReset(this.resetForm.get('email')?.value).subscribe({
      next: () => {
        this.toastr.success(
          this.translateService.instant('auth.resetMailSend'),
          this.translateService.instant('toast.successTitle')
        );
        this.dialog.close();
      },
      error: (error) => {
        let errorCode: string = error?.error?.code || 'auth.resetMailSendFail'; 

        if (errorCode.includes('DAP')) {
          errorCode = 'errorCodes.' + errorCode;
        }

        this.toastr.error(
          this.translateService.instant(errorCode),
          this.translateService.instant('toast.errorTitle')
        )
      }
    });
  }

  cancel() {
    this.dialog.close();
  }

  get form() {
    return this.resetForm.controls;
  }

}
