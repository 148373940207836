import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { catchError, first, repeatWhen, tap } from 'rxjs/operators';
import { EMPTY, Subscription, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TechnicalInspectionService } from '@app/technical-inspections/_services/technical-inspection.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { NomenclatureService } from '@services/nomenclature.service';
import { ExportService } from '@services/export.service';

import { ListTechnicalInspection } from '@app/technical-inspections/_models/list-technical-inspection.model';
import { TechnicalPointWorkingHour } from '@app/tech-points/_models/technical-point-working-hours.model';
import { LoyaltyPrograms } from '@app/card-config/_models/loyalty-programs.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { CustomerDiscount } from '@models/customer-discount.model';
import { Service } from '@app/services/_models/service.model';
import { DiscountType } from '@models/discount-type.model';
import { RejectReason } from '@models/reject-reason.model';
import { RvsCategory } from '@models/rvs-category.model';
import { ServiceType } from '@models/service-type.model';
import { LoyalCombo } from '@models/loyal-combo.model';
import { Status } from '@models/status.model';

import { convertDateToString, convertObjDateToString } from '@app/_utils/date-util';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { prepareDateForForm } from '@app/_utils/date-util';
import { prepareDateForDb } from '@app/_utils/date-util';

import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { MatDialog } from '@angular/material/dialog';
import { YES_NO, YesNoEnum } from '@app/_enums/yes-no-enum';

@Component({
  selector: 'app-list-technical-inspections',
  templateUrl: './list-technical-inspections.component.html',
  styleUrls: ['./list-technical-inspections.component.css']
})
export class ListTechnicalInspectionsComponent extends BaseSortableSearchComponent<ListTechnicalInspection> implements OnInit, OnDestroy {
  // Units
  technicalInspections:           ListTechnicalInspection[];
  technicalInspectionsCopy:       ListTechnicalInspection[] = [];
  technicalPoints:                TechnicalPoint[];
  services:                       Service[];
  techPointWorkingHours:          TechnicalPointWorkingHour[]; 
  rejectReasons:                  RejectReason[];
  rvsCategories:                  RvsCategory[];
  techInspStatuses:               Status[];
  serviceTypes:                   ServiceType[];
  customerDiscounts:              CustomerDiscount[];
  customerDiscountsByType:        CustomerDiscount[];
  userTechnicalPointMobileAppIds: number[];
  defaultStatus:                  Status;
  loyaltyCard:                    LoyaltyPrograms | null;
  loyaltyCardId:                  number | null;
  discountTypes:                  DiscountType[];

  // Constants
  readonly TECH_INSP_STATUS_TYPE        = 'TECHINSP';
  readonly RESERVED_STATUS_NAME         = 'Резервиран';
  readonly FINISHED_STATUS_NAME         = 'Завършен';
  readonly ANNULED_STATUS_NAME          = 'Анулиран';
  readonly MAIN_SERVICE_GTP_NAME        = "ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД";
  readonly CODE_ANNULLED                = 'ANNULLED';

  // readonly PAID_ORDER_STATUS_NAME       = 'Платен';
  readonly PAID_ORDER_STATUS_NAME       = '20';
  // readonly ORDER_CARD_PAYMENT_ID        = '3';
  readonly ORDER_CARD_PAYMENT_ID        = '3';
  readonly yesNoOptions: YesNoEnum[] = YES_NO;
  
  // Booleans
  extendedSearch                = false;
  disableTechPointWorkingHours: boolean | null;
  hasTechnicalPoint:            boolean;
  loadPdfOrExcel                = false;
  dataLoaded                    = false;

  // Payload
  pageSizeOptions:            number[];
  maxSize:                    number;
  fromDateStr:                string;
  toDateStr:                  string;
  selectedTechPointMobileId   = 0;
  lastHour:                   { id: number, name: string } | null;
  finded:                     any;
  futureTechInspId            = 0;

  // Form
  searchForm = this.formBuilder.group({
    customerDiscountId:         [''],
    techPointMobileAppId:       [''],
    rejectReasonId:             [''],
    rvsCategoryId:              [''],
    serviceTypeId:              [''],
    statusId:                   [''],
    mobileId:                   [''],
    registrationNumber:         [''],
    subscriptionNumber:         [''],
    contractNumber:             [''],
    phoneNumber:                [''],
    serviceId:                  [''],
    hasAssistant:               [''],
    fromDateSearch:             [''],
    toDateSearch:               [''],
    techPointWorkingHoursId:    [''],
    promocode:                  [''],
    loyaltyCardCombo:           [''],
    loyaltyCardId:              [''],
    discountType:               [''],
    wantsMarketingMessages:     ['']
  });

  // Observables
  searchDataSubscription: Subscription;
  loadSearchForm$ = forkJoin([
    this.nomenclatureService.getRejectReasons(),
    this.nomenclatureService.getServiceTypes(),
    this.nomenclatureService.getDiscountTypes(),
    this.nomenclatureService.getCustomerDiscounts()])
    .pipe(
      tap(() => this.dataLoaded = false), 
      tap(([rejectReasons, serviceTypes, discountTypes, customerDiscounts]) => {
        this.rejectReasons = rejectReasons;
        this.serviceTypes = serviceTypes;
        this.discountTypes = discountTypes;
        this.customerDiscounts = customerDiscounts;

        this.dataLoaded = true;
        this.searchSubject.next();
      }),
      catchError(err => {
        displayError(err);
        this.dataLoaded = false;
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
  );

  @ViewChild('confirmAnnul') confirmAnnul: TemplateRef<any>;
  dialogRef: any

  constructor(
      protected router:            Router,
      private formBuilder:         FormBuilder,
      private dialog:              MatDialog,
      public  perms:               PermissionsService,
      private nomenclatureService: NomenclatureService,
      private exportService:       ExportService,
      private translateService:    TranslateService,
      private techInspService:     TechnicalInspectionService,
      private techPointService:    TechnicalPointService
  ) {
      super();
      this.technicalInspections = [];
      this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }  

  async ngOnInit() {
    this.searchDataSubscription = this.loadSearchForm$.subscribe();

    await this.loadTechInspStatuses();
    this.techInspStatuses.forEach(status => {
      if (status.name == this.RESERVED_STATUS_NAME) {
        this.defaultStatus = status;
        this.searchForm.get('statusId')?.patchValue(this.defaultStatus.id)
      }
    });

    const dateObj = new Date();
    this.searchForm.get('fromDateSearch')?.patchValue(prepareDateForForm(dateObj));
    this.searchForm.get('toDateSearch')?.patchValue(prepareDateForForm(dateObj));
    
    await this.loadTechnicalPoints();
    Promise.all([this.loadServices(), this.loadRvsCategories()]);
  }

  ngOnDestroy() {
    localStorage.removeItem('amount');
    localStorage.removeItem('oldAmount');
    this.searchDataSubscription.unsubscribe();
  }

  async onSubmitSearchForm() {
    this.setupSlotForm();
    await this.reloadLoyalCombo();
    this.loadTechnicalInspections();
    this.loadTotalElements();
  }

  private setupSlotForm() {
    if (this.finded) {
      this.techPointWorkingHours.push(this.finded);
      this.techPointWorkingHours = [...new Set(this.techPointWorkingHours)];
      this.sortWorkingHours();
    } 

    const tpId = Number.parseInt(this.searchForm.get('techPointWorkingHoursId')?.value);
    if (!tpId || Number.isNaN(tpId)) {
      this.lastHour = null;
      return;
    } 

    this.finded = this.techPointWorkingHours.find(tp => tp.id == tpId);
    if (!this.finded && !this.findBindedModel()) return;

    const result = (this.finded.timeConstraint.validFrom + " : " + this.finded.timeConstraint.validTo).trim();
    if (result) this.searchForm.get('techPointWorkingHoursId')?.setValue(result);

    this.lastHour = {
      id: this.finded.id,
      name: result,
    };

    this.techPointWorkingHours = this.techPointWorkingHours.filter(h => h.id !== tpId);
    this.sortWorkingHours();
  }

  // because cannot bind from form if it's custom
  private findBindedModel() {
    if (!this.lastHour) return false;
    
    const token = this.lastHour.name.split(' : ');
    this.finded = this.techPointWorkingHours.find(tp => tp.timeConstraint.validFrom === token[0] && tp.timeConstraint.validTo === token[1]);
    return !!this.finded;
  }

  private getDiscountTypeIdFromForm() {
    const discountType = this.searchForm.get('discountType')?.value;
    if (discountType) {
      return discountType.id;
    }
  }

  onSubmitSearchFormSort(event: any) {
    this.sortingPaging.sortAsc = event.sortAsc;
    this.sortingPaging.sortBy = event.sortBy;
    this.loadTechnicalInspections();
    this.loadTotalElements();
  }

  loadTechnicalInspections() {
    this.dataLoaded = false;
    this.prepareDates();
    
    const searchForm = Object.assign({}, this.searchForm.value);    
    const dti = this.getDiscountTypeIdFromForm();
    if (dti) {
      searchForm.discountType = dti;
    }

    this.techInspService.getPagableTechnicalInspections(this.sortingPaging, searchForm, this.fromDateStr, this.toDateStr).pipe(first()).subscribe(resp => {
      this.technicalInspections = resp.content;
      this.sortingPaging.fromRow = resp.fromRow;
      this.sortingPaging.toRow = resp.toRow;
      this.dataLoaded = true;
    });
  }

  loadTotalElements() {
    const searchForm = Object.assign({}, this.searchForm.value);    
    const dti = this.getDiscountTypeIdFromForm();
    if (dti) {
      searchForm.discountType = dti;
    }

    this.techInspService.getTotalElements(this.sortingPaging, searchForm, this.fromDateStr, this.toDateStr).subscribe(resp => {
      this.sortingPaging.totalElements = resp;
      
      const oldAmount = localStorage.getItem('amount');
      if (oldAmount) {
        localStorage.setItem('oldAmount', oldAmount?.toString());
      } else {
        localStorage.setItem('oldAmount', '-1');
      }
      
      localStorage.setItem('amount', this.sortingPaging.totalElements.toString());
    });
  }

  pageChanged(page: number) {
    this.sortingPaging.pageNumber = page;
    this.loadTechnicalInspections(); 
  }

  onFromDateChange() {
    this.searchForm.get('techPointWorkingHoursId')?.patchValue(null);
  }

  onToDateChange() {
    if (this.searchForm.get('toDateSearch')?.value) {
      this.searchForm.get('techPointWorkingHoursId')?.patchValue(null);
      this.disableTechPointWorkingHours = true;
    }
  }

  showExtendedSearch() {
    this.extendedSearch = !this.extendedSearch;
  }

  clearFilters() {
    this.loyaltyCard = null;
    this.loyaltyCardId = null;
    this.techPointWorkingHours = [];
    this.finded = null;
    this.lastHour = null;
    this.searchForm.reset();

    const dateObj = new Date();
    this.searchForm.get('fromDateSearch')?.patchValue(prepareDateForForm(dateObj));
    this.searchForm.get('toDateSearch')?.patchValue(prepareDateForForm(dateObj));
    this.searchForm.get('statusId')?.patchValue(this.defaultStatus.id);

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }

    this.fromDateStr = "";
    this.toDateStr = "";
    this.disableTechPointWorkingHours = null;
    this.loadTechnicalInspections();
    this.loadTotalElements();
  }

  // Init filter section
  private async loadTechnicalPoints() {
    if (this.userTechnicalPointMobileAppIds.length === 0) {
      const tp = await this.techPointService.findTechnicalPoints().toPromise() as TechnicalPoint[]; 
      this.technicalPoints = tp;
      this.hasTechnicalPoint = false;
    } else {
      this.selectedTechPointMobileId = this.userTechnicalPointMobileAppIds[0];

      const tp = await this.techPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise() as TechnicalPoint[] ; 
      this.technicalPoints = tp;
      this.hasTechnicalPoint = true;
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }
    
    Promise.all([this.loadTechnicalInspections(), this.loadTotalElements(), this.loadTechnicalPointWorkingHours()]);
  }

  private loadRvsCategories() {
    this.nomenclatureService.getRvsCategoriesByTechPointId(this.technicalPoints[0]?.mobileAppId!)?.subscribe(rvsCategories => {
      if (rvsCategories) {
        this.rvsCategories = rvsCategories;
      }
    });
  }

  private async loadTechInspStatuses() {
    const statuses = await this.nomenclatureService.getStatusesByType(this.TECH_INSP_STATUS_TYPE).toPromise();
    this.techInspStatuses = statuses;
  }

  private async reloadLoyalCombo() {
    const combo = this.searchForm.get('loyaltyCardCombo')?.value;
    if (!combo) {
      this.loyaltyCardId = null;
      this.searchForm.get('loyaltyCardId')?.setValue(null);
      return;
    } 

    try {
      const data = await this.techInspService.getLoyalComboByCard(combo).toPromise() as LoyalCombo;
      if (!data || !data.customerCard) return;
      this.loyaltyCardId = data.customerCard.id;
      this.searchForm.get('loyaltyCardId')?.setValue(this.loyaltyCardId);
    } catch (err) {
      this.loyaltyCardId = 0;
      displayErrorFromUnknown(err);
    }
  }

  private loadServices() {
    if (this.userTechnicalPointMobileAppIds.length === 0) {
      this.nomenclatureService.findAllValidServices().subscribe(services => {
        this.services = services;
        this.setDefaultService();
      });
    } else {
      this.techPointService.findServicesByPointMobileAppId(this.searchForm.get('techPointMobileAppId')?.value).subscribe(services => {
        this.services = services;
        this.setDefaultService();
      });
    }
  }

  private setDefaultService() {
    const gtp = this.services.splice(this.services.findIndex((item: { name: string; }) => item.name === this.MAIN_SERVICE_GTP_NAME), 1)[0];
    if (gtp) {
      this.services.splice(0, 0, gtp);
    }
  }

  private async loadTechnicalPointWorkingHours() {
    if (!this.selectedTechPointMobileId) return;

    const techPoint = this.technicalPoints.find(tp => tp.mobileAppId === this.selectedTechPointMobileId);
    if (techPoint && techPoint.id) {
      const searchFromDate = this.searchForm.get('fromDateSearch')?.value;
      const fromDateStr = prepareDateForDb(searchFromDate);
  
      this.techPointWorkingHours = await this.techInspService.getTechnicalPointWorkingHoursByDate(techPoint.id, fromDateStr).toPromise();
      // just in case
      await new Promise(f => setTimeout(f, 300));
      this.sortWorkingHours();
    }
  }

  async onChangeTechnicalPoint(event: any) {
    this.finded = null;
    this.lastHour = null;
    this.searchForm.get('techPointWorkingHoursId')?.setValue('');
    this.selectedTechPointMobileId = Number.parseInt(event.target.value);
 
    if (this.selectedTechPointMobileId) {
      this.services = await this.techPointService.findServicesByPointMobileAppId(this.selectedTechPointMobileId).toPromise();
      this.loadTechnicalPointWorkingHours();
    } else {
      this.loadServices();
      this.techPointWorkingHours = [];
    }   
  }

  private sortWorkingHours() {
    this.techPointWorkingHours.sort((val1, val2) => {
      const tokenOne = val1.timeConstraint.validFrom.split(':');
      const tokenTwo = val1.timeConstraint.validTo.split(':');
      const tokenThree = val2.timeConstraint.validFrom.split(':');
      const tokenFour = val2.timeConstraint.validTo.split(':');

      const time1 = Number.parseInt((tokenOne[0] + tokenOne[1]) + (tokenTwo[0] + tokenTwo[1]));
      const time2 = Number.parseInt((tokenThree[0] + tokenThree[1]) + (tokenFour[0] + tokenFour[1]));

      return time1 - time2;
    });
  }

  private prepareDates() {
    const searchFromDate = this.searchForm.get('fromDateSearch')?.value;
    this.fromDateStr = prepareDateForDb(searchFromDate);

    const searchToDate = this.searchForm.get('toDateSearch')?.value;
    if (searchToDate) {
      this.toDateStr = prepareDateForDb(searchToDate);
    }
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.technicalInspectionResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.technicalInspectionResult"), convertDateToString(new Date()), undefined, undefined, true);
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    const amount = localStorage.getItem('amount');
    const oldAmount = localStorage.getItem('oldAmount');
    const isEqual = amount && oldAmount && Number.parseInt(amount) === Number.parseInt(oldAmount);
    
    if (!isEqual) {
      let searchableData = this.searchForm.value;
      if (searchableData.discountType && typeof searchableData.discountType === 'object') {
        searchableData.discountType = searchableData.discountType.id;
      }

      const result = await this.techInspService.getPagableTechnicalInspections(sortingPagingCopy, searchableData, this.fromDateStr, this.toDateStr).pipe(first()).toPromise();
      // just in case
      await new Promise(f => setTimeout(f, 500));
      this.technicalInspectionsCopy = result.content;
      localStorage.setItem('oldAmount', this.sortingPaging.totalElements.toString());
    }
    
    this.technicalInspectionsCopy.forEach((object: any) => {
      newContent?.push([object.technicalPointShortName,
                        object.inspectionDate,
                        object.registrationNumber,
                        object.rvsCategory,
                        object.phoneNumber,
                        object.email,
                        object.slot,
                        object.subjectName,
                        object.services,
                        object.customerCard,
                        object.promocode,
                        object.status,
                        object.wantsMarketingMessages,
                    ]);
    }); 

    return newContent;
  }
 
  private getHeadings() : string[][] {
    return [[this.translateService.instant("technicalInspection.shortName"),
            this.translateService.instant("technicalInspection.date"),
            this.translateService.instant("technicalInspection.regNumber"), 
            this.translateService.instant("technicalInspection.rvsCategory"), 
            this.translateService.instant("technicalInspection.phoneNumber"),
            this.translateService.instant("technicalInspection.email"),
            this.translateService.instant("technicalInspection.slot"),
            this.translateService.instant("technicalInspection.subjectName"),
            this.translateService.instant("technicalInspection.service"),
            this.translateService.instant("technicalInspection.loyalCard"),
            this.translateService.instant("technicalInspection.listPromocode"),
            this.translateService.instant("technicalInspection.techInspStatus"),
            this.translateService.instant("technicalInspection.marketingNotification")
            ]]
  }
   
  private getFilterBody(): any[] {
    let pointName;
    this.technicalPoints.forEach(point => {
      if (point.mobileAppId == this.searchForm.get('techPointMobileAppId')?.value) {
        pointName = point.shortName
      }
    });
    let slot = this.searchForm.get('techPointWorkingHoursId')?.value;

    let status = "";
    this.techInspStatuses.forEach(st => {
      if (st.id == this.searchForm.get('statusId')?.value) {
        status = st.name;
      }
    });
    let discountType = this.discountTypes.find(tp => tp.id === this.searchForm.get('discountType')?.value)?.description;
    let discount = "";
    this.customerDiscounts.forEach(cd => {
      if (cd.id == this.searchForm.get('customerDiscountId')?.value) {
        discount = cd.discountName;
      }
    });
    let reason: String = "";
    this.rejectReasons.forEach(r => {
      if (r.id == this.searchForm.get('rejectReasonId')?.value) {
        reason = r.description;
      }
    });
    let category = "";
    this.rvsCategories.forEach(c => {
      if (c.id == this.searchForm.get('rvsCategoryId')?.value) {
        category = c.description;
      }
    });
    let assistant = "";
    if (this.searchForm.get('hasAssistant')?.value == "true" && this.searchForm.get('hasAssistant')?.value != "") {
      assistant = this.translateService.instant("technicalInspection.withAssistant");
    } 
    if (this.searchForm.get('hasAssistant')?.value == "false" && this.searchForm.get('hasAssistant')?.value != "") {
      assistant = this.translateService.instant("technicalInspection.withoutAssistant")
    }

    let serviceName;
    this.services.forEach(service => {
      if (service.id == this.searchForm.get('serviceId')?.value) {
        serviceName = service.name;
      }
    });

    let result: string[] = [
      pointName,
      this.searchForm.get('registrationNumber')?.value, 
      convertObjDateToString(this.searchForm.get('fromDateSearch')?.value), 
      convertObjDateToString(this.searchForm.get('toDateSearch')?.value),
      slot,
      serviceName,
      status,
      // this.searchForm.get('mobileId')?.value,
      // this.searchForm.get('subscriptionNumber')?.value,
      // this.searchForm.get('contractNumber')?.value,
      discountType,
      discount,
      reason,
      category,
      this.searchForm.get('phoneNumber')?.value,
      this.searchForm.get('loyaltyCardCombo')?.value,
      this.searchForm.get('promocode')?.value,
      this.searchForm.get('wantsMarketingMessages')?.value,
      // assistant
    ];

    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 10;
    let result: any[] = [
      this.translateService.instant('technicalInspection.pointPlace'),
      this.translateService.instant('technicalInspection.regNumber'),
      this.translateService.instant('technicalInspection.startDate'),
      this.translateService.instant('technicalInspection.endDate'),
      this.translateService.instant('technicalInspection.slot'),
      this.translateService.instant('technicalInspection.service'),
      this.translateService.instant('technicalInspection.requestStatus'),
      // this.translateService.instant('technicalInspection.reservationNumber'),
      // this.translateService.instant('technicalInspection.cardNumber'),
      // this.translateService.instant('technicalInspection.contractNumber'),
      this.translateService.instant('technicalInspection.discountType'),
      this.translateService.instant('technicalInspection.discount'),
      this.translateService.instant('technicalInspection.terminationReason'),
      this.translateService.instant('technicalInspection.category'),
      this.translateService.instant('technicalInspection.phoneNumber'),
      this.translateService.instant('technicalInspection.loyalCard'),
      this.translateService.instant('technicalInspection.listPromocode'),
      this.translateService.instant('technicalInspection.marketingNotification'),
      // this.translateService.instant('technicalInspection.mannerOfExecution')
    ];

    return [
      [{content: this.translateService.instant('exportData.filters.chosenFilters'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

  changeTechInspStatus(id: number, statusCode: string) {
    this.techInspService.changeTechInspStatus(id, statusCode).subscribe({
      next: (data) => {
        window.location.reload();
      }
    });
  }

  loadDiscountByType() {
    const discountType = this.searchForm.get('discountType')?.value;
    if (!discountType) {
      return;
    }
    this.customerDiscountsByType = this.filterDiscountsByType(this.customerDiscounts, discountType.code);
  }

  private filterDiscountsByType(discounts: CustomerDiscount[], discountType: string) {
    return discounts.filter((discount) => discount.customerDiscountType?.some((cdt) => cdt.discountType.code === discountType));
  }

  onSubmitAdditionalFormAnnulModalFutureReservation(id: number) {
    this.onSubmitAdditionalFormAnnulModal();
    this.futureTechInspId = id; 
  }

  onSubmitAdditionalFormAnnulModal() {
    this.dialogRef  = this.dialog.open(this.confirmAnnul, {
      width: '800px',
      height: '200px'
    });
  }

  onSubmitAdditionalFormTerminated() {
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result && this.futureTechInspId) {
        this.changeTechInspStatus(this.futureTechInspId, this.CODE_ANNULLED);
        return;
      }
    });
  }

}