import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseSearchComponent } from '@components/_base/base-search/base-search.component';
import { ListSubjectVersion } from '@models/list-subject-version.model';
import { SubjectType } from '@models/subject-type.model';
import { TranslateService } from '@ngx-translate/core';
import { SubjectVersionService } from '@services/subject-version.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, concatMap, repeatWhen, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-person',
  templateUrl: './search-person.component.html',
  styleUrls: ['./search-person.component.css']
})
export class SearchPersonComponent extends BaseSearchComponent<ListSubjectVersion> implements OnInit, OnDestroy {
  subjectTypes: SubjectType[];
  addSection: boolean;
  editState: boolean;
  subject: any;
  
  @Output() getSubjectEvent = new EventEmitter<any>();
  @Output() selectSubjectEvent = new EventEmitter<any>();
  @Output() editStateEvent = new EventEmitter<boolean>();
  @Output() addSectionEvent = new EventEmitter<boolean>();

  constructor(
    private subjectVersionService: SubjectVersionService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    super();
   }

   searchForm = this.formBuilder.group({
    fullName:   [null],
    identNum:   [null],
    type:       [null]
  });

  getSubject (value: any) {
    this.editState = true;
    this.editStateEvent.emit(this.editState);
    this.getSubjectEvent.emit(value);
  }

  selectSubject(id: number) {
    this.subjectVersionService.getSubjectVersionById(id).subscribe(result => { 
      this.subject = result 
      this.selectSubjectEvent.emit(this.subject);
    });
  
  }

  loadAdd() {
    this.editState = true;
    this.addSection = true;
    this.editStateEvent.emit(this.editState);
    this.addSectionEvent.emit(this.addSection);
  }

  loadSearchForm$ = this.subjectVersionService.getSubjectVersionPageableNomenclacutes().pipe(
    tap(([subjectTypes]) => {
      this.subjectTypes = subjectTypes;
      this.searchSubject.next();
    }),
    catchError(err => {
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  )

  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.subjectVersionService.pageable(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        }),
      )
    }),
    catchError(err => {
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
  );

  searchSubscription: Subscription;

  ngOnInit(): void {
    this.searchSubscription = this.search$.subscribe();
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }  

  reload() {
    this.searchSubject.next();
  }
}
