<div class="form-group form-row"  [formGroup]="subjectsForm">
    <div class="col form-group" formGroupName="ownerSubjectVersion">
        <div class="card">
            <div class="card-header">
                <h6 class="card-title" translate>contract.owner</h6>
                <small class="text-danger" *ngIf="ownerSubjectVersion?.get('id')?.errors?.required && showValidations" translate>
                    errors.requiredOnly
                </small>
            </div>
            <div class="card-body">
                <div class="form-group form-row">
                    <div class="col">
                        <label translate>col.eik</label>
                        <input type="text" class="form-control"
                            formControlName="identNum">
                    </div>
                    <button class="btn btn-primary mt-4" (click)="openModal('ownerSubjectVersion')"
                        type="button" translate>contract.chooseSubject</button>
                </div>
                <div class="form-group form-row">
                    <label translate>col.name</label>
                    <input type="text" class="form-control"
                        formControlName="fullName">
                </div>
                <div *ngIf="!isClientContract()" formGroupName="bankAccount">
                    <div class="form-group form-row">
                        <label translate>contract.bankName</label>
                         <input type="text" class="form-control"
                         formControlName="bankName">
                    </div>
                    <div class="form-group form-row">
                        <label translate>contract.bic</label>
                         <input type="text" class="form-control"
                         formControlName="bic">
                    </div>
                    <div class="form-group form-row">
                        <label translate>contract.iban</label>
                        <input type="text" class="form-control"
                        formControlName="iban">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col form-group" formGroupName="contractSubjectVersion">
        <div class="card">
            <div class="card-header">
                <h6 class="card-title" translate>contract.user</h6>
                <small class="text-danger" *ngIf="contractSubjectVersion?.get('id')?.errors?.required && showValidations" translate>
                    errors.requiredOnly
                </small>
            </div>
            <div class="card-body">
                <div class="form-group form-row">
                    <div class="col">
                        <label translate>col.eik</label>
                        <input type="text" class="form-control" formControlName="identNum">
                    </div>
                    <button class="btn btn-primary mt-4" (click)="openModal('contractSubjectVersion')"
                        type="button" translate>contract.chooseSubject</button>
                </div>
                <div class="form-group form-row">
                    <label translate>col.name</label>
                    <input type="text" class="form-control"
                        formControlName="fullName">
                </div>
                <div *ngIf="!isClientContract()" formGroupName="bankAccount">
                    <div class="form-group form-row">
                        <label translate>contract.bankName</label>
                        <input type="text" class="form-control"
                        formControlName="bankName">
                    </div>
                    <div class="form-group form-row">
                        <label translate>contract.bic</label>
                        <input type="text" class="form-control"
                        formControlName="bic">
                    </div>
                    <div class="form-group form-row">
                        <label translate>contract.iban</label>
                        <input type="text" class="form-control"
                        formControlName="iban">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>