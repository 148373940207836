<div *ngIf="isInitialized; else loading">
    <!-- ****************************************************** 
    ************** Technical Point Form *************
    ****************************************************** -->
    <form [formGroup]="techPointsForm">
        <div formArrayName="techPoints">
            <h5 class="text-danger" style="margin-left: 20px;" *ngIf="techPointsForm.controls.techPoints?.errors?.required && showValidations" translate>promotion.noTechnicalPointValidation</h5>
            <!-- Related points to city -->
            <div *ngFor="let techPoint of techPointsForms.controls; let techPointGroup=index" [formGroupName]="techPointGroup">
                <div class="card m-3">
                    <div class="d-flex justify-content-end pt-2 pr-2" *ngIf="canAddEdit">
                        <i class="fa fa-trash" *ngIf="statusCode != ACTIVE_STATUS_CODE" (click)="deleteTechPoint(techPointGroup)"></i>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <!-- City -->
                            <div class="row">
                                <div class="col-lg-4 col-md-6 d-flex flex-column">
                                    <label translate>promotion.city</label>
                                    <select class="custom-select input-custom-size" formControlName="city">
                                        <option value="null" translate>placeholder.selectAll</option>
                                        <option *ngFor="let city of cities" [ngValue]="city"> {{city.name}}
                                    </select>
                                </div>
                            </div>
                            <!-- Technical point -->
                            <div class="row">
                                <div class="col-lg-4 col-md-6 d-flex flex-column">
                                    <label class="mt-2" translate>promotion.technicalPoint</label>
                                    <select class="custom-select input-custom-size" formControlName="technicalPoint" [compareWith]="compareById"
                                            [ngClass]="{ 'is-invalid': showValidations && techPoint.get('technicalPoint')?.errors }" (change)="technicalPointChange(techPoint.get('technicalPoint')?.value, techPoint)">
                                        <option value="null" disabled translate>placeholder.select</option>
                                        <!-- <option *ngFor="let technicalPoint of techPoint.get('filterdTechPoints')?.value" [disabled]="isTechnicalPointSelected(technicalPoint)" [ngValue]="technicalPoint"> {{technicalPoint?.name}} -->
                                        <option *ngFor="let technicalPoint of conciseTechPoints" [disabled]="isTechnicalPointSelected(technicalPoint)" [ngValue]="technicalPoint" [ngClass]="{'disabled-option': isTechnicalPointSelected(technicalPoint)}"> {{technicalPoint?.name}}
                                    </select>
                                    <div class="invalid-feedback" *ngIf="techPoint?.get('technicalPoint')?.errors?.required && showValidations" translate>errors.required</div>
                                </div>
                            </div>
                            <!-- Working slots -->
                            <div class="row" *ngIf="techPoint.get('workingHours')?.value?.length != 0">
                                <div class="col">
                                    <div class="d-flex flex-row mt-2">
                                        <div>
                                            <h5 translate>techPoint.workingSlots</h5>
                                            <small class="text-danger pt-1" *ngIf="techPoint.get('workingHours')?.errors?.noWorkingHoursSelected && showValidations" translate>promotion.noTimeSlotsSelected</small>
                                        </div>
                                        <div class="ml-auto">
                                            <h5 translate>techPoint.selectAllSlots</h5>
                                            <input style="position: absolute; top: 10px;" class="form-check-input" type="checkbox" (click)="selectAllDaysForPoint(techPoint, $event)" />
                                        </div>
                                    </div>
                                    <hr class="w-90 mt-0">
                                </div>
                            </div>
                            <!-- Working hours -->
                            <div class="row">
                                <div class="col">
                                    <div class="container-fluid" formArrayName="workingHours">
                                        <ng-container *ngFor="let day of getDayFromtechPoint(techPoint); let dayGroup=index" [formGroupName]="dayGroup" >
                                            <div class="row">
                                                <div class="col">
                                                    <div *ngIf="day.get('timeSlots')?.value.length != 0 " class="d-flex flex-row">
                                                        <h5 class="mr-4">{{day.get('day')?.value?.name | translate}}</h5>
                                                        <div>
                                                            <input class="form-check-input" type="checkbox" formControlName="isFull">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <ng-container formArrayName="timeSlots">
                                                    <div class="col-6 col-md-4 col-lg-2" *ngFor="let workingHour of getTimeSlotsFromDay(day); let workingHourGroup=index" [formGroupName]="workingHourGroup">
                                                        <input type="checkbox" class="form-check-input" formControlName="isSelected">
                                                        <label>{{workingHour.get('timeSlot')?.value?.validFrom}} - {{workingHour.get('timeSlot')?.value?.validTo}}</label>
                                                    </div> 
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ****************************************************** 
    ******* Action Button Which Opens Technical Point Form  ********
    ****************************************************** -->
    <div class="d-flex justify-content-center py-3" data-toggle="tooltip" data-placement="bottom" (click)="addPromotionTechPoint()" *ngIf="canAddEdit && statusCode != ACTIVE_STATUS_CODE">
        <i class="fa fa-plus"></i>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>