import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Service } from '@app/services/_models/service.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { City } from '@models/city.model';
import { Status } from '@models/status.model';
import { NomenclatureService } from '@services/nomenclature.service';
import { StatusCode } from '@app/_enums/status-code';

import { EMPTY, forkJoin, Observable, of, OperatorFunction, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, first, repeatWhen, switchMap, tap } from 'rxjs/operators';

import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { SearchTechnicalPoint } from '@app/tech-points/_models/search-technical-point.mode';
import { PromotionService } from '@app/promotions/_services/promotion.service';
import { ListPromotion } from '@app/promotions/_models/list-promotions.model';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { convertDateToString } from '@app/_utils/date-util';

@Component({
  selector: 'app-list-promotions',
  templateUrl: './list-promotions.component.html',
  styleUrls: ['./list-promotions.component.css']
})
export class ListPromotionsComponent extends BaseSortableSearchComponent<ListPromotion> implements OnInit, OnDestroy {
  // Units
  module:           string;
  cities:           City[];
  statuses:         Status[];
  services:         Service[];
  promotionTypeId:  number;
  technicalPoints:  TechnicalPoint[];

  // Constants
  readonly DISCOUNT_MODULE_CODE:      string = 'discount';
  readonly PROMOCODE_MODULE_CODE:     string = 'promocode';
  readonly INVITE_FRIEND_MODULE_CODE: string = 'invite-friend';
  readonly MAIN_SERVICE_GTP_NAME:     string = "ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД";

  // Booleans
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    name:             [null],
    status:           [null],
    service:          [null],
    technicalPoint:   [null],
    city:             [null]
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.promotionService.pageable(this.sortingPaging, this.searchForm.value, this.promotionTypeId).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    })
  );

  loadSearchForm$ = this.route.queryParams.pipe(
    switchMap(params => {
      return forkJoin([
        this.nomenclatureService.getCities(),
        this.nomenclatureService.findAllValidServices(),
        this.nomenclatureService.getStatusesByType(this.checkStatus(params['module']))        
        ]).pipe(
          tap(() => this.setPromotionType(params['module'])),
          tap(([cities, services, statuses]) => {
            this.cities = cities;
            this.statuses = statuses;
            this.services = services;
            const gtp = this.services.splice(this.services.findIndex((item: { name: string; }) => item.name === this.MAIN_SERVICE_GTP_NAME), 1)[0];
            if (gtp) this.services.splice(0, 0, gtp);

            this.searchSubject.next();
          }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$));
    }) 
  );

  technicalPointsSearch: OperatorFunction<string, SearchTechnicalPoint[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    switchMap(input =>
      this.technicalPointService.filterTechnicalPoints(input).pipe(
        catchError(() => of([]))
      )
    )
  );

  constructor(
    private perms: PermissionsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private exportService: ExportService, 
    private translateService: TranslateService,
    private promotionService: PromotionService,
    private nomenclatureService: NomenclatureService,
    private technicalPointService: TechnicalPointService
  ) {
    super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  checkStatus(module: any) {
    if (module == this.PROMOCODE_MODULE_CODE) {
      return StatusCode.PromotionPromocode;
    } 
    if (module == this.INVITE_FRIEND_MODULE_CODE) {
      return StatusCode.PromotionInviteFriend;
    } 
 
    return StatusCode.PromotionDiscount;
  }

  setPromotionType(module: any) {
    this.module = module;

    //TODO: fix
    if (module == this.DISCOUNT_MODULE_CODE) {
      this.promotionTypeId = 1;
    }
    if (module == this.PROMOCODE_MODULE_CODE) {
      this.promotionTypeId = 2;
    }
    if (module == this.INVITE_FRIEND_MODULE_CODE) {
      this.promotionTypeId = 3;
    }
  }

  technicalPointFormatter(element: any): string {
    return element.name;
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    const fileName = this.getFileName();

    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), fileName);
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    const fileName = this.getFileName();

    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), fileName, convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private getFileName() {
    if (this.module === this.DISCOUNT_MODULE_CODE) {
      return this.translateService.instant('exportData.filesNames.promotionsResult');
    }
    if (this.module === this.PROMOCODE_MODULE_CODE) {
      return this.translateService.instant('exportData.filesNames.promocodesResult');
    }
    if (this.module === this.INVITE_FRIEND_MODULE_CODE) {
      return this.translateService.instant('exportData.filesNames.inviteFriendResult');
    }
  }

  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.promotionService.findAllByFilter(sortingPagingCopy, this.searchForm.value, this.promotionTypeId).pipe(first()).toPromise();

    result.content.forEach(object => {
      if (this.module === this.DISCOUNT_MODULE_CODE) {
        newContent?.push([object.name, object.status.name, object.validFrom, object.validTo, object.services, object.technicalPoints])
      }
      if (this.module === this.PROMOCODE_MODULE_CODE) {
        newContent?.push([object.name, object.status.name, object.validFrom, object.validTo, object.services, object.technicalPoints, object.maxUsageNumber, object.orderTotalAmount])
      } 
      if (this.module === this.INVITE_FRIEND_MODULE_CODE) {
        newContent?.push([object.status.name, object.validFrom, object.validTo, object.services, object.technicalPoints, object.maxUsageNumber])
      }  
    });

    return newContent;
  }
 
  private getHeadings() : string[][] {
    let data: any[][] = [];
  
    if (this.module == this.DISCOUNT_MODULE_CODE) {
      data = [[this.translateService.instant("promotion.name"), this.translateService.instant("promotion.status"), 
               this.translateService.instant("promotion.validFrom"), this.translateService.instant("promotion.validTo"), this.translateService.instant("promotion.services"),
               this.translateService.instant("promotion.technicalPoints")]];
    }

    if (this.module == this.PROMOCODE_MODULE_CODE) {
      data = [[this.translateService.instant("promotion.name"), this.translateService.instant("promotion.status"), 
               this.translateService.instant("promotion.validFrom"), this.translateService.instant("promotion.validTo"), this.translateService.instant("promotion.services"),
               this.translateService.instant("promotion.technicalPoints"), this.translateService.instant("promotion.maxCount"),
               this.translateService.instant("promotion.totalAmount")]];
    }

    if (this.module == this.INVITE_FRIEND_MODULE_CODE) {
      data = [[this.translateService.instant("promotion.status"), this.translateService.instant("promotion.validFrom"), 
               this.translateService.instant("promotion.validTo"), this.translateService.instant("promotion.services"),
               this.translateService.instant("promotion.technicalPoints"), this.translateService.instant("promotion.shipmentsNumber")]];
    }
    
    return data;
  }
   
  private getFilterBody(): any[] {
    let status: Status | null;
    let service: Service | null;
    let city: City | null;

    status = this.searchForm.get('status')?.value;
    service = this.searchForm.get('service')?.value;
    city = this.searchForm.get('city')?.value;

    let result: string[]= [
      this.searchForm.get('name')?.value,
      status?.name, 
      service?.name,
      this.searchForm.get('technicalPoint')?.value,
      city?.name
    ];

    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listPromotions.name'),
      this.translateService.instant('exportData.listPromotions.status'),
      this.translateService.instant('exportData.listPromotions.service'),
      this.translateService.instant('exportData.listPromotions.technicalPoint'),
      this.translateService.instant('exportData.listPromotions.city')
    ];

    let content: string = '';
    if (this.module === this.DISCOUNT_MODULE_CODE) {
      content = this.translateService.instant('menu.marketing.promotions.search');
    }

    if (this.module === this.PROMOCODE_MODULE_CODE) {
      content = this.translateService.instant('menu.marketing.promoCodes.search');
    }

    if (this.module === this.INVITE_FRIEND_MODULE_CODE) {
      content = this.translateService.instant('menu.marketing.inviteFriends.search');
    }
 
    return [
       [{content: content, colSpan: colSpan, styles: {halign: 'center'}}],
       result
    ];
  }

  get canView() {
    switch(this.module) {
      case this.DISCOUNT_MODULE_CODE: 
        return this.perms.hasAccess(this.perms.CAN_VIEW_PROMOTION);
      case this.PROMOCODE_MODULE_CODE: 
        return this.perms.hasAccess(this.perms.CAN_VIEW_PROMOCODE);
      case this.INVITE_FRIEND_MODULE_CODE: 
        return this.perms.hasAccess(this.perms.CAN_VIEW_INVITE_FRIEND);
      default: 
        return false;
    }
  } 

  get canAddEdit() {
    switch(this.module) {
      case this.DISCOUNT_MODULE_CODE: 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOTION);
      case this.PROMOCODE_MODULE_CODE: 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOCODE);
      case this.INVITE_FRIEND_MODULE_CODE: 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_INVITE_FRIEND);
      default: 
        return false;
    }
  }

}