<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 translate>serviceAddInfo.serviceAddInfos</h3>
        </span>
    </div>
    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- service type -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>serviceAddInfo.serviceType</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="serviceType" style="max-width: 280pt;">
                    <option *ngFor="let serviceType of serviceTypes" [value]="serviceType.id">
                        {{serviceType.description}}
                    </option>
                </select>

                <!-- valid -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>serviceAddInfo.isValid</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="isValid" style="max-width: 280pt;">
                    <option *ngFor="let option of options" [value]="option.code">
                        {{option.name}}
                    </option>
                </select>

                <!-- actions -->
                <div class="input-group-append ml-2">
                    <button class="btn btn-outline-success" type="button" (click)="search()" translate>
                        button.search
                    </button>
                    <button class="btn btn-outline-danger ml-2" type="button" (click)="clearSearch()" translate>
                        button.clear
                    </button>
                    <button class="btn btn-outline-success ml-2" routerLink="/add-edit-service-config" type="button"
                        *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE)" translate>
                        button.add
                    </button>
                    <i class="fa fa-file-excel-o fa-2x" style="margin-left: 10px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" (click)="exportExcel()"></i>
                    <i style="margin-left: 5px; margin-top: 2px" class="fa fa-file-pdf-o fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" (click)="exportPDF()"></i>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class=" d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th scope="col" sortBy="service" (sort)="onSort($event)" translate>service.orderIndex</th>
                        <th scope="col" sortBy="service" (sort)="onSort($event)" translate>service.name</th>
                        <th scope="col" sortBy="service" (sort)="onSort($event)" translate>service.serviceType</th>
                        <th scope="col" sortBy="service" (sort)="onSort($event)" translate>service.isValid</th>
                        <th class="text-center" scope="col" translate>actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{item.orderIndex}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.serviceType.description}}</td>
                        <td>{{item.isValid == true? 'Да' : 'Не'}}</td>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success"  data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                            routerLink="/add-edit-service-config" *ngIf="perms.hasAccess(perms.CAN_VIEW_ARTICLE)" [queryParams]="{id: item.id, serviceId: item.serviceId}"><i class="fa fa-pencil fa-lg"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize"
                (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>