<div *ngIf="isInitialized; else loading" >
    <!-- ****************************************************** 
    ************** Service Form *************
    ****************************************************** -->
    <form [formGroup]="servicesForm">
        <div formArrayName="services">
            <h5 class="text-danger" style="margin-left: 20px;" *ngIf="servicesForm.controls.services?.errors?.required && showValidations" translate>promotion.noServicesValidation</h5>
            <!-- Services and categories -->
            <div *ngFor="let service of servicesForms.controls; let serviceGroup=index" [formGroupName]="serviceGroup">
                <div class="card m-3">
                    <div class="d-flex justify-content-end pt-2 pr-2" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT)">
                        <i class="fa fa-trash" *ngIf="statusCode != ACTIVE_STATUS_CODE" (click)="deleteService(serviceGroup)"></i>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <!-- Choose service -->
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 d-flex flex-column">
                                    <label translate>promotion.service</label>
                                    <select  class="custom-select input-custom-size" 
                                            [compareWith]="compareById" formControlName = "service" 
                                            [ngClass]="{ 'is-invalid': showValidations && service.get('service')?.errors }"
                                            (change)="onServiceChange(service.get('service')?.value, service)">
                                        <option value="null" disabled translate>placeholder.select</option>
                                        <option *ngFor="let service of selectedServices" [disabled]="isServiceSelected(service)" [ngValue]="service" > {{service.name}}
                                    </select>
                                    <div class="invalid-feedback" *ngIf="service?.get('service')?.errors?.required && showValidations" translate>errors.required</div> 
                                </div>
                            </div>
                            <!-- Choose to which categories to apply -->
                            <div class="row mt-2" *ngIf="service?.get('rvsCategories')?.value.length !== 0">
                                <div class="col">
                                    <label translate>promotion.categories</label>
                                    <small class="text-danger pt-1" style="margin-left: 4px" *ngIf="service.get('rvsCategories')?.errors?.noCategoriesSelected && showValidations" translate>promotion.noCategoriesSelected</small>
                                    <div class="container" formArrayName="rvsCategories">
                                        <div class="row">
                                            <div class="col-6 col-md-4 col-lg-2" *ngFor= "let category of getRvsCategoriesConstrols(service);let categoryGroup=index" [formGroupName]="categoryGroup">
                                                <input class="form-check-input" type="checkbox" (change)="onCategorySelect()" formControlName="isSelected">
                                                <label>{{category?.get('rvsCategory')?.value?.code}}</label>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- ****************************************************** 
    ******* Action Button Which Opens Service Form  ********
    ****************************************************** -->
    <div class="d-flex justify-content-center py-3" data-toggle="tooltip" data-placement="bottom" (click)= "addPromotionService()" *ngIf="canAddEdit && statusCode != ACTIVE_STATUS_CODE">
        <i class="fa fa-plus"></i>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>