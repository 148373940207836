import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Service } from '@app/services/_models/service.model';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { NomenclatureService } from '@services/nomenclature.service';
import { catchError, map, repeatWhen, take, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, forkJoin, of, Subscription } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PromotionService } from '@app/promotions/_services/promotion.service';
import { TranslateService } from '@ngx-translate/core';
import { PromotionServices } from '@app/promotions/_models/promotion-services.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { RvsCategory } from '@models/rvs-category.model';
import { PromotionValidators } from '@app/_validators/promotion.validator';
import { MatDialog } from '@angular/material/dialog';
import { YesNoMessageboxComponent } from '@components/_base/_messageboxes/yes-no-messagebox/yes-no-messagebox.component';
import { modalMinWidth } from '@env/config';
import { displayError } from '@app/_utils/error-util';

@Component({
  selector: 'app-promotion-services',
  templateUrl: './promotion-services.component.html',
  styleUrls: ['./promotion-services.component.css']
})
export class PromotionServicesComponent extends BaseChildComponent implements OnInit, OnDestroy {
  // Units
  selectedServices: Service[];
  rvsCategoriesCache: Map<number, RvsCategory[]> = new Map<number, RvsCategory[]>();

  // Constants
  readonly ACTIVE_STATUS_CODE             = 'ACTIVE';
  private readonly MAIN_SERVICE_GTP_NAME  = "ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД";

  // Property decorators
  @Input() changedCategoriesServices: BehaviorSubject<number[]>;
  @Input() module: string;
  @Input() statusCode: string | undefined;
  @Input() isDisabled: boolean;

  // Form
  servicesForm = this.formBuilder.group({
    services: this.formBuilder.array([], Validators.required)
  });

  // Observables
  baseSubscription: Subscription = new Subscription();

  constructor(
    private dialog:               MatDialog,
    private formBuilder:          FormBuilder,
    public  perms:                PermissionsService,
    private translateService:     TranslateService,
    private promotionService:     PromotionService,
    private nomenclatureService:  NomenclatureService
  ) {
    super();
  }

  ngOnInit() {    
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data,
    });
  }

  ngOnDestroy() {
    this.baseSubscription?.unsubscribe();
  }

  initialize() {
    if (this.isInitialized) return; 
    this.isInitialized = true;

    return forkJoin([
        this.promotionService.findServicesByPromotionId(this.parentId),
        this.nomenclatureService.findAllValidServicesForPromo(),
        this.nomenclatureService.getRvsCategoriesForPromoServices()
      ]).pipe( 
        tap(([promServices, services, rvsCategories]) => {
          this.rvsCategoriesCache = rvsCategories;
          promServices?.forEach(service => this.loadPromotionService(service));
          this.selectedServices = services;
          const gtp = this.selectedServices.splice(this.selectedServices.findIndex((item: { name: string; }) => item.name === this.MAIN_SERVICE_GTP_NAME), 1)[0];
          if (gtp) {
            this.selectedServices.splice(0, 0, gtp);
          }
          
          this.emmitServiceRvsCategories();

          this.isInitialized = true;

          if (this.statusCode == this.ACTIVE_STATUS_CODE) {
            this.servicesForms.disable();
          }
        }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      ).pipe(take(1)).toPromise();
  }

  private emmitServiceRvsCategories() {
    if (this.servicesForms == null) return;

    let result: number[] = [];

    let services = this.servicesForms.controls;

    services.forEach(s => {
      this.getServiceRvsCatIds(s).forEach(id => result.push(id))
    });

    this.changedCategoriesServices.next(result);
  }

  private getServiceRvsCatIds(service: AbstractControl): number[] {
    let result: number[] = [];

    let categories = service.get('rvsCategories') as FormArray;
  
    categories.controls.filter(c => c.get('isSelected')?.value).forEach(c => {
      result.push(service.get('service')?.value?.id);
      result.push(c.get('rvsCategory')?.value?.id);
    });

    return result;
  }

  addPromotionService(): void {
   this.loadPromotionService(null);
  }

  async deleteService(index: number) {
    let service = this.servicesForms.at(index)?.get('service')?.value as Service;
    if (service != null) {
      if (this.parentId != null) {
        let dialogRef = this.dialog.open(YesNoMessageboxComponent, {
          ...modalMinWidth,
          data: {
            isWarning: false,
            message: "messagebox.promotionServiceRemoval"
          }
        });
    
        await dialogRef.afterClosed().toPromise().then((isConfirm) => {
          if (isConfirm) {
            this.servicesForms.removeAt(index);
            this.emmitServiceRvsCategories();
          }
        });
    } else {
      this.servicesForms.removeAt(index);
      this.emmitServiceRvsCategories();
    }
     
    } else {
      this.servicesForms.removeAt(index);
      this.emmitServiceRvsCategories();
    }
  }

  loadPromotionService(promotionService: PromotionServices | null): void {
    let serviceForm: FormGroup;
    
    if (promotionService == null) {
      serviceForm = this.formBuilder.group({
        id:                  [null],         
        service:             [null, Validators.required],
        rvsCategories:       this.formBuilder.array([], PromotionValidators.validateServiceRvsCategories())
      });  
    } else {
      serviceForm = this.formBuilder.group({
        id:                  [promotionService.id],         
        service:             [promotionService.service, Validators.required],
        rvsCategories:       this.formBuilder.array([], PromotionValidators.validateServiceRvsCategories())
      });

      this.loadRvsCategoriesForService(serviceForm, promotionService.service.id, promotionService?.rvsCategories || null);
    }

    this.servicesForms.push(serviceForm);
  }

  public onServiceChange (promotionService: PromotionServices, serviceForm:AbstractControl) {
    if (serviceForm.get('id')?.value == null) {
      this.loadRvsCategoriesForService(serviceForm, serviceForm.get('service')?.value.id, promotionService?.rvsCategories || null);
      this.emmitServiceRvsCategories();
    } else {
      let dialogRef = this.dialog.open(YesNoMessageboxComponent, {
              ...modalMinWidth,
              data: {
                isWarning: true,
                message: "messagebox.promotionServiceRemovalWarning"
              }
      });
    
      dialogRef.afterClosed().toPromise().then((isConfirm) => {
        if (isConfirm) {
          this.loadRvsCategoriesForService(serviceForm, serviceForm.get('service')?.value.id, promotionService?.rvsCategories || null);
          this.emmitServiceRvsCategories();
        }
      });
    }
  }

  private loadRvsCategoriesForService(serviceForm: AbstractControl, id: number, rvsCategories: RvsCategory[] | null) {
    this.getRvsCategoriesObservable(id, rvsCategories).subscribe(categories => {
      let rvsCategoriesControl = serviceForm.get('rvsCategories') as FormArray;
      rvsCategoriesControl.clear();

      categories?.forEach(form => rvsCategoriesControl.push(form));
    });
  }

  private getRvsCategoriesObservable(id: number, rvsCategories: RvsCategory[] | null) {
    let obs;

    if (this.rvsCategoriesCache.get(id) != null) {
      obs = of(this.rvsCategoriesCache.get(id));
    } else {
      obs = this.nomenclatureService.getRvsCategoriesForService(id).pipe(
        tap(categories => this.rvsCategoriesCache.set(id, categories))
      );
    }

    return obs.pipe(
      map(categories => categories?.map(cat => {
        return this.formBuilder.group({
          rvsCategory:   [cat],
          isSelected:    [this.checkCategoryId(cat.id, rvsCategories)]
        })
      }))
    )
  }

  constructServices() {
    if (!this.isInitialized) {
      return null;
    }

    let services = this.servicesForms.value as any[];
    return services.map((service: any) => this.constructService(service));
  }

  private constructService(service: any): PromotionServices {
    return {
      id:            service.id,
      service:       service.service,
      isValid:       true,
      rvsCategories: this.getSelectedRvsCategories(service)
    }
  }

  private getSelectedRvsCategories(service: any): RvsCategory[] {
    let allCategories = service.rvsCategories as any[];

    return allCategories.filter((c: any) => c.isSelected).map((c: any) => c.rvsCategory);
  }

  checkCategoryId(categoryId: number, rvsCategories: RvsCategory[] | null): boolean {
    if(rvsCategories == null) {
      return false;
    }

    return rvsCategories.some(category => category.id == categoryId);
  }
  
  onCategorySelect() {
     this.emmitServiceRvsCategories();
  }

  validateServices(): boolean {
    let servicesValid = this.servicesForm.valid;

    if (!servicesValid) {
      this.isOpened = true;
    }
    
    return servicesValid;
  }

  isServiceSelected(service: Service) {
    let services = this.servicesForm.get('services') as FormArray;
    let servicesIds: number[] = [];

    if(service == null || services.length == 0) {
      return false;
    }

    services?.controls.forEach(s => {
      if(s?.get('service')?.value != null) {
        servicesIds.push(s.get('service')?.value.id)
      }
    })

    if(servicesIds.includes(service?.id)) {
      return true;
    } 

    return false;
  }

  getRvsCategoriesConstrols(service: AbstractControl) {
    let array = service.get('rvsCategories') as FormArray;
    return array?.controls;
  }

  get servicesForms(): FormArray { 
    return this.servicesForm.get('services') as FormArray; 
  } 

  get canAddEdit() {
    switch(this.module) {
      case 'discount': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOTION);
      case 'promocode': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOCODE);
      case 'invite-friend': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_INVITE_FRIEND);
      default: 
        return false;
    }
  }

}