import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { EMPTY, forkJoin, Subscription } from 'rxjs';
import { catchError, concatMap, first, repeatWhen, switchMap, tap } from 'rxjs/operators';
import { ListIncomeByServices } from '../_models/list-income-by-services.model';
import { IncomeByServicesService } from '../_services/income-by-services.service';
import { ActivatedRoute } from '@angular/router';
import { NomenclatureService } from '@services/nomenclature.service';
import { Service } from '@app/services/_models/service.model';
import { ServiceType } from '@models/service-type.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { prepareDateForDb, convertDateToString, convertObjDateToString, prepareDateForForm } from '@app/_utils/date-util';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';

@Component({
  selector: 'app-income-by-services',
  templateUrl: './income-by-services.component.html',
  styleUrls: ['./income-by-services.component.css']
})
export class IncomeByServicesComponent extends BaseSortableSearchComponent<ListIncomeByServices> implements OnInit, OnDestroy {
  // Units
  technicalPoints:                  TechnicalPoint[];
  dateObj                           = new Date();
  userTechnicalPointMobileAppIds:   number[];
  services:                         Service[];
  serviceTypes:                     ServiceType[];
  sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);

  // Booleans
  hasTechnicalPoint:  boolean;
  loadPdfOrExcel      = false;

  // Form
  searchForm = this.formBuilder.group({
    techPointMobileAppId:            [''],
    serviceTypeId:                   null,
    service:                         null,
    dateFrom:                        prepareDateForForm(this.dateObj),
    dateTo:                          prepareDateForForm(this.dateObj)
  });

  // Observables
  exportSubscription: Subscription;
  loadSearchForm$ = this.route.queryParams.pipe(
    switchMap(params => {
     return forkJoin([
       this.nomenclatureService.findAllValidServices(),
       this.nomenclatureService.getServiceTypes()
       ]).pipe(
         tap(([services, serviceTypes]) => {
           this.services = services;
           this.serviceTypes = serviceTypes;
           this.searchSubject.next();
         }),
         catchError(err => {
           displayError(err);
           this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
           return EMPTY;
         }),
        repeatWhen(() => this.reload$)
        );
     }) 
  );

  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.incomeByServicesService.pageable(this.sortingPaging, this.searchForm.value,
                                                     prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                     prepareDateForDb(this.searchForm.get('dateTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private exportService: ExportService,
    private nomenclatureService: NomenclatureService,
    private technicalPointService: TechnicalPointService,
    private incomeByServicesService: IncomeByServicesService
  ) {
    super();
    this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  async ngOnInit() {
    await this.loadTechnicalPoints();
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  private async loadTechnicalPoints() {
    try {
      if (this.userTechnicalPointMobileAppIds.length === 0) {
        const result = await this.technicalPointService.findTechnicalPoints().toPromise();
        this.technicalPoints = result;
        this.hasTechnicalPoint = false;
      } else {
        const result = await this.technicalPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise();
        this.hasTechnicalPoint = true;
        this.technicalPoints = result;
        this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
      }
    } catch (err) {
      displayErrorFromUnknown(err);
    }
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      techPointMobileAppId:                 [''],
      serviceTypeId:                        null,
      service:                              null,
      dateFrom:                             prepareDateForForm(this.dateObj),
      dateTo:                               prepareDateForForm(this.dateObj)
    });

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
                                         this.translateService.instant("exportData.filesNames.incomeByServicesResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.incomeByServicesResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;
 
    let result = await this.incomeByServicesService.findAllByFilter(sortingPagingCopy, this.searchForm.value,
                                                                     prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                     prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                    ).pipe(first()).toPromise();
 
    result.content.forEach(object => {
       newContent?.push([
          object.techPointShortName,
          object.serviceTypeDesc,
          object.serviceName,
          object.serviceCount,
          object.totalAmount])
    })

    return newContent;
  }
 
  private getHeadings() : string[][] {
    return [[this.translateService.instant("references.point"), 
            this.translateService.instant("references.serviceType"), 
            this.translateService.instant("references.service"), 
            this.translateService.instant("references.count"), 
            this.translateService.instant("references.value")]]
  }
   
  private getFilterBody(): any[] {
   let tpName: string = "";
   let serviceName: string = ""
   let serviceType: string = "";
 
   this.technicalPoints.forEach(tp => {
     if (tp.mobileAppId == this.searchForm.get('techPointMobileAppId')?.value) {
       tpName = tp.shortName;
     }
   });

   if (this.serviceTypes) {
    this.serviceTypes.forEach(sType => {
      if(sType.id == this.searchForm.get('serviceTypeId')?.value) {
        serviceType = sType.description;
      }
    });
   }

   if (this.services) {
    this.services.forEach(service => {
      if(service.id == this.searchForm.get('service')?.value?.id) {
        serviceName = service.name;
      }
    });
   }

   let result: string[]= [
      tpName,
      serviceType,
      serviceName,
      convertObjDateToString(this.searchForm.get('dateFrom')?.value), 
      convertObjDateToString(this.searchForm.get('dateTo')?.value),
    ];
 
    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('references.point'),
      this.translateService.instant('references.serviceType'),
      this.translateService.instant('references.service'),
      this.translateService.instant('references.fromDate'),
      this.translateService.instant('references.toDate'),
    ];
 
    return [
      [{content: this.translateService.instant('references.incomeByServices'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

}