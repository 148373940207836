import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { TranslateService } from '@ngx-translate/core';
import { NomenclatureService } from '@services/nomenclature.service';
import { RvsCategoryService } from '@models/rvs-category-service.model';
import { StatusCode } from '@app/_enums/status-code';
import { AfterViewChecked, ChangeDetectorRef, Input, OnDestroy, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EMPTY, Observable, Subscription, forkJoin, of, Subject } from 'rxjs';
import { catchError, repeatWhen, tap, map, switchMap } from 'rxjs/operators';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { Status } from '@models/status.model';
import { CategoriesData } from '@app/tech-points/_helpers/categories-data.helper'
import { ServicesCardsContainer, ServicesCardsControlContainer, validateContainer } from '@app/tech-points/_helpers/services-cards-container.helper';
import { EventEmitter } from '@angular/core';

const MAIN_SERVICE_CODES: string[] = ['M', 'A'];

@Component({
  selector: 'app-technical-point-services',
  templateUrl: './technical-point-services.component.html',
  styleUrls: ['./technical-point-services.component.css']
})
export class TechnicalPointServicesComponent extends BaseChildComponent implements OnInit, OnDestroy, AfterViewChecked {
  
  readonly VAT_GROUPS: number[]         = [20, 9];
  readonly DEAFULT_VAT_GROUP: number    = 20;
  
  readonly ACTIVE_STATUS_CODE: string   = 'ACTIVE';
  readonly INACTIVE_STATUS_CODE: string = 'INACTIVE';

  readonly PRICE_KIND: string           = 'V';
  readonly PRICE_TYPE: string           = 'price';
  
  readonly GTP_CODE:  string            = 'GTP';

  @Input() selectedCategories$: Observable<number[]>;
  @Input() selectedCategoriesSubject: Subject<number[]>;

  @Output() formChangeEmmiter = new EventEmitter<void>();

  statuses: Status[];
  defaultMainServiceStatus?: Status;
  defaultCardServiceStatus?: Status;
  selectedCategories: number[] = [];
  
  //Error flags
  noGtpError: boolean = false;
  notAllCategoriesHaveServices : boolean = false;

  servicesContainer: ServicesCardsContainer = {
    services: [],
    subCards: []
  };

  //Subscriptions
  baseSubscription: Subscription = new Subscription();
  
  servicesForm = this.formBuilder.group({
    services: this.formBuilder.array([]),
    subCards: this.formBuilder.array([])
  });

  constructor(
    private formBuilder:            FormBuilder,
    private changeDetector:         ChangeDetectorRef,
    private translateService:       TranslateService,
    private nomenclatureService:    NomenclatureService,
    private technicalPointService:  TechnicalPointService,
  ) { 
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.baseSubscription.add(this.selectedCategories$.pipe(
      tap(() => {
        if (!this.isInitialized) {
          this.initialize();
        }
        this.isOpened = true;
      }),
      map(categories => this.processCategories(categories)),
      switchMap(categoriesData => {
        return this.processFormControls(categoriesData)
      }),
      map(this.splitControls),
      map(container => this.sortControlContainer(container)),
      tap(container => this.updateServiceForm(container))
    ).subscribe());

    this.baseSubscription.add(this.servicesForm.valueChanges.subscribe({
      next: () => {
        if (this.servicesForm.dirty) {
          this.formChangeEmmiter.emit();
        }
      }
    }));

    this.servicesForms.setErrors({ noGTPSelected : false })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.baseSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  toNewServiceControl(rvsService: RvsCategoryService, isCardService: boolean): AbstractControl {
    let cardPriceAtt = isCardService ? rvsService.cardPriceAttribute: null;
    let defaultStatus = isCardService ? this.defaultCardServiceStatus : this.defaultMainServiceStatus;

    return this.formBuilder.group({
      id:                 [null                                        ],
      isValid:            [isCardService ? true : false                ],    
      rvsService:         [rvsService                                  ],
      status:             [defaultStatus,           Validators.required],
      duration :          [null,                   [Validators.min(1), 
                                                    Validators.required]],
      validFrom:          [null,                                       ],
      validTo:            [null,                                       ],
      priceId:            [null                                        ],
      priceType:          [this.PRICE_TYPE                             ],
      priceAmount:        [cardPriceAtt != null ? 
                            cardPriceAtt.amount : null, 
                                                    [Validators.min(1), 
                                                    Validators.required]],
      priceAmountDds:     [cardPriceAtt != null ? 
                            cardPriceAtt.amountDds : null,             ],
      priceDdsValue:      [this.DEAFULT_VAT_GROUP,                     ],
      priceKind:          [this.PRICE_KIND                             ],

      isRemoved:          [false                                       ],
      servcieVersionData: [null                                        ],
      isRefreshing:       [false                                       ]
    });
  }

  loadServiceControl(service: TechPointService): AbstractControl {
    return this.formBuilder.group({
      id:                 [service.id                                            ],
      isValid:            [service.isValid                                       ],    
      rvsService:         [service.rvsService                                    ],
      status:             [service.status,                    Validators.required],
      duration :          [service.duration,                  [Validators.min(1), 
                                                              Validators.required]],
      validFrom:          [service.validFrom,                                    ],
      validTo:            [service.validTo,                                      ],
      priceId:            [service.priceAttribute?.id                            ],
      priceType:          [service.priceAttribute?.type                          ],
      priceAmount:        [service.priceAttribute?.amount,    [Validators.min(1), 
                                                               Validators.required]],
      priceAmountDds:     [service.priceAttribute?.amountDds,                    ],
      priceDdsValue:      [service.priceAttribute?.ddsValue,                     ],
      priceKind:          [service.priceAttribute?.kind                          ],

      isRemoved:          [false                                                 ],
      servcieVersionData: [service.serviceVersionData                            ],
      isRefreshing:       [false                                                 ]
    });
  }

  initialize() {
    if (this.isInitialized) return;

   this.baseSubscription.add(forkJoin([
      this.technicalPointService.findServicesByPointId(this.parentId),
      this.technicalPointService.getTechPointServicesNomenclature(StatusCode.TechnicalPointService)
    ]).pipe( 
      tap(([services, [statuses]]) => {
        this.initServicesAndNomenclatures(services, statuses);
      }),
      catchError(err => {
        console.log(err)
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true
    }));
  }

  initServicesAndNomenclatures(services: TechPointService[], statuses: Status[]) {
    this.servicesContainer = this.splitServices(services);
    this.statuses = statuses;
    this.defaultMainServiceStatus = this.statuses.find(status => status.code === this.INACTIVE_STATUS_CODE);
    this.defaultCardServiceStatus = this.statuses.find(status => status.code === this.ACTIVE_STATUS_CODE);

    if (this.servicesContainer != null && this.servicesContainer.services.length > 0) {
      if (this.selectedCategories == null || this.selectedCategories.length == 0) {
        let categories = 
          this.servicesContainer.services
                                .filter(service => service.isValid)
                                .map(service => service.rvsService.rvsCategory.id);

        categories = 
          categories.concat(
            this.servicesContainer.subCards.filter(service => service.isValid)
                                           .map(service => service.rvsService.rvsCategory.id)
          );

        this.selectedCategoriesSubject.next(categories);
      }
    }

    for (let service of this.servicesForms.controls) {
      if (service.get('status')?.value == null) {
        service.get('status')?.setValue(this.defaultMainServiceStatus);
      }
    }

    for (let cardService of this.subCardsForms.controls) {
      if (cardService.get('status')?.value == null) {
        cardService.get('status')?.patchValue(this.defaultCardServiceStatus);
      }
    }

    this.isInitialized = true;
  }

  calculatePriceAmountDds(index: number) {
    let service = this.servicesForms.at(index);

    let price = service.get('priceAmount')?.value;
    let vat = service.get('priceDdsValue')?.value;

    service.get('priceAmountDds')?.patchValue(price + ((vat / 100) * price));
  }

  trackByFn(index:any, item: any) {
    return index;
  }

  onChange() {
    this.validateServices();
  }

  validateServices() {
    let servicesValid = true;

    if (!this.checkServicesForCategories()) {
      servicesValid = false;
    } else {
      this.notAllCategoriesHaveServices = false;
    }

    if (!this.checkForGtpService()) {
      servicesValid = false;
      this.noGtpError = true;              
    } else {
      this.noGtpError = false;
    }

    for(let row of this.servicesForms.controls) {
      if (!row.get('isValid')?.value) {
        continue;
      }

      if (row.invalid) {
        servicesValid = false;
      }
    }

    if (!servicesValid) {
      this.isOpened = true;
    }
   
    return servicesValid;
  }

  refreshService(service: AbstractControl) {
    service.get('isRefreshing')?.patchValue(true);
    let categoryId = service.get('rvsService')?.value?.rvsCategory.id;
    let firstVersionId = service.get('servcieVersionData')?.value?.firstVersion?.id;

    this.nomenclatureService.findActualRvsCategoryService(firstVersionId, categoryId).subscribe({
      next: (data) => {
        service.get('rvsService')?.patchValue(data.rvsCategoryServiceDTO);
        service.get('servcieVersionData')?.patchValue(data.serviceVersionDataDTO);
        service.get('isRefreshing')?.patchValue(false);
      },
      error: (err) => {
        console.log(err)
        service.get('isRefreshing')?.patchValue(true);
      }
    })
  }

  private getServicesCountByCategory(categoryId: number) {
    let services = this.servicesForms.value as any[];

    return services.filter(service => service.rvsService?.rvsCategory?.id === categoryId)
                   .filter(service => service.isValid).length
  }

  private initService(rvsService: RvsCategoryService): AbstractControl {
    let techPointService = null;
    let isCardService = null;

    if (MAIN_SERVICE_CODES.includes(rvsService?.service?.serviceType.code!)) {
      techPointService = this.servicesContainer.services?.find(service => service?.rvsService?.id === rvsService.id) || null;
      isCardService = false;
    } else {
      techPointService = this.servicesContainer.subCards?.find(service => service?.rvsService?.id === rvsService.id) || null;
      isCardService = true;
    }

    if (techPointService == null) {
      return this.toNewServiceControl(rvsService, isCardService); 
    } else {
      return this.loadServiceControl(techPointService);
    }
  }

  private processFormControls(categoriesData: CategoriesData): Observable<AbstractControl[]> {
    if (categoriesData.newCategoriesIds != null && categoriesData.newCategoriesIds.length > 0) {
      return this.loadNewFormControls(categoriesData.newCategoriesIds);
    } else if (categoriesData.newCategoriesIds != null && categoriesData.removedCategoriesIds.length > 0) {
      return this.removeOldControls(categoriesData.removedCategoriesIds);
    }
    
    return EMPTY;
  }

  private loadNewFormControls(newCategoriesIds: number[]) {
    let wantedVersions = this.getWantedVersionFormContainer(this.servicesContainer, newCategoriesIds);

    return this.nomenclatureService.getRvsCategoriesByIds(newCategoriesIds, wantedVersions).pipe(
      map(categories => categories.map(service => this.initService(service))),
      map(controls => this.combineNewControlsAndCurrent(controls))
    );
  }

  private combineNewControlsAndCurrent(newControls: AbstractControl[]) {
    let currentControls = this.servicesForms.controls.concat(this.subCardsForms.controls);
    return newControls.concat(currentControls);
  }

  private updateServiceForm(container: ServicesCardsControlContainer) {
    this.servicesForms.clear();
    this.subCardsForms.clear();

    container.services.forEach(control => this.servicesForms.push(control));
    container.subCards.forEach(control => this.subCardsForms.push(control));
  }

  private removeOldControls(removedCategoriesIds: number[]): Observable<AbstractControl[]> {
    let currentControls = this.servicesForms.controls.concat(this.subCardsForms.controls);

    return of(currentControls.filter(control => !removedCategoriesIds.includes(control.value?.rvsService?.rvsCategory?.id))).pipe(
      tap(() => this.invalidateServices(removedCategoriesIds))
    );
  }

  private sortTwoServices(s1: AbstractControl, s2: AbstractControl): number {
    return s1.value?.rvsService?.rvsCategory?.id - s2.value?.rvsService?.rvsCategory?.id; 
  }

  private processCategories(categoriesIds: number[]): CategoriesData {
    let categoriesData = {
      newCategoriesIds:      [...new Set(categoriesIds.filter(id => !this.selectedCategories.includes(id)))],
      removedCategoriesIds:  [...new Set(this.selectedCategories.filter(id => !categoriesIds.includes(id)))]
    }

    this.selectedCategories = categoriesIds;

    return categoriesData;
  }

  private invalidateServices(categoriesIds: number[]) {
    if (validateContainer(this.servicesContainer)) {
      this.servicesContainer.services.filter(
        service => categoriesIds.includes(service.rvsService?.rvsCategory?.id)
      )
      .forEach(service => service.isValid = false);

      this.servicesContainer.subCards.filter(
        card => categoriesIds.includes(card.rvsService?.rvsCategory?.id)
      )
      .forEach(card => card.isValid = false);
    }
  }

  private splitServices(services: TechPointService[]): ServicesCardsContainer {
    let container: ServicesCardsContainer = {
      services: [],
      subCards: []
    }
    
    if (services == null) {
      return container;
    }
    
    services.forEach(service => {
      if (MAIN_SERVICE_CODES.includes(service?.rvsService?.service?.serviceType?.code!)) {
        container.services.push(service);
      } else {
        container.subCards.push(service);
      }
    });

    return container;
  }

  private splitControls(services: AbstractControl[]): ServicesCardsControlContainer {
    let container: ServicesCardsControlContainer = {
      services: [],
      subCards: []
    }

    services.forEach(service => {
      if (MAIN_SERVICE_CODES.includes(service?.get('rvsService')?.value.service?.serviceType?.code)) {
        container.services.push(service);
      } else {
        container.subCards.push(service);
      }
    });

    return container;
  }

  private sortControlContainer(container: ServicesCardsControlContainer): ServicesCardsControlContainer {
    return {
      services: container.services.sort(this.sortTwoServices),
      subCards: container.subCards.sort(this.sortTwoServices)
    };
  }

  private getWantedVersionFormContainer(container: ServicesCardsContainer, newCategoriesIds: number[]): number[] {
    if (!validateContainer(container)) {
      return [];
    }

    let mainServicesVersions =
      this.servicesContainer.services.filter(service => service.isValid)
                                     .filter(service => newCategoriesIds.includes(service.rvsService?.rvsCategory?.id))
                                     .map(service => service.rvsService.id!);

    let cardServicesVersions =
      this.servicesContainer.subCards.filter(service => service.isValid)
                                     .filter(service => newCategoriesIds.includes(service.rvsService?.rvsCategory?.id))
                                     .map(service => service.rvsService.id!);

    return mainServicesVersions.concat(cardServicesVersions);
  }

  private checkForGtpService() {
    let services = this.servicesForms.value as any[];

    return services.filter((service: any) => service.isValid)
                   .filter((service: any) => this.GTP_CODE === service.rvsService?.service?.code).length > 0;
  }

  private checkServicesForCategories() {
    let servicesValid = true;

    if (this.servicesForms.value.filter((service: any) => service.isValid === true).length <= 0) {
      this.notAllCategoriesHaveServices = true;
      servicesValid = false;
    }

    this.selectedCategories.forEach(category => {
      if (this.getServicesCountByCategory(category) <= 0) {
        servicesValid = false;
        this.notAllCategoriesHaveServices = true;
      }
    });

    return servicesValid;
  }

  get servicesForms(): FormArray {
    return this.servicesForm.get('services') as FormArray;
  }

  get subCardsForms(): FormArray {
    return this.servicesForm.get('subCards') as FormArray;
  }

  get combinedServicesForms() {
    let mainServices = this.servicesForms.value as any[];

    return mainServices.concat(this.subCardsForms.value);
  }

  get invalidPersistedServices(): TechPointService[] {
    if (!validateContainer(this.servicesContainer) || 
        (this.servicesContainer.services.length == 0 && this.servicesContainer.subCards.length == 0)) {
      return [];
    }

    let mainServices = 
      this.servicesContainer.services.filter(service => !this.selectedCategories.includes(service?.rvsService?.rvsCategory?.id))
                                     .filter(service => service.id != null && !service.isValid)

    let subCards = 
      this.servicesContainer.subCards.filter(service => !this.selectedCategories.includes(service?.rvsService?.rvsCategory?.id))
                                     .filter(service => service.id != null && !service.isValid)

    return mainServices.concat(subCards);
  }
}
