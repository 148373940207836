import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { CustomerCards } from '@app/card-config/_models/customer-cards.model';
import { CardProgramServiceService } from '@app/card-config/_services/card-program-service.service';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { CustomerCardGeneratorRequest } from '@app/card-config/_models/customer-card-generator-request.model';
import { ListCustomerCards } from '@app/card-config/_models/list-customer-cards.model';
import { catchError, first, repeatWhen, tap } from 'rxjs/operators';
import { LoyaltyProgramsEssential } from '@app/card-config/_models/loyalty-programs-essential.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Router } from '@angular/router';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { generatePdf } from '@app/_utils/pdf-util';
import { Subscription, forkJoin, EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { DataExcelGenerator } from '@app/card-config/_models/data-excel-generator.model';

@Component({
  selector: 'app-generator-card-loyal',
  templateUrl: './generator-card-loyal.component.html',
  styleUrls: ['./generator-card-loyal.component.css']
})
export class GeneratorCardLoyalComponent extends BaseSortableSearchComponent<CustomerCards> implements OnInit, OnDestroy {

  // Units
  cardRequest: CustomerCardGeneratorRequest;
  cardList:    ListCustomerCards[] = [];
  allCards:    ListCustomerCards[] = [];
  programs:    LoyaltyProgramsEssential[] = [];

  // Booleans
  isSubmited            = false; 
  isSearchSubmited      = false;
  loadPrograms          = false;
  loadData              = false;
  loadMultiCardBarcode  = false;

  // Payload
  private lastGeneratedProgramName: string;
  private firstCardNumber:          number;

  // File payload
  @ViewChild('fileInput') fileInput!: ElementRef;
  selectedFile:                       File | null = null;
  dataRows:                           Array<Array<any>> = [];
  cardsDataExcel                      = new Map<string, number>();

  // Form
  generateForm = this.formBuilder.group({
    numberOfCards:        [null, [Validators.required]],
    programMobileAppId:   [null, [Validators.required]],
  });

  excelForm = this.formBuilder.group({
    file: [],
    programMobileAppId: [null, [Validators.required]],
  });

  searchForm = this.formBuilder.group({
    cardNumber:   [null],
    cardBarcode:  [null],
    programName:  [null]
  });

  // Observables
  programsSubscription: Subscription;
  loadPrograms$ = forkJoin([
    this.programService.findAll()
  ]).pipe(
    tap(([programs]) => {
      this.programs = programs as LoyaltyProgramsEssential[];
      this.searchSubject.next();
    }), catchError(error => {
      displayError(error);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  );
    
  constructor (
    private perms:                PermissionsService,
    protected router:             Router,
    private formBuilder:          FormBuilder, 
    private programService:       CardProgramServiceService,
    private translateService:     TranslateService,
    private cardService:          CustomerCardService,
    private uiEvent:              UIEventCustom)  { 
      super();
  }

  async ngOnInit() {
    if (!this.perms.hasAccess(this.perms.CAN_ADD_EDIT_LOYALTY_CARD)) {
      this.router.navigate(['/']);
    }
    this.programs = [];
    this.programsSubscription = this.loadPrograms$.subscribe();
    this.cardList = [];
    this.allCards = [];
    this.lastGeneratedProgramName = '';
    this.generateForm.reset();
    this.searchForm.reset();
  }

  ngOnDestroy() {
    this.generateForm.reset();
    this.searchForm.reset();
    this.cardList = [];
    this.lastGeneratedProgramName = '';
    this.programsSubscription.unsubscribe();
  }

  async onSubmit() {
    this.dataLoaded = false;
    this.lastGeneratedProgramName = '';
    this.cardList = [];
    this.isSubmited = true;

    if (!this.generateForm.valid) {
      this.uiEvent.displayUIError();
      this.sortingPaging.pageNumber = 1;
      this.sortingPaging.fromRow = 1;
      this.sortingPaging.toRow = 1;
      this.sortingPaging.totalElements = 0;
      return;
    }

    this.setupCardRequestObjectData();
    this.loadData = true;
    this.cardService.generateCustomerCards(this.cardRequest).toPromise().then((raw) => {
      this.cardsDataExcel.clear();
      this.calculatePaging(raw, this.cardRequest.numberOfCards);
      this.uiEvent.displayUISuccess();
      this.generateForm.reset();
    }).catch(err => {
      displayError(err);
      this.dataLoaded = true;
      this.loadData = false;
      this.isSubmited = false;
      this.uiEvent.displayUIError();
    }); 
  }

  private setupCardRequestObjectData() {
    this.cardRequest = new CustomerCardGeneratorRequest();
    this.cardRequest.numberOfCards      = this.generateForm.get('numberOfCards')?.value;
    this.cardRequest.programMobileAppId = this.generateForm.get('programMobileAppId')?.value;
  }

  onSubmitSearchFormSort(event: any) {
    this.cardList.sort((a, b) => a.programName > b.programName ? 1 : -1);
  }

  pageChanged(page: number) {
    this.sortingPaging.pageNumber = page;
    this.loadCustomerCards(); 
  }

  loadCustomerCards() {
    this.dataLoaded = false;
    this.cardService.getPagableCustomerCards(this.sortingPaging, this.generateForm.value).pipe(first()).toPromise().then(resp => {
      this.cardList = [];
        const data = resp.content as ListCustomerCards[];
        data.forEach(d => {
          if (d.programName === this.lastGeneratedProgramName) {
            if (Number.parseInt(d.cardNumber) >= this.firstCardNumber) {
              this.cardList.push(d);
            }
          }
        });
        this.sortingPaging.fromRow = resp.fromRow;
        this.sortingPaging.toRow = resp.toRow;
        this.dataLoaded = true;
    })
    .catch(err => displayError(err));
  }

  loadTotalElements() {
    this.cardService.getTotalCustomerCards(this.sortingPaging, this.generateForm.value).toPromise().then(resp => {
      this.sortingPaging.totalElements = resp;
    })
    .catch(err => displayError(err));
  }

  async generateSingleCardBarcode(id: number) {
    await this.cardService.getSingleCardBarcode(id).toPromise().then(response => generatePdf(id, response))
    .catch(err => {
      err.customMessage = 'Something bad happend when generate single barcode. Cause: ' + err.message;
      displayError(err);
    });
  }

  async generateMultiCardBarcode() {
    this.loadMultiCardBarcode = true;
    let ids: number[] = [];
    this.allCards.forEach(c => ids.push(c.id));
    await this.cardService.getMultiCardBarcode(ids).toPromise().then(response => generatePdf('multi', response))
    .catch(err => {
      err.customMessage = 'Something bad happend when generate multi barcode. Cause: ' + err.message;
      displayError(err);
    });
    this.loadMultiCardBarcode = false;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (!this.selectedFile) return;

    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 }) as any;
      this.dataRows = data.slice(1); // first row consist headings
      this.dataRows.forEach((r: any) => {
        this.cardsDataExcel.set(r[0].toString(), r[1]);
      });
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onSubmitExcel() {
    if (!this.excelForm.valid) {
      this.uiEvent.displayUIError();
      return;
    }

    const request: DataExcelGenerator = {
      data: this.cardsDataExcel,
      programMobileAppId: this.excelForm.get('programMobileAppId')?.value,
    };

    this.cardService.generateCardsExcel(request).toPromise().then(raw => {
      this.calculatePaging(raw, this.cardsDataExcel.size);
      this.uiEvent.displayUISuccess();
      this.generateForm.reset();
      this.excelForm.reset();
    }).catch(err => {
      this.cardsDataExcel.clear();
      this.uiEvent.displayUIError();
      err.customMessage = 'Error occurs while creating cards from excel. Cause: ' + err.message;
      displayError(err);
    });
  }

  private calculatePaging(raw: any, collectionSize: number) {
    let data = raw as ListCustomerCards[];
    this.allCards = data;
    data = data.slice(0, collectionSize);
    this.sortingPaging.pageNumber = 1;
    this.sortingPaging.fromRow = 1;
    this.sortingPaging.toRow = Math.round(data.length / 10);
    this.sortingPaging.totalElements = data.length;
    this.cardList = data.slice(0, 10);
    if (this.cardList && this.cardList.length > 0) {
      this.firstCardNumber = Number.parseInt(this.cardList[0].cardNumber);
      this.lastGeneratedProgramName = this.cardList[0]?.programName;
    }
    this.cardList.sort((a,b) => a.id - b.id);
    this.isSubmited = false;
    this.loadData = false;
    this.dataLoaded = true;
  }

  get getForm() {
    return this.generateForm.controls;
  }

}