import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MapCoordinates } from '@interfaces/map-coordinates';
import * as L from 'leaflet';
import { Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() point: MapCoordinates | undefined;

  @Output() mapClickEvent = new EventEmitter<MapCoordinates>();

  addressCoordinatesSubject = new Subject<MapCoordinates>();
  addressCoordinatesSubscription: Subscription;

  private map: any;
  private marker: any = undefined;
  private defaulCenter: any = [42.394346, 25.1470824];

  constructor() { }

  ngOnDestroy(): void {
    this.addressCoordinatesSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.addressCoordinatesSubscription = this.addressCoordinatesSubject.subscribe({
      next: (point) => this.addMarker(point.lat, point.lng)
    });
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  initMap(): void {
    this.map = L.map('map', {
      center: this.getCoordinates(),
      zoom: this.point === undefined ? 7 : 18
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    if (this.point !== undefined) {
      this.addMarker(this.point?.lat, this.point?.lng);
    }

    this.map.on('click', (e: any) => {
      this.addMarker(e.latlng.lat, e.latlng.lng);
      this.mapClickEvent.emit({lat : e.latlng.lat, lng : e.latlng.lng});
    });
  }

  getCoordinates(): any {
    return this.point === undefined ? this.defaulCenter : [this.point?.lat, this.point?.lng];
  }

  addMarker(lat: number, lng: number): void {
    if (this.marker !== undefined) {
      this.map.removeLayer(this.marker);
    }

    this.marker = L.marker([lat, lng]);
    this.marker.addTo(this.map);
    this.map.setView([lat, lng], 18);
  }
}
