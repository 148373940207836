import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/security/_models/role.model';
import { SecurityService } from '@app/security/_services/security.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { ConciseTechPoint } from '@app/tech-points/_models/concise-technical-point.model'
import { YesNoEnum, YES_NO } from '@app/_enums/yes-no-enum';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { SearchSubjectVersion } from '@models/search-subject-version.model';
import { TranslateService } from '@ngx-translate/core';
import { SubjectVersionService } from '@services/subject-version.service';
import { OperatorFunction, Observable, of, forkJoin, Subscription, EMPTY } from 'rxjs';
import { debounceTime, switchMap, catchError, map, tap, concatMap, repeatWhen, filter } from 'rxjs/operators';
import { SubjectVersion } from '@models/subject-version.model';
import { User } from '@app/security/_models/user.model';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { displayError } from '@app/_utils/error-util';
import { mustMatch } from '@app/_utils/password-uti';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from '@app/user-profile/user-profile.component';
import { modalMinWidth } from '@env/config';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.css']
})
export class AddEditUserComponent extends BaseParentComponent implements OnInit, OnDestroy {
  
  // Core
  roles:                Role[];
  loadedTechPoints:     number[] | null;
  allConciseTechPoint:  ConciseTechPoint[] = [];

  // Constants
  readonly yesNoOptions: YesNoEnum[] = YES_NO;
  private readonly TECH_POINT_PERMS: string[] = ['IS_EMPLOYEE', 'IS_EMPLOYER'];

  // Booleans
  isSaving:             boolean = false;
  showTechPointSection: boolean = false;
  isOwner:              boolean | null = false;
  isUnchanged:          boolean | null = true;
  showComponent         = false;

  // Payload
  userId = 0;
  @ViewChild('changePassword') changePassword: TemplateRef<any>;
  dialogRef: any

  // Form
  userForm = this.formBuilder.group({
    id:             null,
    enabled:        [true, Validators.required],
    email:          [null, [Validators.required, Validators.email, Validators.maxLength(50)]],
    fullName:       [null, [Validators.required, Validators.maxLength(50)]],
    username:       [null, [Validators.required, Validators.maxLength(50)]],
    role:           [null, Validators.required],
    employer:       [null, Validators.required],
    techPoints:     this.formBuilder.array([])
  });
  
  userChangePassForm: FormGroup;

  // Observables
  private mainSub: Subscription = new Subscription();

  loadUser$ = this.route.queryParams.pipe(
    concatMap(params => {
      return forkJoin([
        this.securityService.findUserById(params['id']),
        this.securityService.getAllValidRoles()
      ]).pipe(
        tap(([user, roles]) => {
          this.roles = roles;
          this.loadedTechPoints = user?.techPointsIds || [];
          if (user != null) {
            this.userId = user.id || 0; 
            this.userForm.patchValue(user);
          }

          this.loadUserRole(user?.roleId);
        }),
        catchError(err => {
          console.log(err)
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
    input$.pipe(
      debounceTime(300),
      filter((input) => input.length > 3),
      switchMap(input =>
        this.subjectVersionService.filterSubjectVersions(input).pipe(
          map(data => data.slice(0,10)),// Getting the first 10 elements
          catchError(() => of([]))
        )
      )
  );

  constructor(
    private router:                 Router,
    private route:                  ActivatedRoute,
    private formBuilder:            FormBuilder,
    private uiEvent:                UIEventCustom,
    private securityService:        SecurityService,
    private techPointService:       TechnicalPointService,
    private translateService:       TranslateService,
    private subjectVersionService:  SubjectVersionService,
    private dialog:                 MatDialog
  ) {
    super(router);
  }

  ngOnInit() {
    this.showComponent = false;
    
    super.ngOnInit();
    this.subToEmployerChange();
    this.subToRoleChange();
    this.subToFormChange();
    this.reloadAllConciseTechPoints();

    this.showComponent = true;
  }

  private reloadAllConciseTechPoints() {
    this.techPointService.findConciseTechPointList().subscribe(data => {
      this.allConciseTechPoint = data;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.mainSub?.unsubscribe();
  }

  onSubmit() {
    if (this.userForm.invalid) {
      this.showValidations = true;
    }
    
    this.isSaving = true;
    this.securityService.saveUser(this.constructUser()).subscribe({
      next: (id) => {
        this.isSaving = false;
        this.uiEvent.displayUISuccess();
        this.router.navigate(['/list-users']);
      },
      error: err => {
        displayError(err);
        this.isSaving = false;
        this.uiEvent.displayUIError();
      } 
    });
  }

  subjectVersionFormatter(element: SubjectVersion) {
    return element.fullName;
  }

  onChangeForm() {
    if (this.userForm.dirty) {
      this.isUnchanged = null;
    }
  }

  onTechPointCheck(event: any, index: number) {
    if (event.target.checked) {
      for (let i = 0; i < this.techPointsForm.length; i++) {
        let techPoint = this.techPointsForm.at(i);
  
        if (index === i) {
          techPoint.enable();
        } else {
          techPoint.disable();
        }
      }
    } else {
      for (let i = 0; i < this.techPointsForm.length; i++) {
        let techPoint = this.techPointsForm.at(i);
        techPoint.enable();
      }
    }
  }

  private subToEmployerChange() {
    let subjectSub = this.form.employer?.valueChanges.pipe(
      switchMap((subject: SubjectVersion) => this.techPointService.findAllBySubject(subject?.id)),
      tap((techPoints => this.addTechPointsToForm(techPoints))),
      tap(() => this.loadTechPoints(this.loadedTechPoints))
    ).subscribe();

    this.mainSub.add(subjectSub);
  }

  private subToRoleChange() {
    let roleSub = this.form.role?.valueChanges.pipe(
      tap((role: Role) => this.checkForTechPointPerms(role)),
      tap((role: Role) => this.checkIsOwner(role)),
      tap(() => this.updateTechPoints())
    ).subscribe();

    this.mainSub.add(roleSub);
  }

  private subToFormChange() {
    this.mainSub.add(
      this.userForm.valueChanges.subscribe(() => this.onChangeForm())
    )
  }

  private loadUserRole(roleId: number) {
    let role = this.roles.find(r => r.id === roleId)

    if (role != null) {
      this.userForm.controls.role?.patchValue(role);
      this.checkForTechPointPerms(role);
      this.checkIsOwner(role);
    }
  }

  private loadTechPoints(userTechPoints: number[] | null) {
    if (userTechPoints == null) {
      return
    };

    if (!this.isOwner && userTechPoints?.length === 1) {
      this.disableById(userTechPoints[0]);
      this.loadedTechPoints = null;
    }
  }

  private disableById(id: number) {
    for (let i = 0; i < this.techPointsForm.length; i++) {
      let techPoint = this.techPointsForm.at(i);

      if (id == techPoint.get('id')?.value) {
        techPoint.enable();
        techPoint.get('isValid')?.patchValue(true);
      } else {
        techPoint.disable();
      }
    }
  }

  private constructUser(): User {
    let userData = this.userForm.value;
    
    return {
      id:              userData.id                                   || null,
      enabled:         userData.enabled                              || null,
      email:           userData.email                                || null,
      fullName:        userData.fullName                             || null,
      username:        userData.username                             || null,
      roleId:          userData.role?.id                             || null,
      isActive:        true                                          || null,
      employer:        this.constructEmployer(userData.employer)     || null,
      techPointsIds:   this.constructTechPoints(userData.techPoints) || null,
    }
  }

  private constructTechPoints(techPoints: any[]): number[] {
    return techPoints.filter(tp => tp.isValid).map(tp => tp.id);
  }

  private constructEmployer(data: any) {
    return {
      id:       data?.id,
      fullName: data?.fullName
    }
  }

  private addTechPointsToForm(techPoints: ConciseTechPoint[]) {
    this.techPointsForm.clear();

    if (!techPoints || !techPoints.length) {
      techPoints = this.allConciseTechPoint;
    }

    for (let point of techPoints) {
      let newTechPoint = this.formBuilder.group({
        id:       point.id,
        name:     point.name,
        isValid:  this.isOwner
      });

      if (this.isOwner) {
        newTechPoint.disable();
      }

      this.techPointsForm.push(newTechPoint);
    }
  }

  private checkForTechPointPerms(role: Role) {
    for (let perm of this.TECH_POINT_PERMS) {
      this.showTechPointSection = this.checkForPerm(role, perm);

      if (this.showTechPointSection) {
        break;
      }
    }
  }

  private checkIsOwner(role: Role) {
    this.isOwner = this.checkForPerm(role, this.TECH_POINT_PERMS[1]) || null;
  }

  private checkForPerm(role: Role, perm: string) {
    return role?.permissions?.map(p => p.name).includes(perm) || false;
  }

  private updateTechPoints(): void {
    for (let control of this.techPointsForm.controls) {
      control.get('isValid')?.patchValue(this.isOwner);
    }
  }

  get form() {
    return this.userForm.controls;
  }

  get techPointsForm() {
    return this.userForm.controls?.techPoints as FormArray;
  }

  openChangePasswordModal() {
    if (this.dialog.openDialogs.length == 0) {
      localStorage.setItem('edit-user-pass', this.userId.toString());
      this.dialog.open(UserProfileComponent, modalMinWidth);
    }
  }

}