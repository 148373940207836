<div class="container mainbox">
    <div class="row justify-content-center">
        <h1 translate>cardLoyal.config</h1>
    </div>
    <ng-container *ngIf="displayComponent">
        <div id="accordion">
            <div class="container" *ngIf="loadNomenclatureData$ | async as data; else dataLoadingTemplate">
                <!-- ****************************************************** 
                ************** Program Form *************
                ****************************************************** -->
                <form [formGroup]="programForm">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="col-lg-12 mb-4">
                                <div class="card">
                                    <div class="card-body">
                                        <!-- Card Name -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6">
                                                <label class="font-weight-bold" translate>cardLoyal.name</label>
                                                <input type="text" formControlName="programName" class="form-control input-custom-size" id="programName" name="programName" required
                                                    [ngClass]="{ 'is-invalid': !getForm['programName'].valid && isSubmited }" 
                                                    placeholder="{{'placeholder.enter' | translate}}" />
                                                <small class="text-danger"
                                                    *ngIf="!getForm['programName'].valid && isSubmited" translate>
                                                    errors.required
                                                </small>
                                            </div>
                                        </div>

                                        <!-- Status -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6">
                                                <label class="font-weight-bold" translate>cardLoyal.status</label>
                                                <select class="custom-select input-custom-size" formControlName="status" id="status"
                                                    [ngClass]="{ 'is-invalid': !getForm['status'].valid && isSubmited }">
                                                    <option *ngFor="let sts of statuses" [ngValue]="sts"> {{ sts.name }} </option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <!-- Type -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6">
                                                <label class="font-weight-bold" translate>cardLoyal.type</label>
                                                <select class="custom-select input-custom-size" formControlName="type" id="type" name="type"
                                                        [ngClass]="{'is-invalid': !getForm['type'].valid && isSubmited}">
                                                    <option *ngFor="let type of types" [ngValue]="type">
                                                        {{ type.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <!-- Discount -->
                                        <ng-container *ngIf="getForm['type'].value.name === 'Статична'">
                                            <div class="row justify-content-center">
                                                <div class="form-group col-md-6">
                                                    <label class="font-weight-bold" translate>cardLoyal.discount</label>
                                                    <select class="custom-select input-custom-size" formControlName="discount" id="discount" name="discount" 
                                                        [ngClass]="{ 'is-invalid': isSubmited && isDiscountReq && getForm['type'].value.name === 'Статична' && !getForm['discount'].value }" >
                                                        <!-- <option disabled selected value translate>placeholder.select</option> -->
                                                        <option value="null" translate>techPoint.pleaseChoose</option>
                                                        <option *ngFor="let dis of customerDiscounts" [ngValue]="dis">
                                                            {{ dis.discountName }}
                                                        </option>
                                                    </select>
                                                    <ng-container *ngIf="getForm['type'].value.name === 'Статична'">
                                                        <small class="text-danger"
                                                            *ngIf="isSubmited && isDiscountReq && !getForm['discount'].value" translate>
                                                            errors.required
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <!-- Impulse Discount -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6">
                                                <label class="font-weight-bold" translate>cardLoyal.impulseDiscount</label>
                                                <select class="custom-select input-custom-size" formControlName="impulseDiscount" id="impulseDiscount" name="impulseDiscount">
                                                    <!-- <option selected value translate>placeholder.select</option> -->
                                                    <option *ngFor="let dis of impulseCustomerDiscounts" [ngValue]="dis">
                                                        {{ dis.discountName }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <!-- Valid From -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6">
                                                <label class="font-weight-bold" translate>cardLoyal.validFrom</label>
                                                <div class="input-group mt-1"> 
                                                    <input class="form-control d-inline" style="max-width: 380pt;" aria-describedby="validFrom" formControlName="validFrom"
                                                        [owlDateTime]="dt1" id="validFrom" name="validFrom" 
                                                        [ngClass]="{ 'is-invalid': isDateActive }" readonly>
                                                    <div class="input-group-append">
                                                        <button [owlDateTimeTrigger]="dt1" class="btn btn-outline-secondary fa fa-calendar"></button>
                                                    </div>
                                                    <owl-date-time #dt1></owl-date-time>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Valid To -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6">
                                                <label class="font-weight-bold" translate>cardLoyal.validTo</label>
                                                <div class="input-group mt-1"> 
                                                    <input class="form-control d-inline" style="max-width: 380pt;" aria-describedby="validTo" formControlName="validTo"
                                                    [owlDateTime]="dt2" id="validTo" name="validTo"
                                                    [ngClass]="{ 'is-invalid': isDateActive}" readonly>
                                                    <div class="input-group-append">
                                                        <button [owlDateTimeTrigger]="dt2" class="btn btn-outline-secondary fa fa-calendar"></button>
                                                    </div>
                                                    <owl-date-time #dt2></owl-date-time>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Choose subject -->
                                        <div class="row justify-content-center">
                                            <div class="form-group col-md-6" style="margin-top: 1rem;">
                                                <button class="btn btn-primary" type="button" (click)="openModal()" translate>technicalInspection.chooseSubject</button>
                                                <ng-container *ngIf="subject">
                                                    <div [ngClass]="{'subject-exists': subject}">
                                                        <span style="font-size: 1.4rem; font-weight: 500; border-bottom: 3px solid #333;" translate>cardLoyal.choosedSubject</span>
                                                        <div style="margin-top: 1.2rem; font-size: 1.2rem; text-align: center;" *ngIf="subject.fullName"><span translate>cardLoyal.subjectName</span>: {{ subject.fullName }}</div>
                                                        <div style="font-size: 1.2rem; margin-bottom: 1.2rem; text-align: center;" *ngIf="subject.address"><span translate>cardLoyal.subjectAddress</span>: {{ subject.address }}</div>
                                                        <button type="submit" class="btn btn-outline-secondary w-20" (click)="onRemoveSubject()" translate>button.delete</button>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Condition Table Data *************
                    ****************************************************** -->
                    <div *ngIf="getForm['type'].value && getForm['type'].value.name === 'Прогресивна'">
                        <div class="row">
                            <div class="col-lg-12 mb-4">
                                <div class="col-lg-12 mb-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="form-group form-row">
                                                <div class="col text-left">
                                                    <h4 translate>cardLoyal.discountCount</h4>
                                                </div>
                                                <div class="col text-right">
                                                    <button _ngcontent-byn-c215="" type="button" class="btn btn-outline-success ml-2 mb-2" 
                                                        translate (click)="addRow()">button.add
                                                    </button>
                                                </div>
                                                <table class="table table-bordered table-striped w-100">
                                                    <thead>
                                                        <tr>
                                                            <th translate>cardLoyal.fromNumber</th>
                                                            <th translate>cardLoyal.toNumber</th>
                                                            <th translate>cardLoyal.discount</th>
                                                            <th translate>button.delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody formArrayName="conditionList">
                                                        <small class="text-danger" *ngIf="isSubmited && isNeedConditions && isAllConditionsInvalid" translate>
                                                            errors.atLeastOneElement
                                                        </small>
                                                        <ng-container *ngFor="let row of rows.controls; let i = index"
                                                            [formGroupName]="i">
                                                            <tr *ngIf="row.get('isValid')?.value">
                                                                <!-- From Number -->
                                                                <td>
                                                                    <div>
                                                                        <input type="number" min=0 class="form-control"
                                                                            formControlName="fromNumber"  id="fromNumber" name="fromNumber" required
                                                                            [ngClass]="{ 'is-invalid': showValidations$ && rows.controls[i]?.get('fromNumber')?.errors && isSubmited }" />
                                                                    </div>
                                                                    <small class="text-danger"
                                                                        *ngIf="rows.controls[i]?.get('fromNumber')?.errors?.required && showValidations$ && isSubmited"
                                                                        translate>
                                                                        errors.required
                                                                    </small>
                                                                </td>
                                                                <!-- To Number -->
                                                                <td>
                                                                    <div>
                                                                        <input type="number" min=0 class="form-control" 
                                                                            formControlName="toNumber" id="toNumber" name="toNumber" required
                                                                            [ngClass]="{ 'is-invalid': showValidations$ && rows.controls[i]?.get('toNumber')?.errors && isSubmited }" />
                                                                        <small class="text-danger"
                                                                            *ngIf="rows.controls[i]?.get('toNumber')?.errors?.required && showValidations$ && isSubmited"
                                                                            translate>
                                                                            errors.required
                                                                        </small>
                                                                    </div>
                                                                </td>
                                                                <!-- Discount -->
                                                                <td>
                                                                    <select class="custom-select" formControlName="discount" id="discount" name="discount" required
                                                                        [ngClass]="{ 'is-invalid': showValidations$ && rows.controls[i]?.get('discount')?.errors && isSubmited }" >
                                                                        <ng-container *ngIf="!conditionDiscounts">
                                                                            <option disabled selected value translate>placeholder.enter</option>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="conditionDiscounts && conditionDiscounts[i]">
                                                                            <option [ngValue]="conditionDiscounts[i]" selected>{{ conditionDiscounts[i].discountName }}</option>                                                                        
                                                                        </ng-container>
                                                                        <option *ngFor="let dis of customerDiscounts" 
                                                                            [ngValue]="dis">
                                                                            {{ dis.discountName }}
                                                                        </option>
                                                                    </select>
                                                                    <small class="text-danger"
                                                                        *ngIf="rows.controls[i]?.get('discount')?.errors?.required && showValidations$ && isSubmited"
                                                                        translate>
                                                                        errors.required
                                                                    </small>
                                                                </td>
                                                                <!-- Delete -->
                                                                <td class="text-center" >
                                                                    <button class="btn btn-danger btn-sm rounded-0" type="button" (click)="deleteRow(i)">
                                                                        <em class="fa fa-trash"></em>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="form-group form-row">
                    <div class="col text-right">
                        <button _ngcontent-vga-c215="" type="submit" class="btn btn-primary w-20" (click)="onSubmit()" translate>button.save</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger text-center">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>