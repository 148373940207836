import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router:             Router,
        private toastr:             ToastrService,
        private translateService:   TranslateService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (AuthenticationService.getAccessToken()) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        forkJoin([
            this.translateService.get('toast.errorTitle'),
            this.translateService.get('toast.notLoggedIn')  
        ]).subscribe({
            next: ([title, message]) => this.toastr.error(message, title) 
        });

        this.router.navigate(['']);

        return false;
    }
}