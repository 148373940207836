import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY } from 'rxjs';
import { CustomerRvs } from '@app/customers/_models/customer-rvs.model';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { CustomerService } from '@app/customers/_services/customer.service';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-client-profile-rvs',
  templateUrl: './client-profile-rvs.component.html',
  styleUrls: ['./client-profile-rvs.component.css']
})
export class ClientProfileRvsComponent extends BaseChildComponent implements OnInit {
  customerRvs: CustomerRvs[];
  carImages: any[] = [];
  certificateImages: any[] = [];
  @Input() customerPhoneNum: String;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void { }

  initialize() {
    if (this.isInitialized) return;

    this.customerService.findRvsByCustomerId(this.parentId).pipe(
      tap(data => this.customerRvs = data),
      tap(customerRvsArray => {
        customerRvsArray.forEach(rvs => this.getImage(rvs, 'pictures')),
        customerRvsArray.forEach(rvs => this.getImage(rvs, 'certificates'))
      }),
      catchError(err => {
        console.log(err)
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true
    })
  }

  getImage(customerRvs: CustomerRvs, type: string) {
    let filePath = "/" + this.customerPhoneNum + "/cars/" + customerRvs.mobileAppId + "/" + type + "/" ;
 
    this.customerService.getImageFromFTP(filePath).subscribe(response => {  
      if(response.size != 0) {
        if(type == 'pictures') {
          response.forEach((el: any) => {
            this.carImages.push(this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + el))
          });
        } else {
          response.forEach((el: any) => {
            this.certificateImages.push(this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + el))
          });
        }
      } 
    });
  }
  
}
