import { EMPTY, forkJoin, from, Observable } from 'rxjs';
import { WorkingHours } from '@models/working-hours.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RvsCategory } from '@models/rvs-category.model';
import { City } from '@models/city.model';
import { RejectReason } from '@models/reject-reason.model';
import { RvsCategoryService} from '@models/rvs-category-service.model'
import { Status } from '@models/status.model';
import { ServiceType } from '@models/service-type.model';
import { CustomerDiscount } from '@models/customer-discount.model';
import { defaultIfEmpty, map, shareReplay } from 'rxjs/operators';
import { SortingPaging } from '@helpers/sorting-paging';
import { Page } from '@interfaces/page';
import { FormGroup } from '@angular/forms';
import { Service } from '@app/services/_models/service.model';
import { ServiceAddInfo } from '@app/service-configs/_models/service-add-info.model';
import { ListServiceAddInfo } from '@app/service-configs/_models/list-service-add-info.model';
import { SubjectType } from '@models/subject-type.model';
import { RefreshRvsCategoryService } from '@models/refresh-rvs-category-service.model'
import { PaymentType } from '@models/payment-type.model';
import { MeasuringUnit } from '@app/accounting_documents/_models/measuring-unit.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { CustomerDiscounts } from '@app/customer-discounts/model/customer-discounts.model';
import { ListCustomerDiscounts } from '@app/customer-discounts/model/list-customer-discounts.model';
import { LoyaltyProgramTypes } from '@app/card-config/_models/loyalty-program-types.model';
import { DiscountType } from '@models/discount-type.model';

const apiServerUrl     = environment.apiBaseURL;
const nomenclatureUrl  = apiServerUrl + '/nomenclatures';

const allRvsCategoriesUrl     = nomenclatureUrl + '/all-rvs-category';
const rvsCategoriesUrl        = nomenclatureUrl + '/rvs-category/{id}';;
const rejectReasonsUrl        = nomenclatureUrl + '/reject-reason';
const timeConstraint          = nomenclatureUrl + '/time-constraints';
const serviceTypesUrl         = nomenclatureUrl + '/service-types';
const serviceTypesByCodeUrl   = nomenclatureUrl + '/service-types/{code}'
const citiesUrl               = nomenclatureUrl + '/cities'
const techPointCitiesUrl      = nomenclatureUrl + '/tech-point-cities'
const subjectTypesUrl         = nomenclatureUrl + '/subject-types';
const statusesByTypeUrl       = nomenclatureUrl + '/statuses/{type}';
const customerDiscountsUrl    = nomenclatureUrl + '/customer-discounts'
const listPaymentTypesURL     = nomenclatureUrl + '/payment-types';
const measuringUnitsUrl       = nomenclatureUrl + '/measuring-units';
const loyaltyProgramTypes     = nomenclatureUrl + '/loyalty-program-types';

const basicsRvsCategories             = nomenclatureUrl + '/rvs-categories-services/basics';
const additionalPackagesRvsCategories = nomenclatureUrl + '/rvs-categories-services/additional-packages';
const subscriptionsRvsCategories      = nomenclatureUrl + '/rvs-categories-services/subscriptions';
const custDiscountsBySubjTypeUrl      = nomenclatureUrl + '/customer-discounts/{subjectTypeId}'
const custDiscountsByDiscountTypeUrl  = nomenclatureUrl + '/customer-discount/{discountTypeId}'
const rvsServicesByIds                = nomenclatureUrl + '/rvs-categories-services';
const rvsCategoryServicesByServiceId  = nomenclatureUrl + '/rvs-categories-services/service/{id}';
const findActualRvsCategoryService    = nomenclatureUrl + '/rvs-categories-services/actual';

const findServiceByIdURL                    = nomenclatureUrl + '/service/find/{id}';
const pageableServiceTotalElements          = nomenclatureUrl + '/service/pageable/count';
const pageableServices                      = nomenclatureUrl + '/service/pageable';
const validServicesURL                      = nomenclatureUrl + '/service/all-valid';
const validServicesByTypeIdURL              = nomenclatureUrl + '/service/valid/{typeId}';
const validServicesForPromo                 = nomenclatureUrl + '/service/all-valid-for-promo';
const validServicesByTypeURL                = nomenclatureUrl + '/service/all-valid/{type}'
const saveServiceURL                        = nomenclatureUrl + '/service/save';
const findActualServiceByFirstVersion       = nomenclatureUrl + '/service/actual/{id}';
const findRvsCategoriesForService           = nomenclatureUrl + '/service/{id}/rvs-categories';
const findRvsCategoriesForPromoService      = nomenclatureUrl + '/service/promo/rvs-categories';

const validServicesByTypeAndRvsCategoriesURL = nomenclatureUrl + '/service/all-valid-by-type-rvs-cat/{type}/';
const findServiceAddInfoByIdURL              = nomenclatureUrl + '/service-add-info/find/{id}';
const saveServiceAddInfoURL                  = nomenclatureUrl + '/service-add-info/save';
const pageableServiceAddInfosTotalElements   = nomenclatureUrl + '/service-add-info/pageable/count';
const pageableServiceAddInfos                = nomenclatureUrl + '/service-add-info/pageable';
const serviceAddInfosByChildService          = nomenclatureUrl + '/service-add-info/service/{id}';
const findActualServiceAddInfoByFirstVersion = nomenclatureUrl + '/service-add-info/actual/{id}';

const saveCustomerDiscountURL                = nomenclatureUrl + '/customer-discounts/save';
const findCustomerDiscountsPageableURL       = nomenclatureUrl + '/customer-discounts/pageable';
const countCustomerDiscountsPageableURL      = nomenclatureUrl + '/customer-discounts/pageable/count';
const findCustomerDiscountByIdURL            = nomenclatureUrl + '/customer-discount/find/{id}';

const filterDiscountsUrl                     = nomenclatureUrl + '/filter-discounts';

const discountTypesUrl                       = nomenclatureUrl + '/discount-types';

@Injectable({
  providedIn: 'root'
})
export class NomenclatureService {

  constructor(
    private http: HttpClient
  ) { }

  public getTimeConstraints(): Observable<WorkingHours> {
    return this.http.get<WorkingHours>(timeConstraint);
  }

  public getRvsCategories(): Observable<RvsCategory[]> {
    return this.http.get<RvsCategory[]>(allRvsCategoriesUrl);
  }

  public getRvsCategoriesByTechPointId(techPointId: number | undefined): Observable<RvsCategory[]> {
    if (techPointId == null) return this.returnEmptyObservable();
    return this.http.get<RvsCategory[]>(rvsCategoriesUrl.replace('{id}', `${techPointId}`)).pipe(shareReplay());
  }

  private returnEmptyObservable() {
    return EMPTY.pipe(
      defaultIfEmpty()
    );
  }

  public getCities(): Observable<City[]> {
    return this.http.get<City[]>(citiesUrl);
  }

  public getTechPointCities(): Observable<City[]> {
    return this.http.get<City[]>(techPointCitiesUrl);
  }

  public getRejectReasons(): Observable<RejectReason[]> {
      return this.http.get<RejectReason[]>(rejectReasonsUrl).pipe(shareReplay());
  }

  public getServiceTypes(): Observable<ServiceType[]> {
    return this.http.get<ServiceType[]>(serviceTypesUrl).pipe(shareReplay());
  }

  public getPaymentTypes(): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(listPaymentTypesURL);
  }

  public getServiceTypesByCode(code: string): Observable<ServiceType[]> {
    return this.http.get<ServiceType[]>(serviceTypesByCodeUrl.replace('{code}', `${code}`));
  }

  public getCustomerDiscounts(): Observable<CustomerDiscount[]> {
    return this.http.get<CustomerDiscount[]>(customerDiscountsUrl).pipe(shareReplay());
  }

  public getCustomerDiscountsBySubjectType(subjectTypeId: number): Observable<CustomerDiscount[]> {
    return this.http.get<CustomerDiscount[]>(custDiscountsBySubjTypeUrl.replace('{subjectTypeId}', `${subjectTypeId}`)).pipe(shareReplay());
  }

  public getCustomerDiscountsByDiscountType(discountTypeId: number): Observable<CustomerDiscount[]> {
    return this.http.get<CustomerDiscount[]>(custDiscountsByDiscountTypeUrl.replace('{discountTypeId}', `${discountTypeId}`)).pipe(shareReplay());
  }

  public getBasicsRvsCategoriesServices(): Observable<RvsCategoryService[]>{
    return this.http.get<RvsCategoryService[]>(basicsRvsCategories);
  }  

  public getAdditionalPackagesRvsCategoriesServices(): Observable<RvsCategoryService[]>{
    return this.http.get<RvsCategoryService[]>(additionalPackagesRvsCategories);
  } 

  public getSubscriptionRvsCategoriesServices(): Observable<RvsCategoryService[]>{
    return this.http.get<RvsCategoryService[]>(subscriptionsRvsCategories);
  } 

  public getRvsCategoryServicesByServiceId(id: number): Observable<RvsCategoryService[]>{
    return this.http.get<RvsCategoryService[]>(rvsCategoryServicesByServiceId.replace('{id}', `${id}`));
  }

  public getStatusesByType(type: string): Observable<Status[]>{
    return this.http.get<Status[]>(statusesByTypeUrl.replace('{type}', `${type}`)).pipe(shareReplay());
  }

  public getRvsCategoriesByIds(newCategoriesIds: number[], wantedVersions: Array<number>): Observable<RvsCategoryService[]> {
    if (newCategoriesIds == null || newCategoriesIds.length == 0) {
      return from([]);
    }

    let params = new HttpParams().set('rvsCategories', newCategoriesIds.toString());

    if (wantedVersions != null && wantedVersions.length > 0) {
      params = params.set('wantedVersions', wantedVersions.toString())
    }

    return this.http.get<RvsCategoryService[]>(rvsServicesByIds, {params : params});
  }

  public getRvsCategoriesForService(id: number) {
    if (id == null) {
      return from([]);
    }

    let url = findRvsCategoriesForService.replace('{id}', id.toString());
    return this.http.get<RvsCategory[]>(url);
  }

  public getRvsCategoriesForPromoServices() {
    return this.http.get<Map<number, RvsCategory[]>>(findRvsCategoriesForPromoService).pipe(
      map((result: any) => {
        let map = new Map<number, RvsCategory[]>();

        for (const key in result) {
          if (result.hasOwnProperty(key)) {
            let value = result[key];
            map.set(parseInt(key), value);
          }
        }

        return map;
      })
    );
  }

  public findServiceById(id: number): Observable<Service> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<Service>(findServiceByIdURL.replace('{id}', `${id}`));
  }

  public pageableServices(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.findAllServicesByFilter(sortingPaging, searchForm),
      this.countAllServicesByFilter(sortingPaging, searchForm)
    ]);
  }

  public findAllServicesByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<Service>> {
    return this.http.get<Page<Service>>(pageableServices, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
  }

  public countAllServicesByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
    return this.http.get<number>(pageableServiceTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
  }
  
  public findAllValidServices(): Observable<Service[]> {
    return this.http.get<Service[]>(validServicesURL).pipe(shareReplay());
  }

  public findAllValidServicesByTypeId(typeId: number): Observable<Service[]> {
    return this.http.get<Service[]>(validServicesByTypeIdURL.replace('{typeId}', `${typeId}`)).pipe(shareReplay());
  }

  public findAllValidServicesByTypeCode(type: string): Observable<Service[]> {
    return this.http.get<Service[]>(validServicesByTypeURL.replace('{type}', `${type}`));
  }

  public findAllValidServicesByTypeCodeAndRvsCategories(type: string, rvsCategoriesIds: number[]): Observable<Service[]> {
    let requestParams = new HttpParams().set("rvsCategoriesIds", rvsCategoriesIds.toString())
    return this.http.get<Service[]>(validServicesByTypeAndRvsCategoriesURL.replace('{type}', `${type}`),{params : requestParams});
  }

  public findAllValidServicesForPromo() {
    return this.http.get<Service[]>(validServicesForPromo);
  }

  public saveService(service: Service): Observable<any> {
    return this.http.post(saveServiceURL, service);
  }

  public findCustomerDiscountById(id: number): Observable<CustomerDiscounts> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<CustomerDiscounts>(findCustomerDiscountByIdURL.replace('{id}', `${id}`));
  }

  public saveCustomerDiscount(customerDiscount: CustomerDiscount): Observable<any> {
    return this.http.post(saveCustomerDiscountURL, customerDiscount);
  }

  public pageableCustomerDiscounts(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.findCustomerDiscountsByFilter(sortingPaging, searchForm),
      this.countCustomerDiscountsByFilter(sortingPaging, searchForm)
    ]);
  }

  public findCustomerDiscountsByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<ListCustomerDiscounts>> {
    return this.http.get<Page<ListCustomerDiscounts>>(findCustomerDiscountsPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  public countCustomerDiscountsByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
    return this.http.get<number>(countCustomerDiscountsPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  private constructPageableHttpParams(sortingPaging: SortingPagingData, searchForm: any) {
    let params = new HttpParams().set('pageSize', sortingPaging.pageSize.toString())
                                .set('page', sortingPaging.pageNumber.toString());
                                
    if (sortingPaging.isSortingValid()) {
      params = params
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    if (searchForm.discountName != null) {
      params = params.set('discountName', searchForm.discountName);
    }

    if (searchForm.isValid != null) {
      params = params.set('isValid', searchForm.isValid);
    }

    if (searchForm.discountType != null) {
      params = params.set('discountType', searchForm.discountType);
    }

    return params;
  }

  public findActualServiceByFirstVersion(id: number): Observable<Service> {
    return this.http.get<Service>(findActualServiceByFirstVersion.replace('{id}', `${id}`));
  }

  public findServiceAddInfoById(id: number): Observable<ServiceAddInfo> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<ServiceAddInfo>(findServiceAddInfoByIdURL.replace('{id}', `${id}`));
  }

  public saveServiceAddInfo(serviceAddInfo: ServiceAddInfo): Observable<any> {
    return this.http.post(saveServiceAddInfoURL, serviceAddInfo);
  }

  public pageableServiceAddInfos(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.findAllServiceAddInfosByFilter(sortingPaging, searchForm),
      this.countAllServiceAddInfosByFilter(sortingPaging, searchForm)
    ]);
  }

  public findAllServiceAddInfosByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<ListServiceAddInfo>> {
    return this.http.get<Page<ListServiceAddInfo>>(pageableServiceAddInfos, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
  }

  public countAllServiceAddInfosByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
    return this.http.get<number>(pageableServiceAddInfosTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
  }

  public findAllValidServiceAddInfosByChildServiceId(id?: number): Observable<ServiceAddInfo[]> {
    return this.http.get<ServiceAddInfo[]>(serviceAddInfosByChildService.replace('{id}', `${id}`));
  }

  public findActualServiceAddInfoByFirstVersion(id: number): Observable<ServiceAddInfo> {
    return this.http.get<ServiceAddInfo>(findActualServiceAddInfoByFirstVersion.replace('{id}', `${id}`));
  }

  public findActualRvsCategoryService(firstVersion: number, categoryId: number) {
    if (firstVersion == null || categoryId == null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('firstVersion', firstVersion.toString());
    httpParams = httpParams.set('categoryId', categoryId.toString())

    return this.http.get<RefreshRvsCategoryService>(findActualRvsCategoryService, { params: httpParams});
  }

  public getMeasuringUnits(): Observable<MeasuringUnit[]> {
    return this.http.get<MeasuringUnit[]>(measuringUnitsUrl);
  }

  public getLoyaltyProgramTypes(): Observable<LoyaltyProgramTypes[]> {
    return this.http.get<LoyaltyProgramTypes[]>(loyaltyProgramTypes).pipe(shareReplay());
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchObject: FormGroup) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
     }

    Object.entries(searchObject).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });

    return requestParams;
  }

  public getSubjectTypes(): Observable<SubjectType[]> {
    return this.http.get<SubjectType[]>(subjectTypesUrl);
  }

  public filterCustomerDiscounts(name: string) {
    let params = new HttpParams().set('name', name);

    return this.http.get<CustomerDiscount[]>(filterDiscountsUrl, { params : params });
  }

  public getDiscountTypes(): Observable<DiscountType[]> {
    return this.http.get<DiscountType[]>(discountTypesUrl);
  }
  
}
