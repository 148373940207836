export interface ContractKindEnum {
    code: string;
    name: string;
}


export const CONTRACT_KINDS: ContractKindEnum[] = [
    { name: "Договор",    code: "C" },
    { name: "Споразумение",   code: "A" }
];

export const findByCode = (code: string) => CONTRACT_KINDS.filter(kind => kind.code === code);