import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY } from 'rxjs';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { CustomerService } from '@app/customers/_services/customer.service';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { PromotionCustomer } from '@app/customers/_models/promotion-customer.model';

@Component({
  selector: 'app-client-profile-promo-codes',
  templateUrl: './client-profile-promo-codes.component.html',
  styleUrls: ['./client-profile-promo-codes.component.css']
})
export class ClientProfilePromoCodesComponent extends BaseChildComponent implements OnInit {
  promotionsCustomer: PromotionCustomer[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private translateService: TranslateService
  ) { 
    super();
  }

  ngOnInit(): void { }

  initialize() {
    if (this.isInitialized) return;

    this.customerService.findPromoCodesByCustomerId(this.parentId).pipe(
      tap(data => this.promotionsCustomer = data),
      catchError(err => {
        console.log(err)
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true})
  }
}
