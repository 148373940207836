import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimWhitespace]'
})
export class TrimWhitespaceDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string | null) {
    if (value !== null) {
      const trimmedValue = value.trim();
      this.ngControl.control?.setValue(trimmedValue);
    }
  }

}