import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { ListTechnicalPoint } from '@app/tech-points/_models/list-technical-point.model';
import { SubjectVersionService } from '@services/subject-version.service';
import { FormBuilder, Validators } from '@angular/forms';
import { City } from '@models/city.model';
import { SearchSubjectVersion } from '@models/search-subject-version.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterOfLegalPersonsModalComponent } from '@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component';
import { modalMinWidth } from '@env/config';
import { tap, concatMap, repeatWhen, catchError, debounceTime, switchMap, first, filter, shareReplay } from 'rxjs/operators';
import { EMPTY, Subscription, OperatorFunction, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { convertDateToString } from '@app/_utils/date-util';

@Component({
  selector: 'app-list-technical-points',
  templateUrl: './list-technical-points.component.html',
  styleUrls: ['./list-technical-points.component.css']
})
export class ListTechnicalPointsComponent extends BaseSortableSearchComponent<ListTechnicalPoint> implements OnInit, OnDestroy {
  // Units
  cities: City[];

  // Booleans
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    subject:        [null],
    permitNumber:   [null, Validators.max(9999)],
    city :          [null]
  });

  // Subscriptions
  private subscriptions = new Subscription();

  // Observables
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.techPointService.pageable(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
  );

  loadSearchForm$ = this.techPointService.getTechPointPageableNomenclacutes().pipe(
    tap(([cities]) => {
      this.cities = cities;
      this.searchSubject.next();
    }),
    catchError(err => {
      displayError(err);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  );

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
    input$.pipe(
      debounceTime(300),
      filter((input) => input.length > 3),
      switchMap(input =>
        this.subjectVersionService.filterSubjectVersions(input).pipe(
          catchError(() => of([])),
          shareReplay()
        )
      )
  );

  constructor(
    public  perms: PermissionsService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private exportService: ExportService,
    private translateService: TranslateService,
    private techPointService: TechnicalPointService,
    private subjectVersionService: SubjectVersionService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.add(this.search$.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  openModal() {
    if (this.dialog.openDialogs.length == 0) {
      let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
      this.subscriptions.add(ref.afterClosed().subscribe(reload => {
          // TODO
      }));
    }
  }

  subjectVersionFormatter(element: any) {
    return element.fullName;
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.techPointResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.techPointResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;
    
    let result = await this.techPointService.findAllByFilter(sortingPagingCopy, this.searchForm.value).pipe(first()).toPromise();
    
    result.content.forEach(object => {
      newContent?.push([object.name, object.subjectName, object.subjectIdentNum, object.permitNumber, object.city, object.address, object.phone, object.email])
    });

    return newContent;
  }
 
  private getHeadings() : string[][] {
     return [[this.translateService.instant("techPoint.name"), this.translateService.instant("techPoint.subjectName"), 
              this.translateService.instant("techPoint.subjectEIK"), this.translateService.instant("techPoint.number"), this.translateService.instant("techPoint.city"),
              this.translateService.instant("techPoint.address"), this.translateService.instant("techPoint.phone"), this.translateService.instant("techPoint.email")]]
  }
   
  private getFilterBody(): any[] {
    let city: City | null;
    city = this.searchForm.get('city')?.value;

    let result: string[]= [
      this.searchForm.get('subject')?.value,
      this.searchForm.get('permitNumber')?.value, 
      city?.name
    ];

    return [result];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listTechnicalPoints.subjectName'),
      this.translateService.instant('exportData.listTechnicalPoints.permitNumber'),
      this.translateService.instant('exportData.listTechnicalPoints.city')
    ];
 
    return [
      [{content: this.translateService.instant('menu.backOffice.technicalPoints.search'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

  get form() { return this.searchForm.controls; }

}