<div class="form-group form-row" [formGroup]="technicalPointForm">
    <div class="col">
        <mat-form-field>
            <mat-label translate>techPoint.city</mat-label>
            <mat-select formControlName="city" (selectionChange)="filterTechPoints()">
                <div *ngFor="let city of cities">
                    <mat-option [value]="city">{{city.name}}</mat-option>
                </div>
            </mat-select>
            <button type="button" matSuffix *ngIf="form.city.value != null"
                (click)="form.city?.setValue(null);$event.stopPropagation();filterTechPoints()">
                <mat-icon style="font-size:100%;">x</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field class="mat-form-field">
            <mat-label translate>ktp</mat-label>
            <mat-select #mySelection multiple formControlName="selectedTechnicalPoints" [compareWith]="compareById">
                <mat-option (onSelectionChange)="change($event, mySelection)"
                    *ngFor="let technicalPoint of technicalPoints" [value]="technicalPoint">
                    <strong>{{technicalPoint.name}}</strong> - <small>{{technicalPoint.city?.name}}</small> <br>
                    <small>№: </small><small>{{technicalPoint.permitNumber}}</small>
                </mat-option>
            </mat-select>
            <small class="text-danger" *ngIf="form.selectedTechnicalPoints.errors?.required && showValidations"
                translate>
                errors.atLeastOneTechnicalPoint
            </small>
        </mat-form-field>
    </div>
</div>