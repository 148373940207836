import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PromotionService } from '@app/promotions/_services/promotion.service';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { Promotion } from '@app/promotions/_models/promotion.model';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, forkJoin, Subject, Subscription } from 'rxjs';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FriendInvitationConfiguration } from '@app/promotions/_models/friend-invitation-configuration.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { displayError } from '@app/_utils/error-util';

@Component({
  selector: 'app-promotion-friends-config',
  templateUrl: './promotion-friends-config.component.html',
  styleUrls: ['./promotion-friends-config.component.css']
})
export class PromotionFriendsConfigComponent extends BaseChildComponent implements OnInit {
  // Units
  promocodes:   Promotion[];
  countRow:     number = 0;

  // Property decorators
  @Input() module: string;
  @Output() countRowEvent = new EventEmitter<number>();

  // Form
  invitationsForm = this.formBuilder.group({
    invitations: this.formBuilder.array([], Validators.required)
  }); 

  // Observables
  invitationsConfigSubscription: Subscription;
  searchSubject = new Subject<void>();

  loadPromocodes$ = this.promotionService.findAllValidPromocodes().pipe(
    tap((promocodes) => {
      this.promocodes = promocodes;
      this.searchSubject.next();
    }),
    catchError(_err => {
      displayError(_err);
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  )

  constructor( 
    private formBuilder: FormBuilder,
    public  perms: PermissionsService,
    private translateService: TranslateService,
    private promotionService: PromotionService
  ) {
    super();
  }

  ngOnInit() { 
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data,
    });
  }

  initialize() {
    if (this.isInitialized) return; 
    this.isInitialized = true;

    if (this.parentId != null) {
     this.invitationsConfigSubscription = forkJoin([
       this.promotionService.findFriendInvitationsConfigByPromotionId(this.parentId)
     ]).pipe( 
       tap(([invitations]) => {
         invitations.forEach(invitation => this.loadFriendInvitationConfig(invitation));
         this.isInitialized = true;
       }),
       catchError(err => {
        displayError(err);
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
       }),
       repeatWhen(() => this.reload$)
     ).subscribe({
       next: () => this.isInitialized = true
     });
    }
  }

  addFriendInvitationConfig() {
   let friendConfigForm = this.formBuilder.group({
    id:                      [null],
    customerOwnerPromotion:  [null, Validators.required],
    customerFriendPromotion: [null, Validators.required],
    usageNumber:             [null, [Validators.required, Validators.min(1), Validators.max(99)]]
    });

    this.countRow++;
    this.countRowEvent.emit(this.countRow);

    this.invitationsForms.push(friendConfigForm);
  }

  loadFriendInvitationConfig(friendConfig: FriendInvitationConfiguration) {
    let friendConfigForm = this.formBuilder.group({
     id:                      [friendConfig.id],
     customerOwnerPromotion:  [friendConfig.customerOwnerPromotion, Validators.required],
     customerFriendPromotion: [friendConfig.customerFriendPromotion, Validators.required],
     usageNumber:             [friendConfig.usageNumber, [Validators.required, Validators.min(1)]]
    });

    this.countRow++;
    this.countRowEvent.emit(this.countRow);
  
    this.invitationsForms.push(friendConfigForm);
  }

  validateInvitationsConfig() {
    let invitationsConfigValid = this.invitationsForms.valid;

    if (!invitationsConfigValid) {
      this.isOpened = true;
    }
    
    return invitationsConfigValid;
  }

  constructFriendConfigs() {
    // if (!this.isInitialized) {
    //   return null;
    // }

    let friendConfigs = this.invitationsForms.value as any[];
    return friendConfigs.map((fc: any) => this.constructFriendConfig(fc));
  }

  private constructFriendConfig(friendConfig: any): FriendInvitationConfiguration {
    return {
      id:                      friendConfig?.id || null,
      customerOwnerPromotion:  friendConfig?.customerOwnerPromotion || null,
      customerFriendPromotion: friendConfig.customerFriendPromotion || null,
      usageNumber:             friendConfig?.usageNumber || null
    }
  }

  get invitationsForms(): FormArray { 
    return this.invitationsForm.get('invitations') as FormArray; 
  } 

  get canAddEdit() {
    switch(this.module) {
      case 'promotion': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOTION);
      case 'promocode': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOCODE);
      case 'invite-friend': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_INVITE_FRIEND);
      default: 
        return false;
    }
  }

}