import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SubjectVersion } from '@models/subject-version.model';
import { SortingPaging } from '@helpers/sorting-paging';
import { ListSubjectVersion } from '@models/list-subject-version.model';
import { Page } from '@interfaces/page';
import { NomenclatureService } from './nomenclature.service';

const apiServerUrl       = environment.apiBaseURL;
const subjectVersionsUrl = apiServerUrl + '/subject-version';

const findByIdUrl                    = subjectVersionsUrl + '/find/{id}';
const findActualSubjectVersion       = subjectVersionsUrl  + '/find-actual';
const filterUrl                      = subjectVersionsUrl + '/filter';
const findSubjectVersionPageableURL  = subjectVersionsUrl + '/pageable';
const countSubjectVersionPageableURL = subjectVersionsUrl + '/pageable-count'

@Injectable({
  providedIn: 'root'
})
export class SubjectVersionService {
 
  private apiServerUrl = environment.apiBaseURL;
  
  constructor(private nomenclatureService: NomenclatureService, private http: HttpClient) { }

  public getSubjectVersionById(id: number): Observable<SubjectVersion>{
    return this.http.get<SubjectVersion>(`${this.apiServerUrl}/subject-version/find/${id}`);
  }

  public getActualSubjectVersion(subjectVersionId: number, versionDataId: number): Observable<SubjectVersion> {
    let requestParams = new HttpParams();
    requestParams = requestParams.set("subjectVersionId", subjectVersionId.toString());
    requestParams = requestParams.set("versionDataId", versionDataId.toString());

    return this.http.get<SubjectVersion>(findActualSubjectVersion, {params: requestParams});
  }

  public filterSubjectVersions(name: string) {
    let params = new HttpParams().set('name', name);

    return this.http.get<SubjectVersion[]>(filterUrl, { params : params });
  }

  public pageable(sortingPaging: SortingPaging, searchForm: any) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm),
      this.countAllByFilter(sortingPaging, searchForm)
    ]);
  }

  public getSubjectVersionPageableNomenclacutes() {
    return forkJoin([
      this.nomenclatureService.getSubjectTypes()
    ])
  }

  public findAllByFilter(sortingPaging: SortingPaging, searchForm: any): Observable<Page<ListSubjectVersion>> {
    return this.http.get<Page<ListSubjectVersion>>(findSubjectVersionPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  public countAllByFilter(sortingPaging: SortingPaging, searchForm: any): Observable<number> {
    return this.http.get<number>(countSubjectVersionPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  private constructPageableHttpParams(sortingPaging: SortingPaging, searchForm: any) {
    let params = new HttpParams().set('pageSize', sortingPaging.pageSize.toString())
    .set('page', sortingPaging.pageNumber.toString());

        if (searchForm.type?.id != null) {
          params = params.set('typeId', searchForm.type?.id);
        }

        if (searchForm.fullName != null) {
          params = params.set('fullName', searchForm.fullName);
        }

        if (searchForm.identNum != null) {
          params = params.set('identNum', searchForm.identNum);
        }

        return params;
  }

  public createSubjectVersion(subjectVersion: SubjectVersion) {
    return this.http.post(subjectVersionsUrl, subjectVersion);
  }

  public saveSelectedSubjectVersion(subjectVersion: SubjectVersion) {
    return this.createSubjectVersion(subjectVersion).toPromise();
  }
}
