import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { EMPTY, Observable } from "rxjs";
import { UserProfile } from "../_models/UserProfile.model";
import { shareReplay } from "rxjs/operators";

const userProfileURL = environment.apiBaseURL + '/employees';

const findEmployeeByIdURL   = userProfileURL + '/{id}';

@Injectable({
    providedIn: 'root'
  })
  export class UserProfileService {
    constructor(private http: HttpClient) { }

    public findEmployeeById(id: number): Observable<UserProfile> {
        if (id == null) return EMPTY;
        return this.http.get<UserProfile>(findEmployeeByIdURL.replace('{id}', `${id}`)).pipe(shareReplay());
      }
  }