import { GroupedTechnicalPointWorkingHour } from '@app/tech-points/_models/grouped-technical-point-working-hours.model';
import { TechnicalPointWorkingHourResponse } from '@app/tech-points/_models/technical-point-working-hour-respose.model';
import { ListTechnicalInspectionProgramCardCore } from '../_models/list-technical-inspection-program-card-core.model';
import { SearchAvailableTimeSlot } from '@app/technical-inspections-cc/_models/search-available-time-slot.model';
import { AvailableTimeTechPoint } from '@app/technical-inspections-cc/_models/available-time-tech-point.model';
import { ListTechnicalInspection } from '@app/technical-inspections/_models/list-technical-inspection.model';
import { AddCustomerSubscriptionData } from '@app/customers/_models/add-customer-subscription-data.model';
import { TechnicalPointWorkingHour } from '@app/tech-points/_models/technical-point-working-hours.model';
import { TechPointServicePriceInfo } from '@app/tech-points/_models/tech-point-service-price-info.model';
import { TechnicalInspection } from '@app/technical-inspections/_models/technical-inspection.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { CustomerSubscription } from '@app/customers/_models/customer-subscription.model';
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { CustomerHttpParamsCodec } from '@helpers/custom-http-params-codec';
import { NomenclatureService } from '../../_services/nomenclature.service';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { AvailableSlot } from '@models/available-slot.model';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VersionData } from '@models/version-data.model';
import { RvsCategory } from '@models/rvs-category.model';
import { LoyalCombo } from '@models/loyal-combo.model';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, shareReplay } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Page } from '@interfaces/page';

const technicalInspectionUrl                     = environment.apiBaseURL + '/technical-inspection';
const technicalInspectionApiUrl                  = environment.apiBaseURL + '/api/technical-inspection';
const getTechPointWorkingHoursResponseUrl        = technicalInspectionUrl + '/tech-point-working-hours-response';
const getGroupedTechPointWorkingHoursUrl         = technicalInspectionUrl + '/grouped-tech-point-working-hours';
const getTechPointWorkingHoursByDateUrl          = technicalInspectionUrl + '/tech-point-working-hours-by-date';
const customerSubscriptionByRegNumUrl            = technicalInspectionUrl + '/customer-subscription-by-reg-num';
const servicesMobileAppIdsForCardUrl             = technicalInspectionUrl + '/services-mobile-app-ids-for-card';
const getCurrentTechPointWorkingHourUrl          = technicalInspectionUrl + '/current-tech-point-working-hour';
const customerPhoneByCustomerRvsUrl              = technicalInspectionUrl + '/customer-phone-by-customer-rvs'; 
const regCertNumberByRegNumberUrl                = technicalInspectionUrl + '/reg-cert-number-by-reg-number';
const getTechPointServicePriceInfoUrl            = technicalInspectionUrl + '/tech-point-service-price-info';
const futureTechInspectionsUrl                   = technicalInspectionUrl + '/future-technical-inspections';
const checkForValidSubscriptionUrl               = technicalInspectionUrl + '/check-for-valid-subscription';
const saveCustomerSubscritionUrl                 = technicalInspectionUrl + '/save-customer-subscription';
const getTechPointsByRvsCategoryUrl              = technicalInspectionUrl + '/tech-point-by-rvs-category';
const getRvsCategoriesByServiceUrl               = technicalInspectionUrl + '/rvs-categories-by-service';
const updateOrderWithWantInvoiceURL              = technicalInspectionUrl + '/update-order-want-invoice';
const normalizedRegNumberUrl                     = technicalInspectionUrl + '/normalize-reg-number';
const availableTimeSlotsUrl                      = technicalInspectionUrl + '/available-timeslots';
const basicTechPointServicesUrl                  = technicalInspectionUrl + '/tech-point-services';
const saveAvalableSlotUrl                        = technicalInspectionUrl + '/save-available-slot';
const checkForContractByRegNumUrl                = technicalInspectionUrl + '/check-for-contract';
const pageableTotalElementsUrl                   = technicalInspectionUrl + '/pageable/count';
const changeTechInspStatusUrl                    = technicalInspectionUrl + '/change-status';
const getLineNumberUrl                           = technicalInspectionUrl + '/line-number';
const findTechInspectionByIdUrl                  = technicalInspectionUrl + '/find/{id}';
const pageableTechnicalInspections               = technicalInspectionUrl + '/pageable';
const pageableTechnicalInspectionProgramCard     = technicalInspectionUrl + '/pageable-card';
const saveTechnicalInspectionUrl                 = technicalInspectionUrl + '/';
const mainServicesByCityCodeUrl                  = technicalInspectionApiUrl + '/services/main';
const loyalComboUrl                              = environment.apiBaseURL + '/customer-card/combo';
const amountPaidFirstVersionUrl                  = technicalInspectionUrl + '/amount-paid-first-technical-inspection/{versionDataId}';
const lastIdByMobileAppIdUrl                     = technicalInspectionUrl + '/last-id-by-mobile/{mobileAppId}';

@Injectable({ providedIn: 'root' })
export class TechnicalInspectionService {

    constructor(
        private technicalPointService: TechnicalPointService,
        private nomenclatureService: NomenclatureService,
        private http: HttpClient
    ) {}

    public getServicesMobileAppIdsForCard(serviceId: number): Observable<number[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("serviceId", serviceId.toString());
        return this.http.get<number[]>(servicesMobileAppIdsForCardUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getCustomerSubscriptionByRegNumber(regNumber: string): Observable<CustomerSubscription> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("regNumber", regNumber);
        return this.http.get<CustomerSubscription>(customerSubscriptionByRegNumUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getCustomerPhoneByCustomerRvs(customerRvsId: number): Observable<any> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("customerRvsId", customerRvsId.toString());
        return this.http.get<any>(customerPhoneByCustomerRvsUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getRegCertNumberIfExists(regNumber: string): Observable<any> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("regNumber", regNumber);
        return this.http.get<any>(regCertNumberByRegNumberUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getMainServicesByCityCode(cityCode: string): Observable<any> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("cityCode", cityCode);
        return this.http.get<any>(mainServicesByCityCodeUrl, {params: requestParams});
    }

    public getTechnicalPointsByRvsCategory(rvsCategoryId: number, serviceMobileAppId: number, cityCode: string): Observable<any> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("rvsCategoryId", rvsCategoryId.toString());
        requestParams = requestParams.set("serviceMobileAppId", serviceMobileAppId.toString());
        requestParams = requestParams.set("cityCode", cityCode);
        return this.http.get<any>(getTechPointsByRvsCategoryUrl, {params: requestParams});
    }

    public getRvsCategoriesByServiceMobileApp(serviceMobileAppId: number, cityCode: string): Observable<any> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("serviceMobileAppId", serviceMobileAppId.toString());
        requestParams = requestParams.set("cityCode", cityCode);
        return this.http.get<any>(getRvsCategoriesByServiceUrl, {params: requestParams});
    }

    public getTechPointsAvailableTimeSlots(searchAvailableTimeSlot: SearchAvailableTimeSlot): Observable<AvailableTimeTechPoint[]> {
        return this.http.post<AvailableTimeTechPoint[]>(availableTimeSlotsUrl, searchAvailableTimeSlot).pipe(shareReplay());
    }

    public getTechnicalPointWorkingHoursByDate(techPointId: number, forDate: string) : Observable<TechnicalPointWorkingHour[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("techPointId", techPointId.toString());
        requestParams = requestParams.set("forDate", forDate);
        return this.http.get<TechnicalPointWorkingHour[]>(getTechPointWorkingHoursByDateUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getTechInspectionNomenclatures(type: string, technicalPointId: number, techPointMobileAppId: number) {
        if (type == null) return this.returnEmptyObservable();

        return forkJoin([
          this.getFutureTechnicalInspections(technicalPointId),
          this.technicalPointService.findValidLinesByPointId(technicalPointId),
          this.nomenclatureService.getRvsCategoriesByTechPointId(techPointMobileAppId),
          this.nomenclatureService.getRejectReasons(),
          this.nomenclatureService.getCustomerDiscountsBySubjectType(1),
          this.nomenclatureService.getStatusesByType(type)
        ]);
    }

    public getTechInspCcNomenclatures(type: string) {
        return forkJoin([
        this.technicalPointService.findTechnicalPoints(),
        this.nomenclatureService.getTechPointCities(),
        this.nomenclatureService.getStatusesByType(type),
        this.nomenclatureService.getRvsCategories(),
        this.nomenclatureService.getRejectReasons(),
        this.nomenclatureService.getCustomerDiscountsBySubjectType(1)
        ]);
    }

    public getRvsCategoryServicesByType(rvsCategory: RvsCategory | null, technicalPointId: number | null) {
        if (rvsCategory == null || technicalPointId == null) return this.returnEmptyObservable();

        return forkJoin([
            this.getTechPointServicesByType(1, rvsCategory.id, technicalPointId),
            this.getTechPointServicesByType(0, rvsCategory.id, technicalPointId),
            this.getTechPointServicesByType(4, rvsCategory.id, technicalPointId)
        ]);
    } 

    public getBasicRvsCategoryServices(rvsCategory: RvsCategory | null, technicalPointId: number | null) {
        if (rvsCategory == null || technicalPointId == null) return this.returnEmptyObservable();
        return this.getTechPointServicesByType(1, rvsCategory.id, technicalPointId).pipe(shareReplay());
    }

    public getAnnualRvsCategoryServices(rvsCategory: RvsCategory | null, technicalPointId: number | null) {
        if (rvsCategory == null || technicalPointId == null) return this.returnEmptyObservable();
        return this.getTechPointServicesByType(4, rvsCategory.id, technicalPointId).pipe(shareReplay());
    }

    public getPagableTechnicalInspections(sortingPaging: SortingPagingData, searchObject: FormGroup, fromDate: string, toDate: string): Observable<Page<ListTechnicalInspection>> {
        let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject, fromDate, toDate);
        return this.http.get<any>(pageableTechnicalInspections, {params: requestParams}).pipe(shareReplay());
    }

    public getPagableTechnicalInspectionsWithoutDates(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<Page<ListTechnicalInspection>> {
        let requestParams = this.createSearchHttpRequestParamsWithoutDates(sortingPaging, searchObject);
        return this.http.get<any>(pageableTechnicalInspections, {params: requestParams});
    }

    public getPagableTechnicalInspectionProgramCard(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<Page<ListTechnicalInspectionProgramCardCore>> {
        let requestParams = this.createSearchHttpRequestParamsWithoutDates(sortingPaging, searchObject);
        return this.http.get<any>(pageableTechnicalInspectionProgramCard, {params: requestParams});
    }

    public getTotalElements(sortingPaging: SortingPagingData, searchObject: FormGroup, fromDate: string, toDate: string): Observable<number>{
        let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject, fromDate, toDate);
        return this.http.get<any>(pageableTotalElementsUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getTotalElementsWithoutDates(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<number>{
        let requestParams = this.createSearchHttpRequestParamsWithoutDates(sortingPaging, searchObject);
        return this.http.get<any>(pageableTotalElementsUrl, {params: requestParams});
    }

    public findTechnicalInspectionById(id: number | undefined): Observable<TechnicalInspection>{
        if (id === undefined || id === null) {
          return EMPTY.pipe(
            defaultIfEmpty()
          );
        }
        return this.http.get<TechnicalInspection>(findTechInspectionByIdUrl.replace('{id}', `${id}`)).pipe(shareReplay());
      }

    public getFutureTechnicalInspections(technicalPointId: number): Observable<TechnicalInspection[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("technicalPointId", technicalPointId.toString());
        return this.http.get<TechnicalInspection[]>(futureTechInspectionsUrl, {params: requestParams}).pipe(shareReplay());
    }

    public checkForExistingContractByRegNumber(regNum: string) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("regNum", regNum);
        return this.http.get<any>(checkForContractByRegNumUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getNormalizedRegNumber(regNum: string) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("regNum", regNum);
        return this.http.get<any>(normalizedRegNumberUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getGroupedTechnicalPointWorkingHours(forDate: string, lineId: number, rvsCategoryId: number, basicServiceDuration: number, technicalPointId: number, arePastLoaded: boolean): Observable<GroupedTechnicalPointWorkingHour[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("forDate", forDate);
        requestParams = requestParams.set("lineId", lineId.toString());
        requestParams = requestParams.set("rvsCategoryId", rvsCategoryId.toString());
        requestParams = requestParams.set("basicServiceDuration", basicServiceDuration.toString());
        requestParams = requestParams.set("technicalPointId", technicalPointId.toString());
        requestParams = requestParams.set("arePastLoaded", arePastLoaded.toString());
        
        return this.http.get<GroupedTechnicalPointWorkingHour[]>(getGroupedTechPointWorkingHoursUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getTechnicalPointWorkingHourResponse(basicServiceId: number, technicalPointId: number): Observable<TechnicalPointWorkingHourResponse> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("basicServiceDuration", basicServiceId.toString());
        requestParams = requestParams.set("technicalPointId", technicalPointId.toString());
        return this.http.get<TechnicalPointWorkingHourResponse>(getTechPointWorkingHoursResponseUrl, {params: requestParams});
    }

    public checkForValidSubscription(phoneNumber: string, regNumber: string, regCertNumber: string, serviceId: number) {
        let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});
        requestParams = requestParams.set("phoneNumber", phoneNumber);
        requestParams = requestParams.set("regNumber", regNumber);
        requestParams = requestParams.set("regCertNumber", regCertNumber);
        requestParams = requestParams.set("serviceId", serviceId.toString());
        return this.http.get<boolean>(checkForValidSubscriptionUrl, {params: requestParams}).pipe(shareReplay());
    }

    public saveAvalableSlot(avalableSlot: AvailableSlot, arePastLoaded: boolean) {
        let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});
        requestParams = requestParams.set("arePastLoaded", arePastLoaded.toString());

        return this.http.post(saveAvalableSlotUrl, avalableSlot, {params: requestParams}).pipe(shareReplay());
    }

    public saveCustomerSubscription(addCustomerSubscriptionData: AddCustomerSubscriptionData) {
        return this.http.post(saveCustomerSubscritionUrl, addCustomerSubscriptionData);
    }

    public saveTechnicalInspection(technicalInspection: TechnicalInspection, arePastLoaded: boolean): Observable<VersionData> {
        let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});
        requestParams = requestParams.set("arePastLoaded", arePastLoaded.toString());

        return this.http.post(saveTechnicalInspectionUrl, technicalInspection, {params: requestParams}).pipe(shareReplay());
    }

    public changeTechInspStatus(id: number, statusCode: string) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("id", id.toString());
        requestParams = requestParams.set("statusCode", statusCode);
        return this.http.get<any>(changeTechInspStatusUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getLineNumberByVersionId(versionDataId: number) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("versionDataId", versionDataId.toString());
        return this.http.get<any>(getLineNumberUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getCurrentTechPointWorkingHourId(techPointId: number) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("techPointId", techPointId.toString());
        return this.http.get<any>(getCurrentTechPointWorkingHourUrl, {params: requestParams}).pipe(shareReplay());
    }

    public getTechPointServicesByType(serviceType: number, selectedRvsCategoryId: number, technicalPointId: number): Observable<TechPointService[]> {
        let requestParams = this.createTechnicalPointServicesHttpRequestParams(serviceType, selectedRvsCategoryId, technicalPointId);
        return this.http.get<TechPointService[]>(basicTechPointServicesUrl, { params: requestParams }).pipe(shareReplay());
    }

    public updateOrderWithWantInvoice(orderId: number) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("orderId", orderId.toString());
        return this.http.get(updateOrderWithWantInvoiceURL, {params: requestParams});
    }
    
    public getTechPointServicePricesFromFunction(techPointWorkingHourId: number, techPointId: number, rvsCategoryCode: string, servicesMobileAppIds: number[], dateOfInsp: String | null): Observable<TechPointServicePriceInfo[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("techPointWorkingHourId", techPointWorkingHourId.toString());
        requestParams = requestParams.set("techPointId", techPointId.toString());
        requestParams = requestParams.set("rvsCategoryCode", rvsCategoryCode);
        requestParams = requestParams.set("servicesMobileAppIds", servicesMobileAppIds.toString());

        if (dateOfInsp) {
            requestParams = requestParams.set("dateOfInsp", dateOfInsp.toString().split("-").reverse().join("-"));
        }
        return this.http.get<TechPointServicePriceInfo[]>(getTechPointServicePriceInfoUrl, {params: requestParams}).pipe(shareReplay());
    }

    private createTechnicalPointServicesHttpRequestParams(serviceTypeId: number, rvsCategoryId: number, technicalPointId: number) {
        let requestParams = new HttpParams();
        requestParams = requestParams.set("serviceTypeId", serviceTypeId.toString());
        requestParams = requestParams.set("rvsCategoryId", rvsCategoryId.toString());
        requestParams = requestParams.set("technicalPointId", technicalPointId.toString());
        return requestParams;
    }

    private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchObject: FormGroup, fromDate: string, toDate: string) {
        let requestParams = new HttpParams();

        if (sortingPaging != null) {
            requestParams = requestParams.set("page", sortingPaging.getPageNumber());
            requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
        }

        if (sortingPaging.isSortingValid()) {
            requestParams = requestParams
              .set("sortBy", sortingPaging.sortBy)
              .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
        }

        Object.entries(searchObject).forEach(item => {
            if (item[1] && item[1].length !== 0) {
                requestParams = requestParams.set(item[0], item[1]);
            }
        });
   
        requestParams = requestParams.set("fromDate", fromDate);
        if(toDate && toDate.length !== 0) {
            requestParams = requestParams.set("toDate", toDate);
        }

        return requestParams;
    }

    private createSearchHttpRequestParamsWithoutDates(sortingPaging: SortingPagingData, searchObject: FormGroup) {
        let requestParams = new HttpParams();

        if (sortingPaging != null) {
            requestParams = requestParams.set("page", sortingPaging.getPageNumber());
            requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
        }

        if (sortingPaging.isSortingValid()) {
            requestParams = requestParams
              .set("sortBy", sortingPaging.sortBy)
              .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
        }

        Object.entries(searchObject).forEach(item => {
            if (item[1] && item[1].length !== 0) {
                requestParams = requestParams.set(item[0], item[1]);
            }
        });
   
        return requestParams;
    }

    private returnEmptyObservable() {
        return EMPTY.pipe(
          defaultIfEmpty()
        );
    }

    public getLoyalComboByCard(card: string) {
        return this.http.post<LoyalCombo>(loyalComboUrl, card).pipe(shareReplay());
    }

    public getPaidAmountFromFirstTechnicalInspection(versionDataId: number) {
        return this.http.get<any>(amountPaidFirstVersionUrl.replace('{versionDataId}', `${versionDataId}`));
    }
    
    public findLastIdByMobileAppId(mobileAppId: string) {
        return this.http.get<Record<string, any>>(lastIdByMobileAppIdUrl.replace('{mobileAppId}', `${mobileAppId}`));
    }

}
