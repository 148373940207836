import { PriceAttribute } from "@models/price-attribute.model";
import { VersionData } from "@models/version-data.model";

export class CustomCustomerDiscount {
    id: number;
    discountName: string;
    priceAttribute: PriceAttribute;
    isValid: boolean;
    versionData?: VersionData; 
    priceAttributeAmountDds?: number;
    priceAttributeKind?: string;
}