import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ArrayValidators } from "@app/_validators/array.validator";
import { BaseParentComponent } from "@components/_base/base-parent/base-parent.component";
import { TranslateService } from "@ngx-translate/core";
import { NomenclatureService } from "@services/nomenclature.service";
import { EMPTY, forkJoin, Observable, of, OperatorFunction } from "rxjs";
import { catchError, concatMap, debounceTime, filter, repeatWhen, switchMap, tap } from "rxjs/operators";
import { DocumentTypeEnum, DOCUMENT_TYPES } from "@app/_enums/document-type-enum";
import { AccountingDocumentService } from "@app/accounting_documents/_services/accounting-document.service";
import { AccountingDocument } from "@app/accounting_documents/_models/accounting-document.model";
import { StatusCode } from "@app/_enums/status-code";
import { Status } from "@models/status.model";
import { PaymentType } from "@models/payment-type.model";
import { AccountingDocumentElement } from "@app/accounting_documents/_models/accounting-document-element.model";
import { MeasuringUnit } from "@app/accounting_documents/_models/measuring-unit.model";
import { RegisterOfLegalPersonsModalComponent } from "@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component";
import { config, modalMinWidth } from "@env/config";
import { SubjectVersionService } from "@services/subject-version.service";
import { MatDialog } from "@angular/material/dialog";
import { SubjectVersion } from "@models/subject-version.model";
import { ConsiceSubjectVersion } from "@models/consice-subject-version.model";
import { SearchAccountingDocument } from "@app/accounting_documents/_models/search-accounting-document.model";
import { AuthenticationService } from "@app/login/_services/authentication.service";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { TechnicalPointService } from "@app/tech-points/_services/technical-point.service";
import { UserProfileService } from "@app/user-profile/_services/user-profile.service";
import { UserProfile } from "@app/user-profile/_models/UserProfile.model";
import { TechnicalInspectionService } from "@app/technical-inspections/_services/technical-inspection.service";
import { UtilValidators } from "@app/_validators/util.validators";
import { displayError } from "@app/_utils/error-util";
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { prepareDateForDb, prepareDateForForm, prepareDateForUI } from "@app/_utils/date-util";

@Component({
    selector: 'add-edit-accounting-document',
    templateUrl: './add-edit-accounting-document.component.html',
    styleUrls: ['./add-edit-accounting-document.component.css']
})

export class AddEditAccountingDocumentComponent extends BaseParentComponent implements OnInit {
    // Core 
    accountingDocument:     AccountingDocument;
    techInspectionId:       number;
    accountingDocumentId:   number;
    statuses:               Status[];
    measuringUnits:         MeasuringUnit[];
    paymentTypes:           PaymentType[];
    defaultStatus:          Status | null;
    defaultDocumentType:    DocumentTypeEnum | null;
    employeeId:             number;
    employeeName:           string;
    dateObj:                any;
    userTechnicalPointIds:  number[];
    userTechnicalPointId:   number;
    employee:               UserProfile;
    statusCode:             string;
    attachedInvoiceId:      number;
    docTypeParam:           string;

    // Constants
    documentTypes =                 DOCUMENT_TYPES;
    readonly VAT_GROUPS:            number[] = [20, 9];
    readonly DEAFULT_VAT_GROUP:     number = 20;
    readonly PAID_STATUS_CODE:      string = 'PAID';
    readonly ISSUED_STATUS_CODE:    string = 'ISSUED';
    readonly ANNULLED_STATUS_CODE:  string = 'ANNULLED';
    readonly PAYMENT_TYPE_BANK:     string = 'По банка';
    readonly PAYMENT_TYPE_DELAYED:  string = 'Отложено плащане';

    // Payloads
    doesNeedToReloadNewData     = false;
    lastInvoiceId               = 0;
    invoiceModalPopUpCounter    = 0;

    // Booleans
    isDisabled: boolean;
    showAdditionalTextInInvoice = false;

    // Children
    @ViewChild('dataChange') dataChangeDialog: TemplateRef<any>;
    dialogRef: any

    // Form
    addEditForm = this.formBuilder.group({
        id:             '',
        documentType:   [null, Validators.required],
        status:         null,
        invoiceNumber:  [null],
        invoice:        null,
        invoiceDate:    [null, Validators.required],
        employeeId:     null,
        technicalPoint: null,
        publisher: this.formBuilder.group({
            id:                     [null, Validators.required],
            identNum:               null,
            fullName:               null,
            versionData:            { value: null, disabled: true },
            manager:                null,
            city:                   null,
            address:                null,
            invoiceDdsRegistration: null,
            phoneNumber:            null,
            bankAccount: this.formBuilder.group({
                bankName:                   null,
                iban:                       null,
                bic:                        null
            })
        }),
        recepient: this.formBuilder.group({
            id:                     [null, Validators.required],
            identNum:               { value: null, disabled: true },
            fullName:               { value: null, disabled: true },
            versionData:            { value: null, disabled: true },
            invoiceManager:         { value: null, disabled: true },
            invoiceDdsRegistration: { value: null, disabled: true },
            city:                   { value: null, disabled: true },
            address:                { value: null, disabled: true }
        }),
        dateOfChargeableEvent:  null,
        dealPlace:              null,
        dealDescription:        null,
        dealReason:             null,
        recipientFullName:      null,
        amount:                 null,
        singleAmount:           null,
        singleAmountWithDDS:    null,
        ddsValue:               [this.DEAFULT_VAT_GROUP, [Validators.required]],
        amountDds:              null,
        totalAmount:            null,
        paymentType:            [null, Validators.required],
        maturityDate:           null,
        versionData:            null,
        orderId:                null,
        accountingDocumentElementList: this.formBuilder.array([], [ArrayValidators.equalsToSomeGroupKey('isValid', true)])
    });

    // Observables
    accountingDocument$ = this.route.queryParams.pipe(
        concatMap(params => {
            return forkJoin([
                (this.accountingDocumentId != null ? this.accountingDocumentService.findAccountingDocumentById(this.accountingDocumentId) :
                this.accountingDocumentService.createAccDocFromTechInspection(this.techInspectionId)),
                this.nomenclatureService.getStatusesByType(StatusCode.Invoice),
                this.nomenclatureService.getPaymentTypes(),
                this.nomenclatureService.getMeasuringUnits(),
                this.userProfileService.findEmployeeById(this.employeeId)
            ]).pipe(
               tap(async([accountingDocument, statuses, paymentTypes, measuringUnits, employee]) => {
                    this.statuses = statuses;
                    this.paymentTypes = paymentTypes;
                    this.measuringUnits = measuringUnits;
                    this.employee = employee;   
                    this.addEditForm.get('publisher')?.patchValue(employee.employer);
                    this.accountingDocument = accountingDocument;
                    

                    const paymentTypeDescription = this.accountingDocument?.paymentType?.description;
                    if (paymentTypeDescription === this.PAYMENT_TYPE_BANK || paymentTypeDescription === this.PAYMENT_TYPE_DELAYED) {
                        this.showAdditionalTextInInvoice = true;
                    }

                    this.defaultStatus = this.statuses.find(status => status.code === this.ISSUED_STATUS_CODE) || null;
                    this.defaultDocumentType = this.documentTypes[0];
                    this.lastInvoiceId = this.accountingDocumentId;
                    this.addEditForm.patchValue(accountingDocument);

                    this.addEditForm.patchValue({
                        invoiceDate:            !!accountingDocument && accountingDocument.invoiceDate != null ? prepareDateForUI(accountingDocument.invoiceDate) : prepareDateForForm(this.dateObj),
                        dateOfChargeableEvent:  !!accountingDocument && accountingDocument.dateOfChargeableEvent != null ? prepareDateForUI(accountingDocument.dateOfChargeableEvent) : prepareDateForForm(this.dateObj),
                        maturityDate:           !!accountingDocument && accountingDocument.maturityDate != null ? prepareDateForUI(accountingDocument.maturityDate) : null,
                        status:                 !!accountingDocument && !!accountingDocument.status? accountingDocument.status : this.defaultStatus,
                        documentType:           !!accountingDocument && !accountingDocument.documentType != null ? accountingDocument.documentType : this.defaultDocumentType.code
                    });
                   
                    this.addEditForm.patchValue({employeeId: this.employeeId});

                    this.addEditForm.removeControl('customerInvoiceDetails');

                    if (this.accountingDocumentId != null && accountingDocument.customerInvoiceDetails != null) {
                        this.addEditForm.get('recepient')?.get('id')?.clearValidators();
                        this.addEditForm.get('recepient')?.get('id')?.updateValueAndValidity();

                        this.addEditForm.addControl('customerInvoiceDetails', this.formBuilder.group({
                            id:             null,
                            identNumber:    null,
                            fullName:       null,
                            fullAddress:    null,
                            manager:        null,
                            vatNumber:      null,
                            customer: this.formBuilder.group({
                                id:                 null,
                                phoneNumber:        null
                            })
                        }));

                        this.addEditForm.get('customerInvoiceDetails')?.patchValue(accountingDocument.customerInvoiceDetails);

                        if (this.addEditForm.get('documentType')?.value == 'I') {
                            this.isDisabled = true;
                            this.addEditForm.disable();
                        }
                    } 

                    let rowsCtrl = <FormArray>this.addEditForm.controls['accountingDocumentElementList'];

                    if (this.docTypeParam) {
                        if (this.attachedInvoiceId) {
                            this.accountingDocumentService.findAccountingDocumentById(this.attachedInvoiceId).subscribe(attachedInvoice => {
                                this.docTypeParam == 'C' ? this.addEditForm.get('documentType')?.patchValue(this.documentTypes[1].code) 
                                                         : this.addEditForm.get('documentType')?.patchValue(this.documentTypes[2].code);

                                this.addEditForm.get('invoice')?.patchValue(attachedInvoice);

                                let invoiceAmountDiff = this.accountingDocument?.amount - attachedInvoice?.amount;
                                let invoiceTotalAmountDiff = this.accountingDocument?.totalAmount - attachedInvoice?.totalAmount;

                                rowsCtrl.push(this.formBuilder.group({
                                    id:                     null,
                                    description:            ['Разлика с фактура', [Validators.maxLength(150), Validators.required]],
                                    quantity:               [1, [Validators.maxLength(10), Validators.pattern(config.regexFloatNumber), Validators.required]],
                                    measuringUnit:          [this.measuringUnits[0], Validators.required],
                                    amount:                 [null], 
                                    singleAmount:           [invoiceAmountDiff.toFixed(2)],
                                    singleAmountWithDDS:    [invoiceTotalAmountDiff.toFixed(2), Validators.required],
                                    totalAmount:            [invoiceTotalAmountDiff.toFixed(2)],
                                    ddsAmount:              null,
                                    ddsValue:               this.DEAFULT_VAT_GROUP,
                                    isValid:                true,
                                    orderElementId:         null
                                 }));
                                 this.calculateElementTotalAmount(0);
                            });
                        }
                    } else {
                        accountingDocument?.accountingDocumentElementList?.forEach((x: AccountingDocumentElement, index) => {
                            rowsCtrl.push(this.formBuilder.group({
                                id:                     x.id,
                                description:            [x.description, [Validators.maxLength(150), Validators.required]],
                                quantity:               [x.quantity, [Validators.maxLength(10), Validators.pattern(config.regexFloatNumber), Validators.required]],
                                measuringUnit:          [x.measuringUnit, Validators.required],
                                amount:                 [x.amount], 
                                singleAmount:           x.singleAmount,
                                singleAmountWithDDS:    [x.totalAmount/x.quantity, Validators.required],
                                totalAmount:            x.totalAmount,
                                ddsAmount:              x.ddsAmount,
                                ddsValue:               x.ddsValue,
                                isValid:                x.isValid,
                                orderElementId:         x.orderElementId
                            }));
                            this.calculateElementTotalAmount(index);
                        });
                    }

                    if (accountingDocument?.status?.code == this.ANNULLED_STATUS_CODE || accountingDocument?.status?.code === this.PAID_STATUS_CODE) {
                        this.statusCode = accountingDocument.status.code; 
                        this.isDisabled = true;
                        this.addEditForm.disable();
                        rowsCtrl.disable();

                        if (accountingDocument?.status?.code === this.PAID_STATUS_CODE) {
                            this.addEditForm.get('status')?.enable();  
                        }
                    } else {
                        this.addEditForm.get('status')?.enable();  
                    }

                    if (this.accountingDocument?.id == null && this.userTechnicalPointId != null) {
                       this.techPointService.findById(this.userTechnicalPointId).subscribe(techPoint => this.addEditForm.get('dealPlace')?.patchValue(techPoint?.address))
                    }
                }),
                catchError(err => {
                    displayError(err);
                    this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                    return EMPTY;
                }),
                repeatWhen(() => this.reload$)
            );
        })
    );

    invoiceSearch: OperatorFunction<string, SearchAccountingDocument[]> = (input$: Observable<string>) =>
    input$.pipe(
        debounceTime(300),
        filter((input) => input.length > 3), 
        switchMap(input =>
            this.accountingDocumentService.findInvoiceByNum(input).pipe(
                catchError(() => of([]))
            )
        )
    );

    constructor (private formBuilder: FormBuilder,
        private dialog:                     MatDialog,
        protected router:                   Router,
        private route:                      ActivatedRoute,
        private uiEvent:                    UIEventCustom,
        public  perms:                      PermissionsService,
        private translateService:           TranslateService,
        private techPointService:           TechnicalPointService,
        private nomenclatureService:        NomenclatureService,
        private userProfileService:         UserProfileService,
        private subjectVersionService:      SubjectVersionService,
        private techInspService:            TechnicalInspectionService,
        private accountingDocumentService:  AccountingDocumentService) {
        super(router);

        this.employeeId = AuthenticationService.getEmployeeId();
        this.employeeName = AuthenticationService.getEmployeeName();

        this.dateObj = new Date();
        this.userTechnicalPointIds = AuthenticationService.getEmployeeTechPointsIds();

        if (this.userTechnicalPointIds[0] != null) {
            this.techPointService.getTechPointIdByTechPointMobileAppId(this.userTechnicalPointIds[0]).subscribe(userTechPointId => {
                this.userTechnicalPointId = userTechPointId;
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();
        localStorage.setItem('invoiceModalPopUpCounter', '0');
        const uniqueTabID = new Date().getTime() + Math.random().toString();
        localStorage.setItem('uniqueTabID', uniqueTabID);
        window.addEventListener('storage', (event) => {
            if (event.key === 'uniqueTabID' && event.newValue !== uniqueTabID) {
                console.log('Another tab has been opened with this app!');
            }
        });

        this.route.queryParams.subscribe(params => {
            if (params['id'] != null) {
                this.accountingDocumentId = params['id'];
            } else {
                this.techInspectionId = params['techInspectionId'];
                this.attachedInvoiceId = params['attachedInvoiceId'];
                this.docTypeParam = params['documentType']; 
            }
        });

        this.addEditForm.get('invoice')?.valueChanges.subscribe(data => {
            if (data?.recepient) {
                this.addEditForm.removeControl('customerInvoiceDetails');
                this.addEditForm.get('recepient')?.patchValue(data?.recepient);
                this.addEditForm.get('recipientFullName')?.patchValue(data?.recepient?.fullName);
            } else if (data?.customerInvoiceDetails) {
                this.addEditForm.get('recepient')?.get('id')?.clearValidators();
                this.addEditForm.get('recepient')?.get('id')?.updateValueAndValidity();

                this.addEditForm.addControl('customerInvoiceDetails', this.formBuilder.group({
                    id: null,
                    identNumber: null,
                    fullName: null,
                    fullAddress: null,
                    manager: null,
                    vatNumber: null,
                    customer: this.formBuilder.group({
                        id: null,
                        phoneNumber: null
                    })
                }));

                this.addEditForm.get('customerInvoiceDetails')?.patchValue(data?.customerInvoiceDetails)
                this.addEditForm.get('recipientFullName')?.patchValue(data?.customerInvoiceDetails?.fullName);
            }
        });
    }

    openModal(formGroup: string) {
        if (this.dialog.openDialogs.length == 0) {
            let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
            ref.componentInstance.submitSubject.subscribe(result => {
                this.setSubjectData(result, formGroup);
            });

            let subjectId = this.addEditForm.get(formGroup)?.get("id")?.value;
            let subjectVersionData = this.addEditForm.get(formGroup)?.get("versionData")?.value;

            if (subjectId != null) {
                ref.componentInstance.selectSubject(subjectId, subjectVersionData.id);
            }
        }
    }

    private setSubjectData(subject: SubjectVersion | ConsiceSubjectVersion | undefined, formGroup: string) {
        if (subject == null) return;
        this.subjectVersionService.getSubjectVersionById(subject.id).subscribe((response: SubjectVersion) => {
            this.addEditForm.get(formGroup)?.patchValue(response);
            
            if (formGroup == 'recepient') {
                this.addEditForm.get('recipientFullName')?.patchValue(response.fullName);
            }
        });
    }

    async onSubmit() {
        this.findAlreadyStarted();
        await new Promise(f => setTimeout(f, 500));
        if (this.doesNeedToReloadNewData) {
            return;
        }

        if (this.addEditForm.get('documentType')?.value == 'C' || this.addEditForm.get('documentType')?.value == 'D') {
            this.addEditForm.get('invoice')?.setValidators([Validators.required]);
            this.addEditForm.get('invoice')?.updateValueAndValidity();

            if (this.addEditForm.get('invoice')?.value) {
                this.addEditForm.get('invoice')?.setValidators([UtilValidators.isInvalidInvoiceDataType()]);
                this.addEditForm.get('invoice')?.updateValueAndValidity();
            }
        } else {
            this.addEditForm.get('invoice')?.clearValidators();
            this.addEditForm.get('invoice')?.updateValueAndValidity();
        }

        let isCoreInvoiceConsistError = false;
        if (!this.recepient?.value?.id) {
            isCoreInvoiceConsistError = true;
        }
        if (!this.addEditForm.value.paymentType) {
            isCoreInvoiceConsistError = true;
        }
        if (!this.addEditForm.value.accountingDocumentElementList || this.addEditForm.value.accountingDocumentElementList.length == 0) {
            isCoreInvoiceConsistError = true;
        }
        if (isCoreInvoiceConsistError) {
            this.uiEvent.displayUIError();
            // This is mandatory, otherwise it will not display error per unit
            this.showValidationsSubject.next(true);
            return;
        }

        let isFailed = false;
        this.addEditForm.value.accountingDocumentElementList.forEach((doc: AccountingDocumentElement) => {
            if (!(doc.description && doc.quantity && doc.measuringUnit && doc.ddsValue)) {
                isFailed = true;
            }
        });
        if (isFailed) {
            this.uiEvent.displayUIError();
            return;
        }

        if (this.recepient != null && this.accountingDocumentId == null) {
            await this.accountingDocumentService.generateInvoiceNum(this.userTechnicalPointIds[0]).toPromise().then(value => {
                this.addEditForm.patchValue({invoiceNumber: value})
            })
            .catch(err => displayError(err));
           
            let technicalPoint = { id: this.userTechnicalPointId }
            this.addEditForm.get('technicalPoint')?.patchValue(technicalPoint);
        }
        
        this.addEditForm.get('invoiceDate')?.patchValue(prepareDateForDb(this.addEditForm.get('invoiceDate')?.value));
        this.addEditForm.get('dateOfChargeableEvent')?.patchValue(prepareDateForDb(this.addEditForm.get('dateOfChargeableEvent')?.value));
        this.addEditForm.get('maturityDate')?.patchValue(prepareDateForDb(this.addEditForm.get('maturityDate')?.value));
        this.addEditForm.enable();

        if (this.addEditForm.get('orderId')?.value != null) {
            this.techInspService.updateOrderWithWantInvoice(this.addEditForm.get('orderId')?.value).subscribe();
        }

        this.accountingDocumentService.saveAccountingDocument(this.addEditForm.value).subscribe({
            next: (id) => {
                this.uiEvent.displayUISuccess();
                this.router.navigate(['/add-edit-accounting-document'], {queryParams: {id: id}});
            },
            error: err => {
                displayError(err);
            }
        });
    }

    addRow() {
        let rowsCtrl = <FormArray>this.addEditForm.controls['accountingDocumentElementList']
        rowsCtrl.push(this.formBuilder.group({
            id:                     null,
            description:            [null, [Validators.maxLength(150), Validators.required]],
            quantity:               [null, [Validators.maxLength(10), Validators.pattern(config.regexFloatNumber), Validators.required]],
            measuringUnit:          [this.accountingDocumentId == null ? this.measuringUnits[0] : null, Validators.required],
            amount:                 [null, [Validators.maxLength(8), Validators.pattern(config.regexFloatNumber), Validators.required]],
            singleAmount:           [null, [Validators.maxLength(8), Validators.pattern(config.regexFloatNumber), Validators.required]],
            singleAmountWithDDS:    [null, [Validators.required, Validators.maxLength(8)]],
            totalAmount:            null,
            ddsAmount:              null,
            ddsValue:               [this.DEAFULT_VAT_GROUP, [Validators.required]],
            isValid:                true
        }));  
    }

    deleteRow(index: number) {
        let rowsCtrl = <FormArray>this.addEditForm.controls['accountingDocumentElementList'];
        let element = rowsCtrl.at(index).value;
        element.isValid = false;
        rowsCtrl.at(index).patchValue(element);
        rowsCtrl.at(index).disable(element);
                
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("description")?.clearValidators();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("quantity")?.clearValidators();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("measuringUnit")?.clearValidators();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("amount")?.clearValidators();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("singleAmount")?.clearValidators();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("singleAmountWithDDS")?.clearValidators();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("description")?.updateValueAndValidity();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("quantity")?.updateValueAndValidity();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("measuringUnit")?.updateValueAndValidity();
        (<FormArray>this.addEditForm.controls['accountingDocumentElementList']).at(index).get("amount")?.updateValueAndValidity();
        this.calculateElementTotalAmount(index);
    }
  
    changeDds() {
        let vat: number = this.addEditForm.get('ddsValue')?.value;
        let rowsCtrl = <FormArray>this.addEditForm.controls['accountingDocumentElementList']
        rowsCtrl.value.forEach((element: AccountingDocumentElement, index: number) => {
            rowsCtrl.at(index).patchValue({ ddsValue: vat });
            this.calculateElementTotalAmount(index);
        });
    }

    calculateElementTotalAmount(index: number) {
        if (this.rows.at(index).get('singleAmount')?.value != null && this.rows.at(index).get('quantity')?.value != null) {
            let vat: number = this.rows.at(index).get('ddsValue')?.value;
            let quantity = this.rows.at(index).get('quantity')?.value;
            let priceValue = this.rows.at(index).get('singleAmountWithDDS')?.value;
            priceValue = String(priceValue);
            if (priceValue.includes(',')) {
                priceValue = priceValue.replace(',', '.');
            }
            let singleAmountWithDDS: number = parseFloat(priceValue); 
            let finalPrice: number = singleAmountWithDDS * quantity;
            let totalAmountWithoutDDS: number = finalPrice / (100.00 + vat) * 100.00;
            let ddsAmount: number = (vat / 100.00) * totalAmountWithoutDDS;

            if (Number.isNaN(finalPrice)) {
                this.rows.at(index).get('totalAmount')?.patchValue(null);
            } else {
                this.rows.at(index).get('totalAmount')?.patchValue(finalPrice.toFixed(2));
                this.rows.at(index).get('amount')?.patchValue(totalAmountWithoutDDS.toFixed(2));
            }

            if (Number.isNaN(ddsAmount)) {
                this.rows.at(index).get('ddsAmount')?.patchValue(null);
            } else {
                this.rows.at(index).get('ddsAmount')?.patchValue(ddsAmount.toFixed(2));
            }

            let amount: number = 0;
            let totalAmount: number = 0;
            let totalDdsAmount: number = 0;
            let rowsCtrl = <FormArray>this.addEditForm.controls['accountingDocumentElementList'];

            rowsCtrl.value.forEach((element: AccountingDocumentElement, index: number) => {
                if (element.isValid) {
                    amount += (element.amount * 1);
                    totalAmount += (element.totalAmount * 1);
                    totalDdsAmount += (element.ddsAmount * 1)
                }
            });

            const finalAmount = (totalAmount / (100.00 + vat) * 100.00)
            this.addEditForm.get('amount')?.patchValue(finalAmount.toFixed(2));
            this.addEditForm.get('totalAmount')?.patchValue(totalAmount.toFixed(2));
            this.addEditForm.get('amountDds')?.patchValue((totalAmount - finalAmount).toFixed(2));
        }
    }

    public calculateSingleAmountWithoutDDS(index: number) {
        let priceValue = this.rows.at(index).get('singleAmountWithDDS')?.value;
        priceValue = String(priceValue);
        if (priceValue.includes(',')) {
            priceValue = priceValue.replace(',', '.');
        }
        let price: number = parseFloat(priceValue);        
        this.rows.at(index).patchValue({
            singleAmountWithDDS: price
        });
        
        let vat: number = this.rows.at(index).get('ddsValue')?.value;
        let finalPrice: number = price / (100.00 + vat) * 100.00;

        if (Number.isNaN(finalPrice)) {
            this.rows.at(index).get('singleAmount')?.patchValue(null);
        } else {
            this.rows.at(index).get('singleAmount')?.patchValue(finalPrice.toFixed(2));
        }
        this.calculateElementTotalAmount(index);
    }

    async printPage() {
        if (this.addEditForm.get('documentType')?.value == 'C' || this.addEditForm.get('documentType')?.value == 'D') {
            this.addEditForm.get('invoice')?.setValidators([Validators.required]);
            this.addEditForm.get('invoice')?.updateValueAndValidity();

            if (this.addEditForm.get('invoice')?.value) {
                this.addEditForm.get('invoice')?.setValidators([UtilValidators.isInvalidInvoiceDataType()]);
                this.addEditForm.get('invoice')?.updateValueAndValidity();
                
            }
        } else {
            this.addEditForm.get('invoice')?.clearValidators();
            this.addEditForm.get('invoice')?.updateValueAndValidity();
        }

        if (!this.addEditForm.valid && this.accountingDocument?.status?.code != this.ANNULLED_STATUS_CODE) {
            this.uiEvent.displayUIError();
            return;
        }

        if (this.addEditForm.dirty) {
            if (this.recepient != null && this.accountingDocumentId == null) {
                await this.accountingDocumentService.generateInvoiceNum(this.userTechnicalPointIds[0]).toPromise().then(value => {
                    this.addEditForm.patchValue({invoiceNumber: value})
                })
                .catch(err => displayError(err));

                let technicalPoint = { id: this.userTechnicalPointId }
                this.addEditForm.get('technicalPoint')?.patchValue(technicalPoint);
            }

            this.addEditForm.get('invoiceDate')?.patchValue(prepareDateForDb(this.addEditForm.get('invoiceDate')?.value));
            this.addEditForm.get('dateOfChargeableEvent')?.patchValue(prepareDateForDb(this.addEditForm.get('dateOfChargeableEvent')?.value));
            this.addEditForm.get('maturityDate')?.patchValue(prepareDateForDb(this.addEditForm.get('maturityDate')?.value));
            this.addEditForm.enable();
            
            if (this.addEditForm.get('orderId')?.value != null) {
                this.techInspService.updateOrderWithWantInvoice(this.addEditForm.get('orderId')?.value).subscribe();
            }

            this.accountingDocumentService.saveAccountingDocument(this.addEditForm.value).subscribe({
                next: (id) => {
                    this.uiEvent.displayUISuccess();
                    this.router.navigate(['/add-edit-accounting-document'], {queryParams: {id: id}});
                },
                error: err => {
                    displayError(err);
                }
            });
        } 

        window.print(); 
    }

    formatType(code: any) {
        return DOCUMENT_TYPES.find(type => type.code === code)?.name
    }

    invoiceFormatter(element: any): string {
        return element.invoiceNumber;
    }

    public onChange(status: Status) {
        this.statusCode = status.code;
    }

    private onDataChangeModal() {
        const stored = localStorage.getItem('invoiceModalPopUpCounter');
        this.invoiceModalPopUpCounter = 0;
        if (stored) {
            this.invoiceModalPopUpCounter = Number.parseInt(stored, 10);
        }
        if (this.invoiceModalPopUpCounter > 0) {
            return;
        }

        this.dialogRef = this.dialog.open(this.dataChangeDialog, {
            width: '740px',
            height: '200px'
        });
        this.invoiceModalPopUpCounter++;
        localStorage.setItem('invoiceModalPopUpCounter', this.invoiceModalPopUpCounter.toString());
    }

    onDataChangeConfirmationModal() {
        this.dialogRef.afterClosed().subscribe((result: any) => {
            if (result && this.lastInvoiceId) {
                this.router.navigate(['/add-edit-accounting-document'], { queryParams: { id: this.lastInvoiceId }});
                this.doesNeedToReloadNewData = false;
                this.lastInvoiceId = 0;
            }
        });
    }

    private async findAlreadyStarted() {
        this.doesNeedToReloadNewData = false;
        if (!this.accountingDocument.invoiceNumber) return;

        const data = await this.accountingDocumentService.findLastIdByInvoiceNumber(this.accountingDocument.invoiceNumber).toPromise();
        if (!data) return;
        if (data.toString() === this.lastInvoiceId.toString()) return;

        this.lastInvoiceId = data;
        this.doesNeedToReloadNewData = true;
        this.onDataChangeModal();
    }

    get form() {
        return this.addEditForm.controls;
    }

    get rows() {
        return this.addEditForm.get('accountingDocumentElementList') as FormArray;
    }

    get publisher() {
        return this.addEditForm.get('publisher');
    }

    get recepient() {
        return this.addEditForm.get('recepient');
    }

    get customerInvoiceDetails() {
        return this.addEditForm.get('customerInvoiceDetails');
    }

    get bankAccount() {
        return this.addEditForm.get('publisher')?.get('bankAccount');
    }

}