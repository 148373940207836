import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { TranslateService } from '@ngx-translate/core';
import { NomenclatureService } from '@services/nomenclature.service';
import { EMPTY, Subscription, forkJoin } from 'rxjs';
import { catchError, concatMap, repeatWhen, tap } from 'rxjs/operators';
import { ListCustomerDiscounts } from '../model/list-customer-discounts.model';
import { displayError } from '@app/_utils/error-util';
import { StatusCode } from '@app/_enums/status-code';
import { DiscountType } from '@models/discount-type.model';

@Component({
  selector: 'app-list-customer-discounts',
  templateUrl: './list-customer-discounts.component.html',
  styleUrls: ['./list-customer-discounts.component.css']
})
export class ListCustomerDiscountsComponent extends BaseSortableSearchComponent<ListCustomerDiscounts> implements OnInit, OnDestroy {
  // Units
  discountTypes: DiscountType[];
  
  // Form
  searchForm = this.formBuilder.group({
    discountName:   null,
    isValid:        null,
    discountType:   null
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.nomenclatureService.pageableCustomerDiscounts(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          
          this.dataLoaded = true;
        }),
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
  );

  loadSearchForm$ = forkJoin([
    this.nomenclatureService.getDiscountTypes()]).pipe(
      tap(([discountTypes]) => {
      this.discountTypes = discountTypes;
      this.searchSubject.next();
    }),
    catchError(err => {
      displayError(err);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  )

  constructor(public perms: PermissionsService,
    private nomenclatureService: NomenclatureService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService) { 
      super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

}