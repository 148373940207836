import { AddEditTechnicalInspectionComponent } from '@app/technical-inspections/_components/add-edit-technical-inspection/add-edit-technical-inspection.component';
import { ListTechnicalInspectionsComponent } from '@app/technical-inspections/_components/list-technical-inspections/list-technical-inspections.component';
import { AddEditServiceConfigComponent } from '@app/service-configs/_components/add-edit-service-config/add-edit-service-config.component';
import { AddEditTechnicalPointComponent } from '@app/tech-points/_components/add-edit-technical-point/add-edit-technical-point.component';
import { AddEditClientProfileComponent } from '@app/customers/_components/add-edit-client-profile/add-edit-client-profile.component';
import { ClientContractComponent } from '@app/contracts/_components/add-edit-client-contract/add-edit-client-contract.component';
import { ListTechnicalPointsComponent } from '@app/tech-points/_components/list-technical-points/list-technical-points.component';
import { ListClientContractsComponent } from '@app/contracts/_components/list-client-contracts/list-client-contracts.component';
import { ListServiceConfigsComponent } from './service-configs/_components/list-service-configs/list-service-configs.component';
import { AddCustomerSubscriptionComponent } from '@components/add-customer-subscription/add-customer-subscription.component';
import { AddEditServiceComponent } from '@app/services/_components/add-edit-service/add-edit-service.component';
import { ListCustomersComponent } from '@app/customers/_components/list-customers/list-customers.component';
import { ListServicesComponent } from '@app/services/_components/list-services/list-services.component';
import { LoginComponent } from './login/_components/login/login.component';
import { HomeComponent } from './home/_components/home/home.component';
import { AuthGuard } from './login/_helpers/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AddEditPromotionComponent } from './promotions/add-edit-promotion/add-edit-promotion.component';
import { ListRolesComponent } from '@app/security/_components/list-roles/list-roles.component';
import { AddEditRoleComponent } from '@app/security/_components/add-edit-role/add-edit-role.component';
import { AddEditUserComponent } from '@app/security/_components/add-edit-user/add-edit-user.component';
import { ListPromotionsComponent } from './promotions/add-edit-promotion/list-promotions/list-promotions.component';
import { ListUsersComponent } from './security/_components/list-users/list-users.component';
import { AddEditTechnicalInspectionCcComponent } from './technical-inspections-cc/_components/add-edit-technical-inspection-cc/add-edit-technical-inspection-cc.component';
import { ListAccountingDocumentsComponent } from './accounting_documents/_components/list-accounting-documents/list-accounting-documents.component';
import { AddEditAccountingDocumentComponent } from './accounting_documents/_components/add-edit-accounting-document/add-edit-accounting-document.component';
import { StatisticsInspectionsComponent } from './reports/statistics-inspections/statistics-inspections.component';
import { DailyServiceReportComponent } from './reports/daily-service-report/daily-service-report.component';
import { ListCustomerDiscountsComponent } from './customer-discounts/list-customer-discounts/list-customer-discounts.component';
import { CustomerDiscountsComponent } from './customer-discounts/customer-discounts.component';
import { ExpiringServicesComponent } from './reports/expiring-services/expiring-services.component';
import { DailyServiceReportSingleComponent } from './reports/daily-service-report-by-cars/daily-service-report-single.component';
import { TechnicalInspectionSlotsComponent } from './reports/technical-inspection-slots/technical-inspection-slots.component';
import { IncomeByServicesComponent } from './reports/income-by-services/income-by-services.component';
import { IncomeByStickersComponent } from './reports/income-by-stickers/income-by-stickers.component';

import { AddProgram } from './card-config/_components/add-program/add-program.component';
import { SearchProgramCardLoyalComponent } from './card-config/_components/search-program-card-loyal/search-program-card-loyal.component';
import { SearchCardLoyalComponent } from './card-config/_components/search-card-loyal/search-card-loyal.component';
import { GeneratorCardLoyalComponent } from './card-config/_components/generator-card-loyal/generator-card-loyal.component';
import { CardDetailComponent } from './card-config/_components/card-detail/card-detail.component';
import { ListSitePageManagementComponent } from './page-management/list-site-page-management/list-site-page-management.component';
import { SitePageManagementComponent } from './page-management/site-page-management/site-page-management.component';
import { SearchPartnerProgramComponent } from './card-config/_components/search-partner-program/search-partner-program.component';
import { GeneratorPartnerCardComponent } from './card-config/_components/generator-partner-card/generator-partner-card.component';
import { GeneratorUpdatePartnerCardComponent } from './card-config/_components/generator-update-partner-card/generator-update-partner-card.component';

const routes: Routes = [
  { path: '',                                 component: HomeComponent,                                               },
  { path: 'login',                            component: LoginComponent,                                              },
  { path: 'add-edit-technical-points',        component: AddEditTechnicalPointComponent,          canActivate: [AuthGuard]},
  { path: 'list-technical-points',            component: ListTechnicalPointsComponent,            canActivate: [AuthGuard]},
  { path: 'add-edit-technical-inspection',    component: AddEditTechnicalInspectionComponent,     canActivate: [AuthGuard]},
  { path: 'list-technical-inspections',       component: ListTechnicalInspectionsComponent,       canActivate: [AuthGuard]},
  { path: 'client-contract',                  component: ClientContractComponent,                 canActivate: [AuthGuard]},
  { path: 'add-customer-subscription',        component: AddCustomerSubscriptionComponent,        canActivate: [AuthGuard]},
  { path: 'add-edit-client-contract', 	      component: ClientContractComponent,                 canActivate: [AuthGuard]},
  { path: 'list-client-contracts', 		        component: ListClientContractsComponent,            canActivate: [AuthGuard]},
  { path: 'list-accounting-documents', 		    component: ListAccountingDocumentsComponent,        canActivate: [AuthGuard]},
  { path: 'add-edit-client-profile',          component: AddEditClientProfileComponent,           canActivate: [AuthGuard]},
  { path: 'add-edit-accounting-document',     component: AddEditAccountingDocumentComponent,      canActivate: [AuthGuard]},
  { path: 'add-edit-promotion', 		          component: AddEditPromotionComponent,               canActivate: [AuthGuard]},
  { path: 'add-edit-service',                 component: AddEditServiceComponent,                 canActivate: [AuthGuard]},
  { path: 'list-services',                    component: ListServicesComponent,                   canActivate: [AuthGuard]},
  { path: 'add-edit-service-config',          component: AddEditServiceConfigComponent,           canActivate: [AuthGuard]},
  { path: 'list-service-configs',             component: ListServiceConfigsComponent,             canActivate: [AuthGuard]},
  { path: 'list-customers',                   component: ListCustomersComponent,                  canActivate: [AuthGuard]},
  { path: 'list-roles',                       component: ListRolesComponent,                      canActivate: [AuthGuard]},
  { path: 'add-edit-role',                    component: AddEditRoleComponent,                    canActivate: [AuthGuard]},
  { path: 'add-edit-user',                    component: AddEditUserComponent,                    canActivate: [AuthGuard]},
  { path: 'list-promotions',                  component: ListPromotionsComponent,                 canActivate: [AuthGuard]},
  { path: 'list-users',                       component: ListUsersComponent,                      canActivate: [AuthGuard]},
  { path: 'statistics-inspections',           component: StatisticsInspectionsComponent,          canActivate: [AuthGuard]},
  { path: 'daily-service-reports',            component: DailyServiceReportComponent,             canActivate: [AuthGuard]},
  { path: 'list-customer-discounts',          component: ListCustomerDiscountsComponent,          canActivate: [AuthGuard]},
  { path: 'customer-discounts',               component: CustomerDiscountsComponent,              canActivate: [AuthGuard]},
  { path: 'add-edit-technical-inspection-cc', component: AddEditTechnicalInspectionCcComponent,   canActivate: [AuthGuard]},
  { path: 'expiring-services', 		            component: ExpiringServicesComponent,               canActivate: [AuthGuard]},
  { path: 'daily-service-reports-single', 		component: DailyServiceReportSingleComponent,       canActivate: [AuthGuard]},
  { path: 'technical-inspection-slots', 		  component: TechnicalInspectionSlotsComponent,       canActivate: [AuthGuard]},
  { path: 'income-by-services', 		          component: IncomeByServicesComponent,               canActivate: [AuthGuard]},
  { path: 'income-by-stickers', 		          component: IncomeByStickersComponent,               canActivate: [AuthGuard]},
  { path: 'add-program', 		                  component: AddProgram,                              canActivate: [AuthGuard]},
  { path: 'search-program-card-loyal', 		    component: SearchProgramCardLoyalComponent,         canActivate: [AuthGuard]},
  { path: 'search-card-loyal', 		            component: SearchCardLoyalComponent,                canActivate: [AuthGuard]},
  { path: 'generator-card-loyal', 		        component: GeneratorCardLoyalComponent,             canActivate: [AuthGuard]},
  { path: 'card-detail', 		                  component: CardDetailComponent,                     canActivate: [AuthGuard]},
  { path: 'search-partner-card',              component: SearchPartnerProgramComponent,           canActivate: [AuthGuard]},
  { path: 'generator-partner-card',           component: GeneratorPartnerCardComponent,           canActivate: [AuthGuard]},
  { path: 'generator-update-partner-card',    component: GeneratorUpdatePartnerCardComponent,     canActivate: [AuthGuard]},
  { path: 'list-site-page-management', 		    component: ListSitePageManagementComponent,         canActivate: [AuthGuard]},
  { path: 'site-page-management', 		        component: SitePageManagementComponent,             canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const appRoutingModule = RouterModule.forRoot(routes);
