import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { YES_NO } from "@app/_enums/yes-no-enum";
import { BaseParentComponent } from "@components/_base/base-parent/base-parent.component";
import { RvsCategoryService } from "@models/rvs-category-service.model";
import { RvsCategory } from "@models/rvs-category.model";
import { ServiceType } from "@models/service-type.model";
import { TranslateService } from "@ngx-translate/core";
import { NomenclatureService } from "@services/nomenclature.service";
import { EMPTY, forkJoin } from "rxjs";
import { catchError, concatMap, repeatWhen, tap } from "rxjs/operators";
import { displayError } from "@app/_utils/error-util";
import { UIEventCustom } from "@app/_utils/ui-event-util";

@Component({
    selector: 'add-edit-service',
    templateUrl: './add-edit-service.component.html',
    styleUrls: ['./add-edit-service.component.css']
})

export class AddEditServiceComponent extends BaseParentComponent implements OnInit {
    // Units
    serviceId:      number;
    serviceTypes:   ServiceType[];
    rvsCategories:  RvsCategory[];

    // Constants
    readonly SERVICE_TYPE_CODE  = 'S';
    options                     = YES_NO;

    // Form
    addEditForm = this.formBuilder.group({
        id:                     '',
        serviceType:            ['', Validators.required],
        name:                   ['', [Validators.required, Validators.maxLength(128)]],
        orderIndex:             ['', [Validators.required, Validators.maxLength(8), Validators.pattern('^(0|[1-9][0-9]*)$')]],
        isValid:                true,
        mobileAppId:            '',
        code:                   '',
        description:            ['', Validators.maxLength(512)],
        selectedRvsCategories:  [null, Validators.required],
        rvsCategoryServices:    null,
        versionData:            null
    });

    // Observables
    service$ = this.route.queryParams.pipe(
        concatMap(params => {
            return forkJoin([
                this.nomenclatureService.findServiceById(params['id']),
                this.nomenclatureService.getServiceTypesByCode(this.SERVICE_TYPE_CODE),
                this.nomenclatureService.getRvsCategories(),
                this.nomenclatureService.getRvsCategoryServicesByServiceId(!!params['id'] ? params['id'] : 0) || null
            ]).pipe(
                tap(() => this.serviceId = params['id']),
                tap(([service, serviceTypes, rvsCategories, selectedRvsCategories]) => {
                    this.serviceTypes = serviceTypes;
                    this.rvsCategories = rvsCategories;
                    this.addEditForm.patchValue(service);
                    this.addEditForm.patchValue({ rvsCategoryServices: selectedRvsCategories });
                    this.addEditForm.patchValue({ selectedRvsCategories: selectedRvsCategories.map((x: any) => x.rvsCategory) });
                }),
                catchError(err => {
                    displayError(err);
                    this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                    return EMPTY;
                }),
                repeatWhen(() => this.reload$)
            );
        })
    );

    constructor(private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        protected router: Router,
        private uiEvent: UIEventCustom,
        public  perms: PermissionsService,
        private translateService: TranslateService,
        private nomenclatureService: NomenclatureService) {
        super(router);
    }

    ngOnInit() {
        super.ngOnInit();
     }

    change(event: { isUserInput: any; source: { value: any; selected: any; }; }) {
        let rvsCategory: RvsCategory;
        rvsCategory = event.source.value;
        if (event.isUserInput) {
            let contractSlotssCtrl: RvsCategoryService[] = this.addEditForm.get('rvsCategoryServices')?.value;

            if (event.source.selected) {
                if (contractSlotssCtrl == null) {
                    contractSlotssCtrl = []
                }
                if (!Array.isArray(contractSlotssCtrl)) {
                    contractSlotssCtrl = Array.from(contractSlotssCtrl);
                }
                if (contractSlotssCtrl.some(x => x.rvsCategory.id === rvsCategory.id)) {
                    contractSlotssCtrl.forEach(x => {
                        if (x.rvsCategory.id === rvsCategory.id) {
                            x.isValid = true;
                        }
                    })
                } else {
                    contractSlotssCtrl.push({
                        rvsCategory: rvsCategory,
                        isValid: true
                    });

                }
            } else {
                contractSlotssCtrl.forEach(x => {
                    if (x.rvsCategory.id === rvsCategory.id) {
                        x.isValid = false;
                    }
                })
            }
        }
    }

    onSubmit() {
        if (!this.addEditForm.valid) {
            this.uiEvent.displayUIError();
            this.showValidationsSubject.next(true);
            return;
        }
 
        this.nomenclatureService.saveService(this.addEditForm.value).subscribe({
            next: (id) => {
                this.uiEvent.displayUISuccess();
                this.router.navigate(['/list-services']);
            },
            error: err => {
                displayError(err);
            } 
          });
    }

    get form() { return this.addEditForm.controls; }

}