/** 
 * @param {string | number} fileName: file name or id
 * @param {any} body: returned body from BE
 * @return {void}: download
*/
const generatePdf = (fileName: string | number, body: any): void => {
    var blob = new Blob([body], {
        type: "application/pdf"
      });
      const fileUrl = URL.createObjectURL(blob);
      var a         = document.createElement('a');
      a.href        = fileUrl; 
      a.target      = '_blank';
      a.download    = fileName + '.pdf';
      document.body.appendChild(a);
      a.click();
}

export { generatePdf };