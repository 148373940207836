<ng-container *ngIf="displayComponent">
    <div class="container-fluid px-5 py-3">
        <div class="d-flex justify-content-between pb-2">
            <span class="text-blue font-weight-bold">
                <h3 translate>accountingDocument.search</h3>
            </span>
        </div>
        <div class="my-2 py-2">
            <!-- ****************************************************** 
            ************** Search Form *************
            ****************************************************** -->
            <form [formGroup]="searchForm">
                <div class="input-group mb-2">
                    <!-- point -->
                    <div class="input-group-prepend">
                        <span class="input-group-text" translate>accountingDocument.point</span>
                    </div>
                    <select class="custom-select" aria-describedby="technicalPoint" formControlName="techPointMobileAppId">
                        <option *ngIf="!hasTechnicalPoint" value="" translate>placeholder.select</option>
                        <option *ngFor="let p of technicalPoints" [value]=p.mobileAppId>
                            {{ p.shortName }}
                        </option>
                    </select>

                    <!-- partner -->
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" translate>accountingDocument.partner</span>
                    </div>
                    <input type="text" class="form-control" formControlName="recepient"
                        [ngbTypeahead]="subjectVersionsSearch" [inputFormatter]="subjectVersionFormatter"
                        [resultTemplate]="rtsv" />
                    <ng-template #rtsv let-r="result" let-t="term">
                        <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                    </ng-template>

                    <!-- choose doc type -->
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" translate>accountingDocument.chooseDocType</span>
                    </div>
                    <select class="custom-select" formControlName="documentType">
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let documentType of documentTypes" [value]="documentType.code">
                            {{ documentType.name }}
                        </option>
                    </select>

                    <!-- invoice number -->
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" translate>accountingDocument.docNumber</span>
                    </div>
                    <input type="text" class="form-control" formControlName="invoiceNumber" appTrimWhitespace>

                    <!-- doc status -->
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" translate>accountingDocument.docStatus</span>
                    </div>
                    <select class="custom-select" formControlName="statusId" [compareWith]="compareById">
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let status of statuses" [ngValue]="status.id">
                            {{ status.name }}
                        </option>
                    </select>
                </div>

                <div class="input-group mb-2">
                    <!-- payment type -->
                    <div class="input-group-prepend">
                        <span class="input-group-text" translate>accountingDocument.listPaymentType</span>
                    </div>
                    <select class="custom-select" formControlName="paymentTypeId" [compareWith]="compareById">
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let paymentType of paymentTypes" [ngValue]="paymentType.id">
                            {{ paymentType.description }}
                        </option>
                    </select>

                    <!-- date from -->
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" id="dateFrom" translate>technicalInspection.startDate</span>
                    </div>
                    <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="dateFrom" 
                        formControlName="dateFrom" ngbDatepicker #d1="ngbDatepicker" readonly>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                    </div>
        
                    <!-- date to -->
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" id="dateTo" translate>technicalInspection.endDate</span>
                    </div>
                    <input class="form-control d-inline" style="max-width: 250pt;" aria-describedby="dateTo" 
                        formControlName="dateTo" ngbDatepicker #d2="ngbDatepicker" readonly>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                    </div>

                    <!-- actions -->
                    <div class="input-group-append ml-2">
                        <button class="btn btn-outline-success" type="button" (click)="search()" translate>button.search</button>
                        <button class="btn btn-outline-danger ml-2" type="button" (click)="clearSearch()"translate>button.clear</button>
                        <button class="btn btn-outline-success ml-2" routerLink="/add-edit-accounting-document"
                            *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ACCOUNTING_DOCUMENT)" type="button" translate>
                            button.add
                        </button>
                        <i class="fa fa-file-text-o fa-2x" style="margin-left: 10px; margin-top: 3px" data-toggle="tooltip" data-placement="bottom" title="{{'button.accountingExport' | translate}}" (click)="exportTxt()"></i>
                        <i class="fa fa-file-text-o fa-2x" style="margin-left: 10px; margin-top: 3px" data-toggle="tooltip" data-placement="bottom" title="{{'button.accountingPaymentExport' | translate}}" (click)="exportPayments()"></i>
                        <i style="margin-left: 10px; margin-top: 2px" class="fa fa-file-excel-o fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" (click)="exportExcel()"></i>
                        <i style="margin-left: 5px; margin-top: 2px" data-toggle="tooltip" data-xplacement="bottom" title="{{'button.pdf' | translate}}" class="fa fa-file-pdf-o fa-2x" (click)="exportPDF()"></i> 
                        <i style="margin-left: 5px; margin-top: 5px" class="fa fa-download fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.downloadAllFromPage' | translate}}" (click)="generatePdfFromPage(content)"></i>
                    </div>
                </div>
            </form>
            <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
            <ng-container *ngIf="!loadTxtFile; else dataLoadingTemplate"></ng-container>
            <ng-container *ngIf="!loadInvoice; else dataLoadingTemplate"></ng-container>
            <ng-container *ngIf="!loadPdfFile; else dataLoadingTemplate"></ng-container>
            <br>
            <!-- ****************************************************** 
            ************** Table Data *************
            ****************************************************** -->
            <div class="d-flex justify-content-center">
                <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                    <thead>
                        <tr>
                            <th sortBy="documentType" (sort)="onSort($event)" scope="col" translate>accountingDocument.docType</th>
                            <th sortBy="invoiceNumber" (sort)="onSort($event)" scope="col" translate>accountingDocument.docNumber</th>
                            <th sortBy="invoiceDate" (sort)="onSort($event)" scope="col" translate>accountingDocument.createdAt</th>
                            <th sortBy="technicalPoint" (sort)="onSort($event)" scope="col" translate>accountingDocument.point</th>
                            <th sortBy="recipientFullName" (sort)="onSort($event)" scope="col" translate>accountingDocument.name</th>  
                            <th sortBy="recepient" (sort)="onSort($event)" scope="col" translate>accountingDocument.personalUniqueNumber</th>
                            <th sortBy="totalAmount" (sort)="onSort($event)" scope="col" translate>accountingDocument.value</th>
                            <th sortBy="paymentType" (sort)="onSort($event)" scope="col" translate>accountingDocument.listPaymentType</th>
                            <th sortBy="status" (sort)="onSort($event)" scope="col" translate>accountingDocument.status</th>
                            <th scope="col" class="text-center" translate>actions</th>
                            <th scope="col" class="text-center" translate>accountingDocument.paidDate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of content">
                            <td>{{formatType(item.documentType)}}</td>
                            <td>{{item.invoiceNumber}}</td>
                            <td>{{item.invoiceDate}}</td>
                            <td>{{item.technicalPointName}}</td>
                            <td>{{item.recipientFullName}}</td>
                            <td>{{item.recepientEIK}}</td>
                            <td>{{item.totalAmount}}</td>
                            <td>{{item.paymentType}}</td>
                            <td>{{item.status}}</td>
                            <td class="text-center py-2">
                                <button class="btn btn-sm btn-success ml-2" routerLink="/add-edit-accounting-document" data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}" 
                                    [queryParams]="{id: item.id}" *ngIf="perms.hasAccess(perms.CAN_VIEW_ACCOUNTING_DOCUMENT)" translate>
                                    <i class="fa fa-pencil fa-lg"></i>
                                </button>
                                <button class="btn btn-sm btn-success ml-2" data-toggle="tooltip" data-placement="bottom" title="{{'button.download' | translate}}" 
                                    (click)="generatePdf(item.id, item.invoiceNumber)">
                                    <i class="fa fa-download fa-lg"></i>
                                </button> 
                            </td>
                            <ng-container *ngIf="perms.hasAccess(perms.CAN_VIEW_AND_PAY_ACCOUNTING_DOCUMENT) && item.status !== INVOICE_PAID_STATUS; else displayRegular">
                                <td>
                                    <ng-container *ngIf="item.paidDate === null">
                                        <form [formGroup]="paidDateForm">
                                            <div class="d-flex justify-content-center">
                                                <button style="font-size: 1rem; padding: 0.1rem 0.9rem;" (click)="payAccount(item, paidDateForm.get('paidDate')?.value)" class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="bottom" title="{{'button.pay' | translate}}">
                                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                                </button>
                                                    <div class="input-group-prepend ml-2" style="display: none;">
                                                    <span class="input-group-text" id="paidDate" translate></span>
                                                </div>
                                                <input class="form-control d-inline" style="max-width: 80pt; margin-right: 10px; margin-left: 1rem;" aria-describedby="paidDate" 
                                                    formControlName="paidDate" ngbDatepicker #d2="ngbDatepicker" readonly>
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-template #displayRegular>
                                <ng-container *ngIf="item.paidDate !== null">
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <span>{{ item.paidDate }}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.paidDate === null">
                                    <td></td>
                                </ng-container>
                            </ng-template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
            <!-- ****************************************************** 
            ************** Pagination *************
            ****************************************************** -->
            <div class="paging-wrapper d-flex justify-content-between">
                <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)">
                    <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                        {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                    </option>
                </select>
                <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                    [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                    [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)">
                </ngb-pagination>
                <span class="pagination-index border center">
                    {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
                </span>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>