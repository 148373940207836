<div class="container justify-content-center" >
    <h1 class="title-position" translate>cardLoyal.generatorPartner</h1>
</div>
<div class="container">
    <ng-container class="border my-2 py-2 px-3">
        <!-- ****************************************************** 
        ************** Card Form *************
        ****************************************************** -->
        <form [formGroup]="excelForm" style="margin-top: 2rem;">
            <div class="input-group mb-2">
                <div class="input-group-prepend ml-2"><span class="input-group-text">Прикачете файл:</span></div>
                <input type="file" #fileInput (change)="onFileSelected($event)" 
                       formControlName="file"
                       accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                       style="display: none;">
                <button class="btn btn-outline-secondary" (click)="fileInput.click()">
                    <ng-container *ngIf="!selectedFile">Изберете</ng-container>
                    <ng-container *ngIf="selectedFile">Избран</ng-container>
                </button>

                <!-- Program -->
                <div class="input-group-prepend ml-2"><span class="input-group-text" style="margin-left: 13rem;" translate>cardLoyal.generate.type</span></div>
                <select class="custom-select input-custom-size" formControlName="programMobileAppId" required id="programMobileAppId" name="programMobileAppId" 
                    [ngClass]="{ 'is-invalid': !getForm['programMobileAppId'].valid && isSubmited }">
                    <option disabled selected value translate>placeholder.select</option>
                    <option *ngFor="let pro of programs" [ngValue]="pro.mobileAppId">
                        {{ pro.programName }}
                    </option>
                </select>

                <!-- Generate And Print Buttons -->
                <div class="input-group-append ml-2">
                    <button class="btn btn-outline-success" type="button" (click)="onSubmitExcel()" translate>button.generate</button>
                </div>
            </div>
        </form>
        <ng-container *ngIf="selectedFile">
            <div class="input-group-prepend ml-2" style="margin-top: 1rem;">
                <span class="input-group-text">Прикачен файл:</span>
                <span style="margin-top: 6px; margin-left: 11px; font-size: 1.1rem;">{{ selectedFile.name }}</span>
            </div>
        </ng-container>

        <br>
        <div class="input-group-append ml-2">
            <button class="btn btn-outline-success" type="button" (click)="dowloadPatternExcel()" translate>button.downloadPattern</button>
        </div>

        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <br>
        <div class="row">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded || (!cardList || cardList.length === 0)">
                <thead>
                    <tr>
                        <th style="width: 28%" sortBy="cardNumber" (sort)="onSubmitSearchFormSort($event)"  translate>cardLoyal.number</th>
                        <th style="width: 28%" sortBy="cardBarcode" (sort)="onSubmitSearchFormSort($event)" translate>cardLoyal.barcode</th>
                        <th style="width: 28%" sortBy="programName" (sort)="onSubmitSearchFormSort($event)" translate>type</th>
                        <ng-container *ngIf="this.cardsDataExcel && this.cardsDataExcel.size > 0">
                            <th style="width: 28%" sortBy="maxUsage" (sort)="onSubmitSearchFormSort($event)" translate>cardLoyal.maxUsage</th>
                        </ng-container>
                        <ng-container *ngIf="!this.cardsDataExcel || this.cardsDataExcel.size === 0">
                            <th style="width: 16%" translate>option</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let card of cardList">
                        <td style="width: 28%">{{ card.cardNumber }}</td>
                        <td style="width: 28%">{{ card.cardBarcode }}</td>
                        <td style="width: 28%">{{ card.programName }}</td>
                        <ng-container *ngIf="this.cardsDataExcel && this.cardsDataExcel.size > 0">
                            <td style="width: 28%">{{ card.maxUsage }}</td>
                        </ng-container>
                    </tr>    
                </tbody>
            </table>       
        </div>
        <br>
        <div *ngIf="!loadData; else dataLoadingTemplate"></div>

        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between p-2" *ngIf="cardList.length !== 0">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (ngModelChange)="loadCustomerCards()">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </ng-container>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger text-center">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>