import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapCoordinates } from '@interfaces/map-coordinates';
import { NominatimAddressResponse } from '@interfaces/nominatim-address-response';
import { NominatimCoordinatesResponse } from '@interfaces/nominatim-coordinates-response';
import { EMPTY, of } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NominatimService {
  
  private nominatimServiceURL = 'https://nominatim.infosys.bg';

  constructor(private http: HttpClient) { }

  public searchCoordinates(point: MapCoordinates | undefined): Observable<NominatimCoordinatesResponse> {
    if (point === undefined) return EMPTY;

    let params = new HttpParams()
                        .set('format', 'json')
                        .set('accept-language', 'bg')
                        .set('zoom', '18')
                        .set('lat', point?.lat.toString())
                        .set('lon', point?.lng.toString());

    return this.http.get<NominatimCoordinatesResponse>(this.nominatimServiceURL + '/reverse', { params: params });
  }

  public searchAddress(address: string): Observable<NominatimAddressResponse[]> {
    if (address == null || address === '' || address.length < 10) return of([]);

    let params = new HttpParams()
                        .set('format', 'json')
                        .set('accept-language', 'bg')
                        .set('addressdetails', '1')
                        .set('q', address);

    return this.http.get<NominatimAddressResponse[]>(this.nominatimServiceURL + '/search', { params: params }).pipe(
      map((result: NominatimAddressResponse[]) => result.sort((first, second) => this.sortResults(first, second))
    ));
  }

  private sortResults(first: NominatimAddressResponse, second: NominatimAddressResponse) {
    if (first.importance === second.importance) {
      return 0;
    } else {
      return first.importance > second.importance ? 1 : -1;
    }
  }
}
