import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { ContractTechnicalPoint } from "@app/contracts/_models/contract-technical-point.model";
import { ContractService } from "@app/contracts/_services/contract.service";
import { TechnicalPoint } from "@app/tech-points/_models/technical-point.model";
import { TechnicalPointService } from "@app/tech-points/_services/technical-point.service";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { YesNoMessageboxComponent } from "@components/_base/_messageboxes/yes-no-messagebox/yes-no-messagebox.component";
import { modalMinWidth } from "@env/config";
import { City } from "@models/city.model";
import { NomenclatureService } from "@services/nomenclature.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'contract-technical-points',
  templateUrl: './contract-technical-points.component.html',
  styleUrls: ['./contract-technical-points.component.css']
})
export class ContractTechnicalPointsComponent extends BaseChildComponent implements OnInit, OnDestroy {
  // Units
  technicalPoints:          TechnicalPoint[];
  fullTechPoints:           TechnicalPoint[];
  contractTechnicalPoints:  ContractTechnicalPoint[];
  cities:                   City[];

  // Decorators
  @Input() contractId: number;
  @Output() selectedTechnicalPoints: TechnicalPoint[];
  @Output() selectTechPoints: EventEmitter<TechnicalPoint[]> = new EventEmitter<TechnicalPoint[]>();
  
  // Form
  technicalPointForm: FormGroup;
  
  //Subscriptions
  selectedTechPointsSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private contractService: ContractService,
    private technicalPointService: TechnicalPointService,
    private nomenclatureService: NomenclatureService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.technicalPointForm = this.formBuilder.group({
      city:                     null,
      contractTechnicalPoints:  null,
      selectedTechnicalPoints:  [null, [Validators.required]],
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.selectedTechPointsSubscription?.unsubscribe();
  }

  async getTechnicalPoints() {
    if (this.isInitialized) return;
    this.isInitialized = true;

    let selectedTechPointfirstVersionIds: Array<number> = [];
    if (!!this.contractId) {
      const response = await this.contractService.findTechPointsByContractId(this.contractId).toPromise();
      response.forEach(x => {
        if (!!x.technicalPoint?.versionData?.firstVersion?.id && !x.technicalPoint.versionData?.isActual) {
          selectedTechPointfirstVersionIds.push(x.technicalPoint.versionData.firstVersion.id)
          if (this.technicalPoints == null) {
            this.technicalPoints = [];
          }
          this.technicalPoints.push(x.technicalPoint);
        }
      });

      if (selectedTechPointfirstVersionIds.length > 0) {
        this.technicalPointService.findNotSelectedTechPoints(selectedTechPointfirstVersionIds).subscribe((res: TechnicalPoint[]) => {
            res.forEach(x => this.technicalPoints.push(x))
        });
      }

      if (this.selectedTechnicalPoints == null) {
        this.selectedTechnicalPoints = [];
      }

      this.contractTechnicalPoints = response;
      response.forEach(x => this.selectedTechnicalPoints.push(x.technicalPoint));
      this.technicalPointForm.patchValue({ contractTechnicalPoints: this.contractTechnicalPoints });
      this.technicalPointForm.patchValue({ selectedTechnicalPoints: this.selectedTechnicalPoints });
      this.selectTechPoints.emit(this.selectedTechnicalPoints)
    }
    
    if (selectedTechPointfirstVersionIds.length == 0) {
      this.technicalPointService.findTechnicalPoints().subscribe((res: TechnicalPoint[]) => {
        this.technicalPoints = res;
        this.fullTechPoints = this.technicalPoints;
      });
    }

    this.nomenclatureService.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });
  }

  async change(event: { isUserInput: any; source: { value: any; selected: any; }; }, mySelect: MatSelect) {
    let techPoint: TechnicalPoint;
    techPoint = event.source.value;

    if (event.isUserInput) {
      if (this.selectedTechnicalPoints == null) {
        this.selectedTechnicalPoints = [];
      }
      if (this.contractTechnicalPoints == null) {
        this.contractTechnicalPoints = [];
      }

      if (event.source.selected) {
        this.selectedTechnicalPoints.push(techPoint);

        if (this.contractTechnicalPoints.some(x => x.technicalPoint.id === techPoint.id)) {
          this.contractTechnicalPoints.forEach(x => {
            if (x.technicalPoint.id === techPoint.id) {
              x.isValid = true;
            }
          })
        } else {
          this.contractTechnicalPoints.push({ technicalPoint: techPoint, isValid: true });
        }

        this.technicalPointForm.patchValue({ contractTechnicalPoints: this.contractTechnicalPoints });
        this.selectTechPoints.emit(this.selectedTechnicalPoints)
      } else {
        let dialogRef = this.dialog.open(YesNoMessageboxComponent, {
          ...modalMinWidth,
          data: {
            isWarning: false,
            message: "messagebox.contractTechPointRemoval"
          }
        });
        await dialogRef.afterClosed().toPromise().then((isConfirm) => {
          if (isConfirm) {
            this.selectedTechnicalPoints = this.selectedTechnicalPoints.filter(tp => tp.id != techPoint.id);
            this.contractTechnicalPoints.forEach(ctp => {
              if (ctp.technicalPoint.id == techPoint.id) {
                ctp.isValid = false
              }
            });

            this.technicalPointForm.patchValue({ contractTechnicalPoints: this.contractTechnicalPoints });
            this.selectTechPoints.emit(this.selectedTechnicalPoints)
          } else {
            mySelect.options.forEach((e: MatOption) => { if (e.value.id == techPoint.id) { e.select() }; });
          }
        });
      }
    }
  }

  filterTechPoints() {
    if (this.form.city.value != null) {
      this.technicalPoints = this.fullTechPoints.filter(x => x.city?.code == this.form.city.value.code)
    } else {
      this.technicalPoints = this.fullTechPoints;
      this.form.selectedTechnicalPoints.patchValue(this.selectedTechnicalPoints)
    }
  }

  get form() { return this.technicalPointForm.controls; }

}