import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { NomenclatureService } from '@services/nomenclature.service';
import { CustomerDiscount } from '@models/customer-discount.model';
import { Status } from '@models/status.model';
import { StatusCode } from '@app/_enums/status-code';
import { catchError, concatMap, first, repeatWhen, tap } from 'rxjs/operators';
import { LoyaltyProgramTypes } from '@app/card-config/_models/loyalty-program-types.model';
import { CardProgramServiceService } from '@app/card-config/_services/card-program-service.service';
import { ListPrograms } from '@app/card-config/_models/list-programs.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { ExportService } from '@services/export.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Router } from '@angular/router';
import { convertDateToString } from '@app/_utils/date-util';
import { displayError } from '@app/_utils/error-util';
import { EMPTY, Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-search-program-card-loyal',
  templateUrl: './search-program-card-loyal.component.html',
  styleUrls: ['./search-program-card-loyal.component.css']
})
export class SearchProgramCardLoyalComponent extends BaseSortableSearchComponent<ListPrograms> implements OnInit, OnDestroy {
  // Units
  programs:           ListPrograms[] = [];
  programTypes:       LoyaltyProgramTypes[] = [];
  customerDiscounts:  CustomerDiscount[] = [];
  statuses:           Status[] = [];

  // Booleans
  dataLoaded     = false;
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    programName:                [null],
    statusId:                   [null],
    loyaltyProgramTypeId:       [null],
  });

  // Payloads
  pageSizeOptions:    number[];
  maxSize:            number;
  
  // Observables
  searchFormSubscription: Subscription;
  loadSearchForm$ = forkJoin([
    this.nomenclatureService.getLoyaltyProgramTypes(),
    this.nomenclatureService.getStatusesByType(StatusCode.LoyaltyProgram)]).pipe(
      tap(([types, statuses]) => {
        this.programTypes = types;
        this.statuses = statuses;
        this.searchSubject.next();
      }),
      catchError(err => {
        displayError(err);
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
  );

  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.cardProgramService.pageable(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    })
  );

  constructor (
    protected router:             Router,
    private perms:                PermissionsService,
    private formBuilder:          FormBuilder, 
    private translateService:     TranslateService,
    private exportService:        ExportService,
    private nomenclatureService:  NomenclatureService,
    private cardProgramService:   CardProgramServiceService)  {
      super(); 
  }

  async ngOnInit() {
    if (!this.perms.hasAccess(this.perms.CAN_SEARCH_LOYALTY_PROGRAM)) {
      this.router.navigate(['/']);
    }
    this.searchFormSubscription = this.loadSearchForm$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchFormSubscription.unsubscribe();
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      programName:              [null],
      statusId:                 [null],
      loyaltyProgramTypeId:     [null]
    });

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.programResult"), null, true);
    } catch (err) {
      displayError(new Error());
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.programResult"), convertDateToString(new Date()));
    } catch (err) {
      displayError(new Error());
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.cardProgramService.getPagablePrograms(this.sortingPaging, this.searchForm.value).pipe(first()).toPromise();

    result.content.forEach(object => {
      newContent?.push([object.programName,
                        object.status,
                        object.loyaltyProgramTypeName,
                        object.customerDiscountName,
                        object.customerImpulseDiscountName,
                        object.validFrom,
                        object.validTo,
                        object.subjectName])
    })

    return newContent;  
  }

  private getHeadings(): string[][] {
    return [[ this.translateService.instant("cardLoyal.name"),
              this.translateService.instant("cardLoyal.status"), 
              this.translateService.instant("cardLoyal.type"),
              this.translateService.instant("cardLoyal.discount"),
              this.translateService.instant("cardLoyal.impulseDiscount"),
              this.translateService.instant("cardLoyal.validFrom"), 
              this.translateService.instant("cardLoyal.validTo"),
              this.translateService.instant("cardLoyal.subject")]]
  }

  private getFilterBody(): any[] {
    let status = this.statuses.find(st => st.id === this.searchForm.get('statusId')?.value)?.name;
    let type = this.programTypes.find(tp => tp.id === this.searchForm.get('loyaltyProgramTypeId')?.value)?.name;
    
    let result: string[] = [
      this.searchForm.get('programName')?.value, 
      status,
      type,
    ];
    
    return [result];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 9;
    let result: any[] = [
      this.translateService.instant('cardLoyal.name'),
      this.translateService.instant('cardLoyal.status'),
      this.translateService.instant('cardLoyal.type'),
    ];

    return [
      [{content: this.translateService.instant('exportData.filters.chosenFilters'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

  get getForm() {
    return this.searchForm.controls;
  }

}