import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Order } from '@models/order.model';
import { SortingPaging } from '@helpers/sorting-paging';
import { Page } from '@interfaces/page';
import { FormGroup } from '@angular/forms';
import { Customer } from '../_models/customer.model';
import { CustomerRvs } from '../_models/customer-rvs.model';
import { PromotionCustomer } from '../_models/promotion-customer.model';
import { CustomerSubscription } from '../_models/customer-subscription.model';
import { CustomerNotification } from '../_models/customer-notification.model';
import { CustomerReminder } from '../_models/customer-reminder.model';
import { CustomerInvoiceDetails } from '../_models/customer-invoice-details.model';
import { ListCustomer } from '../_models/list-customer.model';
import { CustomerHttpParamsCodec } from '@helpers/custom-http-params-codec';
import { SortingPagingData } from '@helpers/sorting-paging-data';


const customerURL = environment.apiBaseURL + '/customers';

const getPdfDocument                    = customerURL + '/downloadPdf';
const getImageFromFTP                   = customerURL + '/downloadImages';
const findCustomerByIdURL               = customerURL + '/{id}';
const findRvsByCustomerIdURL            = customerURL + '/{id}/rvs';
const findCustomerPageableURL           = customerURL + '/pageable';
const countCustomerPageableURL          = customerURL + '/pageable-count'
const findOrdersByCustomerIdURL         = customerURL + '/{id}/orders';
const findRemindersByCustomerIdURL      = customerURL + '/{id}/reminders';
const findPromoCodesByCustomerIdURL     = customerURL + '/{id}/promo-codes';
const findSubscriptionsByCustomerIdURL  = customerURL + '/{id}/subscriptions';
const findInvoiceDetailsByCustomerIdURL = customerURL + '/{id}/invoice-details';
const findNotificationsByCustomerIdURL  = customerURL + '/{id}/notifications-settings';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  public findCustomerById(id: number): Observable<Customer> {
    return this.http.get<Customer>(findCustomerByIdURL.replace('{id}', `${id}`));
  }

  public findRvsByCustomerId(id: number): Observable<CustomerRvs[]> {
    return this.http.get<CustomerRvs[]>(findRvsByCustomerIdURL.replace('{id}', `${id}`));
  }

  public findPromoCodesByCustomerId(id: number): Observable<PromotionCustomer[]> {
    return this.http.get<PromotionCustomer[]>(findPromoCodesByCustomerIdURL.replace('{id}', `${id}`));
  }

  public findSubscriptionsByCustomerId(id: number): Observable<CustomerSubscription[]> {
    return this.http.get<CustomerSubscription[]>(findSubscriptionsByCustomerIdURL.replace('{id}', `${id}`));
  }

  public findNotificationsByCustomerId(id: number): Observable<CustomerNotification[]> {
    return this.http.get<CustomerNotification[]>(findNotificationsByCustomerIdURL.replace('{id}', `${id}`));
  }

  public findRemindersByCustomerId(id: number): Observable<CustomerReminder[]> {
    return this.http.get<CustomerReminder[]>(findRemindersByCustomerIdURL.replace('{id}', `${id}`));
  }

  public findInvoiceDetailsByCustomerId(id: number): Observable<CustomerInvoiceDetails> {
    return this.http.get<CustomerInvoiceDetails>(findInvoiceDetailsByCustomerIdURL.replace('{id}', `${id}`));
  }

  public findOrdersByCustomerId(id: number): Observable<Order[]> {
    return this.http.get<Order[]>(findOrdersByCustomerIdURL.replace('{id}', `${id}`));
  }

  public pageable(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm),
      this.countAllByFilter(sortingPaging, searchForm)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<ListCustomer>> {
    return this.http.get<Page<ListCustomer>>(findCustomerPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
    return this.http.get<number>(countCustomerPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  private constructPageableHttpParams(sortingPaging: SortingPagingData, searchObject: FormGroup) {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchObject).forEach(item => {
      if (item[1] && item[1].length !== 0) {
          requestParams = requestParams.set(item[0], item[1]);
      }
  });
 
  return requestParams;
  }

  public getPdfDocument(path: String): Observable<any> {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    requestParams = requestParams.set("path", path.toString());

    return this.http.get(getPdfDocument,{params:requestParams, responseType: 'blob'});
  }

  public getImageFromFTP(path: String): Observable<any> {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    requestParams = requestParams.set("filePath", path.toString());

    return this.http.get(getImageFromFTP, {params:requestParams});
  }

}
