<div class="container-fluid px-5 py-3">
    <div class="d-flex pb-2">
        <span class="text-blue font-weight-bold">
            <h3 translate>references.dailyReportSingle</h3>
        </span>
    </div>
    <div class="my-2 py-2">
        <!-- ****************************************************** 
        ************** Search Card Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- point -->
                <div>
                    <span class="input-group-text" translate>references.point</span>
                </div>
                <select class="custom-select" aria-describedby="techPointMobileAppId" formControlName="techPointMobileAppId" style="max-width: 200pt;">
                    <option *ngIf="!hasTechnicalPoint" value="" translate>placeholder.select</option>
                    <option *ngFor="let p of technicalPoints" [value]=p.mobileAppId>
                        {{p.shortName}}
                    </option>
                </select>

                <!-- date from -->
                <div class="ml-2">
                    <span class="input-group-text" id="dateFrom" translate>technicalInspection.startDate</span>
                </div>
                <input class="form-control d-inline" aria-describedby="dateFrom" style="max-width: 130pt;"
                       formControlName="dateFrom" ngbDatepicker #d1="ngbDatepicker" readonly>
                <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button" style="height: 38px; border-radius: 0;"></button>
    
                <!-- date to -->
                <div class="ml-2">
                    <span class="input-group-text" id="dateTo" translate>technicalInspection.endDate</span>
                </div>
                <input class="form-control d-inline" aria-describedby="dateTo" style="max-width: 130pt;" 
                       formControlName="dateTo" ngbDatepicker #d2="ngbDatepicker" readonly>
                <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button" style="height: 38px; border-radius: 0;"></button>

                <!-- reg number -->
                 <div class="ml-2">
                    <span class="input-group-text" translate>references.regNumberShort</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitRegNumber">
                     <input type="text" class="form-control" formControlName="regNumber" (keyup.enter)="search()" style='text-transform:uppercase; max-width: 170pt;'> 
                 </ng-container>
                 <ng-template #pleaseWaitRegNumber>
                    <input type="text" class="form-control" formControlName="regNumber" style='text-transform:uppercase; max-width: 170pt;'> 
                </ng-template>

                <!-- category -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>references.category</span>
                </div>
                <select class="custom-select" aria-describedby="categoryId" formControlName="categoryId" style="max-width: 170pt;">
                    <option *ngIf="!categories" value="" translate>placeholder.select</option>
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let category of categories" [value]=category.id>
                        {{category.code}}
                    </option>
                </select>
                
                <!-- is employee -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>references.isEmployee</span>
                </div>
                <select class="custom-select" aria-describedby="isEmployee" formControlName="isEmployee" style="max-width: 170pt;">
                    <option *ngIf="!isEmployeeOptions" value="" translate>placeholder.select</option>
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let option of isEmployeeOptions" [value]="option.value">
                        {{ option.key }}
                    </option>
                </select>
            </div>
            <div class="input-group mb-2">
                <!-- owner name -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>references.name</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitOwnerName">
                    <input type="text" class="form-control" formControlName="ownerName"
                        [ngbTypeahead]="ownerSearch" 
                        [resultTemplate]="rtsv" />
                    <ng-template #rtsv let-r="result" let-t="term">
                        <ngb-highlight [result]="r" [term]="t"></ngb-highlight>
                    </ng-template>
                </ng-container>
                <ng-template #pleaseWaitOwnerName>
                    <input type="text" class="form-control" formControlName="ownerName"/>
                </ng-template>

                <!-- subject name -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text"translate>references.subjectName</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitSubject">
                    <input type="text" class="form-control" formControlName="subject"
                        [ngbTypeahead]="subjectVersionsSearch" [inputFormatter]="subjectVersionFormatter"
                        [resultTemplate]="res" />
                    <ng-template #res let-r="result" let-t="term">
                        <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                    </ng-template>
                </ng-container>
                <ng-template #pleaseWaitSubject>
                    <input type="text" class="form-control" formControlName="subject"/>
                </ng-template>

                <!-- service type -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>references.serviceType</span>
                </div>
                <ng-container *ngIf="!loadPdfOrExcel;else pleaseWaitServiceType">
                    <select class="custom-select" aria-describedby="serviceType" formControlName="serviceType" style="max-width: 300pt;" (change)='loadServices()'>
                        <option *ngIf="!serviceTypes" value="" translate>placeholder.select</option>
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let s of serviceTypes" [value]=s.id>
                            {{ s.description }}
                        </option>
                    </select>
                </ng-container>
                <ng-template #pleaseWaitServiceType>
                    <select class="custom-select" aria-describedby="serviceType" formControlName="serviceType" style="max-width: 300pt;">
                        <option *ngIf="!serviceTypes" value="" translate>placeholder.select</option>
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let s of serviceTypes" [value]=s.id>
                            {{ s.description }}
                        </option>
                    </select>
                </ng-template>

                <!-- service -->
                <div class="input-group-prepend ml-2" >
                    <span class="input-group-text" translate>references.service</span>
                </div>
                <select class="custom-select" aria-describedby="serviceName" formControlName="serviceName" style="max-width: 320pt;">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let s of services" [value]=s.name>
                        {{ s.name }}
                    </option>
                </select>
                
                <!-- actions -->
                <div class="input-group-append ml-2 action-buttons">
                    <button class="btn btn-outline-success" type="button" (click)="search()" [disabled]="loadPdfOrExcel" translate>button.search</button>
                    <button class="btn btn-outline-danger ml-2" type="button" (click)="clearSearch()" [disabled]="loadPdfOrExcel" translate>button.clear</button>
                    <ng-container *ngIf="!loadPdfOrExcel;else pleaseWait">
                        <ng-container *ngIf="perms.hasAccess(perms.CAN_DOWNLOAD_DAILY_CAR_REPORTS)">
                            <i class="fa fa-file-excel-o fa-2x ml-2 mt-1" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" (click)="exportExcel()"></i>
                            <i class="fa fa-file-pdf-o fa-2x ml-2 mt-1" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" (click)="exportPDF()"></i>
                        </ng-container>
                    </ng-container>
                    <ng-template #pleaseWait>
                        <span class="pleaseWait" translate>placeholder.wait</span>
                    </ng-template>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100 table-responsive" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th style="width: 6%;" scope="col" translate>references.date</th>
                        <th style="width: 10%;" scope="col" translate>references.point</th>
                        <th style="width: 8%;" scope="col" translate>references.name</th>
                        <th style="width: 6%;" scope="col" translate>references.subjectName</th>
                        <th style="width: 6%;" scope="col" translate>references.isEmployee</th>
                        <th style="width: 10%;" scope="col" translate>references.service</th>
                        <th style="width: 10%;" scope="col" translate>references.paymentMethod</th>
                        <th style="width: 6%;" scope="col" translate>references.discount</th>
                        <th style="width: 6%;" scope="col" translate>references.pointTechInsp</th>
                        <th style="width: 10%;" scope="col" translate>references.unitPriceWithVatAfterDiscount</th>
                        <th style="width: 5%;" scope="col" translate>references.totalPrice</th>
                        <th style="width: 6%;" scope="col" translate>references.receptionChannel</th>
                        <th style="width: 5%;" scope="col" translate>references.regNumberShort</th>
                        <th style="width: 4%;" scope="col" translate>references.category</th>
                        <!-- <th style="width: 6%;" scope="col" translate>references.conclusionDescription</th>
                        <th style="width: 5%;" scope="col" translate>references.validTo</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{item.inspectionDate}}</td>
                        <td>{{item.techPointShortName}}</td>
                        <td>{{item.personName}}</td>
                        <td>{{item.invoiceSubjectName}}</td>
                        <td translate>{{item.isEmployee ? "yes" : "no"}}</td>
                        <td>{{item.serviceName}}</td>
                        <td>{{item.paymentType}}</td>
                        <td>{{item.discountName}}</td>
                        <td>{{item.serviceCount}}</td>
                        <td>{{item.techPointDiscountAmount}}</td>
                        <td>{{item.totalAmountDds}}</td>
                        <td>{{item.incomingWay}}</td>
                        <td>{{item.regNumber}}</td>
                        <td>{{item.categoryCode}}</td>
                        <!-- <td>{{item.conclusionDescription}}</td>
                        <td>{{item.validTo}}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between action-pagination">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)" [disabled]="loadPdfOrExcel">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)" [disabled]="loadPdfOrExcel">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>