import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceAddInfoItem } from "@app/service-configs/_models/service-add-info-item.model";
import { Service } from "@app/services/_models/service.model";
import { PRICE_ATTRIBUTE_KIND } from "@app/_enums/price-attribute-kind";
import { YES_NO } from "@app/_enums/yes-no-enum";
import { ArrayValidators } from "@app/_validators/array.validator";
import { BaseParentComponent } from "@components/_base/base-parent/base-parent.component";
import { PRICE_ATTRIBUTE_TYPE } from "@models/price-attribute.model";
import { RvsCategoryService } from "@models/rvs-category-service.model";
import { RvsCategory } from "@models/rvs-category.model";
import { ServiceType } from "@models/service-type.model";
import { TranslateService } from "@ngx-translate/core";
import { NomenclatureService } from "@services/nomenclature.service";
import { EMPTY, forkJoin } from "rxjs";
import { config } from '@env/config';
import { catchError, concatMap, repeatWhen, tap } from "rxjs/operators";
import { ServiceAddInfo } from "@app/service-configs/_models/service-add-info.model";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { displayError } from "@app/_utils/error-util";
import { UIEventCustom } from "@app/_utils/ui-event-util";

@Component({
    selector: 'add-edit-service-config',
    templateUrl: './add-edit-service-config.component.html',
    styleUrls: ['./add-edit-service-config.component.css']
})

export class AddEditServiceConfigComponent extends BaseParentComponent implements OnInit {
    // Units
    serviceAddInfo:             ServiceAddInfo;
    serviceTypes:               ServiceType[];
    rvsCategories:              RvsCategory[];
    selectedRvsCategories:      RvsCategoryService[];
    services:                   Service[];

    // Constants
    readonly SERVICE_TYPE_CODE      = 'P';
    readonly VAT_GROUPS             = [20, 9];
    readonly DEAFULT_VAT_GROUP      = 20;
    options                         = YES_NO;

    // Form
    addEditForm = this.formBuilder.group({
        id: '',
        service: this.formBuilder.group({
            id:                     null,
            code:                   null,
            serviceType:            ['', Validators.required],
            name:                   ['', [Validators.required, Validators.maxLength(128)]],
            // orderIndex:          ['', [Validators.required, Validators.maxLength(8), Validators.pattern(config.regexOnlyPositiveNumber)]],
            isValid:                true,
            mobileAppId:            null,
            selectedRvsCategories:  [null, Validators.required],
            rvsCategoryServices:    [null],
            versionData:            null,
        }),
        // validFrom:               ['', [Validators.required]],
        // validTo:                 ['', [Validators.required]],
        allowDeferredPayment:   false,
        depositsCount:          [{ value: 1, disabled: true }, [Validators.compose([Validators.maxLength(8), Validators.pattern(config.regexOnlyPositiveNumber)])]],
        depositSinglePrice:     [null, ], //[Validators.maxLength(8), Validators.pattern(config.regexFloatNumber), Validators.required]
        depositDescription:     ['', [Validators.maxLength(256)]],
        priceAttribute: this.formBuilder.group({
            id:         null,
            type:       PRICE_ATTRIBUTE_TYPE.PRICE,
            amount:     [null, [Validators.maxLength(8), Validators.pattern(config.regexFloatNumber), Validators.required]],
            amountDds:  [null, [Validators.maxLength(8), Validators.required]],
            ddsValue:   [this.DEAFULT_VAT_GROUP, [Validators.required]],
            kind:       PRICE_ATTRIBUTE_KIND.VALUE
        }),
        versionData: null,
        serviceAddInfoItemList: this.formBuilder.array([], [ArrayValidators.equalsToSomeGroupKey('isValid', true)])
    });
    // , { validator: [DateValidators.groupValidator('validFrom', 'validTo')] } as AbstractControlOptions);

    // Observables
    serviceAddInfo$ = this.route.queryParams.pipe(
        concatMap(params => {
            return forkJoin([
                this.nomenclatureService.findServiceAddInfoById(params['id']).pipe(
                    concatMap((serviceAddInfo) => {
                        this.serviceAddInfo = serviceAddInfo;
                        return this.nomenclatureService.getRvsCategoryServicesByServiceId(
                            !!serviceAddInfo?.service?.id ? serviceAddInfo?.service?.id : 0).pipe(
                                concatMap((selectedRvsCategories) => {
                                    this.selectedRvsCategories = selectedRvsCategories;
                                    const rvsCategoriesIds = selectedRvsCategories.map(x => x.rvsCategory.id);
                                    return this.nomenclatureService.findAllValidServicesByTypeCodeAndRvsCategories("S", !!rvsCategoriesIds ? rvsCategoriesIds : []);
                                })
                            )
                        }
                    )),
                this.nomenclatureService.getServiceTypesByCode(this.SERVICE_TYPE_CODE),
                this.nomenclatureService.getRvsCategories()
            ]).pipe(
                tap(([services, serviceTypes, rvsCategories]) => {
                    this.serviceTypes = serviceTypes;
                    this.rvsCategories = rvsCategories;
                    this.services = services;

                    this.addEditForm.patchValue(this.serviceAddInfo);
                    if (!this.serviceAddInfo?.allowDeferredPayment) {
                        this.addEditForm.patchValue({ depositsCount: 1 });
                    } else {
                        this.addEditForm.get('depositsCount')?.enable()
                    }

                    let contractServicesCtrl = <FormArray>this.addEditForm.controls['serviceAddInfoItemList']
                    this.serviceAddInfo?.serviceAddInfoItemList.forEach((x: ServiceAddInfoItem) => {
                        if (x.childService != null && (!x.childService.isValid || !x.childService.versionData?.isActual)) {
                            this.services = this.services.filter(y => y.versionData?.firstVersion?.id != x.serviceAddInfo.versionData?.firstVersion?.id);
                            this.services.push(x.childService);
                        }
                        contractServicesCtrl.push(this.formBuilder.group({
                                id:             x.id,
                                isValid:        x.isValid,
                                childService:   [x.childService, Validators.required],
                                usageCount:     [x.usageCount, [Validators.min(0), Validators.max(999999), Validators.required]]
                            })
                        );
                    });

                    this.addEditForm.patchValue({ service: { rvsCategoryServices: this.selectedRvsCategories } });
                    this.addEditForm.patchValue({ service: { selectedRvsCategories: this.selectedRvsCategories.map((x: any) => x.rvsCategory) } });
                }),
                catchError(err => {
                    displayError(err);
                    this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                    return EMPTY;
                }),
                repeatWhen(() => this.reload$)
            );
        })
    );

    constructor(private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        protected router: Router,
        private uiEvent: UIEventCustom,
        public  perms: PermissionsService,
        private translateService: TranslateService,
        private nomenclatureService: NomenclatureService) {
            super(router);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    change(event: { isUserInput: any; source: { value: any; selected: any; }; }) {
        let rvsCategory: RvsCategory;
        rvsCategory = event.source.value;
        if (event.isUserInput) {
            let contractSlotssCtrl: RvsCategoryService[] = this.addEditForm.get('service')?.get('rvsCategoryServices')?.value;

            if (event.source.selected) {
                if (contractSlotssCtrl == null) {
                    contractSlotssCtrl = []
                }
                if (!Array.isArray(contractSlotssCtrl)) {
                    contractSlotssCtrl = Array.from(contractSlotssCtrl);
                }
                if (contractSlotssCtrl.some(x => x.rvsCategory.id === rvsCategory.id)) {
                    contractSlotssCtrl.forEach(x => {
                        if (x.rvsCategory.id === rvsCategory.id) {
                            x.isValid = true;
                        }
                    })
                } else {
                    contractSlotssCtrl.push({
                        rvsCategory: rvsCategory,
                        isValid: true
                    });
                }

            } else {
                contractSlotssCtrl.forEach(x => {
                    if (x.rvsCategory.id === rvsCategory.id) {
                        x.isValid = false;
                    }
                })
            }
            let rvsCategoriesIds = contractSlotssCtrl.filter(x => x.isValid).map(x => x.rvsCategory.id);
            this.updateChildServices(rvsCategoriesIds);
        }
    }

    updateChildServices(rvsCategoriesIds: number[]) {
        this.nomenclatureService.findAllValidServicesByTypeCodeAndRvsCategories("S", !!rvsCategoriesIds ? rvsCategoriesIds : []).subscribe(
            (services) => {
                this.services = services;
                this.addEditForm.controls['serviceAddInfoItemList'].value.forEach((x: ServiceAddInfoItem, index: number) => {
                    if (!this.services.some(y => x.childService?.id == y.id)) {
                        (<FormArray>this.addEditForm.get('serviceAddInfoItemList')).at(index).patchValue({ childService: null });
                    }
                });
            }
        )
    }

    onPaymentTypeSelect(event: any) {
        if (event.target.value == 'false') {
            this.addEditForm.patchValue({ depositsCount: 1 })
            this.addEditForm.updateValueAndValidity();
            this.addEditForm.controls['depositsCount'].disable();
        } else {
            this.addEditForm.controls['depositsCount'].enable();
        }

        this.calculateDepositPrice();
    }

    calculatePriceAmountDds() {
        let price: number = this.priceAttribute.get('amount')?.value;
        let vat: number = this.priceAttribute.get('ddsValue')?.value;
        let finalPrice: number = price * 1 + ((vat / 100) * price);

        if (Number.isNaN(finalPrice)){
            this.priceAttribute.get('amountDds')?.patchValue(null);
        } else {
            this.priceAttribute.get('amountDds')?.patchValue(finalPrice.toFixed(2));
        }

        this.calculateDepositPrice();
    }

    calculateDepositPrice() {
        let depositSinglePrice = this.priceAttribute.get('amountDds')?.value / this.addEditForm.get('depositsCount')?.value;
        this.addEditForm.get('depositSinglePrice')?.patchValue(depositSinglePrice.toFixed(2));
    }

    onSubmit() {
        if (!this.addEditForm.valid) {
            this.uiEvent.displayUIError();
            this.showValidationsSubject.next(true);
            return;
        }

        this.nomenclatureService.saveServiceAddInfo(this.addEditForm.value).subscribe({
            next: (id) => {
                this.uiEvent.displayUISuccess();
                this.router.navigate(['/list-service-configs']);
            },
            error: err => {
                displayError(err);
            }
        });
    }

    addRow() {
        let contractServicesCtrl = <FormArray>this.addEditForm.controls['serviceAddInfoItemList']
        contractServicesCtrl.push(this.formBuilder.group({
            id: null,
            childService: [null, Validators.required],
            usageCount: ['', [Validators.min(0), Validators.max(999999), Validators.required]],
            isValid: true
        }));
    }

    deleteRow(index: number) {
        let contractServicesCtrl = <FormArray>this.addEditForm.controls['serviceAddInfoItemList']
        let element = contractServicesCtrl.at(index).value
        element.isValid = false;
        contractServicesCtrl.at(index).patchValue(element);
        (<FormArray>this.addEditForm.controls['serviceAddInfoItemList']).at(index).get("usageCount")?.clearValidators();
        (<FormArray>this.addEditForm.controls['serviceAddInfoItemList']).at(index).get("childService")?.clearValidators();
        (<FormArray>this.addEditForm.controls['serviceAddInfoItemList']).at(index).get("usageCount")?.updateValueAndValidity();
        (<FormArray>this.addEditForm.controls['serviceAddInfoItemList']).at(index).get("childService")?.updateValueAndValidity();
    }

    updateChildService(index: number) {
        let rowCtrl = <FormArray>this.addEditForm.controls['serviceAddInfoItemList']
        let childService = rowCtrl.at(index).value.childService;
        
        this.nomenclatureService.findActualServiceByFirstVersion(childService.versionData.firstVersion.id).subscribe(res => {
            this.services = this.services.filter((x: Service) => x.id != childService.id);
            rowCtrl.at(index).patchValue({ childService: res });
        });
    }

    get form() {
        return this.addEditForm.controls;
    }

    get service() {
        return this.addEditForm.get('service') as FormGroup;
    }

    get priceAttribute() {
        return this.addEditForm.get('priceAttribute') as FormGroup;
    }

    get rows() {
        return this.addEditForm.get('serviceAddInfoItemList') as FormArray;
    }

}