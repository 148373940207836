<!-- <div *ngIf="isInitialized; else notificationsHistoryLoading"> -->
<div class="card-body">
    <table class="table">
      <thead>
        <tr>
          <th translate>clientProfile.notificationTitle </th>
          <th translate>clientProfile.notificationContent</th>
          <th translate>clientProfile.notificationDate</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>Само на 17.05.2021 -20% на ГТП във всички сервизи на Мици</td>
          <td>16.05.2021</td>
        </tr>
      </tbody>
    </table>
  </div>
 <!-- </div> -->
<!-- 
  <ng-template #notificationsHistoryLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else notificationsHistorySpinner
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #notificationsHistorySpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template> -->
