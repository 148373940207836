import { FormControl, FormGroup} from "@angular/forms";

export class PasswordValidator {

   public static MustMatch(newPassword: string, repeatNewPassword: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[newPassword];
            const matchingControl = formGroup.controls[repeatNewPassword];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }

    public static isStrong(control: FormControl){
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSymbol = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value);

        const valid = hasNumber && hasUpper && hasLower && hasSymbol;
        if (!valid) {
            return { strong: true };
        }
        return null;
    }
}