<div class="my-1 py-1" *ngIf="loadSearchForm$ | async as data; else loading">
<form [formGroup]="searchForm">
<div class="input-group mb-2">
    <div class="input-group-prepend">
        <div class="input-group-text" translate> registerPerson.name</div>
    </div>
    <input class="form-control" type="text" (keyup.enter)="search()" formControlName="fullName" id="fullNameInput">
    <div class="input-group-prepend">
        <div class="input-group-text" translate> registerPerson.personalNumber</div>
    </div>
    <input class="form-control" type="text" (keyup.enter)="search()" formControlName="identNum" id="identNumInput">
            <div class="input-group-prepend">
                <div class="input-group-text" translate>registerPerson.type</div>
            </div>
            <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="type">
                <option *ngFor="let type of subjectTypes" [ngValue]="type"> {{type.description}}
            </select>    
    <div class="input-group-append ml-2">
        <button class="btn btn-outline-success" type="button" (click)="search()" translate>button.search</button>
        <button class="btn btn-outline-success ml-2" type="button" (click)="clearSearch()" translate>button.clear</button>
        <button class="btn btn-outline-danger ml-2" type="button" (click)="loadAdd()" translate>button.add</button>
    </div>
</div>
</form>
<h5 class="style" translate>registerPerson.resultFromSearch</h5>
<!-- <div style="text-align: left;">
    <label class="style" >Export as:</label>
    <button class="btn btn-outline-success" style="margin-left: 20px;" type="button" (click)="exportAsExcelFile()" >Excel</button>
    <button class="btn btn-outline-success" style="margin-left: 20px;" type="button"  (click)="exportAsPdfFile()">PDF</button>
    </div> -->

<div class="inline border" style="width: 100%;">
    <table class="table table-bordered table-striped w-100" id="table">
        <thead>
            <tr>
                <th translate>col.name</th>
                <th translate>col.personalNumber</th>
                <th translate>col.type</th>
                <th translate>col.city</th>
                <th translate>col.address</th>
                <th translate>col.actions</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let subject of content">
                <td>{{subject.fullName}}</td>
                <td>{{subject.identNum}}</td>
                <td>{{subject.type}}</td>
                <td>{{subject.city}}</td>
                <td>{{subject.address}}</td>
                <td class="text-center py-2">
                    <button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}" (click)="getSubject(subject)"><i class="fa fa-pencil fa-lg"></i></button>
                    <button class="btn btn-sm btn-success ml-2" data-toggle="tooltip" data-placement="bottom" title="{{'button.choose' | translate}}" (click)="selectSubject(subject.id)"><i class="fa fa-check-square fa-lg"></i></button>
                </td>
                   
            </tr>  
        </tbody>
    </table>
</div>
<div class="paging-wrapper d-flex justify-content-between p-2">
    <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)">
        <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
          {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
        </option>
    </select>
    <ngb-pagination class="pagination-control"
        [(page)]="sortingPaging.pageNumber"
        [pageSize]="sortingPaging.pageSize"
        [collectionSize]="sortingPaging.totalElements"
        [boundaryLinks]="true"
        [maxSize]="maxSize"
        (pageChange)="pageChanged($event)">
    </ngb-pagination>
    <span class="pagination-index border center">
        {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
    </span>
</div>
</div>
<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>
