import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { catchError, first, repeatWhen, tap } from 'rxjs/operators';
import { CardProgramServiceService } from '@app/card-config/_services/card-program-service.service';
import { LoyaltyPrograms } from '@app/card-config/_models/loyalty-programs.model';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { ListCustomerCards } from '@app/card-config/_models/list-customer-cards.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Router } from '@angular/router';
import { LoyaltyProgramsEssential } from '@app/card-config/_models/loyalty-programs-essential.model';
import { displayError } from '@app/_utils/error-util';
import { EMPTY, forkJoin } from 'rxjs';
import { generatePdf } from '@app/_utils/pdf-util';
import { DiscountType } from '@models/discount-type.model';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-search-card-loyal',
  templateUrl: './search-card-loyal.component.html',
  styleUrls: ['./search-card-loyal.component.css']
})
export class SearchCardLoyalComponent extends BaseSortableSearchComponent<LoyaltyPrograms> implements OnInit, OnDestroy {
  // Units
  cards:          ListCustomerCards[] = [];
  allCards:       ListCustomerCards[] = [];
  programs:       LoyaltyProgramsEssential[] = [];
  discountTypes:  DiscountType[] = [];

  // Booleans
  isSubmited           = false;
  loadMultiCardBarcode = false;
  dataLoaded           = false;
  loadPdf              = false;
  
  // Form
  searchForm = this.formBuilder.group({
    fromNumber:         [null, [Validators.required]],
    toNumber:           [null, [Validators.required]],
    programMobileAppId: [null, [Validators.required]],
  });

  searchTableForm = this.formBuilder.group({
    cardNumber:        [null],
    cardBarcode:       [null],
    programName:       [null]
  });

  // Observables
  loadCards$ = forkJoin([
    this.cardService.getAllFilteredCustomerCards(this.sortingPaging, this.searchForm.value),
  ]).pipe(
    tap(([cards]) => {
      this.allCards = cards;
      this.dataLoaded = true;
      this.searchSubject.next();
    }), catchError(error => {
      this.dataLoaded = false;
      displayError(error);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  );

  constructor (
    private perms:                PermissionsService,
    protected router:             Router,
    private formBuilder:          FormBuilder, 
    private translateService:     TranslateService,
    private uiEvent:              UIEventCustom,
    private programService:       CardProgramServiceService,
    private cardService:          CustomerCardService)  {
      super(); 
  }

  async ngOnInit() {
    if (!this.perms.hasAccess(this.perms.CAN_SEARCH_LOYALTY_CARD)) {
      this.router.navigate(['/']);
    }
    this.searchForm.reset();
    this.searchTableForm.reset();
    await Promise.all([this.reloadPrograms(), this.loadCustomerCards(), this.loadTotalElements()]);
  }

  ngOnDestroy() {
    this.cards = [];
    this.searchForm.reset();
    this.searchTableForm.reset();
  }
  
  async onSubmit() {
    this.loadCustomerCards();
    this.loadTotalElements();
    this.findAllFilteredCustomerCards();
  }

  private reloadPrograms() {
    this.programService.findAll().toPromise().then(programs => {
      this.programs = programs as LoyaltyProgramsEssential[];
    })
    .catch(err => displayError(err));
  }

  private findAllFilteredCustomerCards() {
    this.dataLoaded = false;
    this.cardService.getAllFilteredCustomerCards(this.sortingPaging, this.searchForm.value).toPromise().then(cards => {
      this.allCards = cards;
      this.dataLoaded = true;
    })
    .catch(err => displayError(err));
  }
  
  loadCustomerCards() {
    this.dataLoaded = false;
    this.cardService.getPagableCustomerCards(this.sortingPaging, this.searchForm.value).pipe(first()).toPromise().then(resp => {
      this.cards = resp.content as ListCustomerCards[];
      this.cards.sort((a,b) => a.id - b.id);
      this.sortingPaging.fromRow = resp.fromRow;
      this.sortingPaging.toRow = resp.toRow;
      this.dataLoaded = true;
    })
    .catch(err => displayError(err));
  }

  loadTotalElements() {
    this.cardService.getTotalCustomerCards(this.sortingPaging, this.searchForm.value).toPromise().then(resp => {
      this.sortingPaging.totalElements = resp;
    })
    .catch(err => displayError(err));
  }

  pageChanged(page: number) {
    this.sortingPaging.pageNumber = page;
    this.loadCustomerCards();
    this.loadTotalElements(); 
  }

  onSubmitSearchFormSort(event: any) {
    this.cards.sort((a, b) => a.programName > b.programName ? 1 : -1);
  }

  clearFilters() {
    this.searchForm.reset();
    this.loadCustomerCards();
    this.loadTotalElements();
  }

  async generateSingleCardBarcode(id: number) {
    if (!id) return;

    this.loadPdf = true;
    await this.cardService.getSingleCardBarcode(id).toPromise()
    .then(response => generatePdf(id, response))
    .catch(err => {
      this.uiEvent.displayUIError('Възникна грешка при генериране');
      err.customMessage = 'Something bad happend when generate single barcode';
      displayError(err);
    })
    .finally(() => {
      this.loadPdf = false;
    });
  }

  async generateMultiCardBarcode() {
    this.allCards.sort((a, b) => a.cardBarcode.localeCompare(b.cardBarcode));
    const ids = this.allCards.map(c => c.id);
    if (ids.length > 100) {
      this.uiEvent.displayUIError('Максималната допустима бройка е 100');
      return;
    }
    
    this.loadPdf = true;
    await this.cardService.getMultiCardBarcode(ids).toPromise().then(response => generatePdf("multiple-barcodes", response))
    .catch(err => {
      err.customMessage = 'Something bad happend when generate multi barcode';
      displayError(err);
      this.uiEvent.displayUIError('Възникна грешка при генериране');
    })
    .finally(() => {
      this.loadPdf = false;
    });
    this.loadMultiCardBarcode = false;
  }

  get getForm() {
    return this.searchForm.controls;
  }
  
}