export interface ServicePaymentType {
    code: string;
    name: string;
}


export const SERVICE_PAYMENT_TYPES: ServicePaymentType[] = [
    { name: "с карта",    code: "C" },
    { name: "без карта",   code: "WC" },
    { name: "не се дължи",   code: "NP" }
];

export const findByCode = (code: string) => SERVICE_PAYMENT_TYPES.filter(type => type.code === code);