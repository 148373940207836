import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { Page } from '@interfaces/page';
import { LoyalCombo } from '@models/loyal-combo.model';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import { defaultIfEmpty, shareReplay } from 'rxjs/operators';
import { CustomerCardGeneratorRequest } from '../_models/customer-card-generator-request.model';
import { CustomerCards } from '../_models/customer-cards.model';
import { ListCustomerCards } from '../_models/list-customer-cards.model';
import { DataExcelGenerator } from '../_models/data-excel-generator.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerCardService {

  private customerCardURL = environment.apiBaseURL + "/customer-card";
  
  private saveURL                       = this.customerCardURL + '/save';
  private updateURL                     = this.customerCardURL + '/update';
  private allFilteredURL                = this.customerCardURL + '/all';
  private findCardByIdURL               = this.customerCardURL + '/{id}';
  private findLoyalComboByCard          = this.customerCardURL + '/combo';
  private generateCardURL               = this.customerCardURL + '/generate';
  private pageableCardURL               = this.customerCardURL + '/pageable'
  private pageableProgramsURL           = this.customerCardURL + '/partner/pageable'
  private totalCardsURL                 = this.customerCardURL + '/pageable/count';
  private totalProgramCardsURL          = this.customerCardURL + '/partner/pageable/count';
  private generateMultiCardBarcodeURL   = this.customerCardURL + '/multi-barcode';
  private generateSingleCardBarcodeURL  = this.customerCardURL + '/single-barcode/{id}';
  private generateCardsURL              = this.customerCardURL + '/generate-excel';
  private updateCardsURL                = this.customerCardURL + '/update-excel';
  private findAllPartnerBarcodesURL     = this.customerCardURL + '/partner-barcodes';

  constructor(private http: HttpClient) { }

  public getAllFilteredCustomerCards(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<Array<ListCustomerCards>> {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.allFilteredURL, {params: requestParams});
  }

  public findCardById(id: number): Observable<CustomerCards> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<CustomerCards>(this.findCardByIdURL.replace('{id}', `${id}`));
  }

  public saveCard(card: CustomerCards): Observable<any> {
    return this.http.post(this.saveURL, card);
  }

  public updateCard(card: CustomerCards): Observable<any> {
    return this.http.post(this.updateURL, card);
  }
  
  public generateCustomerCards(requestDTO: CustomerCardGeneratorRequest): Observable<any> {
    return this.http.post(this.generateCardURL, requestDTO);
  }

  public getPagableCustomerCards(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<Page<ListCustomerCards>> {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.pageableCardURL, {params: requestParams});
  }

  public getPagablePartnerCustomerCards(sortingPaging: SortingPagingData, searchObject: FormGroup) {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.pageableProgramsURL, {params: requestParams});
  }

  public getTotalCustomerCards(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<number> {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.totalCardsURL, {params: requestParams});
  }

  public getTotalPartnerCustomerCards(sortingPaging: SortingPagingData, searchObject: FormGroup): Observable<number> {
    let requestParams = this.createSearchHttpRequestParams(sortingPaging, searchObject);
    return this.http.get<any>(this.totalProgramCardsURL, {params: requestParams});
  }

  public getMultiCardBarcode(ids: number[]) {
    return this.http.post(this.generateMultiCardBarcodeURL, ids, { responseType: 'blob' });
  }

  public getSingleCardBarcode(id: number) {
    return this.http.get(this.generateSingleCardBarcodeURL.replace('{id}', `${id}`),{ responseType: 'blob'});
  }

  public getLoyalComboByCard(card: string) {
    return this.http.post<LoyalCombo>(this.findLoyalComboByCard, card.trim()).pipe(shareReplay());
  }

  public generateCardsExcel(request: DataExcelGenerator) {
    const serializedRequest = {
      ...request,
      data: this.mapToObject(request.data)
    };

    return this.http.post(this.generateCardsURL, JSON.stringify(serializedRequest), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public updateCardsExcel(request: DataExcelGenerator) {
    const serializedRequest = {
      ...request,
      data: this.mapToObject(request.data)
    };

    return this.http.post(this.updateCardsURL, JSON.stringify(serializedRequest), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public findAllPartnerBarcodes() {
    return this.http.get<any>(this.findAllPartnerBarcodesURL);
  }

  public pageableCountPartnerCards(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.getPagablePartnerCustomerCards(sortingPaging, searchForm),
      this.getTotalPartnerCustomerCards(sortingPaging, searchForm)
    ]);
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });

    return requestParams;
  }

  private mapToObject(map: Map<string, number>): {[key: string]: number} {
    const object: {[key: string]: number} = {};
    map.forEach((value, key) => {
      object[key] = value;
    });
    return object;
  }

}