import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { NomenclatureService } from '@services/nomenclature.service';
import { defaultIfEmpty } from 'rxjs/operators';
import { Promotion } from '../_models/promotion.model';
import { PromotionServices } from '../_models/promotion-services.model';
import { PromotionTechnicalPoint } from '../_models/promotion-technical-point.model';
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { PromotionRvsCategory } from '../_models/promotion-rvs-category.model';
import { Page } from '@interfaces/page';
import { ListPromotion } from '../_models/list-promotions.model';
import { FriendInvitationConfiguration } from '../_models/friend-invitation-configuration.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { SubjectVersion } from '@models/subject-version.model';
import { PromotionResponse } from '../_models/service-rvs.model';

const apiServerUrl                       = environment.apiBaseURL;
const promotionsUrl                      = apiServerUrl + '/promotion';

const findPromotionByIdURL                         = promotionsUrl + '/{id}';
const findPromotionPageableURL                     = promotionsUrl + '/pageable';
const countPromotionPageableURL                    = promotionsUrl + '/pageable-count';
const findAllValidPromocodesURL                    = promotionsUrl + '/promocodes';
const findPromotionServicesByPromotionIdURL        = promotionsUrl + '/{id}/services';
const findPromotionServicesAndRvsByPromotionIdURL  = promotionsUrl + '/services-rvs/{id}';
const findPromotionRvsCategoriesByPromotionIdURL   = promotionsUrl + '/{id}/categories';
const findTechnicalPointsByPromotionIdURL          = promotionsUrl + '/{id}/technical-points';
const findFriendInvitationsConfigByPromotionIdURL  = promotionsUrl + '/{id}/friend-invitations-configuration';
const findTimeConstraintByTechnicalPointIdURL      = promotionsUrl + '/technical-points-time-constraints';
const findTechnicalPointsByServiceAndCategoryIdURL = promotionsUrl + '/by-service-category-id';
const findSubjectByPromotionId                     = promotionsUrl + '/subject-by-promo/{id}';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private http: HttpClient, private nomenclatureService: NomenclatureService) { }

  public findPromotionById(id: number): Observable<Promotion> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<Promotion>(findPromotionByIdURL.replace('{id}', `${id}`));
  }

  public findSubjectByPromotionId(id: number): Observable<SubjectVersion> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<SubjectVersion>(findSubjectByPromotionId.replace('{id}', `${id}`));
  }

  private returnEmptyObservable() {
    return EMPTY.pipe(
      defaultIfEmpty()
    );
  }

  public getPromotionsNomenclature(id: number) {
    return forkJoin([
     this.nomenclatureService.getRvsCategoryServicesByServiceId(id),
     this.nomenclatureService.findAllValidServices()
    ]);
  }

  public findServicesByPromotionId(id: number): Observable<PromotionServices[]> {
    if (id == null) {
      return EMPTY.pipe(defaultIfEmpty());
    }
    
    return this.http.get<PromotionServices[]>(findPromotionServicesByPromotionIdURL.replace('{id}', `${id}`));
  }

  public findServicesAndRvsByPromotionId(id: number): Observable<PromotionResponse> {
    if (id == null) {
      return EMPTY.pipe(defaultIfEmpty());    
    }
    
    const url = findPromotionServicesAndRvsByPromotionIdURL.replace('{id}', `${id}`);
    return this.http.get<PromotionResponse>(url);
  }

  public findServicesByPromotionIdToPromise(id: number) {
    return this.findServicesByPromotionId(id).toPromise()
  }

  public findPromotionRvsCategoriesByPromotionId(id: number | undefined): Observable<PromotionRvsCategory[]> {
    return this.http.get<PromotionRvsCategory[]>(findPromotionRvsCategoriesByPromotionIdURL.replace('{id}', `${id}`));
  }

  public findTechnicalPointByPromotionId(id: number): Observable<PromotionTechnicalPoint[]> {
    if (id == null) {
      return EMPTY.pipe(defaultIfEmpty());
    }

    return this.http.get<PromotionTechnicalPoint[]>(findTechnicalPointsByPromotionIdURL.replace('{id}', `${id}`));
  }

  public findFriendInvitationsConfigByPromotionId(id: number): Observable<FriendInvitationConfiguration[]> {
    return this.http.get<FriendInvitationConfiguration[]>(findFriendInvitationsConfigByPromotionIdURL.replace('{id}', `${id}`));
  }

  public findTimeConstraintsByTechnicalPointId(id: number): Observable<any> {
    let requestParams = new HttpParams();
    requestParams = requestParams.set("id", id.toString());
    return this.http.get<any>(findTimeConstraintByTechnicalPointIdURL, {params: requestParams});
  }

  public findAllValidPromocodes(): Observable<Promotion[]> {
    return this.http.get<Promotion[]>(findAllValidPromocodesURL);
  }

  public findTechnicalPointsByServiceAndCategoryId(serviceCategoryIds: Array<number>): Observable<TechPointService[]> {
    let requestParams = new HttpParams();
    requestParams = requestParams.set("serviceCategoryIds", serviceCategoryIds.toString());

    return this.http.get<any>(findTechnicalPointsByServiceAndCategoryIdURL, {params: requestParams});
  }

  public savePromotion(promotion: Promotion, deactivate: boolean) {
    let requestParams = new HttpParams();
    requestParams = requestParams.set("deactivate", deactivate.toString());

    return this.http.post(promotionsUrl, promotion, {params: requestParams});
  }

  public initializePromotionServices(id: number) {
    return Promise.all([
      this.findServicesByPromotionId(id).toPromise(),
      this.nomenclatureService.getRvsCategoriesForPromoServices().toPromise(),
      this.nomenclatureService.findAllValidServicesForPromo().toPromise()
    ]);
  }

  public initializePromotionTechPoints(id: number) {
    return Promise.all([
      this.findTechnicalPointByPromotionId(id).toPromise()
    ]);
  }

  public initializeFriendConfig(id: number) {
    return Promise.all([
      this.findFriendInvitationsConfigByPromotionId(id).toPromise()
    ]);
  }

  public pageable(sortingPaging: SortingPagingData, searchForm: any, promotionTypeId: number) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm, promotionTypeId),
      this.countAllByFilter(sortingPaging, searchForm, promotionTypeId)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: any, promotionTypeId: number): Observable<Page<ListPromotion>> {
    return this.http.get<Page<ListPromotion>>(findPromotionPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm, promotionTypeId) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: any, promotionTypeId: number): Observable<number> {
    return this.http.get<number>(countPromotionPageableURL, { params : this.constructPageableHttpParams(sortingPaging, searchForm, promotionTypeId) });
  }

  private constructPageableHttpParams(sortingPaging: SortingPagingData, searchForm: any, promotionTypeId: number) {
    let params = new HttpParams().set('pageSize', sortingPaging.pageSize.toString())
                                 .set('page', sortingPaging.pageNumber.toString());
                                 
     if (sortingPaging.isSortingValid()) {
      params = params
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    if (searchForm.city?.code != null) {
      params = params.set('cityCode', searchForm.city?.code);
    }

    if (searchForm.status?.id != null) {
      params = params.set('statusId', searchForm.status?.id);
    }

    if (searchForm.name != null) {
      params = params.set('name', searchForm.name);
    }

    if (searchForm.technicalPoint?.mobileAppId != null) {
      params = params.set('technicalPointId', searchForm.technicalPoint.mobileAppId!);
    }

    if (searchForm.service != null) {
      params = params.set('serviceId', searchForm.service.id);
    }

    if (promotionTypeId != null) {
      params = params.set('promotionTypeId', promotionTypeId.toString());
    }

    return params;
  }

}
