<div class="container mainbox">
    <div>
        <h1 class="form-group title-position" translate>service.service</h1>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()" *ngIf="service$ | async as data; else loading">
            <div class="card">
                <div class="card-body">
                    <!-- name and service type -->
                    <div class="form-group form-row">
                        <!-- name -->
                        <div class="col">
                            <label translate>service.name</label>
                            <input type="text" class="form-control" formControlName="name"
                                [ngClass]="{'is-invalid': showValidations && form.name.errors }"
                                placeholder="{{'placeholder.enter' | translate}}" />
                            <small class="text-danger" *ngIf="form.name?.errors?.required && showValidations" translate>
                                errors.required
                            </small>
                            <small class="text-danger" *ngIf="form.name?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:128} }}
                            </small>
                        </div>

                        <!-- service type -->
                        <div class="col">
                            <label translate>service.serviceType</label>
                            <select class="custom-select input-custom-size" formControlName="serviceType"
                                [compareWith]="compareById"
                                [ngClass]="{ 'is-invalid': showValidations && form.serviceType.errors }">
                                <option *ngFor="let serviceType of serviceTypes" [ngValue]="serviceType">
                                    {{serviceType.description}}
                            </select>
                            <small class="text-danger" *ngIf="form.serviceType?.errors?.required && showValidations"
                                translate>
                                errors.required
                            </small>
                        </div>

                    </div>

                    <!-- order index and valid -->
                    <div class="form-group form-row">
                        <!-- order index -->
                        <div class="col">
                            <label translate>service.orderIndex</label>
                            <input type="text" class="form-control" formControlName="orderIndex"/>
                            <small class="text-danger" *ngIf="form.orderIndex?.errors?.required && showValidations"
                             translate>
                                errors.required
                            </small>
                            <small class="text-danger" *ngIf="form.orderIndex?.errors?.pattern && showValidations"
                             translate>
                                errors.onlyDigits
                            </small>
                            <small class="text-danger"
                            *ngIf="form.orderIndex?.errors?.maxlength && showValidations">
                            {{'errors.inputTooLong' | translate : {max:8} }}
                        </small>
                        </div>

                        <!-- valid -->
                        <div class="col">
                            <label translate>service.isValid</label>
                            <select class="custom-select input-custom-size" formControlName="isValid">
                                <option *ngFor="let option of options" [value]="option.code">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- rvs categories -->
                    <div class="form-group form-row">
                        <!-- rvs categories -->
                        <div class="col">
                            <mat-form-field class="mat-form-field-small">
                                <mat-label translate>service.rvsCategories</mat-label>
                                <mat-select multiple formControlName="selectedRvsCategories"
                                    [compareWith]="compareById">
                                    <mat-option (onSelectionChange)="change($event)"
                                        *ngFor="let rvsCategory of rvsCategories" [value]="rvsCategory">
                                        {{rvsCategory.code}}
                                    </mat-option>
                                </mat-select>
                                <small class="text-danger"
                                        *ngIf="form.selectedRvsCategories?.errors?.required && showValidations"
                                        translate>
                                        errors.required
                                    </small>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <!-- description -->
                    <div class="form-group form-row">
                        <!-- description -->
                        <div class="col">
                            <label translate>service.description</label>
                            <input type="text" class="form-control" formControlName="description"
                                [ngClass]="{'is-invalid': showValidations && form.description.errors }"
                                placeholder="{{'placeholder.enter' | translate}}" />
                            <small class="text-danger" *ngIf="form.description?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:512} }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="text-right">
                <button class="btn btn-primary w-20" type="submit"  *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_SERVICE)" translate>button.save</button>
            </div>
        </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <i class="fa fa-refresh" (click)="reload()"></i>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>