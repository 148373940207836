<div *ngIf="isInitialized; else purchaseHistoryLoading">
  <div class="card-body">
  <div *ngFor="let order of orders let i = index" class="card-body">
    <div class="card">
      <div class="d-flex">
        <div class="container pt-2 px-3">
          <div class="row">
            <div class="col-md-5 col-lg-4 d-flex flex-column">
              <b translate>clientProfile.purchaseHistoryOrderNumber</b>
              <label>{{order.orderNumber}}</label>
            </div>
            <div class="col-md-5 col-lg-4 d-flex flex-column">
              <b translate>clientProfile.purchasesHistoryTotalPrice</b>
              <label>{{order?.totalAmount}}</label>
            </div>
            <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.purchasesHistoryDate</b>
                <label>{{order?.orderDate}}</label>
              </div>
              <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.depositCount</b>
                <label>{{order?.depositCount}}</label>
              </div>
              <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.depositCountPayed</b>
                <label>{{order?.depositCountPayed}}</label>
              </div>
              <div class="col-md-6 col-lg-4 d-flex flex-column">
                  <b for="cardValidТо" translate>clientProfile.purchasesHistoryPlace</b>
                  <label>{{order.shippingAddress}}</label>
                </div>
                <div class="col-md-6 col-lg-4 d-flex flex-column">
                  <b for="cardValidТо" translate>clientProfile.purchasesHistoryStatus</b>
                  <label>{{order.status?.name}}</label>
                </div>
                <div class="col-md-6 col-lg-4 d-flex flex-column">
                  <b for="cardValidТо" translate>clientProfile.purchasesHistoryReceipt</b>
                  <i *ngIf="order.status?.code == PAID_STATUS_CODE" class="fa fa-file-pdf-o fa-lg mt-1" (click)="printPDF(order.id)" aria-hidden="true"></i>
                  <label *ngIf="order.status?.code != PAID_STATUS_CODE" translate>clientProfile.notAvailable</label>
                </div>
              </div>
        </div>
      </div>
      <hr class="mt-0 mb-1">
      <div class="card-body" role="tab" id="serviceSection">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <a data-toggle="collapse" href="#servicesSectionContent{{i}}" aria-expanded="true" 
                 aria-controls="serviceSection" style="font-size: 17px;"  translate>
               clientProfile.cardServices
            </a>
            </div>
          </div>
          <div id="servicesSectionContent{{i}}" class="collapse hidden" role="tabpanel" aria-labelledby="serviceSection">
          <div class="row">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th translate>clientProfile.purchasesHistoryServiceName</th>
                    <th translate>clientProfile.purchasesHistoryRegNum</th>
                    <th translate>clientProfile.purchasesHistoryPrice</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let orderElement of order.orderElements; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{orderElement?.service?.name}}</td>
                    <td>{{orderElement.customerRvs.regNumber}}</td>
                    <td>{{orderElement?.totalAmount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div> 
      </div>
    </div>
    <hr class="mt-0 mb-1">
    <div class="card-body" role="tab" id="paymentSection">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <a data-toggle="collapse" href="#paymentSectionContent{{i}}" aria-expanded="true" 
               aria-controls="paymentSection" style="font-size: 17px;" translate>
             clientProfile.payments
          </a>
          </div>
          </div>
          <div id="paymentSectionContent{{i}}" class="collapse hidden" role="tabpanel" aria-labelledby="paymentSection">
            <div class="row">
              <div class="col">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th translate>clientProfile.purchasesHistoryStatus</th>
                      <th translate>clientProfile.purchasesHistoryDate</th>
                      <th translate>clientProfile.depositNumber</th>
                      <th translate>clientProfile.transactionNumber</th>
                      <th translate>clientProfile.recipientKin</th>
                      <th translate>clientProfile.amountDds</th>
                      <th translate>clientProfile.taxes</th>
                      <th translate>clientProfile.totalAmount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let orderPayment of order.orderPayments; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{orderPayment?.statusName}}</td>
                      <td>{{orderPayment?.paymentDate}}</td>
                      <td>{{orderPayment?.depositNumber}}</td>
                      <td>{{orderPayment?.transactionNumber}}</td>
                      <td>{{orderPayment?.recipientKIN}}</td>
                      <td>{{orderPayment?.amount}}</td>
                      <td>{{orderPayment?.taxes}}</td>
                      <td>{{orderPayment?.totalAmount}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
</div> 
</div>
  <ng-template #purchaseHistoryLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else purchaseHistorySpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #purchaseHistorySpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>