import { TechPointService } from "@app/tech-points/_models/tech-point-service.model";
import { TechnicalPoint } from "@app/tech-points/_models/technical-point.model";
import { TechInspectionService } from "@models/tech-inspection-service.model";
import { ConsiceSubjectVersion } from "@models/consice-subject-version.model";
import { TechInspectionOrder } from "@models/tech-inspection-order.model";
import { TechInspectionTime } from "@models/tech-inspection-time.model";
import { CustomerDiscount } from "@models/customer-discount.model";
import { RejectReason } from "@models/reject-reason.model";
import { RvsCategory } from "@models/rvs-category.model";
import { VersionData } from "@models/version-data.model";
import { Status } from "@models/status.model";
import { CustomerCards } from "@app/card-config/_models/customer-cards.model";

export class TechnicalInspection {
    id?:                        number;
    regNumber:                  string;
    registrationCertNumber:     string;
    inspectionDate?:            string;
    phoneNumber?:               string;
    slot?:                      string;
    email?:                     string;
    name?:                      string;
    status?:                    Status;
    services?:                  string;
    mobileId?:                  number;
    fromErp?:                   boolean;
    incomingWay?:               string;
    createdOrder?:              boolean;
    issueInvoice?:              boolean;
    customerRvsId?:             number;
    contractRvsId?:             number;
    techPointWorkingHourId?:    number;
    rvsCategory?:               RvsCategory;
    versionData?:               VersionData;
    rejectReason?:              RejectReason;
    technicalPoint?:            TechnicalPoint;
    isAgreeWithNotification?:   boolean;
    isEmployee?:                boolean;
    customerDiscount?:          CustomerDiscount;
    customerCard?:              CustomerCards | null;
    invoiceSubjectVersion?:     ConsiceSubjectVersion;
    techPointServices?:         TechPointService[];
    techInspectionServices?:    TechInspectionService[];
    techInspectionTimes?:       TechInspectionTime[];
    techInspectionOrders?:      TechInspectionOrder[];
    techPointWorkingHourIds?:   number[];
    isDeferPayment?:            boolean;
    isBankPayment?:             boolean;
    skipSubscribtion:           boolean = false;
    loyaltyCardId?:             number;
    promotionId?:               number | null;
    wantsMarketingMessages:     boolean;
}