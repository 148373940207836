<div class="container mainbox">
    <div>
        <h1 class="form-group title-position" translate>serviceAddInfo.serviceAddInfo</h1>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()" *ngIf="serviceAddInfo$ | async as data; else loading">
            <div class="card">
                <div class="card-body">
                    <!-- name and service type -->
                    <div formGroupName="service">
                        <div class="form-group form-row">
                            <!-- name -->
                            <div class="col">
                                <label translate>serviceAddInfo.name</label>
                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{'is-invalid': showValidations && service.get('name')?.errors }" />
                                <small class="text-danger"
                                    *ngIf="service.get('name')?.errors?.required && showValidations" translate>
                                    errors.required
                                </small>
                                <small class="text-danger"
                                    *ngIf="service.get('name')?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:128} }}
                                </small>
                            </div>

                            <!-- service type -->
                            <div class="col">
                                <label translate>serviceAddInfo.serviceType</label>
                                <select class="custom-select input-custom-size" formControlName="serviceType"
                                    [compareWith]="compareById"
                                    [ngClass]="{'is-invalid': showValidations && service.get('serviceType')?.errors }">
                                    <option *ngFor="let serviceType of serviceTypes" [ngValue]="serviceType">
                                        {{serviceType.description}}
                                </select>
                                <small class="text-danger"
                                    *ngIf="service.get('serviceType')?.errors?.required && showValidations" translate>
                                    errors.required
                                </small>
                            </div>
                        </div>
                    </div>

                    <!-- deferred payment and deposits count -->
                    <div class="form-group form-row">
                        <!-- allow deferred payment -->
                        <div class="col">
                            <label translate>serviceAddInfo.allowDeferredPayment</label>
                            <select class="custom-select input-custom-size" formControlName="allowDeferredPayment"
                                (change)='onPaymentTypeSelect($event)'>
                                <option *ngFor="let option of options" [value]="option.code">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>

                        <!-- deposits count -->
                        <div class="col">
                            <label translate>serviceAddInfo.depositsCount</label>
                            <input type="text" class="form-control" formControlName="depositsCount" (change)="calculateDepositPrice()"
                                [ngClass]="{'is-invalid': showValidations && form.depositsCount?.errors }" />
                            <small class="text-danger" *ngIf="form.depositsCount?.errors?.pattern && showValidations"
                                translate>
                                errors.onlyDigits
                            </small>
                            <small class="text-danger" *ngIf="form.depositsCount?.errors?.required && showValidations"
                                translate>
                                errors.required
                            </small>
                            <small class="text-danger" *ngIf="form.depositsCount?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:8} }}
                            </small>
                        </div>
                    </div>

                    <!-- prices -->
                    <div formGroupName="priceAttribute">
                        <div class="form-group form-row">
                            <!-- price without dds -->
                            <div class="col">
                                <label translate>Цена без ДДС</label>
                                <input type="text" class="form-control" formControlName="amount" (change)="calculatePriceAmountDds()"/>
                                <small class="text-danger"
                                    *ngIf="priceAttribute.get('amount')?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:8} }}
                                </small>
                                <small class="text-danger"
                                    *ngIf="priceAttribute.get('amount')?.errors?.pattern && showValidations" translate>
                                    errors.onlyPositiveNumbers
                                </small>
                                <small class="text-danger"
                                    *ngIf="priceAttribute.get('amount')?.errors?.required && showValidations" translate>
                                    errors.required
                                </small>
                            </div>

                            <!-- price with dds -->
                            <div class="col">
                                <label translate>ДДС</label>
                                <select class="form-control w-100" formControlName="ddsValue" (change)="calculatePriceAmountDds()">
                                    <option *ngFor="let vatGroup of VAT_GROUPS" [ngValue]="vatGroup"> {{vatGroup}}
                                </select>
                                <small class="text-danger"
                                    *ngIf="priceAttribute.get('ddsValue')?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                            </div>

                            <!-- final price -->
                            <div class="col">
                                <label translate>Крайна цена</label>
                                <input type="text" class="form-control" formControlName="amountDds" (change)="calculateDepositPrice()" readonly/>
                                <small class="text-danger"
                                    *ngIf="priceAttribute.get('amountDds')?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:8} }}
                                </small>
                                <small class="text-danger"
                                    *ngIf="priceAttribute.get('amountDds')?.errors?.required && showValidations"
                                    translate>
                                    errors.required
                                </small>
                            </div>
                        </div>
                    </div>
                    
                    <!-- deposit single price and deposit description -->
                    <div class="form-group form-row">
                        <!-- deposit single price -->
                        <div class="col">
                            <label translate>serviceAddInfo.depositSinglePrice</label>
                            <input type="text" class="form-control" formControlName="depositSinglePrice" readonly>
                            <!-- <small class="text-danger"
                                *ngIf="form.depositSinglePrice?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:8} }}
                            </small>
                            <small class="text-danger"
                                *ngIf="form.depositSinglePrice?.errors?.pattern && showValidations" translate>
                                errors.onlyPositiveNumbers
                            </small>
                            <small class="text-danger"
                                *ngIf="form.depositSinglePrice?.errors?.required && showValidations" translate>
                                errors.required
                            </small> -->
                        </div>

                        <!-- deposit description  -->
                        <div class="col">
                            <label translate>serviceAddInfo.depositDescription</label>
                            <input type="text" class="form-control" formControlName="depositDescription" />
                            <small class="text-danger"
                                *ngIf="form.depositDescription?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:256} }}
                            </small>
                        </div>
                    </div>

                    <!-- <div class="form-group form-row">
                        <div class="col">
                            <label translate>serviceAddInfo.validFrom</label>
                            <input type="date" class="form-control" formControlName="validFrom"
                                [ngClass]="{'is-invalid': showValidations && form.validFrom?.errors }">
                            <small class="text-danger" *ngIf="form.validFrom?.errors?.required && showValidations"
                                translate>
                                errors.required
                            </small>
                            <small class="text-danger" *ngIf="addEditForm?.errors && showValidations" translate>
                                errors.validFromToDates
                            </small>
                        </div>
                        <div class="col">
                            <label translate>serviceAddInfo.validTo</label>
                            <input type="date" class="form-control" formControlName="validTo"
                                [ngClass]="{'is-invalid': showValidations && form.validTo?.errors }">
                            <small class="text-danger" *ngIf="form.validTo?.errors?.required && showValidations"
                                translate>
                                errors.required
                            </small>
                        </div>
                    </div> -->

                    <!-- rvs categories and valid -->
                    <div formGroupName="service">
                        <div class="form-group form-row">
                            <!-- rvs categories -->
                            <div class="col">
                                <mat-form-field class="mat-form-field">
                                    <mat-label translate>serviceAddInfo.rvsCategories</mat-label>
                                    <mat-select multiple formControlName="selectedRvsCategories"
                                        [compareWith]="compareById">
                                        <mat-option (onSelectionChange)="change($event)"
                                            *ngFor="let rvsCategory of rvsCategories" [value]="rvsCategory">
                                            {{rvsCategory.code}}
                                        </mat-option>
                                    </mat-select>
                                    <small class="text-danger"
                                        *ngIf="service.get('selectedRvsCategories')?.errors?.required && showValidations"
                                        translate>
                                        errors.required
                                    </small>
                                </mat-form-field>
                            </div>

                            <!-- valid -->
                            <div class="col">
                                <label translate>serviceAddInfo.isValid</label>
                                <select class="custom-select input-custom-size" formControlName="isValid">
                                    <option *ngFor="let option of options" [value]="option.code">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <!-- services -->
                    <div class="form-group form-row">
                        <!-- services -->
                        <div class="col">
                            <label translate>serviceAddInfo.services</label>
                            <div class="col text-right">
                                <button class="btn btn-outline-success ml-2" type="button" (click)="addRow()"
                                *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE)" translate>button.add</button>
                            </div>
                            <table class="table table-bordered table-striped w-100">
                                <thead>
                                    <tr>
                                        <th translate>serviceAddInfo.service</th>
                                        <th translate>serviceAddInfo.usageCount</th>
                                        <th translate class="text-center">button.delete</th>
                                    </tr>
                                </thead>

                                <tbody formArrayName="serviceAddInfoItemList">
                                    <small class="text-danger" *ngIf="rows?.errors && showValidations" translate>
                                        errors.atLeastOneService
                                    </small>
                                    <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">
                                        <tr *ngIf="row.get('isValid')?.value">
                                            <td>
                                                <mat-select class="custom-select" formControlName="childService"
                                                    [compareWith]="compareById">
                                                    <mat-option *ngFor="let service of services" [value]="service">
                                                        {{service.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div *ngIf="rows.controls[i]?.get('childService')?.value != null && !rows.controls[i]?.get('childService')?.value?.versionData.isActual">
                                                    <i class="fa fa-exclamation-triangle mr-2 text-warning" style="font-size: 25pt;" 
                                                    title="{{'contract.cardOldVersion' | translate}}" aria-hidden="true"></i>
                                                    <i class="fa fa-refresh ml-2" style="font-size: 25pt;cursor: pointer;" title="{{'refresh' | translate}}" 
                                                    (click)="updateChildService(i)" aria-hidden="true"></i>
                                                </div>
                                                <small class="text-danger"
                                                    *ngIf="row.get('childService')?.errors?.required && showValidations"
                                                    translate>
                                                    errors.required
                                                </small>
                                            </td>
                                            <td>
                                                <input type="number" class="form-control" formControlName="usageCount">

                                                <small class="text-danger"
                                                    *ngIf="row.get('usageCount')?.errors?.required && showValidations"
                                                    translate>
                                                    errors.requiredOnlyDigits
                                                </small>
                                                <small class="text-danger"
                                                    *ngIf="row.get('usageCount')?.errors?.max && showValidations"
                                                    translate>
                                                    errors.tooLong
                                                </small>
                                                <small class="text-danger"
                                                    *ngIf="row.get('usageCount')?.errors?.min && showValidations"
                                                    translate>
                                                    errors.onlyPositiveNumbers
                                                </small>
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-danger btn-sm rounded-0" type="button"
                                                    (click)="deleteRow(i)" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE)"><em class="fa fa-trash"></em></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div class="text-right">
                <button class="btn btn-primary w-20" type="submit" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE)" translate>button.save</button>
            </div>
        </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <em class="fa fa-refresh" (click)="reload()"></em>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>