import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ListUser } from '@app/security/_models/list-user.model';
import { Role } from '@app/security/_models/role.model';
import { SecurityService } from '@app/security/_services/security.service';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { YES_NO } from '@app/_enums/yes-no-enum';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SearchSubjectVersion } from '@models/search-subject-version.model';
import { SubjectVersion } from '@models/subject-version.model';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SubjectVersionService } from '@services/subject-version.service';
import { forkJoin, EMPTY, Subscription, Observable, of, OperatorFunction } from 'rxjs';
import { tap, catchError, repeatWhen, concatMap, debounceTime, map, switchMap, filter } from 'rxjs/operators';
import { displayError } from '@app/_utils/error-util';
import { MatDialog } from '@angular/material/dialog';
import { UIEventCustom } from '@app/_utils/ui-event-util';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent extends BaseSortableSearchComponent<ListUser> implements OnInit, OnDestroy {
  
  // Core
  roles:          Role[];
  employeeInput:  String | null = null;
  techPointInput: String | null = null;
  
  // Constants
  readonly options = YES_NO;

  // Payload
  selectedUserId: number = 0;
  @ViewChild('confirmDelete') confirmDelete: TemplateRef<any>;
  dialogRef: any

  // Form
  searchForm = this.formBuilder.group({
    role:         null,
    fullName:     null,
    enabled:      null,
    employer:     null,
    techPoint:    null,
    email:        null
  });

  // Observables
  private baseSub: Subscription = new Subscription();

  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.securityService.pageableUsers(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
  );

  loadSearchForm$ = forkJoin([
    this.securityService.getAllRoles()
  ]).pipe(
    tap(([roles]) => {
      this.roles = roles;
      
      this.searchSubject.next();
    }),
    catchError(err => {
      displayError(err);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  );

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    filter((input) => input.length > 3),
    switchMap(input =>
      this.subjectVersionService.filterSubjectVersions(input).pipe(
        map(data => data.slice(0,10)),// Getting the first 10 elements
        catchError(() => of([]))
      )
    )
  );

  techPointSearch: OperatorFunction<string, TechnicalPoint[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    switchMap(input =>
      this.technicalPointService.filterTechnicalPoints(input).pipe(
        map(data => data.slice(0,10)),// Getting the first 10 elements
        catchError(() => of([]))
      )
    )
  );

  constructor(
    private formBuilder:            FormBuilder,
    private securityService:        SecurityService,
    private translateService:       TranslateService,
    private subjectVersionService:  SubjectVersionService,
    private technicalPointService:  TechnicalPointService,
    private dialog:                 MatDialog,
    private uiEvent:                UIEventCustom
  ) {
    super();
  }
  
  ngOnInit() {
    let searchSub = this.search$.subscribe();
    this.baseSub.add(searchSub);
  }
  
  ngOnDestroy() {
    this.baseSub?.unsubscribe();  
  }

  onClear() {
    super.clearSearch();
    this.employeeInput = null;
    this.techPointInput = null;
  }

  onEmployerSelect(selectedItem: NgbTypeaheadSelectItemEvent<SubjectVersion>) {
    this.employeeInput = selectedItem.item.fullName;
    this.searchForm.controls.employer?.patchValue(selectedItem.item?.mobileAppId);
  }

  onTechPointSelect(selectedItem: NgbTypeaheadSelectItemEvent<TechnicalPoint>) {
    this.techPointInput = selectedItem.item.name;
    this.searchForm.controls.techPoint?.patchValue(selectedItem.item?.mobileAppId);
  }

  subjectVersionFormatter(element: SubjectVersion) {
    return element.fullName;
  }

  techPointFormatter(element: TechnicalPoint) {
    return element.name;
  }

  onSubmitDeleteModal(userId: number) {
    this.selectedUserId = userId;
    this.dialogRef  = this.dialog.open(this.confirmDelete, {
      width: '800px',
      height: '200px'
    });
  }

  onSubmitDelete() {
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result && this.selectedUserId) {
        this.securityService.findUserAndDeactiveById(this.selectedUserId).subscribe(() => {
          this.uiEvent.displayUISuccess("Успешна операция");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
        return;
      }
    });
  }

}