import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ListRole } from "@app/security/_models/list-role.model";
import { Permission } from "@app/security/_models/permission.model";
import { Role } from "@app/security/_models/role.model";
import { SecurityService } from "@app/security/_services/security.service";
import { YES_NO } from "@app/_enums/yes-no-enum";
import { BaseSearchComponent } from "@components/_base/base-search/base-search.component";
import { BaseSortableSearchComponent } from "@components/_base/base-search/base-sortable-search.component";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, forkJoin, Subscription } from "rxjs";
import { catchError, concatMap, repeatWhen, tap } from "rxjs/operators";
import { displayError } from "@app/_utils/error-util";

@Component({
  selector: 'list-roles',
  templateUrl: './list-roles.component.html'
})

export class ListRolesComponent extends BaseSortableSearchComponent<ListRole> implements OnInit, OnDestroy {
  // Units
  rolesList:          Role[];
  permissions:        Permission[];

  // Constants
  readonly options = YES_NO;
  private readonly SERVICE_TYPE_CODE: string = 'P';

  // Form
  searchForm = this.formBuilder.group({
    role:         null,
    permission:   null,
    enabled:      null,
  });

  // sObservables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.securityService.pageableRoles(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
  );

  loadSearchForm$ = forkJoin([
    this.securityService.getAllRoles(),
    this.securityService.getPermissions()
  ]).pipe(
    tap(([roles, permissions]) => {
      this.rolesList = roles;
      this.permissions = permissions;
      
      this.searchSubject.next();
    }),
    catchError(err => {
      displayError(err);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  )

  constructor(
    private formBuilder:      FormBuilder,
    private securityService:  SecurityService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      role:       null,
      permission: null,
      enabled:    null,
    });
    this.searchSubject.next();
  }

  formatPermissions(permissions: Permission[]) {
    if (permissions.length == 1) {
      return this.translateService.instant('roles.onePermSelected');
    }

    return this.translateService.instant('roles.multiplePermsSelected', { permsNum : permissions.length });
  }
}