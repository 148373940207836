<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 class="my-2" *ngIf="module == DISCOUNT_MODULE_CODE" translate>menu.marketing.promotions.search</h3>
            <h3 class="my-2" *ngIf="module == PROMOCODE_MODULE_CODE" translate>menu.marketing.promoCodes.search</h3>
            <h3 class="my-2" *ngIf="module == INVITE_FRIEND_MODULE_CODE" translate>menu.marketing.inviteFriends.search</h3>
        </span>
    </div>

    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- promotion name -->
                <ng-container *ngIf="module != INVITE_FRIEND_MODULE_CODE">
                    <div class="input-group-prepend">
                        <span class="input-group-text" translate>promotion.name</span>
                    </div>
                    <input type="text" class="form-control d-inline" (keyup.enter)="search()" formControlName="name" id="nameInput">
                </ng-container>
                
                <!-- status  -->
                <div class="input-group-prepend" [ngClass]="{'ml-2': module != INVITE_FRIEND_MODULE_CODE}">
                    <span class="input-group-text" translate>promotion.status</span>
                </div>
                <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="status">
                    <option value="" [ngValue]="null" translate>placeholder.select</option>
                    <option *ngFor="let status of statuses" [ngValue]="status"> {{status.name}}
                </select>
                
                <!-- service -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate> promotion.service</span>
                </div>
                <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="service">
                    <option value="" [ngValue]="null" translate>placeholder.select</option>
                    <option *ngFor="let service of services" [ngValue]="service"> {{service.name}}
                </select>
                
                <!-- technical point -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>promotion.technicalPoint</span>
                </div>
                <input id="addressTypeahead" type="text" class="form-control" (keyup.enter)="search()"
                    formControlName="technicalPoint" [ngbTypeahead]="technicalPointsSearch"
                    [inputFormatter]="technicalPointFormatter" 
                    [resultTemplate]="rt"/>
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                </ng-template>

                <ng-container *ngIf="module == INVITE_FRIEND_MODULE_CODE">
                    <div class="input-group-prepend ml-2">
                        <span class="input-group-text" translate>promotion.city</span>
                    </div>
                    <select class="custom-select input-custom-size" [compareWith]="compareByCode" formControlName="city" style="max-width: 280pt;">
                        <option value="" [ngValue]="null" translate>placeholder.select</option>
                        <option *ngFor="let city of cities" [ngValue]="city"> {{city.name}}
                    </select>
    
                    <!-- actions -->
                    <div class="input-group-prepend ml-2">
                        <div style="display: flex;">
                            <button class="btn btn-outline-success" style="border-radius: 0;" type="button" (click)="search()" translate>button.search</button>
                            <button class="btn btn-outline-danger ml-2" style="border-radius: 0;" type="button" (click)="clearSearch()" translate>button.clear</button>
                            <button class="btn btn-outline-success ml-2" style="border-radius: 0;" routerLink="/add-edit-promotion"
                                [queryParams]="{module: module}" type="button" *ngIf="canAddEdit" translate>button.add</button>
                            <i class="fa fa-file-excel-o fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" style="margin-left: 10px; margin-top: 2px" (click)="exportExcel()"></i>
                            <i style="margin-left: 5px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" class="fa fa-file-pdf-o fa-2x" (click)="exportPDF()"></i>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="input-group mb-2" *ngIf="module != INVITE_FRIEND_MODULE_CODE">
                <!-- city -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>promotion.city</span>
                </div>
                <select class="custom-select input-custom-size" [compareWith]="compareByCode" formControlName="city" style="max-width: 280pt;">
                    <option value="" [ngValue]="null" translate>placeholder.select</option>
                    <option *ngFor="let city of cities" [ngValue]="city"> {{city.name}}
                </select>

                <!-- actions -->
                <div class="input-group-prepend ml-2">
                    <div style="display: flex;">
                        <button class="btn btn-outline-success" style="border-radius: 0;" type="button" (click)="search()" translate>button.search</button>
                        <button class="btn btn-outline-danger ml-2" style="border-radius: 0;" type="button" (click)="clearSearch()" translate>button.clear</button>
                        <button class="btn btn-outline-success ml-2" style="border-radius: 0;" routerLink="/add-edit-promotion"
                            [queryParams]="{module: module}" type="button" *ngIf="canAddEdit" translate>button.add</button>
                        <i class="fa fa-file-excel-o fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" style="margin-left: 10px; margin-top: 2px" (click)="exportExcel()"></i>
                        <i style="margin-left: 5px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" class="fa fa-file-pdf-o fa-2x" (click)="exportPDF()"></i>
                    </div>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class=" d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th *ngIf="module!='invite-friend'" sortBy="name" (sort)="onSort($event)" translate>promotion.name</th>
                        <th sortBy="status" (sort)="onSort($event)" translate>promotion.status</th>
                        <th sortBy="validFrom" (sort)="onSort($event)" translate>promotion.validFrom</th>
                        <th sortBy="validTo" (sort)="onSort($event)" translate>promotion.validTo</th>
                        <th translate>promotion.services</th>
                        <th translate>promotion.technicalPoints</th>
                        <th *ngIf="module=='promocode'" sortBy="maxUsageNumber" (sort)="onSort($event)" translate>promotion.maxCount</th>
                        <th *ngIf="module=='invite-friend'" sortBy="maxUsageNumber" (sort)="onSort($event)" translate>promotion.shipmentsNumber</th>
                        <th *ngIf="module=='promocode'" sortBy="orderTotalAmount" (sort)="onSort($event)" translate>promotion.totalAmount</th>
                        <th translate>promotion.actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let promotion of content">
                        <td *ngIf="module!='invite-friend'">{{promotion.name}}</td>
                        <td>{{promotion.status?.name}}</td>
                        <td>{{promotion.validFrom}}</td>
                        <td>{{promotion.validTo}}</td>
                        <td>{{promotion?.services}}</td>
                        <td>{{promotion.technicalPoints}}</td>
                        <td *ngIf="module != DISCOUNT_MODULE_CODE">{{promotion.maxUsageNumber}}</td>
                        <td *ngIf="module == PROMOCODE_MODULE_CODE">{{promotion.orderTotalAmount}}</td>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success" routerLink="/add-edit-promotion" *ngIf="canView"
                            data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}" 
                            [queryParams]="{id: promotion.id, module: module}"><i class="fa fa-pencil fa-lg"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
        <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
            <div class="alert alert-danger">
                {{errorMessage}}
            </div>
            <div class="d-flex justify-content-center">
                <i class="fa fa-refresh" (click)="reload()"></i>
            </div>
        </div>
        <ng-template #dataSpinner>
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
</ng-template>