import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { CustomerCards } from '@app/card-config/_models/customer-cards.model';
import { CardProgramServiceService } from '@app/card-config/_services/card-program-service.service';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { CustomerCardGeneratorRequest } from '@app/card-config/_models/customer-card-generator-request.model';
import { ListCustomerCards } from '@app/card-config/_models/list-customer-cards.model';
import { catchError, first, repeatWhen, tap } from 'rxjs/operators';
import { LoyaltyProgramsEssential } from '@app/card-config/_models/loyalty-programs-essential.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Router } from '@angular/router';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { Subscription, forkJoin, EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { DataExcelGenerator } from '@app/card-config/_models/data-excel-generator.model';
import { ExportService } from '@services/export.service';
import { convertDateToString } from '@app/_utils/date-util';

@Component({
  selector: 'app-generator-update-partner-card',
  templateUrl: './generator-update-partner-card.component.html',
  styleUrls: ['./generator-update-partner-card.component.css']
})
export class GeneratorUpdatePartnerCardComponent extends BaseSortableSearchComponent<CustomerCards> implements OnInit, OnDestroy {

  // Units
  cardRequest: CustomerCardGeneratorRequest;
  cardList:    ListCustomerCards[] = [];
  allCards:    ListCustomerCards[] = [];
  successAndFailCards: ListCustomerCards[] = [];
  programs:    LoyaltyProgramsEssential[] = [];

  // Booleans
  isSubmited            = false; 
  isSearchSubmited      = false;
  loadPrograms          = false;
  loadData              = false;
  loadMultiCardBarcode  = false;
  loadExcel             = false;  

  // Form
  excelForm = this.formBuilder.group({
    file: [],
    programMobileAppId: [null, [Validators.required]],
  });

  searchForm = this.formBuilder.group({
    cardNumber:   [null],
    cardBarcode:  [null],
    programName:  [null]
  });

  // File payload
  @ViewChild('fileInput') fileInput!: ElementRef;
  selectedFile:                       File | null = null;
  dataRows:                           Array<Array<any>> = [];
  cardsDataExcel                      = new Map<string, number>();
  
  // Observables
  programsSubscription: Subscription;
  loadPrograms$ = forkJoin([
    this.programService.findAll()
  ]).pipe(
    tap(([programs]) => {
      this.programs = programs as LoyaltyProgramsEssential[];
      this.searchSubject.next();
    }), catchError(error => {
      displayError(error);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  );

  constructor (
    private perms:                PermissionsService,
    protected router:             Router,
    private formBuilder:          FormBuilder, 
    private programService:       CardProgramServiceService,
    private translateService:     TranslateService,
    private exportService:        ExportService,
    private cardService:          CustomerCardService,
    private uiEvent:              UIEventCustom)  { 
      super();
  }

  ngOnInit() {
    if (!this.perms.hasAccess(this.perms.CAN_ADD_EDIT_LOYALTY_CARD)) {
      this.router.navigate(['/']);
    }

    this.programs = [];
    this.programsSubscription = this.loadPrograms$.subscribe();
    this.cardList = [];
    this.allCards = [];
    this.excelForm.reset();
    this.searchForm.reset();
  }

  ngOnDestroy() {
    this.excelForm.reset();
    this.searchForm.reset();
    this.cardList = [];
    this.programsSubscription.unsubscribe();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (!this.selectedFile) return;

    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 }) as any;
      this.dataRows = data.slice(1); // first row consist headings
      this.dataRows.forEach((r: any) => {
        this.cardsDataExcel.set(r[0].toString(), r[1]);
      });

      this.filterIncorrectCards();
    };

    reader.readAsBinaryString(target.files[0]);
  }

  private filterIncorrectCards() {
    let listIncorrect: string[] = [];
    this.cardsDataExcel.forEach((value, key) => {
      if (key.length >= 30) {
        listIncorrect.push(key);
        this.cardsDataExcel.delete(key);
      }
    });
    
    if (listIncorrect.length > 0) {
      const message = 'Карти с повече от 30 символа бяха премахнати. Списък: ' + listIncorrect.join(', ');
      this.uiEvent.displayUIError(message, 10000);
    }
  }

  onSubmitExcel() {
    if (!this.excelForm.valid) {
      this.uiEvent.displayUIError();
      return;
    }

    const request: DataExcelGenerator = {
      data: this.cardsDataExcel,
      programMobileAppId: this.excelForm.get('programMobileAppId')?.value,
    };

    this.cardService.updateCardsExcel(request).toPromise().then(async raw => {
      this.calculatePaging(raw, this.cardsDataExcel.size);
      this.uiEvent.displayUISuccess();
      await new Promise(f => setTimeout(f, 500));
      this.exportExcel();
      this.selectedFile = null;
      this.excelForm.reset();
    }).catch(err => {
      this.cardsDataExcel.clear();
      this.selectedFile = null;
      this.uiEvent.displayUIError();
      err.customMessage = 'Error occurs while creating cards from excel. Cause: ' + err.message;
      this.exportExcel();
      displayError(err);
    });
  }

  private calculatePaging(raw: any, collectionSize: number) {
    let data = raw as ListCustomerCards[];
    this.successAndFailCards = raw;
    data = data.filter(d => d.actionStatus !== 'fail');
    this.allCards = data;
    data = data.slice(0, collectionSize);

    this.sortingPaging.pageNumber = 1;
    this.sortingPaging.fromRow = 1;
    this.sortingPaging.toRow = Math.round(data.length / 10);
    this.sortingPaging.totalElements = data.length;
    this.cardList = data.slice(0, 10);
    this.cardList.sort((a,b) => a.id - b.id);

    this.isSubmited = false;
    this.loadData = false;
    this.dataLoaded = true;
  }

  onSubmitSearchFormSort(event: any) {
    this.cardList.sort((a, b) => a.programName > b.programName ? 1 : -1);
  }

  sizeChanged(size: number) {
    this.sortingPaging.pageNumber = this.sortingPaging.pageNumber;
    this.sortingPaging.pageSize = size;
    this.sortingPaging.fromRow = 1;
    this.sortingPaging.toRow = size;
    this.sortingPaging.totalElements = this.allCards.length;
    this.cardList = this.allCards.slice(0, size);
  }

  pageChanged(page: number) {
    this.sortingPaging.pageNumber = page;
    const pageSize = this.sortingPaging.pageSize || 10;
    this.sortingPaging.fromRow = (page - 1) * pageSize + 1;
    this.sortingPaging.toRow = page * pageSize;
    this.sortingPaging.totalElements = this.allCards.length;

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    this.cardList = this.allCards.slice(startIndex, endIndex);
  }

  loadTotalElements() {
    this.sortingPaging.totalElements = this.cardList.length;
  }

  private async exportExcel() {
    this.loadExcel = true;
    const title = "Резултат от импорт на карти - " + convertDateToString(new Date());
    
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        title, null, true);
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];
    this.successAndFailCards.forEach(c => {
      newContent?.push([c.cardNumber, c.actionStatus, c.actionMessage])
    });

    return newContent;
  }

  private getHeadings() : string[][] {
    return [["Номер на карта", "Резултат", "Съобщение"]];
  }

  private getFilterBody(): any[] {
    return [[]];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 3;
    let result: any[] = [];

    return [[{content: 'Резултати от импорт на карти', colSpan: colSpan, styles: {halign: 'center'}}], result];
  }

  async dowloadPatternExcel() {
    const placeholder: any[] = [];
    const placeholderTwo: any[][] = [];
    this.exportService.exportAsExcelFile(placeholder, this.getHeadingsPattern(), this.getFilterBody(), placeholderTwo, "Шаблон за генериране на карти", null, true); 
  }

  private getHeadingsPattern() {
    return [["Карти", "Брой използвания"]];
  }

  get getForm() {
    return this.excelForm.controls;
  }

}
