<div class="form-group form-row" [formGroup]="contractServicesForm">
    <div class="col text-left">
        <button class="btn btn-outline-success mb-2" type="button" (click)="addAll()" *ngIf="canAddEdit" translate>button.fillAll</button>
    </div>
    <div class="col text-right">
        <button class="btn btn-outline-success ml-2 mb-2" type="button" (click)="addRow()" *ngIf="canAddEdit" translate>button.add</button>
    </div>
    <table class="table table-bordered table-striped w-100">
        <thead>
            <tr>
                <th scope="col" style="width:40vw" translate>ktp</th>
                <th scope="col" style="width:35vw" translate>contract.category</th>
                <th scope="col" style="width:35vw" translate>contract.service</th>
                <th scope="col" style="width:10vw" translate>contract.withoutDds</th>
                <th scope="col" style="width:10vw" translate>contract.finalAmount</th>
                <th scope="col" style="width:5vw" translate class="text-center" *ngIf="canAddEdit">button.delete</th>
            </tr>
        </thead>

        <tbody formArrayName="rows">
            <small class="text-danger" *ngIf="rows?.errors && showValidations" translate>
                errors.atLeastOneService
            </small>
            <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">
                
                <tr *ngIf="row.get('isValid')?.value" >
                    <td>
                        <mat-select class="custom-select" formControlName="technicalPoint" [compareWith]="compareById"
                            (ngModelChange)='onTechPointSelect($event, i)'
                            [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('technicalPoint')?.errors }">
                            <mat-option *ngFor="let technicalPoint of selectedTechnicalPoints" 
                                [value]="technicalPoint">
                                {{technicalPoint.name}}
                                </mat-option>
                        </mat-select>
                        <small class="text-danger" *ngIf="rows.controls[i]?.get('technicalPoint')?.errors?.required && showValidations" translate>
                            errors.required
                        </small> 
                    </td>
                    <td>
                        <mat-select class="custom-select" formControlName="category" [compareWith]="compareById"
                        (ngModelChange)="onCategorySelect($event, i, rows.controls[i]?.get('technicalPoint')?.value)"
                        [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('category')?.errors }">
                            <mat-option *ngFor="let category of rows.controls[i]?.get('categories')?.value"
                                [value]="category">
                                {{category.description}}
                                </mat-option>
                        </mat-select>
                        <small class="text-danger" *ngIf="rows.controls[i]?.get('category')?.errors?.required && showValidations" translate>
                            errors.required
                        </small>  
                    </td>
                    <td>
                        <mat-select class="custom-select" formControlName="technicalPointService" [compareWith]="compareById"
                        (ngModelChange)="onServiceSelect($event, i)"
                        [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('technicalPointService')?.errors }">
                            <mat-option *ngFor="let service of rows.controls[i]?.get('techPointServices')?.value"
                                [value]="service">
                                {{service.serviceName}}
                                </mat-option>
                        </mat-select>
                        <small class="text-danger" *ngIf="rows.controls[i]?.get('technicalPointService')?.errors?.required && showValidations" translate>
                            errors.required
                        </small>  
                    </td>
                    <td>
                        <input class="form-control input-custom-size" formControlName="withoutDds">
                    </td>
                    <td>
                        <input class="form-control input-custom-size" formControlName="finalAmount">
                    </td>
                    <td class="text-center" *ngIf="canAddEdit">
                        <button class="btn btn-danger btn-sm" type="button" data-toggle="tooltip" data-placement="bottom" title="{{'button.delete' | translate}}" (click)="deleteRow(i)" *ngIf="canAddEdit"><em class="fa fa-trash"></em></button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>