import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Status } from '@models/status.model';
import { CONTRACT_KINDS } from '@app/_enums/contract-kind-enum';
import { AbstractControlOptions, FormBuilder, FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { catchError, concatMap, repeatWhen, tap } from 'rxjs/operators';
import { EMPTY, forkJoin } from 'rxjs';
import { NomenclatureService } from '@services/nomenclature.service';
import { ContractSubjectsComponent } from './contract-subjects/contract-subjects.component';
import { CustomerDiscountComponent } from './customer-discount/customer-discount.component';
import { ContractTechnicalPointsComponent } from './contract-technical-points/contract-technical-points.component';
import { ContractServicesComponent } from './contract-services/contract-services.component';
import { ContractSlotsComponent } from './contract-slots/contract-slots.component';
import { ContractRvsComponent } from './contract-rvs/contract-rvs.component';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { StatusCode } from '@app/_enums/status-code';
import { CustomerDiscount } from '@models/customer-discount.model';
import { PartnerContractServicesComponent } from './partner-contract-services/partner-contract-services.component';
import { ContractService } from '@app/contracts/_services/contract.service';
import { ContractTechnicalPoint } from '@app/contracts/_models/contract-technical-point.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { DateValidators } from '@app/_validators/dates-validator';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';

@Component({
  selector: 'app-add-edit-client-contract',
  templateUrl: './add-edit-client-contract.component.html',
  styleUrls: ['./add-edit-client-contract.component.css']
})
export class ClientContractComponent extends BaseParentComponent implements OnInit, OnDestroy {
  // Units
  module:                     string;
  contractId:                 number;
  ownerSubjectVersionId:      number;
  contractSubjectVersionId:   number;
  customerDiscountId:         number;
  statuses:                   Status[];
  defaultStatus:              Status | null;
  previousStatus:             Status | null;
  selectedTechnicalPoints:    TechnicalPoint[];
  selectedCustomerDiscount:   CustomerDiscount;

  // Constants
  readonly INACTIVE_STATUS_CODE   = 'INACTIVE';
  readonly ACTIVE_STATUS_CODE     = 'ACTIVE';
  contractKinds                   = CONTRACT_KINDS;
  readonly allSectionsList        = ['clientData', 'clientDataComponent', 'customerDiscount', 'technicalPoints',
   'contractServices', 'partnerContractServices', 'contractSlots', 'contractRvs'];

  // Booleans
  showFirstSection  = true;
  showAllSections   = false;

  // Child components
  @ViewChild('clientData') clientDataComponent:                           ContractSubjectsComponent;
  @ViewChild('customerDiscount') customerDiscountComponent:               CustomerDiscountComponent;
  @ViewChild('technicalPoints') technicalPointsComponent:                 ContractTechnicalPointsComponent;
  @ViewChild('contractServices') contractServicesComponent:               ContractServicesComponent;
  @ViewChild('partnerContractServices') partnerContractServicesComponent: PartnerContractServicesComponent;
  @ViewChild('contractSlots') contractSlotsComponent:                     ContractSlotsComponent;
  @ViewChild('contractRvs') contractRvsComponent:                         ContractRvsComponent;

  // Forms
  addEditForm = this.formBuilder.group({
    id:                   [''],
    docNumber:            ['', [Validators.required, Validators.maxLength(32)]],
    connectedTo:          [''],
    validFrom:            ['', Validators.required],
    validTo:              ['', Validators.required],
    contractKind:         ['', [Validators.required, Validators.maxLength(16)]],
    status:               [{ id: '' }, [Validators.required]],
    contractType:         [this.isClientContract() ? 'C' : 'P'],
    invoicePeriod:        null,
    versionData:          null,
  }, { validator: [DateValidators.groupValidator('validFrom', 'validTo')] } as AbstractControlOptions);


  // Observables
  contract$ = this.route.queryParams.pipe(
    concatMap(params => {
      return forkJoin([
        this.contractService.findContractById(params['id']),
        this.nomenclatureService.getStatusesByType(StatusCode.Contract)
      ]).pipe(
        tap(() => this.contractId = params['id']),
        tap(([contract, statuses]) => {
          this.statuses = statuses;
          this.defaultStatus = this.statuses.find(status => status.code === this.INACTIVE_STATUS_CODE) || null;
          this.addEditForm.patchValue(contract);
          this.addEditForm.patchValue({
            validFrom:          !!contract ? formatDate(contract.validFrom, 'yyyy-MM-dd', 'en') : '',
            validTo:            !!contract ? formatDate(contract.validTo, 'yyyy-MM-dd', 'en') : '',
            status:             !!contract ? contract.status : this.defaultStatus,
            contractType:       this.isClientContract() ? 'C' : 'P',
            invoicePeriod:      !!contract ? formatDate(contract.invoicePeriod, 'yyyy-MM-dd', 'en') : '',
          });
          this.ownerSubjectVersionId = contract?.ownerSubjectVersion?.id;
          this.contractSubjectVersionId = contract?.contractSubjectVersion?.id;
          this.customerDiscountId = contract?.customerDiscount?.id;
          this.previousStatus = this.statuses.find(status => status.id === contract?.status.id) || null;
        }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router,
    private uiEvent: UIEventCustom,
    private perms: PermissionsService,
    private translateService: TranslateService,
    private nomenclatureService: NomenclatureService,
    private contractService: ContractService) {
    super(router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe(params => {
      this.module = params['module'];
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  isClientContract() {
    return this.module == 'client';
  }

  async initializeSection(code: string) {
    switch (code) {
      case 'clientData':
        await this.clientDataComponent.getSubjectVersions();
        break;
      case 'customerDiscount':
        if (this.isClientContract()) {
          await Promise.all([this.customerDiscountComponent.getDiscount()]);
          if (!!this.customerDiscountId && this.customerDiscountComponent.customerDiscountForm.controls['customerDiscount'].value == null) {
            this.customerDiscountComponent.customerDiscountForm.patchValue({ customerDiscount: { id: this.customerDiscountId } });
          }
        }
        break;
      case 'technicalPoints':
        await this.technicalPointsComponent.getTechnicalPoints();
        break;
      case 'contractServices':
        if (this.isClientContract()) {
          await Promise.all([this.customerDiscountComponent.getDiscount(),
          this.technicalPointsComponent.getTechnicalPoints(),
          this.contractServicesComponent.getContractServices()]
          )
        }
        break;
      case 'partnerContractServices':
        if (!this.isClientContract()) {
          await Promise.all([this.technicalPointsComponent.getTechnicalPoints(),
          this.partnerContractServicesComponent.getContractServices()]);
        }
        break;
      case 'contractSlots':
        if (this.isClientContract()) {
          await Promise.all([this.technicalPointsComponent.getTechnicalPoints(),
          this.contractSlotsComponent.getContractSlots()]);
        }
        break;
      case 'contractRvs':
        if (this.isClientContract()) {
          await Promise.all([this.contractRvsComponent.getContractRvs()]);
        }
        break;
      default:
    }
  }

  async onSubmit() {
    await this.initSections();

    if (this.addEditForm.get('status')?.value?.code === this.INACTIVE_STATUS_CODE) {//|| this.previousStatus?.code === this.ACTIVE_STATUS_CODE
      if (!this.partualValidation()) {
        this.uiEvent.displayUIError();
        this.showValidationsSubject.next(true);
        this.showFullValidationSubject.next(false);
        return;
      }
    } else {
      if (!this.fullValidation()) {
        // this.allSectionsList.forEach(x => this.initializeSection(x));
        if (!this.showFirstSection) this.showFirstSection = true;
        if (!this.showAllSections) this.showAllSections = true;

        this.uiEvent.displayUIError();
        this.showValidationsSubject.next(true);
        this.showFullValidationSubject.next(true);
        return;
      }
    }

    let contractTechnicalPoints = this.technicalPointsComponent.technicalPointForm.controls['contractTechnicalPoints'].value;
    contractTechnicalPoints = this.setTechPointServices(contractTechnicalPoints);
    if (this.isClientContract()) {
      contractTechnicalPoints = this.setTechPointLines(contractTechnicalPoints);
    }
    this.addChildControlsToParent(contractTechnicalPoints);
    
    this.contractService.saveContract(this.addEditForm.value).subscribe({
      next: (id) => {
        this.uiEvent.displayUISuccess();
        this.router.navigate(['/list-client-contracts'], { relativeTo: this.route, queryParams: { module: this.module } });
      },
      error: err => {
        displayError(err);
      } 
    });
  }

  async initSections() {
    if (this.addEditForm.get('status')?.value?.code !== this.INACTIVE_STATUS_CODE || this.contractId != null) {// && this.previousStatus?.code !== this.ACTIVE_STATUS_CODE
      for await (let x of this.allSectionsList) {
        await this.initializeSection(x);
      }
    }
  }

  private partualValidation() {
    return this.addEditForm.valid;
  }

  private fullValidation() {
    if (this.isClientContract()) {
      return this.addEditForm.valid  && this.customerDiscountComponent.customerDiscountForm.valid
        && this.clientDataComponent.subjectsForm.valid
        && this.technicalPointsComponent.technicalPointForm.valid
        && this.contractServicesComponent.contractServicesForm.valid
        && this.contractSlotsComponent.contractSlotsForm.valid
        && this.contractRvsComponent.contractRvsForm.valid;
    }
    return this.addEditForm.valid && this.clientDataComponent.subjectsForm.valid
      && this.technicalPointsComponent.technicalPointForm.valid
      && this.partnerContractServicesComponent.contractServicesForm.valid
  }

  setTechPointServices(contractTechnicalPoints: ContractTechnicalPoint[]): ContractTechnicalPoint[] {
    let contractTechnicalPointServices;
    if (this.isClientContract()) {
      contractTechnicalPointServices = this.contractServicesComponent.contractServicesForm.controls['rows'].value;
    } else {
      contractTechnicalPointServices = this.partnerContractServicesComponent.contractServicesForm.controls['rows'].value;
    }

    if (contractTechnicalPoints != null) {
      contractTechnicalPointServices.forEach((x: any) => {
        contractTechnicalPoints.forEach(y => {
          if (x?.technicalPoint?.id == y?.technicalPoint?.id) {
            if (y.contractTechnicalPointServices == null) {
              y.contractTechnicalPointServices = new Array();
            }
            y.contractTechnicalPointServices?.push(x);
          }
        });
      });
    }

    return contractTechnicalPoints;
  }

  setTechPointLines(contractTechnicalPoints: ContractTechnicalPoint[]): ContractTechnicalPoint[] {
    let contractTechnicalPointLines = this.contractSlotsComponent.contractSlotsForm.controls['rows'].value;

    contractTechnicalPointLines.forEach((x: any) => {
      contractTechnicalPoints.forEach(y => {
        if (x.technicalPoint == y.technicalPoint.id) {
          if (y.contractTechnicalPointLines == null) {
            y.contractTechnicalPointLines = new Array();
          }
          y.contractTechnicalPointLines?.push(x);
        }
      });
    });
    
    return contractTechnicalPoints;
  }

  addChildControlsToParent(contractTechnicalPoints: ContractTechnicalPoint[]) {
    this.addEditForm.addControl('ownerSubjectVersion', new FormControl(this.clientDataComponent.subjectsForm.controls['ownerSubjectVersion'].value));
    this.addEditForm.addControl('contractSubjectVersion', new FormControl(this.clientDataComponent.subjectsForm.controls['contractSubjectVersion'].value));
    this.addEditForm.addControl('contractTechnicalPoints', new FormControl(contractTechnicalPoints));
    
    if (this.isClientContract()) {
      this.addEditForm.addControl('customerDiscount', new FormControl(this.customerDiscountComponent.customerDiscountForm.controls['customerDiscount'].value));
      if (!!this.customerDiscountId && this.addEditForm.controls['customerDiscount'].value == null) {
        this.addEditForm.patchValue({ customerDiscount: { id: this.customerDiscountId } });
      }
      this.addEditForm.addControl('contractRvsList', new FormControl(this.contractRvsComponent.contractRvsForm.controls['rows'].value));
    }
  }

  // checkForValidation(component: BaseChildComponent) {
  //     return component.isValid();
  // }

  getSelectedTechPoints(event: TechnicalPoint[]) {
    this.selectedTechnicalPoints = event;
  }

  getSelectedCustomerDiscount(discount: CustomerDiscount) {
    this.selectedCustomerDiscount = discount;
  }

  get form() { 
    return this.addEditForm.controls; 
  }

  get canAddEdit() {
    switch(this.module) {
      case 'client': 
        return this.perms.hasAccess(this.perms.CAN_VIEW_PARTNER_CONTRACT);
      case 'pertner': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PARTNER_CONTRACT);
      default: 
        return false;
    }
  }
}
