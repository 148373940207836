<div class="h-100 d-flex flex-column" *ngIf="isLogin">
    <div class="container">
        <div class="row justify-content-center align-items-center" style="height:100vh">
            <div class="col-4">
                <div class="card">
                    <div class="card-header" translate>login</div>
                    <div class="card-body">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="d-flex flex-column h-100">
                            <div class="form-group">
                                <label for="email" translate>auth.username</label>
                                <input type="text" formControlName="username" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && form.username.errors }" />
                                <div class="invalid-feedback" *ngIf="form.username.errors?.required" translate>errors.required</div>
                            </div>
                            <div class="form-group">
                                <label for="password" translate>auth.password</label>
                                <input type="password" formControlName="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && form.password.errors }" />
                                <div class="invalid-feedback" *ngIf="form.password.errors?.required" translate>errors.required</div>
                            </div>
            
                            <div class="d-flex justify-content-between mt-auto pt-3">
                                <a class="pointer text-danger my-auto mr-5" (click)="forgottenPasswd()" style="cursor: pointer;" translate>
                                    auth.forgottenPass
                                </a>
                                <button type="submit" [disabled]="loading" class="btn btn-sm btn-primary" translate>
                                    auth.login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-reset-password *ngIf="!isLogin"></app-reset-password>