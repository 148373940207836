<div class="card" *ngIf="loadPromocodes$ | async as data; else loading">
    <div class="text-right" style="margin-right:20px; margin-top: 10px">
      <button class="btn btn-primary" (click)= "addFriendInvitationConfig()" *ngIf="canAddEdit" translate>button.add</button>
    </div>
    <!-- ****************************************************** 
    ************** Configurator Form *************
    ****************************************************** -->
    <form [formGroup]="invitationsForm">
      <div formArrayName="invitations">
        <h5 class="text-danger" style="margin-left: 20px;" *ngIf="invitationsForm.controls.invitations?.errors?.required && showValidations" translate>promotion.noRowAdded</h5>
        <!-- Table Data -->
        <table class="table table-bordered table-striped" style="margin-top: 10px; margin-left: 20px; width:96%">
          <thead>
            <tr>
              <th class="w-15" style="width: 33%;" translate>promotion.orderNumber</th>
              <th class="w-15" style="width: 33%;" translate>promotion.senderValue</th>
              <th class="w-15" style="width: 33%;" translate>promotion.friendValue</th>
            </tr>
          </thead>
          <tbody *ngFor="let invitation of invitationsForms.controls; let i=index" [formGroupName]="i">
            <tr>
              <!-- order number -->
              <td style="width: 33%;">
                <input class="form-control" type="number" formControlName="usageNumber" 
                  [ngClass]="{ 'is-invalid': showValidations && invitation.get('usageNumber')?.errors }">
                <div class="invalid-feedback" *ngIf="invitation?.get('usageNumber')?.errors?.required && showValidations" translate>errors.required</div>  
                <div class="invalid-feedback" *ngIf="invitation?.get('usageNumber')?.errors?.min && showValidations" translate>errors.onlyPositiveNumbers</div>
                <div class="invalid-feedback" *ngIf="invitation?.get('usageNumber')?.errors?.max && showValidations" translate>errors.tooLong</div>
              </td>
              <!-- sender value -->
              <td style="width: 33%;">
                <select class="custom-select input-custom-size" formControlName="customerOwnerPromotion" [compareWith]="compareById"
                        [ngClass]="{ 'is-invalid': showValidations && invitation.get('customerOwnerPromotion')?.errors }">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let promocode of promocodes" [ngValue]="promocode"> {{promocode.name}}
                </select>
                <div style="margin-left: 20px;" class="invalid-feedback" *ngIf="invitation?.get('customerOwnerPromotion')?.errors?.required && showValidations" translate>errors.required</div>
              </td>
              <!-- friend value -->
              <td style="width: 33%;">
                <select class="custom-select input-custom-size" formControlName="customerFriendPromotion" [compareWith]="compareById"
                      [ngClass]="{ 'is-invalid': showValidations && invitation.get('customerFriendPromotion')?.errors }">
                  <option value="" translate>placeholder.select</option>
                  <option *ngFor="let promocode of promocodes" [ngValue]="promocode"> {{promocode.name}}
                </select>
                <div style="margin-left: 20px;" class="invalid-feedback" *ngIf="invitation?.get('customerFriendPromotion')?.errors?.required && showValidations" translate>errors.required</div>
              </td> 
            </tr>
          </tbody>
        </table>
      </div>
    </form>
</div>

<ng-template #loading>
  <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
      <div class="alert alert-danger">
          {{errorMessage}}
      </div>
      <div class="d-flex justify-content-center">
          <i class="fa fa-refresh" (click)="reload()"></i>
      </div>
  </div>
  <ng-template #spinner>
      <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </div>
  </ng-template>
</ng-template>