import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { EMPTY, Subscription } from 'rxjs';
import { ListIncomeByStickers } from '../_models/list-income-by-stickers.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { IncomeByStickersService } from '../_services/income-by-stickers.service';
import { catchError, concatMap, first, tap } from 'rxjs/operators';
import { prepareDateForDb, convertDateToString, convertObjDateToString, prepareDateForForm } from '@app/_utils/date-util';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';

@Component({
  selector: 'app-income-by-stickers',
  templateUrl: './income-by-stickers.component.html',
  styleUrls: ['./income-by-stickers.component.css']
})
export class IncomeByStickersComponent extends BaseSortableSearchComponent<ListIncomeByStickers> implements OnInit, OnDestroy {
  // Units
  technicalPoints:                  TechnicalPoint[];
  dateObj                           = new Date();
  userTechnicalPointMobileAppIds:   number[];
  sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);

  // Booleans
  hasTechnicalPoint: boolean;
  loadPdfOrExcel     = false;
  
  // Form
  searchForm = this.formBuilder.group({
    techPointMobileAppId:            [''],
    dateFrom:                        prepareDateForForm(this.dateObj),
    dateTo:                          prepareDateForForm(this.dateObj)
  });

  // Observables
  exportSubscription: Subscription;

  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.incomeByStickersService.pageable(this.sortingPaging, this.searchForm.value,
                                                     prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                     prepareDateForDb(this.searchForm.get('dateTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
  );

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private exportService: ExportService,
    private technicalPointService: TechnicalPointService,
    private incomeByStickersService: IncomeByStickersService
  ) {
    super();
    this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  async ngOnInit() {
    await this.loadTechnicalPoints();
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  private async loadTechnicalPoints() {
    try {
      if (this.userTechnicalPointMobileAppIds.length === 0) {
        const result = await this.technicalPointService.findTechnicalPoints().toPromise();
        this.technicalPoints = result;
        this.hasTechnicalPoint = false;
      } else {
        const result = await this.technicalPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise();
        this.hasTechnicalPoint = true;
        this.technicalPoints = result;
        this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
      }
    } catch (err) {
      displayErrorFromUnknown(err);
    }
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      techPointMobileAppId:                 [''],
      dateFrom:                             prepareDateForForm(this.dateObj),
      dateTo:                               prepareDateForForm(this.dateObj)
    });

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
                                         this.translateService.instant("exportData.filesNames.incomeByStickersResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.incomeByStickersResult"), convertDateToString(new Date()));
    } catch(err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;
 
    let result = await this.incomeByStickersService.findAllByFilter(sortingPagingCopy, this.searchForm.value,
                                                                     prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                     prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                    ).pipe(first()).toPromise();
 
    result.content.forEach(object => {
       newContent?.push([
          object.techPointShortName,
          object.firstServiceCount,
          object.firstTotalAmount,
          object.secondServiceCount,
          object.secondTotalAmount])
    })

    return newContent;
  }
 
  private getHeadings() : string[][] {
    return [[this.translateService.instant("references.point"), 
             this.translateService.instant("references.countTechInsp"), 
             this.translateService.instant("references.amountWithoutVat"), 
             this.translateService.instant("references.countFirstTechInsp"), 
             this.translateService.instant("references.amountWithoutVat")]]
  }
   
  private getFilterBody(): any[] {
   let tpName: string = "";
   this.technicalPoints.forEach(tp => {
     if (tp.mobileAppId == this.searchForm.get('techPointMobileAppId')?.value) {
       tpName = tp.shortName;
     }
   });

   let result: string[]= [
     tpName,
     convertObjDateToString(this.searchForm.get('dateFrom')?.value), 
     convertObjDateToString(this.searchForm.get('dateTo')?.value),
   ];

   return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('references.point'),
      this.translateService.instant('references.fromDate'),
      this.translateService.instant('references.toDate')
    ];
    return [
      [{content: this.translateService.instant('references.incomeByStickers'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

}