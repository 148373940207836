<div class="container border my-2 py-2 px-3">
    <form [formGroup]="customerSubscriptionForm" (ngSubmit)="onSubmitCustomerSubscriptionForm()">
        <div class="row justify-content-center">
            <h2 translate>menu.subscriptionCard</h2>
        </div>
        <br>
        <!-- ****************************************************** 
        ************** Creational Data *************
        ****************************************************** -->
        <section>
            <div class="row">
                <!-- valid from -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.validFrom</label>
                    <div class="input-group mt-1"> 
                        <input class="form-control d-inline" [minDate]="minDate" style="max-width: 380pt;" aria-describedby="validFrom"  formControlName="validFrom" ngbDatepicker #d1="ngbDatepicker" (dateSelect)='onValidFromChange()' readonly>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>

                <!-- valid to -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.validTo</label>
                    <div class="input-group mt-1"> 
                        <input class="form-control d-inline" style="max-width: 380pt;" aria-describedby="validTo"  formControlName="validTo" ngbDatepicker #d2="ngbDatepicker" readonly>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button" disabled></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- registration number -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.registrationNumber</label>
                    <input type="text" formControlName="regNumber" class="form-control input-custom-size" style="text-transform: uppercase;" 
                        (focusout)="normalizeRegNumber(custSubscrForm.regNumber.value)"
                        (input)="validateRegNumber(custSubscrForm.regNumber.value)"
                        [ngClass]="{ 'is-invalid': submittedCustomerSubscriptionForm && custSubscrForm.regNumber.errors || isValidRegNum }">
                    <div class="text-danger" *ngIf="isValidRegNum"><small translate>errors.onlyLatinSymbolsAndDigits</small></div>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.regNumber.errors?.required" translate>errors.required</div>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.regNumber.errors?.maxlength" translate>errors.tooLong</div>
                    <div class="text-danger" *ngIf="carLicensePlateForeignerIncorrect"><small translate>errors.regNumberForForeigner</small></div>
                    <div class="text-danger" *ngIf="carLicensePlateDiplomaticIncorrect"><small translate>errors.regNumberForDiplomatic</small></div>
                    <div class="text-danger" *ngIf="carLicensePlateElectricIncorrect"><small translate>errors.regNumberForElectric</small></div>
                </div>

                <!-- coupon number -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.couponNumber</label>
                    <input type="text" formControlName="regCertNumber" class="form-control input-custom-size"
                    (input)="validateRegCertNumber(custSubscrForm.regCertNumber.value)"
                    [ngClass]="{ 'is-invalid': submittedCustomerSubscriptionForm && custSubscrForm.regCertNumber.errors || isValidRegCertNum  }">
                    <div class="text-danger" *ngIf="isValidRegCertNum"><small translate>errors.onlyCapitalLatinLettersAndNumbers</small></div>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.regCertNumber.errors?.required" translate>errors.required</div>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.regCertNumber.errors?.maxlength" translate>errors.tooLong</div>
                </div>
            </div>

            <div class="row">
                <!-- category -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.category</label>
                    <select class="custom-select input-custom-size" formControlName="rvsCategory" [compareWith]="compareById" (change)='onRvsCategorySelect(custSubscrForm.rvsCategory.value)'
                            [ngClass]="{ 'is-invalid': submittedCustomerSubscriptionForm && custSubscrForm.rvsCategory.errors }">
                        <option *ngFor="let category of rvsCategories" [ngValue]="category">
                            {{category.description}}
                        </option>
                    </select>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.rvsCategory.errors?.required" translate>errors.required</div>
                </div>

                <!-- phone number -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.phoneNumber</label>
                    <input type="text" formControlName="phoneNumber" placeholder="+359888112233" class="form-control input-custom-size" 
                        [ngClass]="{ 'is-invalid': submittedCustomerSubscriptionForm && custSubscrForm.phoneNumber.errors }">
                    <div class="invalid-feedback" *ngIf="custSubscrForm.phoneNumber.errors?.maxlength" translate>errors.tooLong</div>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.phoneNumber?.errors?.required" translate>errors.required</div>
                    <div class="invalid-feedback" *ngIf="custSubscrForm.phoneNumber?.errors?.invalidPhoneNumber" translate>errors.phoneInvalid</div>
                </div>
            </div>

            <div class="row">
                <!-- card type -->
                <div class="form-group col-md-6">
                    <label translate>subscriptionCard.cardType</label>
                    <div class="form-check" *ngFor="let item of annualServices">
                        <input class="form-check-input radio-input-size" formControlName="techPointService" type="radio" [value]="item" 
                            [ngClass]="{ 'is-invalid': submittedCustomerSubscriptionForm && custSubscrForm.techPointService.errors }" />
                        <label class="form-check-label radio-label-size ml-2" translate> {{item.serviceName}}</label>
                    </div>
                </div>
            </div>
        </section>

        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="row">
            <table class="table table-bordered table-striped w-100">
                <thead>
                    <tr>
                        <th translate>subscriptionCard.service</th>
                        <th translate>subscriptionCard.type</th>
                        <th translate>subscriptionCard.quantity</th>
                        <th translate>subscriptionCard.measure</th>
                        <th translate>subscriptionCard.unitPrice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{custSubscrForm.techPointService.value.serviceName}}</td>
                        <td>{{custSubscrForm.techPointService.value.serviceType}}</td>
                        <td>1</td>
                        <td>бр.</td>
                        <td>{{custSubscrForm.techPointService.value.amountDds}}</td>
                    </tr>    
                </tbody>
            </table>
        </div>
    
        <div class="row">
            <div class="col-12">
                <button class="btn btn-primary w-20" style="float: right;" translate>button.save</button>
            </div>
        </div>
    </form>
</div>