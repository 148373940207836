<div class="container mainbox">
    <div>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()"
            *ngIf="accountingDocument$ | async as data; else loading">
            <h1 class="form-group title-position">{{formatType(form.documentType.value)}}</h1>
            <!-- ****************************************************** 
            ************** Invoice Data *************
            ****************************************************** -->
            <div id="accordion">
                <div class="container" id="non-print">
                    <!-- ****************************************************** 
                    ************** Core Invoice Data *************
                    ****************************************************** -->
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.docType</label>
                                            <select class="custom-select" formControlName="documentType" [compareWith]="compareByCode"
                                                [ngClass]="{ 'is-invalid': showValidations && form.documentType.errors }">
                                                <option *ngFor="let documentType of documentTypes" [value]="documentType.code" >
                                                    {{ documentType.name }}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="form.documentType?.errors?.required && showValidations"
                                                translate>
                                                errors.required
                                            </small>
                                        </div>
                                        <div class="col">
                                            <label translate>accountingDocument.accDocNum</label>
                                            <input type="text" class="form-control" formControlName="invoiceNumber" readonly>
                                        </div>
                                        <div class="col"
                                            *ngIf="form.documentType.value == 'C' || form.documentType.value == 'D'">
                                            <label translate>accountingDocument.toInvoiceNum</label>
                                            <input type="text" class="form-control" formControlName="invoice"
                                                [ngClass]="{ 'is-invalid': showValidations && form.invoice?.errors }"
                                                [ngbTypeahead]="invoiceSearch" [inputFormatter]="invoiceFormatter"
                                                [resultTemplate]="rtsv" />
                                            <ng-template #rtsv let-r="result" let-t="term">
                                                <ngb-highlight [result]="r.invoiceNumber" [term]="t"></ngb-highlight>
                                            </ng-template>
                                            <small class="text-danger"
                                                *ngIf="form.invoice?.errors?.required && showValidations"
                                                translate>
                                                errors.required
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="form.invoice?.errors?.invalidInvoice && showValidations"
                                                translate>
                                                errors.pleaseChooseFromDropdown
                                            </small>
                                        </div>
                                        <div class="col">
                                            <label for="invoiceDate" translate>accountingDocument.createdAt</label>
                                            <div class="input-group"> 
                                                <input class="form-control d-inline" aria-describedby="invoiceDate" 
                                                formControlName="invoiceDate" ngbDatepicker #d1="ngbDatepicker" readonly
                                                [ngClass]="{ 'is-invalid': showValidations && form.invoiceDate.errors}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button" disabled></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label translate>status</label>
                                            <select #status class="custom-select" formControlName="status"
                                                [compareWith]="compareById" (ngModelChange)="onChange($event)" (ngModel)="statuses">
                                                <option *ngFor="let status of statuses" [ngValue]="status">
                                                    {{ status.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ****************************************************** 
                    ************** Publisher And Recipient Data *************
                    ****************************************************** -->
                    <div class="row">
                        <!-- Publisher Data -->
                        <div class="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-body" formGroupName="publisher">
                                    <h5 class="card-title" translate>accountingDocument.publisher</h5>
                                    <small class="text-danger"
                                        *ngIf="form.publisher?.get('id')?.errors?.required && showValidations"
                                        translate>
                                        errors.requiredOnly
                                    </small>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.eik</label>
                                            <input type="text" class="form-control" formControlName="identNum" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.customerName</label>
                                        <input type="text" class="form-control" formControlName="fullName" readonly>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.supplierDirector</label>
                                        <input type="text" class="form-control" formControlName="manager" readonly>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.supplierVatNumber</label>
                                        <input type="text" class="form-control"
                                            formControlName="invoiceDdsRegistration" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Publisher Recipient -->
                        <div class="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-body" *ngIf="form.customerInvoiceDetails?.get('id')?.value == null" formGroupName="recepient">
                                    <h5 class="card-title" translate>accountingDocument.recepient</h5>
                                    <small class="text-danger" *ngIf="form.recepient?.get('id')?.errors?.required && showValidations" translate>
                                        errors.requiredOnly
                                    </small>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.eik</label>
                                            <input type="text" class="form-control" formControlName="identNum">

                                        </div>
                                        <button class="btn btn-primary mt-4" (click)="openModal('recepient')" [disabled]="accountingDocument?.status?.code == ANNULLED_STATUS_CODE"
                                            type="button" translate>contract.chooseSubject</button>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.customerName</label>
                                        <input type="text" class="form-control" formControlName="fullName">
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.customerDirector</label>
                                        <input type="text" class="form-control" formControlName="invoiceManager">
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.customerVatNumber</label>
                                        <input type="text" class="form-control"
                                            formControlName="invoiceDdsRegistration">
                                    </div>
                                </div>
                                <div class="card-body" *ngIf="form.customerInvoiceDetails?.get('id')?.value != null" formGroupName="customerInvoiceDetails">
                                    <h5 class="card-title" translate>accountingDocument.recepient</h5>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>col.eik</label>
                                            <input type="text" class="form-control" formControlName="identNumber" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>col.name</label>
                                        <input type="text" class="form-control" formControlName="fullName" readonly>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.customerDirector</label>
                                        <input type="text" class="form-control" formControlName="manager" readonly>
                                    </div>
                                    <div class="form-group form-row">
                                        <label translate>accountingDocument.customerVatNumber</label>
                                        <input type="text" class="form-control"
                                            formControlName="vatNumber" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ****************************************************** 
                    ************** Invoice Data *************
                    ****************************************************** -->
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label for="dateOfChargeableEvent" translate>accountingDocument.dateOfChargeableEvent</label>
                                            <div class="input-group"> 
                                                <input class="form-control d-inline" aria-describedby="dateOfChargeableEvent" 
                                                formControlName="dateOfChargeableEvent" ngbDatepicker #d2="ngbDatepicker" readonly
                                                [ngClass]="{ 'is-invalid': showValidations && form.dateOfChargeableEvent.errors}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button" disabled></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label translate>accountingDocument.placeOfTransaction</label>
                                            <input type="text" class="form-control" formControlName="dealPlace"
                                                placeholder="{{'placeholder.enter' | translate}}" [readonly]="isDisabled">
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.dealDescription</label>
                                            <input type="text" class="form-control" formControlName="dealDescription"
                                                placeholder="{{'placeholder.enter' | translate}}" maxlength="50" [readonly]="isDisabled">
                                        </div>
                                        <div class="col">
                                            <label translate>accountingDocument.dealReason</label>
                                            <input type="text" class="form-control" formControlName="dealReason"
                                                placeholder="{{'placeholder.enter' | translate}}" maxlength="50" [readonly]="isDisabled">
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.recepient</label>
                                            <input type="text" class="form-control" formControlName="recipientFullName" [readonly]="isDisabled"
                                                placeholder="{{'placeholder.enter' | translate}}">
                                        </div>
                                        <div class="col">
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.amountWithoutVat</label>
                                            <input type="text" class="form-control" formControlName="amount"
                                                [ngClass]="{ 'is-invalid': showValidations && form.amount.errors }"
                                                readonly />
                                        </div>
                                        <div class="col">
                                            <label translate>accountingDocument.ddsValue</label>
                                            <select class="form-control w-100" formControlName="ddsValue"
                                                (change)="changeDds()">
                                                <option *ngFor="let vatGroup of VAT_GROUPS" [ngValue]="vatGroup">
                                                    {{vatGroup}}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.amountDds</label>
                                            <input type="text" class="form-control" formControlName="amountDds"
                                                readonly />
                                        </div>
                                        <div class="col">
                                            <label translate>accountingDocument.totalAmount</label>
                                            <input type="text" class="form-control" formControlName="totalAmount"
                                                readonly />
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <div class="col">
                                            <label translate>accountingDocument.paymentType</label>
                                            <select class="custom-select" formControlName="paymentType"
                                            [ngClass]="{ 'is-invalid': showValidations && form.paymentType.errors}" [compareWith]="compareById">
                                                <option *ngFor="let paymentType of paymentTypes"
                                                    [ngValue]="paymentType">
                                                    {{paymentType.description}}
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="form.paymentType?.errors?.required && showValidations"
                                                translate>
                                                errors.required
                                            </small>
                                        </div>
                                        <div class="col">
                                            <label for="maturityDate" translate>accountingDocument.maturity</label>
                                            <div class="input-group"> 
                                                <input class="form-control d-inline" aria-describedby="maturityDate" 
                                                formControlName="maturityDate" ngbDatepicker #d3="ngbDatepicker" readonly
                                                [ngClass]="{ 'is-invalid': showValidations && form.maturityDate.errors}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d3.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ****************************************************** 
                    ************** Service Table Data *************
                    ****************************************************** -->
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="form-group form-row">
                                        <div class="col text-right">
                                            <button class="btn btn-outline-success ml-2 mb-2" type="button"
                                                (click)="addRow()" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ACCOUNTING_DOCUMENT) && (form.customerInvoiceDetails?.get('id')?.value == null || form.documentType?.value != 'I')"  [disabled]="accountingDocument?.status?.code == ANNULLED_STATUS_CODE" translate>button.add</button>
                                        </div>
                                        <table class="table table-bordered table-striped w-100">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style="width:40vw" translate>accountingDocument.service</th>
                                                    <th scope="col" style="width:8vw" translate>accountingDocument.fullQuantity</th>
                                                    <th scope="col" style="width:8vw" translate>accountingDocument.measuringUnit</th>
                                                    <th scope="col" style="width:8vw" translate>accountingDocument.singleValueWithoutDDS</th>
                                                    <th scope="col" style="width:8vw" translate>accountingDocument.singleValueWithDDS</th>
                                                    <th scope="col" style="width:15vw" translate>accountingDocument.amountWithoutVat</th>
                                                    <th scope="col" style="width:8vw" translate>accountingDocument.ddsValue</th>
                                                    <th scope="col" style="width:8vw" translate>accountingDocument.amountDds</th>
                                                    <th scope="col" style="width:12vw" translate>accountingDocument.totalAmount</th>
                                                    <th scope="col" style="width:5vw" translate class="text-center" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ACCOUNTING_DOCUMENT) && form.customerInvoiceDetails?.get('id')?.value == null">button.delete</th>
                                                </tr>
                                            </thead>

                                            <tbody formArrayName="accountingDocumentElementList">
                                                <small class="text-danger" *ngIf="rows?.errors && showValidations"
                                                    translate>
                                                    errors.atLeastOneElement
                                                </small>
                                                <ng-container *ngFor="let row of rows.controls; let i= index"
                                                    [formGroupName]="i">
                                                    <tr *ngIf="row.get('isValid')?.value">
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="description" maxlength="150"
                                                                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('description')?.errors }" 
                                                                    [readonly] = "isDisabled">
                                                            </div>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('description')?.errors?.maxlength && showValidations">
                                                                {{'errors.inputTooLong' | translate : {max:150} }}
                                                            </small>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('description')?.errors?.required && showValidations"
                                                                translate>
                                                                errors.required
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="quantity"
                                                                    (change)= "calculateElementTotalAmount(i)"
                                                                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('quantity')?.errors }"
                                                                    [readonly] = "isDisabled"
                                                                    >
                                                            </div>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('quantity')?.errors?.required && showValidations"
                                                                translate>
                                                                errors.required
                                                            </small>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('quantity')?.errors?.maxlength && showValidations">
                                                                {{'errors.inputTooLong' | translate : {max:10} }}
                                                            </small>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('quantity')?.errors?.pattern && showValidations"
                                                                translate>
                                                                errors.onlyPositiveNumbers
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <select class="custom-select"
                                                                    formControlName="measuringUnit"
                                                                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('measuringUnit')?.errors }"
                                                                    [compareWith]="compareById">
                                                                    <option *ngFor="let measuringUnit of measuringUnits"
                                                                        [ngValue]="measuringUnit">
                                                                        {{measuringUnit.description}} 
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('measuringUnit')?.errors?.required && showValidations"
                                                                translate>
                                                                errors.required
                                                            </small>
                                                        </td> 
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control" formControlName="singleAmount" readonly>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="singleAmountWithDDS" 
                                                                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('singleAmountWithDDS')?.errors }"
                                                                    (change)="calculateSingleAmountWithoutDDS(i)">
                                                            </div>
                                                            <small class="text-danger"
                                                                *ngIf="rows.controls[i]?.get('singleAmountWithDDS')?.errors?.required && showValidations"
                                                                translate>
                                                                errors.required
                                                            </small>
                                                            <small class="text-danger"
                                                            *ngIf="rows.controls[i]?.get('singleAmountWithDDS')?.errors?.maxlength && showValidations">
                                                            {{'errors.inputTooLong' | translate : {max:8} }}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="amount"
                                                                    (onChange)="calculateElementTotalAmount(i)"
                                                                    readonly>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="ddsValue" readonly>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="ddsAmount" readonly
                                                                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('ddsAmount')?.errors }">
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div>
                                                                <input type="text" class="form-control"
                                                                    formControlName="totalAmount" readonly
                                                                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('totalAmount')?.errors }">
                                                            </div>
                                                        </td>
                                                        <td class="text-center" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ACCOUNTING_DOCUMENT) && form.customerInvoiceDetails?.get('id')?.value == null" >
                                                            <button class="btn btn-danger btn-sm rounded-0" [disabled]="accountingDocument?.status?.code == ANNULLED_STATUS_CODE"
                                                                type="button" (click)="deleteRow(i)"><em
                                                                    class="fa fa-trash"></em></button>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ****************************************************** 
            ************** Print And Save Button *************
            ****************************************************** -->
            <div class="form-group form-row" id="non-print">
                <div class="col">
                    <button class="btn btn-primary w-20" *ngIf= "addEditForm.get('invoiceNumber')?.value != null && techInspectionId == null" 
                        (click)="printPage()" type="button" translate>
                        button.print
                    </button>
                </div>
                <div class="col text-right">
                    <button class="btn btn-primary w-20" type="submit"
                        *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ACCOUNTING_DOCUMENT) && accountingDocument?.status?.code != ANNULLED_STATUS_CODE" translate>
                        button.save
                    </button>
                </div>
            </div>

            <!-- ****************************************************** 
            ************** Print Invoice *************
            ****************************************************** -->
            <div id="print">
                <!-- ****************************************************** 
                ************** Print Original Invoice Start *************
                ****************************************************** -->
                <h4 class="form-group title-position" translate>accountingDocument.original</h4>
                <!-- To Invoice Number -->
                <div class="row" style="padding-right:1.25rem !important" *ngIf="form.documentType.value == 'C' || form.documentType.value == 'D'">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.toInvoiceNumber</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form?.invoice?.value?.invoiceNumber}}</label>
                    </div>
                </div>

                <!-- Invoice number -->
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.number</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.invoiceNumber.value}}</label>
                    </div>
                </div>

                <!-- Invoice status and date -->
                <div class="row inline d-flex justify-content-between">
                    <div class="inline d-flex">
                        <div class="col-12">
                            <label translate>accountingDocument.documentStatus</label>
                            <label class="ml-2">{{addEditForm.get('status')?.value?.name}}</label>
                        </div>
                    </div>
                    <div class="inline d-flex">
                        <div class="col-6">
                            <label translate>accountingDocument.date</label>
                        </div>
                        <div>
                            <label>{{form.invoiceDate.value.day + '.' + form.invoiceDate.value.month + '.' + form.invoiceDate.value.year}}</label>
                        </div>
                    </div>
                </div>
                
                <!-- Publisher and recipient data -->
                <div class="row">
                    <!-- Publisher -->
                    <div class="col-6 mb-4">
                        <div class="card border-secondary">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.publisher</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('fullName')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.eik</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('identNum')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.supplierDirector</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('manager')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.supplierVatNumber</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('invoiceDdsRegistration')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.city</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('city')?.value?.name}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.address</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('address')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.phone</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('phoneNumber')?.value}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Recipient -->
                    <div class="col-6 mb-4">
                        <div class="card border-secondary">
                            <div class="card-body" *ngIf="form.customerInvoiceDetails?.get('id')?.value == null">
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.recepient</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('fullName')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.eik</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('identNum')?.value}}</label>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.customerDirector</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('invoiceManager')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.customerVatNumber</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('invoiceDdsRegistration')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.city</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('city')?.value?.name}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.address</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('address')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.phone</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('phoneNumber')?.value}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" *ngIf="form.customerInvoiceDetails?.get('id')?.value != null">
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.recepient</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('fullName')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.eik</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('identNumber')?.value}}</label>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.customerDirector</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('manager')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.customerVatNumber</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('vatNumber')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.address</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('fullAddress')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.phone</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('customer')?.get('phoneNumber')?.value}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Service table data  -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group form-row">
                            <table class="table table-bordered w-100">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:40vw" translate>accountingDocument.service</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.quantity</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.measuringUnit</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.singleValueWithoutDDS</th>
                                        <th scope="col" style="width:15vw" translate>accountingDocument.amountWithoutVat</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.ddsValue</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.amountDds</th>
                                        <th scope="col" style="width:15vw" translate>accountingDocument.totalAmount
                                        </th>
                                    </tr>
                                </thead>

                                <tbody formArrayName="accountingDocumentElementList">
                                    <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">
                                        <tr *ngIf="row.get('isValid')?.value">
                                            <td>
                                                <div>
                                                    <label>{{row.value.description}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.quantity}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.measuringUnit?.description}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.singleAmount}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.amount}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.ddsValue}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.ddsAmount}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.totalAmount}}</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Service table calculating data start -->
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.taxBase</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.amount.value}}</label>
                    </div>
                </div>
                
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.amountDds</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.amountDds.value}}</label>
                    </div>
                </div>
                
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.paymentAmount</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.totalAmount.value}}</label>
                    </div>
                </div>
                <!-- Service table calculating data end -->
                
                <!-- Invoice and payment data -->
                <div class="row">
                    <!-- Invoice data -->
                    <div class="col-6 mb-4 border">
                        <div class="form-row">
                            <div class="col-6 mt-3">
                                <label translate>accountingDocument.dateOfChargeableEvent</label>
                            </div>
                            <div class="col-6 mt-3">
                                <label>{{form?.dateOfChargeableEvent?.value.day + '.' + form?.dateOfChargeableEvent?.value.month + '.' + form?.dateOfChargeableEvent?.value.year}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.dealReason</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.dealReason?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.dealDescription</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.dealDescription?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.placeOfTransaction</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.dealPlace?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.recepient</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.recipientFullName?.value}}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Payment data -->
                    <div class="col-6 mb-4 border">
                        <div class="form-row">
                            <div class="col-6 mt-3">
                                <label translate>accountingDocument.paymentType</label>
                            </div>
                            <div class="col-6 mt-3">
                                <label>{{form.paymentType?.value?.description}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.supplierIban</label>
                            </div>
                            <div class="col-6">
                                <label>{{bankAccount?.get('iban')?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.bank</label>
                            </div>
                            <div class="col-6">
                                <label>{{bankAccount?.get('bankName')?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.bic</label>
                            </div>
                            <div class="col-6">
                                <label>{{bankAccount?.get('bic')?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.maturity</label>
                            </div>
                            <div class="col-6" *ngIf="form?.maturityDate?.value != null">
                                <label>{{form?.maturityDate?.value?.day + '.' + form?.maturityDate?.value?.month + '.' + form?.maturityDate?.value?.year}}</label> 
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Receipient and compiled by names -->
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col text-left">
                        <label translate>accountingDocument.recepient</label>
                        <label class="ml-2"> {{form?.recipientFullName?.value}}</label>
                    </div>
                    <div class="col text-right">
                        <label translate>accountingDocument.compiledBy</label>
                        <label class="ml-2">{{employeeName}}</label>
                    </div>
                </div>
                <ng-container *ngIf="showAdditionalTextInInvoice">
                    <div class="row" style="padding-right:1.4rem !important; font-weight: bold; margin-top: 2rem; font-size: 20px; text-align: justify; line-height: 1.1; margin-left: -2.4rem !important; font-family: 'DejaVu Sans', sans-serif;">
                        <label>Моля, попълнете номера на фактурата в полето основание за плащане при извършване на банковия превод. Please, include the invoice number  in the "reason for payment" field when making the bank transfer.</label>
                    </div>
                </ng-container>
                <!-- ****************************************************** 
                ************** Print Original Invoice End *************
                ****************************************************** -->
                <br/><br/><br/><br/><br/><br/><br/><br/> <br/><br/>
                <br/><br/><br/><br/><br/><br/><br/>
                <!-- ****************************************************** 
                ************** Print Copy Invoice Start *************
                ****************************************************** -->
                <h1 class="form-group title-position">{{formatType(form.documentType.value)}}</h1>
                <h4 class="form-group title-position" translate>accountingDocument.copy</h4>
                <!-- To Invoice Number -->
                <div class="row" style="padding-right:1.25rem !important" *ngIf="form.documentType.value == 'C' || form.documentType.value == 'D'">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.toInvoiceNumber</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form?.invoice?.value?.invoiceNumber}}</label>
                    </div>
                </div>

                <!-- Invoice number -->
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.number</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.invoiceNumber.value}}</label>
                    </div>
                </div>
                
                <!-- Invoice status and date -->
                <div class="row inline d-flex justify-content-between">
                    <div class="inline d-flex">
                        <div class="col-12">
                            <label translate>accountingDocument.documentStatus</label>
                            <label class="ml-2">{{addEditForm.get('status')?.value?.name}}</label>
                        </div>
                    </div>
                    <div class="inline d-flex">
                        <div class="col-6">
                            <label translate>accountingDocument.date</label>
                        </div>
                        <div>
                            <label>{{form.invoiceDate.value.day + '.' + form.invoiceDate.value.month + '.' + form.invoiceDate.value.year}}</label>
                        </div>
                    </div>
                </div>
                
                <!-- Publisher and recipient data -->
                <div class="row">
                    <!-- Publisher -->
                    <div class="col-6 mb-4">
                        <div class="card border-secondary">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.publisher</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('fullName')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.eik</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('identNum')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.supplierDirector</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('manager')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.supplierVatNumber</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('invoiceDdsRegistration')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.city</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('city')?.value?.name}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.address</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('address')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.phone</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{publisher?.get('phoneNumber')?.value}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Recipient -->
                    <div class="col-6 mb-4"  *ngIf="form.customerInvoiceDetails?.get('id')?.value == null">
                        <div class="card border-secondary">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.recepient</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('fullName')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.eik</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('identNum')?.value}}</label>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.customerDirector</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('invoiceManager')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.customerVatNumber</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('invoiceDdsRegistration')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.city</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('city')?.value?.name}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.address</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('address')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-4">
                                        <label translate>accountingDocument.phone</label>
                                    </div>
                                    <div class="col-8">
                                        <label>{{recepient?.get('phoneNumber')?.value}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Recipient -->
                    <div class="col-6 mb-4"  *ngIf="form.customerInvoiceDetails?.get('id')?.value != null">
                        <div class="card border-secondary">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.recepient</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('fullName')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.eik</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('identNumber')?.value}}</label>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.customerDirector</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('manager')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.customerVatNumber</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('vatNumber')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.address</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('fullAddress')?.value}}</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-3">
                                        <label translate>accountingDocument.phone</label>
                                    </div>
                                    <div class="col-9">
                                        <label>{{customerInvoiceDetails?.get('customer')?.get('phoneNumber')?.value}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Service table data  -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group form-row">
                            <table class="table table-bordered  w-100">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width:40vw" translate>accountingDocument.service</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.quantity</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.measuringUnit</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.singleValueWithoutDDS</th>
                                        <th scope="col" style="width:15vw" translate>accountingDocument.amountWithoutVat</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.ddsValue</th>
                                        <th scope="col" style="width:10vw" translate>accountingDocument.amountDds
                                        </th>
                                        <th scope="col" style="width:15vw" translate>accountingDocument.totalAmount
                                        </th>
                                    </tr>
                                </thead>

                                <tbody formArrayName="accountingDocumentElementList">
                                    <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">
                                        <tr *ngIf="row.get('isValid')?.value">
                                            <td>
                                                <div>
                                                    <label>{{row.value.description}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.quantity}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.measuringUnit?.description}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.singleAmount}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.amount}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.ddsValue}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.ddsAmount}}</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <label>{{row.value.totalAmount}}</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Service table calculating data start -->
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.taxBase</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.amount.value}}</label>
                    </div>
                </div>
                
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.amountDds</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.amountDds.value}}</label>
                    </div>
                </div>
                
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col-11 text-right">
                        <label translate>accountingDocument.paymentAmount</label>
                    </div>
                    <div class="col-1 text-right">
                        <label>{{form.totalAmount.value}}</label>
                    </div>
                </div>
                <!-- Service table calculating data end -->

                <!-- Invoice and payment data -->
                <div class="row">
                    <div class="col-6 mb-4 border">
                        <div class="form-row">
                            <div class="col-6 mt-3">
                                <label translate>accountingDocument.dateOfChargeableEvent</label>
                            </div>
                            <div class="col-6 mt-3">
                                <label>{{form?.dateOfChargeableEvent?.value.day + '.' + form?.dateOfChargeableEvent?.value.month + '.' + form?.dateOfChargeableEvent?.value.year}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.dealReason</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.dealReason?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.dealDescription</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.dealDescription?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.placeOfTransaction</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.dealPlace?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.recepient</label>
                            </div>
                            <div class="col-6">
                                <label>{{form?.recipientFullName?.value}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mb-4 border">
                        <div class="form-row">
                            <div class="col-6 mt-3">
                                <label translate>accountingDocument.paymentType</label>
                            </div>
                            <div class="col-6 mt-3">
                                <label>{{form.paymentType?.value?.description}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.supplierIban</label>
                            </div>
                            <div class="col-6">
                                <label>{{bankAccount?.get('iban')?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.bank</label>
                            </div>
                            <div class="col-6">
                                <label>{{bankAccount?.get('bankName')?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.bic</label>
                            </div>
                            <div class="col-6">
                                <label>{{bankAccount?.get('bic')?.value}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <label translate>accountingDocument.maturity</label>
                            </div>
                            <div class="col-6" *ngIf="form?.maturityDate?.value != null">
                                <label>{{form?.maturityDate?.value?.day + '.' + form?.maturityDate?.value?.month + '.' + form?.maturityDate?.value?.year}}</label> 
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Receipient and compiled by names -->
                <div class="row" style="padding-right:1.25rem !important">
                    <div class="col text-left">
                        <label translate>accountingDocument.recepient</label>
                        <label class="ml-2"> {{form?.recipientFullName?.value}}</label>
                    </div>
                    <div class="col text-right">
                        <label translate>accountingDocument.compiledBy</label>
                        <label class="ml-2">{{employeeName}}</label>
                    </div>
                </div>
                <ng-container *ngIf="showAdditionalTextInInvoice">
                    <div class="row" style="padding-right:1.4rem !important; font-weight: bold; margin-top: 2rem; font-size: 20px; text-align: justify; line-height: 1.1; margin-left: -2.4rem !important; font-family: 'DejaVu Sans', sans-serif;">
                        <label>Моля, попълнете номера на фактурата в полето основание за плащане при извършване на банковия превод. Please, include the invoice number  in the "reason for payment" field when making the bank transfer.</label>
                    </div>
                </ng-container>

                <!-- ****************************************************** 
                ************** Print Copy Invoice End *************
                ****************************************************** -->
            </div>
        </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <em class="fa fa-refresh" (click)="reload()"></em>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>

<ng-template #dataChange>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.change</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;">accountingDocument.dataChange<p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="true" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                (click)="onDataChangeConfirmationModal()" translate>
                messages.ok
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>