<div class="container justify-content-center" >
    <h1 class="title-position" translate>cardLoyal.generator</h1>
</div>
<div class="container">
    <ng-container class="border my-2 py-2 px-3">
        <!-- ****************************************************** 
        ************** Card Form *************
        ****************************************************** -->
        <!-- Standard way of creation -->
        <form [formGroup]="generateForm" style="margin-top: 2rem;">
            <div class="input-group mb-2">
                <!-- Number of cards -->
                <div class="input-group-prepend ml-2"><span class="input-group-text" translate>cardLoyal.generate.total</span></div>
                <input type="number" min="1" formControlName="numberOfCards" class="form-control" id="numberOfCards" name="numberOfCards" required
                    [ngClass]="{ 'is-invalid': !getForm['numberOfCards'].valid && isSubmited }" />
                
                <!-- Program -->
                <div class="input-group-prepend ml-2"><span class="input-group-text" translate>cardLoyal.generate.type</span></div>
                <select class="custom-select input-custom-size" formControlName="programMobileAppId" required id="programMobileAppId" name="programMobileAppId" 
                    [ngClass]="{ 'is-invalid': !getForm['programMobileAppId'].valid && isSubmited }">
                    <option disabled selected value translate>placeholder.select</option>
                    <option *ngFor="let pro of programs" [ngValue]="pro.mobileAppId">
                        {{ pro.programName }}
                    </option>
                </select>

                <!-- Generate And Print Buttons -->
                <div class="input-group-append ml-2">
                    <button class="btn btn-outline-success" type="button" (click)="onSubmit()" translate>button.generate</button>
                </div>
                <div class="input-group-append ml-2">
                    <i class="fa fa-file-pdf-o fa-2x" data-toggle="tooltip" data-placement="bottom" style="margin-left: 5px; margin-top: 4px; cursor: pointer;"
                        title="{{'button.pdf' | translate}}" (click)="generateMultiCardBarcode()">
                    </i>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadMultiCardBarcode; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <br>
        <div class="row">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th style="width: 28%" sortBy="cardNumber" (sort)="onSubmitSearchFormSort($event)"  translate>cardLoyal.number</th>
                        <th style="width: 28%" sortBy="cardBarcode" (sort)="onSubmitSearchFormSort($event)" translate>cardLoyal.barcode</th>
                        <th style="width: 28%" sortBy="programName" (sort)="onSubmitSearchFormSort($event)" translate>type</th>
                        <ng-container *ngIf="this.cardsDataExcel && this.cardsDataExcel.size > 0">
                            <th style="width: 28%" sortBy="maxUsage" (sort)="onSubmitSearchFormSort($event)" translate>cardLoyal.maxUsage</th>
                        </ng-container>
                        <ng-container *ngIf="!this.cardsDataExcel || this.cardsDataExcel.size === 0">
                            <th style="width: 16%" translate>option</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let card of cardList">
                        <td style="width: 28%">{{ card.cardNumber }}</td>
                        <td style="width: 28%">{{ card.cardBarcode }}</td>
                        <td style="width: 28%">{{ card.programName }}</td>
                        <ng-container *ngIf="this.cardsDataExcel && this.cardsDataExcel.size > 0">
                            <td style="width: 28%">{{ card.maxUsage }}</td>
                        </ng-container>
                        <ng-container *ngIf="!this.cardsDataExcel || this.cardsDataExcel.size === 0">
                            <td style="width: 16%" class="text-center">
                                <i class="fa fa-file-pdf-o fa-2x" data-toggle="tooltip" data-placement="bottom" style="margin-left: 5px; margin-top: 4px; cursor: pointer;"
                                    title="{{'button.pdf' | translate}}" (click)="generateSingleCardBarcode(card.id)">
                                </i>
                            </td>
                        </ng-container>
                    </tr>    
                </tbody>
            </table>       
        </div>
        <br>
        <div *ngIf="!loadData; else dataLoadingTemplate"></div>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between p-2" *ngIf="cardList.length !== 0">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (ngModelChange)="loadCustomerCards()">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </ng-container>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger text-center">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>