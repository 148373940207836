import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

const S_TOKEN_ACCESS    = 'accessToken';
const S_TOKEN_REFRESH   = 'refreshToken';

const URL_AUTH                  = environment.apiBaseURL + '/auth';
const URL_LOGIN                 = URL_AUTH + '/o/login';
const URL_CHECK_VALIDITY        = URL_AUTH + '/o/check-validity?token='
const URL_REFRESH               = URL_AUTH + '/o/refresh';
const URL_RESET_PASSWD          = URL_AUTH + '/o/passwd-reset';
const URL_REQUEST_RESET_PASSWD  = URL_AUTH + '/o/request-password-reset';

const URL_LOGOUT                = URL_AUTH + '/logout';
const URL_CHANGE_PASSWD         = URL_AUTH + '/change-passwd';

const URL_EMPLOYEE              = environment.apiBaseURL + '/employees';
const URL_EMPLOYEE_TECH_POINTS  = URL_EMPLOYEE + '/{id}/tech-points';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private isLoginSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn());
  private employeeTechPoints: number[];

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  public passwdLogin(username: string, password: string): Observable<void> {
    const req = { 'username': username, 'password': password };
    
    return this.http.post<any>(URL_LOGIN, req)
      .pipe(
        map(resp => {
          let token = JSON.parse(JSON.stringify(resp));
          // store user details and jwt token in localStorage
          this.registerToken(token.accessToken, token.refreshToken);
          this.isLoginSubject.next(true);

          return AuthenticationService.getDecodedAccessToken().lau_id;
        })
      );
  }

  public registerToken(accessToken: string, refreshToken: string) {
    AuthenticationService.storeData(S_TOKEN_ACCESS,  accessToken);
    AuthenticationService.storeData(S_TOKEN_REFRESH, refreshToken);
  }

  public static refreshToken(http: HttpClient): Observable<void> {
    const data = {
      'accessToken':  AuthenticationService.getData(S_TOKEN_ACCESS),
      'refreshToken': AuthenticationService.getData(S_TOKEN_REFRESH)
    };

    return http.post<any>(URL_REFRESH, data).pipe(map(resp => {
      let token = JSON.parse(JSON.stringify(resp));
      AuthenticationService.storeData(S_TOKEN_ACCESS, token.accessToken);
      AuthenticationService.storeData(S_TOKEN_REFRESH, token.refreshToken);
    }));
  }

  public logout() {
    this.http.post<any>(URL_LOGOUT, null).subscribe();
    sessionStorage.clear();
    localStorage.clear();
    this.isLoginSubject.next(false);
    this.router.navigate(['/login']);
  }

  public checkTokenValidity(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${URL_CHECK_VALIDITY}${token}`);
  }

  public requestPasswordReset(email: string) {
    let body = { 'email' : email };
    return this.http.post<void>(URL_REQUEST_RESET_PASSWD, body);
  }

  public resetPassword(token: string, password: string): Observable<void> {
    let body = { 'token': token, 'password': password };
    return this.http.post<any>(URL_RESET_PASSWD, body);
  }

  public changePassword(currentPass: string, newPass: string): Observable<void> {
    let body = { 'oldPassword': currentPass, 'newPassword': newPass };
    return this.http.post<any>(URL_CHANGE_PASSWD, body);
  }

  public static getDecodedAccessToken(): any {
    try {
        return jwt_decode(this.getAccessToken());
    } catch (Error) {
        return null;
    }
  }

  public static getAccessToken(): string {
    return AuthenticationService.getData(S_TOKEN_ACCESS);
  }

  public static getEmployeeTechPointsIds(): number[] {
    let token = AuthenticationService.getDecodedAccessToken()

    if (token != null) {
      return token.lau_tp == null ? [] : JSON.parse(token.lau_tp); 
    } else {
      return [];
    }
  }

  public static getEmployeeName(): string {
    let token = AuthenticationService.getDecodedAccessToken()

    if (token != null) {
      return token.lau_tp == null ? '' : token.lau_name; 
    } else {
      return '';
    }
  }

  public static getEmployeeId() {
    let token = AuthenticationService.getDecodedAccessToken();

    if (token != null) {
      return token.lau_em == null ? null : JSON.parse(token.lau_em);
    } else {
      return null;
    }
  }

  public static getUsername() {
    let token = AuthenticationService.getDecodedAccessToken();

    if (token != null) {
      return token.lau_un == null ? null : token.lau_un;
    } else {
      return null;
    }
  }

  private static storeData(key: string, val: any): void {
    localStorage.removeItem(key);
    localStorage.setItem(key, val);
  }

  public isLoggedIn() {
    if (localStorage.getItem(S_TOKEN_ACCESS)) {
        return true;
    }

    return false;
  }

  private static getData(key: string): string {
    return localStorage.getItem(key)!;
  }

  get loginSubject() {
    return this.isLoginSubject;
  }

}
