export interface PriceAttributeKind {
    code: string;
    name: string;
}

export enum PRICE_ATTRIBUTE_KIND{
    VALUE = "V",
    PERCENTAGE = "P"
}

export const PRICE_ATTRIBUTE_KINDS: PriceAttributeKind[] = [
    { name: "Стойност",    code: "V" },
    { name: "Процент",   code: "P" },
];

export const findByCode = (code: string) => PRICE_ATTRIBUTE_KINDS.filter(kind => kind.code === code);