&nbsp;
<div class="container border mainbox">
    &nbsp;
    <h1 class="title-position" translate>technicalInspection.technicalInspection</h1>
    &nbsp;

    <div>
        <form [formGroup]="techInspectionMainForm" (ngSubmit)="onSubmitTechnicalInspectionCcForm()">
            <!-- ****************************************************** 
            ************** Filters *************
            ****************************************************** -->
            <div class="card">
                <div class="card-header">
                    <h3 class="mb-0 title-position" translate> technicalInspection.techPointInformation</h3>
                </div>
                <div class="card-body">
                    <div class="form-group form-row">
                        <div class="form-group row">
                            <!-- date -->
                            <div class="form-group col-md-6 col-lg-2">
                                <label for="searchDateInput" translate>technicalInspection.date</label>
                                <div class="input-group"> 
                                    <input class="form-control d-inline" [minDate]="minDate" aria-describedby="searchDate"
                                        formControlName="searchDate" ngbDatepicker #d1="ngbDatepicker" readonly (dateSelect)='onAvailableTimesDataChange()'
                                        [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.searchDate.errors}">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                                    </div>
                                </div>
                            </div>

                            <!-- city -->
                            <div class="form-group col-md-6 col-lg-2">
                                <label for="cityInput" translate>techPoint.city</label>
                                <select class="custom-select" [compareWith]="compareByCode" formControlName="city" (change)='onCityChange(mainForm.city.value)'
                                        [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.city.errors }">
                                    <option *ngFor="let city of cities" [ngValue]="city"> 
                                        {{city.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.city?.errors?.required " translate>errors.required</div>
                            </div>
                            
                            <!-- main service -->
                            <div class="form-group col-md-6 col-lg-2">
                                <label for="mainServiceInput" translate>technicalInspection.basicService</label>
                                <select class="custom-select" [compareWith]="compareByCode" formControlName="mainService" (change)='onMainServiceChange(mainForm.mainService.value, mainForm.city.value)'
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.mainService.errors }">
                                    <option *ngFor="let service of mainServices" [ngValue]="service"> 
                                        {{service.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.orderOption?.errors?.required " translate>errors.required</div>
                            </div>
                            
                            <!-- category -->
                            <div class="form-group col-md-6 col-lg-2">
                                <label for="rvsCategory" translate>technicalInspection.category</label>
                                <select class="custom-select" formControlName="rvsCategory" [compareWith]="compareById" (change)='onRvsCategoryChange(mainForm.rvsCategory.value, mainForm.mainService.value, mainForm.city.value)'
                                        [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.rvsCategory.errors }">
                                    <option *ngFor="let category of rvsCategories" [ngValue]="category">
                                        {{category.description}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.rvsCategory.errors?.required" translate>errors.required</div>
                            </div>
                            
                            <!-- tech point -->
                            <div class="form-group col-md-6 col-lg-2">
                                <label for="techPointInput" translate>technicalInspection.pointPlace</label>
                                <select class="custom-select" [compareWith]="compareByCode" formControlName="searchTechPoint" (change)='onAvailableTimesDataChange()'
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.orderOption.errors }">
                                    <option value="" translate [selected]="true">
                                        placeholder.select
                                    </option>
                                    <option *ngFor="let techPoint of technicalPoints" [ngValue]="techPoint" [selected]="false">
                                        {{techPoint.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.orderOption?.errors?.required" translate>errors.required</div>
                            </div>
                            
                            <!-- order by -->
                            <div class="form-group col-md-6 col-lg-2">
                                <label for="orderOptionInput" translate>technicalInspection.orderOption</label>
                                <select class="custom-select" [compareWith]="compareByCode" formControlName="orderOption" 
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.orderOption.errors }">
                                    <option *ngFor="let orderOption of orderOptions" [ngValue]="orderOption">
                                        {{orderOption.description}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.orderOption?.errors?.required " translate>errors.required</div>
                            </div>
                        </div>
                        
                        <!-- Result from filters (tech points or time slots) -->
                        <table class="table table-bordered table-striped w-100 center" style=" font-size: 19px">
                            <thead>
                                <tr>
                                    <th class="text-center" translate>technicalInspection.techPointName</th>
                                    <th class="text-center" translate>technicalInspection.slot</th>
                                    <th class="text-center" translate>technicalInspection.price</th>
                                    <th class="text-center" translate>actions</th>
                                </tr>
                            </thead>
                            
                            <!-- tech points -->
                            <tbody *ngIf="mainForm.searchTechPoint?.value == ''">
                                <tr *ngFor="let attp of availableTimesTechPoints; let i = index">
                                    <td *ngIf="attp?.slots?.length != 0" class="text-center">{{attp?.technicalPointName}}</td>
                                    <td *ngIf="attp?.slots?.length != 0" class="text-center">{{attp?.slots![0]?.time}}</td>
                                    <td *ngIf="attp?.slots?.length != 0" class="text-center">{{attp?.slots![0]?.services![0]?.totalAmount}}</td>
                                    <td *ngIf="attp?.slots?.length != 0" class="text-center">
                                        <button class="btn btn-sm btn-primary" type="button" (click)="chooseTechPoint(attp.technicalPointId, attp.slots![0].id, attp.technicalPointName, attp.slots![0].time)" translate>
                                            button.choose
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                            <!-- time slots -->
                            <tbody *ngIf="mainForm.searchTechPoint?.value != ''">
                                <tr *ngFor="let slot of availableTimesTechPoints[0]?.slots; let i = index"> 
                                    <td class="text-center">{{mainForm.searchTechPoint?.value?.shortName}}</td>
                                    <td class="text-center">{{slot.time}}</td>
                                    <td class="text-center">{{slot.services[0]!.totalAmount}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-primary" (click)="chooseTechPointWorkignHour(slot.id, mainForm.searchTechPoint.value.id, mainForm.searchTechPoint.value.name, slot.time)" type="button" translate>
                                            button.choose
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            &nbsp;
            <!-- ****************************************************** 
            ************** Core data for creation *************
            ****************************************************** -->
            <div id="accordion" *ngIf="loadTechnicalInspection$ | async as data; else loading">
                <div *ngIf="showAllFields">
                    <!-- ************************************************** 
                    ********************* Personal data *******************
                    *************************************************** -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title-position" translate>technicalInspection.stepTwo</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="phoneNumber" translate>technicalInspection.phoneNumber</label>
                                <input type="text" formControlName="phoneNumber" placeholder="Пример:+359888112233" class="form-control"
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.phoneNumber.errors }">
                                <div class="invalid-feedback" *ngIf="mainForm.phoneNumber.errors?.maxlength" translate>errors.tooLong</div>
                                <div class="invalid-feedback" *ngIf="mainForm.phoneNumber?.errors?.required" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="mainForm.phoneNumber?.errors?.invalidPhoneNumber" translate>errors.phoneInvalid</div>
                            </div>

                            <div class="form-group">
                                <label for="emailInput" translate>techPoint.email</label>
                                <input class="form-control" type="text" id="emailInput" placeholder="{{'placeholder.enter' | translate}}"
                                formControlName="email" [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.email.errors}">
                                <div class="invalid-feedback" *ngIf="mainForm.email?.errors?.required" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="mainForm.email?.errors?.email" translate>errors.mailInvalid</div>
                                <div class="invalid-feedback" *ngIf="mainForm.email?.errors?.maxlength" translate>{{"errors.inputTooLong" | translate: {max: 60} }}</div>
                            </div>

                            <div class="form-group">
                                <label for="nameInput" translate>technicalInspection.name</label>
                                <input class="form-control" type="text" id="nameInput" placeholder="{{'placeholder.enter' | translate}}"
                                formControlName="name" [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.name.errors}">
                                <div class="invalid-feedback" *ngIf="mainForm.name?.errors?.required" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="mainForm.name?.errors?.maxlength" translate>{{"errors.inputTooLong" | translate: {max: 60} }}</div>
                            </div>

                        </div>
                    </div>
                    <!-- ************************************************** 
                    *************** Car Data And Main Service *************
                    *************************************************** -->
                    <div class="card">
                        <div class="card-header" >
                            <h3 class="mb-0 title-position" translate>technicalInspection.stepOne</h3>
                        </div>

                        <div class="card-body">
                            <div class="form-group">
                                <label class="person-label-position" translate>technicalInspection.pointName</label>
                                <label class="ml-2 label-size">{{techPointName}}</label>
                            </div>
                            <div class="form-group">
                                <label class="person-label-position" translate>technicalInspection.dateOfInsp</label>
                                <label class="ml-2 label-size">{{dateOfInsp}}</label>
                            </div>
                            <div class="form-group">
                                <label class="person-label-position" translate>technicalInspection.slotLabel</label>
                                <label class="ml-2 label-size">{{slotDescr}}</label>
                            </div>
                            <div *ngIf="customerSubscriptionName != null" class="form-group">
                                <label class="person-label-position" translate>technicalInspection.subscription</label>
                                <label class="ml-2 label-size">{{customerSubscriptionName}}</label>
                            </div>

                            <div *ngIf="technicalInspection !== null">
                                <div class="form-group">
                                    <label class="person-label-position" translate>technicalInspection.lineNumber</label>
                                    <label class="ml-2 label-size">{{mainForm.lineNumber.value}}</label>
                                </div>
                                <div class="form-group ">
                                    <label class="person-label-position" translate>technicalInspection.status</label>
                                    <label class="ml-2 label-size">{{techInspStatusName}}</label>
                                </div>
                                <div class="form-group ">
                                    <label class="person-label-position" translate>technicalInspection.mobileNumber</label>
                                    <label class="ml-2 label-size">{{techInspMobileId}}</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="regNumber" translate>technicalInspection.registrationNumber</label>
                                <input class="form-control d-inline" type="text" id="regNumberInput"
                                    placeholder="{{'placeholder.enter' | translate}}" formControlName="regNumber" 
                                    (focusout)="normalizeRegNumber('mainForm', mainForm.regNumber.value)"
                                    (input)="validateMainFormRegNumber(mainForm.regNumber.value)"
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.regNumber?.errors || isValidMainFormRegNum}" />
                                <div class="invalid-feedback" *ngIf="mainForm.regNumber?.errors?.required" translate>errors.required</div>
                                <div class="text-danger" *ngIf="isValidMainFormRegNum" translate>errors.onlyLatinSymbolsAndDigits</div>
                                <div class="text-danger" *ngIf="isValidRegNumberForForeigner" translate>errors.regNumberForForeigner</div>
                                <div class="text-danger" *ngIf="isValidRegNumberForDiplomatic" translate>errors.regNumberForDiplomatic</div>
                                <div class="text-danger" *ngIf="isValidRegNumberForElectric" translate>errors.regNumberForElectric</div>
                                <div class="invalid-feedback" *ngIf="mainForm.regNumber.errors?.maxlength" translate>errors.tooLong</div>
                            </div>

                            <!-- <div class="form-group">
                                <label for="registrationCertNumber" translate>technicalInspection.certificationNumber</label>
                                <input type="text" formControlName="registrationCertNumber" class="form-control" placeholder="{{'placeholder.enter' | translate}}"
                                (input)="validateMainFormRegCertNumber(mainForm.registrationCertNumber.value)"
                                [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.registrationCertNumber.errors || isValidMainFormRegCertNum }">
                                <div class="text-danger" *ngIf="isValidMainFormRegCertNum"><small translate>errors.onlyCapitalLatinLettersAndNumbers</small></div>
                                <div class="invalid-feedback" *ngIf="mainForm.registrationCertNumber.errors?.required" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="mainForm.registrationCertNumber.errors?.maxlength" translate>errors.tooLong</div>
                            </div> -->
                        
                            <!-- <div class="form-group">
                                <label for="rvsCategory" translate>technicalInspection.category</label>
                                <select class="custom-select" formControlName="rvsCategory" [compareWith]="compareById" (change)='onRvsCategorySelect(mainForm.rvsCategory.value)'
                                        [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.rvsCategory.errors }">
                                    <option *ngFor="let category of rvsCategories" [ngValue]="category">
                                        {{category.description}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.rvsCategory.errors?.required" translate>errors.required</div>
                            </div> -->
                            
                            <div class="form-group">
                                <h4 for="basicTechPointService" translate>technicalInspection.basicServices</h4>
                                <div class="form-check" *ngFor="let item of basicServices">
                                    <input class="form-check-input radio-input-size" formControlName="basicTechPointService" type="radio" 
                                        [value]="item" (change)='onBasicTechPointServiceSelect()' [attr.disabled]="disableTechPointServices"
                                        [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.basicTechPointService.errors }" />
                                    <label class="form-check-label radio-label-size ml-2" translate> {{item.serviceName}}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- ************************************************** 
                    **** Additional services and annual subscriptions ****
                    *************************************************** -->
                    &nbsp;
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title-position" translate> technicalInspection.additionalAndAnnualServices</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group form-row">
                                <div class="col form-group">
                                    <div class="card">
                                        <div class="card-header">
                                            <h5 class="mb-0 card-title" translate>technicalInspection.additionalServices</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="form-check" *ngFor="let item of additionalServices">
                                                    <input #checkboxes type="checkbox" [value]="item" [attr.disabled]="disableTechPointServices"
                                                    class="form-check-input mt-2"  
                                                    [checked]="checkId(item.id)"
                                                    (change)="onChangeAdditionalService($event, item)">
                                                    <label class="form-check-label checkboxtext"> {{item.serviceName}} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col form-group">
                                    <div class="card">
                                        <div class="card-header">
                                            <h5 class="mb-0 card-title" translate>technicalInspection.annualSubscriptions</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="form-check" *ngFor="let item of annualServices">
                                                    <input type="checkbox" class="form-check-input mt-2" [value]="item" [attr.disabled]="disableAnnualService"
                                                    [checked]="annualService != null && annualService.id === item.id" (change)="onChangeAnnualService($event, item)">
                                                    <label class="form-check-label checkboxtext"> {{item.serviceName}} </label>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="text-right">
                                <button class="btn btn-outline-success btn-sm" type="button" *ngIf="!isVisiblePaymentServicesTable" (click)="showPaymentServicesTable()" translate>button.showPaymentServiceData</button>
                                <button *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC) && technicalInspection?.status?.code != 'ANNULLED' && technicalInspection?.id != null" class="btn btn-sm btn-danger  btn-lg ml-1" (click)="changeTechInspStatus(techInspectionMainForm.get('id')?.value, 'ANNULLED')" type="button" translate>button.annul</button>
                            </div> -->
                        </div>
                    </div>
                    <!-- ************************************************** 
                    ******************** loyalCard & promoCard ************
                    *************************************************** -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title-position" translate>technicalInspection.loyalCardOrPromoCode</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group form-row">
                                <!--  ******************** Loyal Card *********************** -->
                                <div class="col form-group" >
                                    <div class="card">
                                        <div class="card-header">
                                            <h5 class="mb-0 card-title" translate>technicalInspection.loyalCard</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <h4 translate>technicalInspection.loyaltyProgramCard</h4>
                                                    <input class="form-control input-custom-size" type="text" id="customerCard" placeholder="{{'placeholder.enter' | translate}}"
                                                        formControlName="customerCard" (change)="onChangeLoyaltyCard()">
                                                    <span *ngIf="displayExceedCardMessage" translate class="card-error" style="margin-bottom: -1.2rem;">
                                                        technicalInspection.displayExceedCardMessage
                                                    </span>
                                                    <span *ngIf="displayCardDoesNotExistMessage" translate class="card-error" style="margin-bottom: -1rem;">
                                                        technicalInspection.displayCardDoesNotExistMessage
                                                    </span>
                                                </div>
                                                
                                                <div class="form-group input-custom-size mt-4">
                                                    <h4 translate>technicalInspection.discountInValueOrPercentage</h4>
                                                    <input class="form-control input-custom-size" type="text" id="customerCardDiscountName" 
                                                        formControlName="customerCardDiscountName" [attr.disabled]="true"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ********************* Promo Code *********************** -->
                                <div class="col form-group" >
                                    <div class="card">
                                        <div class="card-header">
                                            <h5 class="mb-0 card-title" translate>technicalInspection.listPromocode</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <h4 translate>technicalInspection.listPromocode</h4>
                                                    <input class="form-control input-custom-size" type="text" id="promoCode" placeholder="{{'placeholder.enter' | translate}}"
                                                        formControlName="promoCode" (change)="onChangePromoCode()">
                                                </div>
                                                <div class="form-group input-custom-size mt-4">
                                                    <ng-container *ngIf="isPercentageDiscount === undefined">
                                                        <h4 translate>technicalInspection.discountInValueOrPercentage</h4>
                                                    </ng-container>
                                                    <ng-container *ngIf="isPercentageDiscount == false">
                                                        <h4 translate>technicalInspection.discount</h4>
                                                    </ng-container>
                                                    <ng-container *ngIf="isPercentageDiscount == true">
                                                        <h4 translate>technicalInspection.discountPercentages</h4>
                                                    </ng-container>
                                                    <ng-container *ngIf="!isPromoCodeDataArrivedHtml">
                                                        <div class="form-control input-custom-size">
                                                            <p class="wait-promo-discount" translate>placeholder.wait</p>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="isPromoCodeDataArrivedHtml">
                                                        <input class="form-control input-custom-size" type="text" id="customerPromoDiscount" 
                                                            formControlName="customerPromoDiscount" [attr.disabled]="true"/>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Show Payment Button -->
                            <div class="text-right">
                                <button class="btn btn-outline-success btn-sm" type="button" *ngIf="!isVisiblePaymentServicesTable && isPromotionDataArrived" (click)="showPaymentServicesTable()" translate>button.showPaymentServiceData</button>
                                <button *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC) && technicalInspection?.status?.code != 'ANNULLED' && technicalInspection?.id != null && !isVisiblePaymentServicesTable" class="btn btn-sm btn-danger  btn-lg ml-1" (click)="changeTechInspStatus(techInspectionMainForm.get('id')?.value, 'ANNULLED')" type="button" translate>button.annul</button>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <!-- ************************************************** 
                    ******************** Payment Services *****************
                    *************************************************** -->
                    <div class="card" *ngIf="isVisiblePaymentServicesTable">
                        <div class="card-header">
                            <h3 class="mb-0 title-position" translate> technicalInspection.stepFour</h3>
                        </div>
                        <div class="card-body">
                            <div *ngIf="hasPromocode && promotionCodeName" class="row form-group input-custom-size">
                                <h5 class="person-label-position ml-3" translate>technicalInspection.promocode</h5>
                                <label class="ml-2 label-size">{{ promotionCodeName }}</label>
                            </div> 
                            <div class="form-group form-row">
                                <table class="table table-bordered table-striped w-100" style=" font-size: 19px">
                                    <thead>
                                        <tr>
                                            <th translate>technicalInspection.service</th>
                                            <th translate>technicalInspection.type</th>
                                            <th translate>technicalInspection.price</th>
                                            <th translate>technicalInspection.discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let s of paymentServiceConvertedData">
                                            <td>{{s.serviceName}}</td>
                                            <td>{{s.serviceType}}</td>
                                            <td>{{s.amountDds | number:'1.0-2'}}</td>
                                            <td>{{s.calculatedDiscount | number:'1.0-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <ng-container *ngIf="isReservationPaid && orderPriceMismatch">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="mb-0 title-position" translate>Корекция предплатени</h3>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <h5 style="display: flex; align-items: center; justify-content: center; height: 30px; margin-top: 1rem;">
                                            <span style="font-size: 50px; height: 74px;">&#8680;</span>
                                            <span>
                                                Платените услуги са на стойност {{orderAmountPaid | number:'1.2-2'}} лева, след корекцията има за 
                                                <span *ngIf="orderAmountPaid > currentAmountServices">връщане {{(orderAmountPaid - currentAmountServices) | number:'1.2-2'}}</span>
                                                <span *ngIf="currentAmountServices > orderAmountPaid">доплащане {{(currentAmountServices - orderAmountPaid) | number:'1.2-2'}}</span>
                                                лева.
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </ng-container>
                        <div class="text-right">
                            <!-- The only diff is that have isUnchanged boolean in this comment btn -->
                            <!-- <button class="btn btn-primary w-20 ml-2 mr-2 mb-1" type="button" *ngIf="!orderPaid && !hasPromocode && isUnchanged && perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC)" [disabled]="disableSaveButton" (click)="onSubmitTechnicalInspectionCcForm()" translate>button.save</button> -->
                            <button class="btn btn-primary w-20 ml-2 mr-2 mb-1" type="button" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC)" [disabled]="disableSaveButton" (click)="onSubmitTechnicalInspectionCcForm()" translate>button.save</button>
                            <button *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC) && technicalInspection?.status?.code != 'ANNULLED' && technicalInspection?.id != null && isVisiblePaymentServicesTable" class="btn btn-sm btn-danger  btn-lg ml-1" (click)="changeTechInspStatus(techInspectionMainForm.get('id')?.value, 'ANNULLED')" type="button" style="font-size: 1rem; padding: 0.36rem; margin-bottom: 5px; margin-right: 5px;" translate>button.annul</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Loading template -->
            <ng-template #loading>
                <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                    <div class="alert alert-danger">
                        {{errorMessage}}
                    </div>
                    <div class="d-flex justify-content-center">
                        <i class="fa fa-refresh" (click)="reload()"></i>
                    </div>
                </div>
                <ng-template #spinner>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </form> 
    </div>
</div>
&nbsp;