import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { ResetPasswordPopupComponent } from '@app/reset-password/reset-password-popup/reset-password-popup.component';
import { modalMinWidth } from '@env/config';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loading    = false;
  public submitted  = false;
  public isLogin    = true;

  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  private baseSub: Subscription = new Subscription();  

  constructor(
    private auth:             AuthenticationService,
    private route:            ActivatedRoute,
    private router:           Router,
    private dialog:           MatDialog,
    private toastr :          ToastrService,
    private formBuilder:      FormBuilder,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    let paramsSub = this.route.queryParams.pipe(
      tap((params: any) => {
        if (params['action'] != null && params['action'] == 'resetPassword') {
          this.isLogin = false;
        } else {
          this.isLogin = true;
        }
      })
    ).subscribe();
    this.baseSub.add(paramsSub);

    if (this.isLogin) {
      let loginSubjectSub = this.auth.loginSubject.subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.router.navigate(['']);
        }
      });
      this.baseSub.add(loginSubjectSub);
    }
  }

  ngOnDestroy(): void {
    this.baseSub.unsubscribe();
  }

  public onSubmit() {
    this.loading = true;
    this.submitted = true;
    
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    
    this.auth.passwdLogin(this.loginForm.get('username')?.value, this.loginForm.get('password')?.value).pipe(
      timeout(5000)
    ).subscribe({
      next:   () => this.router.navigate(['']),
      error:  (err: HttpErrorResponse) => {
        if(err.status == 401) {
          let title = this.translateService.instant('toast.errorTitle');
          let message = this.translateService.instant('toast.' + err.error.messageKey);  
        
          this.toastr.error(message, title);
          this.loading = false;
        } else {  
          let title = this.translateService.instant('toast.errorTitle');
          let message = this.translateService.instant('toast.unexpectedError');  
        
          this.toastr.error(message, title);
          this.loading = false;
        }
      }
    });
  }

  public forgottenPasswd() {
    let dialogRef = this.dialog.open(ResetPasswordPopupComponent, {
      minWidth: "25vw",
      maxWidth: "30vw",
      maxHeight: "40vw",
    });
  }

  get form() {
    return this.loginForm.controls;
  }
}
