<div class="form-group form-row" [formGroup]="contractSlotsForm">
    <div class="col text-right">
        <button class="btn btn-outline-success ml-2 mb-2" type="button" (click)="addRow()"
        *ngIf="canAddEdit" translate>button.add</button>
    </div>
    <table class="table table-bordered table-striped w-100">
        <thead>
            <tr>
                <th scope="col" style="width:30vw" translate>ktp</th>
                <th scope="col" style="width:10vw" translate>contract.line</th>
                <th scope="col" style="width:55vw" translate>contract.slots</th>
                <th scope="col" style="width:5vw" translate class="text-center" *ngIf="canAddEdit">button.delete</th>
            </tr>
        </thead>

        <tbody formArrayName="rows">
            <small class="text-danger" *ngIf="rows?.errors && showValidations" translate>
                errors.atLeastOneTimeSlot
            </small>
            <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">
            <tr *ngIf="row.get('isValid')?.value" >
                <td>
                    <mat-select class="custom-select" formControlName="technicalPoint" (ngModelChange)='onTechPointSelect($event, i)'
                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('technicalPoint')?.errors }">
                        <mat-option *ngFor="let technicalPoint of selectedTechnicalPoints"
                            [value]="technicalPoint.id">
                            {{technicalPoint.name}}
                            </mat-option>
                    </mat-select>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('technicalPoint')?.errors?.required && showValidations" translate>
                        errors.required
                    </small> 
                </td>
                <td>
                    <mat-select class="custom-select" formControlName="line" 
                    (ngModelChange)="onLineSelect($event, i)" [compareWith]="compareById"
                    [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('line')?.errors }">
                        <mat-option *ngFor="let line of rows.controls[i]?.get('linesList')?.value"
                            [value]="line">
                            {{line.number}}
                            </mat-option>
                    </mat-select>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('line')?.errors?.required && showValidations" translate>
                        errors.required
                    </small> 
                </td>
                <td>
                    <mat-form-field class="mat-form-field">
                    <mat-select #mySelect multiple [compareWith]="compareById" (click)="checkSelectedAll(mySelect,i)"
                        [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('technicalPointWorkingHoursList')?.errors }"
                        formControlName="technicalPointWorkingHoursList">
                        <mat-option [value]="0" (click)="toggleAllSelection(mySelect,i)" translate>{{'button.chooseAll' | translate}}</mat-option>
                        <mat-option  *ngFor="let workingHours of rows.controls[i]?.get('workingHoursList')?.value"
                            (onSelectionChange)="change($event,i,mySelect)"
                            [value]="workingHours">
                            {{workingHours?.timeConstraint?.validFrom}} - {{workingHours?.timeConstraint?.validTo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <small class="text-danger" *ngIf="rows.controls[i]?.get('technicalPointWorkingHoursList')?.errors?.required && showValidations" translate>
                    errors.required
                </small> 
                </td>
                <td class="text-center" *ngIf="canAddEdit">
                    <button class="btn btn-danger btn-sm" type="button" data-toggle="tooltip" data-placement="bottom" title="{{'button.delete' | translate}}" (click)="deleteRow(i)" *ngIf="canAddEdit" ><em class="fa fa-trash"></em></button>
                </td>
            </tr>
            </ng-container>
        </tbody>
    </table>
</div>