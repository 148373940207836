import { WarningMessageboxComponent } from '@components/_base/_messageboxes/warning-messagebox/warning-messagebox.component';
import { TechnicalInspectionService } from '@app/technical-inspections/_services/technical-inspection.service';
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { NomenclatureService } from '@services/nomenclature.service';
import { FormBuilder, Validators } from '@angular/forms';
import { UtilValidators } from '@app/_validators/util.validators';
import { RvsCategory } from '@models/rvs-category.model';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { modalMinWidth } from '@env/config';
import { config } from '@env/config';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { prepareDateForDb, prepareDateForForm } from '@app/_utils/date-util';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';

@Component({
  selector: 'app-add-customer-subscription',
  templateUrl: './add-customer-subscription.component.html',
  styleUrls: ['./add-customer-subscription.component.css']
})
export class AddCustomerSubscriptionComponent implements OnInit {
  // Units
  rvsCategories:                RvsCategory[];
  defRvsCategory:               RvsCategory | null;
  annualServices:               TechPointService[];
  userTechnicalPointIds:        number[];
  userTechnicalPointId:         number;
  technicalPointWorkingHourId:  number;

  // Constants
  readonly RVS_CATEGORY_CODE = 'M1';

  // Booleans
  loading                             = false;
  isValidRegNum                       = false;
  isValidRegCertNum                   = false;
  submittedCustomerSubscriptionForm   = false;
  carLicensePlateForeignerIncorrect   = false;
  carLicensePlateDiplomaticIncorrect  = false;
  carLicensePlateElectricIncorrect    = false;

  // Payload
  minDate: { year: number, month: number, day: number };

  // Form
  customerSubscriptionForm = this.formBuilder.group({
    validFrom:          ['', [Validators.required]],
    validTo:            ['', [Validators.required]],
    regNumber:          ['', [Validators.required, Validators.maxLength(5), Validators.maxLength(8)]],
    regCertNumber:      ['', [Validators.required, Validators.maxLength(9)]],
    phoneNumber:        ['', [Validators.required, Validators.maxLength(16), UtilValidators.validatePhoneNumber(), ]],
    rvsCategory:        ['', [Validators.required]],
    techPointService:   ['', [Validators.required]],
  });

  constructor(
    private dialog: MatDialog,
    private uiEvent: UIEventCustom,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private techPointService: TechnicalPointService,
    private nomenclatureService: NomenclatureService,
    private techInspService: TechnicalInspectionService
  ) {
    this.minDate = prepareDateForForm(new Date());

    this.userTechnicalPointIds = AuthenticationService.getEmployeeTechPointsIds();
    this.techPointService.getTechPointIdByTechPointMobileAppId(this.userTechnicalPointIds[0]).subscribe(userTechPointId => {
      this.userTechnicalPointId = userTechPointId;
      this.techInspService.getCurrentTechPointWorkingHourId(userTechPointId).subscribe(workingHourId => {
        this.technicalPointWorkingHourId = workingHourId;
      });
    });
  }  

  async ngOnInit() {
    this.initDates();
    this.loadRvsCategories();
  }

  onSubmitCustomerSubscriptionForm() {
    this.submittedCustomerSubscriptionForm = true;
    if (this.customerSubscriptionFormValidation()) {
      return;
    }

    let customrSubscriptionData = this.customerSubscriptionForm.value;
    this.techInspService.checkForValidSubscription(customrSubscriptionData.phoneNumber, customrSubscriptionData.regNumber, customrSubscriptionData.regCertNumber, customrSubscriptionData.techPointService.serviceId).subscribe({
      next: (result) => {
        if(result) {
           this.showWarningModal();
        }  else {
          this.loading = true;
          this.techInspService.saveCustomerSubscription(this.constructCustomerSubscriptionData()).subscribe({
            next: (result) => {
              this.submittedCustomerSubscriptionForm = false;
              this.prepareNewCustomerSubscription();
              this.uiEvent.displayUISuccess();
            }, error:(err) => {
              this.loading = false;
              displayError(err);
            }
          });
        }
      }  
    });
  }

  async showWarningModal() { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.validSubscription"
      }
    });
    await dialogRef.afterClosed().toPromise();
  }

  private constructCustomerSubscriptionData() {
    let customrSubscriptionData = this.customerSubscriptionForm.value;
    
    return {
      validFrom:        prepareDateForDb(customrSubscriptionData.validFrom),
      validTo:          prepareDateForDb(customrSubscriptionData.validTo),
      regNumber:        customrSubscriptionData.regNumber        || null,
      regCertNumber:    customrSubscriptionData.regCertNumber    || null,
      phoneNumber:      customrSubscriptionData.phoneNumber      || null,
      rvsCategory:      customrSubscriptionData.rvsCategory      || null,
      techPointService: customrSubscriptionData.techPointService || null,
    }
  }

  onValidFromChange() {
    const validFromValue = this.customerSubscriptionForm.get('validFrom')?.value;
    this.customerSubscriptionForm.get('validTo')?.patchValue({year: validFromValue.year + 1, month: validFromValue.month, day: validFromValue.day});
  }

  onRvsCategorySelect(rvsCategory: any) {
    this.loadAnnualServicesByRvsCategory(rvsCategory);
  }

  validateRegNumber(regNumber: string) {
    regNumber = regNumber.toUpperCase();
    if (!regNumber.length && regNumber.length < 2) { // don't trigger validators
      this.customerSubscriptionForm.get('regNumber')?.clearValidators();
      return; 
    }
    if (regNumber.length > 7) {
        this.defaultCarLicensePlateBehaviour();
        return;
    }

    // foreign
    if (regNumber.startsWith('XH') || regNumber.startsWith('ХН')) {
      this.validateForeigner(regNumber);
    
    // diplomatic
    } else if (this.validateDiplomaticInitial(regNumber)) {
      this.validateDiplomatic(regNumber);   
    
    // electric
    } else if (regNumber.startsWith('EA') || regNumber.startsWith('ЕА')) {
      this.validateElectric(regNumber);
    
    // default
    } else {
      this.defaultCarLicensePlateBehaviour();
    }
  }

  private defaultCarLicensePlateBehaviour() {
    this.carLicensePlateForeignerIncorrect = false;
    this.carLicensePlateElectricIncorrect = false;
    this.carLicensePlateDiplomaticIncorrect = false;
    this.customerSubscriptionForm.get('regNumber')?.setValidators([Validators.required, Validators.min(8), Validators.max(8), Validators.pattern(/^(([A-ZА-Я]){2}([0-9]){4}([A-ZА-Я]){2}){1,8}$/)]);
  }
  
  private validateForeigner(carLicensePlate: string) {
    this.carLicensePlateForeignerIncorrect = false;
    if (!carLicensePlate.match(config.regexForeigner)) {
      this.customerSubscriptionForm.get('regNumber')?.clearValidators();
      this.carLicensePlateForeignerIncorrect = true;
    }
  }

  private validateElectric(carLicensePlate: string) {
    this.carLicensePlateElectricIncorrect = false;
    if (!carLicensePlate.match(config.regexElectric)) {
      this.customerSubscriptionForm.get('regNumber')?.clearValidators();
        this.carLicensePlateElectricIncorrect = true;
    }
}

  validateDiplomaticInitial(carLicensePlate: string) {
    let firstDiplomaticCheck = false;
    let secondDiplomaticCheck = false;
    let thirdDiplomaticSymbolsEndCheck = false;
    const numbers = /\d+$/;

    firstDiplomaticCheck = carLicensePlate.startsWith('C') || carLicensePlate.startsWith('CC') 
        || carLicensePlate.startsWith('С') || carLicensePlate.startsWith('СС');
    
    secondDiplomaticCheck = carLicensePlate.charAt(1).startsWith('C') || carLicensePlate.charAt(1).startsWith('С');
    if (!secondDiplomaticCheck) {
        if (carLicensePlate.charAt(1).match(numbers)) {
            secondDiplomaticCheck = true;
        }
    }
    if (carLicensePlate.charAt(carLicensePlate.length - 1).match(numbers)) {
      thirdDiplomaticSymbolsEndCheck = true;
    }

    if (firstDiplomaticCheck && secondDiplomaticCheck && thirdDiplomaticSymbolsEndCheck) {
        return true;
    }
    return false;
  }

  private validateDiplomatic(carLicensePlate: string) {
    this.carLicensePlateDiplomaticIncorrect = false;
    if (!carLicensePlate.match(config.regexDiplomatic)) {
      this.customerSubscriptionForm.get('regNumber')?.clearValidators();
        this.carLicensePlateDiplomaticIncorrect = true;
    }
  }

  validateRegCertNumber(regCertNumber: string) {
    regCertNumber = regCertNumber.toUpperCase();
    regCertNumber.match(config.regexOnlyLatinSymbolsAndDigits) || regCertNumber === "" ? this.isValidRegCertNum = false :  this.isValidRegCertNum = true;
    this.customerSubscriptionForm.get('regCertNumber')?.patchValue(regCertNumber);
  }

  normalizeRegNumber(regNumber: string) {
    this.techInspService.getNormalizedRegNumber(regNumber).subscribe(normalizedNumber => {
      this.customerSubscriptionForm.get('regNumber')?.patchValue(normalizedNumber.regNum);
    });
  }

  private customerSubscriptionFormValidation() {
    return this.customerSubscriptionForm.invalid || this.isValidRegNum || this.isValidRegCertNum;
  }

  private prepareNewCustomerSubscription() {
    this.customerSubscriptionForm.reset();
    this.initDates();
    this.customerSubscriptionForm.get('rvsCategory')?.patchValue(this.defRvsCategory);
    this.customerSubscriptionForm.get('techPointService')?.patchValue(this.annualServices[0]);
  }

  private loadRvsCategories() {
    this.nomenclatureService.getRvsCategoriesByTechPointId(this.userTechnicalPointIds[0]).subscribe(rvsCategories => {
      this.rvsCategories = rvsCategories;
      this.defRvsCategory = this.rvsCategories.find(category => category.code === this.RVS_CATEGORY_CODE) || null;
      this.customerSubscriptionForm.get('rvsCategory')?.patchValue(this.defRvsCategory);
      this.loadAnnualServicesByRvsCategory(this.defRvsCategory!);
    });
  }

  private async loadAnnualServicesByRvsCategory(rvsCategory: RvsCategory) {
    await new Promise(f => setTimeout(f, 500));
    this.techInspService.getAnnualRvsCategoryServices(rvsCategory, this.userTechnicalPointId).subscribe(async services => {
        this.annualServices = services;
        this.customerSubscriptionForm.get('techPointService')?.patchValue(services[0]);
       
        let currentSelectedServiceMobileAppIds: Array<number> = [];
        this.annualServices.forEach(service => currentSelectedServiceMobileAppIds.push(service.serviceMobileAppId!));
        
        let result = await this.techInspService.getTechPointServicePricesFromFunction(this.technicalPointWorkingHourId, this.userTechnicalPointId, this.customerSubscriptionForm.get('rvsCategory')?.value.code, currentSelectedServiceMobileAppIds, new Date().toISOString().split('T')[0].split("-").reverse().join("-")).toPromise();
        this.annualServices.forEach(service1 => {
          result.forEach(service2 => {
            if (service1.serviceMobileAppId === service2.serviceMobileAppId) {
              service1.amountDds = service2.discountedPrice
              service1.calculatedDiscount = service2.discountedValue;
            }
          });
        });
    });
  }

  private initDates() {
    const dateObj = new Date();
    this.customerSubscriptionForm.get('validFrom')?.patchValue(prepareDateForForm(dateObj));
    this.customerSubscriptionForm.get('validTo')?.patchValue(prepareDateForForm(dateObj));
  }

  compareById(optionOne: any, optionTwo: any): boolean {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  get custSubscrForm() { return this.customerSubscriptionForm.controls; }

}
