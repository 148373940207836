<div class="container mainbox">
    <div class="row justify-content-center">
        <h1 translate class="form-group title-position">cardLoyal.detail</h1>
    </div>
    <ng-container *ngIf="showComponent">
        <div id="accordion">
            <div class="container">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 mb-4">
                                <!-- ****************************************************** 
                                ************** Card Detail Form *************
                                ****************************************************** -->
                                <form [formGroup]="cardForm">
                                    <!-- Number -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.number</label>
                                            <input type="text" formControlName="cardNumber" class="form-control input-custom-size" id="cardNumber" name="cardNumber" required
                                                [ngClass]="{ 'is-invalid': !getForm['cardNumber'].valid && isSubmited }" 
                                                placeholder="{{'placeholder.enter' | translate}}" readonly/>
                                        </div>
                                    </div>

                                    <!-- Barcode -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.barcode</label>
                                            <input type="text" formControlName="cardBarcode" class="form-control input-custom-size" id="cardBarcode" name="cardBarcode" required
                                                [ngClass]="{ 'is-invalid': !getForm['cardBarcode'].valid && isSubmited }" 
                                                placeholder="{{'placeholder.enter' | translate}}" readonly/>
                                        </div>
                                    </div>

                                    <!-- Program -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.name</label>
                                            <input type="text" formControlName="programMobileId" class="form-control input-custom-size" id="programMobileId" name="programMobileId" required
                                                [ngClass]="{ 'is-invalid': !getForm['programMobileId'].valid && isSubmited }" 
                                                placeholder="{{'placeholder.enter' | translate}}" readonly/>
                                        </div>
                                    </div>
                                    
                                    <!-- Status -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.status</label>
                                            <select class="custom-select input-custom-size" formControlName="status" id="status"
                                                [ngClass]="{ 'is-invalid': !getForm['status'].valid && isSubmited }">
                                                <option *ngFor="let sts of statuses" [ngValue]="sts"> {{ sts.name }} </option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <!-- Activation Email -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.email</label>
                                            <input type="text" formControlName="activationEmail" class="form-control input-custom-size" id="activationEmail" name="activationEmail" required
                                                [ngClass]="{ 'is-invalid': !getForm['activationEmail'].valid && isSubmited }" readonly/>
                                        </div>
                                    </div>
                                    
                                    <!-- Activation Date -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.date</label>
                                            <input type="text" formControlName="activationDate" class="form-control input-custom-size" id="activationDate" name="activationDate" required
                                                [ngClass]="{ 'is-invalid': !getForm['activationDate'].valid && isSubmited }" readonly/>
                                        </div>
                                    </div>
                                    
                                    <!-- Max Usage -->
                                    <div class="row justify-content-center">
                                        <div class="form-group col-md-6">
                                            <label class="font-weight-bold" translate>cardLoyal.maxUsage</label>
                                            <input formControlName="maxUsage" class="form-control input-custom-size" id="maxUsage" name="maxUsage"
                                                [ngClass]="{ 'is-invalid': !getForm['maxUsage'].valid && isSubmited }" type="number"/>
                                        </div>
                                    </div>
                                </form>
                                <br>
                                <div class="col-lg-12 mb-4">
                                    <div class="row flex-row-reverse" style="margin-left: 1rem !important;">
                                        <button type="submit" class="btn btn-primary w-20" (click)="onSubmit()" translate>button.save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mb-4">
                                <h2 translate style="border-bottom: 3px solid #000; padding-bottom: 5px;">
                                    cardLoyal.history
                                </h2>
                            </div>
                            <!-- ****************************************************** 
                            ************** Table Data *************
                            ****************************************************** -->
                            <div class="col-lg-12 mb-4" *ngIf="technicalInspections.length !== 0">
                                <table class="table table-bordered table-striped w-100">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%;"  sortBy="id" (sort)="onSubmitSearchFormSort()" translate>date</th>
                                            <th style="width: 20%;"  sortBy="id" (sort)="onSubmitSearchFormSort()" translate>cardLoyal.pps</th>
                                            <th style="width: 20%;"  sortBy="id" (sort)="onSubmitSearchFormSort()" translate>cardLoyal.point</th>
                                            <th style="width: 20%;"  sortBy="id" (sort)="onSubmitSearchFormSort()" translate>cardLoyal.services</th>
                                            <th style="width: 20%;"  sortBy="id" (sort)="onSubmitSearchFormSort()" translate>cardLoyal.discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ins of technicalInspections">
                                            <td style="width: 20%;">{{ ins.inspectionDate }}</td>
                                            <td style="width: 20%;">{{ ins.registrationNumber }}</td>
                                            <td style="width: 20%;">{{ ins.technicalPointShortName }}</td>
                                            <td style="width: 20%;">{{ ins.services }}</td>
                                            <td style="width: 20%;">{{ ins.discountNameFromOrder }}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                                <br>
                                <!-- ****************************************************** 
                                    ************** Pagination *************
                                ****************************************************** -->
                                <div class="paging-wrapper d-flex justify-content-between p-2">
                                    <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (ngModelChange)="loadTechnicalInspections()">
                                        <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                                        {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                                        </option>
                                    </select>
                                    <ngb-pagination class="pagination-control"
                                        [(page)]="sortingPaging.pageNumber"
                                        [pageSize]="sortingPaging.pageSize"
                                        [collectionSize]="sortingPaging.totalElements"
                                        [boundaryLinks]="true"
                                        [maxSize]="maxSize"
                                        (pageChange)="pageChanged($event)">
                                    </ngb-pagination>
                                    <span class="pagination-index border center">
                                        {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-4" *ngIf="technicalInspections.length === 0">
                                <h5 translate>cardLoyal.inactivity</h5>
                            </div>
             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>