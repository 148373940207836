<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 class="my-2" translate>users.users</h3>
        </span>
    </div>
    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- role -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>users.role</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="role">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let r of roles" [value]="r.id">
                        {{r.description}}
                    </option>
                </select>
                
                <!-- status -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>serviceAddInfo.isValid</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="enabled">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let option of options" [value]="option.code">
                        {{option.name}}
                    </option>
                </select>
                
                <!-- full name -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>users.fullName</span>
                </div>
                <input class="form-control d-inline" type="text" formControlName="fullName"/>

                <!-- employeer -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>users.employer</span>
                </div>
                <input id="employerTypeahead" type="text" class="form-control" 
                        [editable]='false' [value]="employeeInput"
                        [ngbTypeahead]="subjectVersionsSearch" (selectItem)="onEmployerSelect($event)"
                        [inputFormatter]="subjectVersionFormatter"
                        [resultTemplate]="ert"/>
                <ng-template #ert let-r="result" let-t="term">
                    <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                </ng-template>
            </div>
            <div class="input-group mb-2">
                <!-- tech point -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>users.techPoint</span>
                </div>
                <input id="techPointTypeahead" type="text" class="form-control" style="max-width: 250pt;"
                        [editable]='false' [value]="techPointInput"
                        [ngbTypeahead]="techPointSearch" (selectItem)="onTechPointSelect($event)"
                        [inputFormatter]="techPointFormatter"
                        [resultTemplate]="tprt"/>
                <ng-template #tprt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                </ng-template>
                
                <!-- email -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>users.email</span>
                </div>
                <input class="form-control" type="text" formControlName="email" style="max-width: 250pt;"/>
                
                <!-- actions -->
                <div class="input-group-prepend ml-2">
                    <button class="btn btn-outline-success mr-2" type="button" (click)="search()" translate>
                        button.search
                    </button>
                    <button class="btn btn-outline-danger mr-2" type="button" (click)="onClear()" translate>
                        button.clear
                    </button>
                    <button class="btn btn-outline-success" routerLink="/add-edit-user" type="button" translate>
                        button.add
                    </button>
                </div>
            </div>
        </form>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th sortBy="fullName" (sort)="onSort($event)" scope="col" translate>users.fullName</th>
                        <th sortBy="username" (sort)="onSort($event)" scope="col" translate>users.username</th>
                        <th sortBy="email" (sort)="onSort($event)" scope="col" translate>users.email</th>
                        <th sortBy="enabled" (sort)="onSort($event)" scope="col" translate>users.isValid</th>
                        <th sortBy="employer" (sort)="onSort($event)" scope="col" translate>users.employer</th>
                        <th sortBy="description" (sort)="onSort($event)" translate>users.role</th>
                        <th class="text-center" scope="col" translate>actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{item.fullName}}</td>
                        <td>{{item.username}}</td>
                        <td>{{item.email}}</td>
                        <td translate>{{item.enabled == true? 'yes' : 'no'}}</td>
                        <th>{{item.employerName}}</th>
                        <th>{{item.roleName}}</th>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}" 
                                routerLink="/add-edit-user" [queryParams]="{id: item.id}"><i class="fa fa-pencil fa-lg"></i>
                            </button>
                            <button data-toggle="tooltip" data-placement="bottom" title="{{'button.delete' | translate}}"
                                class="btn btn-sm btn-danger  btn-lg ml-1" type="button" (click)="onSubmitDeleteModal(item.id)">
                                <i class="fa fa-close"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize"
                (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="d-flex flex-column" *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="d-inline-flex justify-content-center">
            <div class="alert alert-danger">
                {{errorMessage}}
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #confirmDelete>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.confirm</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;"><p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                (click)="onSubmitDelete()" translate>
            no
        </button>
            <button mat-button [mat-dialog-close]="true" class="ml-2 mr-2 mb-1 btn btn btn-danger" type="button" 
                (click)="onSubmitDelete()" translate>
                button.delete
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>