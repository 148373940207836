import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { CardProgramServiceService } from '@app/card-config/_services/card-program-service.service';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { ListCustomerCards } from '@app/card-config/_models/list-customer-cards.model';
import { Router } from '@angular/router';
import { LoyaltyProgramsEssential } from '@app/card-config/_models/loyalty-programs-essential.model';
import { displayError } from '@app/_utils/error-util';
import { BarcodeService } from '@services/barcode.service';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-search-partner-program',
  templateUrl: './search-partner-program.component.html',
  styleUrls: ['./search-partner-program.component.css']
})
export class SearchPartnerProgramComponent extends BaseSortableSearchComponent<ListCustomerCards> implements OnInit, OnDestroy {
  // Units
  cards:          ListCustomerCards[] = [];
  copyCards:      ListCustomerCards[] = [];
  programs:       LoyaltyProgramsEssential[] = [];
  allBarcodes:    string[] = [];

  // Booleans
  isSubmited           = false;
  dataLoaded           = false;
  loadMultiCardBarcode = false;
  loadPdf              = false;

  // Forms
  searchForm = this.formBuilder.group({
    searchNumber:       [null, [Validators.required]],
    programMobileAppId: [null, [Validators.required]],
  });

  searchTableForm = this.formBuilder.group({
    cardNumber:        [null],
    cardBarcode:       [null],
    programName:       [null]
  });

  constructor (
    protected router:         Router,
    private formBuilder:      FormBuilder,
    private uiEvent:          UIEventCustom,
    private programService:   CardProgramServiceService,
    private barcodeService:   BarcodeService,
    private cardService:      CustomerCardService) {
      super(); 
  }

  ngOnInit() {
    this.reloadInitialData();
  }

  ngOnDestroy() {
    this.cards = [];
    this.searchForm.reset();
    this.searchTableForm.reset();
  }

  private reloadInitialData() {
    this.reloadPrograms();
    this.reloadPartnerCards();
    this.reloadPartnerCardsCount();
    this.reloadAllPartnerBarcodes();
  }

  onSubmit() {
    this.reloadPartnerCards();
    this.reloadPartnerCardsCount();
  }

  clearFilters() {
    this.searchForm.reset();
  }

  onSubmitSearchFormSort(event: any) {
    const sortBy: keyof ListCustomerCards = event.sortBy;
    const sortAsc: boolean = event.sortAsc;
    if (sortAsc == null || sortAsc == undefined) {
      this.cards = this.copyCards;
      return;
    }
    this.cards.sort((a: ListCustomerCards, b: ListCustomerCards) => {
      if (a[sortBy] < b[sortBy]) {
          return sortAsc ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
          return sortAsc ? 1 : -1;
      }
      return 0;
    });
  }
  
  private reloadAllPartnerBarcodes() {
    this.cardService.findAllPartnerBarcodes().subscribe(data => {
      this.allBarcodes = data;
    });
  }
  
  private reloadPrograms() {
    this.programService.findAll().toPromise().then(programs => {
      this.programs = programs as LoyaltyProgramsEssential[];
    })
    .catch(err => displayError(err));
  }

  reloadData() {
    this.reloadPartnerCards();
    this.reloadPartnerCardsCount();
  }

  private reloadPartnerCards() {
    this.dataLoaded = false;
    this.cardService.getPagablePartnerCustomerCards(this.sortingPaging, this.searchForm.value).subscribe(page => {
      this.sortingPaging.fromRow = page.fromRow;
      this.sortingPaging.toRow = page.toRow;
      this.content = page.content;
      this.cards = this.content;
      this.copyCards = this.content;
      this.dataLoaded = true;
    });
  }

  private reloadPartnerCardsCount() {
    this.cardService.getTotalPartnerCustomerCards(this.sortingPaging, this.searchForm.value).subscribe(totalCount => {
      this.sortingPaging.totalElements = totalCount;
    });
  }

  async generateSingleCardBarcode(barcode: string) {
    if (!barcode) return;
    this.loadPdf = true;
    this.barcodeService.downloadPdfSingleBarcode(barcode)
    .pipe(
      finalize(() => {
        this.loadPdf = false;
      })
    ).subscribe(
      data => this.triggerDownload(data, 'barcode-' + barcode + '.pdf'),
      error => this.handleError(error)
    );
  }

  async generateMultipleCardBarcodes() {
    if (!this.allBarcodes) return;
    this.loadPdf = true;
    this.barcodeService.downloadPdfWithMultipleBarcodes(this.allBarcodes).pipe(
      finalize(() => {
        this.loadPdf = false;
      })
    )
    .subscribe(
      data => this.triggerDownload(data, 'multiple-barcodes.pdf'),
      error => this.handleError(error)
    );
  }

  private triggerDownload(data: BlobPart, filename: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  private handleError(error: any) {
    this.uiEvent.displayUIError('Възникна грешка при изтегляне на pdf');
  }

  get getForm() {
    return this.searchForm.controls;
  }

}
