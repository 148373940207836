import { CustomerDiscount } from "../../_models/customer-discount.model";
import { VersionData } from "../../_models/version-data.model";
import { Status } from "../../_models/status.model";
import { LoyaltyProgramTypes } from "./loyalty-program-types.model";
import { LoyaltyProgramConditions } from "./loyalty-program-conditions.model";
import { SubjectVersion } from "@models/subject-version.model";

export class LoyaltyPrograms {
    id:                 number;
    discount?:          CustomerDiscount;
    impulseDiscount?:   CustomerDiscount | null;
    type:               LoyaltyProgramTypes;
    versionData?:       VersionData;
    status:             Status;
    conditions?:        LoyaltyProgramConditions[];
    mobileAppId?:       number;
    programName:        string;
    validFrom?:         Date;
    validTo?:           Date;
    subjectVersion:     SubjectVersion | null;
}