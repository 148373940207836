import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { environment } from "@env/environment";
import { SortingPaging } from "@helpers/sorting-paging";
import { Page } from "@interfaces/page";
import { EMPTY, forkJoin, Observable } from "rxjs";
import { defaultIfEmpty } from "rxjs/operators";
import { ListRole } from "../_models/list-role.model";
import { Permission } from "../_models/permission.model";
import { Role } from "../_models/role.model";
import { ChangePasswordRequest, User } from "@app/security/_models/user.model";
import { ListUser } from "@app/security/_models/list-user.model";
import { SortingPagingData } from "@helpers/sorting-paging-data";

const apiServerUrl     = environment.apiBaseURL;
const securityUrl      = apiServerUrl + '/security';

const pageableRolesTotalElements    = securityUrl + '/authority/pageable/count';
const pageableRoles                 = securityUrl + '/authority/pageable';
const findRoleByIdURL               = securityUrl + '/authority/find/{id}';
const saveRoleURL                   = securityUrl + '/authority/save';
const allRolesUrl                   = securityUrl + '/authority/list';
const allValidRolesUrl              = securityUrl + '/authority/valid';

const saveUserUrl                   = securityUrl + '/users';
const findUserById                  = securityUrl + '/users/{id}';
const pageableUsersTotalElements    = securityUrl + '/users/pageable/count';
const pageableUsers                 = securityUrl + '/users/pageable';
const changePasswordUrl             = securityUrl + '/users/change-password';

const permissionsUrl                = securityUrl + '/permission/list';

@Injectable({
    providedIn: 'root'
  })
  export class SecurityService {
  
    constructor(
      private http: HttpClient
    ) { }

    public getAllRoles(): Observable<Role[]> {
      return this.http.get<Role[]>(allRolesUrl);
    }

    public getAllValidRoles(): Observable<Role[]> {
      return this.http.get<Role[]>(allValidRolesUrl);
    }

    public getPermissions(): Observable<Permission[]> {
      return this.http.get<Permission[]>(permissionsUrl);
    }

    public pageableRoles(sortingPaging: SortingPagingData, searchForm: any) {
      return forkJoin([
        this.findAllRolesByFilter(sortingPaging, searchForm),
        this.countAllRolesByFilter(sortingPaging, searchForm)
      ]);
    }

    public findAllRolesByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<ListRole>> {
      return this.http.get<Page<ListRole>>(pageableRoles, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
    }
  
    public countAllRolesByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
      return this.http.get<number>(pageableRolesTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
    }

    public findRoleById(id: number): Observable<Role> {
      if (id === undefined || id === null) {
        return EMPTY.pipe(defaultIfEmpty());
      }
      return this.http.get<Role>(findRoleByIdURL.replace('{id}', `${id}`));
    }

    public saveRole(role: Role): Observable<any> {
      return this.http.post(saveRoleURL, role);
    }

    public saveUser(user: User): Observable<any> {
      return this.http.post(saveUserUrl, user);
    }

    public findUserById(id: number): Observable<User> {
      if (id == null) {
        return EMPTY.pipe(defaultIfEmpty())
      }

      return this.http.get<User>(findUserById.replace("{id}", id.toString()));
    }

    public findUserAndDeactiveById(id: number) {
      if (id == null || id === 0) {
        return EMPTY.pipe(defaultIfEmpty())
      }

      return this.http.delete<User>(findUserById.replace("{id}", id.toString()));
    }

    public changePassword(request: ChangePasswordRequest) {
      return this.http.post(changePasswordUrl, request);
    }

    public pageableUsers(sortingPaging: SortingPagingData, searchForm: any) {
      return forkJoin([
        this.findAllUsersByFilter(sortingPaging, searchForm),
        this.countAllUsersByFilter(sortingPaging, searchForm)
      ]);
    }

    public findAllUsersByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<ListUser>> {
      return this.http.get<Page<ListUser>>(pageableUsers, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
    }
  
    public countAllUsersByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
      return this.http.get<number>(pageableUsersTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm) });
    }

    private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchObject: FormGroup) {
      let requestParams = new HttpParams();
  
      if (sortingPaging != null) {
        requestParams = requestParams.set("page", sortingPaging.getPageNumber());
        requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
      }

      if (sortingPaging.isSortingValid()) {
        requestParams = requestParams
          .set("sortBy", sortingPaging.sortBy)
          .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
      }
  
      Object.entries(searchObject).forEach(item => {
        if (item[1] && item[1].length !== 0) {
          requestParams = requestParams.set(item[0], item[1]);
        }
      });
  
      return requestParams;
    }

  }