import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '@env/environment';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { AppComponent } from '@app/app.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from '@app/app-routing.module';
import { MapComponent } from './_components/map/map.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './login/_helpers/auth-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './home/_components/home/home.component';
import { LoginComponent } from './login/_components/login/login.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { NgbDateCustomParserFormatter } from '@helpers/ng-date-custom-parser-formatter';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { BaseSearchComponent } from './_components/_base/base-search/base-search.component';
import { ListServicesComponent } from '@app/services/_components/list-services/list-services.component';
import { ListCustomersComponent } from '@app/customers/_components/list-customers/list-customers.component';
import { AddEditServiceComponent } from '@app/services/_components/add-edit-service/add-edit-service.component';
import { SearchPersonComponent } from '@components/register-of-legal-persons-modal/search-person/search-person.component';
import { YesNoMessageboxComponent } from './_components/_base/_messageboxes/yes-no-messagebox/yes-no-messagebox.component';
import { ContractRvsComponent } from '@app/contracts/_components/add-edit-client-contract/contract-rvs/contract-rvs.component';
import { AddEditPersonComponent } from '@components/register-of-legal-persons-modal/add-edit-person/add-edit-person.component';
import { WarningMessageboxComponent } from './_components/_base/_messageboxes/warning-messagebox/warning-messagebox.component';
import { ListClientContractsComponent } from '@app/contracts/_components/list-client-contracts/list-client-contracts.component';
import { ListServiceConfigsComponent } from './service-configs/_components/list-service-configs/list-service-configs.component';
import { ClientContractComponent } from '@app/contracts/_components/add-edit-client-contract/add-edit-client-contract.component';
import { AddCustomerSubscriptionComponent } from './_components/add-customer-subscription/add-customer-subscription.component';
import { AddEditClientProfileComponent } from './customers/_components/add-edit-client-profile/add-edit-client-profile.component';
import { ListTechnicalPointsComponent } from '@app/tech-points/_components/list-technical-points/list-technical-points.component';
import { ContractSlotsComponent } from '@app/contracts/_components/add-edit-client-contract/contract-slots/contract-slots.component';
import { AddEditTechnicalPointComponent } from '@app/tech-points/_components/add-edit-technical-point/add-edit-technical-point.component';
import { AddEditServiceConfigComponent } from '@app/service-configs/_components/add-edit-service-config/add-edit-service-config.component';
import { RegisterOfLegalPersonsModalComponent } from '@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component';
import { ContractSubjectsComponent } from '@app/contracts/_components/add-edit-client-contract/contract-subjects/contract-subjects.component';
import { CustomerDiscountComponent } from '@app/contracts/_components/add-edit-client-contract/customer-discount/customer-discount.component';
import { ContractServicesComponent } from '@app/contracts/_components/add-edit-client-contract/contract-services/contract-services.component';
import { ClientProfileRvsComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-rvs/client-profile-rvs.component';
import { ClientProfileCardsComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-cards/client-profile-cards.component';
import { TechnicalPointLinesComponent } from './tech-points/_components/add-edit-technical-point/technical-point-lines/technical-point-lines.component';
import { ListTechnicalInspectionsComponent } from '@app/technical-inspections/_components/list-technical-inspections/list-technical-inspections.component';
import { TechnicalPointServicesComponent } from './tech-points/_components/add-edit-technical-point/technical-point-services/technical-point-services.component';
import { ClientProfileRemindersComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-reminders/client-profile-reminders.component';
import { ContractTechnicalPointsComponent } from '@app/contracts/_components/add-edit-client-contract/contract-technical-points/contract-technical-points.component';
import { AddEditTechnicalInspectionComponent } from '@app/technical-inspections/_components/add-edit-technical-inspection/add-edit-technical-inspection.component';
import { PartnerContractServicesComponent } from '@app/contracts/_components/add-edit-client-contract/partner-contract-services/partner-contract-services.component';
import { ClientProfilePromoCodesComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-promo-codes/client-profile-promo-codes.component';
import { ClientProfileInvoiceDataComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-invoice-data/client-profile-invoice-data.component';
import { ClientProfilePurchaseHistoryComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-purchase-history/client-profile-purchase-history.component';
import { ClientProfileNotificationsHistoryComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-notifications-history/client-profile-notifications-history.component';
import { ClientProfileNotificationsSettingsComponent } from '@app/customers/_components/add-edit-client-profile/client-profile-notifications-settings/client-profile-notifications-settings.component';
import { AddEditPromotionComponent } from './promotions/add-edit-promotion/add-edit-promotion.component';
import { PromotionServicesComponent } from './promotions/add-edit-promotion/promotion-services/promotion-services.component';
import { PromotionTechnicalPointsComponent } from './promotions/add-edit-promotion/promotion-technical-points/promotion-technical-points.component';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { ListRolesComponent } from './security/_components/list-roles/list-roles.component';
import { AddEditRoleComponent } from './security/_components/add-edit-role/add-edit-role.component';
import { AddEditUserComponent } from './security/_components/add-edit-user/add-edit-user.component';
import { ListUsersComponent } from './security/_components/list-users/list-users.component';
import { PromotionFriendsConfigComponent } from './promotions/add-edit-promotion/promotion-friends-config/promotion-friends-config.component';
import { ListPromotionsComponent } from './promotions/add-edit-promotion/list-promotions/list-promotions.component';
import { ExportAsModule } from 'ngx-export-as';
import { ListAccountingDocumentsComponent } from './accounting_documents/_components/list-accounting-documents/list-accounting-documents.component';
import { AddEditAccountingDocumentComponent } from './accounting_documents/_components/add-edit-accounting-document/add-edit-accounting-document.component';
import { AddEditTechnicalInspectionCcComponent } from './technical-inspections-cc/_components/add-edit-technical-inspection-cc/add-edit-technical-inspection-cc.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './user-profile/change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordPopupComponent } from './reset-password/reset-password-popup/reset-password-popup.component';
import { StatisticsInspectionsComponent } from './reports/statistics-inspections/statistics-inspections.component';
import { DailyServiceReportComponent } from './reports/daily-service-report/daily-service-report.component';
import { NgdbSortableHeaderDirective } from '@helpers/directives/ngdb-sortable-header.directive';
import { CustomerDiscountsComponent } from './customer-discounts/customer-discounts.component';
import { ListCustomerDiscountsComponent } from './customer-discounts/list-customer-discounts/list-customer-discounts.component';
import { ExpiringServicesComponent } from './reports/expiring-services/expiring-services.component';
import { DailyServiceReportSingleComponent } from './reports/daily-service-report-by-cars/daily-service-report-single.component';
import { TechnicalInspectionSlotsComponent } from './reports/technical-inspection-slots/technical-inspection-slots.component';
import { IncomeByServicesComponent } from './reports/income-by-services/income-by-services.component';
import { IncomeByStickersComponent } from './reports/income-by-stickers/income-by-stickers.component';
import { AddProgram } from './card-config/_components/add-program/add-program.component';
import { SearchProgramCardLoyalComponent } from './card-config/_components/search-program-card-loyal/search-program-card-loyal.component';
import { SearchCardLoyalComponent } from './card-config/_components/search-card-loyal/search-card-loyal.component';
import { GeneratorCardLoyalComponent } from './card-config/_components/generator-card-loyal/generator-card-loyal.component';
import { CardDetailComponent } from './card-config/_components/card-detail/card-detail.component';
import { SitePageManagementComponent } from './page-management/site-page-management/site-page-management.component';
import { ListSitePageManagementComponent } from './page-management/list-site-page-management/list-site-page-management.component';
import { SearchPartnerProgramComponent } from './card-config/_components/search-partner-program/search-partner-program.component';
import { GeneratorPartnerCardComponent } from './card-config/_components/generator-partner-card/generator-partner-card.component';
import { GeneratorUpdatePartnerCardComponent } from './card-config/_components/generator-update-partner-card/generator-update-partner-card.component';
import { TrimWhitespaceDirective } from './_helpers/directives/trim-whitespace.directive';

export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Откажи'
  setBtnLabel = 'Избери'
};

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    HomeComponent,
    LoginComponent,
    BaseChildComponent,
    BaseSearchComponent,
    BaseParentComponent,
    ContractRvsComponent,
    SearchPersonComponent,
    ListServicesComponent,
    ListCustomersComponent,
    ContractSlotsComponent,
    AddEditPersonComponent,
    AddEditServiceComponent,
    ClientContractComponent,
    ClientContractComponent,
    YesNoMessageboxComponent,
    ContractSubjectsComponent,
    CustomerDiscountComponent,
    ContractServicesComponent,
    ClientProfileRvsComponent,
    AddEditPromotionComponent,
    PromotionServicesComponent,
    WarningMessageboxComponent,
    ClientProfileCardsComponent,
    ListServiceConfigsComponent,
    TechnicalPointLinesComponent,
    ListClientContractsComponent,
    ListTechnicalPointsComponent,
    AddEditClientProfileComponent,
    AddEditServiceConfigComponent,
    AddEditTechnicalPointComponent,
    ClientProfileRemindersComponent,
    TechnicalPointServicesComponent,
    PartnerContractServicesComponent,
    ListAccountingDocumentsComponent,
    AddCustomerSubscriptionComponent,
    ClientProfilePromoCodesComponent,
    ContractTechnicalPointsComponent,
    ListTechnicalInspectionsComponent,
    PromotionTechnicalPointsComponent,
    ClientProfileInvoiceDataComponent,
    AddEditAccountingDocumentComponent,
    AddEditTechnicalInspectionComponent,
    RegisterOfLegalPersonsModalComponent,
    ClientProfilePurchaseHistoryComponent,
    ClientProfileNotificationsHistoryComponent,
    ClientProfileNotificationsSettingsComponent,
    ListRolesComponent,
    AddEditRoleComponent,
    NgdbSortableHeaderDirective,
    AddEditUserComponent,
    ListUsersComponent,
    PromotionFriendsConfigComponent,
    ListPromotionsComponent,
    AddEditTechnicalInspectionCcComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ResetPasswordPopupComponent,
    StatisticsInspectionsComponent,
    DailyServiceReportComponent,
    CustomerDiscountsComponent,
    ListCustomerDiscountsComponent,
    ExpiringServicesComponent,
    DailyServiceReportSingleComponent,
    TechnicalInspectionSlotsComponent,
    IncomeByServicesComponent,
    IncomeByStickersComponent,
    AddProgram,
    SearchProgramCardLoyalComponent,
    SearchCardLoyalComponent,
    GeneratorCardLoyalComponent,
    CardDetailComponent,
    SitePageManagementComponent,
    ListSitePageManagementComponent,
    SearchPartnerProgramComponent,
    GeneratorPartnerCardComponent,
    GeneratorUpdatePartnerCardComponent,
    TrimWhitespaceDirective
  ],
  imports: [
    NgbModule,
    FormsModule,
    BrowserModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    ExportAsModule,
    OwlNativeDateTimeModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      tapToDismiss: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'bg',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [
    NgdbSortableHeaderDirective,
  ],
  providers: [
    { provide: OwlDateTimeIntl,             useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_LOCALE,        useValue: 'bg'}, 
    { provide: APP_BASE_HREF,               useValue: environment.webPath },
    { provide: HTTP_INTERCEPTORS,           useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS,  useValue: {disableClose: true, hasBackdrop: true} },
    { provide: NgbDateParserFormatter,      useClass: NgbDateCustomParserFormatter },
    DatePipe,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environment.webPath + 'assets/i18n/' , '.json');
}