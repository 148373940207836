<div class="form-group form-row" [formGroup]="contractServicesForm">
    <div class="col text-left">
        <button class="btn btn-outline-success ml-2" type="button" (click)="addAll()" translate> button.fillAll</button>
    </div>
    <div class="col text-right">
        <button class="btn btn-outline-success ml-2" type="button" (click)="addRow()" translate>button.add</button>
    </div>


    <table class="table table-bordered table-striped w-100">
        <thead>
            <tr>
                <th scope="col" style="width:25vw" translate>ktp</th>
                <th scope="col" style="width:20vw" translate>contract.service</th>
                <th scope="col" style="width:10vw" translate>contract.paymentType</th>
                <th scope="col" style="width:10vw" translate>contract.card</th>
                <th scope="col" style="width:10vw" translate>contract.value</th>
                <th scope="col" style="width:7vw" translate>contract.withoutDds</th>
                <th scope="col" style="width:7vw" translate>contract.dds</th>
                <th scope="col" style="width:7vw" translate>contract.finalAmount</th>
                <th scope="col" style="width:5vw" translate class="text-center">button.delete</th>
            </tr>
        </thead>

        <tbody formArrayName="rows">
            <small class="text-danger" *ngIf="rows?.errors && showValidations" translate>
                errors.atLeastOneService
            </small>
            <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">

                <tr *ngIf="row.get('isValid')?.value">
                    <td>
                        <select class="custom-select" formControlName="technicalPoint"
                            (change)='onTechPointSelect($event, i)'
                            [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('technicalPoint')?.errors }">
                            <option *ngFor="let technicalPoint of selectedTechnicalPoints" [value]="technicalPoint.id">
                                {{technicalPoint.name}}
                        </select>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('technicalPoint')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                    </td>
                    <td>
                         <select class="custom-select" formControlName="technicalPointService"
                            [compareWith]="compareById" (ngModelChange)="onServiceSelect($event, i)"
                            [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('technicalPointService')?.errors }">
                            <option *ngFor="let service of rows.controls[i]?.get('techPointServices')?.value"
                                [ngValue]="service">
                                {{service.serviceName}}
                        </select>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('technicalPointService')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                    </td>
                    <td>
                        <select class="custom-select" formControlName="paymentType" [compareWith]="compareByCode"
                            [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('paymentType')?.errors }">
                            <option *ngFor="let paymentType of servicePaymentTypes" [value]="paymentType.code">
                                {{paymentType.name}}
                        </select>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('paymentType')?.errors?.required && showValidations" translate>
                            errors.required
                        </small>
                    </td>
                    <td>
                        <select class="custom-select" *ngIf="rows.controls[i]?.get('paymentType')?.value=='C'"
                            formControlName="serviceAddInfo" [compareWith]="compareById">
                            <option *ngFor="let serviceAddInfo of rows.controls[i]?.get('serviceAddInfoList')?.value"
                                [ngValue]="serviceAddInfo">
                                {{serviceAddInfo.service.name}}
                        </select>
                        <!-- <button class="btn btn-danger btn-sm rounded-0" type="button" (click)="updateServiceAddInfo(i)" 
                            title="Избраната карта не е актуална версия. Натиснете, за да обновите!"
                            *ngIf="rows.controls[i]?.get('serviceAddInfo')?.value != null && !rows.controls[i]?.get('serviceAddInfo')?.value?.versionData.isActual" translate>
                            !
                        </button> -->
                        <div *ngIf="rows.controls[i]?.get('serviceAddInfo')?.value != null && !rows.controls[i]?.get('serviceAddInfo')?.value?.versionData.isActual">
                            <i class="fa fa-exclamation-triangle mr-2 text-warning" style="font-size: 25pt;" 
                            title="{{'contract.cardOldVersion' | translate}}" aria-hidden="true"></i>
                            <i class="fa fa-refresh ml-2" style="font-size: 25pt; cursor: pointer;" title="{{'refresh' | translate}}" 
                            (click)="updateServiceAddInfo(i)" aria-hidden="true"></i>
                        </div>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('serviceAddInfo')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                    </td>
                    <td formGroupName="priceAttribute">
                        <select class="custom-select" formControlName="kind" [compareWith]="compareByCode"
                        (ngModelChange)="onKindSelect($event, i)"
                            [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('paymentType')?.errors }">
                            <option *ngFor="let kind of priceAttributeKinds" [value]="kind.code">
                                {{kind.name}}
                        </select>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('priceAttribute')?.get('kind')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                    </td>
                    <td formGroupName="priceAttribute">
                        <input type="text" class="form-control" formControlName="amount" (change)="calculatePriceAmountDds(i)">
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('priceAttribute')?.get('amount')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('priceAttribute')?.get('amount')?.errors?.pattern && showValidations"
                            translate>
                            errors.onlyPositiveNumbers
                        </small>
                    </td>
                    <td formGroupName="priceAttribute">
                        <select class="form-control w-100" formControlName="ddsValue" (change)="calculatePriceAmountDds(i)">
                            <option *ngFor="let vatGroup of VAT_GROUPS" [ngValue]="vatGroup"> {{vatGroup}}
                        </select>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('priceAttribute')?.get('ddsValue')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                    </td>
                    <td formGroupName="priceAttribute">
                        <input type="text" class="form-control" formControlName="amountDds" readonly>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('priceAttribute')?.get('amountDds')?.errors?.required && showValidations"
                            translate>
                            errors.required
                        </small>
                        <small class="text-danger"
                            *ngIf="rows.controls[i]?.get('priceAttribute')?.get('amountDds')?.errors?.pattern && showValidations"
                            translate>
                            errors.onlyPositiveNumbers
                        </small>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-danger btn-sm rounded-0" type="button" (click)="deleteRow(i)"><em
                                class="fa fa-trash"></em></button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>