import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { displayError } from '@app/_utils/error-util';
import { SitePageManagement } from '../model/site-page-management';
import { SitPageManagementService } from '../services/sit-page-management.service';

@Component({
  selector: 'app-list-site-page-management',
  templateUrl: './list-site-page-management.component.html',
  styleUrls: ['./list-site-page-management.component.css']
})
export class ListSitePageManagementComponent extends BaseSortableSearchComponent<SitePageManagement> implements OnInit, OnDestroy {
  
  // Units
  sitePageManagements: SitePageManagement[];
  
  // Form
  searchForm = this.formBuilder.group({
    pageNumber:   null,
    isActive:     null,
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.sitePageManagementService.pageableCountSite(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          
          this.dataLoaded = true;
        }),
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
  );

  constructor(public perms: PermissionsService,
    private sitePageManagementService:  SitPageManagementService,
    private formBuilder:                FormBuilder,
    private translateService:           TranslateService) { 
      super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

}
