import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContractService } from "@app/contracts/_services/contract.service";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { CustomerDiscount } from "@models/customer-discount.model";

@Component({
    selector: 'customer-discount',
    templateUrl: './customer-discount.component.html',
    styleUrls: ['./customer-discount.component.css']
})
export class CustomerDiscountComponent extends BaseChildComponent implements OnInit, OnDestroy {
    // Units
    discounts: CustomerDiscount[];

    // Constants
    private readonly DISCOUNT_TYPE_CONTRACT = 'contract';

    // Decorators
    @Input() customerDiscountId: number;
    @Output() selectCustomerDiscount: EventEmitter<CustomerDiscount> = new EventEmitter<CustomerDiscount>();

    // Form
    customerDiscountForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private contractService: ContractService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.customerDiscountForm = this.formBuilder.group({
            customerDiscount:   [null, Validators.required],
            priceAttribute:     { value: '', disabled: true }
        });

        this.customerDiscountForm?.get('customerDiscount')?.valueChanges.subscribe(value => {
            if (!!this.discounts) {
                this.customerDiscountForm.patchValue({
                    priceAttribute: this.discounts
                        .find(d => d.id == value.id)?.priceAttribute.amount
                });
                this.selectCustomerDiscount.emit(value);
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    async getDiscount() {
        if (this.isInitialized) return;
        this.isInitialized = true;
        
        const response = await this.contractService.findDiscountList().toPromise();
        this.discounts = this.filterDiscountsByType(response);
        if (!!this.customerDiscountId) {
            this.customerDiscountForm.patchValue({ customerDiscount: { id: this.customerDiscountId } });
            this.customerDiscountForm.patchValue({
                priceAttribute: this.discounts
                    .find(d => d.id == this.customerDiscountId)?.priceAttribute.amount
            });
            this.selectCustomerDiscount.emit(this.discounts
                .find(d => d.id == this.customerDiscountId));
        }
    }

    private filterDiscountsByType(discounts: CustomerDiscount[]) {
        return discounts.filter((discount) => discount.customerDiscountType?.some((cdt) => cdt.discountType.code === this.DISCOUNT_TYPE_CONTRACT));
      }

    get form() { return this.customerDiscountForm.controls; }

    // isValid(): boolean {
    //     if (this.isInitialized) {
    //         return this.customerDiscountForm.valid
    //     }
    //     return true;
    // }
}