import { Injectable } from '@angular/core';
import { AccountingDocumentService } from '@app/accounting_documents/_services/accounting-document.service';
import { pdfConf } from '@env/config';
import { base64Fonts } from '@helpers/fonts';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';
const BORDER = 15;

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  
  constructor(
    private translateService: TranslateService,
    private accountingDocumentService: AccountingDocumentService
  ) { }
  
  public exportAsExcelFile(data: any[], heading: string[][], filterBody: any[], filterHeading: string[][], excelFileName: string, total?: any, defaultStandartHeading?: boolean): void {
    
    const myworksheet = XLSX.utils.book_new();
    if (!defaultStandartHeading) {
      heading[0].push("");
      heading[0].push(this.translateService.instant('exportData.filters.chosenFilters'));
      heading[0].push(this.translateService.instant('exportData.filters.value'));
    }

    XLSX.utils.sheet_add_aoa(myworksheet, heading, {});

    let filter : any[][] = [];
    filter.push([]);
    if (filterHeading && filterHeading.length > 0) {
      for (let i = 0; i < filterHeading[1].length; i++) {
          filter.push([]);
          filter[1 + i].push(filterHeading[1][i]);
          filter[1 + i].push(filterBody[0][i]);
      }
    }
    let filterStart = heading[0].length + 1;
    let ws = XLSX.utils.sheet_add_aoa(myworksheet, filter, {origin:{r:0, c:filterStart - 3}});
    ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
   
    XLSX.utils.sheet_add_json(myworksheet, data, { origin: 'A2', skipHeader: true});
    if (total != null) {
      XLSX.utils.sheet_add_aoa(myworksheet, total, {origin:{r:data.length + 1, c:4}});
    }
    
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet}, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportAsTxt(filePath: string) {
    this.accountingDocumentService.getTxtDocument(filePath).subscribe(response => {  
      var blob = new Blob([response], {
         type: "application/text"
      });

      const fileUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.download = "filename.txt";
      link.href = fileUrl; 
      link.click();
    });
  }

  exportAsPdfFile(body: any[], heading: string[][], filterBody: any[], filterHeading: string[][], fileName: string, date: string | null, total?: string, pageFormat?: string, isFilterBig?: boolean) {    
    let fullFileName = fileName + ' ' + date;
    let pdf = this.createPdf("landscape", pageFormat).setFont(pdfConf.font.default);

    let pageW = pdf.internal.pageSize.getWidth();
    if (isFilterBig) {
      this.addHeadingTwoLine(pdf, pageW, filterHeading, filterBody, fullFileName);
    } else {
      this.addHeading(pdf, pageW, filterHeading, filterBody, fullFileName);
    }
    pdf.setTextColor(99);
    (pdf as any).autoTable({
        startY: (pdf as any).lastAutoTable.finalY + 10,
        head: heading,
        body: body,
        styles: { font: pdfConf.font.default },
        theme: 'striped',
    })
    if (total != null && total != undefined) {
      this.addFooting(pdf, pageW, filterHeading, filterBody, total);
    }

    pdf.save(fileName + PDF_EXTENSION)
  }

  private createPdf(orientation: "portrait" | "landscape", pageFormat?: string): jsPDF {
    let pdf = new jsPDF({
        format: pageFormat == 'a0' ? 'a0' : 'a4',
        orientation: orientation,
        unit: 'mm'
    });
    pdf.addFileToVFS('OpenSans-Regular-normal.ttf', base64Fonts.openSansRegular);
    pdf.addFont('OpenSans-Regular-normal.ttf', 'OpenSans', 'normal');

    return pdf;
  }

  private addHeading(pdf: jsPDF, pageW: number, filterHeading: string[][], filterBody: any[], fileName: string) {
    pdf.setFontSize(15);
    pdf.text(fileName, pageW / 2, BORDER, { align: 'center' });
    
    pdf.setTextColor(99);
    (pdf as any).autoTable({
        startY: BORDER + 3,
        head: filterHeading,
        body: filterBody,
        styles: { font: pdfConf.font.default },
        theme: 'striped',
    })
  }

  private addHeadingTwoLine(pdf: jsPDF, pageW: number, filterHeading: string[][], filterBody: any[], fileName: string) {
    pdf.setFontSize(15);
    pdf.text(fileName, pageW / 2, BORDER, { align: 'center' });
    const middleIndex = Math.ceil(filterHeading[1].length / 2);
    const middleIndex2 = Math.ceil(filterBody[0].length / 2);
    let filterHeadingFirstHalf = [filterHeading[0], filterHeading[1].splice(0, middleIndex)];
    let filterHeadingSecondHalf = [filterHeading[0], filterHeading[1].splice(-middleIndex)];
    let filterBodyFirstHalf = [filterBody[0].splice(0, middleIndex2)];
    let filterBodySecondHalf = [filterBody[0].splice(-middleIndex2)];
    
    pdf.setTextColor(99);
    (pdf as any).autoTable({
        startY: BORDER + 3,
        head: filterHeadingFirstHalf,
        body: filterBodyFirstHalf,
        styles: { font: pdfConf.font.default },
        theme: 'striped',
    })
    pdf.setTextColor(99);
    (pdf as any).autoTable({
        startY: (pdf as any).lastAutoTable.finalY + 5,
        head: filterHeadingSecondHalf,
        body: filterBodySecondHalf,
        styles: { font: pdfConf.font.default },
        theme: 'striped',
    })
  }

  private addFooting(pdf: jsPDF, pageW: number, filterHeading: string[][], filterBody: any[], total?: string) {
    pdf.setFontSize(15);
    pdf.text(total != undefined ? total : "-", pageW / 2, 200, { align: 'center' });
  }
}