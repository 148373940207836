<div class="container-fluid py-3" style="padding-left:10rem !important;padding-right:10rem !important">
    <div>
        <h1 class="form-group title-position" *ngIf="isClientContract()" translate>contract.clientContract</h1>
        <h1 class="form-group title-position" *ngIf="!isClientContract()" translate>contract.partnerContract</h1>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()" *ngIf="contract$ | async as data; else loading">
            <!-- ****************************************************** 
            ************** Data *************
            ****************************************************** -->
            <div id="accordion">
                <div class="card">
                    <!-- ****************************************************** 
                    ************** Core Contract Data *************
                    ****************************************************** -->
                    <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne" translate type="button">
                                contract.mainData
                            </button>
                        </h5>
                    </div>
                    <div id="collapseOne" class="collapse" [ngClass]="{'show': showFirstSection}" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <div class="form-group form-row">
                                <div class="col">
                                    <label translate>contract.contractNum</label>
                                    <input type="text" class="form-control" formControlName="docNumber" maxlength="32"
                                        [ngClass]="{ 'is-invalid': showValidations && form.docNumber.errors }"
                                        placeholder="{{'placeholder.enter' | translate}}">
                                    <small class="text-danger"
                                        *ngIf="form.docNumber?.errors?.required && showValidations" translate>
                                        errors.required
                                    </small>
                                    <small class="text-danger"
                                    *ngIf="form.docNumber?.errors?.maxlength && showValidations">
                                    {{'errors.inputTooLong' | translate : {max:32} }}
                                </small>
                                </div>
                                <div class="col">
                                    <label translate>contract.validFrom</label>
                                    <input type="date" class="form-control" formControlName="validFrom"
                                        [ngClass]="{ 'is-invalid': showValidations && form.validFrom.errors }">
                                    <small class="text-danger"
                                        *ngIf="form.validFrom?.errors?.required && showValidations" translate>
                                        errors.required
                                    </small>
                                </div>
                                <div class="col">
                                    <label translate>contract.validTo</label>
                                    <input type="date" class="form-control" formControlName="validTo"
                                        [ngClass]="{ 'is-invalid': showValidations && form.validTo.errors }">
                                    <small class="text-danger"
                                        *ngIf="form.validFrom?.errors?.required && showValidations" translate>
                                        errors.required
                                    </small>
                                    <small class="text-danger" *ngIf="addEditForm?.errors && showValidations" translate>
                                        errors.validFromToDates
                                    </small>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="col">
                                    <label translate>contract.contractType</label>
                                    <select class="custom-select input-custom-size" formControlName="contractKind"
                                    [ngClass]="{ 'is-invalid': showValidations && form.contractKind.errors }">
                                        <option *ngFor="let contractKind of contractKinds" [value]="contractKind.code">
                                            {{contractKind.name}}
                                    </select>
                                    <small class="text-danger"
                                        *ngIf="form.contractKind?.errors?.required && showValidations" translate>
                                        errors.required
                                    </small>
                                </div>
                                <div class="col">
                                    <label translate>contract.attachedToContractNum</label>
                                    <input type="text" class="form-control" formControlName="connectedTo" maxlength="32"
                                        placeholder="{{'placeholder.enter' | translate}}">
                                </div>
                                <div class="col">
                                    <label translate>status</label>
                                    <select class="custom-select" formControlName="status" [compareWith]="compareById">
                                        <option *ngFor="let status of statuses" [ngValue]="status"> {{status.name}}
                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-row" *ngIf="!isClientContract()">
                                <div class="col">
                                    <label translate>contract.invoicePeriod</label>
                                    <input type="date" class="form-control" formControlName="invoicePeriod">
                                </div>
                                <div class="col"></div>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Client Data *************
                    ****************************************************** -->
                    <!-- clientData -->
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo"
                                        type="button"
                                        (click)="initializeSection('clientData')"
                                        aria-expanded="false" aria-controls="collapseTwo" translate>
                                        contract.clientData
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseTwo" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingTwo"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <contract-subjects #clientData [ownerSubjectVersionId]="ownerSubjectVersionId"
                                        [contractSubjectVersionId]="contractSubjectVersionId"
                                        [showValidations$]="showFullValidation$" [module]="module">
                                    </contract-subjects>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Discounts *************
                    ****************************************************** -->
                    <div id="accordion" *ngIf="isClientContract()">
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree" translate type="button"
                                        (click)="initializeSection('customerDiscount')">
                                        contract.discounts
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseThree" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingThree"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <customer-discount #customerDiscount [customerDiscountId]="customerDiscountId"
                                    [showValidations$]="showFullValidation$" 
                                    (selectCustomerDiscount)="getSelectedCustomerDiscount($event)">
                                    </customer-discount>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Technical Point *************
                    ****************************************************** -->
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFour"
                                        (click)="initializeSection('technicalPoints')"
                                        aria-expanded="false" aria-controls="collapseFour" translate type="button">
                                        contract.technicalPoint
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseFour" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingFour"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <contract-technical-points #technicalPoints [contractId]="contractId"
                                    [showValidations$]="showFullValidation$"
                                        (selectTechPoints)="getSelectedTechPoints($event)">
                                    </contract-technical-points>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Services *************
                    ****************************************************** -->
                    <div *ngIf="isClientContract()" id="accordion">
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFive"
                                        (click)="initializeSection('contractServices')"
                                        aria-expanded="false" aria-controls="collapseFive" translate type="button">
                                        contract.services
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseFive" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingFive"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <contract-services #contractServices
                                        [selectedTechnicalPoints]="selectedTechnicalPoints" [contractId]="contractId"
                                        [selectedCustomerDiscount]="selectedCustomerDiscount"
                                        [showValidations$]="showFullValidation$" [module]="module">
                                    </contract-services>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isClientContract()" id="accordion">
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFive"
                                        (click)="initializeSection('partnerContractServices')"
                                        aria-expanded="false" aria-controls="collapseFive" translate type="button">
                                        contract.partnerServices
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseFive" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingFive"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <partner-contract-services #partnerContractServices
                                        [selectedTechnicalPoints]="selectedTechnicalPoints" [contractId]="contractId"
                                        [showValidations$]="showFullValidation$" [module]="module">
                                    </partner-contract-services>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Slots *************
                    ****************************************************** -->
                    <div id="accordion" *ngIf="isClientContract()">
                        <div class="card">
                            <div class="card-header" id="headingSix">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseSix"
                                        (click)="initializeSection('contractSlots')"
                                        aria-expanded="false" aria-controls="collapseSix" translate type="button">
                                        contract.slots
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseSix" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingSix"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <contract-slots #contractSlots [selectedTechnicalPoints]="selectedTechnicalPoints"
                                        [contractId]="contractId" [showValidations$]="showFullValidation$" [module]="module">
                                    </contract-slots>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ****************************************************** 
                    ************** Rvs *************
                    ****************************************************** -->
                    <div id="accordion" *ngIf="isClientContract()">
                        <div class="card">
                            <div class="card-header" id="headingSeven">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseSeven"
                                        (click)="initializeSection('contractRvs')"
                                        aria-expanded="false" aria-controls="collapseSeven" type="button" translate>
                                        contract.rvs
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseSeven" class="collapse" [ngClass]="{'show': showAllSections}" aria-labelledby="headingSeven"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <contract-rvs #contractRvs [contractId]="contractId"
                                    [showValidations$]="showFullValidation$" [module]="module"></contract-rvs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Save button -->
                <div class="text-right">
                    <button class="btn btn-primary w-20 mt-2" type="submit" *ngIf="canAddEdit" translate>button.save</button>
                </div>
            </div>
        </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <em class="fa fa-refresh" (click)="reload()"></em>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>