export interface YesNoEnum {
    code: boolean;
    name: string;
}


export const YES_NO: YesNoEnum[] = [
    { name: "Да",    code: true },
    { name: "Не",   code: false }
];
