import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
class UIEventCustom {
    protected showValidationsSubject = new BehaviorSubject<boolean>(false);

    constructor (private toastr: ToastrService, private translateService: TranslateService) { }

    /** 
     * @return {void}: Display successfull toast message
    */
    public displayUISuccess(message?: string): void {
        if (message) {
            this.toastr.success(this.translateService.instant(message));
            return;
        }
        this.toastr.success(this.translateService.instant('toast.successfulSave'));
    }

    /** 
     * @return {void}: Display successfull change toast message
    */
    public displayUISuccessChange(): void {
        this.toastr.success(this.translateService.instant('toast.successfulChange'));
    }
    
    /** 
     * @return {void}: Display unsuccessfull toast message
    */
    public displayUIError(message?: string, timeout?: number): void {
      this.showValidationsSubject.next(true);
      this.toastr.error(
          this.translateService.instant(message ? message : 'toast.validationError'),
          this.translateService.instant('toast.errorTitle'),
          { timeOut: timeout }
      );
    }
}

export { UIEventCustom }