
<div *ngIf="isInitialized; else promoCodesLoading">
<div class="card-body">
    <table *ngIf="promotionsCustomer.length > 0" class="table">
      <thead>
        <tr>
          <th>#</th>
          <th translate>clientProfile.promoCodeName</th>
          <th translate>clientProfile.promoCodeValid</th>
          <th translate>clientProfile.promoCodeStatus</th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let promotionCustomer of promotionsCustomer let i = index" >
          <td scope="row">{{i+1}}</td>
          <td>{{promotionCustomer?.promotion?.name}} </td>
          <td>{{promotionCustomer?.promotion?.promotionType?.isValid == true ? 'Да' : 'Не'}}</td>
          <td>{{promotionCustomer.status.name}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #promoCodesLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else promoCodesSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #promoCodesSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>