import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY } from 'rxjs';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { CustomerService } from '@app/customers/_services/customer.service';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { CustomerNotification } from '@app/customers/_models/customer-notification.model';

@Component({
  selector: 'app-client-profile-notifications-settings',
  templateUrl: './client-profile-notifications-settings.component.html',
  styleUrls: ['./client-profile-notifications-settings.component.css']
})
export class ClientProfileNotificationsSettingsComponent extends BaseChildComponent implements OnInit {
  notificationsCustomer: CustomerNotification[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private translateService: TranslateService
  ) {
    super();
   }

  ngOnInit(): void { }

  initialize() {
    if (this.isInitialized) return;

    this.customerService.findNotificationsByCustomerId(this.parentId).pipe(
      tap(data => this.notificationsCustomer = data),
      catchError(err => {
        console.log(err)
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true})
  }
}
