import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { PasswordValidator } from '@app/_validators/password.validator';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SecurityService } from '@app/security/_services/security.service';
import { ChangePasswordRequest } from '@app/security/_models/user.model';
import { UIEventCustom } from '@app/_utils/ui-event-util';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseParentComponent implements OnInit, OnDestroy {
  // Decorators
  @Output() closeDialog = new EventEmitter<boolean>();

  // Booleans
  loadComponent = false;
  showAddOldPassword = true;
 
  // Forms
  passwordForm: any;

  // Payload
  userIdFromRequestAdmin = 0;

  constructor(
    private authService:      AuthenticationService, 
    private router:           Router,
    private formBuilder:      FormBuilder, 
    private toastr:           ToastrService,
    private translateService: TranslateService,
    private uiEvent:          UIEventCustom,
    private securityService:  SecurityService) { 
      super(router);
    }

  ngOnInit() {
    this.loadComponent = false;

    const userIdFromEditUserComponent = localStorage.getItem('edit-user-pass');
    if (userIdFromEditUserComponent) {
      this.userIdFromRequestAdmin = Number.parseInt(userIdFromEditUserComponent);
      this.showAddOldPassword = false;
    } else {
      this.showAddOldPassword = true;
    }

    this.initForm();

    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data
    });

    this.loadComponent = true;
  }

  ngOnDestroy() {
    localStorage.removeItem('edit-user-pass');
  }

  private initForm() {
    type FormControls = {
      newPassword: (string | (((control: AbstractControl) => ValidationErrors | null) | ((control: FormControl) => { strong: boolean; } | null))[])[]; 
      repeatNewPassword: (string | (((control: AbstractControl) => ValidationErrors | null) | ((control: FormControl) => { strong: boolean; } | null))[])[]; 
      currPassword?: (string | (((control: AbstractControl) => ValidationErrors | null) | ((control: FormControl) => { strong: boolean; } | null))[])[]; 
    };
    
    const formControls: FormControls = {
      newPassword: ['', [Validators.required, Validators.maxLength(50), PasswordValidator.isStrong]],
      repeatNewPassword: ['', [Validators.required, Validators.maxLength(50), PasswordValidator.isStrong]]
    };

    if (this.showAddOldPassword) {
      formControls.currPassword = ['', [Validators.required, Validators.maxLength(50)]];
    }

    this.passwordForm = this.formBuilder.group(formControls, {
      validator: PasswordValidator.MustMatch('newPassword', 'repeatNewPassword')
    } as AbstractControlOptions);
  }
  
  public changePassword() {
    if (!this.validate()) {
      this.showValidationsSubject.next(true);
      
      this.toastr.error(
        this.translateService.instant('toast.validationError'),
        this.translateService.instant('toast.errorTitle')
      );
  
      return;
    }

    if (!this.userIdFromRequestAdmin) {
      this.authService.changePassword(this.passwordForm.get('currPassword')?.value, this.passwordForm.get('newPassword')?.value).subscribe();
    } else {
      const request: ChangePasswordRequest = {
        userId: this.userIdFromRequestAdmin,
        newPassword: this.passwordForm.get('newPassword')?.value
      }
      
      this.securityService.changePassword(request).subscribe(
        () => {
          this.uiEvent.displayUISuccess('Успешна смяна на парола');
          localStorage.removeItem('edit-user-pass');
          this.closeDialog.emit(true);
        },
        () => {
          this.uiEvent.displayUIError('Възникна грешка при смяна на паролата');
        }
      );
      return;
    }

    this.closeDialog.emit(true);

    this.toastr.success(
      this.translateService.instant('toast.successfulPasswordChange')
    );

  }

  private validate(): boolean {
    let formValid = true;
    if (!this.passwordForm.valid) {
      formValid = false;
    }

    return formValid;
  }

  get passForm() { return this.passwordForm.controls; }

}
