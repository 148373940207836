import { DatePipe, formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ListAccountingDocument } from "@app/accounting_documents/_models/list-accounting-document.model";
import { AccountingDocumentService } from "@app/accounting_documents/_services/accounting-document.service";
import { AuthenticationService } from "@app/login/_services/authentication.service";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { TechnicalPoint } from "@app/tech-points/_models/technical-point.model";
import { TechnicalPointService } from "@app/tech-points/_services/technical-point.service";
import { DOCUMENT_TYPES } from "@app/_enums/document-type-enum";
import { StatusCode } from "@app/_enums/status-code";
import { BaseSortableSearchComponent } from "@components/_base/base-search/base-sortable-search.component";
import { SortingPagingData } from "@helpers/sorting-paging-data";
import { PaymentType } from "@models/payment-type.model";
import { SearchSubjectVersion } from "@models/search-subject-version.model";
import { Status } from "@models/status.model";
import { TranslateService } from "@ngx-translate/core";
import { ExportService } from "@services/export.service";
import { NomenclatureService } from "@services/nomenclature.service";
import { SubjectVersionService } from "@services/subject-version.service";
import { EMPTY, Observable, of, OperatorFunction, Subscription } from "rxjs";
import { catchError, concatMap, debounceTime, first, switchMap, tap } from "rxjs/operators";
import { displayError, displayErrorFromUnknown } from "@app/_utils/error-util";
import { convertDateToString, prepareDateForDb, convertObjDateToString } from "@app/_utils/date-util";
import { generatePdf } from "@app/_utils/pdf-util";
import { UIEventCustom } from "@app/_utils/ui-event-util";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'list-accounting-documents',
  templateUrl: './list-accounting-documents.component.html',
  styleUrls: ['./list-accounting-documents.component.css']
})

export class ListAccountingDocumentsComponent extends BaseSortableSearchComponent<ListAccountingDocument> implements OnInit, OnDestroy {
  // Units
  userTechnicalPointMobileAppIds:   number[];
  technicalPoints:                  TechnicalPoint[];
  statuses:                         Status[];
  paymentTypes:                     PaymentType[];
  documentTypes =                   DOCUMENT_TYPES;
  fromDateStr:                      string;
  toDateStr:                        string;

  // Constants
  private readonly DATE_LOCALE:             string = "en";
  private readonly DATE_FORMAT:             string = "dd.MM.yyyy";
  readonly SERVICE_TYPE_CODE:               string = 'P';
  private readonly INVOICE_DOCUMENT_TYPE    = 'I';
  readonly INVOICE_PAID_STATUS              = 'Платена';
  private readonly INVOICE_PAID_STATUS_CODE = 'PAID';

  // Booleans
  hasTechnicalPoint:   boolean;
  loadPdfOrExcel       = false;
  loadInvoice          = false;
  loadTxtFile          = false;
  loadPdfFile          = false;
  displayComponent     = false; 

  // Forms
  searchForm = this.formBuilder.group({
    techPointMobileAppId:            [''],
    documentType:                    this.INVOICE_DOCUMENT_TYPE,
    invoiceNumber:                   null,
    recepient:                       null,
    statusId:                        null,
    paymentTypeId:                   null,
    dateFrom:                        null,
    dateTo:                          null
  });

  paidDateForm: FormGroup;

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {     
      return this.accountingDocumentService.pageable(this.sortingPaging, this.searchForm.value,
                                                     prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                     prepareDateForDb(this.searchForm.get('dateTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
  );

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    switchMap(input =>
      this.subjectVersionService.filterSubjectVersions(input).pipe(
        catchError(() => of([]))
      )
    )
  );

  constructor(
    public  perms:                      PermissionsService,
    private formBuilder:                FormBuilder,
    private exportService:              ExportService,
    private translateService:           TranslateService,
    private techPointService:           TechnicalPointService,
    private nomenclatureService:        NomenclatureService,
    private subjectVersionService:      SubjectVersionService,
    private accountingDocumentService:  AccountingDocumentService,
    private uiEvent:                    UIEventCustom,
    private datePipe:                   DatePipe,
    private calendar: NgbCalendar
  ) {
    super();
    this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  async ngOnInit() {
    this.displayComponent = false;
    await this.loadTechnicalPoints();

    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();

    this.initPaidDateForm();

    await Promise.all([this.reloadStatuses(StatusCode.Invoice), this.reloadPayments()]);
    this.displayComponent = true;
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  private async loadTechnicalPoints() {
    try {
      if (this.userTechnicalPointMobileAppIds.length === 0) {
        const result = await this.techPointService.findTechnicalPoints().toPromise();
        this.technicalPoints = result;
        this.hasTechnicalPoint = false;
      } else {
        const result = await this.techPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise();
        this.hasTechnicalPoint = true;
        this.technicalPoints = result;
        this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
      }
    } catch (err) {
      displayErrorFromUnknown(err);
    }
  }

  private reloadStatuses(statusCode: string) {
    this.nomenclatureService.getStatusesByType(statusCode).toPromise().then(statuses => {
      this.statuses = statuses;
    })
    .catch(err => displayError(err));
  }

  private reloadPayments() {
    this.nomenclatureService.getPaymentTypes().toPromise().then(paymentTypes => {
      this.paymentTypes = paymentTypes;
    })
    .catch(err => displayError(err));
  }
  
  currentDate: Date;
  defaultDate: NgbDateStruct;
  private initPaidDateForm() {
    this.currentDate = new Date();
    const formattedDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.defaultDate = this.convertToNgbDate(formattedDate!);

    this.paidDateForm = this.formBuilder.group({
      paidDate: [this.defaultDate] // Set default value to today's date
    });
  }

  // Convert formatted date to NgbDateStruct
  convertToNgbDate(dateString: string): NgbDateStruct {
    const dateParts = dateString.split('-').map(Number);
    return { year: dateParts[0], month: dateParts[1], day: dateParts[2] };
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      techPointMobileAppId:                 [''],
      documentType:                         this.INVOICE_DOCUMENT_TYPE,
      invoiceNumber:                        null,
      recepient:                            null,
      statusId:                             null,
      paymentTypeId:                        null,
      dateFrom:                             null,
      dateTo:                               null
    });

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }
    
    this.searchSubject.next();
  }

  async exportTxt() {
    this.loadTxtFile = true;
    try {
      let data: any = await this.prepareTxtData();
      this.accountingDocumentService.convert(data).toPromise().then((path: string) => {
        this.exportService.exportAsTxt(path);
      });
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadTxtFile = false;
    }
  }
  
  private async prepareTxtData() {
    let newContent: string[] = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.accountingDocumentService.findAllByFilterExport(sortingPagingCopy, this.searchForm.value,
                                                                            prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                            prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                            ).pipe(first()).toPromise();

    for (let object of result) {
      let objValue = '999999999'; // default one
      const invoiceIdentNum = object?.recepient?.invoiceIdentNum;
      const vatNumber = object?.customerInvoiceDetails?.vatNumber;
      if (invoiceIdentNum != null && invoiceIdentNum.trim()) {
        objValue = invoiceIdentNum;
      } else if (vatNumber != null && vatNumber.trim()) {
        objValue = vatNumber;
      }

      if (objValue === '999999999') {
        objValue = '';
      }
 
      newContent.push("2" + "|" + (object?.invoiceDate != null ? formatDate(object?.invoiceDate.toString(), this.DATE_FORMAT, this.DATE_LOCALE ) : "")
        + "|" + (object?.invoiceNumber != null ? object?.invoiceNumber : "")
        + "|" + (object?.documentType == null ? "" : (object?.documentType == 'I' ? "Ф-ра" : (object?.documentType == 'C' ? "КИ" : "ДИ")))
        + "|" + (object?.totalAmount == null ? "" : object?.totalAmount)
        + "|" + "16"
        + "|" + (object?.recepient?.invoiceFullName != null ? object?.recepient?.invoiceFullName : object?.customerInvoiceDetails?.fullName != null ? object?.customerInvoiceDetails?.fullName : "")
        + "|" + (object?.recepient?.invoiceManager != null ? object?.recepient?.invoiceManager : object?.customerInvoiceDetails?.manager != null ? object?.customerInvoiceDetails?.manager : "")
        + "|" + (object?.recepient?.city?.name != null ? object?.recepient?.city?.name : "")
        + "|" + (object?.recepient?.invoiceFullAddress != null ? object?.recepient?.invoiceFullAddress : object?.customerInvoiceDetails?.fullAddress != null ? object?.customerInvoiceDetails?.fullAddress : "")
        + "|" + (object?.recepient?.invoiceDdsRegistration != null ? object?.recepient?.invoiceDdsRegistration : "")
        + "|" + (objValue)
        + "|" + "с-ка:" + "|" + "" + "|" + ""
        + "|" + (object?.amountDds == null ? "" : object?.amountDds)
        + "\n");
    }

    return newContent;
  }

  async exportPayments() {
    this.loadTxtFile = true;
    try {
      let data: any = await this.preparePaymentData();
      this.accountingDocumentService.convert(data).toPromise().then((path: string) => {
        this.exportService.exportAsTxt(path);
      });
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadTxtFile = false;
    }
  }
  
  private async preparePaymentData() {
    let newContent: string[] = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.accountingDocumentService.findAllByFilterExport(sortingPagingCopy, this.searchForm.value,
                                                                            prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                            prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                            ).pipe(first()).toPromise();

    for (let object of result) {
      let objValue = '999999999'; // default one
      const invoiceIdentNum = object?.recepient?.invoiceIdentNum;
      const vatNumber = object?.customerInvoiceDetails?.vatNumber;
      if (invoiceIdentNum != null && invoiceIdentNum.trim()) {
        objValue = invoiceIdentNum;
      } else if (vatNumber != null && vatNumber.trim()) {
        objValue = vatNumber;
      }

      if (objValue === '999999999') {
        objValue = '';
      }

      newContent.push("10" + "|" + (object?.invoiceDate != null ? formatDate(object?.invoiceDate.toString(), this.DATE_FORMAT, this.DATE_LOCALE ) : "")
        + "|" + (object?.invoiceNumber != null ? object?.invoiceNumber : "")
        + "|" + "ПКО"
        + "|" + (object?.totalAmount == null ? "" : object?.totalAmount)
        + "|" + "8"
        + "|" + (object?.recepient?.invoiceFullName != null ? object?.recepient?.invoiceFullName : object?.customerInvoiceDetails?.fullName != null ? object?.customerInvoiceDetails?.fullName : "")
        + "|" + (object?.recepient?.invoiceManager != null ? object?.recepient?.invoiceManager : object?.customerInvoiceDetails?.manager != null ? object?.customerInvoiceDetails?.manager : "")
        + "|" + (object?.recepient?.city?.name != null ? object?.recepient?.city?.name : "")
        + "|" + (object?.recepient?.invoiceFullAddress != null ? object?.recepient?.invoiceFullAddress : object?.customerInvoiceDetails?.fullAddress != null ? object?.customerInvoiceDetails?.fullAddress : "")
        + "|" + (object?.recepient?.invoiceDdsRegistration != null ? object?.recepient?.invoiceDdsRegistration : "")
        + "|" + (objValue)
        + "|" + "" 
        + "|" + "Плащане в брой" 
        + "|" + "разплащане"
        + "|" + 0
        + "\n");
    }

    return newContent;
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareDataForExcelAndPdf(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
                                         this.translateService.instant("exportData.filesNames.accountingDocumentsResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareDataForExcelAndPdf(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.accountingDocumentsResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareDataForExcelAndPdf() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.accountingDocumentService.findAllByFilter(sortingPagingCopy, this.searchForm.value, 
                                                                       prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                       prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                       ).pipe(first()).toPromise();

    result.content.forEach(object => {
       newContent?.push([this.formatType(object.documentType), object.invoiceNumber, object.invoiceDate, object.technicalPointName, 
                         object.recipientFullName, object.recepientEIK, object.totalAmount, object.paymentType, object.status])
    })

    return newContent;
  }
 
  private getHeadings() : string[][] {
    return [[this.translateService.instant("accountingDocument.docType"), this.translateService.instant("accountingDocument.docNumber"), 
              this.translateService.instant("accountingDocument.createdAt"), this.translateService.instant("accountingDocument.point"), 
              this.translateService.instant("accountingDocument.name"), this.translateService.instant("accountingDocument.personalUniqueNumber"), 
              this.translateService.instant("accountingDocument.value"), this.translateService.instant("accountingDocument.listPaymentType"), 
              this.translateService.instant("accountingDocument.status")]]
  }
   
  private getFilterBody(): any[] {
    let paymentTypeDescription: string = '';
    let statusName: string = '';
    let technicalPointName: string = '';
    let recepient = this.searchForm.get('recepient')?.value;

    this.technicalPoints.forEach(technicalPoint => {
      if (technicalPoint.mobileAppId == this.searchForm.get('techPointMobileAppId')?.value) {
        technicalPointName = technicalPoint.shortName;
      }
    });

    this.paymentTypes.forEach(paymentType => {
      if (paymentType.id == this.searchForm.get('paymentTypeId')?.value) {
        paymentTypeDescription = paymentType.description;
      }
    });

    this.statuses.forEach(status => {
    if (status.id == this.searchForm.get('statusId')?.value) {
      statusName = status.name;
    }
    });

    let result: string[]= [
       technicalPointName,
       recepient?.fullName,
       this.formatType(this.searchForm.get('documentType')?.value), 
       this.searchForm.get('invoiceNumber')?.value,
       statusName,
       paymentTypeDescription,
       convertObjDateToString(this.searchForm.get('dateFrom')?.value), 
       convertObjDateToString(this.searchForm.get('dateTo')?.value)
    ];
 
    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listAccountingDocuments.technicalPoint'),
      this.translateService.instant('exportData.listAccountingDocuments.partner'),
      this.translateService.instant('exportData.listAccountingDocuments.chooseDocType'),
      this.translateService.instant('exportData.listAccountingDocuments.docNumber'),
      this.translateService.instant('exportData.listAccountingDocuments.docStatus'),
      this.translateService.instant('exportData.listAccountingDocuments.paymentType'),
      this.translateService.instant('exportData.listAccountingDocuments.dateFrom'),
      this.translateService.instant('exportData.listAccountingDocuments.dateTo')
    ];
 
    return [
      [{content: this.translateService.instant('accountingDocument.search'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

  async generatePdf(id: number, invoiceNumber: string) {
    this.loadPdfFile = true;
    try {
      await this.accountingDocumentService.generatePdf(id).toPromise().then(response => generatePdf(invoiceNumber, response));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfFile = false;
    }
  }

  async generatePdfFromPage(content: any[]) {
    this.loadInvoice = true;
    content.forEach(async element => {
      this.loadPdfFile = true;
      try {
        await this.accountingDocumentService.generatePdf(element.id).toPromise().then(response => generatePdf(element.invoiceNumber, response));
      } catch (err) {
        displayErrorFromUnknown(err);
      } finally {
        this.loadPdfFile = false;
      }
    });
    this.loadInvoice = false;
  }

  formatType(code: any) {
    return DOCUMENT_TYPES.find(type => type.code === code)?.name
  }

  technicalPointFormatter(element: any): string {
    return element.name;
  }

  subjectVersionFormatter(element: any): string {
    return element.fullName;
  }

  payAccount(item: any,  paidDate: any) {
    const dateString = `${paidDate.year}-${paidDate.month.toString().padStart(2, '0')}-${paidDate.day.toString().padStart(2, '0')}`;
    this.accountingDocumentService.updateAccountingDocumentStatusDateById(item.id, this.INVOICE_PAID_STATUS_CODE, dateString).subscribe(id => {
      if (!id) {
        this.uiEvent.displayUIError();
        return;
      }
      
      item.id = id;
      item.status = this.INVOICE_PAID_STATUS;
      this.uiEvent.displayUISuccess();
      item.paidDate = dateString;
    });
  }

}