import { formatDate } from "@angular/common";

const DATE_FORMAT = "yyyy-MM-dd HH:mm:00";
const DATE_LOCALE = "en";

/** 
 * @param {Object} date: {year: 2023, month: 4, day: 4}
 * @return {string}: Output: 2023-04-04
*/
export const prepareDateForDb = (date: any): string => {
    if (!date) return '';

    let fromDateYear  = date.year.toString();
    let fromDateMonth = date.month.toString();
    let fromDateDay   = date.day.toString();

    if (fromDateMonth.length === 1) fromDateMonth = '0' + fromDateMonth;
    if (fromDateDay.length === 1)   fromDateDay = '0' + fromDateDay;

    return fromDateYear + '-' + fromDateMonth + '-' + fromDateDay;
}

/** 
 * @param {Object} date: {year: 2023, month: 4, day: 4}
 * @return {string}: 4-4-2023
*/
export const convertObjDateToString = (date: any): string => {
    if (!date) return "";
    return date.day + '-' + date.month + '-' + date.year;
}

/** 
 * @param {Date} date: new Date()
 * @returns {Object}: {year: 2023, month: 4, day: 4} 
*/
export const prepareDateForForm = (date: Date): { year: number, month: number, day: number } => {
   return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() };
}

/** 
 * @param {Date} date: new Date()
 * @return {string}: 04/04/2023 11:13 
*/
export const convertDateToString = (date: Date): string => {
    return dateFormatter.format(date).replace(',', '')
}

/** 
 * @param {any} date: 2023-01-20
 * @returns {Object}: {year: 2023, month: 1, day: 20}
*/
export const prepareDateForUI = (date: any): object | null => {
    if (!date) return null;

    let splitted = date.toString().split("-");
    const dateYear  = +splitted[0];
    const dateMonth = +splitted[1];
    const dateDay   = +splitted[2];
   
    return { year: dateYear, month: dateMonth, day: dateDay };
}

/** 
 * @param {number[]} dateArr: [2023, 1, 26, 16, 6, 39, 420128000]
 * @return {string}: 26.01.2023 - 16:06
*/
export const formatDateFromArray = (dateArr: any): string => {
    if (!dateArr) return '';
    const[year, month, day, hour, minutes] = dateArr;
    return `${addLeadingZero(day)}.${addLeadingZero(month)}.${addLeadingZero(year)} - ${addLeadingZero(hour)}:${addLeadingZero(minutes)}`;
}

/** 
 * @param {Date | Number | String} date: new Date()
 * @return {string}: 2023-04-10 11:35:00
*/
export const formatFullDate = (date: string | number | Date): string => {
    return formatDate(date, DATE_FORMAT, DATE_LOCALE);
}

export const dateFormatter = new Intl.DateTimeFormat("en-GB", {
    year:   "numeric",
    month:  "2-digit",
    day:    "2-digit",
    hour:   "2-digit",
    minute: "2-digit",
});

export const addLeadingZero = (obj: any): string => {
    obj = new String(obj);
    if (obj.length === 1) {
      return '0' + obj;
    }
    return obj;
}