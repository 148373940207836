import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { CustomerDiscount } from '@models/customer-discount.model';
import { DiscountType } from '@models/discount-type.model';
import { SubjectType } from '@models/subject-type.model';
import { TranslateService } from '@ngx-translate/core';
import { NomenclatureService } from '@services/nomenclature.service';
import { EMPTY, forkJoin, Subject } from 'rxjs';
import { catchError, concatMap, repeatWhen, tap } from 'rxjs/operators';
import { CustomerDiscountType } from './model/customer-discount-type.model';

@Component({
  selector: 'app-customer-discounts',
  templateUrl: './customer-discounts.component.html',
  styleUrls: ['./customer-discounts.component.css']
})
export class CustomerDiscountsComponent extends BaseParentComponent implements OnInit {
  
  // Units
  customerDiscountId:         number;
  subjectTypes:               SubjectType[];
  defaultSubjectType:         SubjectType | null;
  discountTypes:              DiscountType[];
  searchSubject               = new Subject<void>();

  // Constants
  readonly OWNER_SUBJECT_TYPE_CODE: string = 'O';

  // Form
  addEditForm = this.formBuilder.group({
    id:                       '',
    discountName:             ['', [Validators.required, Validators.maxLength(128)]],
    isValid:                  true,
    versionData:              null,
    priceAttributeKind:       [null, Validators.required],
    priceAttributeAmountDds:  null,
    subjectType:              [null, Validators.required],
    discountTypes:            [null, Validators.required]
  });

  // Observables
  customerDiscount$ = this.route.queryParams.pipe(
    concatMap(params => {
        return forkJoin([
            this.nomenclatureService.findCustomerDiscountById(params['id']),
            this.nomenclatureService.getSubjectTypes(),
            this.nomenclatureService.getDiscountTypes()
        ]).pipe(
            tap(() => this.customerDiscountId = params['id']),
            tap(([customerDiscount, subjectTypes, discountTypes]) => {
                this.subjectTypes = subjectTypes;
                this.discountTypes = discountTypes;

                if (customerDiscount?.priceAttributeKind == 'P') {
                  this.addEditForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.max(100)]);
                  this.addEditForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
                } else if (customerDiscount?.priceAttributeKind == 'V') {
                  this.addEditForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.max(99)]);
                  this.addEditForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
                } 
                this.addEditForm.patchValue(customerDiscount);

                this.defaultSubjectType = this.subjectTypes.find(subjectType => subjectType.code === this.OWNER_SUBJECT_TYPE_CODE) || null;
                this.addEditForm.get('subjectType')?.patchValue(!!customerDiscount && !!customerDiscount.subjectType? customerDiscount.subjectType : this.defaultSubjectType);
                
                this.addEditForm.get('priceAttributeAmountDds')?.patchValue(customerDiscount?.priceAttributeAmountDds);   
                this.addEditForm.get('priceAttributeAmountDds')?.patchValue(customerDiscount?.priceAttributeAmountDds);

                const listDiscountTypes: DiscountType[] = customerDiscount?.customerDiscountType?.map(cdt => cdt.discountType) || [];
                this.addEditForm.get('discountTypes')?.patchValue(Object.assign([], listDiscountTypes));
            }),
            catchError(err => {
              displayError(err);
              this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
              return EMPTY;
            }),
            repeatWhen(() => this.reload$)
        );
    })
  );

  constructor(
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    protected router: Router,
    private uiEvent: UIEventCustom,
    public  perms: PermissionsService,
    private translateService: TranslateService,
    private nomenclatureService: NomenclatureService) {
      super(router);
  }

  ngOnInit() {
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data
    });
  }

  changePriceAttrKind(event: any) {
    let type = event.target.value;

    if (type == 'P') {
      this.addEditForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.max(100)]);
      this.addEditForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
    } else if (type == 'V') {
      this.addEditForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.max(99)]);
      this.addEditForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
    }
  }

  onSubmit() {
    if (!this.addEditForm.valid) {
        this.uiEvent.displayUIError();
        this.showValidationsSubject.next(true);
        return;
    }

    this.nomenclatureService.saveCustomerDiscount(this.constructCustomerDiscount()).subscribe({
        next: (id) => {
          this.uiEvent.displayUISuccess();
          this.router.navigate(['/list-customer-discounts']);
        },
        error: err => {
          displayError(err);
        } 
      });
  }

  private constructCustomerDiscount() {
    const formResult = this.addEditForm.value;
    const listOfCustomerDiscountTypes: CustomerDiscountType[] = formResult.discountTypes.map((dt: DiscountType) => ({ discountType: dt, isValid: dt.isValid }));

    const result: CustomerDiscount = {
      ...formResult,
      customerDiscountType: listOfCustomerDiscountTypes
    };
    delete result.discountTypes;

    return result;
  }

  get mainForm() { 
    return this.addEditForm.controls; 
  }

}