import { TranslateService } from '@ngx-translate/core';
import { StatusCode } from '@app/_enums/status-code';
import { TechnicalPointLinesComponent } from './technical-point-lines/technical-point-lines.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalPointServicesComponent } from './technical-point-services/technical-point-services.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { RegisterOfLegalPersonsModalComponent } from '@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component';
import { modalMinWidth } from '@env/config';
import { MapCoordinates } from '@interfaces/map-coordinates';
import { NominatimService } from '@services/nominatim.service';
import { NominatimCoordinatesResponse } from '@interfaces/nominatim-coordinates-response';
import { NominatimAddressResponse } from '@interfaces/nominatim-address-response';

import { catchError, concatMap, debounceTime, repeatWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, forkJoin, OperatorFunction, Observable, of, Subject, Subscription } from 'rxjs';
import { Status } from '@models/status.model';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { City } from '@models/city.model';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MapComponent } from '@components/map/map.component';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { SubjectVersion } from '@models/subject-version.model';
import { UtilValidators } from '@app/_validators/util.validators';
import { ConsiceSubjectVersion } from '@models/consice-subject-version.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';

@Component({
  selector: 'app-add-edit-technical-point',
  templateUrl: './add-edit-technical-point.component.html',
  styleUrls: ['./add-edit-technical-point.component.css']
})
export class AddEditTechnicalPointComponent extends BaseParentComponent implements OnInit, OnDestroy {
  // Units
  cities:         City[];
  statuses:       Status[];
  defaultStatus:  Status | null;

  // Constants
  readonly INACTIVE_STATUS_CODE = 'INACTIVE';

  // Booleans
  wasLoadedInactive = false;
  isSaving = false;
  isUnchanged: boolean | null = true;

  // Payload
  techPointId:          number;
  addressTypeaheadValue = '';
  currentPoint?: MapCoordinates = undefined;

  // Form
  techPointMainForm = this.formBuilder.group({
    id:                   [''],
    name:                 ['', [Validators.required, Validators.maxLength(120)]],
    shortName:            ['', [Validators.required, Validators.maxLength(60)]],
    address:              ['', [Validators.required, Validators.maxLength(2048)]],
    city:                 ['', Validators.required],
    lat:                  ['', Validators.required],
    lng:                  ['', Validators.required],
    email:                ['', [Validators.required, Validators.email, Validators.maxLength(60)]],
    phone:                ['', [Validators.required, UtilValidators.validatePhoneNumber()]],
    status:               ['', Validators.required],
    permitNumber:         ['', [Validators.required, Validators.max(9999), Validators.min(1), Validators.pattern(/^[0-9]*$/)],],
    subjectId:            [null, Validators.required],
    subjectName:          [null],
    subjectIdentNum:      [null],
    subjectVersionData:   [null],
    invoiceNumber:        [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
    mobileAppId:          [null],
    versionData:          [''],
  });

  // Child components
  @ViewChild('services') servicesComponent: TechnicalPointServicesComponent;
  @ViewChild('lines')    linesComponent:    TechnicalPointLinesComponent;
  @ViewChild('map')      mapComponent:      MapComponent;

  // Subjects
  private showMapSubject = new BehaviorSubject<boolean>(false);
  showMap$ = this.showMapSubject.asObservable();

  private initializeSectionsSubject = new Subject<void>();
  initializeSections = this.initializeSectionsSubject.asObservable();

  selectedCategoriesSubject = new Subject<number[]>();
  selectedCategories$ = this.selectedCategoriesSubject.asObservable();

  // Subsriptions
  baseSubscription: Subscription = new Subscription();

    // Observables
  loadTechnicalPoint$ = this.route.queryParams.pipe(
    concatMap(params => {
      return forkJoin([
        this.technicalPointService.findById(params['id']),
        this.technicalPointService.getTechPointNomenclatures(StatusCode.TechnicalPoint)
      ]).pipe(
        tap(() => this.techPointId = params['id']),
        tap(([techPoint, [cities,statuses]]) => {
          this.techPointMainForm.patchValue(techPoint);
          this.cities = cities;
          this.statuses = statuses;
          this.defaultStatus = this.statuses.find(status => status.code === this.INACTIVE_STATUS_CODE) || null;
          this.setCurrentPoint(techPoint);
          this.setTechPointStatus(techPoint);
          this.setSubjectData(techPoint?.subjectOwner);
        }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  addressSearch: OperatorFunction<string, NominatimAddressResponse[]> = (input$: Observable<string>) =>
  input$.pipe(
    debounceTime(300),
    switchMap(input =>
        this.nominatimService.searchAddress(input).pipe(
        catchError(() => of([])),
        shareReplay()
      )
    )
  );

  constructor(
    public  perms:                  PermissionsService,
    private router:                 Router,
    private dialog:                 MatDialog,
    private route:                  ActivatedRoute,
    private uiEvent:                UIEventCustom,
    private formBuilder:            FormBuilder,
    private nominatimService:       NominatimService,
    private translateService:       TranslateService,
    private technicalPointService:  TechnicalPointService
  ) { 
    super(router);
  }
  
  ngOnInit() {
    super.ngOnInit();

    this.baseSubscription.add(this.techPointMainForm.valueChanges.subscribe({
        next: () => {
          if (this.techPointMainForm.dirty) {
            this.onChangeForm();
          }
        },
        error: err => {
          displayError(err);
        } 
      })
    );

    this.baseSubscription.add(this.techPointMainForm.get('invoiceNumber')?.valueChanges.subscribe({
        next: () => this.validateInvoiceNumber(false),
        error: err => displayError(err)
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.baseSubscription?.unsubscribe();
  }

  openModal() {
    if (this.dialog.openDialogs.length == 0) {
      let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
      ref.componentInstance.submitSubject.subscribe(result => {
        this.setSubjectData(result);
        this.onChangeForm();
      });

      let subjectId = this.techPointMainForm.get('subjectId')?.value;
      let subjectVersionData = this.techPointMainForm.get('subjectVersionData')?.value;
      
      if (subjectId != null) {   
        ref.componentInstance.selectSubject(subjectId, subjectVersionData.id);
      } 
    }
  }

  openMap() {
    this.showMapSubject.next(!this.showMapSubject.value);
  }

  setPoint(event: MapCoordinates) {
    this.currentPoint = event;
    this.techPointMainForm.get('lat')?.setValue(this.currentPoint?.lat);
    this.techPointMainForm.get('lng')?.setValue(this.currentPoint?.lng);
    this.onChangeForm();

    this.searchCoordinates();
  }

  onAddressSelect(selectedItem: NgbTypeaheadSelectItemEvent<NominatimAddressResponse>) {
    let address = selectedItem.item.display_name;

    this.addressTypeaheadValue = address;
    this.techPointMainForm.get('address')?.patchValue(address);
    this.techPointMainForm.get('lat')?.patchValue(this.currentPoint?.lat);
    this.techPointMainForm.get('lng')?.patchValue(this.currentPoint?.lng);
    this.techPointMainForm.get('city')?.patchValue(this.findCity(selectedItem.item.address.city, selectedItem.item.display_name))
    this.onChangeForm();

    this.currentPoint = { lat: selectedItem.item.lat, lng: selectedItem.item.lon };
    this.mapComponent.addressCoordinatesSubject.next(this.currentPoint);
  }

  searchCoordinates() {
    this.nominatimService.searchCoordinates(this.currentPoint).subscribe({
      next: (data: NominatimCoordinatesResponse) => {
        this.techPointMainForm.get('city')?.setValue(data.address.city);
        this.techPointMainForm.get('address')?.setValue(data.display_name);
        this.techPointMainForm.get('city')?.patchValue(this.findCity(data.address.city, data.display_name))
      },
      error: err => {
        displayError(err);
      }
    });
  }

  async validateInvoiceNumber(isFromSubmit: boolean) {
    let idControl = this.techPointMainForm.get("id");
    let ownerIdControl = this.techPointMainForm.get("subjectId");
    let invoiceControl = this.techPointMainForm.get("invoiceNumber");

    if (invoiceControl?.invalid) {
      return;
    }

    if (ownerIdControl?.value == null) {
      invoiceControl?.setErrors({ noOwnerSelected : true });
    } else if (invoiceControl?.valid && (this.showValidations || isFromSubmit)) {
      let isInvoiceValid = await this.technicalPointService.isInvoiceNumberValid(invoiceControl?.value, ownerIdControl?.value, idControl?.value)
                                                           .toPromise();

      if (isInvoiceValid) {
        invoiceControl.setErrors(null);
      } else {
        invoiceControl.setErrors({ invalidNumber: true });
      }
    }
  }

  async onSubmit() {
    if (this.techPointMainForm.get('status')?.value?.code !== this.INACTIVE_STATUS_CODE && this.wasLoadedInactive) {
      if (!this.servicesComponent.isInitialized) {
        await this.technicalPointService.initializeServicesAndNomenclatures(this.techPointId).then(([services, [statuses]]) => {
          this.servicesComponent.initServicesAndNomenclatures(services, statuses);
        });
      }

      if (!this.linesComponent.isInitialized) {
        await this.technicalPointService.initializeLinesAndNomeclatures(this.techPointId).then(([lines, [statuses, timeConstrains,categories]]) => {
          this.linesComponent.initLinesAndNomenclatures(lines, timeConstrains,categories, statuses);
        });
      }
    }

    await this.validateInvoiceNumber(true);
    
    if (this.techPointMainForm.get('status')?.value?.code === this.INACTIVE_STATUS_CODE) {
      if (!this.partualValidation()) {
        this.uiEvent.displayUIError();
        this.showValidationsSubject.next(true);
        this.showFullValidationSubject.next(false);
        return;
      }
    } else {
      if (!this.fullValidation()) {
        this.uiEvent.displayUIError();
        this.showValidationsSubject.next(true);
        this.showFullValidationSubject.next(true);
        return;
      }
    }

    this.isSaving = true;
    this.technicalPointService.createTechnicalPoint(this.constructTechPoint()).subscribe({
      next: (id) => {
        this.isSaving = false;
        this.uiEvent.displayUISuccess();
        this.router.navigate(['/list-technical-points']);
      },
      error: err => {
        displayError(err);
        this.isSaving = false;
      } 
    });
  }

  initializeSection(code: string) {
    switch (code) {
      case 'services':
        this.servicesComponent.initialize();
        break;
      case 'lines':
        this.linesComponent.initialize();
        break;
      default:
    }
  }

  onChangeForm() {
    this.isUnchanged = null;
  }

  private findCity(code: string, address: string) {
    let result = this.findCityByName(code);
    return result == null ? this.findCityByAddress(address) : result;
  }

  private findCityByName(code: string) {
    return this.cities.find(city => city.name.toLocaleLowerCase() === code?.toLocaleLowerCase()) || null;
  }

  private findCityByAddress(address: string) {
    let tokens = address.split(',');
    let cityName = tokens.length > 0 ? tokens[tokens.length - 3] : '';

    return this.cities.find(city => cityName.toLocaleLowerCase().includes(city.name.toLocaleLowerCase())) || null;
  }

  private partualValidation() {
    return this.techPointMainForm.valid;
  }

  private fullValidation() {
    let formValid = true;

    if (!this.techPointMainForm.valid) {
      formValid = false;
    }

    if (this.linesComponent.isInitialized) {
      if (!this.linesComponent.validateLines()) {
        formValid = false;
      }
    }

    if (this.servicesComponent.isInitialized) {
      if (!this.servicesComponent.validateServices()) {
        formValid = false;
      }
    }

    return formValid;
  }

  private setCurrentPoint(techPoint: TechnicalPoint) {
    if (techPoint != null && techPoint.lat != null && techPoint.lng != null) {
      this.currentPoint = { lat: techPoint.lat!, lng: techPoint.lng! };
    }
  }

  private setTechPointStatus(techPoint: TechnicalPoint) {
    if (techPoint == null) {
      this.techPointMainForm.get('status')?.patchValue(this.defaultStatus);
      this.wasLoadedInactive = true;
    } else {
      this.wasLoadedInactive = techPoint.status?.code === this.INACTIVE_STATUS_CODE;
    }
  }

  private setSubjectData(subject: SubjectVersion | ConsiceSubjectVersion | undefined) {
    if (subject == null) return;

    this.techPointMainForm.get('subjectId')?.patchValue(subject?.id);
    this.techPointMainForm.get('subjectName')?.patchValue(subject?.fullName);
    this.techPointMainForm.get('subjectVersionData')?.patchValue(subject.versionData);
    this.techPointMainForm.get('subjectIdentNum')?.patchValue(subject?.identNum);

    let invoiceControl = this.techPointMainForm.get("invoiceNumber");

    if (invoiceControl?.hasError("noOwnerSelected")) {
      invoiceControl.setErrors(null);
    }

    this.validateInvoiceNumber(false);
  }

  private constructTechPoint() {
    let mainData = this.techPointMainForm.value;
    let linesData = this.linesComponent.linesForms.value;
    let servicesData = this.servicesComponent.combinedServicesForms;
    
    return {
      id:                     mainData.id                                 || null,
      name:                   mainData.name                               || null,
      shortName:              mainData.shortName                          || null,
      address:                mainData.address                            || null,
      city:                   mainData.city                               || null,
      lat:                    mainData.lat                                || null,
      lng:                    mainData.lng                                || null,
      email:                  mainData.email                              || null,
      phone:                  mainData.phone                              || null,
      status:                 mainData.status                             || null,
      versionData:            mainData.versionData                        || null,
      mobileId:               mainData.mobileId                           || null,
      permitNumber:           mainData.permitNumber                       || null,
      mobileAppId:            mainData.mobileAppId                        || null,
      isAddServicesTimeCalc:  false,
      invoiceNumber:          mainData.invoiceNumber                      || null,
      subjectOwner:           this.constructSubjectOwner(mainData),
      lines:                  this.constructLines(linesData),
      services:               this.constructServices(servicesData)
    }
  }

  private constructSubjectOwner(data: any) {
    if (data.subjectId == null) return undefined;

    return {
      id:          data.subjectId              || null,
      fullName:    data.subjectName            || null,
      identNum:    data.subjectIdentNum        || null,
      versionData: data.subjectVersionData     || null
    }
  }

  private constructLines(data: any[]) {
    return data.map(lineData => {
      return {
        id:               lineData.id               || null,
        number:           lineData.number           || null,
        status:           lineData.status           || null,
        isValid:          lineData.isValid          || false,
        workingHoursType: lineData.workingHoursType || null,
        categories: lineData.categories.filter((category: any) => category.techPointCategory != null)
                                       .map((category: any) => category.techPointCategory) || null,
        workingHours: lineData.timeSlots.filter((timeSlot: any) => timeSlot.techPointTimeSlot != null && timeSlot.techPointTimeSlot.isValid)
                                        .map((timeSlot: any) => timeSlot.techPointTimeSlot) || null
      };
    });
  }

  private constructServices(data: any[]) {
    let convertedServices = 
      data.filter(serviceData => serviceData.id !== null || (serviceData.isValid && !serviceData.isRemoved)).map(serviceData => {
        return {
          id:             serviceData.id                            || null,
          status:         serviceData.status                        || null,
          isValid:        serviceData.isValid                       || false,
          rvsService:     serviceData.rvsService                    || null,
          duration:       serviceData.duration                      || null,
          validFrom:      serviceData.validFrom                     || null,
          validTo:        serviceData.validTo                       || null,
          priceAttribute: this.constructPriceAttribute(serviceData) || null
        }
      });

    return this.servicesComponent.invalidPersistedServices.concat(convertedServices);
  }

  private constructPriceAttribute(data: any) {
    return {
      id:        data.priceId,
      type:      data.priceType,
      amount:    data.priceAmount,
      amountDds: data.priceAmountDds,
      ddsValue:  data.priceDdsValue,
      kind:      data.priceKind
    }
  }

  get linesComponentState() {
    if (this.linesComponent == null) {
      return false;
    } else {
      return this.linesComponent.isOpened;
    }
  }

  get servicesComponentState() {
    if (this.servicesComponent == null) {
      return false;
    } else {
      return this.servicesComponent.isOpened;
    }
  }

  get mainForm() { 
    return this.techPointMainForm.controls;
  }

}
