import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-base-parent',
  template: ``,
  styles: [ ]
})
export class BaseParentComponent implements OnInit, OnDestroy {

  protected errorMessageSubject = new Subject<string>();
  errorMessage$ = this.errorMessageSubject.asObservable();

  protected reloadSubject = new Subject();
  reload$ = this.reloadSubject.asObservable();

  protected showValidationsSubject = new BehaviorSubject<boolean>(false);
  showValidations$ = this.showValidationsSubject.asObservable();

  protected showFullValidationSubject = new BehaviorSubject<boolean>(false);
  showFullValidation$ = this.showFullValidationSubject.asObservable();

  showValidationsSubscription: Subscription;
  showFullValidationsSubscription: Subscription;

  showValidations = false;
  showFullValidations = false;


  constructor(router: Router) { 
     //reload component
     router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
       
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data
    });

    this.showFullValidationsSubscription = this.showFullValidation$.subscribe({
      next: (data) => this.showFullValidations = data
    })
  }

  ngOnDestroy(): void {
    this.showValidationsSubscription?.unsubscribe();
    this.showFullValidationsSubscription?.unsubscribe();
  }

  initializeSection(code: string): void { }

  initializeAllSections(): void { }

  reload():void {
    this.errorMessageSubject.next(undefined);
    this.reloadSubject.next();
  }

  compareById(optionOne: any, optionTwo: any): boolean {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  compareByCode(optionOne: any, optionTwo: any) : boolean {
    return optionOne && optionTwo && optionOne.code === optionTwo.code;
  }
}
