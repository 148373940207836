<div class="form-group form-row" [formGroup]="contractRvsForm">
    <div class="col text-right">
        <button class="btn btn-outline-success ml-2 mb-2" type="button" (click)="addRow()"
            *ngIf="canAddEdit" translate>button.add</button>
    </div>
    <table class="table table-bordered table-striped w-100">
        <thead>
            <tr>
                <th scope="col" style="width:35vw" translate>rvs.regNum</th>
                <th scope="col" style="width:30vw" translate>rvs.talonNum</th>
                <th scope="col" style="width:30vw" translate>phone</th>
                <th scope="col" style="width:5vw" translate class="text-center" *ngIf="canAddEdit">button.delete</th>
            </tr>
        </thead>

        <tbody formArrayName="rows">
            <small class="text-danger" *ngIf="rows?.errors && showValidations" translate>
                errors.atLeastOneCar
            </small>
            <ng-container *ngFor="let row of rows.controls; let i= index" [formGroupName]="i">
             <tr *ngIf="row.get('isValid')?.value">
               <td>
                    <div>
                        <input type="text" class="form-control" formControlName="regNumber" maxlength="16"
                        #regnum (input)="rows.controls[i].patchValue({regNumber: regnum.value.toUpperCase()})"
                        [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('regNumber')?.errors }">
                    </div>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('regNumber')?.errors?.required && showValidations" translate>
                        errors.required
                    </small>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('regNumber')?.errors?.pattern && showValidations"
                        translate>
                        errors.onlyLatinSymbolsAndDigits
                    </small>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('regNumber')?.errors?.maxlength && showValidations">
                        {{'errors.inputTooLong' | translate : {max:8} }}
                    </small>
                </td>
                <td>
                    <div>
                        <input type="text" class="form-control" formControlName="registrationCertNum" 
                        #input (input)="rows.controls[i].patchValue({registrationCertNum: input.value.toUpperCase()})"
                        [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('registrationCertNum')?.errors }">
                    </div>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('registrationCertNum')?.errors?.required && showValidations" translate>
                        errors.required
                    </small>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('registrationCertNum')?.errors?.pattern && showValidations"
                        translate>
                        errors.onlyCapitalLatinLettersAndNumbers
                    </small>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('registrationCertNum')?.errors?.maxlength && showValidations">
                        {{'errors.inputTooLong' | translate : {max:9} }}
                    </small>
                </td>
                <td>
                    <div>
                        <input type="text" class="form-control" formControlName="phoneNumber"
                        [ngClass]="{ 'is-invalid': showValidations && rows.controls[i]?.get('phoneNumber')?.errors }">
                    </div>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('phoneNumber')?.errors?.required && showValidations" translate>
                        errors.required
                    </small>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('phoneNumber')?.errors?.maxlength && showValidations">
                        {{'errors.inputTooLong' | translate : {max:16} }}
                    </small>
                    <small class="text-danger" *ngIf="rows.controls[i]?.get('phoneNumber')?.errors?.invalidPhoneNumber && showValidations"
                    translate>
                    {{'errors.invalidPhoneNumber' | translate}}
                    </small>
                </td>
                <td class="text-center" *ngIf="canAddEdit">
                    <button class="btn btn-danger btn-sm" type="button" (click)="deleteRow(i)" *ngIf="canAddEdit"
                     data-toggle="tooltip" data-placement="bottom" title="{{'button.delete' | translate}}"><em class="fa fa-trash"></em></button>
                </td>
            </tr>
            </ng-container>
        </tbody>
    </table>
</div>