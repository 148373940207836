import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Line } from '@app/tech-points/_models/line.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { City } from '@models/city.model';
import { Status } from '@models/status.model';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { NomenclatureService } from '@services/nomenclature.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, concatMap, first, tap } from 'rxjs/operators';
import { ListTechnicalInspectionSlots } from '../_models/list-technical-inspection-slots.model';
import { TechnicalInspectionSlotsService } from '../_services/technical-inspection-slots.service';
import { prepareDateForDb, convertDateToString, convertObjDateToString, prepareDateForForm } from '@app/_utils/date-util';
import { displayError } from '@app/_utils/error-util';

@Component({
  selector: 'app-technical-inspection-slots',
  templateUrl: './technical-inspection-slots.component.html',
  styleUrls: ['./technical-inspection-slots.component.css']
})
export class TechnicalInspectionSlotsComponent extends BaseSortableSearchComponent<ListTechnicalInspectionSlots> implements OnInit, OnDestroy{  
  // Units
  technicalPoints:                TechnicalPoint[];
  userTechnicalPointMobileAppIds: number[];
  cities:                         City[];
  lines:                          Line[];
  statuses:                       Status[];
  dateObj                         = new Date();
  sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);

  // Constants
  private readonly TECH_INSP_STATUS_TYPE = 'TECHINSP';

  // Booleans
  hasTechnicalPoint:  boolean;
  loadPdfOrExcel      = false;

  // Form
  searchForm = this.formBuilder.group({
    mobileAppId:                     [''],
    dateFrom:                        prepareDateForForm(this.dateObj),
    dateTo:                          prepareDateForForm(this.dateObj),
    cityCode:                        null,
    lineId:                          null,
    statusId:                        null
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.technicalInspectionSlotsService.pageable(this.sortingPaging, this.searchForm.value,
                                                            prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                            prepareDateForDb(this.searchForm.get('dateTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    })
  );

  constructor(
    public perms: PermissionsService,
    private formBuilder: FormBuilder,
    private exportService: ExportService,
    private nomenclatureService: NomenclatureService,
    private translateService: TranslateService,
    private technicalPointService: TechnicalPointService,
    private technicalInspectionSlotsService: TechnicalInspectionSlotsService
    ) {
    super();
    this.userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  async ngOnInit() {
    await Promise.all([this.loadStatuses(), this.loadSubjectVersionCities()]);
    await this.loadTechnicalPoints();
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  private async loadTechnicalPoints() {
    try {
      if (this.userTechnicalPointMobileAppIds.length === 0) {
        const result = await this.technicalPointService.findTechnicalPoints().toPromise();
        this.technicalPoints = result;
        this.hasTechnicalPoint = false;
      } else {
        const result = await this.technicalPointService.findTechnicalPointsByIds(this.userTechnicalPointMobileAppIds).toPromise();
        this.hasTechnicalPoint = true;
        this.technicalPoints = result;
        this.searchForm.get('techPointMobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
      }
    } catch (err) {
      displayError(new Error());
    }
  }

  private loadSubjectVersionCities() {
    this.nomenclatureService.getCities().toPromise().then(cities => {
      this.cities = cities;
    })
    .catch(err => displayError(err));
  }

  private loadStatuses() {
    this.nomenclatureService.getStatusesByType(this.TECH_INSP_STATUS_TYPE).toPromise().then(statuses => {
      this.statuses = statuses;
    })
    .catch(err => displayError(err));
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      mobileAppId:                          [''],
      dateFrom:                             prepareDateForForm(this.dateObj),
      dateTo:                               prepareDateForForm(this.dateObj),
      cityCode:                             null,
      lineId:                               null,
      statusId:                             null
    });

    if (this.userTechnicalPointMobileAppIds.length != 0) {
      this.searchForm.get('mobileAppId')?.patchValue(this.technicalPoints[0].mobileAppId);
    }

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.technicalInspectionSlotsResult"));
    } catch (err) {
      displayError(new Error());
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.technicalInspectionSlotsResult"), convertDateToString(new Date()));
    } catch(err) {
      displayError(new Error());
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.technicalInspectionSlotsService.findAllByFilter(sortingPagingCopy, this.searchForm.value,
                                                                    prepareDateForDb(this.searchForm.get('dateFrom')?.value), 
                                                                    prepareDateForDb(this.searchForm.get('dateTo')?.value)
                                                                   ).pipe(first()).toPromise();

    result.content.forEach(object => {
       newContent?.push([
          object.createdAt,
          object.cityName,
          object.shortName, 
          object.lineNumber,
          object.slotDescription,
          object.dayOfWeekDescription,
          object.statusDescription])
    });

    return newContent;
  }
 
  private getHeadings(): string[][] {
    return [[this.translateService.instant("references.date"), this.translateService.instant("references.city"), 
              this.translateService.instant("references.point"), this.translateService.instant("references.line"), 
              this.translateService.instant("references.slot"), this.translateService.instant("references.dayOfWeek"),
              this.translateService.instant("references.status")]]
  }
   
  private getFilterBody(): any[] {
    let statusName: string = "";
    let tpName: string = "";
    let cityName: string = "";
    let lineNumber: string = "";

    this.technicalPoints.forEach(tp => {
    if (tp.mobileAppId == this.searchForm.get('mobileAppId')?.value) {
      tpName = tp.shortName;
    }
    });

    this.statuses.forEach(status => {
    if (status.id == this.searchForm.get('statusId')?.value) {
      statusName = status.name;
    }
    });

    this.cities.forEach(city => {
    if (city.code == this.searchForm.get('cityCode')?.value) {
      cityName = city.name;
    }
    });

    let result: string[]= [
     tpName,
     cityName,
     statusName,
     convertObjDateToString(this.searchForm.get('dateFrom')?.value), 
     convertObjDateToString(this.searchForm.get('dateTo')?.value),
     ];
  
    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
     let result: any[] = [
      this.translateService.instant('references.point'),
      this.translateService.instant('references.city'),
      this.translateService.instant('references.status'),
      this.translateService.instant('references.fromDate'),
      this.translateService.instant('references.toDate'),
   ];

   return [
     [{content: this.translateService.instant('menu.technicalPointSlots.search'), colSpan: colSpan, styles: {halign: 'center'}}],
     result
   ];
  }

}