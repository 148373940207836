<div class="container-fluid py-3" style="padding-left:30rem !important;padding-right:30rem !important">
    <div>
        <h1 class="form-group title-position" translate>roles.role</h1>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()" *ngIf="role$ | async as data; else loading">
            <!-- Data -->
            <div class="card">
                <div class="card-body">
                    <!-- name -->
                    <div class="form-group form-row">
                        <div class="col">
                            <label translate>roles.name</label>
                            <input type="text" class="form-control" formControlName="description"
                                [ngClass]="{'is-invalid': showValidations && form.description.errors }"
                                placeholder="{{'placeholder.enter' | translate}}" />
                            <small class="text-danger" *ngIf="form.description?.errors?.required && showValidations"
                                translate>
                                errors.required
                            </small>
                            <small class="text-danger" *ngIf="form.description?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:128} }}
                            </small>
                        </div>
                    </div>
                    <!-- code -->

                    <div class="form-group form-row">
                        <div class="col">
                            <label translate>roles.code</label>
                            <input type="text" class="form-control" formControlName="name"
                                [ngClass]="{'is-invalid': showValidations && form.name.errors }"
                                placeholder="{{'placeholder.enter' | translate}}" />
                            <small class="text-danger" *ngIf="form.name?.errors?.required && showValidations" translate>
                                errors.required
                            </small>
                            <small class="text-danger" *ngIf="form.name?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:128} }}
                            </small>
                        </div>
                    </div>

                    <!-- status  -->
                    <div class="form-group form-row">
                        <div class="col">
                            <label translate>service.isValid</label>
                            <select class="custom-select input-custom-size" formControlName="enabled">
                                <option *ngFor="let option of options" [value]="option.code">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- permissions -->
                    <div class="form-group form-row">
                        <div class="col" >
                            <div>
                                <label class="pr-2" translate>roles.perms</label>
                                <small class="text-danger" *ngIf="showValidations && this.permissions?.errors?.noValidElement" translate>roles.atLeastOnePerm</small>
                            </div>
                            <ng-container formArrayName="permissions">    
                                <div *ngFor="let permission of permissions.controls; let i=index" [formGroupName]="i">
                                    <label>
                                        <input type="checkbox" formControlName="isValid"/>
                                        {{permission.get('description')?.value}}
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Save button -->
            <div class="text-right">
                <button class="btn btn-primary w-20 mt-2" type="submit" translate>button.save</button>
            </div>
        </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <em class="fa fa-refresh" (click)="reload()"></em>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>