import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-base-child',
  template: ``,
  styles: [ ]
})
export class BaseChildComponent implements OnInit, OnDestroy {

  @Input() showValidations$: Observable<boolean>;
  @Input() parentId: number;
  
  protected errorMessageSubject = new Subject<string>();
  errorMessage$ = this.errorMessageSubject.asObservable();
  
  protected reloadSubject = new Subject();
  reload$ = this.reloadSubject.asObservable();
  
  showValidationsSubscription: Subscription;
  
  isOpened = false;
  isInitialized = false;
  showValidations = false;

  constructor() { }

  ngOnInit(): void {
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data,
    });
  }

  ngOnDestroy(): void {
    this.showValidationsSubscription?.unsubscribe();
  }

  initialize(): void { }

  isValid(): boolean{
    throw "IsValid is not supported from parent class";
  }

  reload():void {
    this.errorMessageSubject.next(undefined);
    this.reloadSubject.next();
  }

  compareById(optionOne: any, optionTwo: any): boolean {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  compareByCode(optionOne: any, optionTwo: any) : boolean {
    return optionOne && optionTwo && optionOne.code === optionTwo.code;
  }
}
