<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 class="my-2" translate>techPoint.techPoint.search</h3>
        </span>
    </div>
    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- subject name -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>techPoint.subjectName</span>
                </div>
                <input id="addressTypeahead" type="text" class="form-control"
                    formControlName="subject" [ngbTypeahead]="subjectVersionsSearch"
                    [inputFormatter]="subjectVersionFormatter" placeholder="Търсене на собственик" 
                    [resultTemplate]="rt"/>
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                </ng-template>
                
                <!-- number -->
                <div class="input-group-prepend ml-2">
                    <div class="input-group-text" translate>techPoint.number</div>
                </div>
                <input type="number" class="form-control" formControlName="permitNumber" id="numberInput">
                <div class="text-danger" *ngIf="form.permitNumber?.errors?.max">
                    <small translate>errors.permitNumberInvalid</small>
                </div>
                
                <!-- city -->
                <div class="input-group-prepend ml-2">
                    <div class="input-group-text" translate>techPoint.city</div>
                </div>
                <select class="custom-select input-custom-size" [compareWith]="compareByCode" formControlName="city">
                    <option *ngFor="let city of cities" [ngValue]="city"> {{city.name}}
                </select>

                <!-- actions -->
                <div class="input-group-prepend ml-2">
                    <div style= "display: flex"> 
                        <button class="btn btn-outline-success" style="border-radius: 0;" type="button" (click)="search()" translate>button.search</button>
                        <button class="btn btn-outline-danger ml-2" style="border-radius: 0;" type="button" (click)="clearSearch()" translate>button.clear</button>
                        <i class="fa fa-file-excel-o fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" style="margin-left: 10px; margin-top: 2px" (click)="exportExcel()"></i>
                        <i style="margin-left: 5px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" class="fa fa-file-pdf-o fa-2x" (click)="exportPDF()"></i>
                    </div>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class=" d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th sortBy="name" (sort)="onSort($event)" translate>techPoint.name</th>
                        <th sortBy="subjectOwner" (sort)="onSort($event)" class="w-15" translate>techPoint.subjectName</th>
                        <th sortBy="subjectOwner" (sort)="onSort($event)" class="w-15" translate>techPoint.subjectEIK</th>
                        <th sortBy="permitNumber" (sort)="onSort($event)" class="w-14" translate>techPoint.number</th>
                        <th sortBy="city" (sort)="onSort($event)" class="w-14" translate>techPoint.city</th>
                        <th sortBy="address" (sort)="onSort($event)" class="w-14" translate>techPoint.address</th>
                        <th sortBy="phoneNumber" (sort)="onSort($event)" class="w-14" translate>techPoint.phone</th>
                        <th sortBy="email" (sort)="onSort($event)" class="w-14" translate>techPoint.email</th>
                        <th class="text-center" translate>actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let technicalPoint of content">
                        <td>{{technicalPoint.name}}</td>
                        <td>{{technicalPoint.subjectName}}</td>
                        <td>{{technicalPoint.subjectIdentNum}}</td>
                        <td>{{technicalPoint.permitNumber}}</td>
                        <td>{{technicalPoint.city}}</td>
                        <td>{{technicalPoint.address}}</td>
                        <td>{{technicalPoint.phone}}</td>
                        <td>{{technicalPoint.email}}</td>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success"  data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                            routerLink="/add-edit-technical-points" *ngIf="perms.hasAccess(perms.CAN_VIEW_TECHNICAL_POINT)"
                            [queryParams]="{id: technicalPoint.id}"><i class="fa fa-pencil fa-lg"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>
