import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { SortingPaging } from '@helpers/sorting-paging';
import { Page } from '@interfaces/page';
import { forkJoin, Observable } from 'rxjs';
import { ListDailyServiceReport } from '../_models/list-daily-service-report.model';

const dailyServiceReportURL = environment.apiBaseURL + '/daily-service-report';

const pageableTotalElements      = dailyServiceReportURL + '/pageable/count';
const pageabledailyServiceReport = dailyServiceReportURL + '/pageable';

@Injectable({
  providedIn: 'root'
})
export class DailyServiceReportService {

  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPaging, searchForm: any, fromDate: string, toDate: string) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm, fromDate, toDate),
      this.countAllByFilter(sortingPaging, searchForm, fromDate, toDate)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPaging, searchForm: FormGroup, fromDate: string, toDate: string): Observable<Page<ListDailyServiceReport>> {
    return this.http.get<Page<ListDailyServiceReport>>(pageabledailyServiceReport, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  public countAllByFilter(sortingPaging: SortingPaging, searchForm: FormGroup, fromDate: string, toDate: string): Observable<number> {
    return this.http.get<number>(pageableTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPaging, searchForm: FormGroup, fromDate: string, toDate: string) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }
    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });
    requestParams = this.filter(requestParams, searchForm, fromDate, toDate);

    return requestParams;
  }

  filter(requestParams: HttpParams, searchForm : any, fromDate: string, toDate: string){
    if (searchForm != null && searchForm.techPointMobileAppId != '' && searchForm.techPointMobileAppId != null) {
      requestParams = requestParams.set('mobileAppId', searchForm.techPointMobileAppId);
    }
    
    if(fromDate && fromDate.length !== 0) {
      requestParams = requestParams.set("dateFrom", fromDate);
     }

    if(toDate && toDate.length !== 0) {
        requestParams = requestParams.set("dateTo", toDate);
    }

    return requestParams;
  }
}
