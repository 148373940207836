import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ListCustomer } from '@app/customers/_models/list-customer.model';

import { CustomerService } from '@app/customers/_services/customer.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, concatMap, first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { convertDateToString } from '@app/_utils/date-util';

@Component({
  selector: 'app-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrls: ['./list-customers.component.css']
})
export class ListCustomersComponent extends BaseSortableSearchComponent<ListCustomer> implements OnInit, OnDestroy {
  // Booleans
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    name:                     null,
    identificationNumber:     null,
    phoneNumber:              null,
    email:                    null,
    drivingLicenceNumber:     null,
    regNumber:                null
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.customerService.pageable(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        }),
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
  );

  constructor( 
    public perms: PermissionsService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private exportService: ExportService
  ) {
    super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.customersResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
    this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
      this.translateService.instant("exportData.filesNames.customersResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.customerService.findAllByFilter(sortingPagingCopy, this.searchForm.value).pipe(first()).toPromise();

    result.content.forEach(object => {
      newContent?.push([object.name, object.identificationNumber, object.phoneNumber, object.email, object.drivingLicenceNumber, object.regNumbers])
    });

    return newContent;
  }

  private getHeadings() : string[][] {
    return [[this.translateService.instant("clientProfile.clientName"), this.translateService.instant("clientProfile.clientPIN"), 
             this.translateService.instant("clientProfile.clientPhone"), this.translateService.instant("clientProfile.clientMail"), 
             this.translateService.instant("clientProfile.clientLicenseNumber"), this.translateService.instant("clientProfile.carNumber")]]
  }
  
  private getFilterBody(): any[] {
    let result = [
      this.searchForm.get('name')?.value,
      this.searchForm.get('identificationNumber')?.value, 
      this.searchForm.get('phoneNumber')?.value,
      this.searchForm.get('email')?.value,
      this.searchForm.get('drivingLicenceNumber')?.value,
      this.searchForm.get('regNumber')?.value
    ];

   return [result];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listCustomers.name'),
      this.translateService.instant('exportData.listCustomers.personalNumber'),
      this.translateService.instant('exportData.listCustomers.phoneNumber'),
      this.translateService.instant('exportData.listCustomers.email'),
      this.translateService.instant('exportData.listCustomers.clientLicenseNumber'),
      this.translateService.instant('exportData.listCustomers.regNumber')
    ];

    return [
      [{content: this.translateService.instant('menu.crm.searchListCustomers'), colSpan: colSpan, styles: {halign: 'center'}}], result
    ];
  }
  
}