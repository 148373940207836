import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-messagebox',
  templateUrl: './yes-no-messagebox.component.html',
  styleUrls: ['./yes-no-messagebox.component.css']
})
export class YesNoMessageboxComponent implements OnInit {

  message: string;
  isWarning: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<YesNoMessageboxComponent>,
  ) {
    this.message = data.message;
    this.isWarning = data.isWarning;
  }

  ngOnInit(): void {}

  onYes() {
    this.dialog.close(true);
  }

  onNo() {
    this.dialog.close(false);
  }

  closeDialog() {
    this.dialog.close();
  }

}
