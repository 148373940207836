export interface DocumentTypeEnum {
    code: string;
    name: string;
}


export const DOCUMENT_TYPES: DocumentTypeEnum[] = [
    { name: "Фактура",    code: "I" },
    { name: "Кредитно известие",   code: "C" },
    { name: "Дебитно известие",   code: "D" }
];

export const findByCode = (code: string) => DOCUMENT_TYPES.filter(type => type.code === code);