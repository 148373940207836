<div class="d-flex flex-column h-100">
	<div>
		<!-- navigation -->
		<nav class="navbar navbar-expand-md navbar-dark bg-dark"> 
			<div class="container-fluid">
				<a class="navbar-brand text-white" translate>appName</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbarCollapse"
						aria-controls="mainNavbarCollapse" aria-expanded="false" aria-label="Open menu">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="mainNavbarCollapse">
					<ul class="nav navbar-nav mr-auto">
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_ADMIN_MENU)">
							<a class="nav-link dropdown-toggle" id="adminPanelToggler" 
							   data-toggle="dropdown" translate>menu.adminPanel</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/list-roles"  translate>menu.adminPanel.perms</a>
								<a class="dropdown-item" routerLink="/list-users" translate>menu.adminPanel.users</a>
								<a class="dropdown-item" translate style="color: red;">menu.adminPanel.logs</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-header" translate>menu.adminPanel.nomenclatures</a>
								<a class="dropdown-item" routerLink="/customer-discounts" translate>menu.adminPanel.nomenclatures.customerDiscounts</a>
								<a class="dropdown-item" routerLink="/list-customer-discounts" translate>menu.adminPanel.nomenclatures.listCustomerDiscounts</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" translate style="color: red;">menu.adminPanel.loadMonitoring</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_BACK_OFFICE_MENU)">
							<a class="nav-link dropdown-toggle" id="backOfficeToggler" 
							   data-toggle="dropdown" translate>menu.backOffice</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/list-customers" *ngIf="perms.hasAccess(perms.CAN_SEARCH_CUSTOMERS)" translate>menu.crm.searchListCustomers</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS) || perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT) || perms.hasAccess(perms.CAN_ADD_EDIT_PARTNER_CONTRACT) || perms.hasAccess(perms.CAN_SERACH_PARTNERS_CONTRACTS)"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS) || perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT) || perms.hasAccess(perms.CAN_ADD_EDIT_PARTNER_CONTRACT) || perms.hasAccess(perms.CAN_SERACH_PARTNERS_CONTRACTS)" translate>menu.backOffice.contracts</a>
								<a class="dropdown-item" routerLink="/add-edit-client-contract" [queryParams]="{module: 'client'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)" translate>menu.backOffice.contracts.clients</a>
								<a class="dropdown-item" routerLink="/list-client-contracts" [queryParams]="{module: 'client'}" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS)" translate>menu.backOffice.contracts.clientContractsSearch</a>
								<a class="dropdown-item" routerLink="/add-edit-client-contract" [queryParams]="{module: 'pertner'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_PARTNER_CONTRACT)" translate>menu.backOffice.contracts.partners</a>
								<a class="dropdown-item" routerLink="/list-client-contracts" [queryParams]="{module: 'pertner'}" *ngIf="perms.hasAccess(perms.CAN_SERACH_PARTNERS_CONTRACTS)"  translate>menu.backOffice.contracts.partnerContractsSearch</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_SEARCH_ACCOUNTING_DOCUMENT)"></div>
								<a class="dropdown-item" routerLink="/list-accounting-documents" *ngIf="perms.hasAccess(perms.CAN_SEARCH_ACCOUNTING_DOCUMENT)" translate style="color: red;">menu.accountingDocument.search</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE) || perms.hasAccess(perms.CAN_SEARCH_ARTICLES)"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE) || perms.hasAccess(perms.CAN_SEARCH_ARTICLES)" translate>menu.backOffice.articles</a>
								<a class="dropdown-item" routerLink="/add-edit-service-config" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_ARTICLE)" translate>menu.backOffice.articles.add</a>
								<a class="dropdown-item" routerLink="/list-service-configs" *ngIf="perms.hasAccess(perms.CAN_SEARCH_ARTICLES)" translate>menu.backOffice.articles.search</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_SERVICE) || perms.hasAccess(perms.CAN_SEARCH_SERVICES)"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_SERVICE) || perms.hasAccess(perms.CAN_SEARCH_SERVICES)" translate>menu.backOffice.services</a>
								<a class="dropdown-item" routerLink="/add-edit-service" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_SERVICE)" translate>menu.backOffice.services.add</a>
								<a class="dropdown-item" routerLink="/list-services" *ngIf="perms.hasAccess(perms.CAN_SEARCH_SERVICES)" translate>menu.backOffice.services.search</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" translate style="color: red;">menu.backOffice.assistant</a>
								<a class="dropdown-item" translate style="color: red;">menu.backOffice.protocols</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT) || perms.hasAccess(perms.CAN_SEARCH_TECHNICAL_POINTS)"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT) || perms.hasAccess(perms.CAN_SEARCH_TECHNICAL_POINTS)" translate>menu.backOffice.technicalPoints</a>
								<a class="dropdown-item" routerLink="/add-edit-technical-points" 
								   routerLinkActive="active" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT)" translate>menu.backOffice.technicalPoints.add</a>
								<a class="dropdown-item" routerLink="/list-technical-points" *ngIf="perms.hasAccess(perms.CAN_SEARCH_TECHNICAL_POINTS)" translate>menu.backOffice.technicalPoints.search</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_TECHNICAL_CONTROL_POINT_MENU)">
							<a class="nav-link dropdown-toggle" id="crmToggler" 
							   data-toggle="dropdown" translate>menu.technicalControlPoint</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/add-edit-technical-inspection" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION)" translate>menu.technicalControlPoint.addTechnicalInspection</a>
								<a class="dropdown-item" routerLink="/list-technical-inspections" *ngIf="perms.hasAccess(perms.CAN_SEARCH_RESERVATIONS)" translate>menu.technicalControlPoint.reservationsSearch </a>
								<a class="dropdown-item" routerLink="/add-customer-subscription" *ngIf="perms.hasAccess(perms.CAN_ADD_SUBSCRIPTION_CARD)" translate>menu.subscriptionCard</a>
								<a class="dropdown-item" routerLink="/daily-service-reports" *ngIf="perms.hasAccess(perms.CAN_SEARCH_DAILY_SALES_REPORTS)"  translate>dailySalesReport.report</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS) || perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)"></div>
								 <a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS) || perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)" translate>menu.crm.contracts</a>
								 <a class="dropdown-item" routerLink="/add-edit-client-contract" [queryParams]="{module: 'client'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)" translate>menu.crm.contracts.add</a>
								 <a class="dropdown-item" routerLink="/list-client-contracts" [queryParams]="{module: 'client'}" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS)" translate>menu.crm.contracts.search</a>
						
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_CRM_MENU)">
							<a class="nav-link dropdown-toggle" id="crmToggler" 
							   data-toggle="dropdown" translate>menu.crm</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/list-customers" *ngIf="perms.hasAccess(perms.CAN_SEARCH_CUSTOMERS)" translate>menu.crm.searchListCustomers</a>
								<div class="dropdown-divider" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS) || perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS) || perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)" translate>menu.crm.contracts</a>
								<a class="dropdown-item" routerLink="/add-edit-client-contract" [queryParams]="{module: 'client'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_CORPORATE_CLIENTS_CONTRACT)" translate>menu.crm.contracts.add</a>
								<a class="dropdown-item" routerLink="/list-client-contracts" [queryParams]="{module: 'client'}" *ngIf="perms.hasAccess(perms.CAN_SERACH_CORPORATE_CLIENTS_CONTRACTS)" translate>menu.crm.contracts.search</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_MARKETING_MENU)">
							<a class="nav-link dropdown-toggle" id="marketingToggler"
							   data-toggle="dropdown" translate>menu.marketing</a>
							   <div class="dropdown-menu">
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_PROMOTION) || perms.hasAccess(perms.CAN_SEARCH_PROMOTIONS)" translate>menu.marketing.promotions</a>
								<a class="dropdown-item" routerLink="/add-edit-promotion" [queryParams]="{module: 'discount'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_PROMOTION)" translate>menu.marketing.promotions.add</a>
								<a class="dropdown-item" routerLink="/list-promotions" [queryParams]="{module: 'discount'}" *ngIf="perms.hasAccess(perms.CAN_SEARCH_PROMOTIONS)" translate>menu.marketing.promotions.search</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_PROMOCODE) || perms.hasAccess(perms.CAN_SEARCH_PROMOCODES)" translate>menu.marketing.promoCodes</a>
								<a class="dropdown-item" routerLink="/add-edit-promotion" [queryParams]="{module: 'promocode'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_PROMOCODE)" translate>menu.marketing.promoCodes.add</a>
								<a class="dropdown-item" routerLink="/list-promotions" [queryParams]="{module: 'promocode'}" *ngIf="perms.hasAccess(perms.CAN_SEARCH_PROMOCODES)" translate>menu.marketing.promoCodes.search</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-header" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_INVITE_FRIEND) || perms.hasAccess(perms.CAN_SEARCH_INVITE_FRIENDS)" translate>menu.marketing.inviteFriends</a>
								<a class="dropdown-item" routerLink="/add-edit-promotion" [queryParams]="{module: 'invite-friend'}" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_INVITE_FRIEND)" translate>menu.marketing.inviteFriends.add</a>
								<a class="dropdown-item" routerLink="/list-promotions" [queryParams]="{module: 'invite-friend'}" *ngIf="perms.hasAccess(perms.CAN_SEARCH_INVITE_FRIENDS)" translate>menu.marketing.inviteFriends.search</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-header" translate>menu.adminPanel.adds</a>
								<a class="dropdown-item" routerLink="/site-page-management" translate>menu.adminPanel.addModal</a>
								<a class="dropdown-item" routerLink="/list-site-page-management" translate>menu.adminPanel.searchModal</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_CALL_CENTER_MENU)">
							<a class="nav-link dropdown-toggle" id="callCenterToggler" data-toggle="dropdown" translate>menu.callcenter</a>
							<div class="dropdown-menu"> 
								<a class="dropdown-item" routerLink="/add-edit-technical-inspection-cc" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION_CC)" translate>menu.technicalControlPoint.addTechnicalInspection</a>
								<a class="dropdown-item" routerLink="/list-technical-inspections" *ngIf="perms.hasAccess(perms.CAN_SEARCH_RESERVATIONS)" translate>menu.reservationManagementSearch</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_PARTNERS_MENU)">
							<a class="nav-link dropdown-toggle" id="partnersToggler" data-toggle="dropdown" translate>menu.partners</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/list-technical-inspections" *ngIf="perms.hasAccess(perms.CAN_SEARCH_RESERVATIONS)" translate>menu.technicalControlPoint.reservationsSearch </a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_FINANCES_MENU)">
							<a class="nav-link dropdown-toggle" id="partnersToggler" data-toggle="dropdown" translate>menu.finances</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/list-accounting-documents" *ngIf="perms.hasAccess(perms.CAN_SEARCH_ACCOUNTING_DOCUMENT)" translate>menu.accountingDocument.search</a>
								<a class="dropdown-item" routerLink="/income-by-services" *ngIf="perms.hasAccess(perms.CAN_VIEW_INCOME_BY_SERVICES)" translate>references.incomeByServices</a>
								<a class="dropdown-item" routerLink="/income-by-stickers" *ngIf="perms.hasAccess(perms.CAN_VIEW_INCOME_BY_STICKERS)" translate>references.incomeByStickers</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_REFERENCES_MENU)">
							<a class="nav-link dropdown-toggle" id="partnersToggler" 
							   data-toggle="dropdown" translate>menu.references</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/statistics-inspections" *ngIf="perms.hasAccess(perms.CAN_VIEW_REFERENCES_MENU)" translate>references.statisticsInspections</a>
								<a class="dropdown-item" routerLink="/daily-service-reports" *ngIf="perms.hasAccess(perms.CAN_VIEW_REFERENCES_MENU)" translate>references.dailyReport</a>
								<a class="dropdown-item" routerLink="/daily-service-reports-single" *ngIf="perms.hasAccess(perms.CAN_VIEW_REFERENCES_MENU)" translate>references.dailyReportSingle</a>
								<a class="dropdown-item" routerLink="/expiring-services" *ngIf="perms.hasAccess(perms.CAN_VIEW_REFERENCES_MENU)" translate>references.expiringServices</a>
								<a class="dropdown-item" routerLink="/technical-inspection-slots" *ngIf="perms.hasAccess(perms.CAN_VIEW_REFERENCES_MENU) && perms.hasAccess(perms.CAN_VIEW_TECH_POINT_SLOTS_REFERENCE)" translate>references.technicalInspectionSlots</a>
							</div>
						</li>
						<li class="nav-item dropdown" *ngIf="perms.hasAccess(perms.CAN_VIEW_LOYALTY_PROGRAM_MENU)">
							<a class="nav-link dropdown-toggle" id="partnersToggler" 
							   data-toggle="dropdown" translate>menu.loyalProgram</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" routerLink="/add-program" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_LOYALTY_PROGRAM)" translate>menu.loyalProgram.createProgram</a>
								<a class="dropdown-item" routerLink="/search-program-card-loyal" *ngIf="perms.hasAccess(perms.CAN_SEARCH_LOYALTY_PROGRAM)" translate>menu.loyalProgram.searchPartners</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" routerLink="/generator-card-loyal" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_LOYALTY_CARD)" translate>menu.loyalProgram.createCards</a>
								<a class="dropdown-item" routerLink="/search-card-loyal" *ngIf="perms.hasAccess(perms.CAN_SEARCH_LOYALTY_CARD)" translate>menu.loyalProgram.searchCards</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" routerLink="/generator-partner-card" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_LOYALTY_CARD)" translate>menu.loyalProgram.createPartnerCard</a>
								<a class="dropdown-item" routerLink="/generator-update-partner-card" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_LOYALTY_CARD)" translate>menu.loyalProgram.updatePartnerCard</a>
								<a class="dropdown-item" routerLink="/search-partner-card" *ngIf="perms.hasAccess(perms.CAN_SEARCH_LOYALTY_PROGRAM)" translate>menu.loyalProgram.searchProgram</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- user profile -->
			<div *ngIf="loggedIn" class="d-flex flex-row">
				<div *ngIf="loadUserProfile$ | async as data" >
					<div *ngIf="technicalPointName != ''" class="text-white mr-3" style="white-space: nowrap;">{{technicalPointName}}</div>
				</div>
				<div class="dropdown">
					<em class="fa fa-user-circle text-white mt-1 mr-3 dropdown-toggle" data-toggle="dropdown" ></em>
					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
						<li class="dropdown-header" style="padding-bottom: 0.20rem" translate>userProfile.username</li>
						<label class="ml-4 mr-4" style=" font-size: 15.5px" >{{username}}</label>
						<ng-container *ngIf="isUserProfileReload">
							<li class="dropdown-header" style="padding-bottom: 0.20rem" translate>userProfile.fullName</li>
							<label class="ml-4 mr-4" style=" font-size: 15.5px">{{employeeName}}</label>
							<li class="dropdown-header" style="padding-bottom: 0.20rem" translate>userProfile.email</li>
							<label class="ml-4 mr-4" style=" font-size: 15.5px">{{userProfileData.email}}</label>
						</ng-container>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" style=" font-size: 15.5px" (click)="openModal()" translate>userProfile.changePassword</a>
						<a class="dropdown-item" style=" font-size: 15.5px" (click)="logout()" translate>users.logOut</a>
					</div>
				</div>
			</div>
		</nav>
	</div>
	<div class="flex-fill">
		<router-outlet></router-outlet>
	</div>
</div>