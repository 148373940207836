import { AuthenticationService } from './login/_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { modalMinWidth } from '@env/config';
import { PermissionsService } from './login/_services/permissions.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileService } from './user-profile/_services/user-profile.service';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { UserProfile } from './user-profile/_models/UserProfile.model';
import { TechnicalPointService } from './tech-points/_services/technical-point.service';
import { displayError } from './_utils/error-util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  // Units
  logoutMsgTimer:     any;
  logoutTimer:        any;
  loggedIn:           boolean;
  userProfileData:    UserProfile;
  technicalPointName: string = '';

  // Booleans
  isUserProfileReload: boolean;

  // Subscriptions
  loginSub: Subscription;
  reload$:  any;
  errorMessageSubject: Subject<string> = new Subject<string>();
  
  userProfile: Subscription;
  loadUserProfile$ = this.route.queryParams.pipe(
    tap(() => this.isUserProfileReload = false),
    concatMap(params => {
      return (this.userProfileService.findEmployeeById(AuthenticationService.getEmployeeId()).pipe(
        tap(data => {
          this.technicalPointName = '';
          this.userProfileData = data;
          this.isUserProfileReload = true;
          this.loadTechnicalPoint();
        }),
        catchError(err => {
          displayError(err);
          this.isUserProfileReload = false;
          this.errorMessageSubject.next(this.translate.instant('messages.errorLoadingData'));
          return EMPTY;
        })
      ));
    })
  );

  constructor(
    private router:             Router,
    private dialog:             MatDialog,
    private route:              ActivatedRoute,
    private translate:          TranslateService,
    public  perms:              PermissionsService,
    private userProfileService: UserProfileService,
    private techPointService:   TechnicalPointService,
    private auth:               AuthenticationService,

  ) {
    this.loginSub = auth.loginSubject.subscribe({
      next: (data) => this.loggedIn = data
    });

    translate.addLangs(['bg', 'eu']);
    translate.setDefaultLang('bg');
    this.useLanguage('bg');
  }

  ngOnDestroy() {
    this.loginSub?.unsubscribe();
  }

  public useLanguage(language: string) {
    this.translate.use(language);
  }

  public logout() {
    this.auth.logout();
    this.router.navigate(['login']);
  }

  loadTechnicalPoint() {
    const availableTechPoint = localStorage.getItem('techPointName');
    if (availableTechPoint) {
      this.technicalPointName = availableTechPoint;
      return;
    }

    const userTechnicalPointMobileAppIds = AuthenticationService.getEmployeeTechPointsIds();
    if (userTechnicalPointMobileAppIds.length != 0) {
      this.techPointService.findTechnicalPointsByIds(userTechnicalPointMobileAppIds).subscribe(resp => {
        this.technicalPointName = resp[0].name;
        localStorage.setItem('techPointName', this.technicalPointName);
      });
    }
  }

  openModal() {
    if (this.dialog.openDialogs.length == 0) {
        let ref = this.dialog.open(UserProfileComponent, modalMinWidth);
    }
  }

  get employeeName() {
    return AuthenticationService.getEmployeeName();
  }

  get username() {
    return AuthenticationService.getUsername();
  }

}