import { AbstractControl, ValidatorFn } from "@angular/forms";

export class UtilValidators {

    public static validatePhoneNumber(): ValidatorFn | any {
      return (control: AbstractControl) => {
        if (control.value === '' || control.value == null) return null;

        let isValid = false;

        if (/^(0|\+359)(8|9)[789]/.test(control.value)) {
          isValid = /^(0|\+359)(8|9)[789]\d{7}$/.test(control.value); 
        } else {
          isValid = /^(0|\+359)\d{8}$/.test(control.value);
        }
        
        return isValid ? null : { invalidPhoneNumber: true };  
      }
    }

    public static validateBicLength(): ValidatorFn | any {
      return (control: AbstractControl) => {
        if (control.value === '' || control.value == null) return null;
        let isValid = false;

        if(control.value.length == 8 || control.value.length == 11){
          isValid = true;
        }
      
        return isValid ? null : { invalidBic: true };  
      }
    }

    public static isInvalidInvoiceDataType(): ValidatorFn | any {
      return (control: AbstractControl) => {
        if (control.value === '' || control.value == null) return null;
        let isValid = false;

        if(typeof control.value == 'object'){
          isValid = true;
        }
      
        return isValid ? null : { invalidInvoice: true };  
      }
    }
}