import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { City } from '@models/city.model';
import { NomenclatureService } from '@services/nomenclature.service';
import { EMPTY, forkJoin, Observable, Subscription } from 'rxjs';
import { catchError, exhaustMap, repeatWhen, take, tap } from 'rxjs/operators';
import { DAYS_OF_THE_WEEK } from '@app/_enums/days-of-the-week';
import { TimeConstraint } from '@models/time-constraint.model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PromotionService } from '@app/promotions/_services/promotion.service';
import { PromotionTechnicalPoint } from '@app/promotions/_models/promotion-technical-point.model';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { PromConciseTechPoint } from '@app/tech-points/_models/prom-concise-technical-point.model';
import { PromotionValidators } from '@app/_validators/promotion.validator';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { ConciseTechPoint } from '@app/tech-points/_models/concise-technical-point.model';

@Component({
  selector: 'app-promotion-technical-points',
  templateUrl: './promotion-technical-points.component.html',
  styleUrls: ['./promotion-technical-points.component.css']
})
export class PromotionTechnicalPointsComponent extends BaseChildComponent implements OnInit, OnDestroy {
  
  // Units
  cities: City[];
  availableTechPoints: ConciseTechPoint[];
  conciseTechPoints:   ConciseTechPoint[];

  // Booleans
  isAllDaysCheckBoxClickedOnce = false;
  
  // Constants 
  readonly ACTIVE_STATUS_CODE: string = 'ACTIVE';
  
  // Property decorators
  @Input() changedCategories$: Observable<number[]>;
  @Input() module: string;
  @Input() statusCode: string | undefined;
  @Input() isDisabled: boolean;

  // Form
  techPointsForm = this.formBuilder.group({
    techPoints: this.formBuilder.array([], Validators.required)
  });

  // Observables
  private baseSub: Subscription = new Subscription();

  constructor(
    private formBuilder:          FormBuilder,
    public  perms:                PermissionsService,
    private translateService:     TranslateService,
    private promotionService:     PromotionService,
    private nomenclatureService:  NomenclatureService,
    private techPointService:     TechnicalPointService,
    private uiEvent:              UIEventCustom
  ) {
    super();
  }

  ngOnInit() {
    this.findAllConciseTechPoints();
    let changedCategoriesSub = this.changedCategories$.pipe(
      exhaustMap(ids => this.techPointService.findConciseTechPointList()),
      tap(tps => this.availableTechPoints = tps),
      tap(() => {
        this.techPointsForms.controls.forEach((control) => this.updateTechPointsOnServicesChange(control))
      })
    ).subscribe();

    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data,
    });
  
    this.baseSub.add(changedCategoriesSub);
  }

  ngOnDestroy() {
    this.baseSub?.unsubscribe();
  }

  private findAllConciseTechPoints() {
    this.techPointService.findConciseTechPointList().subscribe(data => {
      this.conciseTechPoints = data;
    });
  }

  initialize() {
    if (this.isInitialized) return; 
    this.isInitialized = true;
    
    return forkJoin([
      this.promotionService.findTechnicalPointByPromotionId(this.parentId),
      this.nomenclatureService.getCities()
    ]).pipe(
      tap(([technicalPoints, cities]) => {
        this.cities = cities;
        technicalPoints?.forEach(technicalPoint => this.loadPromotionTechnicalPoint(technicalPoint));
        this.isInitialized = true;
      }),
      catchError(err => {
        displayError(err);
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).pipe(take(1)).toPromise();
  }

  addPromotionTechPoint() {
    this.loadPromotionTechnicalPoint(null);
  }

  deleteTechPoint(index: number) {
    this.techPointsForms.removeAt(index);
  }

  loadPromotionTechnicalPoint(promotionTechnicalPoint: PromotionTechnicalPoint | null) {
    let techPointForm = this.formBuilder.group({
      id:                    [promotionTechnicalPoint?.id || null], 
      technicalPoint:        [promotionTechnicalPoint?.technicalPoint || null, Validators.required],
      workingHours:          this.formBuilder.array([], PromotionValidators.validateWorkingHours()),
      city:                  [this.findCityByCityCode(promotionTechnicalPoint?.technicalPoint?.cityCode || null)],
      filterdTechPoints:     [this.availableTechPoints]
    });

    let citySub = techPointForm.get('city')?.valueChanges.subscribe((city: City) => {
      this.updateTechPoints(city?.code || null, techPointForm);
    });

    if (techPointForm.get('technicalPoint')?.value != null) {
      let workingHoursSub = this.techPointService.findTechPointWorkingHours(techPointForm?.get('technicalPoint')?.value?.id).pipe(
        tap(slots => {
          let result = this.loadWorkingHours(slots, promotionTechnicalPoint?.workingHours || null);
          let workingHours = techPointForm.get('workingHours') as FormArray;
          workingHours?.clear();
  
          result.forEach(wkf => workingHours.push(wkf));
        })
      ).subscribe();

      this.baseSub.add(workingHoursSub);
    }
   
    this.baseSub.add(citySub);

    if (promotionTechnicalPoint != null) {
      techPointForm.get('technicalPoint')?.patchValue(promotionTechnicalPoint.technicalPoint);
    }

    this.techPointsForms.push(techPointForm);
  }

  public technicalPointChange (tp: PromConciseTechPoint, techPointForm: AbstractControl) {
    this.techPointService.findTechPointWorkingHours(tp?.id).pipe(
      tap(slots => {
        let result = this.loadWorkingHours(slots, null);
        let workingHours = techPointForm.get('workingHours') as FormArray;
        workingHours?.clear();

        result.forEach(wkf => workingHours.push(wkf));
      })
    ).subscribe();
  }

  private updateTechPointsOnServicesChange(control: AbstractControl) {
    let cityCode = control.get('city')?.value?.code || null;
    this.updateTechPoints(cityCode, control);
  }

  private updateTechPoints(cityCode: string | null, control: AbstractControl) {
    let filteredTechPoints = this.filterByCityCode(cityCode);
    control.get('filterdTechPoints')?.patchValue(filteredTechPoints);
    
    let techPointControl = control.get('technicalPoint');
    let isContained = filteredTechPoints.map(tp => tp.id).includes(techPointControl?.value?.id);

    if (!isContained) {
      // if(control.get('technicalPoint')?.value != null) {
      //   this.techPointsForms.controls.splice(this.techPointsForms.controls.indexOf(control))
      // }

      control.get('id')?.patchValue(null);
      techPointControl?.reset();
  
      let workingHoursControl = control.get('workingHours') as FormArray;
      workingHoursControl?.clear();
    }
  }

  private findCityByCityCode(code: string | null) {
    if (code == null) return null;

    let result = this.cities.filter(city => city.code === code);
    return result[0] || null;
  }

  private filterByCityCode(cityCode: string | null) {
    if (this.availableTechPoints == null) return [];
    if (cityCode == null) return this.availableTechPoints;

    // return this.availableTechPoints.filter(tp => tp.cityCode === cityCode);
    return this.availableTechPoints;
  }

  private loadWorkingHours(timeSlots: Map<number, TimeConstraint[]>, existingWorkingHours: TimeConstraint[] | null) {
    if (timeSlots == null) return [];

    let result: FormGroup[] = [];
    
    for (let day of DAYS_OF_THE_WEEK) {
      let slotsByDay = timeSlots.get(day.code);
      let filteredExistingHours = this.filterPromWorkingHours(day.code, existingWorkingHours);

      let dayGroup = this.formBuilder.group({
        day:       [day],
        timeSlots: this.formBuilder.array(this.loadTimeSlots(slotsByDay, filteredExistingHours)),
        isFull:    [false],
      });

      if (filteredExistingHours != null) {
        let timeSlots = dayGroup.get('timeSlots') as FormArray;
        let isSelectedValues: any = [];

        timeSlots.controls.forEach(control => {
          isSelectedValues.push(control.get('isSelected')?.value)
        });

        if (isSelectedValues.includes(false)) {
          dayGroup.get('isFull')?.patchValue(false);
        } else {
          dayGroup.get('isFull')?.patchValue(true);
        }
      }

      let isFullSub = dayGroup.get('isFull')?.valueChanges.subscribe(isSelected => {
        let timeSlots = dayGroup.get('timeSlots') as FormArray;
        timeSlots.controls.forEach(control => control.get('isSelected')?.patchValue(isSelected));
      });

      let timeSlotsSub = dayGroup.get('timeSlots')?.valueChanges.subscribe((timeSlots: any[]) => {
        let isFullControl = dayGroup.get('isFull');
        let isFullCurrValue = isFullControl?.value as boolean;
        let areAllSelected = timeSlots.every(ts => ts.isSelected);

        if (isFullCurrValue != areAllSelected) {
          isFullControl?.patchValue(areAllSelected, {emitEvent: false});
        }
      });

      this.baseSub.add(isFullSub);
      this.baseSub.add(timeSlotsSub);

      result.push(dayGroup);
    }

    return result;
  }

  private filterPromWorkingHours(day: number, existingWorkingHours: TimeConstraint[] | null) {
    if (existingWorkingHours == null) return null;
    return existingWorkingHours.filter(hour => hour.dayOfTheWeek == day);
  }

  private loadTimeSlots(timeSlots: TimeConstraint[] | undefined, existingWorkingHours: TimeConstraint[] | null) {
    if (timeSlots == undefined) return [];

    let result: FormGroup[] = [];

    timeSlots.forEach(slot => {
      result.push(this.formBuilder.group({
        timeSlot:   [slot],
        isSelected: [this.checkTimeSlotId(slot, existingWorkingHours)]
      }));
    });

    return result;
  }

  private checkTimeSlotId(timeSlot: TimeConstraint | null, existingWorkingHours: TimeConstraint[] | null): boolean {
    if (existingWorkingHours == null || timeSlot == null) {
      return false;
    }

    return existingWorkingHours.some(wh => wh.id === timeSlot.id);
  }

  validateTechPoints() {
    let techPointsValid = this.techPointsForm.valid;

    if (!techPointsValid) {
      this.isOpened = true;
    }

    return techPointsValid;
  }

  constructPromTechPoints() {
    if (!this.isInitialized) {
      return null;
    }

    let techPoints = this.techPointsForms.value as any[];
    return techPoints.map(tp => this.constructTechPoint(tp));
  }

  private constructTechPoint(techPoint: any): PromotionTechnicalPoint {
    return {
      id:             techPoint?.id || null,
      technicalPoint: techPoint?.technicalPoint || null,
      isValid:        true,
      workingHours:   this.filterSelectedWorkingHours(techPoint?.workingHours)
    }
  }

  private filterSelectedWorkingHours(workingHours: any[]): TimeConstraint[] {
    if (workingHours == null) {
      return [];
    }

    let result: TimeConstraint[] = [];

    workingHours.forEach(day => {
      let timeSlots = day.timeSlots as any[];
      timeSlots.filter(slot => slot.isSelected).map(slot => slot.timeSlot).forEach(slot => result.push(slot));
    });

    return result;
  }

  getDayFromtechPoint(tp: AbstractControl) {
    let array = tp.get('workingHours') as FormArray;
    return array?.controls;
  }

  getTimeSlotsFromDay(day: AbstractControl) {
    let workingHours = day.get('timeSlots') as FormArray;
    return workingHours?.controls;
  }

  isTechnicalPointSelected(techPoint: PromConciseTechPoint | ConciseTechPoint) {
    let technicalPoints = this.techPointsForm.get('techPoints') as FormArray;
    let technicalPointsIds: number[] = [];

    if (techPoint == null || technicalPoints.length == 0) {
      return false;
    }

    technicalPoints?.controls.forEach(tp => {
      if (tp?.get('technicalPoint')?.value != null) {
        technicalPointsIds.push(tp.get('technicalPoint')?.value.id);
      }
    })

    if (technicalPointsIds.includes(techPoint.id)) {
      return true;
    } 

    return false;
  }

  selectAllDaysForPoint(techPoint: any, event: MouseEvent) {
    const checkbox = event.target as HTMLInputElement;

    if (techPoint && techPoint.value && techPoint.value.technicalPoint) {
      const tp = this.fillTechPointWorkingHours(techPoint.value);
      if (!this.isAllDaysCheckBoxClickedOnce) {
        this.isAllDaysCheckBoxClickedOnce = true;
      }
  
      const arrays = this.techPointsForm.get('techPoints') as FormArray;
      arrays.controls.forEach((control: AbstractControl) => {
        if (control instanceof FormGroup) {
          const technicalPoint = control.get('technicalPoint')?.value;
  
          if (technicalPoint && technicalPoint.name === tp.technicalPoint.name) {
            control.patchValue(tp);
          }
        }
      });
    }

    if (checkbox.checked) {
      this.uiEvent.displayUISuccess('Успешно избрахте всички слотове за текущия пункт');
      setTimeout(() => {
        checkbox.checked = false;
      }, 5000);
    }
  }

  private fillTechPointWorkingHours(techPoint: any) {
    const tp = techPoint;

    let workingHours: { day: any; timeSlots: any[]; isFull: boolean; }[] = [];
    tp.workingHours.forEach((wh: { timeSlots: any[]; day: any; }) => {
      let slots: { timeSlot: any }[] = [];
      wh.timeSlots.forEach(ts => {
        ts.isSelected = true;
        slots.push(ts);
      });
      workingHours.push({
        day: wh.day,
        timeSlots: slots,
        isFull: true
      });
    });

    tp.workingHours = workingHours;
    return tp;
  }

  get techPointsForms(): FormArray {
    return this.techPointsForm.get('techPoints') as FormArray;
  } 

  get canAddEdit() {
    switch(this.module) {
      case 'discount': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOTION);
      case 'promocode': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOCODE);
      case 'invite-friend': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_INVITE_FRIEND);
      default: 
        return false;
    }
  }

}