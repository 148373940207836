import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { EMPTY } from 'rxjs';
import { CustomerService } from '@app/customers/_services/customer.service';
import { catchError, concatMap, repeatWhen } from 'rxjs/operators';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { ClientProfileRvsComponent } from './client-profile-rvs/client-profile-rvs.component';
import { ClientProfileCardsComponent } from './client-profile-cards/client-profile-cards.component';
import { ClientProfileRemindersComponent } from './client-profile-reminders/client-profile-reminders.component';
import { ClientProfilePromoCodesComponent } from './client-profile-promo-codes/client-profile-promo-codes.component';
import { ClientProfileInvoiceDataComponent } from './client-profile-invoice-data/client-profile-invoice-data.component';
import { ClientProfilePurchaseHistoryComponent } from './client-profile-purchase-history/client-profile-purchase-history.component';
import { ClientProfileNotificationsHistoryComponent } from './client-profile-notifications-history/client-profile-notifications-history.component';
import { ClientProfileNotificationsSettingsComponent } from './client-profile-notifications-settings/client-profile-notifications-settings.component';

@Component({
  selector: 'app-add-edit-client-profile',
  templateUrl: './add-edit-client-profile.component.html',
  styleUrls: ['./add-edit-client-profile.component.css']
})
export class AddEditClientProfileComponent extends BaseParentComponent implements OnInit {
  @ViewChild('rvs') rvsComponent: ClientProfileRvsComponent;
  @ViewChild('cards') cardsComponent: ClientProfileCardsComponent;
  @ViewChild('reminders') remindersComponent: ClientProfileRemindersComponent;
  @ViewChild('invoice') invoiceDataComponent: ClientProfileInvoiceDataComponent;
  @ViewChild('promoCodes') promoCodeComponent: ClientProfilePromoCodesComponent;
  @ViewChild('purchaseHistory') purchaseHistoryComponent: ClientProfilePurchaseHistoryComponent;
  @ViewChild('notificationsHistory') notificationsHistoryComponent: ClientProfileNotificationsHistoryComponent;
  @ViewChild('notificationsSettings') notificationsSettingsComponent: ClientProfileNotificationsSettingsComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private translateService: TranslateService
  ) {
    super(router);
  }

  ngOnInit(): void { }
  
  loadCustomer$ = this.route.queryParams.pipe(
    concatMap(params => {
      return (this.customerService.findCustomerById(params['id']).pipe(
        catchError(err => {
          console.log(err)
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      ));
    })
  );

  initializeSection(code: string): void {
    switch (code) {
      case 'rvs':
        this.rvsComponent.initialize();
        break;
      case 'invoice':
        this.invoiceDataComponent.initialize();
        break;
      case 'promoCodes':
        this.promoCodeComponent.initialize();
        break;
      case 'purchaseHistory':
        this.purchaseHistoryComponent.initialize();
        break;
      case 'cards':
        this.cardsComponent.initialize();
        break;
      case 'notificationsHistory':
        this.notificationsHistoryComponent.initialize();
        break;
      case 'notificationsSettings':
        this.notificationsSettingsComponent.initialize();
        break;
      case 'reminders':
        this.remindersComponent.initialize();
        break;
      default:
    }
  }
}
