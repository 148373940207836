import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { convertDateToString } from "@app/_utils/date-util";
import { displayError, displayErrorFromUnknown } from "@app/_utils/error-util";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { Service } from "@app/services/_models/service.model";
import { BaseSearchComponent } from "@components/_base/base-search/base-search.component";
import { BaseSortableSearchComponent } from "@components/_base/base-search/base-sortable-search.component";
import { SortingPaging } from "@helpers/sorting-paging";
import { SortingPagingData } from "@helpers/sorting-paging-data";
import { ServiceType } from "@models/service-type.model";
import { TranslateService } from "@ngx-translate/core";
import { ExportService } from "@services/export.service";
import { NomenclatureService } from "@services/nomenclature.service";
import { EMPTY, Subscription } from "rxjs";
import { catchError, concatMap, first, repeatWhen, tap } from "rxjs/operators";

@Component({
    selector: 'list-services',
    templateUrl: './list-services.component.html',
    styleUrls: ['./list-services.component.css']
})

export class ListServicesComponent extends BaseSortableSearchComponent<Service> implements OnInit, OnDestroy {
  // Units
  serviceTypes: ServiceType[];

  // Constants
  readonly SERVICE_TYPE_CODE = 'S';

  // Booleans
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    serviceType:        null,
    isValid:            null,
    serviceTypeCode:    this.SERVICE_TYPE_CODE
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.nomenclatureService.pageableServices(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
  );

  loadSearchForm$ = this.nomenclatureService.getServiceTypesByCode(this.SERVICE_TYPE_CODE).pipe(
    tap((serviceTypes) => {
      this.serviceTypes = serviceTypes;
      this.searchSubject.next();
    }),
    catchError(err => {
      displayError(err);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  );

  constructor(
    private formBuilder: FormBuilder,
    public  perms: PermissionsService,
    private nomenclatureService: NomenclatureService,
    private translateService: TranslateService,
    private exportService: ExportService
  ) {
    super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  clearSearch(){
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      serviceType:         null,
      isValid:            null,
      serviceTypeCode:    this.SERVICE_TYPE_CODE
    });

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.servicesResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
  
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.servicesResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }

  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;
    
    let result = await this.nomenclatureService.findAllServicesByFilter(sortingPagingCopy, this.searchForm.value).pipe(first()).toPromise();

    result.content.forEach(object => {
      let isValid = object.isValid == true? this.translateService.instant("yes") : this.translateService.instant("no");
      newContent?.push([object.orderIndex, object.name, object.serviceType.description, isValid])
    });

    return newContent;
  }

  private getHeadings() : string[][] {
    return [[this.translateService.instant("service.orderIndex"), this.translateService.instant("service.name"), 
            this.translateService.instant("service.serviceType"), this.translateService.instant("service.isValid")]]
  }
  
  private getFilterBody(): any[] {
    let isValid = '';
    if (this.searchForm.get('isValid')?.value !== null) {
      isValid = this.searchForm.get('isValid')?.value == 'true' ? this.translateService.instant("yes") : this.translateService.instant("no");
    }

    let serviceTypeDesc = '';
    this.serviceTypes.forEach(serviceType => {
      if (serviceType.id == this.searchForm.get('serviceType')?.value) {
        serviceTypeDesc = serviceType.description;
      }
    });

    let result: string[]= [
      serviceTypeDesc,
      isValid
    ];

    return [result];
  }

  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listServices.serviceType'),
      this.translateService.instant('exportData.listServices.isValid')
    ];

    return [
      [{content: this.translateService.instant('menu.backOffice.services.search'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }
  
}