import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class DateValidators {

  public static groupValidator(fromDate: string, toDate: string): ValidatorFn | any {
    return (controls: AbstractControl) => {
      const fromCtrl = controls.get(fromDate);
      const toCtrl = controls.get(toDate);

      return new Date(fromCtrl?.value) > new Date(toCtrl?.value) ? { message: 'error message' } : null;

    }
  }


}