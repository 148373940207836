import { Component, OnInit } from '@angular/core';
import { SitPageManagementService } from '../services/sit-page-management.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { TranslateService } from '@ngx-translate/core';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { displayError } from '@app/_utils/error-util';
import { forkJoin, EMPTY } from 'rxjs';
import { concatMap, tap, catchError, repeatWhen } from 'rxjs/operators';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { TechnicalPoint } from '@models/technical-point';

@Component({
  selector: 'app-site-page-management',
  templateUrl: './site-page-management.component.html',
  styleUrls: ['./site-page-management.component.css']
})
export class SitePageManagementComponent extends BaseParentComponent implements OnInit {

  // Units
  sitePageManagementId:   number;
  fileName                = '';
  formData:               FormData;
  invalidFileType         = false;
  fileURL                 = '';
  listSitePageUrl         = '/list-site-page-management';
  technicalPoints:        any[];
  imageUrl: string | ArrayBuffer | null = null;

  // Form
  addEditForm = this.formBuilder.group({
    id:                       '',
    pageNumber:               [null, Validators.required],
    showAddMiliseconds:       [null, Validators.required],
    pathToFtp:                '',
    file:                     [null, Validators.required],
    isActive:                 [true, Validators.required],
    techPointNames:           ''
  });

  // Observables
  siteManagement$ = this.route.queryParams.pipe(
    concatMap(params => {
      return forkJoin([
          this.siteManagement.findById(params['id'])
      ]).pipe(
          tap(() => this.sitePageManagementId = params['id']),
          tap(([sitePageManagement]) => {
            this.addEditForm.patchValue(sitePageManagement);
          }),
          catchError(err => {
            displayError(err);
            this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
            return EMPTY;
          }),
          repeatWhen(() => this.reload$)
      );
    })
  );

  constructor(
    private formBuilder:            FormBuilder, 
    private route:                  ActivatedRoute,
    protected router:               Router,
    private uiEvent:                UIEventCustom,
    private translateService:       TranslateService,
    private siteManagement:         SitPageManagementService,
    private technicalPointService:  TechnicalPointService) {
      super(router);
  }

  ngOnInit() {
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data
    });
    this.getTechnicalPoints();
  }

  private getTechnicalPoints() {
    this.technicalPointService.findTechnicalPoints().subscribe(data => {
      this.technicalPoints = data;
    });
  }

  onSubmit() {
    if (!this.addEditForm.valid) {
      this.uiEvent.displayUIError();
      this.showValidationsSubject.next(true);
      return;
    }

    const model = this.constructModel();
    if (this.sitePageManagementId) {
      this.sendUpdateReq(model);
      return;
    }

    this.sendSaveReq(model);
  }

  private sendSaveReq(model: any) {
    this.siteManagement.save(model).subscribe({
      next: (id) => {
        this.uiEvent.displayUISuccess();
        this.router.navigate([this.listSitePageUrl]);
      },
      error: err => {
        displayError(err);
      } 
    });
  }

  private sendUpdateReq(model: any) {
    this.siteManagement.update(this.sitePageManagementId, model).subscribe({
      next: (id) => {
        this.uiEvent.displayUISuccess();
        this.router.navigate([this.listSitePageUrl]);
      },
      error: err => {
        displayError(err);
      } 
    });
  }

  private constructModel() {
    const formResult = this.addEditForm.value;
    const { file, techPointNames, ...resultWithoutFile } = formResult;
  
    resultWithoutFile.pathToFtp = this.fileURL;

    if (this.sitePageManagementId) {
      resultWithoutFile.id = this.sitePageManagementId;
    }

    resultWithoutFile.techPointNames = techPointNames.join(", ");
    
    return resultWithoutFile;
  }
  
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (!file) {
      return;
    }
    this.getImageDataUrl(file);

    const allowedExtensions = /(\.png|\.jpg|\.jpeg|\.gif|\.svg)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.invalidFileType = true;
      return;
    }

    this.upload(file);
  }

  getImageDataUrl(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  private upload(file: any) {
    this.siteManagement.uploadImage(file).subscribe((res) => {
      this.fileURL = res;
    });
  }

  get mainForm() { 
    return this.addEditForm.controls; 
  }

}
