import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { ContractTechnicalPointLine } from "@app/contracts/_models/contract-technical-point-line.model";
import { ContractTechnicalPointWorkingHours } from "@app/contracts/_models/contract-technical-point-working-hours.model";
import { ContractService } from "@app/contracts/_services/contract.service";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { ArrayValidators } from "@app/_validators/array.validator";
import { EMPTY, Subscription } from "rxjs";
import { catchError, repeatWhen, tap } from "rxjs/operators";
import { TechnicalPoint } from "@app/tech-points/_models/technical-point.model";
import { TechnicalPointService } from "@app/tech-points/_services/technical-point.service";
import { Line } from "@app/tech-points/_models/line.model";
import { TechnicalPointWorkingHour } from "@app/tech-points/_models/technical-point-working-hours.model";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { TranslateService } from "@ngx-translate/core";
import { displayError } from "@app/_utils/error-util";

@Component({
    selector: 'contract-slots',
    templateUrl: './contract-slots.component.html',
    styleUrls: ['./contract-slots.component.css']
})

export class ContractSlotsComponent extends BaseChildComponent implements OnInit, OnDestroy {
    // Units
    contractTechPointLines: ContractTechnicalPointLine[];

    // Decorators
    @Input() selectedTechnicalPoints:   TechnicalPoint[];
    @Input() contractId:                number;
    @Input() module:                    string;

    // Form
    contractSlotsForm: FormGroup;

    // Subscriptions
    lineSubscription:           Subscription;
    workingHoursSubscription:   Subscription;

    constructor(
        private translateService: TranslateService,
        private formBuilder: FormBuilder,
        private perms: PermissionsService,
        private contractService: ContractService,
        private technicalPointService: TechnicalPointService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.contractSlotsForm = this.formBuilder.group({
            rows: this.formBuilder.array([], [ArrayValidators.equalsToSomeGroupKey('isValid', true)])
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.lineSubscription?.unsubscribe();
        this.workingHoursSubscription?.unsubscribe();
    }

    ngOnChanges(changes: any) {
        if (!!changes.selectedTechnicalPoints) {
            this.clearRowValues();
        }
    }

    async getContractSlots() {
        if (this.isInitialized) return;
        this.isInitialized = true;

        if (!!this.contractId) {
            const response = await this.contractService.findContractTechnicalPointLines(this.contractId).toPromise();
            this.contractTechPointLines = response;

            this.contractSlotsForm = this.formBuilder.group({
                rows: this.formBuilder.array(
                    this.contractTechPointLines.map(x =>
                        this.formBuilder.group({
                            id:                     x.id,
                            contractTechnicalPoint: x.contractTechnicalPoint,
                            technicalPoint:         [x.contractTechnicalPoint?.technicalPoint?.id, Validators.required],
                            line:                   [{ id: x.line?.id }, Validators.required],
                            isValid:                x.isValid,
                            linesList:              this.reduceTechPointLines(x, this.contractTechPointLines),
                            workingHoursList:       Array.of(x.workingHoursList),
                            technicalPointWorkingHoursList:         Array.of(x.contractTechnicalPointWorkingHoursList?.map(y => y.technicalPointWorkingHours)),
                            contractTechnicalPointWorkingHoursList: Array.of(x.contractTechnicalPointWorkingHoursList),
                        })
                    ), [ArrayValidators.equalsToSomeGroupKey('isValid', true)]
                )
            });
            (<FormArray>this.contractSlotsForm.controls['rows']).value.forEach((row: any, rowIndex: number) =>{
                (<FormArray>this.contractSlotsForm.controls['rows'])?.at(rowIndex)?.get("technicalPointWorkingHoursList")?.setValidators(Validators.required);
            })
            this.clearRowValues();
        }
    }

    getTechPointLines(id: number, index: number): Line[] {
        if (!!id) {
            this.lineSubscription = this.technicalPointService.findContractLinesByPointId(id).pipe(
                tap(data => {
                    let rowsCtrl = <FormArray>this.contractSlotsForm.controls['rows'];
                    let list = rowsCtrl.value.filter((e: any) => e.line != null).map((x: any) =>
                        x.line?.id)
                    data = data.filter(el =>
                        !list.includes(el.id)
                    );
                    rowsCtrl.at(index).patchValue({
                        line: null,
                        linesList: data,
                        workingHoursList: null
                    });
                    return data;
                }),
                catchError(err => {
                    displayError(err);
                    this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                    return EMPTY;
                }),
                repeatWhen(() => this.reload$)
            ).subscribe()
        }
        return [];
    }

    getLineWorkingHours(id: number, index: number): TechnicalPointWorkingHour[] {
        if (!!id) {
            this.workingHoursSubscription = this.technicalPointService.findWorkingHoursByLineId(id).pipe(
                tap(data => {
                    (<FormArray>this.contractSlotsForm.controls['rows']).at(index).patchValue({
                        technicalPointWorkingHoursList: null,
                        workingHoursList: data
                    });
                    (<FormArray>this.contractSlotsForm.controls['rows']).value.forEach((row: any, rowIndex: number) => {
                        if (rowIndex != index) {
                            (<FormArray>this.contractSlotsForm.controls['rows']).at(rowIndex).patchValue({
                                linesList: row.linesList?.filter((line: Line) => line.id != id)
                            })
                        }
                    });

                    //find and add preselected element to other lists
                    let element = (<FormArray>this.contractSlotsForm.controls['rows']).at(index).value;
                    let whatToAdd: Line[] = element.linesList.filter((s: Line) => s.id != null && s.id != id);

                    if (whatToAdd != null) {
                        (<FormArray>this.contractSlotsForm.controls['rows']).value.forEach((row: any, rowIndex: number) => {
                            if (rowIndex != index) {
                                if (row.technicalPoint != null && row.technicalPoint == element.technicalPoint) {
                                    whatToAdd.forEach(e => {
                                        if (!row.linesList.some((r: Line) => r.id == e.id)) {
                                            row.linesList.push(e)
                                        }
                                    });
                                }
                            }
                        });
                    }
                    return data;

                }),
                catchError(() => {
                    this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                    return EMPTY;
                }),
                repeatWhen(() => this.reload$)
            ).subscribe()
        }
        return [];
    }

    change(event: { isUserInput: any; source: { value: any; selected: any; }; }, i: number, matSelect: MatSelect) {
        let technicalPointWorkingHour: TechnicalPointWorkingHour; //ne se save purviq put
        technicalPointWorkingHour = event.source.value;

        if (event.isUserInput) {
            let contractSlotssCtrl = (<FormArray>this.contractSlotsForm.controls['rows']).at(i).get('contractTechnicalPointWorkingHoursList')?.value;

            if (event.source.selected) {
                if (contractSlotssCtrl == null) {
                    contractSlotssCtrl = []
                }
                if (!Array.isArray(contractSlotssCtrl)) {
                    contractSlotssCtrl = Array.from(contractSlotssCtrl);
                }
                if (contractSlotssCtrl.some((x:ContractTechnicalPointWorkingHours) => x.technicalPointWorkingHours.id === technicalPointWorkingHour.id)) {
                    contractSlotssCtrl.forEach((x: ContractTechnicalPointWorkingHours) => {
                        if (x.technicalPointWorkingHours.id === technicalPointWorkingHour.id) {
                            x.isValid = true;
                        }
                    })
                } else {
                    contractSlotssCtrl.push({
                        technicalPointWorkingHours: technicalPointWorkingHour,
                        isValid: true
                    });
                }
            } else {
                matSelect.options.filter(item => item.value === 0).forEach((item: MatOption) => {
                    item.deselect();
                })    
                contractSlotssCtrl.forEach((x: ContractTechnicalPointWorkingHours) => {
                    if (x.technicalPointWorkingHours.id === technicalPointWorkingHour.id) {
                        x.isValid = false;
                    }
                })
            }
            (<FormArray>this.contractSlotsForm.controls['rows']).at(i)
                .get('contractTechnicalPointWorkingHoursList')?.patchValue(contractSlotssCtrl);
        }
    }

    addRow() {
        let rowsCtrl = <FormArray>this.contractSlotsForm.controls['rows']
        let fb = this.formBuilder.group({
            id:                 '',
            technicalPoint:     [null, Validators.required],
            isValid:            true,
            line:               [null, Validators.required],
            linesList:          null,
            workingHoursList:   null,
            technicalPointWorkingHoursList: [[], Validators.required],
            contractTechnicalPointWorkingHoursList: []
        });
        rowsCtrl.push(fb);
    }

    deleteRow(index: number) {
        let rowsCtrl = <FormArray>this.contractSlotsForm.controls['rows']
        let element = rowsCtrl.at(index).value
        element.isValid = false;
        rowsCtrl.at(index).patchValue(element);
        rowsCtrl.at(index).get('technicalPoint')?.clearValidators();
        rowsCtrl.at(index).get('line')?.clearValidators();
        rowsCtrl.at(index).get('technicalPointWorkingHoursList')?.clearValidators();
        rowsCtrl.at(index).get('technicalPoint')?.updateValueAndValidity();
        rowsCtrl.at(index).get('line')?.updateValueAndValidity();
        rowsCtrl.at(index).get('technicalPointWorkingHoursList')?.updateValueAndValidity();

        if (element.line != null) {
            rowsCtrl.value.forEach((row: any, rowIndex: number) => {
                if (rowIndex != index) {
                    if (row.linesList == null) {
                        row.linesList = [];
                    }
                    if (element.linesList != null) {
                        if (element.technicalPoint == row.technicalPoint) {
                            row.linesList.push(element?.linesList.find((s: Line) => s.id = element?.line?.id));
                        }
                    }
                }
            });
        }
    }

    onTechPointSelect(event: any, index: number) {
        this.getTechPointLines(event, index);
    }

    onLineSelect(event: any, index: number) {
        if (!!event) {
            this.getLineWorkingHours(event.id, index);
        }
    }

    checkSelectedAll(matSelect: MatSelect, i: number) {
        let anyNonSelected = matSelect.options.filter(item => item.value.id > 0).some((item: MatOption) =>
            !item.selected
        )
        if (!anyNonSelected) {
            matSelect.options.filter(item => item.value === 0).forEach((item: MatOption) => {
                item.select();
            })
        }
    }

    toggleAllSelection(matSelect: MatSelect, i: number) {
        const isSelected: boolean = matSelect.options
            .filter((item: MatOption) => item.value === 0)
            .map((item: MatOption) => item.selected)[0];

        let rowsCtrl: ContractTechnicalPointWorkingHours[] = (<FormArray>this.contractSlotsForm.controls['rows'])
            .at(i).get('contractTechnicalPointWorkingHoursList')?.value;
        if (isSelected) {
            matSelect.options.filter(item => item.value.id > 0).forEach((item: MatOption) => {
                item.select();
                if (rowsCtrl == null) {
                    rowsCtrl = []
                }
                if (!Array.isArray(rowsCtrl)) {
                    rowsCtrl = Array.from(rowsCtrl);
                }
                if (rowsCtrl.some(x => x.technicalPointWorkingHours.id === item.value.id)) {
                    rowsCtrl.forEach(x => {
                        if (x.technicalPointWorkingHours.id === item.value.id) {
                            x.isValid = true;
                        }
                    })
                } else {
                    rowsCtrl.push({
                        technicalPointWorkingHours: item.value,
                        isValid: true
                    });

                }
            });
        } else {
            matSelect.options.forEach((item: MatOption) => {
                item.deselect();
                rowsCtrl.forEach(x => {
                    if (x.technicalPointWorkingHours.id === item.value.id) {
                        x.isValid = false;
                    }
                })
            });
        }
        (<FormArray>this.contractSlotsForm.controls['rows']).at(i)
            .get('contractTechnicalPointWorkingHoursList')?.patchValue(rowsCtrl);
    }

    clearRowValues() {
        let rowsCtrl = <FormArray>this.contractSlotsForm?.controls['rows']
        if (!!rowsCtrl) {
            rowsCtrl.value.forEach((row: any, index: number) => {
                if (!this.selectedTechnicalPoints.some(techPoint => techPoint.id == row.technicalPoint)) {
                    rowsCtrl.at(index).patchValue({
                        technicalPoint: null,
                        line: null,
                        linesList: null,
                        technicalPointWorkingHoursList: null,
                        workingHoursList: null,
                        isValid: false
                    });
                    rowsCtrl.at(index).get('technicalPoint')?.clearValidators();
                    rowsCtrl.at(index).get('line')?.clearValidators();
                    rowsCtrl.at(index).get('technicalPointWorkingHoursList')?.clearValidators();
                }
            })
        }
    }

    reduceTechPointLines(x: ContractTechnicalPointLine, contractTechPointServices: ContractTechnicalPointLine[]) {
        let list = contractTechPointServices.filter((e: ContractTechnicalPointLine) =>
            e.line != null &&
            e.line?.id != x.line.id).map((x: any) =>
                x.line?.id)
        x.linesList = x.linesList.filter(el =>
            !list.includes(el.id)
        );
        return Array.of(x.linesList);
    }

    get rows() {
        return this.contractSlotsForm.get('rows') as FormArray;
    }

    get canAddEdit() {
        switch(this.module) {
          case 'client': 
            return this.perms.hasAccess(this.perms.CAN_VIEW_PARTNER_CONTRACT);
          case 'pertner': 
            return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PARTNER_CONTRACT);
          default: 
            return false;
        }
    }

}