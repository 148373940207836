import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContractTechnicalPointService } from "@app/contracts/_models/contract-technical-point-service.model";
import { ContractService } from "@app/contracts/_services/contract.service";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { ArrayValidators } from "@app/_validators/array.validator";
import { CustomerDiscount } from "@models/customer-discount.model";
import { PriceAttribute } from "@models/price-attribute.model";
import { EMPTY, Subscription } from "rxjs";
import { catchError, repeatWhen, tap } from "rxjs/operators";
import { TechnicalPoint } from "@app/tech-points/_models/technical-point.model";
import { TechnicalPointService } from "@app/tech-points/_services/technical-point.service";
import { TechPointService } from "@app/tech-points/_models/tech-point-service.model";
import { PermissionsService } from "@app/login/_services/permissions.service";
import { TranslateService } from "@ngx-translate/core";
import { NomenclatureService } from "@services/nomenclature.service";
import { displayError, displayErrorFromUnknown } from "@app/_utils/error-util";

@Component({
    selector: 'contract-services',
    templateUrl: './contract-services.component.html',
    styleUrls: ['./contract-services.component.css']
})

export class ContractServicesComponent extends BaseChildComponent implements OnInit, OnDestroy {
    // Units
    contractTechPointServices: ContractTechnicalPointService[];

    // Constants
    readonly GTP_SERVICE_CODE: string = 'GTP';
    
    // Decorators
    @Input() module: string;
    @Input() contractId: number;
    @Input() selectedTechnicalPoints: TechnicalPoint[];
    @Input() selectedCustomerDiscount: CustomerDiscount;
    
    // Form
    contractServicesForm: FormGroup;

    // Subscription
    servicesSubscription: Subscription;

    constructor(
        private translateService: TranslateService,
        private formBuilder: FormBuilder,
        private perms: PermissionsService,
        private contractService: ContractService,
        private technicalPointService: TechnicalPointService,
        private nomenclatureService: NomenclatureService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.contractServicesForm = this.formBuilder.group({
            rows: this.formBuilder.array([], [ArrayValidators.equalsToSomeGroupKey('isValid', true)])
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.servicesSubscription?.unsubscribe();
    }

    ngOnChanges(changes: any) {
        let rowsCtrl = <FormArray>this.contractServicesForm?.controls['rows']
        if (!!rowsCtrl) {
            if (!!changes.selectedCustomerDiscount) {
                rowsCtrl.value.forEach((x: any, index: number) => {
                    if (!!x.technicalPointService) {
                        let servicePriceAttribute = x.techPointServices.find((y: any) => y.id == x.technicalPointService.id).priceAttribute;
                        let withoutDds = this.priceWithDiscount(servicePriceAttribute, this.selectedCustomerDiscount?.priceAttribute, 'amount');
                        let finalAmount = this.priceWithDiscount(servicePriceAttribute, this.selectedCustomerDiscount?.priceAttribute, 'amountDDS');
                        rowsCtrl.at(index).patchValue({ withoutDds: withoutDds, finalAmount: finalAmount });
                    }
                })
            }
            if (!!changes.selectedTechnicalPoints) {
                this.clearRowValues();
            }
        }
    }

    async getContractServices() {
        if (this.isInitialized) return;
        this.isInitialized = true;

        if (!!this.contractId) {
            const response = await this.contractService.findContractTechnicalPointServices(this.contractId).toPromise();
            this.contractTechPointServices = response;
            
            this.contractServicesForm = this.formBuilder.group({
                rows: this.formBuilder.array(
                    this.contractTechPointServices.map(x =>
                        this.formBuilder.group({
                            id:                         x.id,
                            contractTechnicalPoint:     x.contractTechnicalPoint,
                            technicalPoint:             [x.technicalPointService?.technicalPoint, Validators.required],
                            category:                   [{ id: x.technicalPointService?.rvsService?.rvsCategory?.id}, Validators.required],
                            technicalPointService:      [{ id: x.technicalPointService?.id }, Validators.required],
                            categories:                 [x.categories],
                            isValid:                    x.isValid,
                            withoutDds:                 { value: this.priceWithDiscount(x.technicalPointService?.priceAttribute, this.selectedCustomerDiscount?.priceAttribute, 'amount'), disabled: true },
                            finalAmount:                { value: this.priceWithDiscount(x.technicalPointService?.priceAttribute, this.selectedCustomerDiscount?.priceAttribute, 'amountDDS'), disabled: true },
                            techPointServices:          this.reduceTechPointServices(x, this.contractTechPointServices)
                        }),
                    ), [ArrayValidators.equalsToSomeGroupKey('isValid', true)]
                ),
            });
        }
        this.clearRowValues();
    }

    getTechPointServices(categoryId: number, index: number, technicalPoint:any): TechPointService[] {
        this.servicesSubscription = this.technicalPointService.findServicesByCategoryAndPointId(technicalPoint?.mobileAppId, categoryId).pipe(
            tap(data => {
                let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows'];
                let list = rowsCtrl.value.filter((e: any) => e.technicalPointService != null).map((x: any) =>
                    x.technicalPointService?.id)
                    data = data.filter(el =>
                    !list.includes(el.id)
                );

                rowsCtrl.at(index).patchValue({ techPointServices: data });
                return data;
            }),
            catchError(err => {
                displayError(err);
                this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                return EMPTY;
            }),
            repeatWhen(() => this.reload$)
        ).subscribe();
        
        return [];
    }


    addAll() {
        if (!!this.selectedTechnicalPoints) {
            let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows'];
            if (!rowsCtrl.controls.some(x => x.value.isValid)) {
                this.selectedTechnicalPoints.forEach((x: TechnicalPoint) => {
                    if (!!x.id) {
                        this.nomenclatureService.getRvsCategoriesByTechPointId(x.mobileAppId).subscribe(rvsCategories => {
                            let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows'];
                            rowsCtrl.push(this.formBuilder.group({
                                    id: null,
                                    technicalPoint: x,
                                    isValid: true,
                                    categories: Array.of(rvsCategories),
                                    withoutDds: { value: null, disabled: true },
                                    finalAmount: { value: null, disabled: true },
                                    techPointServices: null,
                                    technicalPointService: null,
                                    category: [null, Validators.required]
                            }));  
                        });
                    }
                });
            }
        }
    }

    addRow() {
        let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows']
        rowsCtrl.push(this.formBuilder.group({
            id:                         null,
            technicalPoint:             [null, Validators.required],
            category:                   [null, Validators.required],
            technicalPointService:      [null, Validators.required],
            isValid:                    true,
            withoutDds:                 { value: null, disabled: true },
            finalAmount:                { value: null, disabled: true },
            techPointServices:          null,
            categories:                 null
        }));
    }

    deleteRow(index: number) {
        let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows'];
        let element = rowsCtrl.at(index).value;
        element.isValid = false;
        rowsCtrl.at(index).patchValue(element);
        rowsCtrl.at(index).get('technicalPoint')?.clearValidators();
        rowsCtrl.at(index).get('technicalPointService')?.clearValidators();
        rowsCtrl.at(index).get('category')?.clearValidators();
        rowsCtrl.at(index).get('technicalPoint')?.updateValueAndValidity();
        rowsCtrl.at(index).get('technicalPointService')?.updateValueAndValidity();
        rowsCtrl.at(index).get('category')?.updateValueAndValidity();

        if (element.technicalPointService != null) {
            rowsCtrl.value.forEach((row: any, rowIndex: number) => {
                if (rowIndex != index) {
                    if (row.techPointServices == null) {
                        row.techPointServices = [];
                    }
                    if (element.techPointServices != null) {
                        if (element.technicalPoint == row.technicalPoint) {
                            row.techPointServices.push(
                                element?.techPointServices.find((s: TechPointService) => s.id = element?.technicalPointService?.id)
                            );
                        }
                    }
                }
            });
        }
    }

    onTechPointSelect(event: any, index: number) {
        this.rows.controls[index]?.get('categories')?.reset();
        this.rows.controls[index]?.get('techPointServices')?.reset();
        this.getTechPointCategories(event?.mobileAppId, index);
    }

    getTechPointCategories(id: any, index: number) {
        this.nomenclatureService.getRvsCategoriesByTechPointId(id).subscribe(rvsCategories => {
            let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows'];
            rowsCtrl.at(index).patchValue({ categories: rvsCategories });    
                
            if (this.rows.controls[index]?.get('category')?.value) {
                this.onCategorySelect(this.rows.controls[index]?.get('category')?.value, index, this.rows.controls[index]?.get('technicalPoint')?.value);
            }
        });
    }

    onServiceSelect(event: any, index: number) {
        if (!!event) {
            let contractServicesCtrl = <FormArray>this.contractServicesForm.controls['rows']
            contractServicesCtrl.at(index).patchValue({
                withoutDds: this.priceWithDiscount(event?.priceAttribute, this.selectedCustomerDiscount?.priceAttribute, 'amount'),
                finalAmount: this.priceWithDiscount(event?.priceAttribute, this.selectedCustomerDiscount?.priceAttribute, 'amountDDS'),
            });
            contractServicesCtrl.value.forEach((row: any, rowIndex: number) => {
                if (rowIndex != index) {
                    contractServicesCtrl.at(rowIndex).patchValue({
                        techPointServices: row.techPointServices?.filter((service: TechPointService) => service.id != event.id)
                    })
                }
            });
            // find and add preselected element to other lists
            let element = contractServicesCtrl.at(index).value;
            let whatToAdd: TechPointService[] = element?.techPointServices?.filter((s: TechPointService) => s.id != null && s.id != event.id);

            if (whatToAdd != null) {
                contractServicesCtrl.value.forEach((row: any, rowIndex: number) => {
                    if (rowIndex != index) {
                        if (row.technicalPoint != null && row.technicalPoint.id == element.technicalPoint.id) {
                            whatToAdd.forEach(e => {
                                if (!row.techPointServices.some((r: TechPointService) => r.id == e.id)) {
                                    row.techPointServices.push(e)
                                }
                            });
                        }
                    }
                });
            }
        }
    }

    onCategorySelect(event: any, index: number, technicalPoint: any) {
      this.getTechPointServices(event?.id, index, technicalPoint);
    }

    public isClientContract() {
        return this.module == 'client';
    }

    priceWithDiscount(priceAttribute: PriceAttribute | undefined, discountPriceAttribute: PriceAttribute | undefined, type: string) {
        let x = 0;
        let y = 0;
        if (type == 'amount') { //TODO FIX this strings
            x = priceAttribute?.amount || 0;
            y = discountPriceAttribute?.amount || 0;
        } else if (type == 'amountDDS') {
            x = priceAttribute?.amountDds || 0;
            y = discountPriceAttribute?.amountDds || 0;
        }
        if (discountPriceAttribute?.kind == 'V') {
            return x - y;
        } else {
            y = discountPriceAttribute?.amount || 0;
            return x * (100 - y) / 100;
        }

    }

    clearRowValues() {
        let rowCtrl = <FormArray>this.contractServicesForm?.controls['rows'];
        rowCtrl.value.forEach((row: any, index: number) => {
            if (!this.selectedTechnicalPoints.some(techPoint => techPoint?.id == row?.technicalPoint?.id)) {
                rowCtrl.at(index).patchValue({
                    technicalPoint:          null,
                    technicalPointService:   null,
                    techPointServices:       null,
                    category:                null,
                    categories:              null,
                    withoutDds:              null,
                    finalAmount:             null,
                    isValid:                 false
                });
                rowCtrl.at(index).reset();
                rowCtrl.at(index).get('technicalPoint')?.clearValidators();
                rowCtrl.at(index).get('technicalPointService')?.clearValidators();
                rowCtrl.at(index).get('category')?.clearValidators();
                rowCtrl.at(index).get('technicalPoint')?.updateValueAndValidity();
                rowCtrl.at(index).get('technicalPointService')?.updateValueAndValidity();
                rowCtrl.at(index).get('category')?.updateValueAndValidity();
            }
        })
    }

    reduceTechPointServices(x: ContractTechnicalPointService, contractTechPointServices: ContractTechnicalPointService[]): any {
        if (x.techPointServices != null) {
            let list = contractTechPointServices.filter((e: ContractTechnicalPointService) =>
            e.technicalPointService != null &&
            e.technicalPointService?.id != x.technicalPointService.id).map((x: any) =>
                x.technicalPointService?.id)
        x.techPointServices = x.techPointServices.filter(el =>
            !list.includes(el.id)
        );
        return Array.of(x.techPointServices);
        }
        return [];
    }
    
    get rows() {
        return this.contractServicesForm.get('rows') as FormArray;
    }

    get canAddEdit() {
        switch(this.module) {
          case 'client': 
            return this.perms.hasAccess(this.perms.CAN_VIEW_PARTNER_CONTRACT);
          case 'pertner': 
            return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PARTNER_CONTRACT);
          default: 
            return false;
        }
    }

}