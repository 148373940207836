import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusCode } from '@app/_enums/status-code';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { Status } from '@models/status.model';
import { TranslateService } from '@ngx-translate/core';
import { NomenclatureService } from '@services/nomenclature.service';
import { BehaviorSubject, EMPTY, Subscription, forkJoin } from 'rxjs';
import { catchError, concatMap, repeatWhen, tap } from 'rxjs/operators';
import { PromotionService } from '@app/promotions/_services/promotion.service';
import { PromotionServicesComponent } from './promotion-services/promotion-services.component';
import { PromotionTechnicalPointsComponent } from './promotion-technical-points/promotion-technical-points.component';
import { PromotionFriendsConfigComponent } from './promotion-friends-config/promotion-friends-config.component';
import { DateValidators } from '@app/_validators/dates-validator';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Promotion } from '../_models/promotion.model';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { formatFullDate } from '@app/_utils/date-util';
import { RegisterOfLegalPersonsModalComponent } from '@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component';
import { modalMinWidth } from '@env/config';
import { MatDialog } from '@angular/material/dialog';
import { SubjectVersion } from '@models/subject-version.model';
import { latinAndDigitsValidator } from '@app/_utils/form-util';

@Component({
  selector: 'app-add-edit-promotion',
  templateUrl: './add-edit-promotion.component.html',
  styleUrls: ['./add-edit-promotion.component.css']
})
export class AddEditPromotionComponent extends BaseParentComponent implements OnInit {
  // Units 
  module:                 string;
  statuses:               Status[];
  defaultStatus:          Status | null;
  promotion:              Promotion;

  // Constants
  readonly INACTIVE_STATUS_CODE:      string = 'INACTIVE';
  readonly ACTIVE_STATUS_CODE:        string = 'ACTIVE';
  readonly DISCOUNT_MODULE_CODE:      string = 'discount';
  readonly PROMOCODE_MODULE_CODE:     string = 'promocode';
  readonly INVITE_FRIEND_MODULE_CODE: string = 'invite-friend';

  // Payload
  private subscriptions = new Subscription();
  serviceCategoriesIds:   Array<number> = [];
  todayDate:              Date = new Date();
  rows:                   number;
  statusCode:             string;
  promotionId:            number;
  promotionType:          string;
  subject:                SubjectVersion | null;

  // Booleans
  dataLoaded = false;

  // Property decorators
  @ViewChild('services')        servicesComponent:       PromotionServicesComponent;
  @ViewChild('friendsConfig')   friendsConfigComponent:  PromotionFriendsConfigComponent;
  @ViewChild('technicalPoints') technicalPointComponent: PromotionTechnicalPointsComponent;
  
  // Form
  mainDataForm = this.formBuilder.group({
    id:                      [null],
    validFrom:               [null, Validators.required],
    validTo:                 [null, Validators.required],
    status:                  [null, Validators.required],
    promotionType:           [null],
    versionData:             [null],
    subject:                 [null],
  }, { validator: [DateValidators.groupValidator('validFrom', 'validTo')] } as AbstractControlOptions);

  // Observables
  changedCategoriesServices = new BehaviorSubject<number[]>([]);
  changedCategories$ = this.changedCategoriesServices.asObservable();

  loadPromotion$ = this.route.queryParams.pipe(
    tap(params => this.module = params['module']),
    tap(() => this.addControlsToMainForm()),
    concatMap(params => {
      return forkJoin([
        this.promotionService.findPromotionById(params['id']),
        this.nomenclatureService.getStatusesByType(this.checkStatuses())
      ]).pipe(
        tap(() => this.promotionId = params['id']),
        tap(([promotion, statuses]) => {
          this.module = params.module;
          this.statuses = statuses;
          this.promotion = promotion;
          this.subject = promotion?.subject ?? null;
          this.statusCode = promotion?.status?.code;
          this.defaultStatus = this.statuses.find(status => status.code === this.INACTIVE_STATUS_CODE) || null;
  
          this.mainDataForm.patchValue(promotion);
          this.mainDataForm.get('status')?.patchValue(!!promotion && !!promotion.status ? promotion.status : this.defaultStatus);
          
          this.mainDataForm.get('promotionType')?.patchValue(this.module);

          if (promotion?.priceAttributeKind == 'P') {
            this.mainDataForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
            this.mainDataForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
          } else if (promotion?.priceAttributeKind == 'V') {
            this.mainDataForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.min(1), Validators.max(99)]);
            this.mainDataForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
          } else {
            this.mainDataForm.get('priceAttributeAmountDds')?.clearValidators();
            this.mainDataForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
          }

          this.mainDataForm.get('priceAttributeAmountDds')?.patchValue(promotion?.priceAttributeAmountDds);     
          this.mainDataForm.get('validFrom')?.patchValue(new Date(promotion?.validFrom));
          this.mainDataForm.get('validTo')?.patchValue(new Date(promotion?.validTo));

          if (this.statusCode == this.ACTIVE_STATUS_CODE) {
            this.mainDataForm.disable();
          }
          this.dataLoaded = true;
        }),
        catchError(err => {
          displayError(err);
          this.dataLoaded = false;
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  constructor( 
    private router:               Router,
    private route:                ActivatedRoute,
    private formBuilder:          FormBuilder,
    private uiEvent:              UIEventCustom,
    public  perms:                PermissionsService,
    private translateService:     TranslateService,
    private promotionService:     PromotionService,
    private nomenclatureService:  NomenclatureService,
    private dialog:               MatDialog,
  ) {
    super(router);
  }

  ngOnInit() {
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data
    });
  }

  async save(deactivate: boolean) {
    if (this.mainDataForm.get('status')?.value?.code === this.INACTIVE_STATUS_CODE) {
      if (!this.partualValidation()) {
        this.uiEvent.displayUIError();
        this.showValidationsSubject.next(true);
        this.showFullValidationSubject.next(false);
        return;
      }
    } else {
      await Promise.all([this.servicesComponent.initialize(), this.technicalPointComponent.initialize()]);

      if (this.module == this.INVITE_FRIEND_MODULE_CODE) {
        this.friendsConfigComponent.initialize();
      }

      await new Promise(f => setTimeout(f, 400));
      this.mainDataForm.enable();
      this.servicesComponent.servicesForms.enable();

      if (!deactivate) {
        if (!this.fullValidation()) {
          this.uiEvent.displayUIError();
          this.showValidationsSubject.next(true);
          this.showFullValidationSubject.next(true);
          return;
        }
      }   
    }

    this.promotionService.savePromotion(this.constructPromotion(), deactivate).subscribe({
      next: () => {
        this.uiEvent.displayUISuccess();
        this.router.navigate(['/list-promotions'], {queryParams: { module: this.module }});
      },
      error: err => {
        displayError(err);
        this.uiEvent.displayUIError();
      } 
    });
  }

  private partualValidation() {
    return this.mainDataForm.valid;
  }

  private fullValidation() {
    let formValid = true;

    if (!this.mainDataForm.valid) {
      formValid = false;
    }
    
    if (this.servicesComponent.isInitialized) { 
      if (!this.servicesComponent.validateServices()) {
        formValid = false;
      }
    }

    if (this.technicalPointComponent.isInitialized) { 
      if (!this.technicalPointComponent.validateTechPoints()) {
        formValid = false;
      }
    }
  
    if (this.module == this.INVITE_FRIEND_MODULE_CODE) {
      if (this.friendsConfigComponent.isInitialized){
        if (!this.friendsConfigComponent.validateInvitationsConfig()) {
          formValid = false;
        }
      }
    }

    return formValid;
  }

  checkStatuses() {
    switch(this.module) {
      case this.PROMOCODE_MODULE_CODE: 
        return StatusCode.PromotionPromocode;
      case this.DISCOUNT_MODULE_CODE:
        return StatusCode.PromotionDiscount;
      case this.INVITE_FRIEND_MODULE_CODE:
        return StatusCode.PromotionInviteFriend;
      default: 
        return StatusCode.PromotionDiscount;  
    }
  } 

  async initializeSection(code: string) {
    switch (code) {
      case 'technicalPoints': {
        await this.servicesComponent.initialize();
        this.technicalPointComponent.initialize();
        break;
      }
      case 'services': {
        await this.servicesComponent.initialize();
        if(this.promotionId != null) {
         await this.technicalPointComponent.initialize()
        }
        break;
      }
      case 'friendsConfig': {
        this.friendsConfigComponent.initialize();
        break;
      }
      default: {}
    }
  }
  
  changePriceAttrKind(event: any) {
    let type = event.target.value;

    if (type == 'P') {
      this.mainDataForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
      this.mainDataForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
    } else if (type == 'V') {
      this.mainDataForm.get('priceAttributeAmountDds')?.setValidators([Validators.required, Validators.min(1), Validators.max(99)]);
      this.mainDataForm.get('priceAttributeAmountDds')?.updateValueAndValidity();
    }
  }

  handleCountRowEvent(rows: number) {
    this.rows = rows;
  }

  private addControlsToMainForm() {
    switch (this.module) {
      case this.PROMOCODE_MODULE_CODE: {
        this.mainDataForm.addControl('name', this.formBuilder.control(null, [Validators.required, Validators.maxLength(30), latinAndDigitsValidator()]));
        this.mainDataForm.addControl('maxUsageNumber', this.formBuilder.control(null, [Validators.required, Validators.min(1), Validators.max(10000)]));
        this.mainDataForm.addControl('priceAttributeKind', this.formBuilder.control(null, Validators.required));
        this.mainDataForm.addControl('priceAttributeAmountDds', this.formBuilder.control(null, [Validators.required, Validators.max(100)]));
        this.mainDataForm.addControl('orderTotalAmount', this.formBuilder.control(null, [Validators.required, Validators.min(1), Validators.max(10000)]));
        break;
      }
      case this.DISCOUNT_MODULE_CODE: {
        this.mainDataForm.addControl('name', this.formBuilder.control(null, [Validators.required, Validators.maxLength(30)]));
        this.mainDataForm.addControl('priceAttributeKind', this.formBuilder.control(null, Validators.required));
        this.mainDataForm.addControl('priceAttributeAmountDds', this.formBuilder.control(null, [Validators.required, Validators.max(100)]));
        break;
      }
      case this.INVITE_FRIEND_MODULE_CODE: {
        this.mainDataForm.addControl('maxUsageNumber', this.formBuilder.control(null, [Validators.required, Validators.min(1), Validators.max(9999)]));
        break;
      }
      default: 
        break;  
    }
  }

  private constructPromotion(): Promotion {
    const promotion = this.mainDataForm.value as Promotion;

    promotion.validFrom = formatFullDate(this.mainDataForm.get('validFrom')?.value);
    promotion.validTo   = formatFullDate(this.mainDataForm.get('validTo')?.value);
    promotion.subject   = this.mainDataForm.get('subject')?.value ?? null;
    promotion.services  = this.servicesComponent.constructServices();
    promotion.promotionTechnicalPoints = this.technicalPointComponent.constructPromTechPoints();

    if (this.module == this.INVITE_FRIEND_MODULE_CODE) {
      promotion.friendInvitationsConfiguration = this.friendsConfigComponent?.constructFriendConfigs();
    }

    return promotion;
  }

  public deactivate() {
    this.save(true);
  }

  openModal() {
    if (this.dialog.openDialogs.length == 0) {
      let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
      this.subscriptions.add(ref.componentInstance.submitSubject.subscribe(result => {
        this.mainDataForm.get('subject')?.setValue(result);
        this.subject = result;
      }));
    }
  }

  clearSubject() {
    this.mainDataForm.get('subject')?.setValue(null);
    this.subject = null;
  }

  get mainForm() { 
    return this.mainDataForm.controls; 
  }

  get servicesComponentState() {
    if (this.servicesComponent == null) return false;
    return this.servicesComponent.isOpened;
  }

  get tpComponentState() {
    if (this.technicalPointComponent == null) return false;
    return this.technicalPointComponent.isOpened;
  }

  get friendConfigComponentState() {
    if (this.friendsConfigComponent == null) return false;
    return this.friendsConfigComponent.isOpened;
  }

  get isDisabled() {
    return this.statusCode == this.ACTIVE_STATUS_CODE    
  }

  get canAddEdit() {
    switch (this.module) {
      case 'discount': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOTION);
      case 'promocode': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PROMOCODE);
      case 'invite-friend': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_INVITE_FRIEND);
      default: 
        return false;
    }
  }

}
