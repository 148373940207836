<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 class="my-2" translate>roles.roleAndPerms</h3>
        </span>
    </div>
    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- roles -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>roles.role</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="role">
                    <option *ngFor="let role of rolesList" [value]="role.id">
                        {{role.description}}
                    </option>
                </select>
                
                <!-- permissions -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>roles.perm</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="permission">
                    <option *ngFor="let permission of permissions" [value]="permission.id">
                        {{permission.description}}
                    </option>
                </select>
                
                <!-- status -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>serviceAddInfo.isValid</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="enabled">
                    <option *ngFor="let option of options" [value]="option.code">
                        {{option.name}}
                    </option>
                </select>

                <!-- actions -->
                <div class="input-group-append ml-2">
                    <button class="btn btn-outline-success mr-2" type="button" (click)="search()" translate>
                        button.search
                    </button>
                    <button class="btn btn-outline-danger mr-2" type="button" (click)="clearSearch()" translate>
                        button.clear
                    </button>
                    <button class="btn btn-outline-success" routerLink="/add-edit-role" type="button" translate>
                        button.add
                    </button>
                </div>
            </div>
        </form>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th sortBy="description" (sort)="onSort($event)" scope="col" translate>roles.role</th>
                        <th scope="col" translate>roles.perms</th>
                        <th sortBy="enabled" (sort)="onSort($event)"  scope="col" translate>roles.isValid</th>
                        <th class="text-center" scope="col" translate>actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{item.description}}</td>
                        <td>{{formatPermissions(item.permissions)}}</td>
                        <td translate>{{item.enabled == true? 'yes' : 'no'}}</td>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}" 
                                routerLink="/add-edit-role" [queryParams]="{id: item.id}">
                                <i class="fa fa-pencil fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize"
                (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="d-flex flex-column" *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="d-inline-flex justify-content-center">
            <div class="alert alert-danger">
                {{errorMessage}}
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>