import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-messagebox',
  templateUrl: './warning-messagebox.component.html',
  styleUrls: ['./warning-messagebox.component.css']
})
export class WarningMessageboxComponent implements OnInit {

  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<WarningMessageboxComponent>,
  ) {
    this.message = data.message;
  }

  ngOnInit(): void {
  }

  onOk() {
    this.dialog.close(true);
  }

  closeDialog() {
    this.dialog.close();
  }

}

