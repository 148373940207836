import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerService } from '@app/customers/_services/customer.service';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { Order } from '@models/order.model';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { AccountingDocumentService } from '@app/accounting_documents/_services/accounting-document.service';
import { AccountingDocument } from '@app/accounting_documents/_models/accounting-document.model';


@Component({
  selector: 'app-client-profile-purchase-history',
  templateUrl: './client-profile-purchase-history.component.html',
  styleUrls: ['./client-profile-purchase-history.component.css']
})
export class ClientProfilePurchaseHistoryComponent extends BaseChildComponent implements OnInit {
  readonly PAID_STATUS_CODE: string = 'PAID';

  orders: Order[];
  accDocument: AccountingDocument;

  @Input() customerPhoneNum: String;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private accDocService: AccountingDocumentService,
    private translateService: TranslateService
  ) { 
    super();
  }

  ngOnInit(): void { }

  initialize() {
    if (this.isInitialized) return;

    this.customerService.findOrdersByCustomerId(this.parentId).pipe(
      tap(data => this.orders = data),
      catchError(err => {
        console.log(err)
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true})
  }

  async printPDF(id: number) {

    await this.accDocService.findAccountingDocByOrderIdToPromise(id).then(result => this.accDocument = result)
 
    let filePath = "/" + this.customerPhoneNum + "/invoices/" + this.accDocument.invoiceNumber + ".pdf"

    this.customerService.getPdfDocument(filePath).subscribe(response => {  
      var blob = new Blob([response], {
         type: "application/pdf"
      });

      const fileUrl = URL.createObjectURL(blob);
      window.open(fileUrl);
    });
  }

}
