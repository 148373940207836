import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Permission } from "@app/security/_models/permission.model";
import { Role } from "@app/security/_models/role.model";
import { SecurityService } from "@app/security/_services/security.service";
import { YesNoEnum, YES_NO } from "@app/_enums/yes-no-enum";
import { ArrayValidators } from "@app/_validators/array.validator";
import { BaseParentComponent } from "@components/_base/base-parent/base-parent.component";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, forkJoin } from "rxjs";
import { catchError, concatMap, repeatWhen, tap } from "rxjs/operators";
import { UIEventCustom } from "@app/_utils/ui-event-util";
import { displayError } from "@app/_utils/error-util";

@Component({
  selector: 'add-edit-role',
  templateUrl: './add-edit-role.component.html'
})

export class AddEditRoleComponent extends BaseParentComponent implements OnInit, OnDestroy {
  // Constants
  readonly options:       YesNoEnum[] = YES_NO;
  private readonly SERVICE_TYPE_CODE  = 'S';

  // Forms
  addEditForm = this.formBuilder.group({
    id:           null,
    name:         [null, [Validators.required, Validators.maxLength(128)]],
    description:  [null, [Validators.required, Validators.maxLength(128)]],
    enabled:      true,
    permissions:  this.formBuilder.array([], ArrayValidators.oneValidElement())
  });

  permissionForm = this.formBuilder.group({
    id:           null,
    isValid:      false,
    description:  null
  });

  // Observables
  role$ = this.route.queryParams.pipe(
    concatMap(params => {
      return forkJoin([
        this.securityService.findRoleById(params['id']),
        this.securityService.getPermissions(),
      ]).pipe(
        tap(([role, permissionsList]) => {
          this.addEditForm.patchValue(role);
          this.loadPerms(role, permissionsList);
        }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  constructor(
    private router:           Router,
    private route:            ActivatedRoute,
    private uiEvent:          UIEventCustom,
    private formBuilder:      FormBuilder,
    private translateService: TranslateService,
    private securityService:  SecurityService
  ) {
    super(router);
  }
  
  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onSubmit() {
    if (!this.addEditForm.valid) {
      this.uiEvent.displayUIError();
      this.showValidationsSubject.next(true);
      return;
    }

    this.securityService.saveRole(this.constructRole()).subscribe({
      next: (id) => {
        this.uiEvent.displayUISuccess();
        this.router.navigate(['/list-roles']);
      },
      error: err => {
        displayError(err);
        this.uiEvent.displayUIError();
      }
    });
  }

  private loadPerms(role: Role, loadedPerms: Permission[]) {
    loadedPerms.map(perm => this.toPremForm(perm, this.checkForPerm(role, perm)))
               .forEach(perm => this.permissions.push(perm));
  }

  private toPremForm(p: Permission, isValid: boolean): FormGroup {
    return this.formBuilder.group({
      id:           p.id,
      isValid:      isValid,
      description:  p.description,
      perm:         p
    })
  }

  private checkForPerm(role: Role, p: Permission) {
    if (role == null) {
      return false;
    }

    return role.permissions.map(perm => perm.id).includes(p.id);
  }

  private constructRole(): Role {
    let formValue = this.addEditForm.value;
    let perms = Array.from(this.permissions.value)
                     .filter((p: any) => p.isValid)
                     .map((p: any) => p.perm);

    return {
      id:           formValue.id          || null,
      name:         formValue.name        || null,
      description:  formValue.description || null,
      permissions:  perms,
      enabled:      formValue.enabled
    }
  }

  get form() {
    return this.addEditForm.controls;
  }

  get permissions() {
    return this.addEditForm.controls.permissions as FormArray;
  }

}