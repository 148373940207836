<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 class="my-2" translate>menu.adminPanel.searchModal</h3>
        </span>
    </div>
    <div class="my-2 py-2">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- name -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>page-management.pageNum</span>
                </div>
                <input type="text" class="form-control" style="max-width: 250pt;" (keyup.enter)="search()" formControlName="pageNumber" id="nameInput">
                
                <!-- status -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>page-management.status</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="isActive" style="max-width: 250pt;">
                    <option [value]="true" translate>yes</option>
                    <option [value]="false" translate>no</option>
                </select>
                <!-- actions -->
                <div class="input-group-prepend ml-2">
                    <div style="display: flex">
                        <button class="btn btn-outline-success" style="border-radius: 0;" type="button" (click)="search()" translate>button.search</button>
                        <button class="btn btn-outline-danger ml-2" style="border-radius: 0;" type="button" (click)="clearSearch()" translate>button.clear</button>
                    </div>
                </div>
            </div>
        </form>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th style="width: 15%" translate>page-management.pageNum</th>
                        <th style="width: 7%" translate>page-management.status</th>
                        <th style="width: 15%" translate>page-management.miliseconds</th>
                        <th style="width: 30%" translate>page-management.path</th>
                        <th style="width: 30%" translate>page-management.techPointNames</th>
                        <th style="width: 10%" translate>page-management.actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let unit of content">
                        <td>{{unit.pageNumber}}</td>
                        <td translate> {{unit.isActive == true? 'Да' : 'Не'}}</td>
                        <td translate> {{unit.showAddMiliseconds}}</td>
                        <td translate> {{unit.pathToFtp}}</td>
                        <ng-container *ngIf="unit.techPointNames === 'allTechPoints'">
                            <td translate>page-management.allTechPoints</td>
                        </ng-container>
                        <ng-container *ngIf="unit.techPointNames !== 'allTechPoints'">
                            <td> {{unit.techPointNames}}</td>
                        </ng-container>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                                routerLink="/site-page-management" [queryParams]="{id: unit.id}">
                                <i class="fa fa-pencil fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>