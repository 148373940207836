import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Page } from '@interfaces/page';
import { FormGroup } from '@angular/forms';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { CustomerHttpParamsCodec } from '@helpers/custom-http-params-codec';
import { ListDailyServiceReportSingle } from '../_models/list-daily-service-report-single.model';

const dailyServiceReportSingleURL = environment.apiBaseURL + '/daily-service-report-single';

const pageableTotalElements            = dailyServiceReportSingleURL + '/pageable/count';
const pageableDailyServiceReportSingle = dailyServiceReportSingleURL + '/pageable';
const filterUrl                        = dailyServiceReportSingleURL + '/filter-owner-name';

@Injectable({
  providedIn: 'root'
})
export class DailyServiceReportSingleService {
  
  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPagingData, searchForm: any, fromDate: string, toDate: string) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm, fromDate, toDate),
      this.countAllByFilter(sortingPaging, searchForm, fromDate, toDate)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<Page<ListDailyServiceReportSingle>> {
    return this.http.get<Page<ListDailyServiceReportSingle>>(pageableDailyServiceReportSingle, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<number> {
    return this.http.get<number>(pageableTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string) {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }

    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });

    requestParams = this.filterSubjects(requestParams, searchForm, fromDate, toDate);

    return requestParams;
  }

  filterSubjects(requestParams: HttpParams, searchForm : any, fromDate: string, toDate: string){
    if (searchForm != null && searchForm.techPointMobileAppId != '' && searchForm.techPointMobileAppId != null) {
        requestParams = requestParams.set('mobileAppId', searchForm.techPointMobileAppId);
    }

    if(fromDate && fromDate.length !== 0) {
      requestParams = requestParams.set("dateFrom", fromDate);
     }

    if(toDate && toDate.length !== 0) {
        requestParams = requestParams.set("dateTo", toDate);
    }

    if (searchForm.subject != null) {
      if (searchForm.subject.mobileAppId != null) {
        requestParams = requestParams.set('subjectMobileAppId', searchForm.subject.mobileAppId);
      } else {
        requestParams = requestParams.set('subjectName', searchForm.subject);
      }
    }

    return requestParams;
  }

  public filterOwnerName(name: string) {
    let params = new HttpParams().set('name', name);

    return this.http.get<String[]>(filterUrl, { params : params });
  }

}
