<ng-container *ngIf="loadComponent">
    <form [formGroup]="passwordForm">
        <h4 mat-dialog-title translate style="text-align: center;">userProfile.changePassword</h4>
        <ng-container *ngIf="showAddOldPassword">
            <div class="form-group">
                <label class="col-form-label" translate>userProfile.currPassword</label>
                <input type="password" formControlName="currPassword" class="form-control" id="currPassword" [ngClass]="{ 'is-invalid': showValidations && passForm.currPassword.errors }">
                <div class="invalid-feedback" *ngIf="passForm.currPassword.errors?.required && showValidations" translate>errors.required</div>
                <div class="invalid-feedback" *ngIf="passForm.currPassword?.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 50} }}</div>
            </div>
        </ng-container>
        <div class="form-group">
            <label class="col-form-label" translate>userProfile.newPassword</label>
            <input type="password" formControlName="newPassword" class="form-control" id="newPassword" [ngClass]="{ 'is-invalid': showValidations && passForm.newPassword.errors }">
            <div class="invalid-feedback" *ngIf="passForm.newPassword.errors?.required && showValidations" translate>errors.required</div>
            <div class="invalid-feedback" *ngIf="passForm.newPassword?.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 50} }}</div>
            <small class="text-danger" *ngIf="passForm.newPassword?.errors && showValidations" translate>
                errors.passwordFormat
            </small>
        </div>
        <div class="form-group">
            <label class="col-form-label" translate>userProfile.repeatNewPassword</label>
            <input type="password" formControlName="repeatNewPassword" class="form-control" id="repeatNewPassword" [ngClass]="{ 'is-invalid': showValidations && passForm.repeatNewPassword.errors }">
            <div class="invalid-feedback" *ngIf="passForm.repeatNewPassword.errors?.required && showValidations" translate>errors.required</div>
            <div class="invalid-feedback" *ngIf="passForm.repeatNewPassword?.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 50} }}</div>
            <small class="text-danger" *ngIf="passForm.repeatNewPassword?.errors && showValidations" translate>
                errors.passwordsMustMatch
            </small>
            <div>
            <small class="text-danger" *ngIf="passForm.newPassword?.errors && showValidations" translate>
                errors.passwordFormat
            </small>
            </div>
        </div>
        <div style="text-align: center;">
            <button type="button" class="btn btn-primary" style="margin-left: 20px;" (click)="changePassword()" translate>button.save</button>
        </div>
    </form>
</ng-container>