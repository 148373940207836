import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubjectVersion } from '@models/subject-version.model';
import { AddEditPersonComponent } from './add-edit-person/add-edit-person.component';
import { SearchPersonComponent } from './search-person/search-person.component';

@Component({
  selector: 'app-register-of-legal-persons-modal',
  templateUrl: './register-of-legal-persons-modal.component.html',
  styleUrls: ['./register-of-legal-persons-modal.component.css']
})

export class RegisterOfLegalPersonsModalComponent implements OnInit {
  editState=false;
  addSection: boolean;
  subjectVersion: SubjectVersion;
  selectedSubjectId: number;
  versionDataId: number;

  @Output() submitSubject = new EventEmitter<any>();

  @ViewChild('addEdit') addEditComponent: AddEditPersonComponent;
  @ViewChild('search') searchComponent: SearchPersonComponent;
  
  constructor(
    private dialogRef: MatDialogRef<RegisterOfLegalPersonsModalComponent>
  ) { }

  ngOnInit() {}

  handleGetSubjectEvent(subject: any) {
    this.addEditComponent.initialize(subject.id, subject.versionDataId);
  }

  selectSubject(subjectId: number, versionDataId: number) {
    this.selectedSubjectId = subjectId;
    this.versionDataId = versionDataId;
    this.editState = true;
  }

  ngAfterViewInit() {
    if(this.selectedSubjectId != null)
      this.addEditComponent.initialize(this.selectedSubjectId, this.versionDataId);    
  }

  handleSelectSubjectEvent(subject: SubjectVersion) {
    this.subjectVersion = subject;
    this.submitSubject.emit(this.subjectVersion);
    
    this.closeDialog();
   }

  closeDialog() {
    this.dialogRef.close();
  }

  handleLoadAddEvent(editState: boolean) {
    this.editState = editState;
    this.addEditComponent.clear();
  }

  handleEditStateEvent(editState: boolean) {
    this.editState = editState;
  }

  handleEditStateAndReloadEvent(editState: boolean) {
    this.editState = editState;
    this.searchComponent.reload();
  }
 
  handleAddSectionEvent(addSection: boolean) {
    this.addSection = addSection;
  }

  changeState(state: boolean) {
    this.editState = state;
  }

  onSubmit() { }
  
}
