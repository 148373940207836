export interface PaymentServiceData {
  serviceName: String;
  serviceType: String;
  amountDds: number;
  calculatedDiscount: number | null;
}

export function createPaymentServiceData(serviceName: String, serviceType: String, amountDds: number, calculatedDiscount: number | null): PaymentServiceData {
  return {
    serviceName: serviceName,
    serviceType: serviceType,
    amountDds: amountDds,
    calculatedDiscount: calculatedDiscount,
  };
}