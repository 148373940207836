import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { Service } from '@app/services/_models/service.model';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { NomenclatureService } from '@services/nomenclature.service';
import { EMPTY, forkJoin, Subscription } from 'rxjs';
import { catchError, concatMap, first, repeatWhen, switchMap, tap } from 'rxjs/operators';
import { ListExpiringServices } from '../_models/list-expiring-services.model';
import { ExpiringServicesService } from '../_services/expiring-services.service';
import { prepareDateForDb, convertDateToString, convertObjDateToString, prepareDateForForm } from '@app/_utils/date-util';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';

@Component({
  selector: 'app-expiring-services',
  templateUrl: './expiring-services.component.html',
  styleUrls: ['./expiring-services.component.css']
})
export class ExpiringServicesComponent extends BaseSortableSearchComponent<ListExpiringServices> implements OnInit, OnDestroy {
  // Units
  services:   Service[];
  dateObj     = new Date();
  sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
  
  // Booleans
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    validFrom:                       prepareDateForForm(this.dateObj),
    validTo:                         prepareDateForForm(this.dateObj),
    // service:                         null,
    regNumber:                       null,
    phoneNumber:                     null
    // invoiceNumber:                null,
    // identNumber:                  [{value: null, disabled: true}]
  });

  // Observables
  loadSearchForm$ = this.route.queryParams.pipe(
    switchMap(params => {
     return forkJoin([
       this.nomenclatureService.findAllValidServices(),
       ]).pipe(
         tap(([services]) => {
           this.services = services;
           this.searchSubject.next();
         }),
         catchError(err => {
           displayError(err);
           this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
           return EMPTY;
         }),
        repeatWhen(() => this.reload$)
        );
     }) 
  );

  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.expiringServicesService.pageable(this.sortingPaging, this.searchForm.value, 
                                                   prepareDateForDb(this.searchForm.get('validFrom')?.value), 
                                                   prepareDateForDb(this.searchForm.get('validTo')?.value)).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
      displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    })
  );

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public perms: PermissionsService,
    private exportService: ExportService,
    private translateService: TranslateService,
    private nomenclatureService: NomenclatureService,
    private expiringServicesService: ExpiringServicesService) {
      super();
  }

  ngOnInit() {
    this.searchSubscription = this.search$.subscribe();
    this.searchSubject.next();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      validFrom:                            prepareDateForForm(this.dateObj),
      validTo:                              prepareDateForForm(this.dateObj),
      regNumber:                            null,
      phoneNumber:                          null
    });

    this.searchSubject.next();
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.expiringServicesResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.expiringServicesResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData= new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;

    let result = await this.expiringServicesService.findAllByFilter(sortingPagingCopy, this.searchForm.value,
                                                                    prepareDateForDb(this.searchForm.get('validFrom')?.value), 
                                                                    prepareDateForDb(this.searchForm.get('validTo')?.value)
                                                                   ).pipe(first()).toPromise();

    result.content.forEach(object => {
       newContent?.push([
          object.phoneNumber,
          object.email,
          object.regNumber, 
          object.validTo])
    })

    return newContent;
  }
 
  private getHeadings(): string[][] {
    return [[this.translateService.instant("references.phoneNumber"), this.translateService.instant("references.email"), 
              this.translateService.instant("references.regNumber"), 
              this.translateService.instant("references.dateValidity")]]
  }
   
  private getFilterBody(): any[] {
    // let service: Service | null;
    // service = this.searchForm.get('service')?.value;
  
    let result: string[]= [
      convertObjDateToString(this.searchForm.get('validFrom')?.value), 
      convertObjDateToString(this.searchForm.get('validTo')?.value),
      //  service?.name,
      this.searchForm.get('regNumber')?.value,
      this.searchForm.get('phoneNumber')?.value
      //  this.searchForm.get('invoiceNumber')?.value,
      //  this.searchForm.get('identNumber')?.value
    ];
 
    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listExpiringServices.dateFrom'),
      this.translateService.instant('exportData.listExpiringServices.dateTo'),
      //  this.translateService.instant('exportData.listExpiringServices.service'),
      this.translateService.instant('exportData.listExpiringServices.phoneNumber'),
      this.translateService.instant('exportData.listExpiringServices.regNumber')
      //  this.translateService.instant('exportData.listExpiringServices.invoiceNumber'),
      //  this.translateService.instant('exportData.listExpiringServices.identNumber')
    ];
 
    return [
      [{content: this.translateService.instant('menu.expiringServices.search'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

}