<div *ngIf="isInitialized; else invoiceLoading">
<div *ngIf="customerInvoiceDetails.fullName != null" class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <b translate>clientProfile.invoiceName</b>
        <br><label>{{customerInvoiceDetails?.fullName}}</label>
      </div>
      <div class="col-md-6 col-lg-4">
        <b translate>clientProfile.invoiceMOL</b>
        <br><label>{{customerInvoiceDetails?.manager}}</label>
      </div>
      <div class="col-md-6 col-lg-4">
        <b translate>clientProfile.invoiceVAT</b>
        <br><label>{{customerInvoiceDetails?.vatNumber}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <b translate>clientProfile.invoiceEIK</b>
        <br><label>{{customerInvoiceDetails?.identNumber}}</label>
      </div>
      <div class="col-md-6 col-lg-4">
        <b translate>clientProfile.invoiceAddress</b>
        <br><label>{{customerInvoiceDetails?.fullAddress}}</label>
      </div>
      <!-- <div class="col-md-6 col-lg-4">
        <b translate>clientProfile.invoiceIBAN</b>
        <br><label>ИБАН</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <b for="invoiceBIC" translate>clientProfile.invoiceBIC</b>
        <br><label>SWIFT/BIC</label>
      </div>
      <div class="col-md-6 col-lg-4">
        <b for="invoiceBank" translate>clientProfile.invoiceBank</b>
        <br><label>Банка</label>
      </div> -->
    </div>
  </div>
  </div> 
  <ng-template #invoiceLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else invoiceSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #invoiceSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>