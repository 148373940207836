<div class="container mainbox">
    <form [formGroup]="mainDataForm" *ngIf="loadPromotion$ | async as data; else loading">
        <h1 *ngIf="module == DISCOUNT_MODULE_CODE" class="form-group title-position" style="margin-top: 20px;" translate>promotion.promotion</h1>
        <h1 *ngIf="module == PROMOCODE_MODULE_CODE" class="form-group title-position" translate>promotion.promoCode</h1>
        <h1 *ngIf="module == INVITE_FRIEND_MODULE_CODE" class="form-group title-position" style="margin-top: 20px;" translate>promotion.inviteFriend</h1>

        <!-- ****************************************************** 
        ************** Core Promotion Data *************
        ****************************************************** -->
        <div id="mainContent" role="tablist" aria-multiselectable="true">
            <div class="card">
                <div class="card-header" id="mainSection">
                        <h5 class="mb-0">
                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseMainSection"
                                aria-expanded="true" aria-controls="collapseMainSection" translate>
                                promotion.mainSection
                            </button>
                        </h5>
                </div>
                <div id="collapseMainSection" class="collapse show" aria-labelledby="mainSection" data-parent="#mainContent">
                    <div class="card-body">
                        <div class="container-fluid row">
                            <div class="col-lg-4 col-md-6 " *ngIf="module == PROMOCODE_MODULE_CODE || module == DISCOUNT_MODULE_CODE">
                                <label style="margin-top: 12px;" translate>promotion.name</label>
                                <input style="width: 200pt" type="text" class="form-control" formControlName="name" id="name"
                                    placeholder="{{'placeholder.enter' | translate}}"  [ngClass]="{ 'is-invalid': showValidations && mainForm.name.errors }">      
                                    <div class="invalid-feedback" *ngIf="mainForm.name.errors?.required && showValidations" translate>errors.required</div>  
                                    <div class="invalid-feedback" *ngIf="mainForm.name.errors?.maxlength && showValidations" translate>{{"errors.inputTooLong" | translate: {max: 30} }}</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.name.errors?.invalidCharacters && showValidations" translate>{{"errors.invalidCharacters" | translate }}</div> 
                            </div>
                            
                            <div class="col-lg-4 col-md-6 " *ngIf="module == PROMOCODE_MODULE_CODE || module == INVITE_FRIEND_MODULE_CODE">
                                <label *ngIf="module == PROMOCODE_MODULE_CODE " style="margin-top: 12px;" translate>promotion.maxCount</label>
                                <label *ngIf="module == INVITE_FRIEND_MODULE_CODE" style="margin-top: 12px;" translate>promotion.shipmentsNumber</label>
                                <input style="width: 200pt" type="number" class="form-control" aria-describedby="maxUsageNumber" formControlName="maxUsageNumber"
                                    placeholder="{{'placeholder.enter' | translate}}" [ngClass]="{ 'is-invalid': showValidations && mainForm.maxUsageNumber.errors }">
                                <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.required && showValidations" translate>errors.required</div>  
                                <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.max && showValidations && module == INVITE_FRIEND_MODULE_CODE && statusCode != ACTIVE_STATUS_CODE" translate>errors.tooLong</div>  
                                <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.min && showValidations && module == INVITE_FRIEND_MODULE_CODE" translate>errors.onlyPositiveNumbers</div>
                                <!-- <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.min && showValidations && module == INVITE_FRIEND_MODULE_CODE && statusCode == ACTIVE_STATUS_CODE" translate>errors.onlyExactNumber</div> -->
                                <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.min && showValidations && module == PROMOCODE_MODULE_CODE" translate>errors.onlyPositiveNumbers</div>
                                <!-- <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.max && showValidations && module == INVITE_FRIEND_MODULE_CODE && statusCode == ACTIVE_STATUS_CODE" translate>errors.onlyExactNumber</div> -->
                                <div class="invalid-feedback" *ngIf="mainForm.maxUsageNumber?.errors?.max && showValidations && module == PROMOCODE_MODULE_CODE" translate>errors.tooLong</div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 " *ngIf="module == PROMOCODE_MODULE_CODE ">
                                <label style="margin-top: 12px;" translate>promotion.totalAmount</label>
                                <input style="width: 200pt" type="number" class="form-control" formControlName="orderTotalAmount"
                                    placeholder="{{'placeholder.enter' | translate}}" [ngClass]="{ 'is-invalid': showValidations && mainForm.orderTotalAmount.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.orderTotalAmount?.errors?.required && showValidations" translate>errors.required</div>  
                                    <div class="invalid-feedback" *ngIf="mainForm.orderTotalAmount?.errors?.min && showValidations" translate>errors.onlyPositiveNumbers</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.orderTotalAmount?.errors?.max && showValidations" translate>errors.tooLong</div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 " *ngIf="module == PROMOCODE_MODULE_CODE || module == DISCOUNT_MODULE_CODE">
                                <div style="width: 200pt">
                                <label style="margin-top: 12px;" translate>promotion.priceAttributeKind</label>
                                <select class="custom-select input-custom-size" aria-describedby="priceAttributeKind" formControlName="priceAttributeKind" (change) = "changePriceAttrKind($event)"
                                [ngClass]="{ 'is-invalid': showValidations && mainForm.priceAttributeKind.errors }">
                                    <option value="null" disabled translate>placeholder.select</option>
                                    <option value="P" translate> promotion.discountPercent </option>
                                    <option value="V" translate> promotion.discountInValue </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.priceAttributeKind.errors?.required && showValidations" translate>errors.required</div>  
                                </div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 " *ngIf="module == PROMOCODE_MODULE_CODE || module == DISCOUNT_MODULE_CODE">
                                <label style="margin-top: 12px;" translate>promotion.amount</label>
                                <input style="width: 200pt" type="number" class="form-control" aria-describedby="priceAttributeAmountDds" formControlName="priceAttributeAmountDds"
                                    placeholder="{{'placeholder.enter' | translate}}" [ngClass]="{ 'is-invalid': showValidations && mainForm.priceAttributeAmountDds.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.priceAttributeAmountDds.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.priceAttributeAmountDds?.errors?.min && showValidations" translate>errors.onlyPositiveNumbers</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.priceAttributeAmountDds?.errors?.max && showValidations" translate>errors.tooLong</div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 ">
                                <div>
                                    <label style="margin-top: 12px;" translate>techPoint.status</label>
                                </div>
                                <select class="custom-select input-custom-size" style="width: 200pt" [compareWith]="compareById" formControlName="status"
                                        [ngClass]="{ 'is-invalid': showValidations && mainForm.status.errors }" >
                                    <option value="null" disabled translate>placeholder.select</option>
                                    <option *ngFor="let status of statuses" [ngValue]="status" > {{status.name}}
                                </select>
                                <div class="invalid-feedback" *ngIf="mainForm.status.errors?.required && showValidations" translate>errors.required</div>  
                            </div> 
                            
                            <div class="col-lg-4 col-md-6 ">
                                <label style="margin-top: 12px;" translate>promotion.validFrom</label>
                                <div  style="width: 200pt" class="input-group mt-1"> 
                                    <input class="form-control d-inline"  aria-describedby="validFrom"  formControlName="validFrom" [owlDateTime]="dt1"
                                    [ngClass]="{ 'is-invalid': showValidations && mainForm.validFrom.errors }" [min]="todayDate" readonly>
                                        <div class="input-group-append">
                                            <button [owlDateTimeTrigger]="dt1" class=" btn btn-outline-secondary fa fa-calendar" [disabled]="statusCode == ACTIVE_STATUS_CODE"></button>
                                        </div>
                                    <owl-date-time [disabled]="statusCode == ACTIVE_STATUS_CODE" #dt1></owl-date-time>
                                </div>
                                <small class="text-danger pt-1" *ngIf="mainForm.validFrom.errors && showValidations && mainForm.validFrom.value == 'Invalid Date'" translate>errors.required</small>
                                <small class="text-danger pt-1" *ngIf="mainForm.validFrom.value < todayDate && showValidations" translate>errors.pastDateAndHour</small> 
                            </div>

                            <div class="col-lg-4 col-md-6 ">
                                <label style="margin-top: 12px;" translate>promotion.validTo</label>
                                <div  style="width: 200pt" class="input-group mt-1"> 
                                    <input class="form-control d-inline"  aria-describedby="validTo"  formControlName="validTo"  
                                        [owlDateTime]="dt2" [ngClass]="{ 'is-invalid': showValidations && mainForm.validTo.errors }" [min]="todayDate" readonly>
                                        <div class="input-group-append">
                                            <button [owlDateTimeTrigger]="dt2" class=" btn btn-outline-secondary fa fa-calendar" [disabled]="statusCode == ACTIVE_STATUS_CODE"></button>
                                        </div>
                                    <owl-date-time [disabled]="statusCode == ACTIVE_STATUS_CODE" #dt2></owl-date-time>
                                </div>
                                <small class="text-danger pt-1" *ngIf="mainForm.validTo.errors && showValidations && mainForm.validTo.value == 'Invalid Date'" translate>errors.required</small>
                                <small class="text-danger pt-1" *ngIf="mainDataForm.errors && showValidations" translate>
                                    errors.validFromToDates
                                </small>
                                <small class="text-danger pt-1" *ngIf="mainForm.validTo.value < todayDate && showValidations" translate>errors.pastDateAndHour</small> 
                            </div>
                            
                            <div class="col-lg-4 col-md-6 ">
                                <ng-container *ngIf="!subject">
                                    <div>
                                        <label style="margin-top: 12px;" translate>techPoint.subject</label>
                                    </div>
                                    <button class="btn btn-primary" type="button" (click)="openModal()" translate>technicalInspection.chooseSubject</button>
                                </ng-container>
                                <ng-container *ngIf="subject">
                                    <span style="display: block; margin-top: 1rem; position: absolute; width: 500px;" ><strong>Избрано лице:</strong> {{subject.fullName}}</span>
                                    <button class="btn btn-danger ml-2" style="margin-top: 2.9rem;" (click)="clearSubject()" type="button" translate>button.clearSubject</button>
                                </ng-container>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ****************************************************** 
        ************** Service Section *************
        ********* promotion-services component *******
        ****************************************************** -->
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="serviceSection">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseServiceSection"
                            aria-expanded="true" aria-controls="collapseServiceSection" (click)="initializeSection('services')" translate>
                            promotion.serviceSection
                        </button>
                    </h5>
                </div>
                <div [ngClass]="{'show': servicesComponentState}" id="collapseServiceSection" class="collapse hidden" aria-labelledby="serviceSection" data-parent="#accordion">
                    <app-promotion-services #services [changedCategoriesServices]="changedCategoriesServices" [showValidations$]="showFullValidation$"
                        [parentId]="promotionId" [module]="module" [isDisabled]="isDisabled" [statusCode]="promotion?.status?.code">
                    </app-promotion-services>
                </div>
            </div>
        </div>

        <!-- ****************************************************** 
        ************** Technical Point Section *************
        ********* promotion-technical-points component *******
        ****************************************************** -->
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="technicalPointSection">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseТechnicalPointSection"
                            aria-expanded="true" aria-controls="collapseTechnicalPointSection" (click)="initializeSection('technicalPoints')" translate>
                            promotion.technicalPointSection
                        </button>
                    </h5>
                </div>
                <div [ngClass]="{'show': tpComponentState}" id="collapseТechnicalPointSection" class="collapse hidden" aria-labelledby="technicalPointSection" data-parent="#accordion">
                    <app-promotion-technical-points #technicalPoints [changedCategories$]="changedCategories$" 
                        [showValidations$]="showFullValidation$" [parentId]="promotionId" [isDisabled]="isDisabled" [statusCode]="promotion?.status?.code" [module]="module"> 
                    </app-promotion-technical-points>
                </div>
            </div>
        </div>

        <!-- ****************************************************** 
        ************** Invite Friend Section *************
        ********* promotion-friends-config component *******
        ****************************************************** -->
        <div *ngIf="module == INVITE_FRIEND_MODULE_CODE" id="accordion">
            <div class="card">
                <div class="card-header" id="configuraionSection">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseConfigurationSection"
                            aria-expanded="true" aria-controls="collapseConfigurationSection" (click)="initializeSection('friendsConfig')"translate>
                            promotion.configuration
                        </button>
                    </h5>
                </div>
                <div [ngClass]="{'show': friendConfigComponentState}" id="collapseConfigurationSection" class="collapse hidden" aria-labelledby="configurationSection" data-parent="#accordion">
                    <app-promotion-friends-config #friendsConfig [parentId]="promotionId" (countRowEvent)="handleCountRowEvent($event)" [module]="module"
                        [showValidations$]="showFullValidation$">
                    </app-promotion-friends-config>
                </div>
            </div>
        </div>
    </form>

    <!-- ****************************************************** 
    ************** Save And Deactivate Button *************
    ****************************************************** -->
    <div class="text-right" *ngIf="dataLoaded">
        <button class="btn btn-primary w-20" style="margin-top: 10px;" (click)="save(false)"  *ngIf="canAddEdit && promotion?.status?.code != ACTIVE_STATUS_CODE" translate>button.save</button>
        <button class="btn btn-primary w-20" style="margin-top: 10px;"  (click)="deactivate()" *ngIf="canAddEdit && (promotion?.status?.code == ACTIVE_STATUS_CODE && promotionId != null)" translate>Деактивирай</button>
    </div>

    <ng-template #loading>
        <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
            <div class="alert alert-danger">
                {{errorMessage}}
            </div>
            <div class="d-flex justify-content-center">
                <i class="fa fa-refresh" (click)="reload()"></i>
            </div>
        </div>
        <ng-template #spinner>
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>