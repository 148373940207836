import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, forkJoin, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, repeatWhen, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { WarningMessageboxComponent } from '@components/_base/_messageboxes/warning-messagebox/warning-messagebox.component';
import { YesNoMessageboxComponent } from '@components/_base/_messageboxes/yes-no-messagebox/yes-no-messagebox.component';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';

import { TechnicalInspectionService } from '@app/technical-inspections/_services/technical-inspection.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { PromotionService } from '@services/promotion.service';

import { UtilValidators } from '@app/_validators/util.validators';

import { config } from '@env/config';
import { modalMinWidth } from '@env/config';

import { PaymentServiceData, createPaymentServiceData } from '@app/technical-inspections/_models/payment-service-data.model';
import { TechInspectionPromoCode } from '@app/technical-inspections-cc/_models/tech-inspection-promo-code.model';
import { AvailableTimeTechPoint } from '@app/technical-inspections-cc/_models/available-time-tech-point.model';
import { PromotionCodeService } from '@app/technical-inspections-cc/_models/promotion-code-service.model';
import { CustomerSubscriptionService } from '@app/customers/_models/customer-subscription-service.model';
import { TechnicalInspection } from '@app/technical-inspections/_models/technical-inspection.model';
import { AvailableTimeSlot } from '@app/technical-inspections-cc/_models/available-time-slot.model';
import { CustomerSubscription } from '@app/customers/_models/customer-subscription.model';
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { CustomerCards } from '@app/card-config/_models/customer-cards.model';
import { TechInspectionTime } from '@models/tech-inspection-time.model';
import { CustomerDiscount } from '@models/customer-discount.model';
import { RejectReason } from '@models/reject-reason.model';
import { RvsCategory } from '@models/rvs-category.model';
import { LoyalCombo } from '@models/loyal-combo.model';
import { Status } from '@models/status.model';
import { City } from '@models/city.model';

import { StatusCode } from '@app/_enums/status-code';

import { displayError } from '@app/_utils/error-util';
import { prepareDateForForm } from '@app/_utils/date-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { prepareDateForDb } from '@app/_utils/date-util';


@Component({
  selector: 'app-add-edit-technical-inspection-cc',
  templateUrl: './add-edit-technical-inspection-cc.component.html',
  styleUrls: ['./add-edit-technical-inspection-cc.component.css']
})
export class AddEditTechnicalInspectionCcComponent extends BaseParentComponent implements OnInit, OnDestroy {
  // Units
  technicalInspection:                TechnicalInspection;
  defRvsCategory:                     RvsCategory | null;
  customerSubscription:               CustomerSubscription | null;
  annualServices:                     TechPointService[];
  basicServices:                      TechPointService[];
  basicServicesCopy:                  TechPointService[];
  rejectReasons:                      RejectReason[];
  rvsCategories:                      RvsCategory[];
  techInspStatuses:                   Status[];
  cities:                             City[];
  customerDiscounts:                  CustomerDiscount[];
  additionalServices:                 TechPointService[];
  paymentServiceConvertedData:        Array<PaymentServiceData> = [];
  paymentServiceTableData:            Array<TechPointService> = [];
  technicalInspectionTimes:           TechInspectionTime[] | null;
  technicalPoints:                    TechnicalPoint[];
  availableTimesTechPoints:           AvailableTimeTechPoint[];
  currSlots:                          AvailableTimeSlot[] = [];

  // Constants
  readonly BASIC_SERVICE_NAME           = 'ГТП';
  readonly DISCOUNT_PERCENTAGE          = 'P';
  readonly RVS_CATEGORY_CODE            = 'M1';
  readonly CODE_RESERVED                = 'RESERVED';
  readonly CODE_FINISHED                = 'FINISHED';
  readonly CODE_PAID                    = 'PAID';
  readonly INCOMING_WAY_CC              = 'erp_cc';
  readonly DISCOUNT_VALUE               = 'V';
  readonly CITY_CODE_SOFIA              = 'SOF';
  readonly ORDER_OPTION_DATE            = 'date';
  readonly INVALID_LOYAL_CARD           = "INVALID_LOYAL_CARD";
  readonly MAIN_SERVICE_TYPE            = "Основна услуга";
  readonly ADDITIONAL_SERVICE_TYPE      = "Допълнителна услуга";
  readonly INACTIVE                     = "INACTIVE";
  readonly MAIN_SERVICE_GTP_NAME        = "ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД";
  readonly DISCOUNT_TYPE_TECHNICAL_POINT= "tp";
  
  // Booleans
  submittedMainForm                = false;
  loading                          = false;
  showAllFields                    = false;
  showAllFieldsGeneral             = false;
  hasValidSubscription             = false;
  isValidMainFormRegNum            = false;
  isVisiblePaymentServicesTable    = false;
  isUnchanged: boolean | null      = false;
  disableTechPointServices: boolean | null;
  disableAnnualService:     boolean | null;
  hasPromocode                     = false;
  orderPaid                        = false;
  disableSaveButton                = false;
  isPromoCode                      = false;
  isPromoCodeDataArrivedHtml       = true;
  isCardPromotion                  = false;
  isCodePromotion                  = false;
  isPromotionDataArrived           = true;
  isReloaded:                      boolean;
  isNewCodePromotion               = false;        
  isPercentageDiscount:boolean | undefined;
  isCityChange                     = false;
  isValidRegNumberForForeigner     = false;
  isValidRegNumberForDiplomatic    = false;
  isValidRegNumberForElectric      = false;
  isReservationPaid                = false;
  orderPriceMismatch               = false;
  displayExceedCardMessage         = false;
  displayCardDoesNotExistMessage   = false;

  // Payload
  promotionCodeService:                 PromotionCodeService | null;
  currentSelectedServiceMobileAppIds:   Array<number> = [];
  annualService:                        TechPointService | null;
  customerCard:                         CustomerCards | null;
  minDate:                              {year: number, month: number, day: number};
  selectedTechPointId:                  number | null;
  technicalInspectionId:                number;
  techInspMobileId:                     number | null;
  customerSubscriptionName:             String | null;
  techPointName:                        String | null;
  dateOfInsp:                           String | null;
  slotDescr:                            String | null;
  techInspStatusName:                   string | null;
  selectedAdditionalServices:           Array<any> = [];
  selectedTechPointServices:            Array<any> = [];
  servicesMobileAppIdsForCard:          number[];
  orderOptions:                         any[];
  mainServices:                         any[];
  lastSelectedDiscountByCard            = '';
  inRangePromocodeServices              = new Set<number>();
  counterForLoadTechInsp                = 0;
  counterForReservationPromotionEvents  = 0; 
  promotionId: number | null            = null;
  promotionCodeName: string | null      = null;
  paidOrderCode                         = 'PAID';
  paymentTypeCardName                   = 'С карта';
  orderAmountPaid                       = 0;
  currentAmountServices                 = 0;

  // Form
  techInspectionMainForm = this.formBuilder.group({
    id:                           [null],
    email:                        ['',   [Validators.required, Validators.email, Validators.maxLength(60)]],
    name:                         ['',   [Validators.required, Validators.maxLength(60)]],
    regNumber:                    ['',   [Validators.required, Validators.maxLength(16)]],
    registrationCertNumber:       ['',   [Validators.maxLength(9)]],
    rvsCategory:                  [''],
    basicTechPointService:        ['',   [Validators.required]],
    phoneNumber:                  ['',   [Validators.required, Validators.maxLength(16), UtilValidators.validatePhoneNumber()]],
    isAgreeWithNotification:      [null],
    inspectionDate:               ['',   [Validators.required]],
    searchDate:                   [''],
    versionData:                  [''],
    status:                       [''],
    lineNumber:                   [''],
    customerRvsId:                [''],
    technicalPointId:             [''],
    customerDiscount:             [''],     
    rejectReason:                 [''],
    createdOrder:                 [''],
    city:                         [''], 
    orderOption:                  [''],
    mainService:                  [''],
    incomingWay:                  [''],
    searchTechPoint:              [''],
    techPointWorkingHourId:       [''],
    contractRvsId:                [null],
    subjectId:                    [null],
    subjectIdentNum:              [null],
    subjectManager:               [null],
    subjectInvoiceDdsReg:         [null],         
    subjectName:                  [null],
    subjectCity:                  [null],
    subjectAddress:               [null],
    subjectVersionData:           [null],
    promoCode:                    [''],
    customerCard:                 [null],
    promotionId:                  [null],
    customerCardDiscount:         [''],
    customerCardDiscountName:     [],
    customerPromoDiscount:        [null],
    createOrder:                  [null],
    isEmployee:                   [false],
  });

  // Subjects
  private rvsAdditionalSubject = new Subject<RvsCategory | null>();
  
  // Subscription
  private subscriptions = new Subscription();

  // Observables
  loadTechnicalInspection$ = this.route.queryParams.pipe(
    concatMap(params => {
      return forkJoin([
        this.techInspService.findTechnicalInspectionById(params['id']),
        this.techInspService.getTechInspCcNomenclatures(StatusCode.TechnicalInspection)
      ]).pipe(
        tap(() => this.technicalInspectionId = params['id']),
        tap(async ([techInspection, [technicalPoints, cities, techInspStatuses, rvsCategories, rejectReasons, customerDiscounts]]) => {
          if (this.counterForLoadTechInsp > 0) {
            return;
          }
          this.techInspectionMainForm.patchValue(techInspection);
          this.technicalInspection = techInspection;
          if (this.technicalInspection && this.technicalInspection.techInspectionOrders) {
            this.technicalInspection.techInspectionOrders?.forEach(o => {
              if (o.order?.status.code === this.paidOrderCode && o.order.paymentType.description === this.paymentTypeCardName) {
                this.isReservationPaid = true;
                this.orderAmountPaid = 0;
                const firstVersionData = this.findLastFirstVersion(techInspection.versionData);
                this.techInspService.getPaidAmountFromFirstTechnicalInspection(firstVersionData.id).subscribe(res => {
                  this.orderAmountPaid = res;
                });
              }
            });
          }
          
          this.technicalPoints = technicalPoints.filter(tp => tp.status?.code !== this.INACTIVE);
          this.cities = cities;
          this.techInspStatuses = techInspStatuses;
          this.rvsCategories = rvsCategories;
          
          this.rejectReasons = rejectReasons;
          this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
          this.orderOptions = this.loadOrderOptions();
          this.isReloaded = false;
          this.loadDefaultSearchAvailableTimeSlotFilters();

          if (techInspection) {
            this.showAllFieldsGeneral = true;
            this.selectedTechPointId = techInspection.technicalPoint?.id!;
            this.techInspStatusName = this.technicalInspection.status!.name;
            this.techInspMobileId = this.technicalInspection.mobileId!;
            this.techPointName = this.technicalInspection.technicalPoint!.name;
            this.dateOfInsp = this.technicalInspection.inspectionDate!;
            this.slotDescr = this.technicalInspection.techInspectionTimes![0].technicalPointWorkingHours?.timeConstraint.validFrom + '-' + this.technicalInspection.techInspectionTimes![0].technicalPointWorkingHours?.timeConstraint.validTo;
            this.techInspectionMainForm.get('techPointWorkingHourId')?.patchValue(this.technicalInspection.techInspectionTimes![0].technicalPointWorkingHours?.id);
            this.techInspectionMainForm.get('technicalPointId')?.patchValue(this.technicalInspection.technicalPoint!.id);
            this.rvsAdditionalSubject.next(techInspection.rvsCategory);

            if (this.technicalInspection?.techInspectionOrders![0]?.order) {
              if (this.technicalInspection?.techInspectionOrders![0]?.order?.status?.code == this.CODE_PAID) {
                this.orderPaid = true;
              }
              
              if (this.technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id) {
                this.promotionId = this.technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id;
                this.isPromoCodeDataArrivedHtml = true;
                this.promotionCodeName = this.technicalInspection?.techInspectionOrders![0]?.order?.promotion.name;
                this.techInspectionMainForm.get('promoCode')?.setValue(this.promotionCodeName);

                if (this.technicalInspection?.techInspectionOrders![0]?.order?.orderElements.length) {
                  this.isCodePromotion = true;
                  let totalDiscount = 0.0;
                  let orderElements = this.technicalInspection?.techInspectionOrders![0]?.order?.orderElements;
                  orderElements.forEach(oe => {
                    totalDiscount += Number.parseFloat(oe.discountAmount.toString());
                  });

                  this.isPercentageDiscount = false;
                  this.techInspectionMainForm.get('customerPromoDiscount')?.setValue(totalDiscount);
                  this.calculateDiscount(false, true);     
                }
                this.isPromoCode = true;
                this.hasPromocode = true;
              }
            }

            if (techInspection && techInspection.customerCard) {
              this.customerCard = techInspection.customerCard;
              if (this.customerCard.cardNumber) {
                let isHaveTechInspectionOrders = false;
                this.isCardPromotion = true;
                this.techInspectionMainForm.get('customerCard')?.setValue(this.customerCard.cardNumber);

                if (techInspection.techInspectionOrders && techInspection.techInspectionOrders.length !== 0) {
                  if (techInspection.techInspectionOrders[0].order) {
                    isHaveTechInspectionOrders = true;
                    if (techInspection.techInspectionOrders[0].order.customerDiscount) {
                      this.techInspectionMainForm.get('customerCardDiscount')?.setValue(techInspection.techInspectionOrders[0].order.customerDiscount);
                      this.techInspectionMainForm.get('customerCardDiscountName')?.setValue(techInspection.techInspectionOrders[0].order.customerDiscount.discountName);
                    }
                  }
                }

                if (!isHaveTechInspectionOrders) {
                  this.cardService.getLoyalComboByCard(this.customerCard.cardNumber).subscribe(data => {
                    const loyalCombo = data as LoyalCombo;
                    this.customerCard = loyalCombo.customerCard;
                    if (loyalCombo.customerDiscount) {
                      this.lastSelectedDiscountByCard = loyalCombo.customerDiscount.discountName;
                      this.techInspectionMainForm.get('customerDiscount')?.setValue(loyalCombo.customerDiscount);
                    }
                  });
                }
              }
            } else {
              this.isCardPromotion = false;
              this.customerCard = null;
            }
          }

          this.counterForLoadTechInsp++;
        }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  rvsAdditional$ = this.rvsAdditionalSubject.asObservable().pipe(
    concatMap(rvsCategory => {
      return this.techInspService.getRvsCategoryServicesByType(rvsCategory, this.selectedTechPointId).pipe(
        tap(([basicServices, additionalServices, annualServices]) => {
          this.basicServices = basicServices;
          this.additionalServices = additionalServices;
          this.annualServices = annualServices;
          this.basicServicesCopy = JSON.parse(JSON.stringify(basicServices));
          this.customerSubscriptionName = null;

          if (this.technicalInspectionId == null) {
            this.techInspectionMainForm.get('basicTechPointService')?.patchValue(this.basicServices.find(service => service.serviceName === this.BASIC_SERVICE_NAME) || null);
            this.annualService = null;
          } else {
            this.techInspectionMainForm.get('isAgreeWithNotification')?.patchValue(null);
            this.techInspectionMainForm.get('lineNumber')?.patchValue(this.technicalInspection.techInspectionTimes![0].technicalPointWorkingHours!.line.number);
            this.isVisiblePaymentServicesTable = true;

            // Basic 
            this.basicServices.forEach(basicTechPointService => {
              this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                if (basicTechPointService.id === techInspService.technicalPointService!.id) {
                  this.techInspectionMainForm.get('basicTechPointService')?.patchValue(basicTechPointService);
                }
              });
            });

            // Additional 
            this.additionalServices.forEach(additionalTechPointService => {
              this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                // if (additionalTechPointService.id === techInspService.technicalPointService!.id || additionalTechPointService.serviceName === techInspService.technicalPointService?.rvsService.service?.name) {
                if (additionalTechPointService.id === techInspService.technicalPointService!.id) {
                  this.selectedAdditionalServices.push(additionalTechPointService);
                }
              });
            });

            // Annual
            this.annualServices.forEach(annualTechPointService => {
              this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                if (annualTechPointService.id === techInspService.technicalPointService!.id) {
                  this.annualService = annualTechPointService;
                  this.customerSubscriptionName = annualTechPointService.serviceName!;
                  this.getServicesMobileAppIdsForCard(this.annualService.serviceId!);
                }
              });
            });
            this.getCustomerSubscriptionByRegNumber(this.technicalInspection.regNumber);
          }
        }),
      );
    })
  );

  constructor(
    protected router:             Router,
    private dialog:               MatDialog,
    private route:                ActivatedRoute,
    private uiEvent:              UIEventCustom,
    private formBuilder:          FormBuilder,
    public  perms:                PermissionsService,
    private translateService:     TranslateService,
    private techPointService:     TechnicalPointService,
    private techInspService:      TechnicalInspectionService,
    private cardService:          CustomerCardService,
    private promotionService:     PromotionService
  ) {
    super(router); 
    this.technicalInspection = new TechnicalInspection;
    this.minDate = prepareDateForForm(new Date());
  }

  async ngOnInit() {
    this.isPercentageDiscount = undefined;
    this.rvsAdditional$.subscribe();
    
    super.ngOnInit();
   
    this.subscriptions.add(this.techInspectionMainForm.valueChanges.subscribe({
      next: () => {
        if (this.techInspectionMainForm.dirty) {
          this.onChangeForm();
        }
      }, error: err => {
        displayError(err);
      }
    }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }

  private findLastFirstVersion(versionData: any): any {
    while (versionData.firstVersion !== null) {
        versionData = versionData.firstVersion;
    }
    return versionData;
  }

  onChangeForm() {
    this.isUnchanged = true;
  }

  async onSubmitTechnicalInspectionCcForm() {
    this.submittedMainForm = true;

    if (this.techInspCcFormValidation()) {
      return;
    }

    this.techInspectionMainForm.get('createdOrder')?.setValue(true);
    this.disableSaveButton = true;
    let currSlots: AvailableTimeSlot[] = [];

    await this.techInspService.getTechPointsAvailableTimeSlots(this.constructSearchAvailableTimeSlot(this.techInspectionMainForm.value)).toPromise().then(availableTimesTechPoints => {
      this.availableTimesTechPoints = availableTimesTechPoints;
      availableTimesTechPoints?.forEach(availableTime => {
        availableTime.slots.forEach(slot => {
          if (slot.id == this.techInspectionMainForm.get('techPointWorkingHourId')?.value) {
            currSlots.push(slot);
          }
        })
      })
    });

    if (currSlots.length != 0 || (this.techInspectionMainForm.get('id')?.value != null && this.techInspectionMainForm.get('techPointWorkingHourId')?.touched == false)) {
      this.showAllFields = false;
      this.loading = true;
      
      this.techInspService.saveTechnicalInspection(this.constructTechInspection(), false).subscribe(resp => {
        if (resp) {
          this.uiEvent.displayUISuccess();
          this.prepareNewTechnicialInpection();
        } else {
          this.showNoAvailableSlotsModal();
        }

        this.router.navigate(['/add-edit-technical-inspection-cc'], { relativeTo: this.route });
        this.disableSaveButton = false;
      }, error => {
        displayError(error);
        this.loading = false;  
      });
    } else {
      this.disableSaveButton = false;
      this.showBusySlotWarningModal();
    }
  }

  checkForContract(regNumber: string) {
    if (regNumber !== '') {
      this.techInspService.checkForExistingContractByRegNumber(regNumber).subscribe(contractRvsList => {
        if (contractRvsList.length > 1) {
            this.showMultipleContractWarningModal();
        } else if (contractRvsList.length != 0) {
          var contractRvs = contractRvsList[0];
          //this.techInspectionMainForm.get('registrationCertNumber')?.setValue(contractRvs.registrationCertNum);
          this.techInspectionMainForm.get('contractRvsId')?.setValue(contractRvs.id);
          this.techInspectionMainForm.get('phoneNumber')?.setValue(contractRvs.phoneNumber);
          this.techInspectionMainForm.get('customerDiscount')?.setValue(contractRvs.customerDiscount);
        }
      }, error => {
        displayError(error);  
        this.loading = false;  
      });
    }
  }

  onRvsCategorySelect(rvsCategory: any) {
    this.cleanPaymentServiceTable();
    this.selectedAdditionalServices = [];
    this.reloadNomenclatures(this.selectedTechPointId!);
  }

  onBasicTechPointServiceSelect() {
    this.cleanPaymentServiceTable();

    this.isReloaded = true;
    this.clearPromotionCodeRelated(false);
    if (this.techInspectionMainForm.get('promoCode')?.value) {
      this.onChangePromoCode();
    }
    
    this.techInspectionMainForm.get('customerCard')?.setValue('');
    this.checkCardForOnlyGtp();
  }

  onChangeAdditionalService(event: any, service: TechPointService) {
    this.cleanPaymentServiceTable();

    if (event.target.checked) {
      this.selectedAdditionalServices.push(service);
    } else {
      let index = this.selectedAdditionalServices.indexOf(service);
      this.selectedAdditionalServices.splice(index, 1);
      let index2 = this.selectedTechPointServices.indexOf(service);
      this.selectedTechPointServices.splice(index2, 1);
    }

    this.isReloaded = true;
    this.clearPromotionCodeRelated(false);
    if (this.techInspectionMainForm.get('promoCode')?.value) {
      this.onChangePromoCode();
    }
  }

  onChangeAnnualService(event: any, service: TechPointService) {
    this.cleanPaymentServiceTable();

    if (this.annualService != null && this.annualService.id === service.id) {
      this.annualService = null;
      this.customerSubscriptionName = null;
      this.customerSubscription = null;
      this.servicesMobileAppIdsForCard = [];
      return;
    }
    this.annualService = service;
    this.getServicesMobileAppIdsForCard(this.annualService.serviceId!);
  }

  cleanPaymentServiceTable() {
    this.submittedMainForm = false;
    this.isVisiblePaymentServicesTable = false;
    this.paymentServiceTableData = [];
    this.paymentServiceConvertedData = [];
    this.onChangeForm();
  }

  async showPaymentServicesTable() {
    this.paymentServiceTableData = [];
    this.paymentServiceConvertedData = [];
    this.submittedMainForm = true;

    if (this.techInspCcFormValidation()) {
      return;
    } 

    let mainData = this.techInspectionMainForm.value;
    if (this.annualService != null && mainData.phoneNumber != null && mainData.phoneNumber != '') {
      let hasValidSubscription = await this.techInspService.checkForValidSubscription(mainData.phoneNumber, mainData.regNumber, mainData.registrationCertNumber, this.annualService!.serviceId!).toPromise();
      if (hasValidSubscription) {
        let dialogRefWarn = this.dialog.open(WarningMessageboxComponent, {
          ...modalMinWidth,
          data: {
            message: "messagebox.validSubscription"
          }
        });
        await dialogRefWarn.afterClosed().toPromise();
        this.annualService = null;
      } else {
        let dialogRefWarn = this.dialog.open(YesNoMessageboxComponent, {
          ...modalMinWidth,
          data: {
            isWarning: false,
            message: "messagebox.cardPurchase"
          }
        });

        let isConfirmed = await dialogRefWarn.afterClosed().toPromise(); 
        if (!isConfirmed) {
          this.annualService = null;
        }
      }
    }

    this.isVisiblePaymentServicesTable = true;
    if (this.selectedAdditionalServices.length > 0) {
      this.selectedAdditionalServices.forEach(service => this.paymentServiceTableData.push(service));
    }

    if (this.annualService != null) {
      this.paymentServiceTableData.push(this.annualService);
    }
    this.paymentServiceTableData.push(this.mainForm.basicTechPointService.value);

    this.paymentServiceTableData.forEach(service => this.currentSelectedServiceMobileAppIds.push(service.serviceMobileAppId!));

    let result = await this.techInspService.getTechPointServicePricesFromFunction(this.techInspectionMainForm.get('techPointWorkingHourId')?.value, this.selectedTechPointId!, mainData.rvsCategory.code, this.currentSelectedServiceMobileAppIds, this.dateOfInsp).toPromise();
    if (!this.isNewCodePromotion) {
      if (this.technicalInspection && this.technicalInspection.id) {
        const order = this.technicalInspection?.techInspectionOrders![0]?.order;
        if (order && this.isPromoCode) {
          order!.orderElements.forEach(oe => {
            let matchSer = result.find(s => s.serviceMobileAppId === oe.service.mobileAppId);
            if (matchSer) {
              matchSer!.discountedValue = oe.discountAmount;
              matchSer!.discountedPrice -= oe.discountAmount;
            }
          });
        } 
      }
    }

    this.paymentServiceTableData.forEach(service1 => {
      result.forEach(service2 => {
        if (service1.serviceMobileAppId === service2.serviceMobileAppId) {
          service1.amountDds = service2.discountedPrice
          service1.calculatedDiscount = service2.discountedValue;
        }
      });
    });

    if (this.annualService != null) {
      this.checkForServicesInCard();
    } else if (this.customerSubscription != null && this.customerSubscription != undefined) {
      this.checkForServicesInExistingCard();
    } else {
      this.paymentServiceTableData.forEach(service => {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, service.amountDds!, service.calculatedDiscount!));
      });
    }

    this.calculateDiscount(this.isCardPromotion, this.isCodePromotion);
    this.cleanDuplicationViewServices();
  }

  cleanDuplicationViewServices() {
    if (!this.paymentServiceConvertedData) return;
    const map = new Map<String, any>();
    this.paymentServiceConvertedData.forEach(x => {
      if (!map.has(x.serviceName)) {
        map.set(x.serviceName, x);
      }
    });
    this.paymentServiceConvertedData = [];
    for (let value of map.values()) {
      this.paymentServiceConvertedData.push(value);
    }
  }

  private checkForServicesInExistingCard() {
    this.paymentServiceTableData.forEach(service => {
      if (this.checkIfServiceIsInTheExistingCard(service.serviceMobileAppId!) ) {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, 0, null));
      } else {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, service.amountDds!, null));
      }
    });
  }

  checkIfServiceIsInTheExistingCard(mobileAppId:number) {
    var subscrServices: CustomerSubscriptionService[] = this.customerSubscription!.customerSubscriptionServices;
    for (let index = 0; index < subscrServices.length; index++) {
      const subscrServ = subscrServices[index];
      if (subscrServ.serviceAddInfoItem.childService!.mobileAppId === mobileAppId && subscrServ.isAvailable) {
        return true;
      }
    }
    return false;
  }

  private checkForServicesInCard() {
    this.paymentServiceConvertedData = [];
    this.paymentServiceTableData.forEach(service => {
      if (this.checkIfServiceIsInTheCard(service.serviceMobileAppId!)) {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, 0, null));
      } else {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, service.amountDds!, null));
      }
    });
  }

  checkIfServiceIsInTheCard(mobileAppId: number) {
    if (this.servicesMobileAppIdsForCard == null || this.servicesMobileAppIdsForCard == undefined) {
      return false;
    }
    return this.servicesMobileAppIdsForCard!.some(mobAppId => mobAppId === mobileAppId);
  }

  private constructTechInspection() {
    this.techInspectionMainForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_RESERVED) || null);
    this.techInspectionMainForm.get('incomingWay')?.setValue(this.INCOMING_WAY_CC);
    // this.techInspectionMainForm.get('createdOrder')?.setValue(false);
    this.techInspectionMainForm.get('technicalPointId')?.setValue(this.selectedTechPointId);

    if (this.promotionCodeService && !this.isCardPromotion) {
      this.techInspectionMainForm.get('promotionId')?.setValue(this.promotionCodeService.promotionId);
    } else {
      if (!(this.technicalInspection && this.technicalInspection.promotionId)) {
        this.techInspectionMainForm.get('promotionId')?.setValue(null);
      }
    }
    let mainData = this.techInspectionMainForm.value;
    
    if (this.technicalInspection !== null) {
      this.technicalInspectionTimes
    }
    this.selectedAdditionalServices = [...new Set(this.selectedAdditionalServices)];
    if (this.selectedAdditionalServices.length > 0) {
      this.selectedAdditionalServices.forEach(service => this.selectedTechPointServices.push(service));
    }
    if (this.annualService != null) {
      this.selectedTechPointServices.push(this.annualService);
    }
    this.selectedTechPointServices.push(mainData.basicTechPointService);
    const tempServices: any[] = [];
    for (const [key, value] of this.cleanUnexpectedServices()) {
      tempServices.push(value);
    }
    this.selectedTechPointServices = tempServices;

    var inspDate = '';
    if (typeof mainData.inspectionDate === "string") {
      var splitted = mainData.inspectionDate.split("-"); 
      inspDate = splitted[2] + '-' + splitted[1] + '-' + splitted[0];
    } else {
      inspDate = prepareDateForDb(mainData.inspectionDate);
    }

    if (!mainData.promotionId) {
      if (!this.isCardPromotion && this.isCodePromotion && this.promotionId) {
        mainData.promotionId = this.promotionId;
      }
    }
    if (!this.techInspectionMainForm.get('customerPromoDiscount')?.value) {
      mainData.promotionId = null;
    }

    if (!this.techInspectionMainForm.get('customerCard')?.value) {
      this.customerCard = null;
    }

    const data = {
      id:                      mainData.id                           || null,
      email:                   mainData.email                        || null,
      name:                    mainData.name                         || null,
      status:                  mainData.status                       || null,
      regNumber:               mainData.regNumber                    || null,
      registrationCertNumber:  mainData.registrationCertNumber       || null,
      rvsCategory:             mainData.rvsCategory                  || null,
      versionData:             mainData.versionData                  || null,
      inspectionDate:          inspDate,     
      technicalPointId:        mainData.technicalPointId             || null,
      techPointWorkingHourId:  mainData.techPointWorkingHourId       || null,
      contractRvsId:           mainData.contractRvsId                || null,
      phoneNumber:             mainData.phoneNumber                  || null,
      createdOrder:            mainData.createdOrder                 || null,
      incomingWay:             mainData.incomingWay                  || null,
      customerDiscount:        mainData.customerCardDiscount         || null,
      customerCard:            this.customerCard                     || null,
      promotionId:             mainData.promotionId                  || null,
      promoCode:               mainData.promoCode                    || null,
      isEmployee:              false,
      isBankPayment:           false,
      isDeferPayment:          false,
      techPointServices:       this.constructServices(this.selectedTechPointServices),
      // techInspectionOrders:    [],
      techInspectionOrders:    this.constructTechInspOrders(this.technicalInspection!),
      techInspectionTimes:     [],
      skipSubscribtion:        true,
      isAgreeWithNotification: true,
      wantsMarketingMessages:  true
    }

    return data;
  }

  private constructTechInspOrders(techInspection: TechnicalInspection) {
    if (techInspection === null || techInspection.techInspectionOrders?.length === 0) {
      return [];
    }
    return techInspection.techInspectionOrders;
  }

  private constructServices(data: any[]) {
    return data.filter(serviceData => serviceData.id !== null || serviceData.isValid).map(serviceData => {
      return {
        id:         serviceData.id                 || null,
        status:     serviceData.status             || null,
        isValid:    serviceData.isValid            || null,
        rvsService: serviceData.rvsService         || null,
        duration:   serviceData.duration           || null,
        validFrom:  serviceData.validFrom          || null,
        validTo:    serviceData.validTo            || null,
        serviceType:serviceData.serviceType        || null,
        serviceId:  serviceData.serviceId          || null,
        priceAttribute: serviceData.priceAttribute || null,
      }
    });
  }

  techInspCcFormValidation() {
    return this.techInspectionMainForm.invalid || this.isValidMainFormRegNum || this.isValidRegNumberForForeigner 
      || this.isValidRegNumberForDiplomatic || this.isValidRegNumberForElectric;
  }

  checkId(id: number) {
    if (this.technicalInspection == null || this.technicalInspection.techInspectionServices == null) {
      return false;
    }
    return this.technicalInspection && this.technicalInspection.techInspectionServices!.some(s => s.technicalPointService!.id === id);
  }

  compareById(optionOne: any, optionTwo: any) {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  getServicesMobileAppIdsForCard(serviceId: number) {
    this.techInspService.getServicesMobileAppIdsForCard(serviceId).subscribe(servicesMobileAppIdsForCard => {
      this.servicesMobileAppIdsForCard = servicesMobileAppIdsForCard;
      this.checkForServicesInCard();
    });
  }

  validateMainFormRegNumber(regNumber: string) {
    regNumber = regNumber.toUpperCase();
    const endWithNumbers = /\d+$/;

    this.isValidRegNumberForForeigner = false
    this.isValidRegNumberForDiplomatic = false
    this.isValidRegNumberForElectric = false
    this.isValidMainFormRegNum = false;

    let firstDiplomaticCheck = false;
    let secondDiplomaticCheck = false;
    let thirdDiplomaticSymbolsEndCheck = true;

    if (regNumber.length < 5) {
      regNumber.match(config.regexRegNumber) || regNumber === "" ? this.isValidMainFormRegNum = false :  this.isValidMainFormRegNum = true;
      return;
    } else {
      firstDiplomaticCheck = regNumber.startsWith('C') || regNumber.startsWith('CC') || regNumber.startsWith('С') || regNumber.startsWith('СС');
      secondDiplomaticCheck = regNumber.charAt(1).startsWith('C') || regNumber.charAt(1).startsWith('С');
      if (regNumber.charAt(regNumber.length - 1).match(endWithNumbers)) {
        thirdDiplomaticSymbolsEndCheck = false;
      }
    }

    // foreign
    if (regNumber.startsWith('XH') || regNumber.startsWith('ХН')) {
      regNumber.match(config.regexForeigner) || regNumber === "" ? this.isValidRegNumberForForeigner = false :  this.isValidRegNumberForForeigner = true;
      if (regNumber.length > 6) {
        this.isValidRegNumberForForeigner = true;
      }
    
    // diplomatic
    } else if (firstDiplomaticCheck && secondDiplomaticCheck && !thirdDiplomaticSymbolsEndCheck) {
      regNumber.match(config.regexDiplomatic) || regNumber === "" ? this.isValidRegNumberForDiplomatic = false :  this.isValidRegNumberForDiplomatic = true;
    
    // electric 
    } else if (regNumber.startsWith('EA') || regNumber.startsWith('ЕА')) {
      regNumber.match(config.regexElectric) || regNumber === "" ? this.isValidRegNumberForElectric = false :  this.isValidRegNumberForElectric = true;
      
      if (regNumber.length > 8) {
        this.isValidRegNumberForElectric = true;
      }

      let isCorrectEnding = false;
      if (regNumber.endsWith('A')) {
        isCorrectEnding = true;
      } else if (regNumber.endsWith('А')) {
        isCorrectEnding = true;
      }
      if (!isCorrectEnding) {
        this.isValidRegNumberForElectric = true;
      }
    // regular
    } else {
      regNumber.match(config.regexRegNumber) || regNumber === "" ? this.isValidMainFormRegNum = false :  this.isValidMainFormRegNum = true;
    }

    this.techInspectionMainForm.get('regNumber')?.patchValue(regNumber);
  }

  // validateMainFormRegCertNumber(regCertNumber: string): void {
  //   regCertNumber = regCertNumber.toUpperCase();
  //   regCertNumber.match(config.regexOnlyLatinSymbolsAndDigits) || regCertNumber === "" ? this.isValidMainFormRegCertNum = false :  this.isValidMainFormRegCertNum = true;
  //   this.techInspectionMainForm.get('registrationCertNumber')?.patchValue(regCertNumber);
  // }

  normalizeRegNumber(formType: string, regNumber: string) {
    if (regNumber.length > 0) {
      this.techInspService.getNormalizedRegNumber(regNumber).subscribe(normalizedNumber => {
        this.techInspectionMainForm.get('regNumber')?.patchValue(normalizedNumber.regNum);
      });
  
      this.checkForContract(regNumber);
      this.getCustomerSubscriptionByRegNumber(regNumber);
      if (regNumber != "") {
        this.getRegCertNumberIfExists(regNumber);
      }
    }
  }

  getRegCertNumberIfExists(regNumber: string) {
    this.techInspService.getRegCertNumberIfExists(regNumber).subscribe(resp => {
      this.techInspectionMainForm.get('registrationCertNumber')?.patchValue(resp.regCertNumber);
    });
  }

  getCustomerSubscriptionByRegNumber(regNumber: string) {
    this.paymentServiceTableData = [];
    this.paymentServiceConvertedData = [];
    this.techInspService.getCustomerSubscriptionByRegNumber(regNumber).subscribe(customerSubscription => {
      if (customerSubscription != null || customerSubscription != undefined) {
        if (customerSubscription.status.code == this.CODE_PAID) {
          this.disableAnnualService = true;
          this.annualService = null;
        }

        this.customerSubscriptionName = customerSubscription.name;
        this.customerSubscription = customerSubscription;
        if (this.technicalInspection != null) {
          this.technicalInspection.techInspectionServices?.forEach(techInspService => {
            if (this.checkIfServiceIsInTheCard(techInspService.technicalPointService!.serviceMobileAppId!) ) {
              this.paymentServiceConvertedData.push(createPaymentServiceData(techInspService.technicalPointService!.rvsService.service!.name!, techInspService.technicalPointService!.rvsService.service!.serviceType.description!, 0, null));
            } else {
              this.paymentServiceConvertedData.push(createPaymentServiceData(techInspService.technicalPointService!.rvsService.service!.name!, techInspService.technicalPointService!.rvsService.service!.serviceType.description!, techInspService.technicalPointService!.priceAttribute?.amountDds!, null));
            }
          });
        }
      } else {
        if (this.technicalInspection != null) {
          this.technicalInspection.techInspectionServices?.forEach(techInspService => {
            this.paymentServiceConvertedData.push(createPaymentServiceData(techInspService.technicalPointService!.rvsService.service!.name!, techInspService.technicalPointService!.rvsService.service!.serviceType.description!, techInspService.technicalPointService!.priceAttribute?.amountDds!, null));
          });
        }
      }
    });
  }

  async showMultipleContractWarningModal() { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.multipleCustomerDiscounts"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        // Do nothing
      }
    });
  }

  async showNoAvailableSlotsModal() { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.noAvailableSlots"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        this.prepareNewTechnicialInpection();
      }
    });
  }

  loadOrderOptions() {
    return [
      { "name": "date",  "description": "Дата" },
      { "name": "price", "description": "Цена" },
      // { "name": "offer", "description": "Оферта" }
    ];
  }

  prepareNewTechnicialInpection() {
    this.techInspectionMainForm.reset();
    this.showAllFields = false;
    this.isVisiblePaymentServicesTable = false;
    this.disableAnnualService = null;
    this.submittedMainForm = false;
    this.selectedTechPointServices = [];
    this.selectedAdditionalServices = [];
    this.paymentServiceTableData = [];
    this.paymentServiceConvertedData = [];
    this.technicalInspection = new TechnicalInspection;
    this.techInspectionMainForm.get('isAgreeWithNotification')?.patchValue(null);
    this.annualService = null;
    this.customerSubscription = null;
    this.hasPromocode = false;
    this.orderPaid = false;
    this.disableSaveButton = false;
    this.isPromoCode = false;
    this.isPromoCodeDataArrivedHtml = true;
    this.isCardPromotion = false;
    this.isCodePromotion = false;
    this.isPromotionDataArrived = true;
    this.loadDefaultSearchAvailableTimeSlotFilters();
  }

  reloadNomenclatures(technicalPointId: number) {
    this.techInspService.getRvsCategoryServicesByType(this.techInspectionMainForm.get('rvsCategory')?.value, technicalPointId).pipe(
      tap(([basicServices, additionalServices, annualServices]) => {
        this.basicServices = basicServices;
        this.basicServicesCopy = JSON.parse(JSON.stringify(basicServices));
        this.additionalServices = additionalServices;
        this.annualServices = annualServices;
        this.techInspectionMainForm.get('basicTechPointService')?.patchValue(this.basicServices.find(service => service.serviceName === this.MAIN_SERVICE_GTP_NAME) || null);
        this.annualService = null;
        this.selectedAdditionalServices = [];

        if (this.technicalInspection != null)
        this.additionalServices.forEach(additionalTechPointService => {
          this.technicalInspection.techInspectionServices?.forEach(techInspService => {
            if (additionalTechPointService.id === techInspService.technicalPointService!.id) {
              this.selectedAdditionalServices.push(additionalTechPointService);
            }
          });
        });
    })).subscribe();
  }

  async loadDefaultSearchAvailableTimeSlotFilters() {
    let defCity = this.cities.find(city => city.code === this.CITY_CODE_SOFIA);

    this.techInspService.getMainServicesByCityCode(defCity?.code!).subscribe(async mainServices => {
      this.techInspectionMainForm.get('searchDate')?.patchValue(prepareDateForForm(new Date()));
      this.mainServices = mainServices.services;
      this.techInspectionMainForm.get('city')?.patchValue(defCity);
      this.onCityChange(defCity);

      this.techInspectionMainForm.get('orderOption')?.patchValue(this.orderOptions.find(option => option.name === this.ORDER_OPTION_DATE));
      this.techInspectionMainForm.get('searchTechPoint')?.patchValue("");
      this.selectedTechPointServices = [];
      
      await new Promise(f => setTimeout(f, 400));
      this.loadTechPointAvailableSlots();
    });
  }

  private cityCounter = 0;
  async onCityChange(city: any) {
    this.techInspService.getMainServicesByCityCode(city?.code!).subscribe(mainServices => {
      this.mainServices = mainServices.services;
      const gtp = this.mainServices.splice(this.mainServices.findIndex((item: { name: string; }) => item.name === this.MAIN_SERVICE_GTP_NAME), 1)[0];
      this.mainServices.splice(0, 0, gtp);

      if (this.mainServices.length > 0) {
        this.techInspectionMainForm.get('mainService')?.patchValue(this.mainServices[0]);
        this.onMainServiceChange(this.mainServices[0], city);
      }
    });
    this.selectedTechPointServices = [];
    await new Promise(f => setTimeout(f, 400));
    
    // This counter is because this function is invoked in the beginning
    if (!this.cityCounter) {
      this.clearPromotionCodeRelated(true);
    } else {
      this.isReloaded = true;
      if (this.isPromoCode) this.clearPromotionCodeRelated(false);
    }
    
    this.isCityChange = true;
    this.loadTechPointAvailableSlots();
    this.cityCounter++;
  }

  private mainServiceChangeCounter = 0;
  async onMainServiceChange(mainService: any, city: any) {
    this.isCityChange = false;
    this.techInspService.getRvsCategoriesByServiceMobileApp(mainService?.id, city?.code!).subscribe(rvsCategories => {
      this.rvsCategories = rvsCategories;
      if (this.rvsCategories.length > 0) {
        this.techInspectionMainForm.get('rvsCategory')?.patchValue(this.rvsCategories[0]);
        this.onRvsCategoryChange(this.rvsCategories[0], mainService, city);
      }
    });

    this.selectedTechPointServices = [];
    await new Promise(f => setTimeout(f, 400));
    
    // This counter is because this function is invoked in the beginning
    if (!this.mainServiceChangeCounter) {
      this.clearPromotionCodeRelated(true);
    } else {
      this.isReloaded = true;
      if (this.isPromoCode) this.clearPromotionCodeRelated(false);
    }

    this.loadTechPointAvailableSlots();
    this.mainServiceChangeCounter++;
  }

  private rvsCategoryCounter = 0;
  async onRvsCategoryChange(rvsCategory: any, mainService: any, city: any) {
    this.isCityChange = false;
    this.techInspService.getTechnicalPointsByRvsCategory(rvsCategory?.id, mainService?.id, city?.code!).subscribe(technicalPoints => {
      this.technicalPoints = technicalPoints.filter((tp: { status: { code: string }; }) => tp.status?.code !== this.INACTIVE);
    });

    this.selectedTechPointServices = [];
    await new Promise(f => setTimeout(f, 400));

    if (!this.rvsCategoryCounter) {
      this.clearPromotionCodeRelated(true);
    } else {
      this.isReloaded = true;
      if (this.isPromoCode) this.clearPromotionCodeRelated(false);
    }
    
    this.loadTechPointAvailableSlots();
    this.rvsCategoryCounter++;
  }

  onAvailableTimesDataChange() {
    this.isCityChange = false;
    this.loadTechPointAvailableSlots();
    this.showAllFields = false;
    this.isReloaded = true;
    this.selectedTechPointServices = [];
    
    if (this.isPromoCode) {
      this.clearPromotionCodeRelated(false);
    }
    this.isVisiblePaymentServicesTable = false;
    this.selectedTechPointServices = [];
  }

  async loadTechPointAvailableSlots() {
    let mainFormData = this.techInspectionMainForm.value;
    if (this.isCityChange) this.techInspectionMainForm.get('searchTechPoint')?.setValue('');
    const data = this.constructSearchAvailableTimeSlot(mainFormData);
    if (this.isCityChange) data.techPointMobileAppId = null; 

    await this.techInspService.getTechPointsAvailableTimeSlots(data).toPromise().then(availableTimesTechPoints => {
      this.availableTimesTechPoints = availableTimesTechPoints;

      // remove this first slots for each point
      if (this.availableTimesTechPoints?.length > 0) {
        this.availableTimesTechPoints.forEach(avtp => {
          if (avtp?.slots.length > 0) {
            avtp.slots.shift();
          }
        });
      }
      
      this.showAllFields = false;

      if (this.showAllFieldsGeneral) {
        this.showAllFields = true;
      }
      this.isVisiblePaymentServicesTable = false;
    });
  }

  chooseTechPoint(techPointMobileAppId: number, techPointWorkingHourId: number, techPointName: String, slot: String) {
    this.techPointService.getTechPointIdByTechPointMobileAppId(techPointMobileAppId).subscribe(techPointId => {
      this.loadDefaultData(techPointId, techPointWorkingHourId, techPointName, slot);
    });
  }

  chooseTechPointWorkignHour(techPointWorkingHourId: number, techPointId: number, techPointName: String, slot: String) {
    this.loadDefaultData(techPointId, techPointWorkingHourId, techPointName, slot);
    this.isReloaded = true;
    this.clearPromotionCodeRelated(true);
  }

  private loadDefaultData(techPointId: number, techPointWorkingHourId: number, techPointName: String, slot: String) {
    this.techInspectionMainForm.get('inspectionDate')?.patchValue(this.techInspectionMainForm.get('searchDate')?.value);
    this.dateOfInsp = this.prepareDateForUi(this.techInspectionMainForm.get('searchDate')?.value);
    this.techPointName = techPointName;
    this.slotDescr = slot;
    this.cleanPaymentServiceTable();
    
    this.selectedTechPointId = techPointId;
    this.techInspectionMainForm.get('techPointWorkingHourId')?.patchValue(techPointWorkingHourId);
    this.techInspectionMainForm.get('techPointWorkingHourId')?.markAsTouched();
    this.showAllFields = true;
    
    this.reloadNomenclatures(this.selectedTechPointId);
    this.selectedAdditionalServices = [];
    this.onChangeForm();
  }

  private constructSearchAvailableTimeSlot(mainFormData: any) {
    return {
      date:         prepareDateForDb(mainFormData.searchDate),
      hasAssistant: false,
      slots:        [],
      cityCode:     mainFormData.city.code,
      categoryId:   mainFormData.rvsCategory.id,
      orderOption:  mainFormData.orderOption.name,
      techPointMobileAppId: mainFormData.searchTechPoint?.mobileAppId,
      services:     [this.createIdOnlyDto(mainFormData.mainService.id)],
    }
  }

  private prepareDateForUi(date: any) {
    let dateYear = date.year.toString();
    let dateMonth = date.month.toString();
    let dateDay = date.day.toString();
    
    if (dateMonth.length === 1) {
      dateMonth = '0' + dateMonth;
    }
    if (dateDay.length === 1) {
      dateDay = '0' + dateDay;
    }

    return dateDay + '-' + dateMonth + '-' + dateYear;
  }

  private createIdOnlyDto(serviceId: number) {
    return {
      id: serviceId
    };
  }

  changeTechInspStatus(id: number, statusCode: string): void {
    this.techInspService.changeTechInspStatus(id, statusCode).subscribe({
      next: (data) => {
        this.uiEvent.displayUISuccess();
        this.router.navigate(['/list-technical-inspections']);
      },
      error: err => {
        displayError(err);
      }  
    });
  }

  async showBusySlotWarningModal() { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.busySlot"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
       this.loadTechPointAvailableSlots();
      }
    });
  }

  private calculateDiscount(card: boolean, code: boolean) {
    let selectedCustomerDiscount: any; 
    if (card) {
      selectedCustomerDiscount = this.techInspectionMainForm.get('customerCardDiscount')?.value;       
    } 
    if (code) {
      selectedCustomerDiscount = this.techInspectionMainForm.get('customerPromoDiscount')?.value;       
    }
    
    if (!(this.isPromoCode || this.customerCard !== null) || !selectedCustomerDiscount) {
      this.calculatePaidAmountServices();
      return;
    }

    if (this.isCardPromotion) {
      this.paymentServiceTableData.forEach(s => {
        if (s.amountDds !== undefined && s.calculatedDiscount !== undefined) {
          s.amountDds += s.calculatedDiscount;
        }
        s.calculatedDiscount = 0;
      });

      if (selectedCustomerDiscount.priceAttribute.kind === this.DISCOUNT_PERCENTAGE) {
        this.paymentServiceTableData.forEach(service => {
          let currentCalculateDiscount = service.calculatedDiscount;
          service.calculatedDiscount = Math.round(((service.amountDds! * selectedCustomerDiscount.priceAttribute.amount / 100)) * 100) / 100;
          service.amountDds! -= service.calculatedDiscount;
          service.calculatedDiscount += currentCalculateDiscount!;
        });
      }
      if (selectedCustomerDiscount.priceAttribute.kind === this.DISCOUNT_VALUE) {
        let totalAmountFromServices = 0;
        this.paymentServiceTableData.forEach(service => {
            totalAmountFromServices+=service.amountDds!;
        });
        this.paymentServiceTableData.forEach(service => {
          let calculatedPercantageFromTotal = Math.round((service.amountDds! / totalAmountFromServices * 100) * 100) / 100;
          let calcValue  = Math.round((selectedCustomerDiscount.priceAttribute.amountDds * calculatedPercantageFromTotal / 100) * 100) / 100;
          service.calculatedDiscount! += calcValue;
          service.amountDds! -= calcValue;
        });
      }
    }

    if (this.isCodePromotion) {
      if (!this.isNewCodePromotion) {
        if (this.technicalInspection && this.technicalInspection.id) {
          const x = this.paymentServiceTableData.map(p => p.serviceMobileAppId).sort();
          const y = this.technicalInspection?.techInspectionOrders![0]?.order?.orderElements.map(p => p.service.mobileAppId).sort() as number[];
          const result = this.arraysEqual(x, y)
          if (result && this.promotionId) {
            return;
          } else {
            this.annulPaymentServiceTable();
          }
        }

        if (this.counterForReservationPromotionEvents > 1) {
          this.paymentServiceTableData.forEach(s => {
            if (s.amountDds !== undefined && s.calculatedDiscount !== undefined) {
              s.amountDds += s.calculatedDiscount;
            }
            s.calculatedDiscount = 0;
          });
        }
        this.counterForReservationPromotionEvents++;
      }

      this.paymentServiceTableData.forEach(service => {
        if (service.serviceMobileAppId) {
          const smai = service.serviceMobileAppId as number;
          let isHaveIt = false;
          this.inRangePromocodeServices.forEach(x => {
            if (x === smai) isHaveIt = true;
          });
          if (isHaveIt) {
            if (this.promotionCodeService?.isDiscountInPercentage) {
              let currentCalculateDiscount = service.calculatedDiscount;
              service.calculatedDiscount = Math.round(((service.amountDds! * selectedCustomerDiscount / 100)) * 100) / 100;
              service.amountDds! -= service.calculatedDiscount;
              service.calculatedDiscount += currentCalculateDiscount!;
            } else {
              let value1 = this.techInspectionMainForm.get('customerPromoDiscount')?.value as number;
              let value2 = Array.from(this.inRangePromocodeServices).length as number;
              let calcValue =  value1 / value2;
              service.calculatedDiscount! += calcValue;
              service.amountDds! -= calcValue;
            }
          }
        }
      });
    }

    this.paymentServiceTableData.forEach(s => {
      if (s.serviceName !== this.MAIN_SERVICE_GTP_NAME) {
        const basicServiceIndex = this.paymentServiceTableData.findIndex(entity => entity.serviceName === this.MAIN_SERVICE_GTP_NAME);
    
        if (basicServiceIndex !== -1 && this.paymentServiceTableData[basicServiceIndex]) {
          if (selectedCustomerDiscount?.priceAttribute?.kind === this.DISCOUNT_VALUE) {
            const basicServiceEntity = this.paymentServiceTableData[basicServiceIndex];
            basicServiceEntity.calculatedDiscount = basicServiceEntity.calculatedDiscount || 0;
            basicServiceEntity.calculatedDiscount += s.calculatedDiscount || 0;
            if (basicServiceEntity.amountDds != undefined) {
              basicServiceEntity.amountDds -= s.calculatedDiscount || 0; 
            }
          }
          // if (s.amountDds != undefined) {
          //   s.amountDds += s.calculatedDiscount || 0;
          // } 
        }
      }
    });

    if (selectedCustomerDiscount?.priceAttribute?.kind === this.DISCOUNT_VALUE) {
      const basicServiceIndex = this.paymentServiceTableData.findIndex(entity => entity.serviceName === this.MAIN_SERVICE_GTP_NAME);
      if (basicServiceIndex !== -1) {
        const basicServiceEntity = this.paymentServiceTableData[basicServiceIndex];
        const discountDiff = selectedCustomerDiscount.priceAttributeAmountDds - (basicServiceEntity.calculatedDiscount || 0);
        
        if (discountDiff !== 0) {
          basicServiceEntity.amountDds = (basicServiceEntity.amountDds || 0) + discountDiff;
          basicServiceEntity.calculatedDiscount = selectedCustomerDiscount.priceAttributeAmountDds;
        }
    
        const originalBasicIndex = this.basicServicesCopy.findIndex(entity => entity.serviceName === basicServiceEntity.serviceName);
        if (originalBasicIndex !== -1) {
          const originalBasicEntity = this.basicServicesCopy[originalBasicIndex];
          
          if (originalBasicEntity.amountDds !== basicServiceEntity.amountDds) {
            basicServiceEntity.amountDds = (originalBasicEntity.amountDds || 0) - (basicServiceEntity.calculatedDiscount || 0);
          }
        }
      }
    }

    this.calculatePaidAmountServices();

    this.paymentServiceConvertedData = [];
    this.paymentServiceTableData.forEach(service => {
      const index = this.paymentServiceConvertedData.findIndex(object => object.serviceName === service.serviceName);
      if (index === -1) {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, service.amountDds!, service.calculatedDiscount!));
      }
    });
  }

  private calculatePaidAmountServices() {
    this.currentAmountServices = 0;
    this.paymentServiceTableData.forEach(p => {
      if (p.amountDds !== undefined) {
        this.currentAmountServices += p.amountDds;
      }
    });
    this.currentAmountServices = parseFloat(this.currentAmountServices.toFixed(2));
    if (this.orderAmountPaid !== this.currentAmountServices) {
      this.orderPriceMismatch = true;
    } else {
      this.orderPriceMismatch = false;
    }
  }

  private arraysEqual(a1: any[], a2: any[]) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1)==JSON.stringify(a2);
  }

  async onChangePromoCode() {
    this.isVisiblePaymentServicesTable = false;
    this.isPromoCodeDataArrivedHtml = false;
    this.isPromotionDataArrived = false; 
    this.isPromoCode = true;

    this.customerCard = null;
    this.techInspectionMainForm.get('customerCard')?.setValue(null);
    this.techInspectionMainForm.get('customerCardDiscount')?.setValue(null);
    this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('');

    this.paymentServiceConvertedData = [];
    this.paymentServiceTableData = [];

    if (!this.techInspectionMainForm.get('promoCode')?.value) {
      this.annulPromotionCodeRelated();
      return;
    }

    this.constructTechInspPromoCode().then(data => {
      if (!data) {
        this.annulPromotionCodeRelated();
        return;
      }
      this.promotionService.findDiscountsByTechnicalInspection(data).subscribe(async data => {
        this.isPromoCodeDataArrivedHtml = true;
        this.isPromotionDataArrived = true;
        
        this.promotionCodeService = data as PromotionCodeService;
        this.isCardPromotion = false;
        if (!data.totalDiscountForServices) {
          this.annulPromotionCodeRelated();
          this.techInspectionMainForm.get('customerPromoDiscount')?.setValue(0);
          return;
        }
        this.isPromoCode = true;
        this.hasPromocode = true;
        
        if (this.promotionCodeService.isDiscountInPercentage === undefined || this.promotionCodeService.isDiscountInPercentage === null) {
          this.isPercentageDiscount = undefined;
        } else {
          this.isPercentageDiscount = false;
          if (this.promotionCodeService.isDiscountInPercentage === true) {
            this.isPercentageDiscount = true;
          }
        }

        this.techInspectionMainForm.get('customerPromoDiscount')?.setValue(this.promotionCodeService.totalDiscountForServices);
        this.promotionCodeName = this.techInspectionMainForm.get('promoCode')?.value;
        this.isNewCodePromotion = true;
        this.isCodePromotion = true;

        await new Promise(f => setTimeout(f, 500));
        if (data.serviceStatus) {
          this.inRangePromocodeServices = new Set<number>();
          this.inRangePromocodeServices = data.serviceStatus;
        }
      }, error => {
        this.hasPromocode = false;
        this.isReloaded = true;
        this.isPercentageDiscount = undefined;
        this.clearPromotionCodeRelated();
        this.isPromoCodeDataArrivedHtml = true;
        console.error('Error occurred when trying to find discounts per service by conditions. Message: ' + error.message);
      });
    });
  }

  private annulPromotionCodeRelated() {
    this.hasPromocode = false;
    this.isPromoCode = false;
    this.isReloaded = true;
    this.isPromotionDataArrived = true;
    this.isPromoCodeDataArrivedHtml = true;
    this.techInspectionMainForm.get('customerPromoDiscount')?.setValue('');
    this.isPercentageDiscount = undefined;
    this.promotionCodeName = null;
    this.promotionCodeService = null;
  }

  private clearPromotionCodeRelated(toClearPromotionCode?: boolean) {
    if (!this.isReloaded) return;
    if (toClearPromotionCode) this.techInspectionMainForm.get('promoCode')?.setValue('');
    this.techInspectionMainForm.get('customerPromoDiscount')?.setValue('');
    this.isPromotionDataArrived = true;
    this.isPromoCode = false;
    this.annulPaymentServiceTable();
  }

  private annulPaymentServiceTable() {
    const list: any[] = [];
    this.paymentServiceTableData.forEach(x => {
      x.amountDds! += x.calculatedDiscount!;
      x.calculatedDiscount = 0;
      list.push(x);
    })
    this.paymentServiceTableData = list;
  }

  private async constructTechInspPromoCode(): Promise<TechInspectionPromoCode | null> {
    if (!this.techInspectionMainForm.get('promoCode')?.value) return null;
    this.currSlots = [];
    const tempTechInspection = this.constructTechInspection();
    await this.getCurrentSlot();
    await new Promise(f => setTimeout(f, 500));

    let isEditable = false;
    if (this.technicalInspection && this.technicalInspection.id) {
      isEditable = true;
    }

    const services = this.getServices();
    if (!services.length) return null;

    const tipc: TechInspectionPromoCode = {
      promoCodeName:                    this.techInspectionMainForm.get('promoCode')?.value,
      dayUsed:                          new Date(),
      promoTechnicalPointId:            tempTechInspection.technicalPointId,
      isEditable:                       isEditable,
      services:                         services,
      rvsCategory:                      tempTechInspection.rvsCategory,
      slot:                             this.currSlots,
    };

    return tipc;
  }

  private cleanUnexpectedServices() {
    let uniqueService = new Map<string, any>();
    let isHaveMainService = false;
    this.selectedTechPointServices.forEach(s => {
      if (s.serviceType === this.ADDITIONAL_SERVICE_TYPE) {
        uniqueService.set(`${this.ADDITIONAL_SERVICE_TYPE}` + s.serviceId, s);
      }
      if (s.serviceType === this.MAIN_SERVICE_TYPE) {
        uniqueService.set(this.MAIN_SERVICE_TYPE, s);
        isHaveMainService = true;
      } 
    });

    if (!isHaveMainService) return [];
    return uniqueService;
  }

  private getServices() {
    const uniqueService = this.cleanUnexpectedServices() as any;
    let promoServices: any[] = []; 
    uniqueService.forEach((value: any, key: string) => promoServices.push(value.serviceMobileAppId));
    return promoServices;
  }

  private async getCurrentSlot() {
    await this.techInspService.getTechPointsAvailableTimeSlots(this.constructSearchAvailableTimeSlot(this.techInspectionMainForm.value)).toPromise().then(availableTimesTechPoints => {
      this.availableTimesTechPoints = availableTimesTechPoints;
      
      availableTimesTechPoints?.forEach(availableTime => {
        availableTime.slots.forEach(slot => {
          if (slot.id === this.techInspectionMainForm.get('techPointWorkingHourId')?.value) {
            this.currSlots.push(slot);
          }
        })
      })
    });
  }

  private checkCardForOnlyGtp() {
    const mainService = this.techInspectionMainForm.get('basicTechPointService')?.value;
    if (mainService && mainService.serviceName !== this.MAIN_SERVICE_GTP_NAME) {
      this.customerCard = null;
      this.techInspectionMainForm.get('customerCardDiscount')?.setValue('');
      this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('');
      return false;
    }
    return true;
  }

  async onChangeLoyaltyCard() {
    this.displayExceedCardMessage = false;
    this.displayCardDoesNotExistMessage = false;

    if (!this.checkCardForOnlyGtp()) {
      return;
    }

    this.isVisiblePaymentServicesTable = false;
    this.annulPromotionCodeRelated();
    this.techInspectionMainForm.get('promoCode')?.setValue('');
    this.isPromotionDataArrived = false; 

    this.paymentServiceTableData = [];
    this.paymentServiceConvertedData = [];

    const cardField = this.techInspectionMainForm.get('customerCard')?.value;
    if (!cardField) {
      this.techInspectionMainForm.get('customerCardDiscount')?.setValue('');
      this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('');
      this.isPromotionDataArrived = true;
      return;
    }
    await this.cardService.getLoyalComboByCard(cardField).toPromise().then(data => {
      if (data.messageOperation === 'Card usage exceeds maximum limit.') {
        this.displayCardDoesNotExistMessage = false;
        this.displayExceedCardMessage = true;
      }
      if (data.messageOperation === 'Card does not exist.') {
        this.displayExceedCardMessage = false;
        this.displayCardDoesNotExistMessage = true;
      }

      if (this.displayCardDoesNotExistMessage || this.displayExceedCardMessage) {
        this.customerCard = null;
        this.isCardPromotion = false;
        this.isPromotionDataArrived = true; 
        this.techInspectionMainForm.get('customerCardDiscount')?.setValue(null);
        this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('');
        return;
      }

      this.displayExceedCardMessage = false;
      this.displayCardDoesNotExistMessage = false;

      const loyalCombo = data as LoyalCombo;
      if (!loyalCombo.customerDiscount) {
        this.customerCard = null;
        this.isCardPromotion = false;
        this.isPromotionDataArrived = true; 
        this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('Няма валидна отстъпка по тази карта');
        return;
      }

      this.customerCard = loyalCombo.customerCard;
      this.lastSelectedDiscountByCard = loyalCombo.customerDiscount.discountName;
      if (this.lastSelectedDiscountByCard) {
        this.techInspectionMainForm.get('customerCardDiscount')?.setValue(loyalCombo.customerDiscount);
        this.techInspectionMainForm.get('customerCardDiscountName')?.setValue(loyalCombo.customerDiscount.discountName);
      } else {
        this.techInspectionMainForm.get('customerCardDiscount')?.setValue('');
        this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('');
      }
      
      this.customerCard = loyalCombo.customerCard;
      this.isCodePromotion = false;
      this.isCardPromotion = true;
      this.isPromotionDataArrived = true; 
      this.calculateDiscount(true, false);
    })
    .catch(err => {
      err.customMessage = `Card doesn't exist.`; 
      displayError(err);
      
      this.techInspectionMainForm.get('customerCardDiscount')?.setValue('');
      this.techInspectionMainForm.get('customerCardDiscountName')?.setValue('');
      this.customerCard = null;
      this.lastSelectedDiscountByCard = this.INVALID_LOYAL_CARD;
      this.isPromotionDataArrived = true; 
    });
  }

  private filterDiscountsByType(discounts: CustomerDiscount[]) {
    return discounts.filter((discount) => discount.customerDiscountType?.some((cdt) => cdt.discountType.code === this.DISCOUNT_TYPE_TECHNICAL_POINT));
  }

  // Getters
  get mainForm() { 
    return this.techInspectionMainForm.controls;
  }

}