<div class="container justify-content-center">
    <h1 class="form-group title-position" translate>cardLoyal.cardSearch</h1>
</div>
<div class="container border my-2 py-2 px-4" *ngIf="loadCards$ | async as data; else loading">
    <!-- ****************************************************** 
     ************** Search Card Form *************
     ****************************************************** -->
    <form [formGroup]="searchForm">
        <div class="input-group mb-2">
            <!-- Search Card Number-->
            <div class="input-group-prepend ml-2"><span class="input-group-text" translate>cardLoyal.fromNumber</span></div>
            <input type="number" min="0" formControlName="fromNumber" class="form-control" id="fromNumber" name="fromNumber" />

            <!-- Search Card Number-->
            <div class="input-group-prepend ml-2"><span class="input-group-text" translate>cardLoyal.toNumber</span></div>
            <input type="number" min="0" formControlName="toNumber" class="form-control" id="toNumber" name="toNumber" />

            <!-- Search Program -->
            <div class="input-group-prepend ml-2"><span class="input-group-text" translate>cardLoyal.generate.type</span></div>
            <select class="custom-select input-custom-size" formControlName="programMobileAppId" id="programMobileAppId" name="programMobileAppId" 
                [ngClass]="{ 'is-invalid': !getForm['programMobileAppId'].valid && isSubmited }">
                <option selected value translate>placeholder.select</option>
                <option *ngFor="let pro of programs" [ngValue]="pro.mobileAppId">
                    {{ pro.programName }}
                </option>
            </select>

            <!-- Search and Clear Buttons -->
            <div class="input-group-append ml-2">
                <button class="btn btn-outline-success" type="button" (click)="onSubmit()" [disabled]="loadPdf" translate>button.search</button>
            </div>
            <div class="input-group-append ml-2">
                <button class="btn btn-outline-danger ml-2" type="button" (click)="clearFilters()" [disabled]="loadPdf" translate>button.clear</button>
            </div>
            <div class="input-group-append ml-2">
                <button [disabled]="loadPdf" style="border: 0; background: transparent;">
                    <i class="fa fa-file-pdf-o fa-2x" data-toggle="tooltip" data-placement="bottom" style="margin-left: 5px; margin-top: 4px; cursor: pointer;"
                        title="{{'button.pdf' | translate}}" (click)="generateMultiCardBarcode()">
                    </i>
                </button>
            </div>
        </div>
    </form>
    <ng-container *ngIf="!loadMultiCardBarcode; else dataLoadingTemplate"></ng-container>
    <!-- ****************************************************** 
     ************** Table Data *************
     ****************************************************** -->
    <br>
    <div class="row">
        <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
            <thead>
                <tr>
                    <th style="width: 28%" sortBy="cardNumber" (sort)="onSubmitSearchFormSort($event)"  translate>cardLoyal.number</th>
                    <th style="width: 28%" sortBy="cardBarcode" (sort)="onSubmitSearchFormSort($event)" translate>cardLoyal.barcode</th>
                    <th style="width: 28%" sortBy="programName" (sort)="onSubmitSearchFormSort($event)" translate>type</th>
                    <th style="width: 28%" sortBy="programName" (sort)="onSubmitSearchFormSort($event)" translate>status</th>
                    <th style="width: 16%" translate>option</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let card of cards">
                    <td style="width: 28%">{{ card.cardNumber }}</td>
                    <td style="width: 28%">{{ card.cardBarcode }}</td>
                    <td style="width: 28%">{{ card.programName }}</td>
                    <td style="width: 28%">{{ card.status }}</td>
                    <td style="width: 16%">
                        <div class="d-flex justify-content-around">
                            <button class="btn btn-sm btn-success"  data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                                routerLink="/card-detail" [queryParams]="{id: card.id}" translate [disabled]="loadPdf">
                                <i class="fa fa-pencil fa-lg"></i>
                            </button>
                            <button [disabled]="loadPdf" style="border: 0; background: transparent;">
                                <i class="fa fa-file-pdf-o fa-2x" data-toggle="tooltip" data-placement="bottom" style="margin-left: 5px; cursor: pointer;"
                                    title="{{'button.pdf' | translate}}" (click)="generateSingleCardBarcode(card.id)">
                                </i>
                            </button>
                        </div>
                    </td>
                </tr>    
            </tbody>
        </table>
    </div>
    <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
     <!-- ****************************************************** 
      ************** Pagination *************
      ****************************************************** -->
    <div class="paging-wrapper d-flex justify-content-between p-2">
        <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" [disabled]="loadPdf" (ngModelChange)="loadCustomerCards()">
            <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
              {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
            </option>
        </select>
        <ngb-pagination class="pagination-control"
            [(page)]="sortingPaging.pageNumber"
            [pageSize]="sortingPaging.pageSize"
            [collectionSize]="sortingPaging.totalElements"
            [boundaryLinks]="true"
            [maxSize]="maxSize"
            [disabled]="loadPdf"
            (pageChange)="pageChanged($event)">
        </ngb-pagination>
        <span class="pagination-index border center">
            {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
        </span>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger text-center">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>