import { TechnicalPointWorkingHour } from '@app/tech-points/_models/technical-point-working-hours.model';
import { ConciseTechPoint } from '@app/tech-points/_models/concise-technical-point.model';
import { ListTechnicalPoint } from '@app/tech-points/_models/list-technical-point.model'
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { TechnicalPoint } from '@app/tech-points/_models/technical-point.model';
import { CustomerHttpParamsCodec } from '@helpers/custom-http-params-codec';
import { NomenclatureService } from '../../_services/nomenclature.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Line } from '@app/tech-points/_models/line.model';
import { defaultIfEmpty, map, shareReplay, timeout } from 'rxjs/operators';
import { EMPTY, Observable, of, forkJoin } from 'rxjs';
import { StatusCode } from '@app/_enums/status-code';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Page } from '@interfaces/page';
import { TimeConstraint } from '@models/time-constraint.model';
import { PromConciseTechPoint } from '../_models/prom-concise-technical-point.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { Service } from '@app/services/_models/service.model';

const technicalPointURL                   = environment.apiBaseURL + '/technical-points';
const filterUrl                           = technicalPointURL + '/filter';
const findByIdURL                         = technicalPointURL + '/{id}';
const findLinesByPointIdURL               = technicalPointURL + '/{id}/lines';
const findValidLinesByPointIdURL          = technicalPointURL + '/{id}/valid-lines';
const findServicesByPointIdURL            = technicalPointURL + '/{id}/services';
const findServicesByPointMobileIdURL      = technicalPointURL + '/services-by-tech-point-mobile-id';
const findTechnicalPointsURL              = technicalPointURL;
const findConciseTechPointListURL         = technicalPointURL + "/all-concise";
const findTechnicalPointsByIdsURL         = technicalPointURL + '/by-ids';
const findTechnicalPointsPageable         = technicalPointURL + '/pageable';
const countTechnicalPointsPageable        = technicalPointURL + '/pageable-count';
const filterTechPointsBySubjectURL        = technicalPointURL + '/filter-by-subject';
const findByRvsCategoryServiceIdsURL      = technicalPointURL + '/by-rvs-category-services/';
const findServicesByTechPointIdURL        = technicalPointURL + '/{id}/services-with-names';
const findServicesByCategoryAndPointIdURL = technicalPointURL + '/{id}/services-by-category-and-point';
const findContractLinesByPointIdURL       = technicalPointURL + '/{id}/contract-lines';
const findWorkingHoursByLineIdURL         = technicalPointURL + '/{id}/contract-working-hours'
const findTechPointWorkingHours           = technicalPointURL + '/{id}/technical-points-time-constraints'
const findTechPointIdByMobileAppIdUrl     = technicalPointURL + '/tech-point-by-mobile-app'
const findNotSelectedTechPointsURL        = technicalPointURL + '/contract/'
const isInvoiceNumberValid                = technicalPointURL + '/is-invoice-valid';
const findByServiceRvsCategories          = technicalPointURL + '/by-service-category-id';

@Injectable({
  providedIn: 'root'
})
export class TechnicalPointService {

  constructor(
    private nomenclatureService: NomenclatureService,
    private http: HttpClient
  ) { }

  public createTechnicalPoint(technicalPoint: TechnicalPoint) {
    return this.http.post(technicalPointURL, technicalPoint);
  }

  public getTechPointNomenclatures(type: string) {
    return forkJoin([
      this.nomenclatureService.getCities(),
      this.nomenclatureService.getStatusesByType(type)
    ]);
  }

  public getTechPointServicesNomenclature(code: string) {
    return forkJoin([
      this.nomenclatureService.getStatusesByType(code),
    ])
  }

  public getTechPointPageableNomenclacutes() {
    return forkJoin([
      this.nomenclatureService.getCities()
    ])
  }

  public pageable(sortingPaging: SortingPagingData, searchForm: any) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm),
      this.countAllByFilter(sortingPaging, searchForm)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<Page<ListTechnicalPoint>> {
    return this.http.get<Page<ListTechnicalPoint>>(findTechnicalPointsPageable, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  public findAllBySubject(id: number): Observable<ConciseTechPoint[]> {
    if (id == null) {
      return of([]);
    }

    let params = new HttpParams({encoder : new CustomerHttpParamsCodec()});
    params = params.set('subjectId', id.toString());

    return this.http.get<ConciseTechPoint[]>(filterTechPointsBySubjectURL, { params : params} );
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: any): Observable<number> {
    return this.http.get<number>(countTechnicalPointsPageable, { params : this.constructPageableHttpParams(sortingPaging, searchForm) });
  }

  public findById(id: number): Observable<TechnicalPoint> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<TechnicalPoint>(findByIdURL.replace('{id}', `${id}`)).pipe(timeout(4000));
  }

  public isInvoiceNumberValid(invoiceNumber: string, ownerId: number, id: number) {
    if (invoiceNumber == null || ownerId == null) return of(false);

    let params = new HttpParams({encoder: new CustomerHttpParamsCodec()})
                                  .set("subjectId", ownerId.toString())
                                  .set("invoiceNumber", invoiceNumber);

    if (id != null) {
      params = params.set("id", id.toString());
    }

    return this.http.get<boolean>(isInvoiceNumberValid, { params: params });
  }

  public findLinesByPointId(id: number): Observable<Line[]> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<Line[]>(findLinesByPointIdURL.replace('{id}', `${id}`)).pipe(timeout(4000));
  }

  public findValidLinesByPointId(id: number): Observable<Line[]> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<Line[]>(findValidLinesByPointIdURL.replace('{id}', `${id}`)).pipe(timeout(4000)).pipe(shareReplay());
  }

  public getTechPointLinesNomenclature(type: string) {
    return forkJoin([
      this.nomenclatureService.getStatusesByType(type),
      this.nomenclatureService.getTimeConstraints(),
      this.nomenclatureService.getRvsCategories()
    ]);
  }

  public findServicesByPointId(id: number | undefined): Observable<TechPointService[]> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<TechPointService[]>(findServicesByPointIdURL.replace('{id}', `${id}`)).pipe(timeout(4000));
  }

  public findServicesByPointMobileAppId(techPointMobileAppId: number): Observable<Service[]> {
    if (techPointMobileAppId == null) return this.returnEmptyObservable();

    let requestParams = new HttpParams().set("techPointMobileAppId", techPointMobileAppId.toString())
    return this.http.get<Service[]>(findServicesByPointMobileIdURL, {params : requestParams}).pipe(shareReplay());
  }

  public findTechnicalPoints(): Observable<TechnicalPoint[]>{
    return this.http.get<TechnicalPoint[]>(findTechnicalPointsURL).pipe(shareReplay());
  }

  public findConciseTechPointList(): Observable<ConciseTechPoint[]>{
    return this.http.get<ConciseTechPoint[]>(findConciseTechPointListURL).pipe(shareReplay());
  }

  public findTechnicalPointsByIds(techPointMobileIds: number[]): Observable<TechnicalPoint[]>{
    if (techPointMobileIds == null || techPointMobileIds.length == 0) {
      return this.returnEmptyObservable();
    }
    let requestParams = new HttpParams().set("techPointMobileIds", techPointMobileIds.toString())
    return this.http.get<TechnicalPoint[]>(findTechnicalPointsByIdsURL, {params : requestParams}).pipe(shareReplay());
  }

  public findByRvsCategoryServiceIds(rvsCategoryServices: Array<BigInteger>) : Observable<TechnicalPoint[]> {
    let requestParams = new HttpParams().set("rvsCategoryServices", rvsCategoryServices.toString())
    return this.http.get<TechnicalPoint[]>(findByRvsCategoryServiceIdsURL, {params : requestParams});
  }
  
  public findServicesByTechPointId(id: number): Observable<TechPointService[]> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<TechPointService[]>(findServicesByTechPointIdURL.replace('{id}', `${id}`))
  }

  public findServicesByCategoryAndPointId(id: number | undefined, categoryId: number): Observable<TechPointService[]> {
    if (id == null || categoryId == null) return this.returnEmptyObservable();
    let requestParams = new HttpParams().set("categoryId", categoryId.toString())

    return this.http.get<TechPointService[]>(findServicesByCategoryAndPointIdURL.replace('{id}', `${id}`), {params : requestParams})
  }

  public initializeLinesAndNomeclatures(id: number) {
    return Promise.all([
      this.findLinesByPointId(id).toPromise(),
      this.getTechPointLinesNomenclature(StatusCode.TechnicalPointLine).toPromise()
    ])
  }

  public initializeServicesAndNomenclatures(id: number) {
    return Promise.all([
      this.findServicesByPointId(id).toPromise(),
      this.getTechPointServicesNomenclature(StatusCode.TechnicalPointService).toPromise()
    ])
  }

  public findContractLinesByPointId(id: number): Observable<Line[]> {
    if (id == null) return this.returnEmptyObservable();
    return this.http.get<Line[]>(findContractLinesByPointIdURL.replace('{id}', `${id}`)).pipe(timeout(4000));
  }

  private returnEmptyObservable() {
    return EMPTY.pipe(
      defaultIfEmpty()
    );
  }

  private constructPageableHttpParams(sortingPaging: SortingPagingData, searchForm: any) {
    let params = new HttpParams().set('pageSize', sortingPaging.pageSize.toString())
                                 .set('page', sortingPaging.pageNumber.toString());

    if (sortingPaging.isSortingValid()) {
      params = params
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
     }

    if (searchForm.city?.code != null) {
      params = params.set('cityCode', searchForm.city?.code);
    }

    if (searchForm.subject != null) {
      if (searchForm.subject?.id != null) {
        params = params.set('subjectVersionId', searchForm.subject.id!);
      } else {
        params = params.set('subjectVersionName', searchForm.subject);
      }
    }

    if (searchForm.permitNumber != null) {
      params = params.set('permitNumber', searchForm.permitNumber);
    }

    return params;
  }

  public findWorkingHoursByLineId(id: number): Observable<TechnicalPointWorkingHour[]> {
    if (id == null) return this.returnEmptyObservable();

    return this.http.get<TechnicalPointWorkingHour[]>(findWorkingHoursByLineIdURL.replace('{id}', `${id}`)).pipe(timeout(4000));
  }

  public findNotSelectedTechPoints(selectedTechPointfirstVersionIds: number[]): Observable<TechnicalPoint[]>{
    let requestParams = new HttpParams().set("selectedTechPointfirstVersionIds", selectedTechPointfirstVersionIds.toString())
    return this.http.get<TechnicalPoint[]>(findNotSelectedTechPointsURL,{params : requestParams});
  }

  public filterTechnicalPoints(name: string) {
    let params = new HttpParams().set('name', name);

    return this.http.get<TechnicalPoint[]>(filterUrl, { params : params });
  }
  
  public getTechPointIdByTechPointMobileAppId(techPointMobileAppId: number): Observable<number> {
    let requestParams = new HttpParams();
    requestParams = requestParams.set("techPointMobileAppId", techPointMobileAppId.toString());
    return this.http.get<number>(findTechPointIdByMobileAppIdUrl, {params: requestParams}).pipe(shareReplay());
  }

  public findTechPointWorkingHours(id: number) {
    if (id == null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    };

    let url = findTechPointWorkingHours.replace('{id}', id.toString());
    return this.http.get<any>(url).pipe(
      map(result => {
        let map = new Map<number, TimeConstraint[]>();

        for (const key in result) {
          if (result.hasOwnProperty(key)) {
            let value = result[key];
            map.set(parseInt(key), value);
          }
        }

        return map;
      })
    );
  }

  public findTechPointsByServiceRvsCategories(serviceCategoryIds: number[]) {
    if (serviceCategoryIds == null || serviceCategoryIds.length == 0) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.set("serviceCategoryIds", serviceCategoryIds.toString());

    return this.http.get<PromConciseTechPoint[]>(findByServiceRvsCategories, { params: params })
  }

}
