import { AbstractControl, FormArray, FormControl, ValidatorFn } from "@angular/forms";

export class PromotionValidators {
  public static validateServiceRvsCategories(): ValidatorFn {
		return (control: AbstractControl): { [key: string]:any } | null => {
			let categoriesArray = control as FormArray;
			let isCategorySelected = categoriesArray.controls.some(c => c.get('isSelected')?.value == true);
			
			return isCategorySelected ? null : { noCategoriesSelected: true };
		}
	}

	public static validateWorkingHours(): ValidatorFn {
		return (control: AbstractControl): { [key: string]:any } | null => {
			let days = control as FormArray;
			let workingHoursSelected = days.controls.some(day => PromotionValidators.isAnyHourFromDaySelected(day));
			
			return workingHoursSelected ? null : { noWorkingHoursSelected: true };
		}
	}

	private static isAnyHourFromDaySelected(day: AbstractControl) {
		let timeSlots = day.get('timeSlots') as FormArray;
		return timeSlots.controls.some(slot => slot.get('isSelected')?.value == true);
	}
}
