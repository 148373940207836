<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 translate *ngIf="isClientContract()">contract.clientContracts</h3>
            <h3 translate *ngIf="!isClientContract()">contract.partnerContracts</h3>
        </span>
    </div>
    <div class="my-2 py-2" *ngIf="loadSearchForm$ | async as data; else loading">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- contract type -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>contract.contractType</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="contractKind">
                    <option value="" translate>placeholder.select</option>
                    <option *ngFor="let contractKind of contractKinds" [value]="contractKind.code">
                        {{contractKind.name}}
                    </option>
                </select>
                
                <!-- owner -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>contract.owner</span>
                </div>
                <input type="text" class="form-control" formControlName="ownerSubjectVersion"
                    [ngbTypeahead]="subjectVersionsSearch" [inputFormatter]="subjectVersionFormatter"
                    placeholder="Търсене на собственик" [resultTemplate]="rt" />
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                </ng-template>
                
                <!-- user -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>contract.user</span>
                </div>
                <input type="text" class="form-control" formControlName="contractSubjectVersion"
                    [ngbTypeahead]="subjectVersionsSearch" [inputFormatter]="subjectVersionFormatter"
                    placeholder="Търсене на ползвател" [resultTemplate]="rt" />
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
                </ng-template>

                <!-- contract number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>contract.contractNum</span>
                </div>
                <input type="text" class="form-control" formControlName="docNumber" maxlength="32">
            </div>
            <div class="input-group mb-2">
                <!-- discount -->
                <ng-container *ngIf="isClientContract()">
                    <div class="input-group-prepend">
                        <span class="input-group-text" translate>contract.discount</span>
                    </div>
                    <select class="custom-select input-custom-size" formControlName="customerDiscountId" style="max-width: 280pt;">
                        <option value="" translate>placeholder.select</option>
                        <option *ngFor="let discount of customerDiscounts" [ngValue]="discount.id">
                            {{discount.discountName}}
                        </option>
                    </select>
                </ng-container>

                <!-- status -->
                <div class="input-group-prepend" [ngClass]="{'ml-2': isClientContract()}">
                    <span class="input-group-text" translate>status</span>
                </div>
                <select class="custom-select" formControlName="statusId" [compareWith]="compareById" style="max-width: 280pt;">
                    <option value="" [ngValue]="null" translate>placeholder.select</option>
                    <option *ngFor="let status of statuses" [ngValue]="status.id"> 
                        {{status.name}}
                    </option>
                </select>
                
                <!-- actions -->
                <div class="input-group-append ml-2">
                    <button class="btn btn-outline-success" type="button" (click)="search()"
                        translate>button.search</button>
                    <button class="btn btn-outline-danger ml-2" type="button" (click)="clearSearch()"
                        translate>button.clear</button>
                    <button class="btn btn-outline-success ml-2" routerLink="/add-edit-client-contract"
                        [queryParams]="{module: module}" type="button" *ngIf="canAddEdit" translate>button.add</button>
                    <i class="fa fa-file-excel-o fa-2x" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" style="margin-left: 10px; margin-top: 2px" (click)="exportExcel()"></i>
                    <i style="margin-left: 5px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" class="fa fa-file-pdf-o fa-2x" (click)="exportPDF()"></i>
                </div>
            </div>
        </form>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <br>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class=" d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th scope="col" sortBy="contractKind" (sort)="onSort($event)" translate>contract.contractType</th>
                        <th scope="col" sortBy="docNumber" (sort)="onSort($event)" translate>contract.contractNum</th>
                        <th scope="col" sortBy="validFrom" (sort)="onSort($event)" translate>contract.validFrom</th>
                        <th scope="col" sortBy="validTo" (sort)="onSort($event)" translate>contract.validTo</th>
                        <th scope="col" sortBy="ownerSubjectVersion" (sort)="onSort($event)" translate>contract.ownerEik</th>
                        <th scope="col" sortBy="ownerSubjectVersion" (sort)="onSort($event)" translate>contract.ownerName</th>
                        <th scope="col" sortBy="contractSubjectVersion" (sort)="onSort($event)" translate>contract.userEik</th>
                        <th scope="col" sortBy="contractSubjectVersion" (sort)="onSort($event)" translate>contract.userName</th>
                        <th scope="col" sortBy="status" (sort)="onSort($event)" translate>status</th>
                        <th class="text-center" scope="col" translate>actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of content">
                        <td>{{fromatKind(item.contractKind)}}</td>
                        <td>{{item.docNumber}}</td>
                        <td>{{item.validFrom}}</td>
                        <td>{{item.validTo}}</td>
                        <td>{{item.ownerSubjectVersion?.identNum}}</td>
                        <td>{{item.ownerSubjectVersion?.fullName}}</td>
                        <td>{{item.contractSubjectVersion?.identNum}}</td>
                        <td>{{item.contractSubjectVersion?.fullName}}</td>
                        <td>{{item.status?.name}}</td>
                        <td class="text-center py-2">
                            <button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                                routerLink="/add-edit-client-contract" *ngIf="canView" [queryParams]="{module: module, id: item.id}">
                                <i class="fa fa-pencil fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize"
                (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}}
                </option>
            </select>
            <ngb-pagination class="pagination-control" [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize" [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true" [maxSize]="maxSize" (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <em class="fa fa-refresh" (click)="reload()"></em>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>