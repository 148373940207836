<div class="container justify-content-center">
    <h1 class="form-group title-position" translate>cardLoyal.search</h1>
</div>
<div class="search-program container  my-2 py-2 px-4" *ngIf="search$ | async as data; else loading">
    <!-- ****************************************************** 
     ************** Search Form *************
     ****************************************************** -->
    <div class="row">
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- Card Name -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="programName" translate>cardLoyal.name</span>
                </div>
                <input type="text" class="form-control" formControlName="programName" id="programName" name="programName"> 
                
                <!-- Status -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="statusId" translate>cardLoyal.status</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="statusId" id="statusId" name="statusId">
                    <option selected value translate>placeholder.select</option>
                    <option *ngFor="let sts of statuses" [ngValue]="sts.id">
                        {{ sts.name }}
                    </option>
                </select>
                
                <!-- Type -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" id="loyaltyProgramTypeId" translate>cardLoyal.type</span>
                </div>
                <select class="custom-select input-custom-size" formControlName="loyaltyProgramTypeId" id="loyaltyProgramTypeId" name="loyaltyProgramTypeId">
                    <option selected value translate>placeholder.select</option>
                    <option *ngFor="let type of programTypes" [ngValue]="type.id">
                        {{ type.name }}
                    </option>
                </select>
                
                <!-- Search && Clear Buttons  - Search Form -->
                <div class="input-group-append ml-2">
                    <button class="btn btn-outline-success" type="button" (click)="search()" translate>button.search</button>
                    <button class="btn btn-outline-danger ml-2" type="button" (click)="clearSearch()" translate>button.clear</button>
                    <i class="fa fa-file-excel-o fa-2x" data-toggle="tooltip" data-placement="bottom" (click)="exportExcel()" title="{{'button.excel' | translate}}" style="margin-left: 10px; margin-top: 4px; cursor: pointer;" ></i>
                    <i class="fa fa-file-pdf-o fa-2x"   data-toggle="tooltip" data-placement="bottom" (click)="exportPDF()" title="{{'button.pdf' | translate}}" style="margin-left: 5px; margin-top: 4px; cursor: pointer;"></i>
                </div>
            </div>
        </form>
    </div>
    <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
    <br>
    <!-- ****************************************************** 
     ************** Table Data *************
     ****************************************************** -->
    <div class="row d-flex justify-content-center">
        <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
            <thead>
                <tr>
                    <th scope="col" sortBy="programName" (sort)="onSort($event)" translate>cardLoyal.name</th>
                    <th scope="col" sortBy="discount" (sort)="onSort($event)" translate>cardLoyal.discount</th>
                    <th scope="col" sortBy="impulseDiscount" (sort)="onSort($event)" translate>cardLoyal.impulseDiscount</th>
                    <th scope="col" sortBy="type" (sort)="onSort($event)" translate>cardLoyal.type</th>
                    <th scope="col" sortBy="status" (sort)="onSort($event)" translate>cardLoyal.status</th>
                    <th scope="col" sortBy="validFrom" (sort)="onSort($event)" translate>cardLoyal.validFrom</th>
                    <th scope="col" sortBy="validTo" (sort)="onSort($event)" translate>cardLoyal.validTo</th>
                    <th scope="col" sortBy="subjectVersion" (sort)="onSort($event)" translate>cardLoyal.subject</th>
                    <th translate>details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of content">
                    <td>{{ item.programName }}</td>
                    <td>{{ item.customerDiscountName }}</td>
                    <td>{{ item.customerImpulseDiscountName }}</td>
                    <td>{{ item.loyaltyProgramTypeName }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.validFrom }}</td>
                    <td>{{ item.validTo }}</td>
                    <td>{{ item.subjectName }}</td>
                    <td class="text-center py-2">
                        <button class="btn btn-sm btn-success"  data-toggle="tooltip" data-placement="bottom" title="{{'button.edit' | translate}}"
                            routerLink="/add-program" [queryParams]="{id: item.id}">
                            <i class="fa fa-pencil fa-lg"></i>
                        </button>
                    </td>
                </tr>    
            </tbody>
        </table>
    </div>
    <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
    <!-- ****************************************************** 
     ************** Pagination *************
     ****************************************************** -->
     <div class="paging-wrapper d-flex justify-content-between p-2">
        <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize">
            <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
              {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
            </option>
        </select>
        <ngb-pagination class="pagination-control"
            [(page)]="sortingPaging.pageNumber"
            [pageSize]="sortingPaging.pageSize"
            [collectionSize]="sortingPaging.totalElements"
            [boundaryLinks]="true"
            [maxSize]="maxSize"
            (pageChange)="pageChanged($event)">
        </ngb-pagination>
        <span class="pagination-index border center">
            {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
        </span>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger text-center">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>