<div *ngIf="isInitialized; else remindersLoading">
<div class="card-body">
    <table class="table">
      <thead>
        <tr>
          <th translate>clientProfile.reminderTitle</th>
          <th translate>clientProfile.reminderPeriod</th>
          <th translate>clientProfile.reminderType</th>
        </tr>
      </thead>
      <tbody *ngFor="let customerReminder of customerReminders">
        <tr>
          <td>{{customerReminder.reminder.name}}</td>
          <td>1 седмица преди, 1 ден преди</td>
          <td>Вид</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #remindersLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else remindersSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #remindersSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

