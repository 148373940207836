<div *ngIf="isInitialized; else servicesLoadins">
    <form [formGroup]="servicesForm">
        <div formArrayName="subCards">
            <h5 translate>techPoint.servicesCards</h5>

            <table class="table w-100">
                <thead>
                    <tr>
                        <th></th>
                        <th translate>techPoint.vehicleType</th>
                        <th translate>techPoint.serviceName</th>
                        <th></th>
                        <th style="width: 120pt;" translate>techPoint.status</th>
                        <th translate>techPoint.serviceDuration</th>
                        <th translate>techPoint.servicePriceNoVat</th>
                        <th translate>techPoint.serviceVatGroup</th>
                        <th translate>techPoint.servicePriceVat</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container #cards *ngFor="let subCard of subCardsForms.controls; let cardI=index" [formGroupName]="cardI">
                        <tr *ngIf="!subCard.get('isRemoved')?.value">
                            <td>
                                <input type="checkbox" class="form-check-input" disabled="true" checked="true">
                            </td>
                            <td>
                                <span>{{subCard.get('rvsService')?.value?.rvsCategory?.code}}</span>
                            </td>
                            <td>
                                <span>{{subCard.get('rvsService')?.value?.service?.name}}</span>
                            </td>
                            <td>
                                <div *ngIf="subCard.get('isRefreshing')?.value == true">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="subCard.get('isRefreshing')?.value == false">
                                    <div *ngIf="subCard.get('servcieVersionData')?.value != null && subCard.get('servcieVersionData')?.value?.isActual == false" class="d-flex flex-row">
                                        <i class="fa fa-exclamation-triangle mr-2 text-warning" style="font-size: 25pt;" title="{{'techPoint.serviceOldVersion' | translate}}" aria-hidden="true"></i>
                                        <i class="fa fa-refresh ml-2" style="font-size: 25pt;" title="{{'refresh' | translate}}" (click)="refreshService(subCard)" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="w-100" *ngIf="subCard.get('isValid')?.value">
                                    <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="status" [attr.disabled]="true">
                                        <option *ngFor="let status of statuses" [ngValue]="status"> {{status.name}}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="subCard.get('isValid')?.value">
                                    <span>-</span>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="subCard.get('isValid')?.value">
                                    <span>{{subCard.get('priceAmount')?.value}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="subCard.get('isValid')?.value">
                                    <span>{{subCard.get('priceDdsValue')?.value}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="subCard.get('isValid')?.value">
                                    <span>{{subCard.get('priceAmountDds')?.value | number : '1.2-2'}}</span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <h5 translate>techPoint.servicesMain</h5>
        <div formArrayName="services" class="table-responsive">
            <h5 class="text-danger" *ngIf="notAllCategoriesHaveServices && showValidations" translate>techPoint.notAllCategoriesHaveServicesValidation</h5>
            <h5 class="text-danger" *ngIf="noGtpError && showValidations" translate>techPoint.noGTPSelected</h5>
            <table class="table w-100">
                <thead>
                    <tr>
                        <th></th>
                        <th translate>techPoint.vehicleType</th>
                        <th translate>techPoint.serviceName</th>
                        <th></th>
                        <th style="width: 120pt;" translate>techPoint.status</th>
                        <th translate>techPoint.serviceDuration</th>
                        <th translate>techPoint.servicePriceNoVat</th>
                        <th translate>techPoint.serviceVatGroup</th>
                        <th translate>techPoint.servicePriceVat</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container #services *ngFor="let service of servicesForms.controls; let i=index" [formGroupName]="i">
                        <tr *ngIf="!service.get('isRemoved')?.value">
                            <td>
                                <input type="checkbox" class="form-check-input" formControlName="isValid" (change)="onChange()">
                            </td>
                            <td>
                                <span>{{service.get('rvsService')?.value?.rvsCategory?.code}}</span>
                            </td>
                            <td>
                                <span>{{service.get('rvsService')?.value?.service?.name}}</span>
                            </td>
                            <td>
                                <div *ngIf="service.get('isRefreshing')?.value == true">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="service.get('isRefreshing')?.value == false">
                                    <div *ngIf="service.get('servcieVersionData')?.value != null && service.get('servcieVersionData')?.value?.isActual == false" class="d-flex flex-row">
                                        <i class="fa fa-exclamation-triangle mr-2 text-warning" style="font-size: 25pt;" title="{{'techPoint.serviceOldVersion' | translate}}" aria-hidden="true"></i>
                                        <i class="fa fa-refresh ml-2" style="font-size: 25pt;" title="{{'refresh' | translate}}" (click)="refreshService(service)" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="w-100" *ngIf="service.get('isValid')?.value">
                                    <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="status">
                                        <option *ngFor="let status of statuses" [ngValue]="status"> {{status.name}}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="service.get('isValid')?.value">
                                <input type="number" class="form-control w-100" formControlName="duration" 
                                        step="1" [ngClass]="{ 'is-invalid': showValidations && servicesForms.at(i).get('duration')?.errors }">
                                <div class="invalid-feedback" *ngIf="servicesForms.at(i).get('duration')?.errors?.required && showValidations" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="servicesForms.at(i).get('duration')?.errors?.min && showValidations" translate>errors.onlyPositiveNumbers</div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="service.get('isValid')?.value">
                                <input type="number" class="form-control w-100" formControlName="priceAmount" step="0.01" 
                                        (change)="calculatePriceAmountDds(i)" [ngClass]="{ 'is-invalid': showValidations && servicesForms.at(i).get('priceAmount')?.errors }">
                                <div class="invalid-feedback" *ngIf="servicesForms.at(i).get('priceAmount')?.errors?.required && showValidations" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="servicesForms.at(i).get('priceAmount')?.errors?.min && showValidations" translate>errors.minAmount</div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="service.get('isValid')?.value">
                                    <select class="form-control w-100" formControlName="priceDdsValue" (change)="calculatePriceAmountDds(i)">
                                        <option *ngFor="let vatGroup of VAT_GROUPS" [ngValue]="vatGroup"> {{vatGroup}}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-group" *ngIf="service.get('isValid')?.value">
                                    <span>{{service.get('priceAmountDds')?.value | number : '1.2-2'}}</span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </form>
</div>
<ng-template #servicesLoadins>
    <div *ngIf="errorMessage$ | async as errorMessage; else servicesSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #servicesSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>
