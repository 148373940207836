import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ListContract } from '@app/contracts/_models/list-contract.model';
import { ContractService } from '@app/contracts/_services/contract.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { CONTRACT_KINDS } from '@app/_enums/contract-kind-enum';
import { StatusCode } from '@app/_enums/status-code';
import { BaseSortableSearchComponent } from '@components/_base/base-search/base-sortable-search.component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { CustomerDiscount } from '@models/customer-discount.model';
import { SearchSubjectVersion } from '@models/search-subject-version.model';
import { Status } from '@models/status.model';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@services/export.service';
import { NomenclatureService } from '@services/nomenclature.service';
import { SubjectVersionService } from '@services/subject-version.service';
import { EMPTY, forkJoin, Observable, of, OperatorFunction, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, first, repeatWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import { displayError, displayErrorFromUnknown } from '@app/_utils/error-util';
import { convertDateToString } from '@app/_utils/date-util';

@Component({
  selector: 'app-list-cleint-contracts',
  templateUrl: './list-client-contracts.component.html',
  styleUrls: ['./list-client-contracts.component.css']
})
export class ListClientContractsComponent extends BaseSortableSearchComponent<ListContract> implements OnInit, OnDestroy {
  // Units
  customerDiscounts:    CustomerDiscount[];
  statuses:             Status[];
  module:               string = '';

  // Constants
  readonly contractKinds = CONTRACT_KINDS;
  private readonly DISCOUNT_TYPE_CONTRACT = 'contract';

  // Booleans
  loadPdfOrExcel = false;

  // Form
  searchForm = this.formBuilder.group({
    contractKind:               [null],
    ownerSubjectVersion:        [null],
    contractSubjectVersion:     [null],
    docNumber:                  [null],
    customerDiscountId:         [null],
    statusId:                   [null],
    contractType:               this.isClientContract() ? 'C' : 'P'
  });

  // Observables
  searchSubscription: Subscription;
  search$ = this.searchSubject.asObservable().pipe(
    tap(() => this.dataLoaded = false),
    concatMap(() => {
      return this.contractService.pageable(this.sortingPaging, this.searchForm.value).pipe(
        tap(([page, totalCount]) => {
          this.sortingPaging.fromRow = page.fromRow;
          this.sortingPaging.toRow = page.toRow;
          this.sortingPaging.totalElements = totalCount;
          this.content = page.content;
          this.dataLoaded = true;
        })
      )
    }),
    catchError(err => {
			displayError(err);
      this.dataLoaded = false;
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
  );

  module$ = this.route.queryParams.subscribe(params => {
    this.module = params['module'];
  });

  loadSearchForm$ = forkJoin([
    this.nomenclatureService.getCustomerDiscounts(),
    this.nomenclatureService.getStatusesByType(StatusCode.Contract)]).pipe(
      tap(([customerDiscounts, statuses]) => {
      this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
      this.statuses = statuses;
      this.searchSubject.next();
    }),
    catchError(err => {
      displayError(err);
      this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  )

  subjectVersionsSearch: OperatorFunction<string, SearchSubjectVersion[]> = (input$: Observable<string>) =>
    input$.pipe(
      debounceTime(300),
      switchMap(input =>
        this.subjectVersionService.filterSubjectVersions(input).pipe(
          catchError(() => of([])),
          shareReplay()
        )
      )
  );

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder, 
    private perms: PermissionsService,
    private exportService: ExportService,
    private contractService: ContractService,
    private translateService: TranslateService,
    private nomenclatureService: NomenclatureService,
    private subjectVersionService: SubjectVersionService
  ) {
    super();
  }

  ngOnInit() {
    this.searchForm.reset();
    this.searchSubscription = this.search$.subscribe();
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  subjectVersionFormatter(element: any) {
    return element.fullName;
  }

  fromatKind(code: any) {
    return CONTRACT_KINDS.find(kind => kind.code === code)?.name
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      contractKind:               [null],
      ownerSubjectVersion:        [null],
      contractSubjectVersion:     [null],
      docNumber:                  [null],
      customerDiscountId:         [null],
      statusId:                   [null],
      contractType:               this.isClientContract() ? 'C' : 'P'
    });
    this.searchSubject.next();
  }

  private filterDiscountsByType(discounts: CustomerDiscount[]) {
    return discounts.filter((discount) => discount.customerDiscountType?.some((cdt) => cdt.discountType.code === this.DISCOUNT_TYPE_CONTRACT));
  }

  isClientContract() {
    return this.module == 'client';
  }

  formatContractKind(code: any) {
    return CONTRACT_KINDS.find(kind => kind.code === code)?.name
  }

  async exportExcel() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsExcelFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(),
        this.translateService.instant("exportData.filesNames.contractsResult"));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  async exportPDF() {
    this.loadPdfOrExcel = true;
    try {
      this.exportService.exportAsPdfFile(await this.prepareData(), this.getHeadings(), this.getFilterBody(), this.getFilterHeading(), 
        this.translateService.instant("exportData.filesNames.contractsResult"), convertDateToString(new Date()));
    } catch (err) {
      displayErrorFromUnknown(err);
    } finally {
      this.loadPdfOrExcel = false;
    }
  }
 
  private async prepareData() {
    let newContent: any = [];
    let sortingPagingCopy: SortingPagingData = new SortingPagingData(this.sortingPaging.totalElements);
    sortingPagingCopy.pageSize = this.sortingPaging.totalElements;
    sortingPagingCopy.sortBy = this.sortingPaging.sortBy;
    sortingPagingCopy.sortAsc = this.sortingPaging.sortAsc;
    
    let result = await this.contractService.findAllByFilter(sortingPagingCopy, this.searchForm.value).pipe(first()).toPromise();

    result.content.forEach(object => {
      newContent?.push([this.fromatKind(object.contractKind), object.docNumber, object.validFrom, object.validTo, object.ownerSubjectVersion?.identNum, object.ownerSubjectVersion?.fullName,
      object.contractSubjectVersion?.identNum, object.contractSubjectVersion?.fullName, object.status.name])
    });

    return newContent;
  }
 
  private getHeadings(): string[][] {
    return [[this.translateService.instant("contract.contractType"), this.translateService.instant("contract.contractNum"), 
            this.translateService.instant("contract.validFrom"), this.translateService.instant("contract.validTo"), this.translateService.instant("contract.ownerEik"),
            this.translateService.instant("contract.ownerName"), this.translateService.instant("contract.userEik"),
            this.translateService.instant("contract.userName"),  this.translateService.instant("contract.status")]]
  }
   
  private getFilterBody(): any[] {
    let customerDiscountName = '';
    this.customerDiscounts.forEach(customerDiscount => {
      if (customerDiscount.id == this.searchForm.get('customerDiscountId')?.value) {
        customerDiscountName = customerDiscount.discountName;
      }
    });

    let statusName = '';
    this.statuses.forEach(status => {
      if (status.id == this.searchForm.get('statusId')?.value) {
        statusName = status.name;
      }
    });

    let result = [
      this.formatContractKind(this.searchForm.get('contractKind')?.value),
      this.searchForm.get('ownerSubjectVersion')?.value, 
      this.searchForm.get('contractSubjectVersion')?.value, 
      customerDiscountName,
      this.searchForm.get('docNumber')?.value,
      statusName
    ];

    return [result];
  }
 
  private getFilterHeading(): any[][] {
    let colSpan = 8;
    let result: any[] = [
      this.translateService.instant('exportData.listContracts.contractKind'),
      this.translateService.instant('exportData.listContracts.ownerSubjectVersion'),
      this.translateService.instant('exportData.listContracts.contractSubjectVersion'),
      this.translateService.instant('exportData.listContracts.customerDiscountId'),
      this.translateService.instant('exportData.listContracts.docNumber'),
      this.translateService.instant('exportData.listContracts.status')
    ];

    return [
      [{content: this.translateService.instant('menu.crm.contracts.search'), colSpan: colSpan, styles: {halign: 'center'}}],
      result
    ];
  }

  get canView() {
    switch (this.module) {
      case 'client': 
        return this.perms.hasAccess(this.perms.CAN_VIEW_CORPORATE_CLIENTS_CONTRACT);
      case 'pertner': 
        return this.perms.hasAccess(this.perms.CAN_VIEW_PARTNER_CONTRACT);
      default: 
        return false;
    }
  } 

  get canAddEdit() {
    switch (this.module) {
      case 'client': 
        return this.perms.hasAccess(this.perms.CAN_VIEW_PARTNER_CONTRACT);
      case 'pertner': 
        return this.perms.hasAccess(this.perms.CAN_ADD_EDIT_PARTNER_CONTRACT);
      default: 
        return false;
    }
  }

}