import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/login/_services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private auth:   AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.auth.loginSubject.subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        this.router.navigate(['login']);
      }
    });
  }

}
