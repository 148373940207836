import { Directive, Input, Output, EventEmitter } from "@angular/core";
import { SortingPagingData } from "@helpers/sorting-paging-data";

@Directive({
  selector: 'th[sortBy]',
  host: {
    '[class.asc]': 'sortAsc',
    '[class.desc]': 'sortAsc === false', // can't user !orderAsc here, all nulls match it
    '(click)': 'toggle()'
  }
})
export class NgdbSortableHeaderDirective {
  @Input() sortBy: string;
  @Input() sortAsc: any = null;
  @Output() sort = new EventEmitter<SortingPagingData>();

  public toggle() {
    switch(this.sortAsc) {
      case true:
        this.sortAsc = false;
        break;
      case false:
        this.sortAsc = null;
        break;
      case null:
        this.sortAsc = true;
        break;
    }

    // this.sort.emit({sortBy: this.sortBy, sortAsc: this.sortAsc});
    this.sort.emit(SortingPagingData.buildSortData(this.sortBy, this.sortAsc));
  }

}
