import { AbstractControl } from "@angular/forms";
import { RvsCategoryService } from "@models/rvs-category-service.model";
import { TechPointService } from "../_models/tech-point-service.model";

export interface ServicesCardsContainer {
    services: TechPointService[],
    subCards: TechPointService[]
}

export interface RvsServiceCardsContainer {
    services: RvsCategoryService[],
    subCards: RvsCategoryService[]
}

export interface ServicesCardsControlContainer {
    services: AbstractControl[],
    subCards: AbstractControl[]
}

export const validateContainer = (container: ServicesCardsContainer | RvsServiceCardsContainer | ServicesCardsControlContainer): boolean => {
    return container != null && container.services != null && container.subCards != null;
}