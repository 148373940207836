<div class="container-fluid px-5 py-3">
    <div class="d-flex justify-content-between pb-2">
        <span class="text-blue font-weight-bold">
            <h3 class="my-2" translate>menu.crm.searchListCustomers</h3>
        </span>
    </div>
    <div class="my-2 py-2">
        <!-- ****************************************************** 
        ************** Search Form *************
        ****************************************************** -->
        <form [formGroup]="searchForm">
            <div class="input-group mb-2">
                <!-- name -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>clientProfile.clientName</span>
                </div>
                <input type="text" class="form-control d-inline" (keyup.enter)="search()" formControlName="name" id="nameInput">
                
                <!-- client pin -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>clientProfile.clientPIN</span>
                </div>
                <input type="text" class="form-control d-inline" (keyup.enter)="search()" formControlName="identificationNumber" id="pinInput">
                
                <!-- phone number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>clientProfile.clientPhone</span>
                </div>
                <input type="text" class="form-control d-inline" (keyup.enter)="search()" formControlName="phoneNumber" id="phoneInput">
                
                <!-- email -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>clientProfile.clientMail</span>
                </div>
                <input type="text" class="form-control d-inline" (keyup.enter)="search()" formControlName="email" id="mailInput">
            </div>
            <div class="input-group mb-2">
                <!-- client license number -->
                <div class="input-group-prepend">
                    <span class="input-group-text" translate>clientProfile.clientLicenseNumber</span>
                </div>
                <input type="number" class="form-control d-inline" style="max-width: 250pt;" (keyup.enter)="search()" formControlName="drivingLicenceNumber" id="licenseInput">
                
                <!-- car number -->
                <div class="input-group-prepend ml-2">
                    <span class="input-group-text" translate>clientProfile.carNumber</span>
                </div>
                <input style='text-transform:uppercase; max-width: 280pt;' type="text" class="form-control d-inline" (keyup.enter)="search()" formControlName="regNumber" id="regNumberInput">
                
                <!-- actions -->
                <div class="input-group-prepend ml-2">
                    <div style="display: flex">
                        <button class="btn btn-outline-success" style="border-radius: 0;" type="button" (click)="search()" translate>button.search</button>
                        <button class="btn btn-outline-danger ml-2" style="border-radius: 0;" type="button" (click)="clearSearch()" translate>button.clear</button>
                        <i style="margin-left: 10px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.excel' | translate}}" class="fa fa-file-excel-o fa-2x" (click)="exportExcel()"></i>
                        <i style="margin-left: 5px; margin-top: 2px" data-toggle="tooltip" data-placement="bottom" title="{{'button.pdf' | translate}}" class="fa fa-file-pdf-o fa-2x" (click)="exportPDF()"></i> 
                    </div>
                </div>
            </div>
        </form>
        <br>
        <ng-container *ngIf="!loadPdfOrExcel; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Table Data *************
        ****************************************************** -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-striped w-100" [hidden]="!dataLoaded">
                <thead>
                    <tr>
                        <th style="width: 14%;" sortBy="name" (sort)="onSort($event)" translate>clientProfile.clientName</th>
                        <th style="width: 9%;" sortBy="identificationNumber" (sort)="onSort($event)" translate>clientProfile.clientPIN</th>
                        <th style="width: 10%;" sortBy="phoneNumber" (sort)="onSort($event)" translate>clientProfile.clientPhone</th>
                        <th style="width: 14%;" sortBy="email" (sort)="onSort($event)" translate>clientProfile.clientMail</th>
                        <th style="width: 15%;" sortBy="drivingLicenceNumber" (sort)="onSort($event)" translate>clientProfile.clientLicenseNumber</th>
                        <th translate>clientProfile.carNumber</th>
                        <th translate>clientProfile.actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let customer of content">
                        <td>{{customer.name}}</td>
                        <td>{{customer.identificationNumber}}</td>
                        <td>{{customer.phoneNumber}}</td>
                        <td>{{customer.email}}</td>
                        <td>{{customer.drivingLicenceNumber}}</td>
                        <td>{{customer.regNumbers}}</td>
                        <td class="text-center py-2"> 
                            <button class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="bottom" title="{{'button.view' | translate}}"
                                routerLink="/add-edit-client-profile" [queryParams]="{id: customer.id}" *ngIf="perms.hasAccess(perms.CAN_VIEW_CUSTOMER)"><i class="fa fa-eye fa-lg"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="dataLoaded; else dataLoadingTemplate"></ng-container>
        <!-- ****************************************************** 
        ************** Pagination *************
        ****************************************************** -->
        <div class="paging-wrapper d-flex justify-content-between">
            <select class="pagination-size custom-select" [(ngModel)]="sortingPaging.pageSize" (change)="onPageSizeChange($event)">
                <option *ngFor="let item of pageSizeOptions; let i = index" [value]="pageSizeOptions[i]">
                    {{pageSizeOptions[i]}} {{'paging.itemsPerPage' | translate}} 
                </option>
            </select>
            <ngb-pagination class="pagination-control"
                [(page)]="sortingPaging.pageNumber"
                [pageSize]="sortingPaging.pageSize"
                [collectionSize]="sortingPaging.totalElements"
                [boundaryLinks]="true"
                [maxSize]="maxSize"
                (pageChange)="pageChanged($event)">
            </ngb-pagination>
            <span class="pagination-index border center">
                {{sortingPaging.fromRow}} - {{sortingPaging.toRow}} / {{sortingPaging.totalElements}}
            </span>
        </div>
    </div>
</div>

<ng-template #dataLoadingTemplate>
    <div *ngIf="errorMessage$ | async as errorMessage; else dataSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #dataSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>