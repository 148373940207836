import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  private readonly TOKEN_NAME = 'token';
  private readonly LOGIN_PATH = '/login';

  public loading = false;
  public showValidation = false;

  public changePassForm = this.formBuilder.group({
    newPass:    [null, Validators.required],
    repeatPass: [null, Validators.required]
  });

  private token: string = '';

  private baseSub: Subscription = new Subscription();  

  constructor(
    private route:            ActivatedRoute,
    private toastr:           ToastrService,
    private router:           Router,
    private formBuilder:      FormBuilder,
    private authService:      AuthenticationService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    let paramsSub = this.route.queryParams.pipe(
      tap(params => this.token = params[this.TOKEN_NAME]),
      switchMap(params => {
        if (params[this.TOKEN_NAME] != null) {
          return this.authService.checkTokenValidity(params[this.TOKEN_NAME]);
        }

        return of(false);
      })
    ).subscribe(isValid => this.handleTokenCheckResult(isValid));
    this.baseSub.add(paramsSub);
  }

  public onSubmit() {
    if(!this.validatePass()) {
      this.showValidation = true;
      this.form.repeatPass.setErrors({ nonMachingPass : true });
      return;
    }

    this.authService.resetPassword(this.token, this.form.newPass.value).subscribe({
      next: () => {
        this.toastr.success(
          this.translateService.instant('auth.resetPassSuccess'),
          this.translateService.instant('toast.successTitle')
        );

        this.router.navigate([this.LOGIN_PATH]);
      }
    });
  }

  private validatePass() {
    let newPass = this.form.newPass.value;
    let repeatPass = this.form.repeatPass.value;

    return newPass === repeatPass;
  }

  private handleTokenCheckResult(isValid: boolean) {
    if (!isValid) {
      this.toastr.error(
        this.translateService.instant('toast.resetTokenInvalid'),
        this.translateService.instant('toast.errorTitle')
      );

      this.router.navigate([this.LOGIN_PATH]);
    }
  }

  get form() {
    return this.changePassForm.controls;
  }

}
