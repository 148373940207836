import { Directive, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { config } from '@env/config';
import { NgdbSortableHeaderDirective } from '@helpers/directives/ngdb-sortable-header.directive';
import { AbstractSortingComponent } from '@helpers/sorting-component';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { Subject } from 'rxjs';

@Directive({})
export class BaseSortableSearchComponent<T> implements OnInit {
  @ViewChildren(NgdbSortableHeaderDirective) headers: QueryList<NgdbSortableHeaderDirective>;
  public sortingPaging = new SortingPagingData();

  public onSort(data: SortingPagingData) {
    // clear previous
    this.headers.forEach(header => {
      if (header.sortBy !== data.sortBy) {
        header.sortAsc = null;
      }
    });

    this.sortingPaging.sortBy = data.sortBy;
    this.sortingPaging.sortAsc = data.sortAsc;

    this.search();
  }
  
  pageSizeOptions: number[] = config.pageSizeOptions;
  content: T[];
  maxSize: number = config.maxSize;
  dataLoaded: boolean;
  searchForm: FormGroup;

  protected errorMessageSubject = new Subject<string>();
  errorMessage$ = this.errorMessageSubject.asObservable();

  protected reloadSubject = new Subject();
  reload$ = this.reloadSubject.asObservable();

  protected searchSubject = new Subject<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  reload():void {
    this.errorMessageSubject.next(undefined);
    this.reloadSubject.next();
  }

  pageChanged(page: number): void {
    this.sortingPaging.pageNumber = page;
    this.searchSubject.next();
  }

  onPageSizeChange(event: any) {
    this.sortingPaging.pageSize = event.target.value;
    this.searchSubject.next();
  }

  search() {
    if (this.searchForm.valid) {
      this.searchSubject.next();
    }
  }

  clearSearch() {
    this.searchForm.reset();
    this.searchSubject.next();
  }

  compareById(optionOne: any, optionTwo: any): boolean {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  compareByCode(optionOne: any, optionTwo: any) : boolean {
    return optionOne && optionTwo && optionOne.code === optionTwo.code;
  }
}
