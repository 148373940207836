export interface PriceAttribute{
    id?: number;
    type?: string;
    amount: number;
    amountDds: number;
    ddsValue: number;
    kind?: string;
    // discountValue?: number;
}


export enum PRICE_ATTRIBUTE_TYPE {
    DISCOUNT = 'discount',
    PRICE = 'price'
}