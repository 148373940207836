import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY, forkJoin, Subscription } from 'rxjs';
import { LoyaltyPrograms } from '@app/card-config/_models/loyalty-programs.model';
import { LoyaltyProgramConditions } from '@app/card-config/_models/loyalty-program-conditions.model';
import { NomenclatureService } from '@services/nomenclature.service';
import { CustomerDiscount } from '@models/customer-discount.model';
import { Status } from '@models/status.model';
import { LoyaltyProgramTypes } from '@app/card-config/_models/loyalty-program-types.model';
import { StatusCode } from '@app/_enums/status-code';
import { CardProgramServiceService } from '@app/card-config/_services/card-program-service.service';
import { catchError, filter, repeatWhen, tap } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CustomStatus } from '@app/card-config/_models/custom-status.model';
import { CustomCustomerDiscount } from '@app/card-config/_models/custom-customer-discounts.model';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { displayError } from '@app/_utils/error-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { TranslateService } from '@ngx-translate/core';
import { RegisterOfLegalPersonsModalComponent } from '@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { modalMinWidth } from '@env/config';
import { SubjectVersion } from '@models/subject-version.model';

@Component({
  selector: 'add-program',
  templateUrl: './add-program.component.html',
  styleUrls: ['./add-program.component.css']
})

export class AddProgram  extends BaseParentComponent implements OnInit, OnDestroy {
  // Units
  loyaltyProgram:             LoyaltyPrograms;
  loyaltyProgramId:           number;
  condition:                  LoyaltyProgramConditions;
  invalidConditions:          LoyaltyProgramConditions[] = [];
  customerDiscounts:          CustomerDiscount[] = [];
  impulseCustomerDiscounts:   CustomerDiscount[] = [];
  statuses:                   Status[] = [];
  types:                      LoyaltyProgramTypes[] = [];
  conditionDiscounts:         CustomerDiscount[];
  subject:                    SubjectVersion | null;

  // Booleans
  isSubmited                = false;
  isDateFormFiledDisabled   = true;
  isDateActive              = false;
  isItEditableProgram       = false;
  loadData                  = false;
  isProgramTypeStatic       = true;
  isDiscountReq             = true;
  isRowAction               = false;
  isNeedConditions          = false;
  isAllConditionsInvalid    = false;
  displayComponent          = false;

  // Constants
  private readonly PROGRAM_STATUS_INACTIVE_NAME: string   = 'Неактивен';
  private readonly PROGRAM_TYPE_STATIC_NAME: string       = 'Статична';
  private readonly PROGRAM_TYPE_PROGRESSIVE_NAME: string  = 'Прогресивна';
  private readonly DISCOUNT_TYPE_CARD: string             = 'card';
  previousUrl: string = '';
  currentUrl:  string = '';

  // Payload
  showValidations$ = this.showValidationsSubject.asObservable();

  // Form
  programForm: FormGroup = this.formBuilder.group({
      programName:         ['', [Validators.required]],
      status:              [null, [Validators.required]],
      type:                [null, [Validators.required]],
      discount:            [null],
      impulseDiscount:     [null],
      versionData:         [''],
      validFrom:           [''],
      validTo:             [''],
      subjectVersion:      [''],
      conditionList:       this.formBuilder.array([])
  });

  loadNomenclatureData$ = forkJoin([
    this.nomenclatureService.getStatusesByType(StatusCode.LoyaltyProgram)]).pipe(
      tap(([statuses]) => {
        this.statuses = statuses;
      }),
      catchError(err => {
        displayError(err);
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
  );

  // Subscriptions
  nomenclatureDataSubscription: Subscription;
  private subscriptions = new Subscription();

  constructor(
    private perms:                PermissionsService,
    protected router:             Router,
    private dialog:               MatDialog,
    private formBuilder:          FormBuilder, 
    private route:                ActivatedRoute,
    private translateService:     TranslateService,
    private nomenclatureService:  NomenclatureService,
    private programService:       CardProgramServiceService,
    private uiEvent:              UIEventCustom) { 
      super(router);
  }

  async ngOnInit() {
    if (!this.perms.hasAccess(this.perms.CAN_ADD_EDIT_LOYALTY_PROGRAM)) {
      this.router.navigate(['/']);
    }
    this.displayComponent = false;
    /*
     * For this router event:
     *  Check current and previous url.
     *  If doesn't match, then reset and bind the form with initial data,
     *  because if you switch for example from /add-program?id=241 to /add-program, 
     *  the form doesn't change and it's stays with the object data values, not with the default inital
    */
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
       const temp = event as NavigationEnd;
       this.previousUrl = this.currentUrl;
       this.currentUrl = temp.url;
       if (this.currentUrl !== this.previousUrl) {
        this.programForm.reset();
        this.loyaltyProgram = new LoyaltyPrograms();
        this.setupInitialFormData();
       }
    });

    await Promise.all([this.reloadCustomerDiscounts(), this.reloadLoyaltyProgramTypes(), this.reloadProgram()]);
    this.nomenclatureDataSubscription = this.loadNomenclatureData$.subscribe();
    await new Promise(f => setTimeout(f, 300));
    if (!this.isItEditableProgram) {
      this.setupInitialFormData();
    }

    if (!this.isItEditableProgram) {
      await new Promise(f => setTimeout(f, 200));
      this.setupInitialData();
    }
    this.displayComponent = true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private reloadProgram() {
    const programId = this.route.snapshot.queryParamMap.get('id');
    if (!programId) return;

    this.conditionDiscounts = [];
    let rowsCtrl = <FormArray>this.programForm.controls['conditionList'];
    this.isItEditableProgram = true;
    
    this.programService.findProgramById(Number.parseInt(programId)).toPromise().then(program => {
      this.loyaltyProgram = program as LoyaltyPrograms;
      if (!this.loyaltyProgram) return;
      
      this.programForm.patchValue(this.loyaltyProgram);
      if (this.loyaltyProgram.subjectVersion) {
        this.subject = this.loyaltyProgram.subjectVersion;
      }
      
      this.updateDateProgram(this.loyaltyProgram);
      this.programForm.get('validFrom')?.setValue(new Date());
      this.programForm.get('validTo')?.setValue(this.loyaltyProgram.validTo);
      
      if (program.conditions) {
          program.conditions.sort((a, b) => a.id > b.id ? 1 : -1);
          program.conditions.forEach(p => {
            this.conditionDiscounts.push(p.discount);
            rowsCtrl.push(this.formBuilder.group({
              id: p.id,
              program: [p.program],
              fromNumber: [p.fromNumber, [Validators.required]],
              toNumber: [p.toNumber, [Validators.required]],
              discount: [p.discount, [Validators.required]],
              isValid: p.isValid,
            }))
          });
      }

      this.setupCorrectTypes();
      this.setupCorrectStatuses();
      this.setupCorrectCustomerDiscount();
      if (this.loyaltyProgram.impulseDiscount) this.setupCorrectImpulseCustomerDiscount();
      this.loadData = true;
    })
    .catch(err => displayError(err));
  }

  private setupInitialData() {
    const activeStatusIndex = this.statuses.findIndex(s => s.name === 'Активен');
    if (activeStatusIndex !== -1) {
      const [activeStatus] = this.statuses.splice(activeStatusIndex, 1);
      this.statuses.unshift(activeStatus);
      this.statuses = [...this.statuses];
      this.programForm.get('status')?.setValue(activeStatus);
    }
  
    const activeTypeIndex = this.types.findIndex(t => t.name === 'Статична');
    if (activeTypeIndex !== -1) {
      const [activeType] = this.types.splice(activeTypeIndex, 1);
      this.types.unshift(activeType);
      this.types = [...this.types];
      this.programForm.get('type')?.setValue(activeType);
    }
  }

  private reloadLoyaltyProgramTypes() {
    this.nomenclatureService.getLoyaltyProgramTypes().toPromise().then(types => {
      this.types = types;
    })
    .catch(err => displayError(err))
  }

  private reloadCustomerDiscounts() {
    this.nomenclatureService.getCustomerDiscounts().toPromise().then(discounts => {
      const filteredDiscounts = this.filterDiscountsByType(discounts);
      this.customerDiscounts = filteredDiscounts;
      this.impulseCustomerDiscounts = filteredDiscounts;
    })
    .catch(err => displayError(err))
  }

  private filterDiscountsByType(discounts: CustomerDiscount[]) {
    return discounts.filter((discount) => discount.customerDiscountType?.some((cdt) => cdt.discountType.code === this.DISCOUNT_TYPE_CARD));
  }

  private async setupInitialFormData() {
    this.displayComponent = false;
    await new Promise(f => setTimeout(f, 200));

    let statusToRemove = new CustomStatus();
    for (let i = 0; i < this.statuses.length; i++) {
      const current = this.statuses[i];
      if (current.name === this.PROGRAM_STATUS_INACTIVE_NAME) {
        statusToRemove = current;
      }
    }
    const indexStatus = this.statuses.indexOf(statusToRemove);
    if (indexStatus > -1) {
      this.statuses.splice(indexStatus, 1);
    }
    this.statuses.splice(0, 0, statusToRemove);
    this.programForm.get('status')?.setValue(statusToRemove);

    let typeToRemove = new LoyaltyProgramTypes();
    for (let i = 0; i < this.types.length; i++) {
      const current = this.types[i];
      if (current.name === this.PROGRAM_TYPE_STATIC_NAME) {
        typeToRemove = current;
        break;
      }
    }
    const indexType = this.types.indexOf(typeToRemove);
    if (indexType > -1) {
      this.types.splice(indexType, 1);
    }
    this.types.splice(0, 0, typeToRemove);
    this.programForm.get('type')?.setValue(typeToRemove);
    this.programForm.get('validFrom')?.setValue(new Date());
  }

  async onSubmit() {
    this.isSubmited = true;
    this.checkDateIfStatusIsActive();
    if (this.programForm.get('type')?.value.name === this.PROGRAM_TYPE_PROGRESSIVE_NAME) {
      this.programForm.get('discount')?.setValue(null);
      this.isNeedConditions = true;
      this.isDiscountReq = false;
    } else {
      this.isNeedConditions = false;
      this.isDiscountReq = true;
    }

    this.checkConditionValid();
    if (!this.programForm.valid || this.isDateActive 
        || (this.isNeedConditions && this.programForm.get('conditionList')?.value.length === 0)
        || (this.isNeedConditions && this.isAllConditionsInvalid)
        || (this.isDiscountReq && !this.programForm.get('discount')?.value)) {
      this.uiEvent.displayUIError();
      return;
    }

    this.setupProgramObjectData();
    this.programService.saveProgram(this.loyaltyProgram).toPromise().then(() => {
        this.isSubmited = false;
        this.programForm.reset();
        this.router.navigate(['/add-program'], { relativeTo: this.route });
        this.setupInitialFormData();
        this.uiEvent.displayUISuccess();
    })
    .catch(err => displayError(err));
  }

  private setupProgramObjectData() {
    if (!this.isItEditableProgram) {
      this.loyaltyProgram = new LoyaltyPrograms();
    }
    this.loyaltyProgram.programName = this.programForm.get('programName')?.value;
    this.loyaltyProgram.discount = this.programForm.get('discount')?.value;
    if (!this.loyaltyProgram.discount) {
      this.loyaltyProgram.discount = this.customerDiscounts[0]; 
    }

    if (this.programForm.get('impulseDiscount')?.value) {
      this.loyaltyProgram.impulseDiscount = this.programForm.get('impulseDiscount')?.value;
    } else {
      this.loyaltyProgram.impulseDiscount = null;
    }

    if (this.programForm.get('subjectVersion')?.value) {
      this.loyaltyProgram.subjectVersion = this.programForm.get('subjectVersion')?.value;
    } else {
      this.loyaltyProgram.subjectVersion = null;
    }

    this.loyaltyProgram.type = this.programForm.get('type')?.value;
    if (!this.loyaltyProgram.type) {
      this.loyaltyProgram.type = this.types[0];
    }
    this.loyaltyProgram.status = this.programForm.get('status')?.value;
    if (!this.loyaltyProgram.status) {
      this.loyaltyProgram.status = this.statuses[0];
    }

    const validFrom = this.programForm.get('validFrom')?.value;
    if (validFrom) {
      this.loyaltyProgram.validFrom = validFrom;
      this.loyaltyProgram.validFrom?.setHours(this.loyaltyProgram.validFrom.getHours() + 1);
    }
    const validTo = this.programForm.get('validTo')?.value;
    if (validTo) {
      this.loyaltyProgram.validTo = validTo;
      this.loyaltyProgram.validTo?.setHours(this.loyaltyProgram.validTo.getHours() + 1);
    }

    if (this.isNeedConditions) {
      const conditions = this.programForm.get('conditionList')?.value as LoyaltyProgramConditions[];
      this.loyaltyProgram.discount = undefined;
      if (this.isItEditableProgram) {
        this.loyaltyProgram.conditions = [];
        if (conditions) {
          conditions.forEach(con => {
            this.loyaltyProgram.conditions?.push(con);
          })
        }
        if (this.invalidConditions) {
          this.invalidConditions.forEach(incon => {
            this.loyaltyProgram.conditions?.push(incon);
          })
        }
      } else {
        this.loyaltyProgram.conditions = [];
        for (let i = 0; i < conditions.length; i++) {
          this.loyaltyProgram.conditions.push(conditions[i]);
        }
      }
    } else {
      if (this.isItEditableProgram) {
        this.loyaltyProgram.conditions?.forEach(c => {
          c.isValid = false;
        });
      } else {
        this.loyaltyProgram.conditions = [];
      }
    }
  }

  private checkDateIfStatusIsActive() {
    let status = this.programForm.get('status')?.value;
    if (!status) {
      this.isDateActive = false;
      return;
    }
    if (status.name == 'Активен') {
      if (!this.programForm.get('validFrom')?.value) {
        this.isDateActive = true;
      } else {
        this.isDateActive = false;
      } 
    } else {
      this.isDateActive = false;
    }
  }

  private checkConditionValid() {
    const condList = this.programForm.get('conditionList')?.value;
    if (!condList) return;
    this.isAllConditionsInvalid = false;
    let countInvalidCon = 0;
    condList.forEach((c: CustomerDiscount) => {
      if (!c.isValid) countInvalidCon++;
    });
    if (countInvalidCon === condList.length) this.isAllConditionsInvalid = true;
  }

  addRow() {
    this.isNeedConditions = false;
    this.isRowAction = true;
    let rowsCtrl = <FormArray>this.programForm.controls['conditionList']
    rowsCtrl.push(this.formBuilder.group({
        id: null,
        fromNumber: [null, [Validators.required, Validators.min(0)]],
        toNumber: [null, [Validators.required, Validators.min(0)]],
        discount: [null, Validators.required],
        isValid: true
    }));  
  }

  deleteRow(index: number) {
    this.isRowAction = true;
    let rowsCtrl = <FormArray>this.programForm.controls['conditionList'];
    let element = rowsCtrl.at(index).value;
    element.isValid = false;
    if (element.id as LoyaltyProgramConditions) {
      this.invalidConditions.push(element);
    }
    rowsCtrl.at(index).patchValue(element);
    rowsCtrl.at(index).disable(element);
            
    (<FormArray>this.programForm.controls['conditionList']).at(index).get("fromNumber")?.clearValidators();
    (<FormArray>this.programForm.controls['conditionList']).at(index).get("toNumber")?.clearValidators();
    (<FormArray>this.programForm.controls['conditionList']).at(index).get("discount")?.clearValidators();
    (<FormArray>this.programForm.controls['conditionList']).at(index).get("fromNumber")?.updateValueAndValidity();
    (<FormArray>this.programForm.controls['conditionList']).at(index).get("toNumber")?.updateValueAndValidity();
    (<FormArray>this.programForm.controls['conditionList']).at(index).get("discount")?.updateValueAndValidity();
  }

  private updateDateProgram(program: LoyaltyPrograms) {
    const splitedDataFrom = program.validFrom?.valueOf().toString().split(',');
    if (splitedDataFrom) program.validFrom = this.formatDateProgram(splitedDataFrom);
    
    const splitedDataTo = program.validTo?.valueOf().toString().split(',');
    if (splitedDataTo) program.validTo = this.formatDateProgram(splitedDataTo);
  }

  private formatDateProgram(splitedDataFrom: any[]) {
    const year    = Number.parseInt(splitedDataFrom[0]);
    const month   = Number.parseInt(splitedDataFrom[1]) - 1;
    const day     = Number.parseInt(splitedDataFrom[2]);
    const hour    = Number.parseInt(splitedDataFrom[3]);
    const minutes = Number.parseInt(splitedDataFrom[4]);
    return new Date(year, month, day, hour, minutes);
  }

  private setupCorrectTypes() {
    let typeToRemove = new LoyaltyProgramTypes();
    for (let i = 0; i < this.types.length; i++) {
      const current = this.types[i];
      if (current.name === this.loyaltyProgram.type.name) {
        typeToRemove = current;
      }
    }
    const index = this.types.indexOf(typeToRemove);
    if (index > -1) {
      this.types.splice(index, 1);
    }
    this.types.splice(0, 0, this.loyaltyProgram.type);
  }

  private setupCorrectStatuses() {
    let statusToRemove = new CustomStatus();
    for (let i = 0; i < this.statuses.length; i++) {
      const current = this.statuses[i];
      if (current.name === this.loyaltyProgram.status.name) {
        statusToRemove = current;
      }
    }
    const index = this.statuses.indexOf(statusToRemove);
    if (index > -1) {
      this.statuses.splice(index, 1);
    }
    this.statuses.splice(0, 0, this.loyaltyProgram.status);
  }

  private setupCorrectCustomerDiscount() {
    let customerDiscountToRemove = new CustomCustomerDiscount();
    for (let i = 0; i < this.customerDiscounts.length; i++) {
      const current = this.customerDiscounts[i];
      if (!this.loyaltyProgram.discount) continue;
      if (current.discountName === this.loyaltyProgram.discount.discountName) {
        customerDiscountToRemove = current;
      }
    }
    const index = this.customerDiscounts.indexOf(customerDiscountToRemove);
    if (index > -1) {
      this.customerDiscounts.splice(index, 1);
    }
    if (!this.loyaltyProgram.discount) return;
    this.customerDiscounts.splice(0, 0, this.loyaltyProgram.discount);
  }

  private setupCorrectImpulseCustomerDiscount() {
    let customerDiscountToRemove = new CustomCustomerDiscount();
    for (let i = 0; i < this.impulseCustomerDiscounts.length; i++) {
      const current = this.impulseCustomerDiscounts[i];
      if (!this.loyaltyProgram.impulseDiscount) continue;
      if (current.discountName === this.loyaltyProgram.impulseDiscount.discountName) {
        customerDiscountToRemove = current;
      }
    }
    const index = this.impulseCustomerDiscounts.indexOf(customerDiscountToRemove);
    if (index > -1) {
      this.impulseCustomerDiscounts.splice(index, 1);
    }
    if (!this.loyaltyProgram.impulseDiscount) return;
    this.impulseCustomerDiscounts.splice(0, 0, this.loyaltyProgram.impulseDiscount);
  }

  openModal() {
    if (this.dialog.openDialogs.length == 0) {
      let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
      this.subscriptions.add(ref.componentInstance.submitSubject.subscribe(result => {
        this.subject = result;
        this.programForm.get('subjectVersion')?.setValue(result);
      }));
    }
  }

  onRemoveSubject() {
    this.subject = null;
    this.programForm.get('subjectVersion')?.setValue(null);
  }

  get getForm() {
    return this.programForm.controls;
  }

  get rows() {
    return this.programForm.get('conditionList') as FormArray;
  }

}