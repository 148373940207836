import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { defaultIfEmpty } from 'rxjs/operators';
import { SortingPaging } from '@helpers/sorting-paging';
import { Page } from '@interfaces/page';
import { Form, FormGroup } from '@angular/forms';
import { ListAccountingDocument } from '../_models/list-accounting-document.model';
import { AccountingDocument } from '../_models/accounting-document.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';
import { CustomerHttpParamsCodec } from '@helpers/custom-http-params-codec';

const accountingDocumentURL = environment.apiBaseURL + '/accounting-document';

const findByIdURL                           = accountingDocumentURL + '/find/{id}';
const findByOrderId                         = accountingDocumentURL + '/order';
const filterURL                             = accountingDocumentURL + '/filter';
const saveURL                               = accountingDocumentURL + '/save';
const pageableTotalElements                 = accountingDocumentURL + '/pageable/count';
const pageableAccountingDocuments           = accountingDocumentURL + '/pageable';
const exportAccountingDocuments             = accountingDocumentURL + '/export';
const convertAccountingDocuments            = accountingDocumentURL + '/convert';
const getTxtDocument                        = accountingDocumentURL + '/downloadTxt';
const generatePdf                           = accountingDocumentURL + '/generatePdf';
const createByTechInspectionURL             = accountingDocumentURL + '/techInspection/{techInspectionId}';
const generateInvoiceNumURL                 = accountingDocumentURL + '/generateInvoiceNum/{mobileAppId}';
const lastIdByInvoiceNumberURL              = accountingDocumentURL + '/last-id-by-invoice-number/{invoiceNumber}';
const updateAccountingDocumentStatusById    = accountingDocumentURL + '/{id}/status/{status}';
const updateAccountingDocumentStatusDateById= accountingDocumentURL + '/{id}/status/{status}/date/{paidDate}';

@Injectable({
  providedIn: 'root'
})
export class AccountingDocumentService {
  
  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPagingData, searchForm: any, fromDate: string, toDate: string) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm, fromDate, toDate),
      this.countAllByFilter(sortingPaging, searchForm, fromDate, toDate)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<Page<ListAccountingDocument>> {
    return this.http.get<Page<ListAccountingDocument>>(pageableAccountingDocuments, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<number> {
    return this.http.get<number>(pageableTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  public findAllByFilterExport(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<Array<AccountingDocument>> {
    return this.http.get<Array<AccountingDocument>>(exportAccountingDocuments, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }
  public convert(data: any): Observable<string> {
    return this.http.post(convertAccountingDocuments, data, {responseType: 'text'});
  }

  public findAccountingDocumentById(id: number): Observable<AccountingDocument> {
    if (id === undefined || id === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<AccountingDocument>(findByIdURL.replace('{id}', `${id}`));
  }

  public createAccDocFromTechInspection(techInspectionId: number): Observable<AccountingDocument> {
    if (techInspectionId === undefined || techInspectionId === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get<AccountingDocument>(createByTechInspectionURL.replace('{techInspectionId}', `${techInspectionId}`));
  }

  public findAccountingDocByOrderId(orderId: number): Observable<AccountingDocument> {
    if (orderId === undefined || orderId === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }

    let requestParams = new HttpParams().set('orderId', orderId.toString());

    return this.http.get<AccountingDocument>(findByOrderId, {params: requestParams});
  }

  public findAccountingDocByOrderIdToPromise(orderId: number) {
     return this.findAccountingDocByOrderId(orderId).toPromise()
  }

  public saveAccountingDocument(accountingDocument: AccountingDocument): Observable<any> {
    return this.http.post(saveURL, accountingDocument);
  }

  public findInvoiceByNum(invoiceNumber: string) {
    let params = new HttpParams().set('invoiceNumber', invoiceNumber);

    return this.http.get<AccountingDocument[]>(filterURL, { params : params });
  }

  public generateInvoiceNum(mobileAppId: number): Observable<any> {
    if (mobileAppId === undefined || mobileAppId === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get(generateInvoiceNumURL.replace('{mobileAppId}', `${mobileAppId}`), {responseType: 'text'});
  }

  public getTxtDocument(path: String): Observable<any> {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    requestParams = requestParams.set("path", path.toString());

    return this.http.get(getTxtDocument,{params:requestParams, responseType: 'blob'});
  }

  public generatePdf(id: number): Observable<any> {
    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});

    requestParams = requestParams.set("id", id.toString());

    return this.http.get(generatePdf,{params:requestParams, responseType: 'blob'});
  }

  public findLastIdByInvoiceNumber(invoiceNumber: String): Observable<any> {
    if (invoiceNumber === undefined || invoiceNumber === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }
    return this.http.get(lastIdByInvoiceNumberURL.replace('{invoiceNumber}', `${invoiceNumber}`));
  }

  public updateAccountingDocumentStatusById(id: number, status: string): Observable<any> {
    if (id == undefined || id === null || status === undefined || status === null) {
      return EMPTY.pipe(
        defaultIfEmpty()
      );
    }

    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});
    const url = updateAccountingDocumentStatusById.replace('{id}', `${id}`).replace('{status}', `${status}`);
    return this.http.put(url, { params:requestParams });
  }

  public updateAccountingDocumentStatusDateById(id: number, status: string, paidDate: string): Observable<any> {
    if (id == undefined || id === null || status === undefined || status === null || paidDate === undefined || paidDate === null) {
      return EMPTY.pipe(defaultIfEmpty());
    }

    let requestParams = new HttpParams({encoder: new CustomerHttpParamsCodec()});
    const url = updateAccountingDocumentStatusDateById.replace('{id}', `${id}`).replace('{status}', `${status}`).replace('{paidDate}', `${paidDate}`);
    return this.http.put(url, { params:requestParams });
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }

    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }
    
    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });
    
    requestParams = this.filterSubjects(requestParams, searchForm, fromDate, toDate);

    return requestParams;
  }

  filterSubjects(requestParams: HttpParams, searchForm : any, fromDate: string, toDate: string){
    if (searchForm.recepient != null) {
      if (searchForm.recepient?.mobileAppId != null) {
        requestParams = requestParams.set('recepientId', searchForm.recepient?.mobileAppId);
      } else {
        requestParams = requestParams.set('recepientName', searchForm.recepient);
      }
    }

    if(fromDate && fromDate.length !== 0) {
      requestParams = requestParams.set("dateFrom", fromDate);
    }

    if(toDate && toDate.length !== 0) {
        requestParams = requestParams.set("dateTo", toDate);
    }
    
    return requestParams;
  }

}
