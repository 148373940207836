<div *ngIf="isInitialized; else linesLoading">
    <form [formGroup]="linesForm">
        <div formArrayName="lines">
            <h5 class="text-danger" *ngIf="linesForm.controls.lines?.errors?.required && showValidations" translate>techPoint.noLinesValidation</h5>
            <ng-container *ngFor="let line of linesForms.controls; let lineIndex=index">
                <div *ngIf="line.get('isValid')?.value" [formGroupName]="lineIndex">
                    <div class="card my-3">
                        <div class="container pt-2 px-3">
                            <div class="row">
                                <div class="col-sm-10 justify-content-start">
                                    <label> {{ "techPoint.lineNumber" | translate: { lineNumber : line.get('number')?.value } }}</label>
                                </div>
                                <div class="col-sm-2 d-flex justify-content-end" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT)">
                                    <i class="fa fa-trash" (click)="deleteLine(line, lineIndex)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="container-fluid">
                                <div class="row ">
                                    <div class="col-md-4">
                                        <label translate>techPoint.status</label>
                                        <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="status">
                                            <option *ngFor="let status of statuses" [ngValue]="status"> {{status.name}}
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex flex-row mt-2">
                                            <h5 class="mr-2" translate>techPoint.vehicleType</h5>
                                            <small class="text-danger pt-1" *ngIf="line.get('categories')?.errors?.noCategoriesSelected && showValidations" translate>techPoint.noCategoriesSelected</small>
                                        </div>
                                        <hr class="w-90 mt-0">
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col">
                                        <div class="container">
                                            <div class="row">
                                                <div *ngFor="let category of line.get('categories')?.value" class="col-6 col-md-4">
                                                    <input class="form-check-input" type="checkbox" [checked]="category.techPointCategory === null ? false : category.techPointCategory.isValid" 
                                                           [value]="category" (click)="onChangeRvsCategories($event, category, line, lineIndex)">
                                                    <label>{{category.category.code}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex flex-row mt-2">
                                            <h5 class="mr-2" translate>techPoint.workingSlots</h5>
                                            <small class="text-danger pt-1" *ngIf="line.get('timeSlots')?.errors?.noTimeSlotsSelected && showValidations" translate>techPoint.noTimeSlotsSelected</small>
                                        </div>
                                        <hr class="w-90 mt-0">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <label translate>techPoint.workingSlotsInterval</label>
                                        <select class="custom-select" (change)="onIntervalChange($event, line, lineIndex)" formControlName="workingHoursType" aria-label="Интервал">
                                            <option value="null" disabled translate>techPoint.pleaseChoose</option>
                                            <option value="INTERVAL_15_TYPE_1">0-15</option>
                                            <option value="INTERVAL_20_TYPE_1">0-20</option>
                                            <option value="INTERVAL_20_TYPE_3">5-25</option>
                                            <option value="INTERVAL_30_TYPE_5">5-35</option>
                                            <option value="INTERVAL_20_TYPE_2">10-30</option>
                                            <option value="INTERVAL_30_TYPE_1">0-30</option>
                                            <option value="INTERVAL_30_TYPE_2">15-45</option>
                                            <option value="INTERVAL_30_TYPE_3">10-40</option>
                                            <option value="INTERVAL_30_TYPE_4">20-50</option>
                                            <option value="INTERVAL_30_TYPE_5">35-5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="container" >
                                    <div class="row">
                                        <div *ngFor="let timeSlot of line.get('timeSlots')?.value" [ngClass]="{'col-6 col-md-4 col-lg-2': timeSlot.timeSlot, 'col-12': timeSlot.day}">
                                            <div *ngIf="timeSlot.day" class="my-2 d-flex flex-row">
                                                <h5 class="mr-4">{{timeSlot.day.name | translate}}</h5>
                                                <div>
                                                    <input type="checkbox" class="form-check-input" (change)="selectAllTimeSlotsByDay($event, timeSlot.day, line)" 
                                                        [checked]="checkIfDayIsFull(line, timeSlot.day.code)"/>
                                                </div>
                                                <hr class="w-85">
                                            </div>
                                            
                                            <div *ngIf="timeSlot.timeSlot">
                                                <input type="checkbox" class="form-check-input"  [checked]="timeSlot.techPointTimeSlot === null ? false : timeSlot.techPointTimeSlot.isValid" 
                                                    [value]="timeSlot" (change)="onChangeTimeSlot($event, timeSlot, line)">
                                                <label>{{timeSlot.timeSlot.validFrom}} - {{timeSlot.timeSlot.validTo}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </form>
    <div class="d-flex justify-content-center" data-toggle="tooltip" data-placement="bottom" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT)"
         (click)="addLine()" title="{{ 'techPoint.addLine' | translate }}">
        <i class="fa fa-plus"></i>
    </div>
</div>
<ng-template #linesLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else lineSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #lineSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>
