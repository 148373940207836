import { TechnicalPointWorkingHour } from '@app/tech-points/_models/technical-point-working-hours.model';
import { TechnicalPointCategory } from './../../../_models/technical-point-category.model';
import { WorkingHours } from '@models/working-hours.model';
import { Line } from '@app/tech-points/_models/line.model';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { TranslateService } from '@ngx-translate/core';
import { modalMinWidth } from '@env/config';
import { StatusCode } from '@app/_enums/status-code';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { catchError, repeatWhen, tap } from 'rxjs/operators';
import { EMPTY, forkJoin, Subscription, Subject, of } from 'rxjs';
import { Status } from '@models/status.model';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';
import { RvsCategory } from '@models/rvs-category.model';
import { TimeConstraint } from '@models/time-constraint.model';
import { DayOfTheWeek, DAYS_OF_THE_WEEK } from '@app/_enums/days-of-the-week';
import { YesNoMessageboxComponent } from '@components/_base/_messageboxes/yes-no-messagebox/yes-no-messagebox.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsService } from '@app/login/_services/permissions.service';

@Component({
  selector: 'app-technical-point-lines',
  templateUrl: './technical-point-lines.component.html',
  styleUrls: ['./technical-point-lines.component.css']
})
export class TechnicalPointLinesComponent extends BaseChildComponent implements OnInit, OnDestroy {
  readonly INACTIVE_STATUS_CODE: string = 'INACTIVE';

  @Input() selectedCategoriesSubject: Subject<number[]>;
  
  @Output() formChangeEmmiter = new EventEmitter<void>();

  statuses: Status[];
  defaultStatus: Status | null;
  rvsCategories: RvsCategory[];
  lines: Line[] = [];

  currentInterval: string | null = null;
  timeConstrains: WorkingHours;

  private timeSlotsErrorSubject = new Subject<string>();
  timeSlotsError$ = this.timeSlotsErrorSubject.asObservable();

  private categoriesErrorSubject = new Subject<string>();
  categoriesError$ = this.categoriesErrorSubject.asObservable();

  //Subscriptions
  linesSubscription: Subscription;
  changeSubscription: Subscription;

  linesForm = this.formBuilder.group({
    lines: this.formBuilder.array([], Validators.required)
  });

  constructor(
    public perms:                   PermissionsService,
    private dialog:                 MatDialog,
    private formBuilder:            FormBuilder,
    private translateService:       TranslateService,
    private technicalPointService:  TechnicalPointService
  ) { 
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.changeSubscription = this.linesForm.valueChanges.subscribe({
      next: () => {
        if (this.linesForm.dirty) {
          this.formChangeEmmiter.emit();
        }
      }
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.linesSubscription?.unsubscribe();
  }

  addLine(): void {
    let lineForm = this.formBuilder.group({
      id:               [null],
      number:           [this.getValidLinesCount(), Validators.required],
      categories:       this.formBuilder.array(this.loadRvsCategories(null)),
      timeSlots:        this.formBuilder.array(this.loadTimeSlots(null, null)),
      status:           [this.defaultStatus, Validators.required],
      isValid:          [true],
      workingHoursType: [null]
    });

    this.linesForms.push(lineForm);
  }

  loadLine(line: Line): void {
    let lineForm = this.formBuilder.group({
      id:               [line.id],
      number:           [line.number, Validators.required],
      categories:       this.formBuilder.array(this.loadRvsCategories(line.categories)),
      timeSlots:        this.formBuilder.array(this.loadTimeSlots(line.workingHours, line.workingHoursType)),
      status:           [line.status, Validators.required],
      isValid:          [line.isValid],
      workingHoursType: [line.workingHoursType]
    });

    this.linesForms.push(lineForm);
  }

  initialize() {
    if (this.isInitialized) return; 

    this.linesSubscription = forkJoin([
      this.technicalPointService.findLinesByPointId(this.parentId),
      this.technicalPointService.getTechPointLinesNomenclature(StatusCode.TechnicalPointLine)
    ]).pipe(
      tap(([lines, [statuses, timeConstrains,rvsCategories]]) => {
        this.statuses = statuses,
        this.rvsCategories = rvsCategories.sort(this.sortTwoCategories);
        this.timeConstrains = timeConstrains;
        this.defaultStatus = this.statuses.find(status => status.code === this.INACTIVE_STATUS_CODE) || null;
        this.initLines(lines);
        this.lines = lines || [];

        this.isInitialized = true;
      }),
      catchError(() => {
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe({
      next: () => this.isInitialized = true
    });
  }

  initLinesAndNomenclatures(lines: Line[], timeConstrains: WorkingHours, 
                            categories: RvsCategory[], statuses: Status[]) {
    this.rvsCategories = categories;
    this.statuses = statuses;
    this.timeConstrains = timeConstrains;
    this.defaultStatus = this.statuses.find(status => status.code === this.INACTIVE_STATUS_CODE) || null;

    if (lines != null) {
      lines.forEach(line => this.loadLine(line));
    }

    this.isInitialized = true;
    this.isOpened = true;
  }

  async deleteLine(line: AbstractControl, index: number) {
    let dialogRef = this.dialog.open(YesNoMessageboxComponent, {
      ...modalMinWidth,
      data: {
        isWarning: false,
        message: "messagebox.lineRemoval"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        this.removeLine(line, index);
        this.sendSelectedCategories();
      }
    });
  }

  selectAllTimeSlotsByDay(event: any, day: DayOfTheWeek, line: AbstractControl) {
    let timeSlots = line.get('timeSlots')?.value as any[];
    let filteredSlots = timeSlots.filter(timeSlot => timeSlot.timeSlot?.dayOfTheWeek === day.code);
  
    if (event.target.checked) {
      if(line.get('timeSlots')?.hasError('noTimeSlotsSelected')) {
        line.get('timeSlots')?.setErrors({ noTimeSlotsSelected : null });
      }

      filteredSlots.forEach(timeSlot => this.createWorkingHour(timeSlot))
    } else {
      filteredSlots.forEach(timeSlot => this.deleteWorkingHour(timeSlot));
      this.validateWorkingHours(line);
    }

    this.formChangeEmmiter.emit();
  }

  onChangeTimeSlot(event: any, timeSlot: any, line: AbstractControl): void {
    if (event.target.checked) {
      if(line.get('timeSlots')?.hasError('noTimeSlotsSelected')) {
        line.get('timeSlots')?.setErrors({ noTimeSlotsSelected : null });
      }

      this.createWorkingHour(timeSlot);
    } else {
      this.deleteWorkingHour(timeSlot);
      this.validateWorkingHours(line);
    }
    
    this.checkIfDayIsFull(line, timeSlot.day);
    this.formChangeEmmiter.emit();
  }

  async onIntervalChange(event: any, line: AbstractControl, index: number) {
    let isConfirm = true;
    
    if (line.get('id')?.value != null) {
      let dialogRef = this.dialog.open(YesNoMessageboxComponent, {
        ...modalMinWidth,
        data: {
          isWarning: false,
          message: "messagebox.intervalChange"
        }
      });
  
      isConfirm = await dialogRef.afterClosed().toPromise();  
    }

    if (isConfirm) {
      let selectedInterval = event?.target?.value;
      this.currentInterval = selectedInterval;

      let timeSlots = line.get('timeSlots') as FormArray;
     
      while (timeSlots.length !== 0) {
        timeSlots.removeAt(0)
      }

      let lineHours = this.lines[index]?.workingHours || null;
      let data = this.loadTimeSlots(lineHours, selectedInterval);
      
      if (data != null && data != []) {
        data.forEach(slot => {
          timeSlots.push(this.formBuilder.control(slot));
        });
      }
    } else {
      line.get('workingHoursType')?.patchValue(this.currentInterval);
    }
  }

  async onChangeRvsCategories(event: any, category: any, line: AbstractControl, lineIndex: number) {
    if (event.target.checked) {
      if(line.get('categories')?.hasError('noCategoriesSelected')) {
        line.get('categories')?.setErrors({ noCategoriesSelected : null });
      }

      if (category.techPointCategory !== null) {
        category.techPointCategory.isValid = true;
      } else {
        category.techPointCategory = {
          id: null,
          rvsCategory: category.category,
          line: null,
          isValid: true
        }
      }
    } else {
      if (this.checkForCategory(category.techPointCategory.rvsCategory, lineIndex)) {
        this.removeRvsCategory(category, line);
        return;
      }

      event.target.checked = true;

      let dialogRef = this.dialog.open(YesNoMessageboxComponent, {
        ...modalMinWidth,
        
        data: {
          isWarning: false,
          message: "messagebox.categoryRemoval"
        }
      });

      await dialogRef.afterClosed().toPromise().then((isConfirm) => {
        if (isConfirm) {
          this.removeRvsCategory(category, line);
          event.target.checked = false;
        }
      });
    }
    
    this.sendSelectedCategories();
    this.formChangeEmmiter.emit();
  }

  validateLines(): boolean {
    let linesValid = true;

    if (this.linesForms.length == 0 || !this.checkForValidLines()) {
      linesValid = false;
    }

    for (let i = 0; i < this.linesForms.length; i++) {
      let line = this.linesForms.at(i);

      if (!line.get('isValid')?.value) {
        continue;
      }

      if (!this.validateWorkingHours(line)) {
        linesValid = false;
      }

      if (!this.validateCategories(line)) {
        linesValid = false;
      }
    }
    
    if (!linesValid) {
      this.isOpened = true;
    }
    return linesValid;
  }

  checkIfDayIsFull(line: AbstractControl, dayCode: number): boolean {
    let timeSlots = line.get('timeSlots')?.value as any[];
    timeSlots = timeSlots.filter(timeSlot => timeSlot.timeSlot?.dayOfTheWeek === dayCode);

    if (timeSlots.length === 0) {
      return false;
    }

    for (let timeSlot of timeSlots) {
      if (timeSlot.techPointTimeSlot == null || timeSlot.techPointTimeSlot.isValid === false) {
        return false;
      }
    }

    return true;
  }

  private createWorkingHour(timeSlot: any): void {
    if (timeSlot.techPointTimeSlot !== null) {
      timeSlot.techPointTimeSlot.isValid = true;
    } else {
      timeSlot.techPointTimeSlot = {
        id: null,
        timeConstraint: timeSlot.timeSlot,
        line: null,
        isValid: true
      }
    }
  }

  private deleteWorkingHour(timeSlot: any) {
    if (timeSlot.techPointTimeSlot !== null && timeSlot.techPointTimeSlot.id !== null) {
      timeSlot.techPointTimeSlot.isValid = false; 
    } else {
      timeSlot.techPointTimeSlot = null;
    }
  }

  private removeRvsCategory(category: any, line: AbstractControl) {
    if (category.techPointCategory !== null && category.techPointCategory.id !== null) {
      category.techPointCategory.isValid = false;  
    } else {
      category.techPointCategory = null;
    }

    this.validateCategories(line);
  }

  private checkForValidLines() {
    let lines = this.linesForms.value as any[];

    return lines.filter(line => line.isValid).length > 0;
  }

  private removeLine(line: AbstractControl, index: number) {
    if (line.get('id')?.value != null) {
      line.get('isValid')?.setValue(false);
    } else {
      this.linesForms.removeAt(index);
    }

    this.sendSelectedCategories();
  }

  private validateWorkingHours(line: AbstractControl): boolean {
    let timeSlots = line.get('timeSlots')?.value as any[];

    let timeSlotsCount = timeSlots.filter(timeSlot => timeSlot.techPointTimeSlot != null)
                                  .filter(timeSlot => timeSlot.techPointTimeSlot.isValid === true).length;

    if (timeSlotsCount <= 0) {
      line.get('timeSlots')?.setErrors({ noTimeSlotsSelected : true });
      return false;
    }

    return true;
  }

  private validateCategories(line: AbstractControl): boolean {
    let categories = line.get('categories')?.value as any[];

    let categoriesCount = categories.filter(category => category.techPointCategory != null)
                                    .filter(category => category.techPointCategory.isValid === true).length

    if (categoriesCount <= 0) {
      line.get('categories')?.setErrors({ noCategoriesSelected : true });
      return false;
    }

    return true;
  }

  private loadRvsCategories(categories: TechnicalPointCategory[] | null) {
    return this.rvsCategories.map(category => {
      return {
        category: category,
        techPointCategory: this.findTechPointCategory(categories, category)
      }
    });
  }

  private loadTimeSlots(workingHours: TechnicalPointWorkingHour[] | null, workingHoursType: string | null) {
    let daysTimeSlots = [];
    let timeSlots = this.getTimeConstraintsByType(workingHoursType);

    if (timeSlots == null) {
      return [];
    }


    for (let day of DAYS_OF_THE_WEEK) {
      let slots = timeSlots[day.code] || [];
      
      if (slots.length == 0) {
        continue;
      } 

      daysTimeSlots.push({
        day: day,
        timeSlot: null,
        techPointTimeSlot: null
      });

      slots.map(slot => {
        let wk = this.findTechPointWorkingHour(workingHours, slot)

        return {
          day: null,
          timeSlot: slot,
          techPointTimeSlot: wk
        }
      }).forEach(result => daysTimeSlots.push(result));
    }

    return daysTimeSlots;
  }

  private getTimeConstraintsByType(type: string | null): {[key: number]: TimeConstraint[]} {
    switch(type) {
      case 'INTERVAL_30_TYPE_1': 
        return this.timeConstrains.INTERVAL_30_TYPE_1;
      case 'INTERVAL_30_TYPE_2':
        return this.timeConstrains.INTERVAL_30_TYPE_2;
      case 'INTERVAL_15_TYPE_1':
        return this.timeConstrains.INTERVAL_15_TYPE_1;
      case 'INTERVAL_20_TYPE_1':
      	return this.timeConstrains.INTERVAL_20_TYPE_1;
      case 'INTERVAL_20_TYPE_2':
          return this.timeConstrains.INTERVAL_20_TYPE_2;
      case 'INTERVAL_20_TYPE_3':
          return this.timeConstrains.INTERVAL_20_TYPE_3;
      case 'INTERVAL_30_TYPE_3': 
          return this.timeConstrains.INTERVAL_30_TYPE_3;
      case 'INTERVAL_30_TYPE_4': 
          return this.timeConstrains.INTERVAL_30_TYPE_4;
      case 'INTERVAL_30_TYPE_5': 
          return this.timeConstrains.INTERVAL_30_TYPE_5;
      default:
        return []
    }
  }

  private sendSelectedCategories() {
    let categories:RvsCategory[] = [];

    for (let i = 0; i < this.linesForms.length; i++) {
      if (!this.linesForms.at(i).get('isValid')?.value) {
        continue;
      }

      let lineCategories = this.linesForms.at(i).get('categories')?.value as any[];
      lineCategories.filter(category => category.techPointCategory !== null && category.techPointCategory.isValid)
                    .forEach(category => categories.push(category.category));
    }

    this.selectedCategoriesSubject.next(categories.map(cat => cat.id));
  }

  private findTechPointCategory(categories: TechnicalPointCategory[] | null, category: RvsCategory) {
    if (categories === null || categories === undefined) return null;
    let result = categories?.find(techPointCategory => techPointCategory.rvsCategory.id === category.id);
    
    return result || null;
  }

  private findTechPointWorkingHour(techPointWorkingHours: TechnicalPointWorkingHour[] | null, timeSlot: TimeConstraint) {
    if (techPointWorkingHours == null) return null;
    let result = techPointWorkingHours?.find(techPointWorkingHour => techPointWorkingHour?.timeConstraint?.id === timeSlot?.id);
    
    return result || null;
  } 

  private initLines(lines: Line[]): void {
    if (lines == null) return
    lines.forEach(line => this.loadLine(line));
  }

  private checkForCategory(category: RvsCategory, ownLineIndex: number) {
    let result = false;

    for (let i = 0; i < this.linesForms.length; i++) {
      if (i === ownLineIndex) continue;

      result = this.linesForms.at(i)
                              .get('categories')?.value
                              .filter((cat: any) => cat.techPointCategory?.rvsCategory?.id === category.id).length > 0;
    } 

    return result;
  }

  private getValidLinesCount() {
    let result = 1;

    for (let line of this.linesForms.controls) {
      if (line.get('isValid')?.value) {
        result++;
      }
    }

    return result;
  }

  private sortTwoCategories(o1: RvsCategory, o2: RvsCategory) {
    return o1.id - o2.id;
  }

  get linesForms(): FormArray { 
    return this.linesForm.get('lines') as FormArray; 
  }

  get daysOfTheWeek() { 
    return DAYS_OF_THE_WEEK 
  }
}
