import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Page } from '@interfaces/page';
import { FormGroup } from '@angular/forms';
import { ListStatisticsInspections } from '../_models/list-statistics-inspections.model';
import { SortingPagingData } from '@helpers/sorting-paging-data';

const statisticsInspectionsURL = environment.apiBaseURL + '/statistics-inspections';

const pageableTotalElements         = statisticsInspectionsURL + '/pageable/count';
const pageableStatisticsInspections = statisticsInspectionsURL + '/pageable';

@Injectable({
  providedIn: 'root'
})
export class StatisticsInspectionsService {
  
  constructor(private http: HttpClient) { }

  public pageable(sortingPaging: SortingPagingData, searchForm: any, fromDate: string, toDate: string) {
    return forkJoin([
      this.findAllByFilter(sortingPaging, searchForm, fromDate, toDate),
      this.countAllByFilter(sortingPaging, searchForm, fromDate, toDate)
    ]);
  }

  public findAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<Page<ListStatisticsInspections>> {
    return this.http.get<Page<ListStatisticsInspections>>(pageableStatisticsInspections, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate) });
  }

  public countAllByFilter(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string): Observable<number> {
    return this.http.get<number>(pageableTotalElements, { params: this.createSearchHttpRequestParams(sortingPaging, searchForm, fromDate, toDate )});
  }

  private createSearchHttpRequestParams(sortingPaging: SortingPagingData, searchForm: FormGroup, fromDate: string, toDate: string) {
    let requestParams = new HttpParams();

    if (sortingPaging != null) {
      requestParams = requestParams.set("page", sortingPaging.getPageNumber());
      requestParams = requestParams.set("pageSize", sortingPaging.getPageSize());
    }
    if (sortingPaging.isSortingValid()) {
      requestParams = requestParams
        .set("sortBy", sortingPaging.sortBy)
        .set("sortAsc", sortingPaging.sortAsc === null ? 'null' : (sortingPaging.sortAsc ? 'true' : 'false'));
    }
    Object.entries(searchForm).forEach(item => {
      if (item[1] && item[1].length !== 0) {
        requestParams = requestParams.set(item[0], item[1]);
      }
    });
    requestParams = this.filterSubjects(requestParams, searchForm, fromDate, toDate);

    return requestParams;
  }

  filterSubjects(requestParams: HttpParams, searchForm : any, fromDate: string, toDate: string){
    if (searchForm.tp != null) {
      requestParams = requestParams.set('fullName', searchForm.tp.fullName);
    }
    if (searchForm.techPointMobileAppId != null) {
      requestParams = requestParams.set('techPointMobileAppId', searchForm.techPointMobileAppId);
    }

    if(fromDate && fromDate.length !== 0) {
      requestParams = requestParams.set("dateFrom", fromDate);
     }

    if(toDate && toDate.length !== 0) {
        requestParams = requestParams.set("dateTo", toDate);
    }

    return requestParams;
  }

}
