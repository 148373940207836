import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UtilValidators } from '@app/_validators/util.validators';
import { BaseSearchComponent } from '@components/_base/base-search/base-search.component';
import { City } from '@models/city.model';
import { ListSubjectVersion } from '@models/list-subject-version.model';
import { SubjectType } from '@models/subject-type.model';
import { TranslateService } from '@ngx-translate/core';
import { NomenclatureService } from '@services/nomenclature.service';
import { SubjectVersionService } from '@services/subject-version.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, repeatWhen, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-person',
  templateUrl: './add-edit-person.component.html',
  styleUrls: ['./add-edit-person.component.css']
})
export class AddEditPersonComponent extends BaseSearchComponent<ListSubjectVersion> implements OnInit, OnDestroy {
  // core
  subjectTypes: SubjectType[];
  cities:       City[];

  // booleans
  showValidations = false;
  
  // payload
  subjectVersion: any;
  reset:          boolean;

  // decorators
  @Input() addSection: boolean;
  @Input() editState: boolean;
  @Output() selectSubjectEvent = new EventEmitter<any>();
  @Output() stateEvent = new EventEmitter<boolean>();

  // forms
  subjectVersionMainForm = this.formBuilder.group({
    id:                     [''],
    fullName:               ['', [Validators.required, Validators.maxLength(120)]],
    address:                ['', [Validators.required, Validators.maxLength(120)]],
    city:                   ['', Validators.required],
    type:                   ['', Validators.required],
    identNum:               ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
    manager:                ['', [Validators.required, Validators.maxLength(120), Validators.pattern('^[а-яА-Яa-zA-Z ]*$')]],
    invoiceIdentNum:        ['', [Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
    invoiceDdsRegistration: ['', Validators.maxLength(15)],
    invoiceFullName:        ['', [Validators.maxLength(120)]],
    invoiceManager:         ['', [Validators.maxLength(120), Validators.pattern('^[а-яА-Яa-zA-Z ]*$')]],
    invoiceFullAddress:     ['', Validators.maxLength(120)],
    bankAccount:            [''],
    versionData:            ['']
  });

  subjectVersionBankAccountForm = this.formBuilder.group({
    id:          [''],
    bankName:    ['', [Validators.maxLength(120), Validators.pattern('^[а-яА-Яa-zA-Z0-9 ]*$')]],
    iban:        ['', [Validators.maxLength(35), Validators.pattern('^[A-Z0-9 ]*$')]],
    bic:         ['', [UtilValidators.validateBicLength(), Validators.pattern('^[A-Z ]*$')]],
    description: ['', Validators.maxLength(120)]
  });

  // observables
  loadSubjectTypes$ = this.subjectVersionService.getSubjectVersionPageableNomenclacutes().pipe(
    tap(([subjectTypes]) => {
      this.subjectTypes = subjectTypes;
      this.searchSubject.next();
    }),
    catchError(_err => {
      this.errorMessageSubject.next(this.translateService.instant("messages.errorLoadingData"));
      return EMPTY;
    }),
    repeatWhen(() => this.reload$)
  )

  // subjects
  errorMessageSubject = new Subject<string>();
  errorMessage$ = this.errorMessageSubject.asObservable();

  reloadSubject = new Subject();
  reload$ = this.reloadSubject.asObservable();

  showValidationsSubject = new BehaviorSubject<boolean>(false);
  showValidations$ = this.showValidationsSubject.asObservable();

  // subscriptions
  showValidationsSubscription:      Subscription;
  showFullValidationsSubscription:  Subscription;

  constructor(
    private subjectVersionService:  SubjectVersionService, 
    private formBuilder:            FormBuilder,
    private translateService:       TranslateService,
    private toastr:                 ToastrService,
    private nomenclatureService:    NomenclatureService) {
    super();
  }

  ngOnInit() {
    this.loadSubjectVersionCities();
    this.showValidationsSubscription = this.showValidations$.subscribe({
      next: (data) => this.showValidations = data
    });

    this.subjectVersionMainForm.get('fullName')?.valueChanges.subscribe((fullName) => {
      this.subjectVersionMainForm.get('invoiceFullName')?.patchValue(fullName);
    });

    this.subjectVersionMainForm.get('identNum')?.valueChanges.subscribe((identNum) => {
      this.subjectVersionMainForm.get('invoiceIdentNum')?.patchValue(identNum);
    });

    this.subjectVersionMainForm.get('address')?.valueChanges.subscribe((address) => {
      this.subjectVersionMainForm.get('invoiceFullAddress')?.patchValue(address);
    });
  }

  ngOnDestroy() { }

  loadSubjectVersionCities() {
    this.nomenclatureService.getCities().subscribe(cities => {
      this.cities = cities;
    });
  }

  initialize(subjectVersionId: number, versionDataId: number) {
    this.subjectVersionService.getActualSubjectVersion(subjectVersionId, versionDataId).pipe(
      tap((subject)=> {
        this.subjectVersionMainForm.patchValue(subject);
        this.subjectVersionBankAccountForm.patchValue(subject.bankAccount);
        this.subjectVersion = subject;
        this.addSection = false;
      }),
      catchError(err => {
        console.log(err)
        this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
        return EMPTY;
      }),
      repeatWhen(() => this.reload$)
    ).subscribe();
  }

  save() {
    if (!this.validate()) {
      this.showValidationsSubject.next(true);
      this.toastr.error(
        this.translateService.instant('toast.validationError'),
        this.translateService.instant('toast.errorTitle')
      );
      return;
    }
    
    this.subjectVersionService.createSubjectVersion(this.constructSubjectVersion()).subscribe({
      next: id => {
        this.editState = false;
        this.stateEvent.emit(this.editState);
        this.reset == true;
        this.clear();
        this.addSection = true;

        this.toastr.success(
          this.translateService.instant('toast.successTitle'),
          this.translateService.instant('toast.successfulSave')
        );
      }, 
    });
  }

  private validate() {
    let formValid = true;
    
    if (!this.subjectVersionMainForm.valid) {
      formValid = false;
    }
    if (!this.subjectVersionBankAccountForm.valid) {
      formValid = false;
    }

    return formValid;
  }

  async selectSubject() {
    if (!this.validate()) {
      this.showValidationsSubject.next(true);
      this.toastr.error(
        this.translateService.instant('toast.validationError'),
        this.translateService.instant('toast.errorTitle')
      );
      return;
    }

    if (this.subjectVersionMainForm.dirty || this.subjectVersionBankAccountForm.dirty) {
      await this.subjectVersionService.saveSelectedSubjectVersion(this.constructSubjectVersion()).then(
        id => {
           this.subjectVersionMainForm.get('id')?.patchValue(id);
           this.selectSubjectEvent.emit(this.constructSubjectVersion()); 
           this.editState = false;
           this.stateEvent.emit(this.editState);
           this.reset == true;
           this.clear();
           this.addSection = true;
   
           this.toastr.success(
             this.translateService.instant('toast.successTitle'),
             this.translateService.instant('toast.successfulSave')
           );  
         }, 
       ); 
    } else {
      this.subjectVersionMainForm.get('id')?.patchValue(this.subjectVersion.id);
      this.selectSubjectEvent.emit(this.constructSubjectVersion()); 
      this.editState = false;
      this.stateEvent.emit(this.editState);
      this.reset == true;
      this.clear();
      this.addSection = true;
    }
  }

  private constructSubjectVersion() {
    let subjectData = this.subjectVersionMainForm.value;
    let bankData = this.subjectVersionBankAccountForm.value;
    
    return {
      id:                     subjectData.id                           || null,
      fullName:               subjectData.fullName                     || null,
      identNum:               subjectData.identNum                     || null,
      type:                   subjectData.type                         || null,
      city:                   subjectData.city                         || null,
      address:                subjectData.address                      || null,
      manager:                subjectData.manager                      || null,
      versionData:            subjectData.versionData                  || null,
      subject:                subjectData.subject                      || null,
      invoiceIdentNum:        subjectData.invoiceIdentNum              || null,
      invoiceDdsRegistration: subjectData.invoiceDdsRegistration       || null,
      invoiceFullName:        subjectData.invoiceFullName              || null,
      invoiceManager:         subjectData.invoiceManager               || null,
      invoiceFullAddress:     subjectData.invoiceFullAddress           || null,
      phoneNumber:            subjectData.phoneNumber                  || null,
      mobileAppId:            subjectData.mobileAppId                  || null,
      bankAccount:            this.constructBankAccounts(bankData)    
    }
  }

  private constructBankAccounts(bankData: any) {
    return {
      id:             bankData.id           || null,
      bankName:       bankData.bankName     || null,
      iban:           bankData.iban         || null,
      bic:            bankData.bic          || null,
      description:    bankData.description  || null
    } 
  };

  clear() {
    this.showValidationsSubject.next(false);
    this.editState = false;
    this.subjectVersionMainForm.reset();
    this.subjectVersionBankAccountForm.reset();
  }

  get subjectVersionForm() { return this.subjectVersionMainForm.controls; }
  
  get bankAccountForm() { return this. subjectVersionBankAccountForm.controls; }

}
