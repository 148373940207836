import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerService } from '@app/customers/_services/customer.service';
import { BaseChildComponent } from '@components/_base/base-child/base-child.component';

@Component({
  selector: 'app-client-profile-notifications-history',
  templateUrl: './client-profile-notifications-history.component.html',
  styleUrls: ['./client-profile-notifications-history.component.css']
})
export class ClientProfileNotificationsHistoryComponent extends BaseChildComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private translateService: TranslateService
  ) {
    super();
   }

  ngOnInit(): void { }

}
