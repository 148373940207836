<div class="h-100 d-flex flex-column">
    <div class="container">
        <div class="row justify-content-center align-items-center" style="height:100vh">
            <div class="col-4">
                <div class="card">
                    <div class="card-header" translate>auth.changePassword</div>
                    <div class="card-body">
                        <form [formGroup]="changePassForm" (ngSubmit)="onSubmit()" class="d-flex flex-column h-100">
                            <div class="form-group">
                                <label for="password" translate>auth.newPass</label>
                                <input type="password" formControlName="newPass" class="form-control"
                                    [ngClass]="{ 'is-invalid': showValidation && form.newPass.errors }" />
                                <div class="invalid-feedback" *ngIf="showValidation && form.newPass.errors?.required" translate>errors.required</div>
                            </div>

                            <div class="form-group">
                                <label for="password" translate>auth.repeatPass</label>
                                <input type="password" formControlName="repeatPass" class="form-control"
                                    [ngClass]="{ 'is-invalid': showValidation && form.repeatPass.errors }" />
                                <div class="invalid-feedback" *ngIf="showValidation && form.repeatPass.errors?.required" translate>errors.required</div>
                                <div class="invalid-feedback" *ngIf="showValidation && form.repeatPass.errors?.nonMachingPass" translate>errors.passwordsMustMatch</div>
                            </div>
            
                            <div class="d-flex justify-content-between mt-auto pt-3">
                                <button type="submit" [disabled]="loading" class="btn btn-sm btn-primary" translate>button.save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>