<div *ngIf="isInitialized; else cardsLoading">
<div class="card-body">
  <div  *ngFor="let subscription of customerSubscriptions let i=index"  class="card-body">
    <div class="card">
      <div class="d-flex">
        <div class="container pt-2 px-3">
          <div class="row">
            <div class="col-md-5 col-lg-4 d-flex flex-column">
              <b translate>clientProfile.cardName</b>
              <label>{{subscription.name}}</label>
            </div>
            <div class="col-md-5 col-lg-4 d-flex flex-column">
              <b translate>clientProfile.cardRegNumber</b>
              <label>{{subscription.customerRvs?.regNumber}}</label>
            </div>
            <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.validFrom</b>
                <label>{{subscription.validFrom}}</label>
              </div>
              <div class="col-md-6 col-lg-4 d-flex flex-column">
                  <b for="cardValidТо" translate>clientProfile.validTo</b>
                  <label>{{subscription.validTo}}</label>
                </div>
          </div>
        </div>
        <div class="col-sm-1 col-lg-1 d-flex justify-content-center">
          <i class="fa fa-eye"></i>
        </div>
      </div>
      <hr class="mt-0 mb-1">
      <div class="card-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <a data-toggle="collapse" href="#cardsSectionContent{{i}}" aria-expanded="true" 
              aria-controls="cardSection" style="font-size: 17px;" translate>clientProfile.cardServices</a>
            </div>
          </div>
        <div id="cardsSectionContent{{i}}" class="collapse hidden" role="tabpanel" aria-labelledby="cardSection">
          <div class="row">
            <div class="col">
              <table class="table" >
                <thead>
                  <tr>
                    <th translate>clientProfile.cardServiceName</th>
                    <th translate>clientProfile.cardServiceStatus</th>
                  </tr>
                </thead>
                <tbody *ngFor="let subsService of subscription.customerSubscriptionServices">
                  <tr>
                    <td >{{subsService.serviceAddInfoItem.childService?.name}}</td>
                    <td *ngIf="subsService?.isAvailable" translate>clientProfile.available</td>
                    <td *ngIf="!subsService?.isAvailable" translate>clientProfile.unavailable</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<ng-template #cardsLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else cardsSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #cardsSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>