import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { RegisterOfLegalPersonsModalComponent } from "@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { modalMinWidth } from "@env/config";
import { ConsiceSubjectVersion } from "@models/consice-subject-version.model";
import { SubjectVersion } from "@models/subject-version.model";
import { SubjectVersionService } from "@services/subject-version.service";

@Component({
    selector: 'contract-subjects',
    templateUrl: './contract-subjects.component.html',
    styleUrls: ['./contract-subjects.component.css']
})
export class ContractSubjectsComponent extends BaseChildComponent implements OnInit, OnDestroy {
    // Form
    subjectsForm: FormGroup;
    
    // Decorators
    @Input() ownerSubjectVersionId:    			number;
    @Input() contractSubjectVersionId: number;
    @Input() module: 		                  string;

    constructor(private formBuilder: FormBuilder,
        private subjectVersionService: SubjectVersionService,
        private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.subjectsForm = this.formBuilder.group({
            ownerSubjectVersion: this.formBuilder.group({
                id:             [null, Validators.required],
                identNum:       { value: null, disabled: true },
                fullName:       { value: null, disabled: true },
                versionData:    { value: null, disabled: true},
                bankAccount: this.formBuilder.group({
                    bankName:       { value: null, disabled: true },
                    bic:            { value: null, disabled: true },
                    iban:           { value: null, disabled: true }
                })
            }),
            contractSubjectVersion: this.formBuilder.group({
                id:             [null, Validators.required],
                identNum:       { value: null, disabled: true },
                fullName:       { value: null, disabled: true },
                versionData:    { value: null, disabled: true},
                bankAccount: this.formBuilder.group({
                    bankName:       { value: null, disabled: true },
                    bic:            { value: null, disabled: true },
                    iban:           { value: null, disabled: true }
                })
            }),
        });
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    openModal(formGroup: string) {
        if (this.dialog.openDialogs.length == 0) {
            let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
            ref.componentInstance.submitSubject.subscribe(result => {
                      this.setSubjectData(result, formGroup);
            });
    
            let subjectId = this.subjectsForm.get(formGroup)?.get("id")?.value;
            let subjectVersionData = this.subjectsForm.get(formGroup)?.get("versionData")?.value;
    
            if (subjectId != null) {
                ref.componentInstance.selectSubject(subjectId, subjectVersionData.id);
            } 
        }
    }

    private setSubjectData(subject: SubjectVersion | ConsiceSubjectVersion | undefined, formGroup: string) {
        if (subject == null) return;
        
        if (this.isClientContract()) {
            this.subjectsForm.get(formGroup)?.patchValue(subject);
        } else {
            this.subjectVersionService.getSubjectVersionById(subject.id).subscribe((response: SubjectVersion) => {
                this.subjectsForm.get(formGroup)?.patchValue(response);
            });
        }

    }

    async getSubjectVersions() {
        if (this.isInitialized) return;
        this.isInitialized = true;

        if (!!this.ownerSubjectVersionId) {
            let ownerSubjectVersionRes = await this.subjectVersionService.getSubjectVersionById(this.ownerSubjectVersionId).toPromise();
            this.subjectsForm.patchValue({ ownerSubjectVersion: ownerSubjectVersionRes });
        }
        if (!!this.contractSubjectVersionId) {
            let contractubjectVersionRes = await this.subjectVersionService.getSubjectVersionById(this.contractSubjectVersionId).toPromise();
            this.subjectsForm.patchValue({ contractSubjectVersion: contractubjectVersionRes });
        }
    }

    public isClientContract() {
        return this.module == 'client';
    }

    get contractSubjectVersion() {
        return this.subjectsForm.get('contractSubjectVersion') as FormGroup;
    }

    get ownerSubjectVersion() {
        return this.subjectsForm.get('ownerSubjectVersion') as FormGroup;
    }

}