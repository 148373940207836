<div class="form-group form-row" [formGroup]="customerDiscountForm">
    <div class="col">
        <label translate>contract.discountType</label>
        <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="customerDiscount"
            [ngClass]="{ 'is-invalid': showValidations && form.customerDiscount.errors }">
            <option *ngFor="let discount of discounts" [ngValue]="discount">
                {{discount.discountName}}
        </select>
        <small class="text-danger" *ngIf="form.customerDiscount?.errors?.required && showValidations" translate>
            errors.required
        </small>
    </div>
    <div class="col">
        <label translate>contract.value</label>
        <input type="text" class="form-control" formControlName="priceAttribute">
    </div>
</div>