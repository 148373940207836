<div class="container my-2 py-2 px-3">
    <h1 class="form-group title-position" translate>techPoint.techPoint</h1>
    <form [formGroup]="techPointMainForm" *ngIf="loadTechnicalPoint$ | async as data; else loading">
        <div id="mainContent" role="tablist" aria-multiselectable="true">
            <!-- main section data -->
            <div class="card">
                <div class="card-header" role="tab" id="mainSection">
                    <h5 class="mb-0">
                        <a data-toggle="collapse" data-parent="#mainContent" href="#mainSectionBody" 
                            aria-expanded="true" aria-controls="mainSection" translate>
                            techPoint.mainSection
                        </a>
                    </h5>
                </div>
                <div id="mainSectionBody" class="collapse show" role="tabpanel" aria-labelledby="mainSection">
                    <div class="card-body">
                        <div class="container-fluid">
                            <!-- names -->
                            <div class="row">
                                <!-- name -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="nameInput" translate>techPoint.name</label>
                                    <input class="form-control" id="nameInput" type="text" placeholder="{{'placeholder.enter' | translate}}"
                                        formControlName="name" [ngClass]="{ 'is-invalid': showValidations && mainForm.name.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.name?.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.name?.errors?.maxlength && showValidations">{{"errors.inputTooLong" | translate: {max: 120} }}</div>
                                </div>

                                <!-- short name -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="shortNameInput" translate>techPoint.shortName</label>
                                    <input class="form-control" id="shortNameInput" type="text" placeholder="{{'placeholder.enter' | translate}}"
                                        formControlName="shortName" [ngClass]="{ 'is-invalid': showValidations && mainForm.shortName.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.shortName?.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.shortName?.errors?.maxlength && showValidations" translate>{{"errors.inputTooLong" | translate: {max: 60} }}</div>
                                </div>
                            </div>

                            <!-- location -->
                            <div class="row">
                                <div class="col d-flex">
                                    <h5 translate>techPoint.location</h5>
                                    <p class="text-danger ml-2" translate
                                    *ngIf="(mainForm.lat?.errors?.required || mainForm.lng?.errors?.required) && showValidations">errors.locationNotChosen</p>
                                </div>
                            </div>

                            <!-- lat and lng -->
                            <div class="row">
                                <div class="form-group col-md-6 col-lg-4 d-flex flex-column">
                                    <label translate>techPoint.lat</label>
                                    <label>{{mainForm?.lat?.value}}</label>
                                </div>
                                <div class="form-group col-md-6 col-lg-4 d-flex flex-column">
                                    <label translate>techPoint.lng</label>
                                    <label>{{mainForm?.lng?.value}}</label>
                                </div>
                                <div class="form-group col-md-6 col-lg-4">
                                    <button class="btn btn-primary mt-4" type="button" (click)="openMap()" translate>techPoint.chooseFromMap</button>
                                </div>
                            </div>

                            <!-- map -->
                            <div class="row mb-2" *ngIf="showMap$ | async">
                                <div class="col w-100 mb-3" style="height: 500px;">
                                    <input id="addressTypeahead" type="text" class="form-control mb-2" [editable]='false' 
                                            [value]="addressTypeaheadValue" (selectItem)="onAddressSelect($event)"
                                            [ngbTypeahead]="addressSearch" placeholder="{{'placeholder.searchAddress' | translate}}" [resultTemplate]="rt"/>
                                    <ng-template #rt let-r="result" let-t="term">
                                        <ngb-highlight [result]="r.display_name" [term]="t"></ngb-highlight>
                                    </ng-template>
                                    <app-map #map [point]="currentPoint" (mapClickEvent)="setPoint($event)" ></app-map>
                                </div>
                            </div>

                            <!-- city and address -->
                            <div class="row">
                                <!-- city -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="cityInput" translate>techPoint.city</label>
                                    <select class="custom-select input-custom-size" [compareWith]="compareByCode" 
                                            formControlName="city" [ngClass]="{ 'is-invalid': showValidations && mainForm.city.errors }">
                                        <option value="" translate>placeholder.select</option>
                                        <option *ngFor="let city of cities" [ngValue]="city"> {{city.name}}
                                    </select>
                                    <div class="invalid-feedback" *ngIf="mainForm.city?.errors?.required && showValidations" translate>errors.required</div>
                                </div>

                                <!-- address -->
                                <div class="form-group col-md-6 col-lg-8">
                                    <label for="addressInput" translate>techPoint.address</label>
                                    <input class="form-control" id="addressInput" type="text" placeholder="{{'placeholder.enter' | translate}}"
                                        formControlName="address" [ngClass]="{ 'is-invalid': showValidations && mainForm.address.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.address?.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.address?.errors?.maxlength && showValidations" translate>{{"errors.addressToLong" | translate: {max: 2048} }}</div>
                                </div>
                            </div>

                            <!-- contact data -->
                            <div class="row">
                                <!-- email -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="emailInput" translate>techPoint.email</label>
                                    <input class="form-control" type="text" id="emailInput" placeholder="{{'placeholder.enter' | translate}}"
                                        formControlName="email" [ngClass]="{ 'is-invalid': showValidations && mainForm.email.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.email?.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.email?.errors?.email && showValidations" translate>errors.mailInvalid</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.email?.errors?.maxlength && showValidations" translate>{{"errors.inputTooLong" | translate: {max: 60} }}</div>
                                </div>

                                <!-- phone -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <div>
                                        <label for="phoneInput" class="mr-2" translate>techPoint.phone</label>
                                    </div>
                                    <input class="form-control" id="phoneInput" type="text" placeholder="{{'placeholder.enter' | translate}}"
                                        formControlName="phone" [ngClass]="{ 'is-invalid': showValidations && mainForm.phone.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.phone?.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.phone?.errors?.invalidPhoneNumber && showValidations" translate>errors.phoneInvalid</div>
                                </div>

                                <!-- status -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label translate>techPoint.status</label>
                                    <select class="custom-select input-custom-size" [compareWith]="compareById" formControlName="status">
                                        <option *ngFor="let status of statuses" [ngValue]="status"> 
                                            {{status.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- permit and invoice number -->
                            <div class="row">
                                <!-- permit number -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label translate>techPoint.permitNumber</label>
                                    <input class="form-control" id="permitNumberInput" type="text" placeholder="{{'placeholder.enter' | translate}}"
                                            formControlName="permitNumber" [ngClass]="{ 'is-invalid': showValidations && mainForm.permitNumber.errors }">
                                            <div class="invalid-feedback" *ngIf="mainForm.permitNumber?.errors?.required && showValidations" translate>errors.required</div>
                                            <div class="invalid-feedback" *ngIf="mainForm.permitNumber?.errors?.max && showValidations" translate>errors.permitNumberInvalid</div>
                                            <div class="invalid-feedback" *ngIf="mainForm.permitNumber?.errors?.min && showValidations" translate>errors.onlyPositiveNumbers</div>
                                            <div class="invalid-feedback" 
                                                 *ngIf="mainForm.permitNumber?.errors?.pattern && showValidations && !mainForm.permitNumber?.errors?.min" translate>errors.onlyDigits</div>
                                </div>
                                
                                <!-- <div class="form-check col-md-6 col-lg-6">
                                    <div class="ml-3 mt-4">
                                        <input class="form-check-input" type="checkbox" formControlName="isAddServicesTimeCalc">
                                        <label translate>techPoint.addedTime</label>
                                    </div>
                                </div> -->

                                <!-- invoice number -->
                                <div class="form-group col-md-6 col-lg-4">
                                    <label translate>techPoint.invoiceNumber</label>
                                    <input class="form-control" id="invoiceNumberInput" type="text" placeholder="{{'placeholder.enter' | translate}}"
                                            formControlName="invoiceNumber" [ngClass]="{ 'is-invalid': showValidations && mainForm.invoiceNumber.errors }">
                                    <div class="invalid-feedback" *ngIf="mainForm.invoiceNumber?.errors?.required && showValidations" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.invoiceNumber?.errors?.minlength && showValidations">{{'errors.invalidInputLength' | translate: {length: 10} }}</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.invoiceNumber?.errors?.maxlength && showValidations">{{'errors.invalidInputLength' | translate: {length: 10} }}</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.invoiceNumber?.errors?.invalidNumber && showValidations" translate>error.invoiceNumberTaken</div>
                                    <div class="invalid-feedback" *ngIf="mainForm.invoiceNumber?.errors?.noOwnerSelected && showValidations" translate>error.noSubjectSelected</div>
                                </div>
                            </div>

                            <!-- error subject req -->
                            <div class="row">
                                <div class="col">
                                    <p class="text-danger ml-2" translate *ngIf="mainForm.subjectId?.errors?.required && showValidations">errors.subjectRequired</p>
                                </div>
                            </div>

                            <!-- choose subject -->
                            <div class="row">
                                <div class="form-group col-lg-4">
                                    <button class="btn btn-primary mt-4" type="button" (click)="openModal()" translate>techPoint.chooseSubject</button>
                                </div>
                                <div class="flex-column d-flex col-md-6 col-lg-4">
                                    <label translate>techPoint.subjectName</label>
                                    <label>{{mainForm.subjectName.value}}</label>
                                </div>
                                <div class="flex-column d-flex col-md-6 col-lg-4">
                                    <label translate>techPoint.subjectEIK</label>
                                    <label >{{mainForm.subjectIdentNum.value}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- lines -->
            <div class="card">
                <div class="card-header" role="tab" id="linesSection">
                    <h5 class="mb-0">
                        <a data-toggle="collapse" data-parent="#linesSection" href="#linesSectionBody"
                            aria-expanded="true" aria-controls="linesSection" (click)="initializeSection('lines')" translate>
                            techPoint.linesSection
                        </a>
                    </h5>
                </div>
                <div id="linesSectionBody" class="collapse" [ngClass]="{'show': linesComponentState}" role="tabpanel" aria-labelledby="linesSection">
                    <div class="card-body">
                        <div>
                            <app-technical-point-lines #lines [showValidations$]="showFullValidation$" 
                                                       [selectedCategoriesSubject]="selectedCategoriesSubject"
                                                       [parentId]="techPointId"
                                                       (formChangeEmmiter)="onChangeForm()">
                            </app-technical-point-lines>
                        </div>
                    </div>
                </div>
            </div>
            <!-- services -->
            <div class="card">
                <div class="card-header" role="tab" id="servicesSection">
                    <h5 class="mb-0">
                        <a data-toggle="collapse" data-parent="#linesSection" href="#servicesSectionBody" 
                            aria-expanded="true" aria-controls="servicesSection" (click)="initializeSection('services')" translate>
                            techPoint.servicesSection
                        </a>
                    </h5>
                </div>
                <div id="servicesSectionBody" class="collapse" [ngClass]="{'show': servicesComponentState}" role="tabpanel" aria-labelledby="servicesSection">
                    <div class="card-body">
                        <app-technical-point-services #services [showValidations$]="showFullValidation$"
                                                      [selectedCategories$]="selectedCategories$" 
                                                      [selectedCategoriesSubject]="selectedCategoriesSubject"
                                                      [parentId]="techPointId"
                                                      (formChangeEmmiter)="onChangeForm()">
                        </app-technical-point-services> 
                    </div>
                </div>
            </div>
        </div>

        <!-- save btn -->
        <div class="d-flex flex-row-reverse mt-2" *ngIf="!isSaving; else saveLoading">
            <button class="btn btn-primary" type="button" (click)="onSubmit()" *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_POINT)" [attr.disabled]="isUnchanged" translate>button.save</button>
        </div>

        <ng-template #saveLoading>
            <div class="d-flex flex-row-reverse mt-2">
                <div class="spinner-border mr-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    </form>

    <ng-template #loading>
        <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
            <div class="alert alert-danger">
                {{errorMessage}}
            </div>
            <div class="d-flex justify-content-center">
                <i class="fa fa-refresh" (click)="reload()"></i>
            </div>
        </div>
        <ng-template #spinner>
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>