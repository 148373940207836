<div *ngIf="isInitialized; else rvsLoading">
<div class="card-body">
    <div *ngFor="let rvs of customerRvs" class="card-body">
      <hr class="mt-0 mb-1">
      <div class="container-fluid">
        <div class="row">
          <div class="col mt-2" >
            <p style="font-size: 1.2em" translate>clientProfile.carMainData</p>
            <hr class="w-85">
          </div>
        </div>
        <div class="d-flex">
          <div class="container pt-2 px-1">
            <div class="row">
              <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.carNumber</b>
                <label> {{rvs.regNumber}}</label>
              </div>
              <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.carCouponNumber</b>
                <label>{{rvs.registrationCertNumber}}</label>
              </div>
              <div class="col-md-5 col-lg-3 d-flex flex-column">
                <b translate>clientProfile.carNickname</b>
                <label>{{rvs.nickname}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.carGTPValidUntil</b>
                <label *ngFor="let iaaaInspection of rvs.iaaaInspections">{{iaaaInspection.validTo}}</label>
              </div>
              <div class="col-md-5 col-lg-4 d-flex flex-column">
                <b translate>clientProfile.carLIValidUntil</b>
                <label *ngFor="let insurance of rvs.insurances" >{{insurance.validTo}}</label>
              </div>
              <div class="col-md-5 col-lg-3 d-flex flex-column">
                <b translate>clientProfile.carVignetteValidUntil</b>
                <label *ngFor="let vignette of rvs.vignettes" >{{vignette.validTo}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-4">
            <p style="font-size: 1.2em" translate>clientProfile.GTPData</p>
            <hr class="w-85">
          </div>
        </div>
        <div *ngFor="let iaaaInspection of rvs.iaaaInspections">
        <div class="row">
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.validFrom</b>
            <label>{{iaaaInspection.validFrom}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.validTo</b>
            <label>{{iaaaInspection.validTo}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.GTPFutureCategory</b>
            <label>{{iaaaInspection.ecoCategoryNumber}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.place</b>
            <label>{{iaaaInspection.location}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.GTPNumber</b>
            <label>{{iaaaInspection.receivedSignNo}}</label>
          </div>
        </div>
    </div>
        <div class="row">
          <div class="col mt-2">
            <p style="font-size: 1.2em" translate>clientProfile.LIData</p>
            <hr class="w-85">
          </div>
        </div>
    <div *ngFor="let insurance of rvs.insurances">
        <div class="row">
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.validFrom</b>
            <label>{{insurance.validFrom}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.validTo</b>
            <label>{{insurance.validTo}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.place</b>
            <label>{{insurance.location}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.LIPolicySeries</b>
            <label>{{insurance?.serie}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.LIPolicyNumber</b>
            <label>{{insurance.number}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.LIInsurer</b>
            <label>{{insurance?.insurer}}</label>
          </div>
        </div>
    </div>
        <div class="row">
          <div class="col mt-2">
            <p style="font-size: 1.2em" translate>clientProfile.vignetteData</p>
            <hr class="w-85">
          </div>
        </div>
    <div *ngFor="let vignette of rvs.vignettes">
        <div class="row">
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.validFrom</b>
            <label>{{vignette.validFrom}}</label>
          </div>
          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <b translate>clientProfile.validTo</b>
            <label>{{vignette.validTo}}</label>
          </div>
        </div>
    </div>
        <div class="row" *ngIf="carImages.length != 0">
          <div class="col mt-2">
            <h4 translate>clientProfile.pictures</h4>
            <hr class="w-85">
          </div>
        </div>
        <div class="row" *ngFor="let carImage of carImages">
          <div class="col-md-6 mt-3 inline-block">
            <img *ngIf="carImage != undefined" [src]="carImage" style="max-width: 100%; max-height: 100%" class="image-fluid">
          </div> 
        </div>
        <div class="row" *ngIf="certificateImages.length != 0">
          <div class="col mt-3">
            <h4 translate>clientProfile.attachedDocs</h4>
            <hr class="w-85">
          </div>
        </div>
        <div class="row" *ngFor="let certificateImage of certificateImages">
          <div class="col-md-6 mt-3 inline-block">
            <img *ngIf="certificateImage != undefined" [src]="certificateImage" style="max-width: 100%; max-height: 100%" class="image-fluid">
          </div> 
        </div>
        <!-- <div class="row">
          <div class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" translate>clientProfile.attachedDocName</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Документ 1</td>
                  <td><i class="fa fa-eye"></i></td>
                </tr>
              </tbody>
            </table>  
          </div>
        </div> -->
      </div>
  </div>
 </div>
</div>
<ng-template #rvsLoading>
    <div *ngIf="errorMessage$ | async as errorMessage; else rvsSpinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #rvsSpinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>