import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContractTechnicalPointService } from "@app/contracts/_models/contract-technical-point-service.model";
import { ContractService } from "@app/contracts/_services/contract.service";
import { ServiceAddInfo } from "@app/service-configs/_models/service-add-info.model";
import { PRICE_ATTRIBUTE_KIND, PRICE_ATTRIBUTE_KINDS } from "@app/_enums/price-attribute-kind";
import { SERVICE_PAYMENT_TYPES } from "@app/_enums/service-payment-type";
import { BaseChildComponent } from "@components/_base/base-child/base-child.component";
import { ArrayValidators } from "@app/_validators/array.validator";
import { PRICE_ATTRIBUTE_TYPE } from "@models/price-attribute.model";
import { NomenclatureService } from "@services/nomenclature.service";
import { EMPTY, Subscription } from "rxjs";
import { catchError, repeatWhen, tap } from "rxjs/operators";
import { TechnicalPoint } from "@app/tech-points/_models/technical-point.model";
import { TechnicalPointService } from "@app/tech-points/_services/technical-point.service";
import { TechPointService } from "@app/tech-points/_models/tech-point-service.model";
import { config } from "@env/config";
import { TranslateService } from "@ngx-translate/core";
import { displayError } from "@app/_utils/error-util";

@Component({
    selector: 'partner-contract-services',
    templateUrl: './partner-contract-services.component.html',
    styleUrls: ['./partner-contract-services.component.css']
})

export class PartnerContractServicesComponent extends BaseChildComponent implements OnInit, OnDestroy {
    // Units
    contractTechPointServices: ContractTechnicalPointService[];

    // Constants
    readonly GTP_SERVICE_CODE       = 'GTP';
    readonly VAT_GROUPS             = [20, 9];
    readonly DEAFULT_VAT_GROUP      = 20;
    servicePaymentTypes             = SERVICE_PAYMENT_TYPES;
    priceAttributeKinds             = PRICE_ATTRIBUTE_KINDS;

    // Decorators
    @Input() module:                    string;
    @Input() contractId:                number;
    @Input() selectedTechnicalPoints:   TechnicalPoint[];

    // Form
    contractServicesForm: FormGroup;

    //Subscriptions
    servicesSubscription: Subscription;
    serviceAddInfoListSubscription: Subscription;

    constructor(
        private translateService: TranslateService,
        private formBuilder: FormBuilder,
        private contractService: ContractService,
        private technicalPointService: TechnicalPointService,
        private nomenclatureService: NomenclatureService) {
        super();
    };

    ngOnInit() {
        super.ngOnInit();
        this.contractServicesForm = this.formBuilder.group({
            rows: this.formBuilder.array([], [ArrayValidators.equalsToSomeGroupKey('isValid', true)])
        });
    };

    ngOnDestroy() {
        super.ngOnDestroy();
        this.servicesSubscription?.unsubscribe();
        this.serviceAddInfoListSubscription?.unsubscribe();
    }

    ngOnChanges(changes: any) {
        let rowsCtrl = <FormArray>this.contractServicesForm?.controls['rows']
        if (!!rowsCtrl) {
            if (!!changes.selectedTechnicalPoints) {
                this.clearRowValues();
            }
        }
    }

    async getContractServices() {
        if (this.isInitialized) return;
        this.isInitialized = true;

        if (!!this.contractId) {
            const response = await this.contractService.findContractTechnicalPointServices(this.contractId).toPromise();
            this.contractTechPointServices = response;

            let res: ServiceAddInfo[] = [];
            for await (const x of this.contractTechPointServices) {
                if (x.technicalPointService != null) {
                    res = await this.nomenclatureService.findAllValidServiceAddInfosByChildServiceId(x.technicalPointService.serviceMobileAppId || 0).toPromise();
                    if (x.serviceAddInfo != null && (!x.serviceAddInfo.service.isValid || !x.serviceAddInfo.versionData?.isActual)) {
                        res = res.filter(y => y.versionData?.firstVersion?.id != x.serviceAddInfo.versionData?.firstVersion?.id);
                        res.push(x.serviceAddInfo);
                    }
                }

                this.contractServicesForm = this.formBuilder.group({
                    rows: this.formBuilder.array(
                        this.contractTechPointServices.map(x =>
                            this.formBuilder.group({
                                id:                         x.id,
                                contractTechnicalPoint:     x.contractTechnicalPoint,
                                technicalPoint:             [x.technicalPointService?.technicalPointId, Validators.required],
                                technicalPointService:      [{ id: x.technicalPointService?.id }, Validators.required],
                                isValid:                    x.isValid,
                                paymentType:                x.paymentType,
                                servicePaymentType:         null,
                                techPointServices:          Array.of(x.techPointServices),
                                serviceAddInfo:             x.serviceAddInfo,
                                serviceAddInfoList:         Array.of(res),
                                priceAttribute: this.formBuilder.group({
                                    id:                          x.priceAttribute?.id,
                                    amount:                     [x.priceAttribute?.amount, Validators.required],
                                    amountDds:                  [x.priceAttribute?.amountDds, [Validators.required]],
                                    ddsValue:                   [{ value: x.priceAttribute?.ddsValue, disabled: x.priceAttribute?.kind == PRICE_ATTRIBUTE_KIND.PERCENTAGE }, Validators.required],
                                    type:                       PRICE_ATTRIBUTE_TYPE.PRICE,
                                    kind:                       x.priceAttribute?.kind
                                })
                            })
                        ), [ArrayValidators.equalsToSomeGroupKey('isValid', true)]
                    ),
                });

                let rowCtrl = (<FormArray>this.contractServicesForm.controls['rows']);
                rowCtrl.value.forEach((x: any, index: number) => {
                    if (x.priceAttribute?.kind == PRICE_ATTRIBUTE_KIND.PERCENTAGE) {
                        rowCtrl.at(index).get('priceAttribute')?.patchValue({ type: PRICE_ATTRIBUTE_TYPE.DISCOUNT })
                        rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.clearValidators();
                        rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.clearValidators();
                        rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.updateValueAndValidity();
                        rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.updateValueAndValidity();
                    }
                });
            }
        }
    }

    getTechPointServices(id: number, index: number): TechPointService[] {
        if (!!id) {
            this.servicesSubscription = this.technicalPointService.findServicesByTechPointId(id).pipe(
                tap(data => {
                    let rowsCtrl = <FormArray>this.contractServicesForm.controls['rows'];
                    let list = rowsCtrl.value.filter((e: any) => e.technicalPointService != null && e.isValid).map((x: any) =>
                        x.technicalPointService?.id)
                    data = data.filter(el =>
                        !list.includes(el.id)
                    );

                    rowsCtrl.at(index)
                        .patchValue({
                            techPointServices: data,
                            technicalPointService: null,
                            withoutDds: null,
                            finalAmount: null
                        });
                    return data;
                }),
                catchError(err => {
                    displayError(err);
                    this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                    return EMPTY;
                }),
                repeatWhen(() => this.reload$)
            ).subscribe()
        }
        return [];
    }

    addAll() {
        let contractServicesCtrl = <FormArray>this.contractServicesForm.controls['rows']

        if (!contractServicesCtrl.controls.some(x => x.value.isValid) && !!this.selectedTechnicalPoints) {
            this.selectedTechnicalPoints.forEach((x: TechnicalPoint) => {
                if (!!x.id) {
                    let techPointServices: TechPointService[];
                    this.servicesSubscription = this.technicalPointService.findServicesByTechPointId(x.id).pipe(
                        tap(data => {
                            techPointServices = data;
                            let techPointService = techPointServices.find(y => y.serviceCode == this.GTP_SERVICE_CODE);
                            if (!!techPointService) {
                                let serviceMobileAppId = techPointService.serviceMobileAppId
                                this.serviceAddInfoListSubscription = this.nomenclatureService.findAllValidServiceAddInfosByChildServiceId(serviceMobileAppId || 0)
                                    .subscribe(
                                        (response: ServiceAddInfo[]) => {
                                            this.populateRow(contractServicesCtrl, x, techPointService, techPointServices, response);

                                        });
                            } else {
                                this.populateRow(contractServicesCtrl, x, techPointService, techPointServices, []);
                            }
                        }),
                        catchError(err => {
                            displayError(err);
                            this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
                            return EMPTY;
                        }),
                        repeatWhen(() => this.reload$)
                    ).subscribe();
                }
            });
        }
    }

    addRow() {
        let contractServicesCtrl = <FormArray>this.contractServicesForm.controls['rows'];
        this.populateRow(contractServicesCtrl, null, null, null, null);
    }

    deleteRow(index: number) {
        let rowCtrl = <FormArray>this.contractServicesForm.controls['rows']
        let element = rowCtrl.at(index).value
        element.isValid = false;
        rowCtrl.at(index).patchValue(element);
        this.clearValidatorsAndUpdateValidity(rowCtrl, index);

        if (element.technicalPointService != null) {
            rowCtrl.value.forEach((row: any, rowIndex: number) => {
                if (rowIndex != index) {
                    if (row.techPointServices == null) {
                        row.techPointServices = [];
                    }
                    if (element.techPointServices != null) {
                        if (element.technicalPoint == row.technicalPoint) {
                            row.techPointServices.push(
                                element?.techPointServices.find((s: TechPointService) => s.id = element?.technicalPointService?.id)
                            );
                        }
                    }
                }
            });
        }
    }

    onTechPointSelect(event: any, index: number) {
        this.getTechPointServices(event.target.value, index);
    }

    onServiceSelect(event: any, index: number) {
        if (!!event) {
            let contractServicesCtrl = <FormArray>this.contractServicesForm.controls['rows']
            if (!!event.serviceMobileAppId) {
                this.serviceAddInfoListSubscription = this.nomenclatureService.findAllValidServiceAddInfosByChildServiceId(event.serviceMobileAppId)
                    .subscribe(
                        (response: ServiceAddInfo[]) => {
                            contractServicesCtrl.at(index).patchValue({ serviceAddInfoList: response });
                        });
            } else {
                contractServicesCtrl.at(index).patchValue({ serviceAddInfo: null });
                contractServicesCtrl.at(index).patchValue({ serviceAddInfoList: null });
                contractServicesCtrl.at(index).get('priceAttribute')?.patchValue({ serviceAddInfo: null });
                contractServicesCtrl.at(index).get('priceAttribute')?.patchValue({ amount: null });
                contractServicesCtrl.at(index).get('priceAttribute')?.patchValue({ amountDds: null });
                contractServicesCtrl.at(index).get('priceAttribute')?.patchValue({ ddsValue: null });
                contractServicesCtrl.at(index).get('priceAttribute')?.patchValue({ kind: null });
            }
            contractServicesCtrl.value.forEach((row: any, rowIndex: number) => {
                if (rowIndex != index) {
                    contractServicesCtrl.at(rowIndex).patchValue({
                        techPointServices: row.techPointServices?.filter((service: TechPointService) => service.id != event.id)
                    })
                }
            });
            //find and add preselected element to other lists
            let element = contractServicesCtrl.at(index).value;
            let whatToAdd: TechPointService[] = element.techPointServices.filter((s: TechPointService) => s.id != null && s.id != event.id);

            if (whatToAdd != null) {
                contractServicesCtrl.value.forEach((row: any, rowIndex: number) => {
                    if (rowIndex != index) {
                        if (row.technicalPoint != null && row.technicalPoint.id == element.technicalPoint.id) {
                            whatToAdd.forEach(e => {
                                if (!row.techPointServices.some((r: TechPointService) => r.id == e.id)) {
                                    row.techPointServices.push(e)
                                }
                            });
                        }
                    }
                });
            }
        }
    }

    onKindSelect(event: any, index: number) {
        if (!!event) {
            let rowCtrl = <FormArray>this.contractServicesForm.controls['rows']
            if (event == PRICE_ATTRIBUTE_KIND.PERCENTAGE) {
                rowCtrl.at(index).get('priceAttribute')?.patchValue({ type: PRICE_ATTRIBUTE_TYPE.DISCOUNT })
                rowCtrl.at(index).get('priceAttribute')?.patchValue({ amountDds: null })
                rowCtrl.at(index).get('priceAttribute')?.patchValue({ ddsValue: null })
                rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.clearValidators();
                rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.clearValidators();
                // rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.disable();
                rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.disable();
            } else {
                rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.setValidators(Validators.required);
                rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.setValidators(Validators.required);
                // rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.enable();
                rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.enable();
            }
            rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.updateValueAndValidity();
            rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.updateValueAndValidity();
        }
    }

    calculatePriceAmountDds(index: number) {
        let rowCtrl = <FormArray>this.contractServicesForm.controls['rows']
        if (rowCtrl.at(index).get('priceAttribute')?.get('kind')?.value == PRICE_ATTRIBUTE_KIND.VALUE) {
            let price: number = rowCtrl.at(index)?.get('priceAttribute')?.get('amount')?.value;
            let vat: number = rowCtrl.at(index)?.get('priceAttribute')?.get('ddsValue')?.value;
            let finalPrice: number = price * 1 + ((vat / 100) * price);
            rowCtrl.at(index)?.get('priceAttribute')?.get('amountDds')?.patchValue(finalPrice);
        }
    }

    isClientContract() {
        return this.module == 'client';
    }

    clearRowValues() {
        let rowCtrl = <FormArray>this.contractServicesForm?.controls['rows'];
        rowCtrl.value.forEach((row: any, index: number) => {
            if (!this.selectedTechnicalPoints.some(techPoint => techPoint.id == row.technicalPoint)) {
                rowCtrl.at(index).patchValue({
                    technicalPoint:         null,
                    technicalPointService:  null,
                    techPointServices:      null,
                    withoutDds:             null,
                    finalAmount:            null,
                    isValid:                false
                });
                this.clearValidatorsAndUpdateValidity(rowCtrl, index);
            }
        })
    }

    updateServiceAddInfo(index: number) {
        let rowCtrl = <FormArray>this.contractServicesForm.controls['rows']
        let serviceAddInfo = rowCtrl.at(index).value.serviceAddInfo;

        this.nomenclatureService.findActualServiceAddInfoByFirstVersion(serviceAddInfo.versionData.firstVersion.id).subscribe(res => {
            let list = rowCtrl.at(index).get("serviceAddInfoList")?.value.filter((x: ServiceAddInfo) => x.id != serviceAddInfo.id);
            list.push(res);
            rowCtrl.at(index).patchValue({ serviceAddInfoList: list });
            rowCtrl.at(index).patchValue({ serviceAddInfo: res });
        });
    }

    populateRow(contractServicesCtrl: FormArray, x: TechnicalPoint | null, techPointService?: TechPointService | null,
        techPointServices?: TechPointService[] | null, serviceAddInfoList?: ServiceAddInfo[] | null) {
        contractServicesCtrl.push(this.formBuilder.group({
            id:                     null,
            technicalPoint:         [x != null ? x.id : null, Validators.required],
            technicalPointService:  [techPointService, Validators.required],
            isValid:                true,
            paymentType:            [null, Validators.required],
            techPointServices:      techPointServices != null ? Array.of(techPointServices) : null,
            serviceAddInfo:         null,
            serviceAddInfoList:     serviceAddInfoList != null ? Array.of(serviceAddInfoList) : null,
            priceAttribute: this.formBuilder.group({
                id:                     null,
                amount:                 [null, [Validators.required, Validators.pattern(config.regexFloatNumber)]],
                amountDds:              [null, [Validators.required]],
                ddsValue:               [null, [Validators.required]],
                type:                   PRICE_ATTRIBUTE_TYPE.PRICE,
                kind:                   [PRICE_ATTRIBUTE_KIND.VALUE, Validators.required]
            })
        }));
    }

    clearValidatorsAndUpdateValidity(rowCtrl: FormArray, index: number) {
        rowCtrl.at(index).get('technicalPoint')?.clearValidators();
        rowCtrl.at(index).get('technicalPointService')?.clearValidators();
        rowCtrl.at(index).get('paymentType')?.clearValidators();
        rowCtrl.at(index).get('priceAttribute')?.get('amount')?.clearValidators();
        rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.clearValidators();
        rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.clearValidators();
        rowCtrl.at(index).get('priceAttribute')?.get('kind')?.clearValidators();

        rowCtrl.at(index).get('technicalPoint')?.updateValueAndValidity();
        rowCtrl.at(index).get('technicalPointService')?.updateValueAndValidity();
        rowCtrl.at(index).get('paymentType')?.updateValueAndValidity();
        rowCtrl.at(index).get('priceAttribute')?.get('amount')?.updateValueAndValidity();
        rowCtrl.at(index).get('priceAttribute')?.get('amountDds')?.updateValueAndValidity();
        rowCtrl.at(index).get('priceAttribute')?.get('ddsValue')?.updateValueAndValidity();
        rowCtrl.at(index).get('priceAttribute')?.get('kind')?.updateValueAndValidity();
    }

    get rows() {
        return this.contractServicesForm.get('rows') as FormArray;
    }

}