export const config = {
    regexValidEmail: "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
    regexPhoneNumber: "^(0)8[789]\d{1}(|-| )\d{3}(|-| )\d{3}$",
    regexOnlyDigits:  "^[0-9]*$",
    regexOnlyLatinSymbolsAndDigits: "^[0-9a-zA-Z]+$",
    regexFloatNumber: "^[0-9]*([.][0-9]?[0-9])?$",
    regexOnlyPositiveNumber: "^(0|[1-9][0-9]*)$",
    regexRegNumber: "^[(АВЕЗКМНОРСТУХ|ABE3KMHOPCTYX) 0-9]*$",
    // regexRegNumberFull: "/^((XH\d{4})|(C{1,2}\d{4})|(EA\d{4}[A-Z]{0,2})|([(АВЕЗКМНОРСТУХ|ABE3KMHOPCTYX) 0-9]*))$/",
    regexForeigner: /^(XH|ХН)\d{4}$/,
    regexDiplomatic: /^(C|С){1,2}\d{4}$/,
    regexElectric: /^((E|Е)[(aA|аА)][0-9]{4}[(Aa|Аа)])|((EA|ЕА)[0-9]{4}[(A|А)]{1,2})$/,
    regexPhonePlusAndDigits : "^[\[+]?[0-9]*]*$",

    maxSize: 3,
    pageSize: 10,
    pageSizeOptions: [10, 15, 20],
    formatTS: "dd.MM.yyyy HH:mm:ss",
    formatTime: "HH:mm:ss",
    formatDate: "dd.MM.yyyy",
};

export const modalMinWidth = {
    minWidth: "25vw",
    maxWidth: "62vw",
    maxHeight: "62vw",
};  

export const pdfConf = {
    color: {
    link: 'blue',
    offer: 'gray',
    offerName: 'gray',
    provider: 'black',
    },
    font: {
    default: 'OpenSans',
    },
    size: {
    offer: 12,
    offerName: 14,
    provider: 14,
    link: 10,
    disclaimer: 6
    }
  };