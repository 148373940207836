<div class="container mainbox">
    <div>
        <h1 class="form-group title-position" translate>references.discount</h1>
        <form [formGroup]="addEditForm" (ngSubmit)="onSubmit()" *ngIf="customerDiscount$ | async as data; else loading">
            <!-- Data -->
            <div class="card">
                <div class="card-body">
                    <div class="form-group form-row">
                        <!-- name -->
                        <div class="col">
                            <label translate>customerDiscounts.discountName</label>
                            <input type="text" class="form-control" formControlName="discountName" [ngClass]="{ 'is-invalid': showValidations && mainForm.discountName.errors }"
                            placeholder="{{'placeholder.enter' | translate}}">
                            <div class="invalid-feedback" *ngIf="mainForm.discountName?.errors?.required && showValidations" translate>errors.required</div>
                            <small class="text-danger" *ngIf="mainForm.discountName?.errors?.maxlength && showValidations">
                                {{'errors.inputTooLong' | translate : {max:128} }}
                            </small>
                        </div>

                        <!-- status -->
                        <div class="col">
                            <div class="form-group form-row">
                                <div class="col">
                                    <label translate>customerDiscounts.valid</label>
                                    <select class="custom-select input-custom-size" aria-describedby="isValid" formControlName="isValid">
                                        <option value="true" translate> yes</option>
                                        <option value="false" translate> no</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- subject type -->
                        <div class="col">
                            <label translate>customerDiscounts.subjectType</label>
                            <select class="custom-select input-custom-size" aria-describedby="subjectType" formControlName="subjectType"
                            [compareWith]="compareById" [ngClass]="{ 'is-invalid': showValidations && mainForm.subjectType.errors }">
                                <option value="null" disabled translate>placeholder.select</option>
                                <option *ngFor="let subjectType of subjectTypes" [ngValue]="subjectType"> {{subjectType.description}}
                            </select>
                            <div class="invalid-feedback" *ngIf="mainForm.subjectType.errors?.required && showValidations" translate>errors.required</div>  
                        </div>
                    </div>
                    <div class="form-group form-row">
                        <!-- price -->
                        <div class="col">
                            <label translate>customerDiscounts.priceAttributeKind</label>
                            <select class="custom-select input-custom-size" aria-describedby="priceAttributeKind" formControlName="priceAttributeKind"
                            (change) = "changePriceAttrKind($event)" [ngClass]="{ 'is-invalid': showValidations && mainForm.priceAttributeKind.errors }">
                                <option value="null" disabled translate>placeholder.select</option>
                                <option value="P" translate> customerDiscounts.discountPercent </option>
                                <option value="V" translate> customerDiscounts.discountInValue </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="mainForm.priceAttributeKind.errors?.required && showValidations" translate>errors.required</div>  
                        </div>

                        <!-- value -->
                        <div class="col">
                            <label *ngIf="addEditForm.get('priceAttributeKind')?.value == 'P'" translate>customerDiscounts.amountPercentage</label>
                            <label *ngIf="addEditForm.get('priceAttributeKind')?.value == 'V'" translate>customerDiscounts.amountDDS</label>
                            <label *ngIf="addEditForm.get('priceAttributeKind')?.value == null" translate>customerDiscounts.amount</label>
                        <input type="number" class="form-control" aria-describedby="priceAttributeAmountDds" formControlName="priceAttributeAmountDds"
                            placeholder="{{'placeholder.enter' | translate}}" [ngClass]="{ 'is-invalid': showValidations && mainForm.priceAttributeAmountDds.errors }">
                        <div class="invalid-feedback" *ngIf="mainForm.priceAttributeAmountDds?.errors?.required && showValidations" translate>errors.required</div>
                        <!-- <div class="invalid-feedback" *ngIf="mainForm.priceAttributeAmountDds?.errors?.min && showValidations" translate>errors.onlyPositiveNumbers</div> -->
                        <div class="invalid-feedback" *ngIf="mainForm.priceAttributeAmountDds?.errors?.max && showValidations" translate>errors.tooLong</div>
                        </div>

                        <div class="col"></div>
                    </div>
                    <div class="form-group form-row">
                        <!-- discount type -->
                        <div class="col" style="max-width: 260pt;">
                            <mat-form-field class="mat-form-field">
                                <mat-label translate style="font-size: 1rem; color: #333;" [ngClass]="{'multi-box-title': addEditForm.get('discountTypes')?.value?.length > 0}">customerDiscounts.discountTypes</mat-label>
                                <mat-select multiple formControlName="discountTypes" [compareWith]="compareById" >
                                    <mat-option *ngFor="let discountType of discountTypes" [value]="discountType">
                                        {{ discountType.description }}
                                    </mat-option>
                                </mat-select>
                                <small class="text-danger" *ngIf="mainForm.discountTypes?.errors?.required && showValidations" translate>
                                    errors.required
                                </small>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Save button -->
            <div class="text-right">
                <button class="btn btn-primary w-20 mt-2" type="submit" translate>button.save</button>
            </div>
        </form>

        <ng-template #loading>
            <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
                <div class="d-flex justify-content-center">
                    <i class="fa fa-refresh" (click)="reload()"></i>
                </div>
            </div>
            <ng-template #spinner>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>